import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '@interfaces/account';

@Pipe({
    name: 'qpAccountValid'
})
export class QpAccountValidPipe implements PipeTransform {

    public transform(value?: Account): boolean {
        return value?.type === 'Reseller' ? this.isResellerAccountValid(value) : this.isMerchantAccountValid(value);
    }

    private isMerchantAccountValid(value?: Account): boolean {
        return this.isResellerAccountValid(value) &&
            (!!value?.shop_urls?.length || !!value?.shop_url);
    }

    private isResellerAccountValid(value?: Account): boolean {
        return !!value?.customer_address?.name &&
            !!value?.customer_address?.street &&
            !!value?.customer_address?.zip_code &&
            !!value?.customer_address?.country_code &&
            !!value?.customer_address?.city &&
            !!value?.customer_address?.vat_no;
    }
}
