import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Animations } from 'app/animations/animations';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RegExpEmail } from '@helpers/reg-exp/reg-exp-email';
import { Account } from '@interfaces/account';
import { AgreementService } from '@services/agreement/agreement.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { UnsavedChanges } from 'app/routing/guards/unsaved-changes.interface';

@Component({
    selector: 'qp-settings-reseller',
    templateUrl: './reseller.html',
    animations: [Animations.getFadeAnimation()]
})
export class ResellerComponent extends BaseLoadingDirective implements OnInit, UnsavedChanges {
    public account: any;
    public resellerForm: UntypedFormGroup;
    public formSaved = false;
    public formError: any;

    constructor(
        private accountRepo: AccountRepo,
        private dialogService: DialogService,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.agreementService.agreement) {
            this.account = Object.assign({}, this.agreementService.agreement.account);
        }
        this.setupForm();
    }

    public hasUnsavedChanges(): Observable<boolean> {
        if (this.resellerForm.dirty) {
            return this.dialogService
                .unsavedChangesConfirm()
                .afterClosed()
                .pipe(map((result) => !!result));
        }
        return of(true);
    }

    public setupForm(): void {
        const readOnly = !this.agreementService.hasPermission('/account', 'patch');
        this.resellerForm = new UntypedFormGroup({
            contactEmail: new UntypedFormControl({ value: this.account.contact_email, disabled: readOnly }, [
                Validators.pattern(new RegExpEmail())
            ]),
            supportEmail: new UntypedFormControl({ value: this.account.support_email, disabled: readOnly }, [
                Validators.pattern(new RegExpEmail())
            ]),
            noreplyEmail: new UntypedFormControl({ value: this.account.noreply_email, disabled: readOnly }, [
                Validators.pattern(new RegExpEmail())
            ])
        });
    }

    public discardChanges(): void {
        this.formError = undefined;
        this.setupForm();
    }

    public save(): void {
        const values = {
            contact_email: this.getResellerFormControl('contactEmail').value,
            support_email: this.getResellerFormControl('supportEmail').value,
            noreply_email: this.getResellerFormControl('noreplyEmail').value
        };

        this.accountRepo
            .update(values, {}, '')
            .pipe(
                tap((account: Account) => {
                    if (!this.agreementService.agreement?.account) {
                        return;
                    }
                    this.agreementService.agreement.account = account;
                    this.formSaved = true;
                    this.resellerForm.markAsPristine();
                    this.resellerForm.markAsUntouched();
                }),
                catchError(error => {
                    this.formError = error;
                    return of();
                })
            )
            .subscribe();
    }

    public getResellerFormControl(key: 'contactEmail' | 'supportEmail' | 'noreplyEmail'): UntypedFormControl {
        return this.resellerForm.get(key) as UntypedFormControl;
    }
}
