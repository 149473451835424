import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerClient } from './server-client';
import { Settlement, SettlementDetail } from '@models/clearhaus-settlement';
import { ClearhausTransaction, ClearhausTransactionResponse } from '@models/transaction';
import { QpPaginationParams } from '@models/qp-pagintor';

@Injectable({
    providedIn: 'root'
})
export class SettlementRepo {
    constructor(
        private client: ServerClient
    ) {}

    public getSettlements(params: any): Observable<SettlementDetail> {
        params = params || {};
        return this.client.get<SettlementDetail>('/clearhaus/account/settlements', { params });
    }

    public getSettlementByID(id: string): Observable<Settlement> {
        return this.client.get<Settlement>(`/clearhaus/account/settlements/${id}`);
    }

    public getSettlementTransactions(id: string, pagination: QpPaginationParams): Observable<ClearhausTransaction[]> {
        return this.client.get<ClearhausTransactionResponse>(`/clearhaus/account/settlements/${id}/transactions`, { params: { ...pagination } }).pipe(
            map((response) => response.transactions)
        );
    }
}
