import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'qpAcquirer' })
export class QpAcquirerPipe implements PipeTransform {
    public readonly acquirers: { [key: string]: string } = {
        paypal: 'PayPal',
        mobilepay: 'MobilePay Online',
        viabill: 'ViaBill',
        anydaysplit: 'Anyday',
        mobilepaysubscriptions: 'MobilePay Subscriptions',
        mps: 'MobilePay Subscriptions',
        vippspsp: 'Vipps',
        mobilepay_online: 'MobilePay Online',
        vmpepayment: 'Vipps MobilePay'
    };

    public transform(acquirer?: string): string {
        if (acquirer === null || acquirer === undefined) {
            return '';
        }
        if (this.acquirers[acquirer]) {
            return this.acquirers[acquirer];
        }
        return acquirer.charAt(0).toUpperCase() + acquirer.substring(1);
    }
}
