<form [formGroup]="documentationForm" class="flex-col rg8">
    <span class="text-grey" *ngIf="company.country === 'DNK' && company.form === 'Forening'" i18n>
        Please insert the documentation of association and the latest general meeting, on which the name of the Director/authorized signatory appears.
    </span>

    <div class="flex-col" *ngIf="company.country === 'DNK' && company.form === 'Entrepreneurial'">
        <span class="text-grey" i18n>
            Because your company is an Entrepreneurial company all owners must sign a suretyship.
        </span>
        <a href="https://apply.clearhaus.com/resources/suretyship.pdf" target="_blank" color="accent" i18n>Download Suretyship</a>
    </div>

    <span class="text-grey" *ngIf="company.country !== 'DNK'" i18n>
        Please include the company registration documents and a full list of shareholders in English.
    </span>

    <mat-form-field>
        <mat-label i18n>Documentation</mat-label>
        <ngx-mat-file-input
            #documentationInput
            name="documentation"
            formControlName="documentation"
            required
            accept="application/pdf,image/*"
        >
        </ngx-mat-file-input>
        <mat-icon matSuffix>note_add</mat-icon>
        <mat-error *ngIf="documentation.errors?.required" i18n>Documentation is required.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="documentationForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
