<h5 mat-dialog-title>{{ data.header }}</h5>

<mat-dialog-content class="grey-border-bottom">
    <pre tabindex="1" id="copy_target" *ngIf="!is_json && data.isSensitive">{{ showFullContent ? data.content : maskContent(data.content) }}</pre>
    <pre tabindex="1" id="copy_target" *ngIf="!is_json && !data.isSensitive">{{ data.content }}</pre>
    <pre class="show-json" id="copy_target" *ngIf="is_json">{{ content | json }}</pre>
</mat-dialog-content>

<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button mat-button color="accent" mat-dialog-close i18n>
        Close
    </button>
    <button mat-button color="accent" i18n *ngIf="data.isSensitive" (click)="toggleContentVisibility()">
        {{ showFullContent ? 'Hide' : 'View' }}
    </button>
    <button
        mat-button
        color="accent"
        ngxClipboard
        [cbContent]="data.content"
        (cbOnSuccess)="onSuccess()"
        (cbOnError)="onError()"
        [matTooltip]="tooltip"
        i18n
    >
    Copy
    </button>
</mat-dialog-actions>
