import { ApiModule } from 'app/endpoints/api/api.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InviteUserDialogComponent } from './invite-user-dialog/invite-user-dialog.component';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';
import { SearchFieldModule } from '@widgets/searchfield/search-field.module';
import { UserComponent } from './user/user.component';
import { UserIpComponent } from './user/ip/ip.component';
import { UserPermissionsComponent } from './user/permissions/permissions.component';
import { UsersComponent } from './users.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        FormsModule,
        LayoutModule,
        PipesModule,
        ReactiveFormsModule,
        SearchFieldModule,
        RouterModule
    ],
    declarations: [
        UsersComponent,
        UserComponent,
        UserIpComponent,
        UserPermissionsComponent,
        InviteUserDialogComponent
    ],
    providers: [
        InviteUserDialogComponent
    ]
})
export class UsersModule {}
