<form name="createPayment" #f="ngForm">
    <h5 mat-dialog-title i18n>Create payment</h5>
    <mat-dialog-content class="grey-border-bottom">
        <div class="flex-row" *ngIf="transaction && !creating_payment">
            <div *ngIf="last_operation?.qp_status_code === '20000'" i18n>Payment created</div>
            <div *ngIf="last_operation?.qp_status_code !== '20000'">{{ last_operation?.qp_status_msg }}</div>
        </div>

        <div class="flex-row space-between items-center" *ngIf="creating_payment">
            <mat-spinner  [diameter]="30"></mat-spinner>
        </div>

        <div *ngIf="!transaction && !creating_payment">
            <div class="flex-row justify-start items-center cg8">
                <qp-currency-input class="flex-1" [autoFocus]="true" name="currency" [(currency)]="payment.currency"> </qp-currency-input>

                <mat-form-field class="flex-1">
                    <mat-label i18n>Amount</mat-label>
                    <input inputDecimal class="input-right" [currency]="payment.currency" matInput name="amount" fxFlex="100" id="partial_capture" type="text" required placeholder="Amount" [(ngModel)]="auth.amount" />
                </mat-form-field>
            </div>
            <div class="flex-row justify-start items-center cg8">
                <mat-form-field class="flex-50">
                    <mat-label i18n>Order id</mat-label>
                    <input matInput name="order_id" type="text" required [(ngModel)]="payment.order_id" />
                </mat-form-field>
                <div>
                    <button mat-raised-button (click)="generateOrderId()" i18n>Generate order id</button>
                </div>
            </div>
            <div class="flex-col rg8">
                <mat-checkbox name="autofee" [(ngModel)]="auth.autofee" i18n>Auto fee</mat-checkbox>
                <mat-checkbox name="autocapture" [(ngModel)]="auth.autocapture" i18n>Auto capture</mat-checkbox>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-row">
        <span class="flex-1"></span>
        <button mat-button color="accent" (click)="dialogRef.close(false)" i18n>Cancel</button>
        <button mat-button color="accent" (click)="createPayment()" *ngIf="!transaction" [disabled]="f.invalid || creating_payment" i18n>Create payment</button>
        <button
            mat-button
            color="accent"
            (click)="goToPayment()"
            *ngIf="transaction && last_operation?.qp_status_code === '20000'" i18n>Go to payment</button>
    </mat-dialog-actions>
</form>
