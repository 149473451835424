import { Component, Input, OnInit } from '@angular/core';

import { HomepageService } from '@services/homepage/homepage.service';
import { Transaction } from '@models/transaction';

@Component({
    selector: 'qp-transaction-details-retended',
    templateUrl: './transaction-retended.html'
})
export class TransactionRetendedComponent implements OnInit {
    @Input() public transaction: Transaction;
    public retentedLink: string;

    constructor(
        private homepageService: HomepageService
    ) {}

    public ngOnInit(): void {
        this.retentedLink = this.homepageService.getHelpdeskLink('pci#retention/');
    }
}
