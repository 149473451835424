import { Component, Input } from '@angular/core';

@Component({
    selector: 'qp-section-heading',
    template: '',
    templateUrl: 'section-heading.html'
})
export class SectionHeadingComponent {
    @Input() public icon: string;
    @Input() public headingText: string;

    constructor() {}
}
