import { Injectable } from '@angular/core';
import { CURRENCIES } from './currencies.constant';
import { CURRENCY_ARRAY } from './currency.constant';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    public readonly currencies: readonly string[] = Object.freeze(['DKK', 'EUR', 'SEK', 'NOK', 'GBP', 'USD']);

    public decimalPlaces(currencyCode: string): number {
        const currency = CURRENCIES[currencyCode.toLowerCase()];

        if (currency.subunitToUnit === 1) {
            return 0;
        } else if (currency.subunitToUnit % 10 === 0) {
            return Math.floor(Math.log10(currency.subunitToUnit));
        } else {
            return Math.floor(Math.log10(currency.subunitToUnit) + 1);
        }
    }

    public toFloat(number: number, currencyCode: string): number {
        const currency = CURRENCIES[currencyCode.toLowerCase()];
        const unit = number / currency.subunitToUnit;
        return unit;
    }

    public formatUserLocalize(number: number, currencyCode: string, locale: string): string {
        if (locale) {
            const currency = CURRENCIES[currencyCode.toLowerCase()];
            const unit = number / currency.subunitToUnit;
            return unit.toLocaleString(locale, { minimumFractionDigits: this.decimalPlaces(currencyCode) });
        }
        return this.format(number, currencyCode);
    }

    public format(number: number, currencyCode: string): string {
        const currency = CURRENCIES[currencyCode.toLowerCase()];
        const decimalPlace = this.decimalPlaces(currencyCode);
        return this.formatMoney(number, decimalPlace, currency.decimalMark, currency.thousandsSeparator);
    }

    public formatMoney(amount: number, decimal_places: number, decimal_mark: string, seperator: string): string {
        const prefix = amount < 0 ? '-' : '';
        let value = Math.abs(amount).toString();
        let decimal_value = '';

        if (decimal_places !== 0) {
            const split_value = this.setDecimalValue(value, decimal_places, decimal_mark);
            value = split_value[0];
            decimal_value = split_value[1];
        }

        return prefix + this.setSeperatorMarkers(value, seperator) + decimal_value;
    }

    private setDecimalValue(value: string, decimal_places: number, decimal_mark: string): Array<string> {
        const base_value = value.split('');
        const decimal_value = [];

        if (value.length <= decimal_places) {
            const offset = decimal_places + 1 - value.length;
            for (let i = 0; i < offset; i++) {
                base_value.unshift('0');
            }
        }

        for (let i = 0; i < decimal_places; i++) {
            decimal_value.push(base_value.pop());
        }

        decimal_value.push(decimal_mark);
        decimal_value.reverse();

        return [base_value.join(''), decimal_value.join('')];
    }

    private setSeperatorMarkers(value: string, seperator_mark: string): string {
        let seperator_value = value;
        const thousand_seperator_pattern = /(-?\d+)(\d{3})/;
        while (thousand_seperator_pattern.test(seperator_value)) {
            seperator_value = seperator_value.replace(thousand_seperator_pattern, '$1' + seperator_mark + '$2');
        }
        return seperator_value;
    }

    public getArray(): Array<{ value: string; name: string }> {
        return CURRENCY_ARRAY;
    }

    public isoNoTOisoCode(isoNo: string): string {
        for (const key of Object.keys(CURRENCIES)) {
            for (const ikey of Object.keys(CURRENCIES[key])) {
                if (ikey === 'isoNo' && CURRENCIES[key][ikey] === isoNo) {
                    return CURRENCIES[key]['isoCode'];
                }
            }
        }
        return isoNo;
    }

    public toCurrency(value?: number | string, currencyCode?: string, mode = 'localized'): string | number {
        let converted_value = NaN;

        if (typeof value === 'string') {
            converted_value = Number(value) ?? NaN;
        } else if (typeof value === 'number') {
            converted_value = value;
        } else if (!value) {
            return '';
        }

        if (!currencyCode) {
            return converted_value;
        }

        const currencyCodeAsNumber = Number(currencyCode);
        const isoCodeNumber = currencyCodeAsNumber ? this.isoNoTOisoCode(currencyCode) : currencyCode;

        switch (mode) {
            case 'userlocalize':
                return this.formatUserLocalize(converted_value, isoCodeNumber, '');
            case 'localized':
                return this.format(converted_value, isoCodeNumber);
            case 'float':
                return this.toFloat(converted_value, isoCodeNumber);
            default:
                return converted_value;
        }
    }
}
