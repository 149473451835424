import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ClearhausApplication, Website } from '@models/clearhaus-application/ch-instant';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { AgreementService } from '@services/agreement/agreement.service';
import { SessionService } from '@services/session/session.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';

@Component({
    selector: 'qp-clearhaus-instant-signup-form',
    templateUrl: './form-instant.html',
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ]
})
export class ClearhausInstantSignupFormComponent extends BaseLoadingDirective implements OnInit {
    @ViewChild('stepper') public myStepper: MatStepper;

    public clearhausApplication: ClearhausApplication;
    public documentationFile: DirectorFile[];
    public validity: {
        bank: boolean;
        business: boolean;
        company: boolean;
        contact: boolean;
        websites: boolean;
        signatory: boolean;
        documentation: boolean;
    };
    public displayDocumentation: boolean;

    constructor(
        private sessionService: SessionService,
        private agreementService: AgreementService
    ) {
        super();

        this.documentationFile = [];
        const whitelist = ['DNK', 'GBR', 'SWE', 'NOR'];
        if (
            this.agreementService.agreement?.account?.customer_address?.country_code &&
            !whitelist.includes(this.agreementService.agreement?.account?.customer_address?.country_code)
        ) {
            this.displayDocumentation = true;
        }
    }

    public ngOnInit(): void {
        this.initClearhausApplication();

        this.validity = {
            bank: false,
            business: false,
            company: false,
            contact: false,
            websites: false,
            signatory: false,
            documentation: !this.displayDocumentation
        };
    }

    public initClearhausApplication(): void {
        const websites: Website[] = [];
        this.agreementService.agreement?.account?.shop_urls?.forEach(url => {
            websites.push(Object.assign({ url }, new Website()));
        });

        this.documentationFile = [];
        this.clearhausApplication = Object.assign(
            {
                application: {
                    voucher_code: null,
                    business_model: {
                        description: null,
                        estimated_monthly_turnover: null
                    },
                    signer: {
                        name: null,
                        email: null
                    }
                },
                bank_account: {
                    currency: null,
                    iban: null
                },
                company: {
                    name: this.agreementService.agreement?.account?.customer_address?.name,
                    registration_number: this.agreementService.agreement?.account?.customer_address?.vat_no,
                    address_line_1: this.agreementService.agreement?.account?.customer_address?.street,
                    city: this.agreementService.agreement?.account?.customer_address?.city,
                    country: this.agreementService.agreement?.account?.customer_address?.country_code,
                    zipcode: this.agreementService.agreement?.account?.customer_address?.zip_code,
                    phone: this.agreementService.agreement?.account?.contact_phone
                },
                contact: {
                    name: this.sessionService.me?.name,
                    email: this.sessionService.me?.email
                },
                websites
            },
            new ClearhausApplication()
        );
    }

    public updateValidity(params: any): void {
        this.validity = { ...this.validity, ...params };
    }
}
