import { Component, Inject } from '@angular/core';
import { DialogService } from '@widgets/dialog/dialog.service';
import { ExportFields } from './exporter-fields';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'qp-exporter-fields-dialog',
    templateUrl: 'exporter-fields.html'
})
export class ExporterFieldsDialogComponent {
    public fields: ExportFields = {
        invoice_address: false,
        shipping_address: false,
        variablesAssembled: true,
        variablesSplit: false,
        variablesSplitValue: [],
        basket: false
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogService: DialogService,
        public dialogRef: MatDialogRef<ExporterFieldsDialogComponent>
    ) {}

    public addVariable(): void {
        const fields: PromptField[] = [
            {
                label: $localize`Variable`,
                required: true,
                type: 'text',
                name: 'variable_name'
            }
        ];

        this.dialogService.prompt(
            $localize`Add variable`,
            '',
            $localize`Add`,
            fields
        ).afterClosed().subscribe((returnFields: PromptField[]) => {
            if (!!returnFields) {
                this.fields.variablesSplitValue.push(returnFields[0].value);
            }
        });
    }
}
