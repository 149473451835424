export const NOP_COMMERCE_PERMISSIONS = [
    {
        resource: '/account',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/account/private-key',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acl-resources',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/:acquirer/test',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/activity',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/api-key',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id/*resource_name',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id/copy',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/cards/:id/authorize',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/cancel',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/link',
        get: false,
        post: false,
        put: true,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/tokens',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/changelog',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/:acquirer/:payment_method',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/formulas',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/formulas/:acquirer',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/formulas/:acquirer/:payment_method',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/operational-status/acquirers',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payment-method-priority',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payment-methods',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/payments/:id/authorize',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/cancel',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/capture',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/link',
        get: false,
        post: false,
        put: true,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/refund',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/renew',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/session',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/ping',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/subscriptions/:id/authorize',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/cancel',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/link',
        get: false,
        post: false,
        put: true,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/payments',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/recurring',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/session',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    }
];
