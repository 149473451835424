<mat-card class="card02" *ngIf="transaction && data_exists">
	<mat-card-content class="flex-col rg16">
        <mat-card-title class="font-weight-normal" *ngIf="different_address" i18n>Invoice address</mat-card-title>

		<qp-customer-info-address [address]="transaction.invoice_address" *ngIf="different_address"></qp-customer-info-address>

        <mat-card-title class="font-weight-normal" *ngIf="different_address && !!transaction.shipping_address" i18n>Shipping address</mat-card-title>
        <mat-card-title class="font-weight-normal" *ngIf="!different_address" i18n>Shipping/Invoice address</mat-card-title>

		<qp-customer-info-address [address]="transaction.shipping_address"></qp-customer-info-address>

		<div class="flex-row customer-info-btn no-print" *ngIf="is_delete_enabled">
			<button mat-raised-button (click)="deleteAddress()" i18n>Delete customer information</button>
		</div>
	</mat-card-content>
</mat-card>
