import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterParams, SearchParams } from '@interfaces/params';
import { ParamService } from '@services/param/params.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    public search_params: SearchParams;

    constructor(
        private activatedRoute: ActivatedRoute,
        private paramService: ParamService
    ) {}

    public handleSearch(params: SearchParams): void {
        this.search_params = {
            page_size: !!params.page_size
                ? params.page_size
                : Number(this.activatedRoute.snapshot.queryParams.page_size) ||
                10,
            sort_by: !!params.sort_by ? params.sort_by : this.activatedRoute.snapshot.queryParams.sort_by || 'id',
            sort_dir: !!params.sort_dir ? params.sort_dir : this.activatedRoute.snapshot.queryParams.sort_dir || 'desc',
            page: !!params.page ? params.page : Number(this.activatedRoute.snapshot.queryParams.page) || 1,
            page_key: !!params.page_key ? params.page_key : this.activatedRoute.snapshot.queryParams.page_key || '',
            auto_focus: !!params.auto_focus ? params.auto_focus : false,
            show_search: !!params.show_search ? params.show_search : false,
            search: !!params.search ? params.search : decodeURIComponent(this.activatedRoute.snapshot.queryParams.search || ''),
            search_by: !!params.search_by ? params.search_by : this.activatedRoute.snapshot.queryParams.search_by || 'order_id',
            search_by_options: !!params.search_by_options
                ? params.search_by_options
                : [
                    { name: 'ID', value: 'id' },
                    { name: 'Order ID', wildcard: true, value: 'order_id' }
                ],
            state: this.getState(this.activatedRoute.snapshot.queryParams.state, params.state, params.states ?? []) || null,
            states: !!params.states ? params.states : undefined,
            acquirer: !!params.acquirer ? params.acquirer : this.activatedRoute.snapshot.queryParams.acquirer || null,
            min_time: !!params.min_time
                ? params.min_time
                : this.activatedRoute.snapshot.queryParams.min_time
                    ? new Date(decodeURIComponent(this.activatedRoute.snapshot.queryParams.min_time))
                    : undefined,
            max_time: !!params.max_time
                ? params.max_time
                : this.activatedRoute.snapshot.queryParams.max_time
                    ? new Date(decodeURIComponent(this.activatedRoute.snapshot.queryParams.max_time))
                    : undefined,
            timestamp: !!params.timestamp ? params.timestamp : this.activatedRoute.snapshot.queryParams.timestamp || null,
            suspected_fraud: !!params.suspected_fraud
                ? params.suspected_fraud
                : this.activatedRoute.snapshot.queryParams.suspected_fraud === 'true' || this.activatedRoute.snapshot.queryParams.suspected_fraud || null
        };

        if (this.search_params.search) {
            this.search_params.auto_focus = true;
        }

        this.paramService.setParams(this.search_params, this.activatedRoute);
    }

    private getState(urlState: string, state: string, states: Array<string>): number | string {
        if (!!urlState && !!Number(urlState)) {
            return Math.abs(Number(urlState));
        } else if (urlState === '0') {
            return '0'; // if get payments initial 0 return as string (╯°□°)╯︵ ┻━┻
        }

        if (!!state) {
            return states.indexOf(state);
        }

        if (!!this.search_params.state) {
            return this.search_params.state;
        }

        return 0;
    }

    public setFilterParams(params: FilterParams): void {
        this.search_params.acquirer = params.acquirer;
        this.search_params.max_time = params.max_time;
        this.search_params.min_time = params.min_time;
        this.search_params.timestamp = params.timestamp;
        this.search_params.suspected_fraud = params.suspected_fraud;

        this.paramService.setParams({ ...params, subscription_group: params.subscription_group?.id ? params.subscription_group.id : params.subscription_group ?? '' }, this.activatedRoute);
    }

    public clearParams(): void {
        this.search_params = {} as SearchParams;
        this.paramService.setParams(this.search_params, this.activatedRoute);
    }

    public getSearchParams(): SearchParams {
        return this.search_params;
    }

    public setSearchParams(params: SearchParams): SearchParams {
        this.search_params.search_by = !!params.search_by ? params.search_by : this.search_params.search_by;

        if (!!params.search || params.search === '') {
            this.search_params.search = params.search;
        }

        if (!!params.state || params.state === 0) {
            this.search_params.state = params.state;
        }

        return this.search_params;
    }

    public queryReplace(search: string): string {
        search = search.replace(/%20/g, ' ');
        search = search.replace(/%40/g, '@');
        search = search.replace(/%C3%A6/g, 'æ');
        search = search.replace(/%C3%86/g, 'Æ');
        search = search.replace(/%C3%B8/g, 'ø');
        search = search.replace(/%C3%98/g, 'Ø');
        search = search.replace(/%C3%A5/g, 'å');
        search = search.replace(/%C3%85/g, 'Å');

        return search;
    }
}
