import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RegExpEmail } from '@helpers/reg-exp/reg-exp-email';
import { Attachment, Mail } from '@interfaces/mail';
import { Payment } from '@models/payment';
import { AgreementService } from '@services/agreement/agreement.service';
import { HomepageService } from '@services/homepage/homepage.service';
import { LocalesService } from '@services/locales/locales.service';
import { SessionService } from '@services/session/session.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { PaymentRepo } from 'app/endpoints/api/payment-repo';
import { SupportRepo } from 'app/endpoints/server/support-repo';
import { Observable, concatMap, finalize, map, range } from 'rxjs';

@Component({
    selector: 'qp-support',
    templateUrl: 'support.html',
    animations: [Animations.getFadeAnimation()]
})
export class SupportComponent extends BaseLoadingDirective implements OnInit {
    public faq_link: string;
    public mail_form: FormGroup;
    public files: Array<Attachment> = [];
    public input_files: Array<File> = [];
    public sending = false;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private dialogService: DialogService,
        private homepageService: HomepageService,
        private locales: LocalesService,
        private paymentRepo: PaymentRepo,
        private sessionService: SessionService,
        private supportRepo: SupportRepo,
        private activatedRoute: ActivatedRoute,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.faq_link = this.homepageService.getHelpdeskLink('index');
        this.setupMailForm();
    }

    public setupMailForm(): void {
        this.mail_form = new FormGroup({
            mail_to: new FormControl(
                { value: this.agreementService.agreement?.account?.reseller.support_email ?? '', disabled: true },
                [Validators.required, Validators.pattern(new RegExpEmail())]
            ),
            mail_from: new FormControl(
                { value: this.sessionService.me?.email ?? '', disabled: true },
                [Validators.required, Validators.pattern(new RegExpEmail())]
            ),
            subject: new FormControl(
                { value: this.setSubject(), disabled: false },
                Validators.required
            ),
            message: new FormControl(
                { value: '', disabled: false },
                Validators.required
            )
        });
    }

    public setSubject(): string {
        let subject = '';

        if (this.activatedRoute.snapshot.queryParams.locale) {
            const locale = this.locales.getLocaleNameFromCode(this.activatedRoute.snapshot.queryParams.locale);
            subject = 'Translation - ' + locale?.name ?? '';
        }

        if (this.activatedRoute.snapshot.queryParams.aq_status_code === '922') {
            subject = 'Change cancel state of transaction ' + this.activatedRoute.snapshot.queryParams.transaction_id;
        }

        return subject;
    }

    public addFile(files: Array<File>): void {
        this.files = [];
        this.input_files = files;

        range(0, files.length).pipe(
            concatMap(index => {
                return this.readFile(files[index]).pipe(
                    map(result => ({
                        filename: files[index].name,
                        content_type: files[index].type,
                        content: result
                    }))
                );
            })
        ).subscribe(attachment => {
            this.files.push(attachment);
        });
    }

    public readFile(file: File): Observable<string> {
        return new Observable(obs => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (): void => {
                obs.next(String(reader.result).split(',')[1]);
                obs.complete();
            };
        });
    }

    public sendMail(): void {
        this.sending = true;
        const mail = {
            to: this.mailFormControl('mail_to').value,
            from: this.mailFormControl('mail_from').value,
            subject: this.mailFormControl('subject').value,
            body_text: this.mailFormControl('message').value,
            attachments: this.files
        } as Mail;

        mail.body_text += this.addExtraInfo();

        this.supportRepo.sendSupportMail(mail).pipe(
            finalize(() => {
                this.sending = false;
            })
        ).subscribe(() => {
            this.dialogService.alert($localize`Mail sent successfully!`, '');
            this.setupMailForm();
            this.files = [];
            this.input_files = [];
        });
    }

    public addExtraInfo(): string {
        let extra_info = '';

        if (this.activatedRoute.snapshot.queryParams.payment_id) {
            this.paymentRepo.get<Payment>(this.activatedRoute.snapshot.queryParams.payment_id).subscribe(payment => {
                extra_info =
                    '\n\n\n//////////////////////////\n' +
                    'Information from manager:\n' +
                    'Payment ID: ' + payment.id + '\n' +
                    'Order ID: ' + payment.order_id + '\n' +
                    'Payment state: ' + payment.state;
            });
        }

        extra_info += '\n\nManager-language: ' + this.locale;
        return extra_info;
    }

    public mailFormControl(key: string): FormControl {
        return this.mail_form.get(key) as FormControl;
    }
}
