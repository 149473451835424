<div mat-dialog-title class="flex-row space-between padding t24">
    <h5 class="order--1" i18n>Gateway agreement</h5>
    <mat-icon class="cursor" mat-dialog-close>close</mat-icon>
</div>
<div mat-dialog-content>
    <table class="min-width-550px" *ngIf="plan && plan.attributes">
        <tbody>
            <tr>
                <td i18n>Monthly fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.price_per_month ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
            <tr>
                <td i18n>Gateway fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.price_per_transaction ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
            </tr>
            <tr>
                <td i18n>MobilePay additional monthly fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.mobilepay_monthly_price ?? 0) / 100)| number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
            <tr>
                <td i18n>MobilePay additional gateway fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.mobilepay_transaction_price ?? 0) / 100)| number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
            </tr>
            <tr>
                <td i18n>Vipps additional gateway fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.vippspsp_transaction_price ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
            </tr>
            <tr>
                <td i18n>Included transactions</td>
                <td class="width-100px"><strong class="subtitle2">{{ plan.attributes.included_transactions }}</strong></td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
            <tr>
                <td i18n>Branding of the payment window</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.branding_fee ?? 0) / 100) | number: '1.2-2'}} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
            <tr>
                <td i18n>Additional domains</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.shop_url_fee ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
            <tr>
                <td i18n>Additional payment method monthly fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.add_pm_monthly_price ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
            <tr>
                <td i18n>Additional payment method gateway fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.price_per_add_pm_transaction ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
            </tr>
        </tbody>
    </table>
</div>
