import { Currency } from '@interfaces/currency';

/* eslint-disable */
export const CURRENCIES: { [key: string]: Currency } = {
    aed: {
        priority: 100,
        isoCode: 'AED',
        isoNo: '784',
        name: 'United Arab Emirates Dirham',
        symbol: 'د.إ',
        subunit: 'Fils',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    afn: {
        priority: 100,
        isoCode: 'AFN',
        isoNo: '971',
        name: 'Afghan Afghani',
        symbol: '؋',
        subunit: 'Pul',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    all: {
        priority: 100,
        isoCode: 'ALL',
        isoNo: '008',
        name: 'Albanian Lek',
        symbol: 'L',
        subunit: 'Qintar',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    amd: {
        priority: 100,
        isoCode: 'AMD',
        isoNo: '051',
        name: 'Armenian Dram',
        symbol: 'դր.',
        subunit: 'Luma',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ang: {
        priority: 100,
        isoCode: 'ANG',
        isoNo: '532',
        name: 'Netherlands Antillean Gulden',
        symbol: 'ƒ',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x0192;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    aoa: {
        priority: 100,
        isoCode: 'AOA',
        isoNo: '973',
        name: 'Angolan Kwanza',
        symbol: 'Kz',
        subunit: 'Cêntimo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ars: {
        priority: 100,
        isoCode: 'ARS',
        isoNo: '032',
        name: 'Argentine Peso',
        symbol: '$',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20B1;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    aud: {
        priority: 4,
        isoCode: 'AUD',
        isoNo: '036',
        name: 'Australian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    awg: {
        priority: 100,
        isoCode: 'AWG',
        isoNo: '533',
        name: 'Aruban Florin',
        symbol: 'ƒ',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x0192;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    azn: {
        priority: 100,
        isoCode: 'AZN',
        isoNo: '944',
        name: 'Azerbaijani Manat',
        subunit: 'Qəpik',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bam: {
        priority: 100,
        isoCode: 'BAM',
        isoNo: '977',
        name: 'Bosnia and Herzegovina Convertible Mark',
        symbol: 'KM or КМ',
        subunit: 'Fening',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bbd: {
        priority: 100,
        isoCode: 'BBD',
        isoNo: '052',
        name: 'Barbadian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bdt: {
        priority: 100,
        isoCode: 'BDT',
        isoNo: '050',
        name: 'Bangladeshi Taka',
        symbol: '৳',
        subunit: 'Paisa',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bgn: {
        priority: 100,
        isoCode: 'BGN',
        isoNo: '975',
        name: 'Bulgarian Lev',
        symbol: 'лв',
        subunit: 'Stotinka',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bhd: {
        priority: 100,
        isoCode: 'BHD',
        isoNo: '048',
        name: 'Bahraini Dinar',
        symbol: 'ب.د',
        subunit: 'Fils',
        subunitToUnit: 1000,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bif: {
        priority: 100,
        isoCode: 'BIF',
        isoNo: '108',
        name: 'Burundian Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bmd: {
        priority: 100,
        isoCode: 'BMD',
        isoNo: '060',
        name: 'Bermudian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bnd: {
        priority: 100,
        isoCode: 'BND',
        isoNo: '096',
        name: 'Brunei Dollar',
        symbol: '$',
        subunit: 'Sen',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bob: {
        priority: 100,
        isoCode: 'BOB',
        isoNo: '068',
        name: 'Bolivian Boliviano',
        symbol: 'Bs.',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    brl: {
        priority: 100,
        isoCode: 'BRL',
        isoNo: '986',
        name: 'Brazilian Real',
        symbol: 'R$ ',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: 'R$',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    bsd: {
        priority: 100,
        isoCode: 'BSD',
        isoNo: '044',
        name: 'Bahamian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    btn: {
        priority: 100,
        isoCode: 'BTN',
        isoNo: '064',
        name: 'Bhutanese Ngultrum',
        subunit: 'Chertrum',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bwp: {
        priority: 100,
        isoCode: 'BWP',
        isoNo: '072',
        name: 'Botswana Pula',
        symbol: 'P',
        subunit: 'Thebe',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    byr: {
        priority: 100,
        isoCode: 'BYR',
        isoNo: '974',
        name: 'Belarusian Ruble',
        symbol: 'Br',
        subunit: 'Kapyeyka',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    bzd: {
        priority: 100,
        isoCode: 'BZD',
        isoNo: '084',
        name: 'Belize Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    cad: {
        priority: 5,
        isoCode: 'CAD',
        isoNo: '124',
        name: 'Canadian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    cdf: {
        priority: 100,
        isoCode: 'CDF',
        isoNo: '976',
        name: 'Congolese Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    chf: {
        priority: 100,
        isoCode: 'CHF',
        isoNo: '756',
        name: 'Swiss Franc',
        symbol: 'Fr',
        subunit: 'Rappen',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    clp: {
        priority: 100,
        isoCode: 'CLP',
        isoNo: '152',
        name: 'Chilean Peso',
        symbol: '$',
        subunit: 'Peso',
        subunitToUnit: 1,
        symbolFirst: true,
        htmlEntity: '&#x20B1;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    cny: {
        priority: 100,
        isoCode: 'CNY',
        isoNo: '156',
        name: 'Chinese Renminbi Yuan',
        symbol: '¥',
        subunit: 'Fen',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x5713;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    cop: {
        priority: 100,
        isoCode: 'COP',
        isoNo: '170',
        name: 'Colombian Peso',
        symbol: '$',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20B1;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    crc: {
        priority: 100,
        isoCode: 'CRC',
        isoNo: '188',
        name: 'Costa Rican Colón',
        symbol: '₡',
        subunit: 'Céntimo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20A1;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    cuc: {
        priority: 100,
        isoCode: 'CUC',
        isoNo: '931',
        name: 'Cuban Convertible Peso',
        symbol: '$',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    cup: {
        priority: 100,
        isoCode: 'CUP',
        isoNo: '192',
        name: 'Cuban Peso',
        symbol: '$',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20B1;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    cve: {
        priority: 100,
        isoCode: 'CVE',
        isoNo: '132',
        name: 'Cape Verdean Escudo',
        symbol: '$ or Esc',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    czk: {
        priority: 100,
        isoCode: 'CZK',
        isoNo: '203',
        name: 'Czech Koruna',
        symbol: 'Kč',
        subunit: 'Haléř',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    djf: {
        priority: 100,
        isoCode: 'DJF',
        isoNo: '262',
        name: 'Djiboutian Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    dkk: {
        priority: 100,
        isoCode: 'DKK',
        isoNo: '208',
        name: 'Danish Krone',
        symbol: 'kr',
        subunit: 'Øre',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    dop: {
        priority: 100,
        isoCode: 'DOP',
        isoNo: '214',
        name: 'Dominican Peso',
        symbol: '$',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20B1;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    dzd: {
        priority: 100,
        isoCode: 'DZD',
        isoNo: '012',
        name: 'Algerian Dinar',
        symbol: 'د.ج',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    egp: {
        priority: 100,
        isoCode: 'EGP',
        isoNo: '818',
        name: 'Egyptian Pound',
        symbol: '£ or ج.م',
        subunit: 'Piastre',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ern: {
        priority: 100,
        isoCode: 'ERN',
        isoNo: '232',
        name: 'Eritrean Nakfa',
        symbol: 'Nfk',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    etb: {
        priority: 100,
        isoCode: 'ETB',
        isoNo: '230',
        name: 'Ethiopian Birr',
        subunit: 'Santim',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    eur: {
        priority: 2,
        isoCode: 'EUR',
        isoNo: '978',
        name: 'Euro',
        symbol: '€',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20AC;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    fjd: {
        priority: 100,
        isoCode: 'FJD',
        isoNo: '242',
        name: 'Fijian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    fkp: {
        priority: 100,
        isoCode: 'FKP',
        isoNo: '238',
        name: 'Falkland Pound',
        symbol: '£',
        subunit: 'Penny',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    gbp: {
        priority: 3,
        isoCode: 'GBP',
        isoNo: '826',
        name: 'British Pound',
        symbol: '£',
        subunit: 'Penny',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    gel: {
        priority: 100,
        isoCode: 'GEL',
        isoNo: '981',
        name: 'Georgian Lari',
        symbol: 'ლ',
        subunit: 'Tetri',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ghs: {
        priority: 100,
        isoCode: 'GHS',
        isoNo: '936',
        name: 'Ghanaian Cedi',
        symbol: '₵',
        subunit: 'Pesewa',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20B5;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    gip: {
        priority: 100,
        isoCode: 'GIP',
        isoNo: '292',
        name: 'Gibraltar Pound',
        symbol: '£',
        subunit: 'Penny',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    gmd: {
        priority: 100,
        isoCode: 'GMD',
        isoNo: '270',
        name: 'Gambian Dalasi',
        symbol: 'D',
        subunit: 'Butut',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    gnf: {
        priority: 100,
        isoCode: 'GNF',
        isoNo: '324',
        name: 'Guinean Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    gtq: {
        priority: 100,
        isoCode: 'GTQ',
        isoNo: '320',
        name: 'Guatemalan Quetzal',
        symbol: 'Q',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    gyd: {
        priority: 100,
        isoCode: 'GYD',
        isoNo: '328',
        name: 'Guyanese Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    hkd: {
        priority: 100,
        isoCode: 'HKD',
        isoNo: '344',
        name: 'Hong Kong Dollar',
        symbol: '$',
        subunit: 'Ho',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    hnl: {
        priority: 100,
        isoCode: 'HNL',
        isoNo: '340',
        name: 'Honduran Lempira',
        symbol: 'L',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    hrk: {
        priority: 100,
        isoCode: 'HRK',
        isoNo: '191',
        name: 'Croatian Kuna',
        symbol: 'kn',
        subunit: 'Lipa',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    htg: {
        priority: 100,
        isoCode: 'HTG',
        isoNo: '332',
        name: 'Haitian Gourde',
        symbol: 'G',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    huf: {
        priority: 100,
        isoCode: 'HUF',
        isoNo: '348',
        name: 'Hungarian Forint',
        symbol: 'Ft',
        subunit: 'Fillér',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    idr: {
        priority: 100,
        isoCode: 'IDR',
        isoNo: '360',
        name: 'Indonesian Rupiah',
        symbol: 'Rp',
        subunit: 'Sen',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    ils: {
        priority: 100,
        isoCode: 'ILS',
        isoNo: '376',
        name: 'Israeli New Sheqel',
        symbol: '₪',
        subunit: 'Agora',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20AA;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    inr: {
        priority: 100,
        isoCode: 'INR',
        isoNo: '356',
        name: 'Indian Rupee',
        symbol: '₨',
        subunit: 'Paisa',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20A8;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    iqd: {
        priority: 100,
        isoCode: 'IQD',
        isoNo: '368',
        name: 'Iraqi Dinar',
        symbol: 'ع.د',
        subunit: 'Fils',
        subunitToUnit: 1000,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    irr: {
        priority: 100,
        isoCode: 'IRR',
        isoNo: '364',
        name: 'Iranian Rial',
        symbol: '﷼',
        subunit: 'Dinar',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#xFDFC;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    isk: {
        priority: 100,
        isoCode: 'ISK',
        isoNo: '352',
        name: 'Icelandic Króna',
        symbol: 'kr',
        subunit: 'Eyrir',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    jmd: {
        priority: 100,
        isoCode: 'JMD',
        isoNo: '388',
        name: 'Jamaican Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    jod: {
        priority: 100,
        isoCode: 'JOD',
        isoNo: '400',
        name: 'Jordanian Dinar',
        symbol: 'د.ا',
        subunit: 'Piastre',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    jpy: {
        priority: 6,
        isoCode: 'JPY',
        isoNo: '392',
        name: 'Japanese Yen',
        symbol: '¥',
        subunit: 'Sen',
        subunitToUnit: 1,
        symbolFirst: true,
        htmlEntity: '&#x00A5;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    kes: {
        priority: 100,
        isoCode: 'KES',
        isoNo: '404',
        name: 'Kenyan Shilling',
        symbol: 'Sh',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    kgs: {
        priority: 100,
        isoCode: 'KGS',
        isoNo: '417',
        name: 'Kyrgyzstani Som',
        subunit: 'Tyiyn',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    khr: {
        priority: 100,
        isoCode: 'KHR',
        isoNo: '116',
        name: 'Cambodian Riel',
        symbol: '៛',
        subunit: 'Sen',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x17DB;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    kmf: {
        priority: 100,
        isoCode: 'KMF',
        isoNo: '174',
        name: 'Comorian Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    kpw: {
        priority: 100,
        isoCode: 'KPW',
        isoNo: '408',
        name: 'North Korean Won',
        symbol: '₩',
        subunit: 'Chŏn',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20A9;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    krw: {
        priority: 100,
        isoCode: 'KRW',
        isoNo: '410',
        name: 'South Korean Won',
        symbol: '₩',
        subunitToUnit: 1,
        symbolFirst: true,
        htmlEntity: '&#x20A9;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    kwd: {
        priority: 100,
        isoCode: 'KWD',
        isoNo: '414',
        name: 'Kuwaiti Dinar',
        symbol: 'د.ك',
        subunit: 'Fils',
        subunitToUnit: 1000,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    kyd: {
        priority: 100,
        isoCode: 'KYD',
        isoNo: '136',
        name: 'Cayman Islands Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    kzt: {
        priority: 100,
        isoCode: 'KZT',
        isoNo: '398',
        name: 'Kazakhstani Tenge',
        symbol: '〒',
        subunit: 'Tiyn',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    lak: {
        priority: 100,
        isoCode: 'LAK',
        isoNo: '418',
        name: 'Lao Kip',
        symbol: '₭',
        subunit: 'Att',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20AD;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    lbp: {
        priority: 100,
        isoCode: 'LBP',
        isoNo: '422',
        name: 'Lebanese Lira',
        symbol: 'ل.ل',
        subunit: 'Piastre',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    lkr: {
        priority: 100,
        isoCode: 'LKR',
        isoNo: '144',
        name: 'Sri Lankan Rupee',
        symbol: '₨',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x0BF9;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    lrd: {
        priority: 100,
        isoCode: 'LRD',
        isoNo: '430',
        name: 'Liberian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    lsl: {
        priority: 100,
        isoCode: 'LSL',
        isoNo: '426',
        name: 'Lesotho Loti',
        symbol: 'L',
        subunit: 'Sente',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ltl: {
        priority: 100,
        isoCode: 'LTL',
        isoNo: '440',
        name: 'Lithuanian Litas',
        symbol: 'Lt',
        subunit: 'Centas',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    lvl: {
        priority: 100,
        isoCode: 'LVL',
        isoNo: '428',
        name: 'Latvian Lats',
        symbol: 'Ls',
        subunit: 'Santīms',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    lyd: {
        priority: 100,
        isoCode: 'LYD',
        isoNo: '434',
        name: 'Libyan Dinar',
        symbol: 'ل.د',
        subunit: 'Dirham',
        subunitToUnit: 1000,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mad: {
        priority: 100,
        isoCode: 'MAD',
        isoNo: '504',
        name: 'Moroccan Dirham',
        symbol: 'د.م.',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mdl: {
        priority: 100,
        isoCode: 'MDL',
        isoNo: '498',
        name: 'Moldovan Leu',
        symbol: 'L',
        subunit: 'Ban',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mga: {
        priority: 100,
        isoCode: 'MGA',
        isoNo: '969',
        name: 'Malagasy Ariary',
        subunit: 'Iraimbilanja',
        subunitToUnit: 5,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mkd: {
        priority: 100,
        isoCode: 'MKD',
        isoNo: '807',
        name: 'Macedonian Denar',
        symbol: 'ден',
        subunit: 'Deni',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mmk: {
        priority: 100,
        isoCode: 'MMK',
        isoNo: '104',
        name: 'Myanmar Kyat',
        symbol: 'K',
        subunit: 'Pya',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mnt: {
        priority: 100,
        isoCode: 'MNT',
        isoNo: '496',
        name: 'Mongolian Tögrög',
        symbol: '₮',
        subunit: 'Möngö',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20AE;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mop: {
        priority: 100,
        isoCode: 'MOP',
        isoNo: '446',
        name: 'Macanese Pataca',
        symbol: 'P',
        subunit: 'Avo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mro: {
        priority: 100,
        isoCode: 'MRO',
        isoNo: '478',
        name: 'Mauritanian Ouguiya',
        symbol: 'UM',
        subunit: 'Khoums',
        subunitToUnit: 5,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mur: {
        priority: 100,
        isoCode: 'MUR',
        isoNo: '480',
        name: 'Mauritian Rupee',
        symbol: '₨',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20A8;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mvr: {
        priority: 100,
        isoCode: 'MVR',
        isoNo: '462',
        name: 'Maldivian Rufiyaa',
        symbol: 'ރ.',
        subunit: 'Laari',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mwk: {
        priority: 100,
        isoCode: 'MWK',
        isoNo: '454',
        name: 'Malawian Kwacha',
        symbol: 'MK',
        subunit: 'Tambala',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mxn: {
        priority: 100,
        isoCode: 'MXN',
        isoNo: '484',
        name: 'Mexican Peso',
        symbol: '$',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    myr: {
        priority: 100,
        isoCode: 'MYR',
        isoNo: '458',
        name: 'Malaysian Ringgit',
        symbol: 'RM',
        subunit: 'Sen',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    mzn: {
        priority: 100,
        isoCode: 'MZN',
        isoNo: '943',
        name: 'Mozambican Metical',
        symbol: 'MTn',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    nad: {
        priority: 100,
        isoCode: 'NAD',
        isoNo: '516',
        name: 'Namibian Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ngn: {
        priority: 100,
        isoCode: 'NGN',
        isoNo: '566',
        name: 'Nigerian Naira',
        symbol: '₦',
        subunit: 'Kobo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20A6;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    nio: {
        priority: 100,
        isoCode: 'NIO',
        isoNo: '558',
        name: 'Nicaraguan Córdoba',
        symbol: 'C$',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    nok: {
        priority: 100,
        isoCode: 'NOK',
        isoNo: '578',
        name: 'Norwegian Krone',
        symbol: 'kr',
        subunit: 'Øre',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: 'kr',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    npr: {
        priority: 100,
        isoCode: 'NPR',
        isoNo: '524',
        name: 'Nepalese Rupee',
        symbol: '₨',
        subunit: 'Paisa',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20A8;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    nzd: {
        priority: 100,
        isoCode: 'NZD',
        isoNo: '554',
        name: 'New Zealand Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    omr: {
        priority: 100,
        isoCode: 'OMR',
        isoNo: '512',
        name: 'Omani Rial',
        symbol: 'ر.ع.',
        subunit: 'Baisa',
        subunitToUnit: 1000,
        symbolFirst: true,
        htmlEntity: '&#xFDFC;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    pab: {
        priority: 100,
        isoCode: 'PAB',
        isoNo: '590',
        name: 'Panamanian Balboa',
        symbol: 'B/.',
        subunit: 'Centésimo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    pen: {
        priority: 100,
        isoCode: 'PEN',
        isoNo: '604',
        name: 'Peruvian Nuevo Sol',
        symbol: 'S/.',
        subunit: 'Céntimo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: 'S/.',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    pgk: {
        priority: 100,
        isoCode: 'PGK',
        isoNo: '598',
        name: 'Papua New Guinean Kina',
        symbol: 'K',
        subunit: 'Toea',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    php: {
        priority: 100,
        isoCode: 'PHP',
        isoNo: '608',
        name: 'Philippine Peso',
        symbol: '₱',
        subunit: 'Centavo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20B1;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    pkr: {
        priority: 100,
        isoCode: 'PKR',
        isoNo: '586',
        name: 'Pakistani Rupee',
        symbol: '₨',
        subunit: 'Paisa',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20A8;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    pln: {
        priority: 100,
        isoCode: 'PLN',
        isoNo: '985',
        name: 'Polish Złoty',
        symbol: 'zł',
        subunit: 'Grosz',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    pyg: {
        priority: 100,
        isoCode: 'PYG',
        isoNo: '600',
        name: 'Paraguayan Guaraní',
        symbol: '₲',
        subunit: 'Céntimo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20B2;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    qar: {
        priority: 100,
        isoCode: 'QAR',
        isoNo: '634',
        name: 'Qatari Riyal',
        symbol: 'ر.ق',
        subunit: 'Dirham',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#xFDFC;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ron: {
        priority: 100,
        isoCode: 'RON',
        isoNo: '946',
        name: 'Romanian Leu',
        symbol: 'L',
        subunit: 'Ban',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    rsd: {
        priority: 100,
        isoCode: 'RSD',
        isoNo: '941',
        name: 'Serbian Dinar',
        symbol: 'din. or дин.',
        subunit: 'Para',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    rub: {
        priority: 100,
        isoCode: 'RUB',
        isoNo: '643',
        name: 'Russian Ruble',
        symbol: 'р.',
        subunit: 'Kopek',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x0440;&#x0443;&#x0431;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    rwf: {
        priority: 100,
        isoCode: 'RWF',
        isoNo: '646',
        name: 'Rwandan Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    sar: {
        priority: 100,
        isoCode: 'SAR',
        isoNo: '682',
        name: 'Saudi Riyal',
        symbol: 'ر.س',
        subunit: 'Hallallah',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#xFDFC;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    sbd: {
        priority: 100,
        isoCode: 'SBD',
        isoNo: '090',
        name: 'Solomon Islands Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    scr: {
        priority: 100,
        isoCode: 'SCR',
        isoNo: '690',
        name: 'Seychellois Rupee',
        symbol: '₨',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20A8;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    sdg: {
        priority: 100,
        isoCode: 'SDG',
        isoNo: '938',
        name: 'Sudanese Pound',
        symbol: '£',
        subunit: 'Piastre',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    sek: {
        priority: 100,
        isoCode: 'SEK',
        isoNo: '752',
        name: 'Swedish Krona',
        symbol: 'kr',
        subunit: 'Öre',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    sgd: {
        priority: 100,
        isoCode: 'SGD',
        isoNo: '702',
        name: 'Singapore Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    shp: {
        priority: 100,
        isoCode: 'SHP',
        isoNo: '654',
        name: 'Saint Helenian Pound',
        symbol: '£',
        subunit: 'Penny',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    sll: {
        priority: 100,
        isoCode: 'SLL',
        isoNo: '694',
        name: 'Sierra Leonean Leone',
        symbol: 'Le',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    sos: {
        priority: 100,
        isoCode: 'SOS',
        isoNo: '706',
        name: 'Somali Shilling',
        symbol: 'Sh',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    srd: {
        priority: 100,
        isoCode: 'SRD',
        isoNo: '968',
        name: 'Surinamese Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ssp: {
        priority: 100,
        isoCode: 'SSP',
        isoNo: '728',
        name: 'South Sudanese Pound',
        symbol: '£',
        subunit: 'Piaster',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    std: {
        priority: 100,
        isoCode: 'STD',
        isoNo: '678',
        name: 'São Tomé and Príncipe Dobra',
        symbol: 'Db',
        subunit: 'Cêntimo',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    syp: {
        priority: 100,
        isoCode: 'SYP',
        isoNo: '760',
        name: 'Syrian Pound',
        symbol: '£ or ل.س',
        subunit: 'Piastre',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x00A3;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    szl: {
        priority: 100,
        isoCode: 'SZL',
        isoNo: '748',
        name: 'Swazi Lilangeni',
        symbol: 'L',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    thb: {
        priority: 100,
        isoCode: 'THB',
        isoNo: '764',
        name: 'Thai Baht',
        symbol: '฿',
        subunit: 'Satang',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x0E3F;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    tjs: {
        priority: 100,
        isoCode: 'TJS',
        isoNo: '972',
        name: 'Tajikistani Somoni',
        symbol: 'ЅМ',
        subunit: 'Diram',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    tmt: {
        priority: 100,
        isoCode: 'TMT',
        isoNo: '934',
        name: 'Turkmenistani Manat',
        symbol: 'm',
        subunit: 'Tennesi',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    tnd: {
        priority: 100,
        isoCode: 'TND',
        isoNo: '788',
        name: 'Tunisian Dinar',
        symbol: 'د.ت',
        subunit: 'Millime',
        subunitToUnit: 1000,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    top: {
        priority: 100,
        isoCode: 'TOP',
        isoNo: '776',
        name: 'Tongan Paʻanga',
        symbol: 'T$',
        subunit: 'Seniti',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    try: {
        priority: 100,
        isoCode: 'TRY',
        isoNo: '949',
        name: 'Turkish New Lira',
        symbol: 'YTL',
        subunit: 'New kuruş',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ttd: {
        priority: 100,
        isoCode: 'TTD',
        isoNo: '780',
        name: 'Trinidad and Tobago Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    twd: {
        priority: 100,
        isoCode: 'TWD',
        isoNo: '901',
        name: 'New Taiwan Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    tzs: {
        priority: 100,
        isoCode: 'TZS',
        isoNo: '834',
        name: 'Tanzanian Shilling',
        symbol: 'Sh',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    uah: {
        priority: 100,
        isoCode: 'UAH',
        isoNo: '980',
        name: 'Ukrainian Hryvnia',
        symbol: '₴',
        subunit: 'Kopiyka',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#x20B4',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    ugx: {
        priority: 100,
        isoCode: 'UGX',
        isoNo: '800',
        name: 'Ugandan Shilling',
        symbol: 'Sh',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    usd: {
        priority: 1,
        isoCode: 'USD',
        isoNo: '840',
        name: 'United States Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    uyu: {
        priority: 100,
        isoCode: 'UYU',
        isoNo: '858',
        name: 'Uruguayan Peso',
        symbol: '$',
        subunit: 'Centésimo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x20B1;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    uzs: {
        priority: 100,
        isoCode: 'UZS',
        isoNo: '860',
        name: 'Uzbekistani Som',
        subunit: 'Tiyin',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    vef: {
        priority: 100,
        isoCode: 'VEF',
        isoNo: '937',
        name: 'Venezuelan Bolívar',
        symbol: 'Bs F',
        subunit: 'Céntimo',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    vnd: {
        priority: 100,
        isoCode: 'VND',
        isoNo: '704',
        name: 'Vietnamese Đồng',
        symbol: '₫',
        subunit: 'Hào',
        subunitToUnit: 10,
        symbolFirst: true,
        htmlEntity: '&#x20AB;',
        decimalMark: ',',
        thousandsSeparator: '.'
    },
    vuv: {
        priority: 100,
        isoCode: 'VUV',
        isoNo: '548',
        name: 'Vanuatu Vatu',
        symbol: 'Vt',
        subunitToUnit: 1,
        symbolFirst: true,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    wst: {
        priority: 100,
        isoCode: 'WST',
        isoNo: '882',
        name: 'Samoan Tala',
        symbol: 'T',
        subunit: 'Sene',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    xaf: {
        priority: 100,
        isoCode: 'XAF',
        isoNo: '950',
        name: 'Central African Cfa Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    xcd: {
        priority: 100,
        isoCode: 'XCD',
        isoNo: '951',
        name: 'East Caribbean Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    xof: {
        priority: 100,
        isoCode: 'XOF',
        isoNo: '952',
        name: 'West African Cfa Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    xpf: {
        priority: 100,
        isoCode: 'XPF',
        isoNo: '953',
        name: 'Cfp Franc',
        symbol: 'Fr',
        subunit: 'Centime',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    yer: {
        priority: 100,
        isoCode: 'YER',
        isoNo: '886',
        name: 'Yemeni Rial',
        symbol: '﷼',
        subunit: 'Fils',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '&#xFDFC;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    zar: {
        priority: 100,
        isoCode: 'ZAR',
        isoNo: '710',
        name: 'South African Rand',
        symbol: 'R',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '&#x0052;',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    zmk: {
        priority: 100,
        isoCode: 'ZMW',
        isoNo: '894',
        name: 'Zambian Kwacha',
        symbol: 'ZK',
        subunit: 'Ngwee',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    zmw: {
        priority: 100,
        isoCode: 'ZMW',
        isoNo: '967',
        name: 'Zambian Kwacha',
        symbol: 'ZK',
        subunit: 'Ngwee',
        subunitToUnit: 100,
        symbolFirst: false,
        htmlEntity: '',
        decimalMark: '.',
        thousandsSeparator: ','
    },
    zwl: {
        priority: 100,
        isoCode: 'ZWL',
        isoNo: '932',
        name: 'Zimbabwean Dollar',
        symbol: '$',
        subunit: 'Cent',
        subunitToUnit: 100,
        symbolFirst: true,
        htmlEntity: '$',
        decimalMark: '.',
        thousandsSeparator: ','
    }
};
