import { Component, OnInit } from '@angular/core';
import { FraudRuleset } from '@interfaces/fraud-ruleset';
import { Theme } from '@interfaces/theme';
import { Nets } from '@models/acquirers/acquirer';
import { ACQUIRERDATA } from '@models/acquirers/acquirerdata.constant';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { CacheService } from '@services/cache/cache.service';
import { CountryService } from '@services/country/country.service';
import { HomepageService } from '@services/homepage/homepage.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { ThemingService } from '@services/theming/theming.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { AcquirerRepo } from 'app/endpoints/api/acquirer-repo';
import { FraudRulesetRepo } from 'app/endpoints/api/fraud-ruleset-repo';

@Component({
    selector: 'qp-fraudfilter',
    templateUrl: 'fraudfilter.html',
    styleUrls: ['../settings.scss'],
    animations: [Animations.getFadeAnimation()]
})
export class FraudfilterComponent extends BaseLoadingDirective implements OnInit {
    public acquirer: Nets;
    public parseInt = parseInt;
    public psd2Active = false;
    public rules: FraudRuleset[];
    public theme: Theme;
    public helpdeskLink: string;
    public dark_mode: boolean;

    constructor(
        private acquirerRepo: AcquirerRepo,
        private acquirerService: AcquirerService,
        private cacheService: CacheService,
        private countryService: CountryService,
        private fraudRulesetRepo: FraudRulesetRepo,
        private homepageService: HomepageService,
        private snackBar: QpSnackBar,
        private themingService: ThemingService
    ) {
        super();
        this.rules = [];
        this.theme = this.themingService.theme;
        this.dark_mode = !!this.cacheService.get('dark_theme', 'mode', 'mode');
    }

    public ngOnInit(): void {
        if (this.acquirerService.acquirers) {
            this.acquirer = Object.assign({}, this.acquirerService.acquirers['nets']);
        }
        this.helpdeskLink = this.homepageService.getHelpdeskLink('fraud-filter/');
        this.getRuleset();
    }

    public getRuleset(): void {
        this.fraudRulesetRepo.getAll().subscribe((rules: FraudRuleset[]) => {
            this.rules = rules;
            this.checkPSD2RulesetAdded();
        });
    }

    public checkPSD2RulesetAdded(): void {
        let found = '';
        for (const rule of this.rules) {
            if (!rule.name) {
                continue;
            }
            switch (rule.name.slice(0, 11)) {
                case 'PSD2 - paym':
                    found += 'a';
                    break;
                case 'PSD2 - subs':
                    found += 'b';
                    break;
                case 'PSD2 - card':
                    found += 'c';
                    break;
                default:
                    continue;
            }
        }

        if (found.indexOf('a') > -1 && found.indexOf('b') > -1 && found.indexOf('c') > -1) {
            this.psd2Active = true;
        }
    }

    public readableActionName(action: string): string {
        switch (action) {
            case 'block':
                return $localize`Block`;
            case 'flag':
                return $localize`Flag`;
            case 'force_3d':
                return $localize`Enforce 3-D Secure`;
            case 'force_sca':
                return $localize`Enforce 3-D Secure challenge`;
            case 'nothing':
                return $localize`Nothing`;
            default:
                return action;
        }
    }

    public readableMetricName(metric: string): string {
        switch (metric) {
            case 'amount_eur':
                return $localize`Amount (EUR)`;
            case 'card_country_alpha3':
                return $localize`Card country`;
            case 'facilitator':
                return $localize`Facilitator`;
            case 'fraudfilter_score':
                return $localize`Fraud score`;
            case 'request_ip_country_alpha3':
                return $localize`Request IP country`;
            case 'transaction_type':
                return $localize`Transaction type`;
            case 'acquirer_id':
                return $localize`Acquirer`;
            default:
                return metric;
        }
    }

    public readableCountryLenght(filter: string): string {
        let value;

        try {
            value = JSON.parse(filter);
        } catch (e) {
            value = filter.split(',');
        }

        const count = value.length;

        if (count > 3) {
            value.length = 3;
            value.push('+ ' + (count - 3) + ' more');
            return JSON.stringify(value).split(',').join(', ');
        }

        return filter;
    }

    public readableAcquirerName(acquire: string): string {
        const val = acquire.replace(/["\[\] ]/g, '').split(',');
        let readable = '[';

        val.forEach((id: string) => {
            Object.keys(ACQUIRERDATA).forEach((key: string) => {
                if (ACQUIRERDATA[key].id === id) {
                    readable += '"' + ACQUIRERDATA[key].title + '", ';
                }
            });
        });

        readable = readable.slice(0, -2);
        readable += ']';

        return readable;
    }

    public readableCombinator(combinator: string): string {
        if (combinator === 'and') {
            return $localize`and`;
        }

        if (combinator === 'or') {
            return $localize`or`;
        }

        return combinator;
    }

    public enableSecureByNets(): void {
        this.acquirer.secured_by_nets = true;

        this.acquirerRepo.update<Nets>('nets', this.acquirer).subscribe(updatedAcquirer => {
            if (!this.acquirerService.acquirers) {
                return;
            }
            this.acquirerService.acquirers['nets'] = updatedAcquirer;
            this.acquirer = updatedAcquirer;
            this.snackBar.open($localize`Secured by Nets enabled`);
        });
    }

    public addPSD2Ruleset(): void {
        const names: string[] = ['PSD2 - payments', 'PSD2 - subscriptions', 'PSD2 - cards'];
        const rulesetsToSave: Array<FraudRuleset> = [];

        this.rules.forEach(rule => {
            const index = names.indexOf(rule.name ?? '');
            if (index !== -1) {
                names.splice(index, 1);
            }
        });

        names.forEach(name => {
            const ruleset = {
                name,
                combinator: 'and',
                filter_rules: [
                    {
                        metric_name: 'card_country_alpha3',
                        operator: 'in',
                        value: JSON.parse(JSON.stringify(this.countryService.getPSD2Countries())).join(',')
                    }
                ],
                action: 'force_3d'
            };
            if (ruleset.name === 'PSD2 - payments') {
                ruleset.filter_rules.push({
                    metric_name: 'transaction_type',
                    operator: 'in',
                    value: ['Payment'].join(',')
                });
                ruleset.filter_rules.push({
                    metric_name: 'amount_eur',
                    operator: '>',
                    value: '3000'
                });
            }
            if (ruleset.name === 'PSD2 - subscriptions') {
                ruleset.filter_rules.push({
                    metric_name: 'transaction_type',
                    operator: 'in',
                    value: ['Subscription'].join(',')
                });
            }
            if (ruleset.name === 'PSD2 - cards') {
                ruleset.filter_rules.push({
                    metric_name: 'transaction_type',
                    operator: 'in',
                    value: ['Card'].join(',')
                });
            }
            rulesetsToSave.push(ruleset);
        });

        this.saveRuleset(rulesetsToSave);
    }

    public saveRuleset(rulesetsToSave: Array<FraudRuleset>): void {
        let count = 0;
        for (const rule of rulesetsToSave) {
            this.fraudRulesetRepo.create(rule).subscribe(() => {
                if (count < rulesetsToSave.length - 1) {
                    count++;
                } else {
                    this.snackBar.open($localize`PSD2 Rulesets added`);
                    this.getRuleset();
                }
            });
        }
    }
}
