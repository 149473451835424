import { ACQUIRERDATA, AcquirerData } from '@models/acquirers/acquirerdata.constant';
import { Acquirers } from '@models/acquirers/acquirers';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Component, OnInit } from '@angular/core';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { EMPTY } from 'rxjs';
import { exhaustMap, map, filter } from 'rxjs/operators';
import { Animations } from 'app/animations/animations';
import { FraudRuleset } from '@interfaces/fraud-ruleset';
import { FraudRulesetRepo } from 'app/endpoints/api/fraud-ruleset-repo';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Agreement } from '@interfaces/agreement';
import { AgreementService } from '@services/agreement/agreement.service';

@Component({
    selector: 'qp-ruleset',
    styleUrls: ['./ruleset.scss'],
    templateUrl: 'ruleset.html',
    animations: [Animations.getFadeAnimation()]
})
export class RulesetComponent extends BaseLoadingDirective implements OnInit {
    public activeAcquirers: AcquirerData[];
    public countries: Country[];
    public currency: string;
    public inActiveAcquirers: AcquirerData[];
    public ruleset: FraudRuleset;
    public agreement?: Agreement;

    constructor(
        private acquirerService: AcquirerService,
        private countryService: CountryService,
        private dialogService: DialogService,
        private fraudRulesetRepo: FraudRulesetRepo,
        private snackBar: QpSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private agreementService: AgreementService
    ) {
        super();
        this.currency = 'EUR';
        this.countries = [];
        this.activeAcquirers = [];
        this.inActiveAcquirers = [];
        this.ruleset = {
            combinator: 'and',
            action: '',
            name: '',
            filter_rules: [{}]
        };
    }

    public ngOnInit(): void {
        this.agreement = this.agreementService.agreement;
        this.countries = this.countryService.getCountries();
        this.sortAcquierers();
        this.getRule();
    }

    public sortAcquierers(): void {
        const acquirers = this.acquirerService.acquirers;

        if (!acquirers) {
            return;
        }

        Object.keys(acquirers).forEach((key: string) => {
            const acquirer = acquirers[key as keyof Acquirers];

            Object.keys(ACQUIRERDATA).forEach((acquirID: string) => {
                if (key === acquirID && ACQUIRERDATA[acquirID].id) {
                    if (acquirer.active) {
                        this.activeAcquirers.push(ACQUIRERDATA[acquirID]);
                    } else {
                        this.inActiveAcquirers.push(ACQUIRERDATA[acquirID]);
                    }
                }
            });
        });
    }

    public getRule(): void {
        if (this.activatedRoute.snapshot.params.id) {
            this.fraudRulesetRepo.get(this.activatedRoute.snapshot.params.id).subscribe((ruleset: FraudRuleset) => {
                this.ruleset = ruleset;
                this.afterGet();
            });
        }
    }

    public afterGet(): void {
        if (!this.ruleset.filter_rules) {
            return;
        }
        for (const rule of this.ruleset.filter_rules) {
            if (
                rule.metric_name === 'card_country_alpha3' ||
                rule.metric_name === 'request_ip_country_alpha3' ||
                rule.metric_name === 'facilitator' ||
                rule.metric_name === 'transaction_type' ||
                rule.metric_name === 'acquirer_id'
            ) {
                rule.value = rule.value.toString().replace(/["\[\] ]/g, '').split(',');
            }
        }
    }

    public addRule(): void {
        if (!this.ruleset.filter_rules) {
            return;
        }
        this.ruleset.filter_rules.push({});
    }

    public destroy(): void {
        this.dialogService.confirm(
            $localize`Delete ${this.ruleset.name}`,
            $localize`Sure you want to delete this rule?`,
            $localize`Delete`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            exhaustMap(() =>
                this.fraudRulesetRepo.destroy(this.ruleset.id ?? NaN).pipe(
                    map(() => {
                        this.snackBar.open($localize`Ruleset deleted`);
                        this.router.navigate(['../../../fraudfilter'], { relativeTo: this.activatedRoute });
                    })
                )
            )
        ).subscribe();
    }

    public cancel(): void {
        this.router.navigate(['../../../fraudfilter'], { relativeTo: this.activatedRoute });
    }

    public removeRule(index: number): void {
        this.dialogService.confirm(
            $localize`Remove rule`,
            $localize`Sure you want to remove the rule?`,
            $localize`Remove`
        ).afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed && this.ruleset.filter_rules?.length !== 1) {
                this.snackBar.open($localize`Rule removed`);
                this.ruleset.filter_rules?.splice(index, 1);
            }
        });
    }

    public changeMetric(rule: any): void {
        rule.operator = undefined;
        rule.value = undefined;
    }

    public selectAllPSD2Countries(event: any, index: number): void {
        let selected: string[];

        if (this.ruleset.filter_rules && this.ruleset.filter_rules[index].value) {
            selected = JSON.parse(JSON.stringify(this.ruleset.filter_rules[index].value));
            this.ruleset.filter_rules[index].value = [];

            for (const country of this.countryService.getPSD2Countries()) {
                if (event.checked && !selected.includes(country)) {
                    selected.push(country);
                }
                if (!event.checked && selected.includes(country)) {
                    selected.splice(selected.indexOf(country), 1);
                }
            }

            selected.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
        } else {
            selected = JSON.parse(JSON.stringify(this.countryService.getPSD2Countries()));
        }

        if (this.ruleset.filter_rules) {
            this.ruleset.filter_rules[index].value = selected;
        }
    }

    public isAllPSD2CountriesSelected(index: number): boolean {
        const psdContries = JSON.parse(JSON.stringify(this.countryService.getPSD2Countries()));

        if (this.ruleset.filter_rules && JSON.stringify(psdContries) === JSON.stringify(this.ruleset.filter_rules[index].value)) {
            return true;
        }

        if (this.ruleset.filter_rules && this.ruleset.filter_rules[index].value) {
            this.ruleset.filter_rules[index].value.forEach((country: any) => {
                if (psdContries.includes(country)) {
                    psdContries.splice(psdContries.indexOf(country), 1);
                }
            });
        }

        if (psdContries.length === 0) {
            return true;
        }

        return false;
    }

    public save(): void {
        const objectToSave = JSON.parse(JSON.stringify(this.ruleset));
        let save = true;

        for (const rule of objectToSave.filter_rules) {
            if (Array.isArray(rule.value)) {
                rule.value = rule.value.join(',');
            }
        }

        this.fraudRulesetRepo.getAll().pipe(
            exhaustMap((rules: FraudRuleset[]) => {
                rules.forEach(element => {
                    if (element.name === this.ruleset.name && this.ruleset.id !== element.id) {
                        save = false;
                        this.dialogService.alert(
                            $localize`Ruleset name`,
                            $localize`You already have a rule-set called` +
                            ' "' +
                            this.ruleset.name +
                            '". ' +
                            $localize`Please change the name.`
                        );
                    }
                });

                if (save && this.ruleset.id) {
                    return this.fraudRulesetRepo.update(objectToSave);
                } else if (save) {
                    return this.fraudRulesetRepo.create(objectToSave);
                }

                return EMPTY;
            })
        ).subscribe(() => {
            if (save) {
                this.snackBar.open($localize`Ruleset saved`);
                this.router.navigate(['../../../fraudfilter'], { relativeTo: this.activatedRoute });
            }
        });
    }
}
