import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@pipes/pipes.module';
import { DialogModule } from '@widgets/dialog/dialog.module';
import { LayoutModule } from '@widgets/layout/layout.module';
import { ShopurlsModule } from '@widgets/shopurls/shopurls.module';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';
import { ApiModule } from 'app/endpoints/api/api.module';
import { EndpointsModule } from 'app/endpoints/endpoints.module';
import { ExporterModule } from 'app/exporter/exporter.module';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        DialogModule,
        LayoutModule,
        EndpointsModule,
        ExporterModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        ShopurlsModule,
        SpinnerButtonModule,
        RouterModule,
        NgOptimizedImage
    ]
})
export class LoginModule {}
