import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { KeySetPageChangeEvent } from './keyset-paginator-event.interface';
import { KeySetPaginatorOptions } from './keyset-paginator-options.interface';

@Component({
    selector: 'qp-keyset-paginator',
    standalone: true,
    imports: [CommonModule, MatPaginatorModule],
    templateUrl: './keyset-paginator.component.html',
    styleUrl: './keyset-paginator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeysetPaginatorComponent implements OnInit {
    @Input() public config: KeySetPaginatorOptions = {
        label: '',
        page_size: 10,
        sort_by: 'id',
        sort_dir: 'desc',
        page_key: '',
        page_size_options: [10, 100]
    };

    @Input() public data: any[] = [];

    @Output() public pageChange = new EventEmitter<KeySetPageChangeEvent>();

    public page = 1;

    constructor(public matPaginatorIntl: MatPaginatorIntl) {}

    public ngOnInit(): void {
        this.matPaginatorIntl.itemsPerPageLabel = !!this.config.label ? this.config.label : $localize`Items per page`;
    }

    public get paginator_length(): number {
        return this.data.length + 1 + (this.page - 1) * this.config.page_size;
    }

    public nextPage(): void {
        const last_element = this.data[this.data.length - 1];

        this.checkSortyByProp(last_element);

        const page_event = {
            sort_by: this.config.sort_by,
            page_key: last_element[this.config.sort_by],
            sort_dir: this.config.sort_dir,
            page_size: this.config.page_size,
            is_previous: false
        };

        this.config.page_key = page_event.page_key;

        this.pageChange.emit(page_event);
    };

    public previousPage(): void {
        const first_element = this.data[0];

        this.checkSortyByProp(first_element);

        this.config.sort_dir = this.config.sort_dir === 'asc' ? 'desc' : 'asc';

        const page_event = {
            sort_by: this.config.sort_by,
            page_key: first_element[this.config.sort_by],
            sort_dir: this.config.sort_dir,
            page_size: this.config.page_size,
            is_previous: true
        };

        this.config.page_key = page_event.page_key;

        this.pageChange.emit(page_event);

        this.config.sort_dir = this.config.sort_dir === 'asc' ? 'desc' : 'asc';
    }

    public samePage(): void {
        const page_event = {
            sort_by: this.config.sort_by,
            page_key: this.config.page_key,
            sort_dir: this.config.sort_dir,
            page_size: this.config.page_size,
            is_previous: false
        };
        this.pageChange.emit(page_event);
    }

    public onPageChange(event: PageEvent): void {
        this.page = event.pageIndex + 1;

        if (this.config.page_size != event.pageSize) {
            this.config.page_size = event.pageSize;
            this.samePage();
            return;
        }

        if (event.previousPageIndex && event.pageIndex < event.previousPageIndex) {
            this.previousPage();
        } else {
            this.nextPage();
        }
    }

    private checkSortyByProp(element: any): void {
        if (!Object.getOwnPropertyNames(element).includes(this.config.sort_by)) {
            throw new Error(`The given paginator data does not have a sort_by property named '${this.config.sort_by}'`);
        }
    }

}
