import { Component, OnInit } from '@angular/core';
import { Account } from '@interfaces/account';
import { ClearhausContract } from '@models/clearhaus-application/ch-contract';
import { Theme } from '@interfaces/theme';
import { ClearhausRepo } from 'app/endpoints/server/clearhaus-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { Fee } from '@models/fee';
import { FeeRepo } from 'app/endpoints/api/fee-repo';
import { HomepageService } from '@services/homepage/homepage.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { ThemingService } from '@services/theming/theming.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgreementService } from '@services/agreement/agreement.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qp-acquirer-fee',
    styleUrls: ['./fee.scss'],
    templateUrl: 'fee.html',
    animations: [Animations.getFadeAnimation()]
})
export class AcquirerFeeComponent extends BaseLoadingDirective implements OnInit {
    public account: Account | undefined;
    public acquirer: string;
    public fees: Fee[];
    public imageName: string;
    public isMobile: boolean;
    public title: string;
    public objectKeys = Object.keys;
    public theme: Theme = this.themingService.theme;
    public helpdeskLink: string;
    public clerhausContract: ClearhausContract;

    public brands: { [key: string]: string } = {
        'apple-pay': 'assets/images/acquirers/applepay-icon.svg',
        'google-pay': 'assets/images/acquirers/googlepay-icon.svg',
        visa: 'assets/images/cards/visa-icon.svg',
        'visa-dk': 'assets/images/cards/visa-icon.svg',
        'visa-electron': 'assets/images/cards/visa-icon.svg',
        'visa-electron-dk': 'assets/images/cards/visa-icon.svg',
        'visa-electron-business': 'assets/images/cards/visa-icon.svg',
        'visa-electron-dk-business': 'assets/images/cards/visa-icon.svg',
        'visa-dankort': 'assets/images/cards/dankort.png',
        'visa-business': 'assets/images/cards/visa-icon.svg',
        'visa-dk-business': 'assets/images/cards/visa-icon.svg',
        dankort: 'assets/images/cards/dankort.png',
        'dankort-business': 'assets/images/cards/dankort.png',
        diners: 'assets/images/cards/diners.png',
        forbrugsforening: 'assets/images/cards/forbrugsforeningen.png',
        jcb: 'assets/images/cards/jcb.svg',
        klarnapayments: 'assets/images/acquirers/klarnapayments.svg',
        mastercard: 'assets/images/cards/mastercard-icon.svg',
        'mastercard-dk': 'assets/images/cards/mastercard-icon.svg',
        'mastercard-business': 'assets/images/cards/mastercard-icon.svg',
        'mastercard-dk-business': 'assets/images/cards/mastercard-icon.svg',
        'mastercard-debet': 'assets/images/cards/mastercard-icon.svg',
        'mastercard-debet-dk': 'assets/images/cards/mastercard-icon.svg',
        'mastercard-debet-business': 'assets/images/cards/mastercard-icon.svg',
        'mastercard-debet-dk-business': 'assets/images/cards/mastercard-icon.svg',
        mobilepay: 'assets/images/acquirers/mobilepay-icon.svg',
        paypal: 'assets/images/acquirers/paypal.svg',
        viabill: 'assets/images/acquirers/viabill.png',
        sofort: 'assets/images/acquirers/sofort.png',
        'american-express': 'assets/images/cards/american-express.svg',
        'american-express-dk': 'assets/images/cards/american-express.svg',
        edankort: 'assets/images/cards/edankort.png',
        maestro: 'assets/images/cards/maestro-icon.svg',
        'maestro-dk': 'assets/images/cards/maestro-icon.svg',
        'maestro-business': 'assets/images/cards/maestro-icon.svg',
        'maestro-dk-business': 'assets/images/cards/maestro-icon.svg',
        fbg1886: 'assets/images/cards/forbrugsforeningen.png'
    };

    constructor(
        private clearhausRepo: ClearhausRepo,
        private dialogService: DialogService,
        private feeRepo: FeeRepo,
        private homepageService: HomepageService,
        private snackBar: QpSnackBar,
        private themingService: ThemingService,
        private activatedRoute: ActivatedRoute,
        private agreementService: AgreementService
    ) {
        super();
        this.theme = this.themingService.theme;
    }

    public ngOnInit(): void {
        this.account = this.agreementService.agreement?.account;
        const helpdeskLinkSubFix = this.agreementService.isDanishMerchant() ? 'transaction-fees/' : 'transaction-fees/';
        this.helpdeskLink = this.homepageService.getHelpdeskLink(helpdeskLinkSubFix);

        this.isMobile = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        this.acquirer = this.activatedRoute.snapshot.queryParams.acquirer;
        this.title = this.acquirer.charAt(0).toUpperCase() + this.acquirer.substring(1);

        switch (this.title) {
            case 'Teller':
                this.title = 'Nets (Teller)';
                break;
            case 'Mobilepay':
                this.title = 'MobilePay Online';
                break;
            case 'Paypal':
                this.title = 'PayPal';
                break;
            case 'Viabill':
                this.title = 'ViaBill';
                break;
            case 'Visa-mastercard':
                this.acquirer = 'clearhaus';
                this.title = 'Visa and Mastercard';
                break;

            default:
                this.title = this.title;
                break;
        }

        this.imageName = `${this.acquirer}.png`;
        if (this.acquirer === 'teller') {
            this.imageName = 'nets.png';
        }

        this.getFees();
        this.checkIfClearhausContractExist();
    }

    public checkIfClearhausContractExist(): void {
        this.clearhausRepo.getContract().pipe(
            map((contract: ClearhausContract) => {
                if (contract.metadata.state === 'signed') {
                    this.clerhausContract = contract;
                }
                return of();
            }),
            catchError(() => of())
        ).subscribe();
    }

    public getFees(): void {
        this.feeRepo.getByAcquirer(this.acquirer).subscribe((fees: Fee[]) => {
            this.fees = fees.sort((a: any, b: any) =>
                a.payment_method > b.payment_method ? 1 : b.payment_method > a.payment_method ? -1 : 0
            );
        });
    }

    public addFee(paymentMethod?: string, formula?: string): void {
        const fields: PromptField[] = [
            {
                placeholder: $localize`Payment method`,
                label: $localize`Payment method`,
                required: true,
                value: paymentMethod,
                type: 'text',
                name: 'payment_method'
            },
            {
                placeholder: $localize`Formula`,
                label: $localize`Formula`,
                required: true,
                value: formula,
                type: 'text',
                name: 'formula'
            }
        ];

        this.dialogService
            .prompt($localize`Create fee`, '', $localize`Create fee`, fields)
            .afterClosed()
            .subscribe((returnFields: PromptField[]) => {
                if (returnFields !== undefined) {
                    this.createFee(returnFields[0].value, returnFields[1].value);
                }
            });
    }

    public createFee(paymentMethod: string, formula: string): void {
        let create = true;
        for (const fee of this.fees) {
            if (fee.payment_method === paymentMethod.toLocaleLowerCase()) {
                create = false;
                this.dialogService
                    .alert($localize`Payment method already exists`, '')
                    .afterClosed()
                    .subscribe(() => {
                        this.addFee(paymentMethod, formula);
                    });
            }
        }
        if (create) {
            this.feeRepo.update(this.acquirer, paymentMethod.toLocaleLowerCase(), formula).subscribe(() => {
                this.getFees();
                this.snackBar.open($localize`Fee added`);
            });
        }
    }

    public save(fee: Fee): void {
        this.feeRepo.update(this.acquirer, fee.payment_method, fee.formula).subscribe((returnFee: Fee) => {
            const itemIndex = this.fees.indexOf(fee);
            if (itemIndex >= 0) {
                this.fees[itemIndex] = returnFee;
            }
            this.snackBar.open($localize`Updated the fee`);
        });
    }

    public useStandard(fee: Fee): void {
        this.feeRepo.reset(this.acquirer, fee.payment_method).subscribe(() => {
            this.getFees();
            this.snackBar.open($localize`Fee set as standard`);
        });
    }

    public selectImage(brand: string): string {
        const brand_new = brand.replace(/-\w{2}$/, '');

        if (this.acquirer && this.acquirer.toLowerCase() !== 'nets' && brand_new.toLowerCase() === 'visa-dankort') {
            return this.brands['visa'];
        }
        return this.brands[brand.toLowerCase()] || brand;
    }

    public showImage(brand: string): boolean {
        if (brand === undefined || brand === null) {
            return false;
        }
        const brand_new = brand.replace(/-\w{2}$/, '');
        return this.brands[brand_new.toLowerCase()] ? true : false;
    }
}
