<ng-container *ngIf="transaction && transaction.variables && objectKeys(transaction.variables).length">
    <form [formGroup]="paymentVariableForm">
    <mat-card class="card02 avoid-page-break">
        <mat-card-content class="flex-col rg16">
            <mat-card-title class="font-weight-normal" i18n>Payment variables</mat-card-title>
            <table class="card-table">
                <thead>
                    <tr>
                        <th class="text-left width-50percent" i18n>Variable</th>
                        <th class="text-left width-50percent" i18n>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let key of objectKeys(transaction.variables)">
                        <tr class="border-bottom">
                            <td>
                                <div *ngIf="!update">{{ key }}</div>
                                <mat-form-field *ngIf="update" class="width-95percent">
                                    <input matInput type="text" formControlName="variable{{key}}">
                                </mat-form-field>
                            </td>
                            <td>
                                <div *ngIf="!update" class="truncate truncate-250" title="{{ transaction.variables[key] }}">
                                    {{ transaction.variables[key] }}
                                </div>
                                <mat-form-field *ngIf="update"  class="width-95percent">
                                    <input matInput type="text" formControlName="value{{key}}">
                                </mat-form-field>
                            </td>
                            <td *ngIf="update">
                                <button mat-icon-button i18n-matTooltip matTooltip="Delete" (click)="deleteVariable(key)">
                                    <mat-icon class="mat-18 text-red">delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div class="flex-row cg8">
                <button mat-raised-button *ngIf="!update" (click)="updateVariables()" i18n>Edit variables</button>
                <button mat-raised-button *ngIf="update" (click)="saveChanges()" color="accent" i18n>Save changes</button>
                <button mat-raised-button *ngIf="update" (click)="discardChanges()" i18n> Discard changes</button>
            </div>
        </mat-card-content>
    </mat-card>
    </form>
</ng-container>
