<form class="form" role="form" name="terms">
    <div mat-dialog-content class="flex-col rg16 grey-border-bottom">
        <h5 i18n>Select billing method</h5>
        <span class="text-grey" i18n> The billing period will begin when you select the billing method </span>

        <div class="flex-row justify-start items-center">
            <mat-checkbox [checked]="accept" (change)="accept = !accept"></mat-checkbox>
            &nbsp;<span i18n>I have read and accepted the</span>&nbsp;
            <a href="https://quickpay.net/dk/terms-of-service" target="_blank" i18n>terms</a>
        </div>
        <span i18n> Please note that billing methods include an fee of DKK 50,- for each invoice as they are processed manually. </span>

        <mat-radio-group class="flex-col rg16 radio-btn-blue" [required]="true" name="paymentMethods">
            <div *ngFor="let paymentMethod of paymentMethods">
                <mat-radio-button
                    *ngIf="!(paymentMethod.type === 'supplier_service' && account?.customer_address?.country_code !== 'DNK')"
                    [value]="paymentMethod.type"
                    aria-label="{{ paymentMethod.name }}"
                    (change)="changePaymentMethod(paymentMethod.type)">
                        {{ paymentMethod.name }}
                </mat-radio-button>
            </div>
        </mat-radio-group>

        <mat-form-field [hideRequiredMarker]="true" class="flex-1 md-block inline-group payment-ean" *ngIf="customer.attributes.billing_info?.payment_method === 'ean'">
            <mat-label i18n>EAN number</mat-label>
            <input matInput #ean type="text" [required]="customer.attributes.billing_info?.payment_method === 'ean'" minlength="13" maxlength="13" name="ean_number" [(ngModel)]="customer.attributes.ean_number" />
            <mat-hint i18n> EAN number must be 13 characters {{ ean.value.length }} / 13 </mat-hint>
        </mat-form-field>

    </div>

    <div mat-dialog-actions class="flex-row">
        <span class="flex-1"></span>
        <button mat-button (click)="dialogRef.close(false)" i18n>Close</button>
        <button mat-button type="submit" color="accent" [disabled]="!accept" (click)="acceptTerms()" i18n>Select method</button>
    </div>
</form>
