import { Component, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { AgreementRepo } from '@endpoints/api/agreement-repo';
import { Agreement } from '@interfaces/agreement';
import { SessionService } from '@services/session/session.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { ThemingService } from '@services/theming/theming.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { MeRepo } from 'app/endpoints/api/me-repo';
import { PasswordStrengthBarComponent } from '../password-strength-bar/password-strength-bar.component';

@Component({
    selector: 'qp-set-password',
    templateUrl: 'set-password.component.html',
    styleUrls: ['../login-layout.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PasswordStrengthBarComponent,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule
    ]
})
export class SetPasswordComponent implements OnInit {
    public headerText: string;
    public pswButtonText: string;
    public snackMessage: string;
    public activateUser: boolean;
    public password: string;
    public confirmPassword: string;
    public passwordSet: boolean;
    public activate_user_form: UntypedFormGroup;
    public loginForm: UntypedFormGroup;
    public loginIn: boolean;
    public hostname: string;
    public showErrorIncorrect = false;
    public showErrorLocked = false;
    public hide = true;
    public step_valid: boolean;
    public email: string;
    public theme = this.themingService.theme;

    constructor(
        private dialogService: DialogService,
        private meRepo: MeRepo,
        private sessionService: SessionService,
        private snackBar: QpSnackBar,
        private themingService: ThemingService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private agreementRepo: AgreementRepo
    ) {
        this.hostname = this.themingService.brand_name;
    }

    public ngOnInit(): void {
        if (window.location.href.indexOf('reset-password') > -1) {
            this.headerText = $localize`Choose a new password`;
            this.pswButtonText = $localize`Reset password`;
            this.snackMessage = $localize`Password reset! Please login.`;
            this.activateUser = false;
        }

        if (window.location.href.indexOf('activate') > -1) {
            this.headerText = $localize`Your email has been verified!`;
            this.pswButtonText = $localize`Complete registration`;
            this.snackMessage = $localize`User activated! Please login.`;
            this.activateUser = true;
        }

        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl({ value: '', disabled: false }, Validators.required),
            password: new UntypedFormControl({ value: '', disabled: false }, Validators.required)
        });

        this.activate_user_form = new UntypedFormGroup({
            password: new UntypedFormControl({ value: '', disabled: false }, [
                Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[0-9_-]).+$'),
                Validators.minLength(10)
            ]
            )
        });
    }

    public validateForm(): void {
        if (this.activate_user_form.invalid) {
            this.step_valid = false;
        } else {
            this.step_valid = true;
        }
    }

    public setForm(): void {
        this.password = this.activate_user_form.get('password')?.value;
    }

    public setPassword(password: string): void {
        if (this.activateUser) {
            this.activate(password);
        } else {
            this.resetPassword(password);
        }
    }

    public resetPassword(password: string): void {
        this.meRepo.resetPassword(this.activatedRoute.snapshot.params.token, password).subscribe(() => {
            this.snackBar.open(this.snackMessage);
            this.router.navigate(['/login']);
        });
    }

    public activate(password: string): void {
        this.meRepo.activate(this.activatedRoute.snapshot.params.token, password).subscribe(() => {
            this.passwordSet = true;
        });
        catchError((err) => {
            if (err.error.message === 'Validation error') {
                this.dialogService.confirm(
                    $localize`The user is already activated.`,
                    $localize`Please go to the log in page and reset your password`,
                    $localize`Go to log in`,
                    $localize`Close`
                ).afterClosed().pipe(
                    map((confirmed: boolean) => {
                        if (confirmed) {
                            this.router.navigate(['/login']);
                        }
                        return EMPTY;
                    })
                ).subscribe();
            }
            return EMPTY;
        });
    }

    public save(): void {
        this.validateForm();
        this.setForm();
        if (this.step_valid) {
            this.setPassword(this.password);
        } else {
            for (const i in this.activate_user_form.controls) {
                if (!!i) {
                    this.activate_user_form.controls[i].markAsTouched();
                }
            }
        }
    }

    public login(): void {
        const email = this.loginForm.get('email')?.value;
        const password = this.loginForm.get('password')?.value;
        this.loginIn = true;
        this.showErrorIncorrect = false;
        this.showErrorLocked = false;
        this.sessionService.login(email, password).pipe(
            catchError(error => {
                this.loginIn = false;
                this.password = '';
                if (error.error.message === 'Invalid email or password') {
                    this.showErrorIncorrect = true;
                } else if (error.error.message === 'User locked out') {
                    this.showErrorLocked = true;
                }
                throw new Error(error.error.message);
            }),
            switchMap(() => this.agreementRepo.getAll({ support: false, page_size: 2 }, undefined)),
            tap((agreements: Agreement[]) => {
                if (agreements.length === 1 && agreements[0].accepted) {
                    return this.router.navigate(['./account', agreements[0].account?.id]);
                }
                return this.router.navigate(['./my-user']);
            })
        ).subscribe();
    }
}
