import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-capture-payment-dialog',
    templateUrl: 'dialog.html'
})
export class TransactionFraudReportDialogComponent {
    public description: string;
    constructor(
        public dialogRef: MatDialogRef<TransactionFraudReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) {}

    public onCreateReport(): void {
        this.dialogRef.close({ description: this.description });
    }
}
