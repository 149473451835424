import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CacheService } from '@services/cache/cache.service';

@Injectable({
    providedIn: 'root'
})
export class ColumnsService {
    public columns: BehaviorSubject<any>;

    constructor(
        private cacheService: CacheService
    ) {
        if (!this.columns) {
            this.columns = new BehaviorSubject(this.getDefault());
        }
    }

    public formatColumns(type: string, columns: Array<string>): Array<string> {
        const returnValue: Array<string> = [];
        for (const value of columns) {
            if (this.columns.value[type][value]) {
                if (this.columns.value[type][value].active) {
                    returnValue.push(value);
                }
            } else {
                returnValue.push(value);
            }
        }
        return returnValue;
    }

    public getDateFormats(): { [key: string]: any } {
        return this.cacheService.getDefaultValue('dateFormats');
    }

    public getDefault(): { [key: string]: any } {
        if (this.cacheService.getFromLocalCache('columns')) {
            return Object.assign({}, this.cacheService.getFromLocalCache('columns'));
        }
        return this.cacheService.getDefaultValue('columns');
    }

    public getColumnsAsObservable(): any {
        return this.columns;
    }

    public getColumns(type: string): { [key: string]: any } {
        return this.columns.value[type];
    }

    public setColumns(type: string, data: any): void {
        const columns = this.columns.value;
        columns[type] = data;
        this.cacheService.putToLocalCache('columns', columns);
        this.columns.next(columns);
    }
}
