<form class="flex-row" [formGroup]="formGroup">
    <mat-form-field class="flex-20">
        <mat-select class="country-code" aria-label="Country Code" formControlName="countryCode">
            <mat-option *ngFor="let code of countryCodes" [value]="code.code">{{code.code}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-1">
        <mat-label i18n>phone</mat-label>
        <input matInput class="flex-1" type="text" name="phone" formControlName="phoneNumber" />
    </mat-form-field>
</form>
