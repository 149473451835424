import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchParams } from '@interfaces/params';
import { Operation } from '@models/operation';
import { Payment } from '@models/payment';
import { Transaction } from '@models/transaction';
import { ColumnsService } from '@services/columns/columns.service';
import { TransactionService } from '@services/transaction/transaction.service';
import { DialogService } from '@widgets/dialog/dialog.service';

@Component({
    selector: 'qp-transaction-table',
    templateUrl: './transaction-table.html',
    styleUrls: ['./transaction-table.scss']
})
export class TransactionTableComponent<T extends Transaction = Transaction> implements OnInit, OnDestroy {
    @ViewChild(MatSort) public sort: MatSort;

    @Output() public getPage = new EventEmitter<{
        sort_dir: SortDirection;
        page: number | undefined;
        sort_by: string;
    }>();
    @Output() public selectionChange = new EventEmitter();

    @Input() public data_source: MatTableDataSource<T>;
    @Input() public search_params: SearchParams;
    @Input() public table_type: string;
    @Input() public columns: Array<string> = [];
    @Input() public get selection(): SelectionModel<T> {
        return this.selection_value;
    }

    public set selection(val: SelectionModel<T>) {
        this.selection_value = val;
        this.selectionChange.emit(this.selection_value);
    }

    public get is_all_selected(): boolean {
        return this.selection.selected.length === this.data_source.data.length;
    };
    public get hide_state(): boolean {
        return !!this.search_params.states && !['all'].includes(this.search_params.states[this.search_params.state]);
    }
    public get hide_balance(): boolean {
        return !!this.search_params.states && ['initial', 'new', 'rejected'].includes(this.search_params.states[this.search_params.state]);
    }

    public selection_value: SelectionModel<T>;
    public display_columns: string[];
    public created_at_format: string;
    public result_row: { id: number | undefined; success: boolean } = { id: undefined, success: false };
    // temporary variables untill #8219 is resolved and transaction components have been refactored.
    @ViewChild('payment_details') public payment_details: TemplateRef<any>;
    public dialog_ref: MatDialogRef<any>;
    public clicked_payment: Payment;

    constructor(
        public columnsService: ColumnsService,
        public transactionService: TransactionService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService
    ) {}

    public ngOnInit(): void {
        this.columnsService.getColumnsAsObservable().subscribe(() => {
            this.display_columns = this.columnsService.formatColumns(this.table_type, this.columns);
            this.created_at_format = this.columnsService.getColumns(this.table_type)['created_at'] === undefined
                ? 'MMM dd, yyyy'
                : this.columnsService.getDateFormats()[this.columnsService.getColumns(this.table_type)['created_at'].format].format;
        });
    }

    public ngOnDestroy(): void {
        if (this.dialog_ref) {
            this.dialog_ref.close();
        };
    }

    public changeSortParams(): void {
        this.getPage.emit({
            sort_dir: this.sort.direction,
            sort_by: this.sort.active,
            page: undefined
        });
    }

    public masterToggle(): void {
        if (this.is_all_selected) {
            this.selection.clear();
        } else {
            this.data_source.data.forEach((row: any) => this.selection.select(row));
        }
    }

    public selectRow(row: T): void {
        if (this.isRowSelected(row)) {
            this.selection.deselect(row);
        } else {
            this.selection.select(row);
        }
    }

    public isRowSelected(row: T): boolean {
        for (const item of this.selection.selected) {
            if (item.id === row.id) {
                return true;
            }
        }
        return false;
    }

    public goToDetails(transaction: Transaction): { [key: string]: number } {
        switch (this.table_type) {
            case 'cards':
                this.router.navigate(['../card', transaction.id], { relativeTo: this.activatedRoute });
                return { card_id: transaction.id ?? NaN };
            case 'payments':
                this.clicked_payment = transaction as Payment;
                this.openPaymentDetailsDialog(this.payment_details);
                return { payment_id: transaction.id ?? NaN };
            case 'payouts':
                this.router.navigate(['../payout', transaction.id], { relativeTo: this.activatedRoute });
                return { payout_id: transaction.id ?? NaN };
            case 'subscriptions':
                this.router.navigate(['../subscription', transaction.id], { relativeTo: this.activatedRoute });
                return { subscription_id: transaction.id ?? NaN };
            default:
                return {};
        }
    }

    public openPaymentDetailsDialog(template: TemplateRef<any>): void {
        this.dialog_ref = this.dialogService.template(
            $localize`Payment details`,
            template,
            { width: '90vw', height: '90vh' }
        );
    }

    public latestTransactionPendingAmount(transaction: T): number {
        return this.transactionService.latestPendingAmount(transaction);
    }

    public latestTransactionFailedAmount(transaction: T): number {
        return this.transactionService.latestFailedAmount(transaction);
    }

    public transactionLinkAmount(transaction: T): number {
        return transaction.link ? transaction.link.amount : 0;
    }

    public getTransactionAuthAmount(transaction: T): number {
        return this.transactionService.getAuthAmount(transaction);
    }

    public transactionLastOperation(transaction: T): Operation | null {
        if (transaction.operations) {
            return transaction.operations.length > 0 ? transaction.operations[transaction.operations.length - 1] : null;
        }
        return null;
    }

    public cardExpired(year: number, month: number): boolean {
        return year === null || year === undefined
            ? false
            : year < new Date().getFullYear() || (year === new Date().getFullYear() && month < new Date().getMonth());
    }

    public transactionChange(event: { transaction: T; operation_type: string }): void {
        const transactions = this.data_source.data;
        transactions[
            transactions.findIndex(transaction => transaction.id === event.transaction.id)
        ] = event.transaction;
        this.data_source.data = transactions;

        this.updateResultRow(event.transaction, event.operation_type);
    }

    public updateResultRow(transaction: Transaction, operation_type: string): void {
        let success = false;

        if (transaction?.operations) {
            for (let i = transaction.operations.length - 1; i >= 0; i--) {
                const operation = transaction.operations[i];
                if (operation.type === operation_type && operation.qp_status_code === '20000') {
                    success = true;
                    break;
                }
            }
        }

        this.result_row = { id: transaction.id, success };
    }
}
