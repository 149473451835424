export const SHIPMONDO_PERMISSIONS = [
    {
        resource: '/account',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/capture',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/refund',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/cancel',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    }
];
