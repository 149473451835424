import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'qp-payment-link-dialog',
    templateUrl: 'payment-link-dialog.html'
})
export class PaymentLinkDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<PaymentLinkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
}
