import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PaymentWindowData {
    url: string;
}

@Component({
    selector: 'qp-payment-window',
    styleUrls: ['payment-window.scss'],
    templateUrl: './payment-window.component.html'
})
export class PaymentWindowComponent {
    constructor(
        public dialogRef: MatDialogRef<PaymentWindowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PaymentWindowData
    ) {}

    public close(): void {
        this.dialogRef.close();
    }
}
