import { Client } from '../client';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class PaypalApiClient extends HttpClient {
    public abstract setHeader(name: string, value: string): any;
    public abstract deleteHeader(name: string): any;
}

/* eslint-disable */
export function paypalApiClientFactory(httpClient: HttpClient): Client {
    return new Client(httpClient, environment.onboardingPaypalPaymentsUrl, {
        headers: new HttpHeaders({
            'Accept-Version': 'v10',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
        })
    });
}
