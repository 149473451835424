<div class="wide-content" [@fade_animation]="!loading ? 'fadeIn' : null" *ngIf="subscription && !loading">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../../subscriptions']" [queryParams]="{ page_size: 10 }" *ngIf="subscription.id && !payment_id" class="mat-button-back" color="accent">&lt; <span i18n>Back to subscriptions</span></button>
        <button mat-button [routerLink]="['../../payments']" [queryParams]="{ page_size: 10 }" *ngIf="!subscription.id && payment_id" class="mat-button-back" color="accent">&lt; <span i18n>Back to payments</span></button>
        <button mat-button [routerLink]="['../../payment', payment_id]" *ngIf="subscription.id && payment_id" class="mat-button-back" color="accent">&lt; <span i18n>Back to payment</span> {{ '-' }} {{ payment_id }}</button>
        <span class="flex-1"></span>

        <div class="flex-row cg8">
            <mat-spinner *ngIf="show_spinner" [diameter]="30"></mat-spinner>
            <mat-menu #subscriptionMenu="matMenu" class="no-print">
                <button mat-menu-item [routerLink]="['../../tools/auditlog']" [queryParams]="{ target_type: 'Subscription', target_id: subscription.id }" i18n>Audit log</button>
                <button mat-menu-item (click)="showJSON()" i18n>json</button>
                <button mat-menu-item (click)="onPrint()" i18n>Print</button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="subscriptionMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <span></span>
        </div>
    </div>

    <div class="flex-row-mobile-col">
        <div class="flex-35-mobile-100" *ngIf="subscription.state !== 'initial' || subscription.acquirer">
            <mat-card class="card02" *ngIf="!subscription.retented_at">
                <mat-card-content>
                    <ng-container *ngIf="subscription?.metadata?.type === 'card'">
                        <qp-transaction-details-card-basic (callBack)="setPaginatorData()" [permission]="(['/subscriptions/:id/fraud-report', 'get'] | qpPermission)" [transactionRepo]="subscriptionRepo" [transaction]="subscription"> </qp-transaction-details-card-basic>
                    </ng-container>
                    <ng-container *ngIf="subscription?.metadata?.type !== 'card'">
                        <qp-transaction-details-other-basic (callBack)="setPaginatorData()" [permission]="(['/subscriptions/:id/fraud-report', 'get'] | qpPermission)" [transactionRepo]="subscriptionRepo" [transaction]="subscription"> </qp-transaction-details-other-basic>
                    </ng-container>
                </mat-card-content>
            </mat-card>
            <qp-transaction-details-retended [transaction]="subscription"></qp-transaction-details-retended>
            <qp-transaction-details-acquirer [transaction]="subscription"></qp-transaction-details-acquirer>
            <mat-card class="card02" *ngFor="let group of groups">
                <mat-card-content class="flex-col rg8">
                    <div class="flex-row">
                        <span class="subtitle1 flex-50" i18n>Group</span>
                        <span class="flex-1"> {{ group.name | titlecase }} </span>
                    </div>
                    <div class="flex-row">
                        <span class="flex-50"> {{ group.description }} </span>
                        <span class="flex-1"> {{ group.currency }} {{ group.amount | qpCurrency: group.currency }} </span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-1">
            <mat-card class="card02 avoid-page-break">
                <mat-card-content class="flex-col rg16">
                    <div class="flex-col">
                        <mat-card-title class="font-weight-normal" i18n>Subscription created</mat-card-title>

                        <div class="flex-row space-between items-center print">
                            <div class="flex-col">
                                <span class="subtitle1 hide-lt-sm" i18n>Created at</span>
                                <span class="subtitle1 hide-gt-xs" i18n>Created</span>
                                <span class="hide-lt-sm">{{ subscription.created_at | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                                <span class="hide-gt-xs">{{ subscription.created_at | date:'dd/MM yyyy HH:mm' }}</span>
                            </div>

                            <div class="flex-col">
                                <span class="subtitle1 hide-lt-sm">Subscription ID</span>
                                <span class="subtitle1 hide-gt-xs">Sub. ID</span>
                                <span>{{ subscription.id }}</span>
                            </div>

                            <div class="flex-col">
                                <span class="subtitle1 hide-lt-sm" i18n>Order ID</span>
                                <span class="subtitle1 hide-gt-xs" i18n>Order</span>
                                <span>{{ subscription.order_id }}</span>
                            </div>

                            <div class="flex-col">
                                <span class="subtitle1" i18n>Currency</span>
                                <span>{{ subscription.currency }}</span>
                            </div>

                            <div class="flex-col hide-lt-sm">
                                <span class="subtitle1" i18n>Description</span>
                                <span>{{ subscription.description }}</span>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="subscription.operations">
                        <div *ngIf="subscription.operations.length >= 1">
                            <h5 class="font-weight-normal" i18n>Operations</h5>
                            <div class="avoid-page-break">
                                <qp-operations [currency]="subscription.currency" [transactionId]="subscription.id" [transactionState]="subscription.state" [operations]="subscription.operations" [acquirer]="subscription.acquirer" mat-card-image> </qp-operations>
                            </div>
                        </div>
                    </ng-container>

                    <div class="no-print">
                        <button mat-raised-button (click)="onCancel()" *ngIf="(['/subscriptions/:id/cancel', 'post'] | qpPermission) && is_cancelable" i18n>Cancel</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card [hidden]="is_loading_results" class="flex-col card02 avoid-page-break" *ngIf="subscription.state !== 'initial'">
                <mat-card-title class="flex-row maxheight-36">
                    <qp-transaction-states class="flex-1" [states]="search_params.states || []" [state]="search_params.state" (tabChange)="setState($event)"> </qp-transaction-states>
                    <span class="flex-1"></span>
                    <button mat-raised-button (click)="createRecurring()" [disabled]="isCancelledRejected()" i18n>Create recurring</button>
                </mat-card-title>
                <mat-card-content mat-card-image class="flex-col">
                    <table mat-table class="hide-lt-sm margin-negative" #table [dataSource]="data_source" matSort [matSortActive]="search_params.sort_by || ''" matSortDisableClear [matSortDirection]="search_params.sort_dir || ''" (matSortChange)="sortPayments($event)">
                        <div matColumnDef="payment_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                            <td mat-cell *matCellDef="let row">
                                <div class="flex-row justify-end items-center">{{ row.id }}</div>
                            </td>
                        </div>

                        <div matColumnDef="order_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Order ID</th>
                            <td mat-cell *matCellDef="let row">{{ row.order_id }}</td>
                        </div>

                        <div matColumnDef="created_at">
                            <th mat-header-cell *matHeaderCellDef i18n>Created</th>
                            <td mat-cell *matCellDef="let row">
                                <qp-date-field [format]="created_at_format" [date]="row.created_at"></qp-date-field>
                            </td>
                        </div>

                        <div matColumnDef="currency">
                            <th mat-header-cell *matHeaderCellDef i18n>Currency</th>
                            <td mat-cell *matCellDef="let row">
                                <div class="flex-row justify-end items-center">{{ row?.currency }}</div>
                            </td>
                        </div>

                        <div matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef i18n>Amount</th>
                            <td mat-cell *matCellDef="let row">
                                <div class="flex-row justify-end items-center" [ngSwitch]="row.state">
                                    <div *ngSwitchCase="'pending'">{{ latest_pending_amount | qpCurrency: row.currency }}</div>
                                    <div *ngSwitchCase="'rejected'">{{ latest_failed_amount | qpCurrency: row.currency }}</div>
                                    <div *ngSwitchDefault>{{ getAuthAmount(row) | qpCurrency: row?.currency }}</div>
                                </div>
                            </td>
                        </div>

                        <div matColumnDef="balance">
                            <th mat-header-cell *matHeaderCellDef i18n>Balance</th>
                            <td mat-cell *matCellDef="let row">
                                <div class="flex-row justify-end items-center" [class.hide]="hideBalance(row.state)">{{ row.balance | qpCurrency: row.currency }}</div>
                            </td>
                        </div>

                        <tr mat-header-row *matHeaderRowDef="display_columns"></tr>
                        <tr mat-row class="cursor" *matRowDef="let row; columns: display_columns;" [routerLink]="['../../payment', row.id]"></tr>
                    </table>

                    <mat-list dense class="hide-gt-xs">
                        <mat-divider></mat-divider>
                        <ng-container *ngFor="let payment of data_source.data; let last = last">
                            <mat-list-item class="flex-row" [routerLink]="['../../payment', payment.id]">
                                <span>{{ payment['order_id'] }}</span>
                                <span class="flex-1"></span>
                                <span class="text-grey font-weight-normal">
                                    <qp-date-field [date]="payment['created_at']"></qp-date-field>
                                </span>
                                <span class="flex-1"></span>
                                <span class="flex-35"> {{ payment['currency'] }} {{ getAuthAmount(payment) | qpCurrency: payment['currency'] }} </span>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </mat-list>

                    <qp-paginator [config]="paginator_options" [data_length]="data_source.data.length" (pageChange)="onPageChange($event)"> </qp-paginator>
                </mat-card-content>
            </mat-card>
            <qp-transaction-details-variables [transaction]="subscription"> </qp-transaction-details-variables>
            <qp-transaction-details-customer-info [transaction]="subscription" [is_delete_enabled]="true" (transactionChange)="updateSubscription($event)"> </qp-transaction-details-customer-info>
            <qp-transaction-details-shipping [transaction]="subscription"></qp-transaction-details-shipping>
            <qp-transaction-details-basket [transaction]="subscription"></qp-transaction-details-basket>
        </div>
    </div>
</div>
