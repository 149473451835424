<mat-toolbar color="primary">
    <span class="flex-1"></span>
    <button mat-icon-button (click)="close()">
        <mat-icon aria-label="Close dialog">close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content class="content-payment-window hide-lt-sm">
    <iframe #paymentFrame [src]="data.url" class="iframe-payment-window"></iframe>
</mat-dialog-content>

<mat-dialog-content class="content-payment-window-mobile hide-gt-xs">
    <iframe #paymentFrame [src]="data.url" class="iframe-payment-window-mobile"></iframe>
</mat-dialog-content>
