import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Params } from '@interfaces/params';
import { LinesEntity, MerchantSpecification } from '@models/merchant-specification';
import { Animations } from 'app/animations/animations';
import { InvoiceRepo } from 'app/endpoints/invoice/invoice-repo';
import { MerchantSpecificationRepo } from 'app/endpoints/invoice/merchant-specification-repo';
import { Observable, combineLatest, map, switchMap, tap, withLatestFrom } from 'rxjs';

@Component({
    selector: 'qp-merchant-specification',
    templateUrl: './merchant-specification.component.html',
    animations: [Animations.getFadeAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MerchantSpecificationComponent {
    public readonly dataSource = new MatTableDataSource<LinesEntity>();
    public readonly invoice$ = this.activatedRoute.queryParams.pipe(
        switchMap(query_params => this.invoiceRepo.get(query_params.invoice_id))
    );
    public readonly merchant_specification$ = this.activatedRoute.params.pipe(
        withLatestFrom(this.activatedRoute.queryParams),
        switchMap(([params, query_params]) => this.getMerchantSpecifications(query_params.invoice_id, params.merchant_id)),
        map(merchant_specifications => merchant_specifications[0]),
        tap(merchant_specification => this.dataSource.data = merchant_specification.attributes.lines ?? [])
    );
    public readonly view_model$ = combineLatest({
        invoice: this.invoice$,
        merchant_specification: this.merchant_specification$
    });
    public readonly displayColumns: string[] = ['description', 'quantity', 'unit_price', 'unit'];

    constructor(
        private invoiceRepo: InvoiceRepo,
        private merchantSpecificationRepo: MerchantSpecificationRepo,
        private activatedRoute: ActivatedRoute
    ) {}

    public getMerchantSpecifications(invoice_id: string, merchant_id: string): Observable<MerchantSpecification[]> {
        const params = {
            page: 1,
            page_size: 1000,
            sort_by: 'id'
        } as Params;

        return this.merchantSpecificationRepo.getAll(invoice_id, params, merchant_id);
    }
}
