import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Settlement } from '@models/clearhaus-settlement';

@Component({
    selector: 'qp-settlement-period',
    templateUrl: './settlement-period.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementPeriodComponent {
    @Input() public settlement?: Settlement;
}
