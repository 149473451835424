import * as Sentry from '@sentry/angular';
import { beforeSend } from 'sentry/filter';
import { getEnv } from 'app/helpers/logging/get-environment';

export const initializeSentry = (environment: any): void => {
    Sentry.init({
        dsn: environment.sentry.dsn,
        sampleRate: environment.sentry.sampleRate,
        environment: getEnv(),
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ],
        beforeSend,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
};
