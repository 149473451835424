import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Agreement } from '@interfaces/agreement';
import { Contact } from '@models/clearhaus-application/ch-instant';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { MeRepo } from 'app/endpoints/api/me-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { exhaustMap, filter, map } from 'rxjs/operators';

@Component({
    selector: 'qp-clearhaus-signatory-form',
    templateUrl: './signatory.html'
})
export class ClearhausSignupFormSignatoryComponent implements AfterViewInit {
    @Output() public signerChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();
    @Input() public contact: Contact;
    @Input() public stepper: MatStepper;

    public _signer: Contact = new Contact();
    public agreementsMails: string[];

    public signatoryForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email]))
    });

    public get email(): AbstractControl {
        return this.signatoryForm.get('email') as UntypedFormControl;
    }
    public get name(): AbstractControl {
        return this.signatoryForm.get('name') as UntypedFormControl;
    }

    @Input() public get signer(): Contact {
        return this._signer;
    }
    public set signer(value: Contact) {
        this._signer = value;

        this.email.setValue(this.signer.email);
        this.name.setValue(this.signer.name);

        this.signerChange.emit(value);
    }

    constructor(
        private agreementRepo: AgreementRepo,
        private dialogService: DialogService,
        private meRepo: MeRepo
    ) {
        this.getAgreements();
    }

    public getAgreements(): void {
        this.agreementsMails = [];
        this.agreementRepo.getAll().subscribe((agreements: Agreement[]) => {
            for (const agreement of agreements) {
                if (agreement?.user?.email) {
                    this.agreementsMails.push(agreement.user.email);
                }
            }
        });
    }

    public goForwardValidation(): void {
        if (this.agreementsMails.includes(this.email.value)) {
            this.stepper.next();
        } else {
            this.dialogService.confirm(
                $localize`${this.name.value} does not have an user on this account.`,
                $localize`${this.email.value} will be invited to the account with owner permissions.`,
                $localize`Invite`,
                $localize`Close`
            ).afterClosed().pipe(
                filter(confirmed => confirmed),
                exhaustMap(() => {
                    const locale = 'de';
                    const countryCode = 'DEU';
                    return this.meRepo.createWithLocale(this.email.value, locale, countryCode).pipe(
                        map(data => {
                            const info: any = {
                                user_email: this.email.value,
                                owner: true,
                                acl_permissions: [
                                    {
                                        resource: '/account',
                                        get: true,
                                        post: false,
                                        put: false,
                                        delete: false,
                                        patch: false
                                    }
                                ]
                            };

                            this.agreementRepo.create(info, undefined).subscribe(() => {
                                this.stepper.next();
                            });
                            return data;
                        })
                    );
                })
            ).subscribe();
        }
    }

    public ngAfterViewInit(): void {
        this.signatoryForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(this.signatoryForm.valid);
        });

        this.validityChange.emit(this.signatoryForm.valid);
    }

    public modelSigner(propName: string, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const inputElement = eventTarget as HTMLInputElement;
        const propObj = { [propName]: inputElement.value };
        this.signer = { ...this.signer, ...propObj };
    }
}
