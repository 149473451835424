import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { DirectorFile } from '@models/clearhaus-application/director-file';

@Component({
    selector: 'qp-clearhaus-documentation-form',
    templateUrl: './documentation-instant.html'
})
export class ClearhausInstantSignupDocumentationComponent implements AfterViewInit {
    @Output() public fileChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _documentationFile: DirectorFile[];

    @Input() public get documentationFile(): DirectorFile[] {
        return this._documentationFile;
    }
    public set documentationFile(value: DirectorFile[]) {
        if (value) {
            this._documentationFile = value;
            this.fileChange.emit(this._documentationFile);
        }
    }

    constructor() {
        this._documentationFile = [];
    }

    public ngAfterViewInit(): void {
        this.isValid();
    }

    public addFile(file: Array<File>): void {
        this.documentationFile = [];

        file.forEach(file => {
            const directorFile: DirectorFile = {
                label: 'documentation',
                name: file.name,
                size: file.size,
                content_type: file.type,
                file
            };

            this.documentationFile.push(directorFile);
        });

        this.isValid();
    }

    public isValid(): void {
        let valid = false;
        if (this.documentationFile.length > 0) {
            valid = true;
        }
        this.validityChange.emit(valid);
    }
}
