import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ApiModule } from '../endpoints/api/api.module';
import { ExporterFieldsDialogComponent } from './dialog/exporter-fields.component';
import { LayoutModule } from '@widgets/layout/layout.module';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        FormsModule,
        LayoutModule,
        PipesModule
    ],
    declarations: [ExporterFieldsDialogComponent]
})
export class ExporterModule {}
