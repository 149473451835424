<div class="flex-col rg16">
    <div
        *ngIf="
            customer?.id &&
            customer?.attributes?.billing_info?.subscription_info &&
            customer?.attributes?.billing_info?.subscription_info?.status === 'failure'
        "
    >
        <span i18n>The withdrawal of your last Quickpay invoice has failed:</span>&nbsp;
        {{ customer?.attributes?.billing_info?.subscription_info.status_description }}
    </div>

    <div *ngIf="balance" class="flex-col rg16">
        <span class="mat-body-strong" i18n>Current balance:
            {{ balance.attributes?.total | qpCurrency: balance.attributes?.currency }}
            {{ balance.attributes?.currency }}
        </span>
        <mat-card-subtitle class="text-grey" i18n>
            The final amount will be charged on the next invoice.
        </mat-card-subtitle>
    </div>

    <div class="flex-col" *ngIf="data_source_unpaid.data.length">
        <span class="subtitle1" i18n>Unpaid</span>
        <table
            class="margin-negative"
            mat-table
            mat-card-image
            #table
            [dataSource]="data_source_unpaid"
        >
            <div matColumnDef="Invoice">
                <th mat-header-cell *matHeaderCellDef i18n>Invoice</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.number }}</td>
            </div>

            <div matColumnDef="Amount (VAT incl.)">
                <th mat-header-cell *matHeaderCellDef i18n>Amount (VAT incl.)</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.amount | qpCurrency: invoice.attributes?.currency }}</td>
            </div>

            <div matColumnDef="Currency">
                <th mat-header-cell *matHeaderCellDef i18n>Currency</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.currency }}</td>
            </div>

            <div matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef i18n>Status</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.status }}</td>
            </div>

            <div matColumnDef="Paid at">
                <th mat-header-cell *matHeaderCellDef i18n>Paid at</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.paid_at | date }}</td>
            </div>

            <div matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
                <td mat-cell *matCellDef="let invoice">
                    <button
                        mat-raised-button
                        i18n-matTooltip
                        matTooltip="Download as PDF"
                        arial-label="'Download as PDF'"
                        (click)="downloadInvoiceAsPDF(invoice)"
                    >
                        PDF
                    </button>

                    <button
                        mat-raised-button
                        i18n-matTooltip
                        matTooltip="Download as CSV"
                        (click)="downloadInvoiceAsCSV(invoice)"
                        *ngIf="['Reseller'] | qpAccountType"
                    >
                        CSV
                    </button>

                    <button
                        mat-icon-button
                        i18n-matTooltip
                        matTooltip="Show Details"
                        [routerLink]="['../invoice', invoice.id]"
                        *ngIf="['Reseller'] | qpAccountType"
                    >
                        <mat-icon>info_outline</mat-icon>
                    </button>
                </td>
            </div>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="no-pointer"></tr>
        </table>
    </div>

    <span class="bottom-border padding t8 b8 line-height-24" *ngIf="data_source_unpaid.data.length"></span>

    <div class="flex-col">
        <span class="subtitle1" i18n>Paid</span>
        <table
            class="margin-negative"
            mat-table
            mat-card-image
            #table
            [dataSource]="data_source_paid"
        >
            <div matColumnDef="Invoice">
                <th mat-header-cell *matHeaderCellDef i18n>Invoice</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.number }}</td>
            </div>

            <div matColumnDef="Amount (VAT incl.)">
                <th mat-header-cell *matHeaderCellDef i18n>Amount (VAT incl.)</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.amount | qpCurrency: invoice.attributes?.currency }}</td>
            </div>

            <div matColumnDef="Currency">
                <th mat-header-cell *matHeaderCellDef i18n>Currency</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.currency }}</td>
            </div>

            <div matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef i18n>Status</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.status }}</td>
            </div>

            <div matColumnDef="Paid at">
                <th mat-header-cell *matHeaderCellDef i18n>Paid at</th>
                <td mat-cell *matCellDef="let invoice">{{ invoice.attributes?.paid_at | date }}</td>
            </div>

            <div matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
                <td mat-cell *matCellDef="let invoice">
                    <button
                        mat-raised-button
                        i18n-matTooltip
                        matTooltip="Download as PDF"
                        arial-label="'Download as PDF'"
                        (click)="downloadInvoiceAsPDF(invoice)"
                    >
                        PDF
                    </button>

                    <button
                        mat-raised-button
                        i18n-matTooltip
                        matTooltip="Download as CSV"
                        (click)="downloadInvoiceAsCSV(invoice)"
                        *ngIf="['Reseller'] | qpAccountType"
                    >
                        CSV
                    </button>

                    <button
                        mat-icon-button
                        i18n-matTooltip
                        matTooltip="Show Details"
                        [routerLink]="['../invoice', invoice.id]"
                        *ngIf="['Reseller'] | qpAccountType"
                    >
                        <mat-icon>info_outline</mat-icon>
                    </button>
                </td>
            </div>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="no-pointer"></tr>
        </table>
    </div>

    <qp-paginator
        [config]="paginator_options"
        [data_length]="data_source_paid.data.length + data_source_unpaid.data.length"
        (pageChange)="onPageChange($event)">
    </qp-paginator>
</div>
