<div>
    <p class="text-grey">
        <span i18n>Your account is in test mode, and can only accept test transactions.</span>
        <br />
        <span i18n>Activate your account to begin accepting real transactions.</span>
    </p>
</div>

<mat-stepper orientation="vertical" #stepper [linear]="true" class="inner-none">
    <mat-step [stepControl]="addressForm">
        <ng-template matStepLabel i18n>
            Company information
        </ng-template>
        <div>
            <p class="small-text text-grey" i18n>
                Please make sure that your company information is correct, and you have filled in your VAT no.
            </p>
        </div>

        <form name="account_details" autocomplete="off" [formGroup]="addressForm" class="flex-col">
            <mat-form-field>
                <mat-label i18n>Name</mat-label>
                <input matInput name="name" formControlName="name" />
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n>Street</mat-label>
                <input matInput name="street" formControlName="street" />
            </mat-form-field>

            <div class="flex-row-mobile-col cg4p">
                <mat-form-field class="flex-28-mobile-100">
                    <mat-label i18n>Zip code</mat-label>
                    <input matInput name="zip_code" formControlName="zip_code" />
                </mat-form-field>

                <mat-form-field class="flex-68-mobile-100">
                    <mat-label i18n>City</mat-label>
                    <input matInput name="city" formControlName="city" />
                </mat-form-field>
            </div>

            <div class="flex-row-mobile-col cg4p">
                <mat-form-field class="flex-48-mobile-100">
                    <mat-label i18n>Country</mat-label>
                    <mat-select name="country_code" formControlName="country_code">
                        <mat-option *ngFor="let country of countries" [value]="country.code">
                            {{ country.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="flex-48-mobile-100">
                    <mat-label i18n>Region (EU Optional)</mat-label>
                    <input matInput name="region" formControlName="region" />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label i18n>VAT. No.</mat-label>
                <input matInput name="vat_no" formControlName="vat_no" />
            </mat-form-field>
        </form>

        <button mat-raised-button color="accent" (click)="updateAccount()" matStepperNext i18n>
            Next
        </button>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel i18n>
            Shop URL
        </ng-template>
        <span class="small-text text-grey margin b4" i18n>
            Add the URL(s) from where you are going to accept payments.
        </span>
        <qp-shopurls 
            [readonly]="!account?.shop_urls || !(['/account', 'patch'] | qpPermission)"
            [(shopUrls)]="account!.shop_urls!"
            (add)="addRemoveShopUrl(account!.shop_urls!)"
            (remove)="addRemoveShopUrl(account!.shop_urls!)">
        </qp-shopurls>

        <p class="margin t4">
            <span class="text-grey" i18n="Provides a link to Quickpay prices">Please note that there is a monthly fee for each additional domain. See <a href="https://quickpay.net/prices-eu/" target="_blank">https://quickpay.net/prices-eu/</a></span>
        </p>

        <button mat-raised-button color="accent" (click)="updateShopUrl()" [disabled]="account?.shop_urls?.length === 0" matStepperNext i18n>
            Next
        </button>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel i18n>
            Billing method
        </ng-template>
        <p class="small-text text-grey" i18n>
            The billing method used for paying your Quickpay invoices
        </p>
        <qp-select-payment-method [customer]="customer" [plan]="plan" (updated)="finish()"> </qp-select-payment-method>
    </mat-step>
</mat-stepper>
