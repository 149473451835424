import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@helpers/windowprovider/window-provider';
import { ScreenSize } from './screen-size.type';

@Injectable({
    providedIn: 'root'
})
export class ScreenSizeService {

    constructor(@Inject(WINDOW) private window: Window) {}

    public isActive(size: ScreenSize): boolean {
        switch (size) {
            case 'xs':
                return this.isScreenBetween(0, 599);
            case 'sm':
                return this.isScreenBetween(600, 959);
            case 'md':
                return this.isScreenBetween(960, 1279);
            case 'lg':
                return this.isScreenBetween(1280, 1919);
            case 'xl':
                return this.isScreenBetween(1920, 5000);
            default:
                return false;
        }
    }

    private isScreenBetween(minWidth: number, maxWidth: number): boolean {
        return this.window.innerWidth >= minWidth && this.window.innerWidth <= maxWidth;
    }
}
