import { Injectable } from '@angular/core';
import { AdminPayment } from '@models/admin-payment';
import { Payment } from '@models/payment';
import { CurrencyService } from '@services/currency/currency.service';
import { TransactionService } from '@services/transaction/transaction.service';
import { ExportFields } from '../dialog/exporter-fields';
import { PAYMENT_HEADER } from './export-header.constant';
import { OptionalTransactionFieldsParser } from './fields.parser';

@Injectable({
    providedIn: 'root'
})
export class ExporterPaymentParser {
    constructor(
        private currencyService: CurrencyService,
        private fieldsParser: OptionalTransactionFieldsParser,
        private transactionService: TransactionService
    ) {}

    public getHeader(): Array<string> {
        return PAYMENT_HEADER;
    }

    public parse(payment: Payment | AdminPayment, fields: ExportFields): any {
        const operation = this.transactionService.lastOperation(payment);

        let data = {
            Id: payment.id,
            Order_id: payment.order_id,
            Accepted: payment.accepted ? 'True' : 'False',
            Test_mode: payment.test_mode ? 'True' : 'False',
            Branding_id: payment.branding_id,
            Acquirer: payment.acquirer,
            Facilitator: payment.facilitator,
            Created_at: payment.created_at,
            Currency: payment.currency,
            Amount: this.currencyService.toCurrency(this.getAmount(payment), payment.currency, 'localized'),
            Balance: this.currencyService.toCurrency(payment.balance, payment.currency, 'localized'),
            Fee: this.currencyService.toCurrency(payment.fee ?? NaN, payment.currency, 'localized'),
            Type: payment.metadata?.type,
            Brand: payment.metadata?.brand,
            First_6: payment.metadata?.bin,
            Last_4: payment.metadata?.last4,
            Exp_month: payment.metadata?.exp_month,
            Exp_year: payment.metadata?.exp_year,
            Country: payment.metadata?.country,
            Is_3d_secure: '',
            Fraud_suspected: '',
            Customer_ip: payment.metadata?.customer_ip,
            Customer_country: payment.metadata?.customer_country,
            Operation_type: operation ? operation.type : '',
            Operation_date: operation ? operation.created_at : '',
            Qp_status_code: operation ? operation.qp_status_code : '',
            Qp_status_msg: operation ? operation.qp_status_msg : '',
            Aq_status_code: operation ? operation.aq_status_code : '',
            Aq_status_msg: operation ? operation.aq_status_msg : '',
            Callback_url: operation ? operation.callback_url : '',
            Callback_success: operation && operation?.callback_success ? operation?.callback_success : '',
            Callback_response_code: operation ? operation.callback_response_code : ''
        };

        if (payment instanceof AdminPayment) {
            data.Is_3d_secure = payment.metadata?.card?.is_3d_secure ? 'True' : 'False';
            data.Fraud_suspected = payment.metadata?.fraud?.suspected ? 'True' : 'False';
        } else {
            data.Is_3d_secure = payment.metadata?.is_3d_secure ? 'True' : 'False';
            data.Fraud_suspected = payment.metadata?.fraud_suspected ? 'True' : 'False';
        }

        data = this.fieldsParser.setData(payment, fields, data);
        return data;
    }

    public getAmount(payment: Payment | AdminPayment): number {
        let amount = this.transactionService.getAuthAmount(payment); // default state

        if (amount === 0) {
            amount = payment.link ? payment.link.amount : 0; // initial state
        }

        if (amount === 0) {
            amount = this.transactionService.latestFailedAmount(payment); // rejected state
        }

        return amount;
    }
}
