import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@helpers/windowprovider/window-provider';
import { Account } from '@interfaces/account';
import { Agreement } from '@interfaces/agreement';
import { Me } from '@interfaces/me';
import { Acquirers } from '@models/acquirers/acquirers';
import { IntegrationSettings } from '@models/integration/integration-settings';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { CredentialsService } from '@services/credentials/credentials.service';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { AcquirerRepo } from 'app/endpoints/api/acquirer-repo';
import { MeRepo } from 'app/endpoints/api/me-repo';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public integration: IntegrationSettings;
    public requestPath: string;
    public me: Me | undefined = undefined;

    constructor(
        private router: Router,
        private accountRepo: AccountRepo,
        private acquirerRepo: AcquirerRepo,
        private acquirerService: AcquirerService,
        private agreementService: AgreementService,
        private credentialsService: CredentialsService,
        private meRepo: MeRepo,
        @Inject(WINDOW) private window: Window
    ) {}

    public get dark_mode(): boolean {
        const dark_mode_key = this.window.localStorage.getItem(`qp-manager~${this.me?.id}~dark_mode`);
        return dark_mode_key ? JSON.parse(dark_mode_key) : false;
    }

    public set dark_mode(is_dark_mode: boolean) {
        this.window.localStorage.setItem(`qp-manager~${this.me?.id}~dark_mode`, JSON.stringify(is_dark_mode));
    }

    public login(username: string, password: string): Observable<any> {
        const auth_key = this.credentialsService.generateBasicKey(username, password);
        return this.meRepo.get(auth_key).pipe(
            map((me: Me) => {
                this.setupUser(me, username, password);

                if (this.requestPath) {
                    return this.requestPath;
                } else {

                }
                return me;
            })
        );
    }

    public setupUser(me: any, username: string, password: string): void {
        this.setUser(me);
        this.credentialsService.setCredentials(username, password);
        this.credentialsService.setUsername(username);
        this.credentialsService.setPassword(password);
    }

    public saveUserToStorage(username: string, password: string): void {
        window.sessionStorage.setItem('username', username);
        window.sessionStorage.setItem('password', password);
    }

    public setUser(me: Me): void {
        this.me = me;
    }

    public isLoggedIn(): boolean {
        return !!this.me;
    }

    public logout(): void {
        this.me = undefined;
        this.requestPath = '';

        this.agreementService.setAgreement(undefined);
        this.agreementService.setAgreements([]);
        this.agreementService.agreementsParams = [];
        this.acquirerService.acquirers = {} as Acquirers;
        this.credentialsService.clearCredentials();
    }

    public hasAccountType(types: string[]): boolean {
        return types &&
            !!this.agreementService.agreement?.account?.type &&
            types.indexOf(this.agreementService.agreement.account.type) !== -1;
    }

    public selectAgreement(agreement: Agreement | undefined): Observable<Account> {
        this.credentialsService.setCredentials('', agreement?.api_key ?? '');
        return this.accountRepo.get().pipe(
            map(account => {
                this.agreementService.setAgreement(agreement ? { ...agreement, account } : { account } as Agreement);
                return account;
            }),
            switchMap((account: Account) => this.getAcquirers(account).pipe(map(() => account)))
        );
    }

    private getAcquirers(account: Account): Observable<Acquirers> {
        if (this.agreementService.hasPermission('/acquirers', 'get') && account.type === 'Merchant') {
            return this.acquirerRepo.getAll().pipe(
                tap((acquirers) => this.acquirerService.acquirers = acquirers)
            );
        } else {
            const acquirers = {} as Acquirers;
            this.acquirerService.acquirers = acquirers;
            return of(acquirers);
        }
    }
}
