import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '@helpers/windowprovider/window-provider';
import { Account } from '@interfaces/account';
import { Attributes, Customer } from '@models/customer';
import { Plan } from '@models/plan';
import { AgreementService } from '@services/agreement/agreement.service';
import { BillingService } from '@services/billing/billing.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { CustomerRepo } from 'app/endpoints/invoice/customer-repo';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { AddCreditCardDialogComponent } from '../add-credit-card/add-credit-card.component';
import { AddOtherPaymentMethodDialogComponent } from '../add-other-payment-method/add-other-payment-method.component';

@Component({
    selector: 'qp-select-payment-method',
    templateUrl: './select.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class SelectPaymentMethodComponent extends BaseLoadingDirective {
    @Output() public updated: EventEmitter<null> = new EventEmitter<null>();
    @Input() public customer?: Customer;
    @Input() public plan?: Plan;

    public account: Account | undefined = this.agreementService.agreement?.account;
    public addingCreditCard = false;
    public addingPaymentMethod = false;
    public isDaneMerchant = this.agreementService.isDanishMerchant();

    constructor(
        private agreementService: AgreementService,
        private billingService: BillingService,
        private customerRepo: CustomerRepo,
        private dialogService: DialogService,
        private sanitizer: DomSanitizer,
        @Inject(WINDOW) private window: Window
    ) {
        super();
    }

    public addCreditCard(): void {
        this.addingCreditCard = true;
        this.dialogService.component(AddCreditCardDialogComponent).afterClosed().pipe(
            switchMap((accepted: boolean) => {
                if (accepted) {
                    return this.savePaymentMethod('credit_card').pipe(
                        switchMap(() => this.billingService.createPaymentRepoLink()),
                        map(() => {
                            this.addingCreditCard = false;
                            this.updated.emit();
                        }),
                        catchError(_ => {
                            this.addingCreditCard = false;
                            return of();
                        })
                    );
                } else {
                    this.addingCreditCard = false;
                    return of();
                }
            })
        ).subscribe();
    }

    public savePaymentMethod(paymentMethod: string): Observable<Customer> {
        const customer: Customer = {
            id: this.customer?.id ?? NaN,
            type: 'customers',
            attributes: {
                payment_method: paymentMethod
            } as Attributes
        };
        if (!customer.id) {
            return this.customerRepo.create(customer).pipe(
                switchMap(createdCustomer => {
                    customer.id = createdCustomer.id;
                    return this.customerRepo.patchBillingInfo(customer);
                })
            );
        }
        return this.customerRepo.patchBillingInfo(customer);
    }

    public showOtherMethods(): void {
        this.addingPaymentMethod = true;
        this.dialogService.component(
            AddOtherPaymentMethodDialogComponent,
            { customer: JSON.parse(JSON.stringify(this.customer)) }
        ).afterClosed().pipe(
            exhaustMap((customer: Customer) => {
                if (customer) {
                    return this.savePaymentMethod(customer?.attributes?.billing_info?.payment_method || '').pipe(
                        switchMap((updatedCustomer: Customer) =>
                            this.customerRepo.deleteSubscriptionInfo(customer.id).pipe(
                                map(() => {
                                    let observable: Observable<any> = of(true);

                                    if (customer?.attributes?.billing_info?.payment_method === 'betalingsservice') {
                                        observable = this.openBetalingsServiceDialog();
                                    }

                                    if (customer?.attributes?.billing_info?.payment_method === 'ean') {
                                        observable = this.customerRepo.update({
                                            id: updatedCustomer.id,
                                            type: 'customers',
                                            attributes: { ean_number: parseInt(customer?.attributes?.ean_number || '0', 10) }
                                        });
                                    }

                                    return observable.subscribe({
                                        complete: () => {
                                            this.addingPaymentMethod = false;
                                            this.updated.emit();
                                        }
                                    });

                                }),
                                catchError(_ => {
                                    this.addingPaymentMethod = false;
                                    this.updated.emit();
                                    return of();
                                })
                            )),
                        catchError(_ => {
                            this.addingPaymentMethod = false;
                            this.updated.emit();
                            return of();
                        })
                    );
                } else {
                    this.addingPaymentMethod = false;
                    return of();
                }
            })
        ).subscribe();
    }

    public openBetalingsServiceDialog(): any {
        const label = $localize`Please enter your merchant id ( ${this.agreementService.agreement?.account?.id} )`;
        const url = this.sanitizer.bypassSecurityTrustResourceUrl(
            'https://www.betalingsservice.dk/BS/?id=2&pbs=03303756' +
            '&pbscheck=azcM9WxDuh0X2eSis0EyAQ%3D%3D&dbnr=&dbgr=00001&repeatKundeNr=false&kundenrLabel=' +
            label +
            '&version=2&t'
        );

        return this.billingService.createPaymentWindow(url);
    }

    public openSupplierServiceDialog(): void {
        this.window.open(
            'https://pbs-erhverv.dk/LS/?id=0&pbs=98a6cebd0aea17175431f07fb20b7bb8&dbnr=' + this.account?.id + '&cvr=&knmin=1&knmax=6',
            'LSTilmeld',
            'resizable=no,toolbar=no,scrollbars=no,menubar=no,location=no,status=yes,width=375,height=500'
        );
    }

    public pauseAccount(): void {
        this.dialogService.confirm(
            $localize`Are you sure you want to pause the account?`,
            $localize`By pausing your account, the following will take place:

• Your monthly fee will be waived starting from the day after you pause your account.
• MobilePay Online will be disabled. You need to manually enable it upon reactivating your account.
• You will not be able to process live payments until your account is reactivated.
• At the end of the month, you will receive an invoice for the period up until and including the day you paused your account.`,
            $localize`Pause account`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() => this.customer ? this.customerRepo.pauseAccount(this.customer) : EMPTY),
            map((customer: Customer) => this.customer = customer)
        ).subscribe();
    }

    public unpauseAccount(): void {
            this.dialogService.confirm(
                $localize`Are you sure you want to re-activate the account?`,
                $localize`By re-activating the account, you will again be able to process live payments.

    Please note that MobilePay Online is disabled. If you want to accept payments through MobilePay Online, you will have to enable it again in Settings > Acquirers > MobilePay Online.`,
                $localize`Activate account`
            ).afterClosed().pipe(
                filter(confirmed => !!confirmed),
                switchMap(() => this.customer ? this.customerRepo.unpauseAccount(this.customer) : EMPTY),
                map(() => {
                    if (this.customer) {
                        this.customer.attributes.on_hold_since = undefined;
                    }
                })
        ).subscribe();
    }
}
