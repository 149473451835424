<form name="createRecurring" [formGroup]="recurring_form">
    <h5 mat-dialog-title i18n>Create recurring</h5>
    <mat-dialog-content class="grey-border-bottom">
        <div class="flex-col rg16">
            <mat-form-field>
                <mat-label i18n>Amount</mat-label>
                <input
                    inputDecimal
                    class="input-right"
                    [currency]="payment.currency"
                    matInput
                    name="amount"
                    id="amount"
                    type="text"
                    class="input-right"
                    i18n-placeholder
                    placeholder="Amount"
                    formControlName="amount"
                />
            </mat-form-field>

            <div class="flex-col">
                <mat-form-field>
                    <mat-label i18n>Order id</mat-label>
                    <input matInput name="order_id" type="text" formControlName="order_id" />
                </mat-form-field>
                <div>
                    <button mat-raised-button (click)="generateOrderId()" i18n>Generate order id</button>
                </div>
            </div>

            <mat-form-field *ngIf="data.subscription.acquirer === 'mobilepaysubscriptions'">
                <mat-label i18n>Due date (MM-DD-YYYY)</mat-label>
                <input
                    matInput
                    [matDatepicker]="dueDate"
                    [min]="minDate"
                    [max]="maxDate"
                    name="dueDate"
                    formControlName="due_date"
                    (focus)="focusState = true"
                    (focusout)="focusState = false"
                    i18n-placeholder
                    placeholder="YYYY-MM-DD"
                />
                <mat-datepicker-toggle matIconSuffix [for]="dueDate"></mat-datepicker-toggle>
                <mat-datepicker #dueDate></mat-datepicker>
                <mat-hint *ngIf="focusState" i18n>Due date must be more than one day and less than 126 days from today</mat-hint>
                <mat-error *ngIf="recurring_form.get('due_date')?.hasError('matDatepickerMin') && !focusState" i18n>Due date must be more than one day from today</mat-error>
                <mat-error *ngIf="recurring_form.get('due_date')?.hasError('matDatepickerMax') && !focusState" i18n>Due date must be less than 126 days from today</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="data.subscription.acquirer === 'mobilepaysubscriptions'">
                <mat-label i18n>Description</mat-label>
                <textarea matInput name="description" formControlName="description"></textarea>
            </mat-form-field>

            <mat-form-field class="flex-100" *ngIf="data.subscription.acquirer === 'clearhaus'">
                <mat-label i18n>Text on statement</mat-label>
                <input matInput name="text_on_statement" type="text" formControlName="text_on_statement" />
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-row">
        <span class="flex-1"></span>
        <button mat-button color="accent" mat-dialog-close i18n>Close</button>
        <button mat-button color="accent" [disabled]="recurring_form.invalid || creatingRecurring()" (click)="createPayment()">
            <span class="mat-button-wrapper" *ngIf="!creatingRecurring()" i18n>Create</span>
            <mat-spinner *ngIf="creatingRecurring()" [diameter]="30"></mat-spinner>
        </button>
    </mat-dialog-actions>
</form>
