<mat-toolbar class="header card02 flex-row" color="primary">
    <div class="flex-row justify-start items-center hide-gt-md">
        <button mat-icon-button (click)="sideNav.toggle()">
            <mat-icon class="text-grey">menu</mat-icon>
        </button>
    </div>
    <div class="header-logo flex-col hide-lt-lg justify-center items-center">
        <img *ngIf="!dark_mode" [src]="theme.logo.dark" />
        <img *ngIf="dark_mode" [src]="theme.logo.light" />
    </div>

    <div *ngIf="(['Unzer'] | qpMerchantType)" class="flex-1 flex-row justify-end items-center">
        <button mat-button color="accent" class="account-layout-header-button" (click)="signOut()" i18n>Sign out</button>
    </div>

    <div class="flex-1 flex-row justify-end items-center" *ngIf="!(['Unzer'] | qpMerchantType)">
        <mat-menu #topbarMenu="matMenu">
            <ng-container *ngTemplateOutlet="topbarActions"></ng-container>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="topbarMenu" color="accent">
            <mat-icon color="accent">perm_identity</mat-icon>
        </button>
    </div>

    <ng-template #topbarActions>
        <div class="flex-col">
            <button mat-button (click)="signOutTo(resellerAgreement)" *ngIf="!sessionAgreements.length" i18n>
                My profile
            </button>
            <button mat-button (click)="signOutTo(sessionAgreements[sessionAgreements.length - 1])" *ngIf="sessionAgreements.length" i18n>
                My profile
            </button>
            <button mat-button color="accent" class="account-layout-header-button" (click)="signOut()" i18n>
                Sign out
            </button>
        </div>
    </ng-template>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav [fixedInViewport]="mode === 'side'" [fixedTopGap]="64" [disableClose]="mode === 'side'" #sideNav class="side-nav" [mode]="mode" [opened]="mode === 'side'">
        <mat-toolbar class="header-logo flex-row justify-start items-center hide-gt-md">
            <img *ngIf="!dark_mode" [src]="theme.logo.dark" />
            <img *ngIf="dark_mode" [src]="theme.logo.light" />
        </mat-toolbar>

        <qp-agreements-list [agreement]="agreement" [reseller_agreement]="resellerAgreement" (sign_out)="signOutTo($event)"></qp-agreements-list>

        <mat-divider></mat-divider>

        <mat-nav-list class="no-hover-effect">
            <mat-list-item *ngFor="let route of routesRoot" [hidden]="route.path" (click)="routeTo(route.path)">
                <button mat-button [ngClass]="{'account-layout-active-row': isActive(route.path)}">
                    <div class="flex-row flex-1 cg8 justify-start items-center">
                        <mat-icon [ngClass]="{'inactive-icon': !isActive(route.path)}" [color]="isActive(route.path) ? 'accent' : ''"> {{ route.data?.settings.icon }} </mat-icon>
                        <span class="nav-item-text">{{ translateRootRoute(route) }}</span>
                    </div>
                </button>
            </mat-list-item>

            <mat-list-item *ngIf="routesTools" (click)="showTools = !showTools; showSettings = false">
                <div [ngClass]="{'account-layout-active-row': isSubentryActive('tools')}">
                    <div class="flex-row flex-1 space-between items-center">
                        <div class="flex-row items-center cg8">
                            <mat-icon color="primary" [ngClass]="{'inactive-icon': !isSubentryActive('tools')}">build</mat-icon>
                            <span class="nav-item-text" i18n>Tools</span>
                        </div>
                        <mat-icon color="primary" [@rotate_chevron]="showTools ? 'up' : 'down'" [ngClass]="{'inactive-icon': !isSubentryActive('tools')}"> keyboard_arrow_up </mat-icon>
                    </div>
                </div>
            </mat-list-item>
            <mat-accordion class="sublist" *ngIf="routesTools">
                <mat-expansion-panel [expanded]="showTools" hideToggle>
                    <mat-nav-list>
                        <mat-list-item *ngFor="let route of routesTools?.children" (click)="routeTo('tools/' + route.path)">
                            <div [ngClass]="{'account-layout-active-row': isActive('tools/' + route.path)}">
                                <div class="flex-row flex-1 justify-start items-center">
                                    <span class="nav-item-text text-grey">{{ translateToolsRoute(route) }}</span>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-list-item *ngIf="routesSettings" (click)="showSettings = !showSettings; showTools = false">
                <div [ngClass]="{'account-layout-active-row': isSubentryActive('settings')}">
                    <div class="flex-row flex-1 space-between items-center">
                        <div class="flex-row items-center cg8">
                            <mat-icon color="primary" [ngClass]="{'inactive-icon': !isSubentryActive('settings')}">settings</mat-icon>
                            <span class="nav-item-text" i18n>Settings</span>
                        </div>
                        <mat-icon color="primary" [@rotate_chevron]="showSettings ? 'up' : 'down'" [ngClass]="{'inactive-icon': !isSubentryActive('tools')}"> keyboard_arrow_up </mat-icon>
                    </div>
                </div>
            </mat-list-item>
            <mat-accordion class="sublist">
                <mat-expansion-panel [expanded]="showSettings" hideToggle>
                    <mat-nav-list>
                        <mat-list-item *ngFor="let route of routesSettings?.children" (click)="routeTo('settings/' + route.path)">
                            <div [ngClass]="{'account-layout-active-row': isActive('settings/' + route.path)}">
                                <div class="flex-row flex-1 justify-start items-center">
                                    <span class="nav-item-text text-grey">{{ translateSettingRoute(route) }}</span>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-list-item *ngIf="(['Unzer'] | qpMerchantType)" (click)="showSupport = !showSupport">
                <a matListItemTitle class="flex-row cg8 justify-start items-center no-underline" [ngClass]="{'account-layout-active-row': isActive('app.account.support')}">
                    <mat-icon [ngClass]="{'inactive-icon': !isSubentryActive('settings')}">help</mat-icon>
                    <span class="nav-item-text text-black" i18n>Support</span>
                    <span class="flex-1"></span>
                    <mat-icon [@rotate_chevron]="showSupport ? 'up' : 'down'">keyboard_arrow_up</mat-icon>
                </a>
            </mat-list-item>
            <mat-accordion class="sublist">
                <mat-expansion-panel [expanded]="showSupport" hideToggle>
                    <mat-nav-list>
                        <mat-list-item (click)="goToUnzerHelp()">
                            <a matListItemTitle>
                                <span class="nav-item-text text-black" i18n>Help portal</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item>
                            <button mat-button (click)="routeTo('support')" [ngClass]="{'account-layout-active-row': isActive('support')}">
                                <span class="nav-item-text" i18n>Contact us</span>
                            </button>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-expansion-panel>
            </mat-accordion>

            <!-- Temp removed so only available via url -->
            <!-- <mat-list-item *ngIf="(['Merchant'] | qpAccountType) && agreement?.account?.reseller?.support_email && !(['Unzer'] | qpMerchantType)" (click)="routeTo('app.account.sale')" >
                <a matListItemLine class="flex-row cg8 justify-start items-center no-underline" [ngClass]="{'account-layout-active-row': isActive('app.account.sale')}">
                    <mat-icon [ngClass]="{'inactive-icon': !isActive('app.account.support')}">work</mat-icon>
                    <span class="nav-item-text text-black" i18n>Sales</span>
                </a>
            </mat-list-item> -->

            <mat-list-item *ngIf="(['Merchant'] | qpAccountType) && agreement?.account?.reseller?.support_email && !(['Unzer'] | qpMerchantType)" (click)="routeTo('support')" >
                <div matListItemTitle class="flex-row cg8 justify-start items-center no-underline" [ngClass]="{'account-layout-active-row': isActive('support')}">
                    <mat-icon class="text-grey-2" [ngClass]="{'inactive-icon': !isActive('support')}">mail_outline</mat-icon>
                    <span class="nav-item-text text-black" i18n>Support</span>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="flex-col justify-start items-center">
        <div *ngIf="is_suspended" class="suspended-bar">
            <div *ngIf="(['Quickpay'] | qpMerchantType)" class="text-container" i18n>
                Your account is suspended -
                <a [routerLink]="['settings', 'billing']">Update your billing information</a>
            </div>
            <span *ngIf="!(['Quickpay'] | qpMerchantType)" class="text-container" i18n>Your account is suspended</span>
        </div>
        <div class="router-outlet-wrapper">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
