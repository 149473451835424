import { Pipe, PipeTransform } from '@angular/core';
import { AgreementService } from '@services/agreement/agreement.service';

@Pipe({ name: 'qpAccountType' })
export class QpAccountTypePipe implements PipeTransform {
    constructor(private agreementService: AgreementService) {}

    public transform(accountTypes: string[] | undefined): boolean {
        if (!Array.isArray(accountTypes)) {
            return false;
        }
        const account_type = this.agreementService.agreement?.account?.type ?? '';
        return accountTypes.includes(account_type);
    }
}
