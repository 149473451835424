<h5 i18n>Summary</h5>

<div class="flex-col rg16 code" *ngIf="settlement">
    <div *ngIf="settlement.summary && settlement.summary.sales !== 0" class="flex-row space-between items-center grey-underline">
        <span i18n>Sales</span>
        <span class="code"> {{ settlement.summary.sales | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
    <div *ngIf="settlement.summary && settlement.summary.credits !== 0" class="flex-row space-between items-center grey-underline">
        <span i18n>Credits</span>
        <span class="code"> {{ settlement.summary.credits | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
    <div *ngIf="settlement.summary && settlement.summary.refunds !== 0" class="flex-row space-between items-center grey-underline">
        <span i18n>Refunds</span>
        <span class="code"> {{ settlement.summary.refunds | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
    <div *ngIf="settlement.summary && settlement.summary.chargebacks !== 0" class="flex-row space-between items-center grey-underline">
        <span i18n>Chargebacks</span>
        <span class="code"> {{ settlement.summary.chargebacks | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
    <div *ngIf="settlement.summary && settlement.summary.other_postings !== 0" class="flex-row space-between items-center grey-underline">
        <span i18n>Other postings</span>
        <span class="code"> {{ settlement.summary.other_postings | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
    <div class="flex-row justify-end items-center cg16 grey-underline">
        <span class="subtitle1" i18n>Subtotal:</span>
        <span class="code" *ngIf="settlement.summary"> {{ ( settlement.summary.sales + settlement.summary.credits + settlement.summary.refunds + settlement.summary.chargebacks ) | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>

    <div *ngIf="settlement.summary && settlement.summary.fees !== 0" class="flex-row space-between items-center grey-underline">
        <span i18n>Fees</span>
        <span class="code"> {{ settlement.summary.fees | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
    <div class="flex-row justify-end items-center cg16 grey-underline">
        <span class="subtitle1" i18n>Total:</span>
        <span class="code"> {{ settlement.summary?.net ?? 0 | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
</div>
