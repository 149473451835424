import { ExportFields } from '../dialog/exporter-fields';
import { Injectable } from '@angular/core';
import { OptionalTransactionFieldsParser } from './fields.parser';
import { Subscription } from '@models/subscription';
import { SUBSCRIPTION_HEADER } from './export-header.constant';
import { TransactionService } from '@services/transaction/transaction.service';

@Injectable({
    providedIn: 'root'
})
export class ExporterSubscriptionParser {
    constructor(
        private fieldsParser: OptionalTransactionFieldsParser,
        private transactionService: TransactionService
    ) {}

    public getHeader(): Array<string> {
        return SUBSCRIPTION_HEADER;
    }

    public parse(subscription: Subscription, fields: ExportFields): any {
        const operation = this.transactionService.lastOperation(subscription);
        let data = {
            Id: subscription.id,
            Description: subscription.description,
            Order_id: subscription.order_id,
            Accepted: subscription.accepted ? 'True' : 'False',
            Test_mode: subscription.test_mode ? 'True' : 'False',
            Branding_id: subscription.branding_id,
            Acquirer: subscription.acquirer,
            Facilitator: subscription.facilitator,
            Created_at: subscription.created_at,
            Currency: subscription.currency,
            Type: subscription.metadata.type,
            Brand: subscription.metadata.brand,
            First_6: subscription.metadata.bin,
            Last_4: subscription.metadata.last4,
            Exp_month: subscription.metadata.exp_month,
            Exp_year: subscription.metadata.exp_year,
            Country: subscription.metadata.country,
            Is_3d_secure: subscription.metadata?.is_3d_secure ? 'True' : 'False',
            Customer_ip: subscription.metadata.customer_ip,
            Customer_country: subscription.metadata.customer_country,
            Qp_status_code: operation ? operation.qp_status_code : '',
            Qp_status_msg: operation ? operation.qp_status_msg : '',
            Aq_status_code: operation ? operation.aq_status_code : '',
            Aq_status_msg: operation ? operation.aq_status_msg : '',
            Callback_url: operation ? operation.callback_url : '',
            Callback_success: operation && operation?.callback_success ? operation?.callback_success : '',
            Callback_response_code: operation ? operation.callback_response_code : ''
        };

        data = this.fieldsParser.setData(subscription, fields, data);
        return data;
    }
}
