<div *ngIf="['Merchant'] | qpAccountType">
    <div class="mb-1" *ngIf="!customer?.attributes?.billing_info?.payment_method">
        <div class="small-text">
            <span i18n>Your account is in test mode. You can complete payments using our</span>
            <a href="https://learn.quickpay.net/tech-talk/appendixes/test/" target="_blank"> test cards. </a>
            <div *ngIf="isDaneMerchant; else notDaneMerchant" i18n>
                Activate your account by adding a credit card or selecting an alternative payment method.
            </div>
            <ng-template #notDaneMerchant i18n>
                Activate your account by adding a credit card.
            </ng-template>
        </div>
    </div>

    <div *ngIf="customer?.attributes?.billing_info?.payment_method === 'credit_card' && customer?.attributes?.billing_info?.subscription_info && customer?.attributes?.billing_info?.subscription_info.brand === null">
        <h5 class="text-darkred" i18n>
            Your account is inactive
        </h5>
        <p class="small-text" *ngIf="account?.customer_address?.country_code === 'DNK'" i18n>Add a credit card or select another method to activate your account</p>
        <p class="small-text" *ngIf="account?.customer_address?.country_code !== 'DNK'" i18n>Add a credit card to activate your account</p>
    </div>

    <div class="flex-col">
        <div class="flex-row cg8 no-padding">
            <button mat-raised-button class="justify-center" color="accent" (click)="addCreditCard()" *ngIf="!(customer?.attributes?.billing_info?.subscription_info && customer?.attributes?.billing_info?.subscription_info.brand)" [disabled]="addingPaymentMethod" i18n>
                Add credit card
            </button>
            <button mat-raised-button class="flex-row justify-center" (click)="showOtherMethods()" *ngIf="account?.customer_address?.country_code === 'DNK'" [disabled]="addingPaymentMethod" i18n>
                Select other method
            </button>
            <button mat-raised-button (click)="openSupplierServiceDialog()" *ngIf="customer?.attributes?.billing_info?.payment_method === 'supplier_service'" i18n>
                Supplier service subscription
            </button>

            <button mat-raised-button (click)="pauseAccount()" *ngIf="!customer?.attributes?.on_hold_since && !!customer?.attributes?.billing_info?.payment_method">
                <div class="flex-row cg8 items-center justify-center">
                    <mat-icon>pause_circle_outline</mat-icon>
                    <span i18n>Pause account</span>
                </div>
            </button>

            <button mat-raised-button (click)="unpauseAccount()" *ngIf="!!customer?.attributes?.on_hold_since">
                <div class="flex-row cg8 items-center justify-center">
                    <mat-icon>play_circle_outline</mat-icon>
                    <span i18n>Re-activate account</span>
                </div>
            </button>
        </div>
    </div>
</div>
<div *ngIf="['Reseller'] | qpAccountType">
    <button mat-raised-button color="accent" (click)="addCreditCard()" *ngIf="!(customer?.attributes?.billing_info?.subscription_info && customer?.attributes?.billing_info?.subscription_info.brand)" i18n>
        Add credit card
    </button>
</div>
