<form autocomplete="no">
    <mat-dialog-content class="flex-col rg16 grey-border-bottom">
        <h5 i18n>Refund payment</h5>
        <div class="flex-col">
            <div class="flex-row">
                <p class="flex-50" i18n>Payment ID</p>
                <p>{{ payment.id }}</p>
            </div>
            <div class="flex-row">
                <p class="flex-50" i18n>Order ID</p>
                <p>{{ payment.order_id }}</p>
            </div>
            <div class="flex-row" *ngIf="customer_name">
                <p class="flex-50" i18n>Customer name</p>
                <p>{{ customer_name }}</p>
            </div>
        </div>

        <div *ngIf="payment.acquirer === 'klarnapayments'">
            <mat-form-field>
                <mat-label i18n>Vat rate</mat-label>
                <input id="vat_rate" type="text" class="input-right" required [(ngModel)]="vat_rate" matInput i18n-placeholder placeholder="Vat rate" />
            </mat-form-field>
        </div>

        <mat-radio-group name="type" [(ngModel)]="type" class="flex-col rg12">
            <mat-radio-button value="full" i18n-aria-label  aria-label="Full refund" i18n>
                Full refund ({{ payment.currency }} {{ payment.balance | qpCurrency: payment.currency }})
            </mat-radio-button>
            <mat-radio-button value="partial" i18n-aria-label aria-label="Partial refund" i18n>
                Partial refund
            </mat-radio-button>
        </mat-radio-group>

        <span [hidden]="type === 'full'">
            <div>
                <mat-form-field>
                    <mat-label i18n>Amount</mat-label>
                    <span class="input-left-label">{{ payment.currency }}</span>
                    <input inputDecimal class="input-right" [currency]="payment.currency" matInput name="amount" fxFlex="100" type="text" required i18n-placeholder placeholder="Amount" [(ngModel)]="amount" />
                    <mat-hint *ngIf="payment.balance < amount" class="text-red" i18n>You cannot refund more than the balance</mat-hint>
                </mat-form-field>
            </div>
        </span>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-row">
        <button mat-button mat-dialog-close i18n>Close</button>
        <button mat-button [disabled]="payment.balance < amount" (click)="refund()" color="accent" i18n>Refund payment</button>
    </mat-dialog-actions>
</form>
