export const ALLOW_EXTENSIONS = [
    'bmp',
    'css',
    'eot',
    'gif',
    'jpeg',
    'jpg',
    'liquid',
    'mo',
    'otf',
    'png',
    'po',
    'pot',
    'svg',
    'tif',
    'tiff',
    'ttf',
    'woff',
    'woff2',
    'yml',
    'json',
    'version'
];
