import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '../client';

@Injectable()
export abstract class StatisticsClient extends HttpClient {
    public abstract setHeader(name: string, value: string): any;
    public abstract deleteHeader(name: string): any;
}

/* eslint-disable */
export function statisticsClientFactory(httpClient: HttpClient) {
    return new Client(httpClient, '/statistics', {
        headers: new HttpHeaders({})
    });
}
