export const CURRENCY_ARRAY = [
    { value: 'aed', name: 'AED' },
    { value: 'afn', name: 'AFN' },
    { value: 'all', name: 'ALL' },
    { value: 'amd', name: 'AMD' },
    { value: 'ang', name: 'ANG' },
    { value: 'aoa', name: 'AOA' },
    { value: 'ars', name: 'ARS' },
    { value: 'aud', name: 'AUD' },
    { value: 'awg', name: 'AWG' },
    { value: 'azn', name: 'AZN' },
    { value: 'bam', name: 'BAM' },
    { value: 'bbd', name: 'BBD' },
    { value: 'bdt', name: 'BDT' },
    { value: 'bgn', name: 'BGN' },
    { value: 'bhd', name: 'BHD' },
    { value: 'bif', name: 'BIF' },
    { value: 'bmd', name: 'BMD' },
    { value: 'bnd', name: 'BND' },
    { value: 'bob', name: 'BOB' },
    { value: 'brl', name: 'BRL' },
    { value: 'bsd', name: 'BSD' },
    { value: 'btn', name: 'BTN' },
    { value: 'bwp', name: 'BWP' },
    { value: 'byr', name: 'BYR' },
    { value: 'bzd', name: 'BZD' },
    { value: 'cad', name: 'CAD' },
    { value: 'cdf', name: 'CDF' },
    { value: 'chf', name: 'CHF' },
    { value: 'clp', name: 'CLP' },
    { value: 'cny', name: 'CNY' },
    { value: 'cop', name: 'COP' },
    { value: 'crc', name: 'CRC' },
    { value: 'cuc', name: 'CUC' },
    { value: 'cup', name: 'CUP' },
    { value: 'cve', name: 'CVE' },
    { value: 'czk', name: 'CZK' },
    { value: 'djf', name: 'DJF' },
    { value: 'dkk', name: 'DKK' },
    { value: 'dop', name: 'DOP' },
    { value: 'dzd', name: 'DZD' },
    { value: 'egp', name: 'EGP' },
    { value: 'ern', name: 'ERN' },
    { value: 'etb', name: 'ETB' },
    { value: 'eur', name: 'EUR' },
    { value: 'fjd', name: 'FJD' },
    { value: 'fkp', name: 'FKP' },
    { value: 'gbp', name: 'GBP' },
    { value: 'gel', name: 'GEL' },
    { value: 'ghs', name: 'GHS' },
    { value: 'gip', name: 'GIP' },
    { value: 'gmd', name: 'GMD' },
    { value: 'gnf', name: 'GNF' },
    { value: 'gtq', name: 'GTQ' },
    { value: 'gyd', name: 'GYD' },
    { value: 'hkd', name: 'HKD' },
    { value: 'hnl', name: 'HNL' },
    { value: 'htg', name: 'HTG' },
    { value: 'huf', name: 'HUF' },
    { value: 'idr', name: 'IDR' },
    { value: 'ils', name: 'ILS' },
    { value: 'inr', name: 'INR' },
    { value: 'iqd', name: 'IQD' },
    { value: 'irr', name: 'IRR' },
    { value: 'isk', name: 'ISK' },
    { value: 'jmd', name: 'JMD' },
    { value: 'jod', name: 'JOD' },
    { value: 'jpy', name: 'JPY' },
    { value: 'kes', name: 'KES' },
    { value: 'kgs', name: 'KGS' },
    { value: 'khr', name: 'KHR' },
    { value: 'kmf', name: 'KMF' },
    { value: 'kpw', name: 'KPW' },
    { value: 'krw', name: 'KRW' },
    { value: 'kwd', name: 'KWD' },
    { value: 'kyd', name: 'KYD' },
    { value: 'kzt', name: 'KZT' },
    { value: 'lak', name: 'LAK' },
    { value: 'lbp', name: 'LBP' },
    { value: 'lkr', name: 'LKR' },
    { value: 'lrd', name: 'LRD' },
    { value: 'lsl', name: 'LSL' },
    { value: 'ltl', name: 'LTL' },
    { value: 'lvl', name: 'LVL' },
    { value: 'lyd', name: 'LYD' },
    { value: 'mad', name: 'MAD' },
    { value: 'mdl', name: 'MDL' },
    { value: 'mga', name: 'MGA' },
    { value: 'mkd', name: 'MKD' },
    { value: 'mmk', name: 'MMK' },
    { value: 'mnt', name: 'MNT' },
    { value: 'mop', name: 'MOP' },
    { value: 'mro', name: 'MRO' },
    { value: 'mur', name: 'MUR' },
    { value: 'mvr', name: 'MVR' },
    { value: 'mwk', name: 'MWK' },
    { value: 'mxn', name: 'MXN' },
    { value: 'myr', name: 'MYR' },
    { value: 'mzn', name: 'MZN' },
    { value: 'nad', name: 'NAD' },
    { value: 'ngn', name: 'NGN' },
    { value: 'nio', name: 'NIO' },
    { value: 'nok', name: 'NOK' },
    { value: 'npr', name: 'NPR' },
    { value: 'nzd', name: 'NZD' },
    { value: 'omr', name: 'OMR' },
    { value: 'pab', name: 'PAB' },
    { value: 'pen', name: 'PEN' },
    { value: 'pgk', name: 'PGK' },
    { value: 'php', name: 'PHP' },
    { value: 'pkr', name: 'PKR' },
    { value: 'pln', name: 'PLN' },
    { value: 'pyg', name: 'PYG' },
    { value: 'qar', name: 'QAR' },
    { value: 'ron', name: 'RON' },
    { value: 'rsd', name: 'RSD' },
    { value: 'rub', name: 'RUB' },
    { value: 'rwf', name: 'RWF' },
    { value: 'sar', name: 'SAR' },
    { value: 'sbd', name: 'SBD' },
    { value: 'scr', name: 'SCR' },
    { value: 'sdg', name: 'SDG' },
    { value: 'sek', name: 'SEK' },
    { value: 'sgd', name: 'SGD' },
    { value: 'shp', name: 'SHP' },
    { value: 'sll', name: 'SLL' },
    { value: 'sos', name: 'SOS' },
    { value: 'srd', name: 'SRD' },
    { value: 'ssp', name: 'SSP' },
    { value: 'std', name: 'STD' },
    { value: 'syp', name: 'SYP' },
    { value: 'szl', name: 'SZL' },
    { value: 'thb', name: 'THB' },
    { value: 'tjs', name: 'TJS' },
    { value: 'tmt', name: 'TMT' },
    { value: 'tnd', name: 'TND' },
    { value: 'top', name: 'TOP' },
    { value: 'try', name: 'TRY' },
    { value: 'ttd', name: 'TTD' },
    { value: 'twd', name: 'TWD' },
    { value: 'tzs', name: 'TZS' },
    { value: 'uah', name: 'UAH' },
    { value: 'ugx', name: 'UGX' },
    { value: 'usd', name: 'USD' },
    { value: 'uyu', name: 'UYU' },
    { value: 'uzs', name: 'UZS' },
    { value: 'vef', name: 'VEF' },
    { value: 'vnd', name: 'VND' },
    { value: 'vuv', name: 'VUV' },
    { value: 'wst', name: 'WST' },
    { value: 'xaf', name: 'XAF' },
    { value: 'xcd', name: 'XCD' },
    { value: 'xof', name: 'XOF' },
    { value: 'xpf', name: 'XPF' },
    { value: 'yer', name: 'YER' },
    { value: 'zar', name: 'ZAR' },
    { value: 'zmw', name: 'ZMW' },
    { value: 'zmk', name: 'ZMW' },
    { value: 'zwl', name: 'ZWL' }
];
