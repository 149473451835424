import { AccessControlPermission, AclPermission } from '@interfaces/acl-permission';
import { AclResourcesRepo } from 'app/endpoints/api/acl-resources-repo';
import { Agreement } from '@interfaces/agreement';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { AgreementService } from '@services/agreement/agreement.service';
import { catchError, filter, tap, switchMap } from 'rxjs/operators';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DialogService } from '@widgets/dialog/dialog.service';
import { EMPTY, Observable, of } from 'rxjs';
import { PermissionTemplatesService } from '@services/permission-templates/permission-templates.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { UnsavedChanges } from 'app/routing/guards/unsaved-changes.interface';

@Component({
    selector: 'qp-user-permissions',
    styleUrls: ['permissions.component.scss'],
    templateUrl: 'permissions.component.html'
})
export class UserPermissionsComponent implements OnInit, UnsavedChanges {
    @Output() public agreementChange = new EventEmitter();
    @Input() public support: boolean;

    @Input()
    public get agreement(): Agreement {
        return this.user_agreement;
    }
    public set agreement(val) {
        this.user_agreement = val;
        this.agreementChange.emit(this.user_agreement);
    }

    public dataSource: Array<AclPermission> = [];
    public displayedColumns: Array<string> = ['resource', 'description', 'get', 'post', 'patch', 'put', 'delete'];
    public permissions: Array<AclPermission> = [];
    public user_agreement: Agreement;
    public isDirty = false;
    public isSaved = false;

    constructor(
        private aclResourceRepo: AclResourcesRepo,
        private agreementRepo: AgreementRepo,
        private dialogService: DialogService,
        private permissionTemplateService: PermissionTemplatesService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        this.setupModels();
    }

    public hasUnsavedChanges(): Observable<boolean> {
        return of(this.isDirty);
    }

    public setupModels(): void {
        this.aclResourceRepo.getAll(
            this.agreementService.agreement?.account?.type || ''
        ).subscribe((permissions: AclPermission[]) => {
            permissions.forEach((permission: AclPermission) => {
                if (permission.actions.indexOf('GET') !== -1) {
                    permission['get'] = false;
                }
                if (permission.actions.indexOf('POST') !== -1) {
                    permission['post'] = false;
                }
                if (permission.actions.indexOf('PUT') !== -1) {
                    permission['put'] = false;
                }
                if (permission.actions.indexOf('PATCH') !== -1) {
                    permission['patch'] = false;
                }
                if (permission.actions.indexOf('DELETE') !== -1) {
                    permission['delete'] = false;
                }
            });
            this.permissions = permissions;
            this.setUserPermissions(this.agreement);
        });
    }

    public setUserPermissions(agreement: Agreement): void {
        if (!agreement.acl_permissions) {
            return;
        }

        agreement.acl_permissions.forEach((aclPermission: AccessControlPermission) => {
            this.permissions.forEach((permission: AclPermission) => {
                if (aclPermission.resource === permission.resource) {
                    if (permission.hasOwnProperty('get') && aclPermission.hasOwnProperty('get')) {
                        permission.get = aclPermission.get;
                    }
                    if (permission.hasOwnProperty('post') && aclPermission.hasOwnProperty('post')) {
                        permission.post = aclPermission.post;
                    }
                    if (permission.hasOwnProperty('put') && aclPermission.hasOwnProperty('put')) {
                        permission.put = aclPermission.put;
                    }
                    if (permission.hasOwnProperty('patch') && aclPermission.hasOwnProperty('patch')) {
                        permission.patch = aclPermission.patch;
                    }
                    if (permission.hasOwnProperty('delete') && aclPermission.hasOwnProperty('delete')) {
                        permission['delete'] = aclPermission['delete'];
                    }
                }
            });
        });

        this.dataSource = this.permissions;
    }

    public setDirty(): void {
        this.isDirty = true;
        this.isSaved = false;
    }

    public updateOwner(): void {
        let header: string;
        if (!this.agreement.owner) {
            header = $localize`Are you sure you want to remove the owner role from the user?`;
        } else {
            header = $localize`Select if the user should be owner of this account`;
        }

        let agreements: Agreement[] = [];
        this.agreementRepo.getAll({ page: 1, page_size: 100 }, undefined).subscribe((agreementArray: Agreement[]) => {
            agreements = agreementArray;
        });

        this.dialogService.confirm(
            header,
            $localize`Please note: An owner automatically has all permissions enabled`,
            $localize`Ok`
        ).afterClosed().pipe(
            switchMap((confirmed: boolean) => {
                if (confirmed) {
                    return this.updateAgreement(agreements);
                } else {
                    this.agreement.owner = !this.agreement.owner;
                    return EMPTY;
                }
            })
        ).subscribe(() => {
            this.setupModels();
            this.snackBar.open($localize`Owner settings updated`);
        });
    }

    private updateAgreement(agreements: Agreement[]): Observable<Agreement> {
        return this.agreementRepo.update(this.agreement).pipe(
            tap((agreement: Agreement) => {
                agreements[agreements.indexOf(this.agreement)] = agreement;
                this.agreement = agreement;
            }),
            catchError(() => {
                this.agreement.owner = !this.agreement.owner;
                return EMPTY;
            })
        );
    }

    public selectPermissionTemplate(): void {
        const fields: PromptField[] = [
            {
                label: $localize`Permission template`,
                required: true,
                type: 'select',
                name: 'template',
                groups: this.permissionTemplateChoises()
            },
            {
                label: $localize`Select update method`,
                required: true,
                type: 'radiogroup',
                name: 'type',
                choices: [
                    {
                        value: 'add',
                        text: $localize`Add missing permissions`,
                        default: true
                    },
                    {
                        value: 'overwrite',
                        text: $localize`Replace existing permissions with template permissions`
                    }
                ]
            }
        ];

        this.dialogService.prompt(
            $localize`Select template`,
            $localize`Use a predefined template to update the users permissions`,
            $localize`Update`,
            fields
        ).afterClosed().pipe(
            filter(value => value !== undefined),
            switchMap((returnFields: PromptField[]) => {
                if (returnFields[1].value === 'add') {
                    if (this.agreement.acl_permissions?.length) {
                        this.agreement.acl_permissions = this.mergePermissions(
                            this.agreement.acl_permissions,
                            returnFields[0].value
                        );
                    } else {
                        this.agreement.acl_permissions = returnFields[0].value;
                    }
                } else {
                    this.agreement.acl_permissions = returnFields[0].value;
                }

                return this.agreementRepo.replace(this.agreement);
            })
        ).subscribe(() => {
            this.setupModels();
            this.snackBar.open($localize`Permissions updated`);
        });
    }

    public permissionTemplateChoises(): Array<{ name: string; choices: any[] }> {
        let templateChoises: any[] = [];

        const permission_templates = this.agreementService.agreement?.account?.type
            ? this.permissionTemplateService.getTemplates()[this.agreementService.agreement.account.type]
            : undefined;

        for (const key in permission_templates) {
            if (permission_templates.hasOwnProperty(key)) {
                const template = permission_templates[key];
                const group: { name: string; choices: any[] } = { name: key, choices: [] };

                for (const groupKey in template) {
                    if (template.hasOwnProperty(groupKey)) {
                        group.choices.push({
                            text: template[groupKey].name,
                            value: template[groupKey].acl_permissions
                        });
                    }
                }

                templateChoises.push(group);
            }
        }

        const rolesTemplate = templateChoises.find(template => template.name === 'Roles');
        if (this.agreement.user?.system_user) {
            templateChoises = templateChoises.filter(obj => obj !== rolesTemplate);
        } else {
            templateChoises = [];
            templateChoises.push(rolesTemplate);
        }

        return templateChoises;
    }

    public mergePermissions(
        userACL: Array<AccessControlPermission>,
        templateACL: Array<AccessControlPermission>
    ): Array<AccessControlPermission> {
        const mergedPermissionsMap = new Map<string, AccessControlPermission>();

        userACL.forEach((permission: AccessControlPermission) => {
            mergedPermissionsMap.set(permission.resource, { ...permission });
        });

        templateACL.forEach((new_permission: AccessControlPermission) => {
            const existingPermission = mergedPermissionsMap.get(new_permission.resource);

            if (existingPermission) {
                if (new_permission.get === true) existingPermission.get = true;
                if (new_permission.post === true) existingPermission.post = true;
                if (new_permission.put === true) existingPermission.put = true;
                if (new_permission.patch === true) existingPermission.patch = true;
                if (new_permission.delete === true) existingPermission.delete = true;
            } else {
                mergedPermissionsMap.set(new_permission.resource, { ...new_permission });
            }
        });

        return Array.from(mergedPermissionsMap.values());
    }

    public save(): void {
        this.agreement.acl_permissions = this.permissions.map(permission => ({
            resource: permission.resource,
            get: permission.get || false,
            post: permission.post || false,
            put: permission.put || false,
            patch: permission.patch || false,
            delete: permission['delete'] || false
        }));

        this.agreementRepo.replace(this.agreement).pipe(
            tap((agreement: Agreement) => this.setUserPermissions(agreement)),
            catchError(() => {
                this.setupModels();
                return EMPTY;
            })
        ).subscribe(() => {
            this.isDirty = false;
            this.isSaved = true;
            this.snackBar.open($localize`Permissions updated`);
        });
    }
}
