import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { GithubClient, githubClientFactory } from './github-client';
import { GithubApiClient, githubApiClientFactory } from './github-api-client';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: GithubClient,
            useFactory: githubClientFactory,
            deps: [HttpClient]
        },
        {
            provide: GithubApiClient,
            useFactory: githubApiClientFactory,
            deps: [HttpClient]
        }
    ]
})
export class GithubModule {}
