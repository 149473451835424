import { Component, OnInit } from '@angular/core';
import { SubscriptionGroupRepo } from '@endpoints/api/subscription-group-repo';
import { SubscriptionGroup } from '@models/subscription-group';
import { SubscriptionGroupResult } from '@models/subscription-group-result';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { filter, switchMap, tap } from 'rxjs';
import { SubscriptionGroupDialogComponent } from '../transaction-dialogs/subscriptiongroup-dialog/subscriptiongroup-dialog';
import { TransactionsService } from '../transactions.service';

@Component({
    selector: 'qp-subscription-group',
    templateUrl: './subscription-group.html',
    animations: [Animations.getFadeAnimation(), Animations.getDetailExpandAnimation()]
})
export class SubscriptionGroupComponent extends BaseLoadingDirective implements OnInit {
    public subscription_group: Array<SubscriptionGroup> = [];
    public showRow: Array<boolean> = [];

    constructor(
        private dialogService: DialogService,
        private snackBar: QpSnackBar,
        private subscriptionGroupRepo: SubscriptionGroupRepo,
        private transactionService: TransactionsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.getSubscriptionGroups();
    }

    public getSubscriptionGroups(): void {
        this.subscriptionGroupRepo.getAll({ page_size: 100 }).subscribe((subscription_groups: SubscriptionGroup[]) => {
            this.showRow = [];
            this.showRow = subscription_groups.map(() => false);
            this.subscription_group = subscription_groups;
        });
    }

    public createGroup(): void {
        this.dialogService.component(
            SubscriptionGroupDialogComponent
        ).afterClosed().pipe(
            filter(value => value !== undefined),
            switchMap((group: SubscriptionGroup) => this.subscriptionGroupRepo.create(group)),
            tap((return_group: SubscriptionGroup) => {
                this.subscription_group.push(return_group);
                this.snackBar.open($localize`Added new group: ` + return_group.name);
            })
        ).subscribe();
    }


    public updateGroup(group: SubscriptionGroup): void {
        this.dialogService.component(
            SubscriptionGroupDialogComponent,
            Object.assign({}, group)
        ).afterClosed().pipe(
            filter(value => value !== undefined),
            switchMap((group: SubscriptionGroup) => this.subscriptionGroupRepo.update(group)),
            tap((return_group: SubscriptionGroup) => {
                const index = this.subscription_group.findIndex(group => group.id == return_group.id);
                this.subscription_group[index] = return_group;
                this.snackBar.open($localize`group: ` + return_group.name + ' updated.');
            })
        ).subscribe();
    }

    public deleteGroup(group: SubscriptionGroup): void {
        this.dialogService.confirm(
            $localize`Remove group`,
            $localize`Are you sure you want to remove ` + group.name + '?',
            $localize`Delete`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() => this.subscriptionGroupRepo.delete(group.id)),
            tap(() => {
                this.snackBar.open('Group removed');
                this.getSubscriptionGroups();
            })
        ).subscribe();
    }

    public captureGroup(group: SubscriptionGroup): void {
        this.transactionService.captureSubscriptionGroup(group).subscribe((result: SubscriptionGroupResult[]) => {
            let success = '';
            let msg = '';

            if (result) {
                for (const key in result) {
                    if (result.hasOwnProperty(key)) {
                        const row: SubscriptionGroupResult = result[key];
                        if (row.message) {
                            let err = '';

                            if (row.errors) {
                                Object.keys(row.errors).forEach(error => {
                                    err += `${error} ${row.errors[error]}\n`;
                                });
                            }

                            msg += $localize`Subscription id: ${row.subscription_id} ${row.message} \n ${err} \n`;
                        } else {
                            success += `${row.subscription_id}\n`;
                        }
                    }
                }
            }

            if (msg !== '') {
                const replaceMsg = msg.replace('undefined', '');
                this.dialogService.alert(
                    $localize`The capture request has been processed`,
                    $localize`The request was rejected for the following subscriptions:` +
                    '\n' +
                    replaceMsg +
                    $localize`The request was approved for the following subscriptions:` +
                    '\n' +
                    success
                );
            } else {
                this.dialogService.alert(
                    $localize`The capture request has been processed`,
                    $localize`All subscriptions are successfully captured`
                );
            }
        });
    }
}
