import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ParamService {
    public prev_params: any;

    constructor(
        private router: Router
    ) {}

    public setParams(params: any, activatedRoute: ActivatedRoute): void {
        this.spliceParams(params);

        this.router.navigate([], {
            queryParams: params,
            queryParamsHandling: 'merge',
            relativeTo: activatedRoute
        });
    }

    public returnPrevParam(): any {
        if (!!this.prev_params) {
            const return_params = JSON.parse(JSON.stringify(this.prev_params));
            return return_params;
        }
        return {};
    }

    public spliceParams(params: any): void {
        if (this.prev_params) {
            Object.keys(params).forEach(new_param => {
                let found = false;
                Object.keys(params).forEach(prev_param => {
                    if (new_param === prev_param) {
                        this.prev_params[prev_param] = params[new_param];
                        found = true;
                    }
                });

                if (!found) {
                    this.prev_params[new_param] = params[new_param];
                }
            });
        } else {
            this.prev_params = params;
        }
    }
}
