<div class="flex-row justify-center">
    <mat-card class="flex-col justify-start items-center rg32 flex-632px-mobile-100 card02">
        <mat-card-title i18n> Shopify Success  </mat-card-title>

        <div class="flex-col rg16 justify-center items-start">
            <h2 i18n>Integration Succesfull</h2>
            <div class="flex-row">
                <span i18n> Shopify app has succesfully been integrated, you can now close the tab </span>
            </div>
        </div>
    </mat-card>
</div>
