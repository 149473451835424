import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Company } from '@models/clearhaus-application/ch-instant';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { AgreementService } from '@services/agreement/agreement.service';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';
import { SessionService } from '@services/session/session.service';

@Component({
    selector: 'qp-clearhaus-company-form',
    templateUrl: './company-instant.html'
})
export class ClearhausInstantSignupFormStepTwoComponent implements AfterViewInit {
    @Output() public companyChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _company: Company = new Company();

    public companyForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        address: new UntypedFormControl('', Validators.required),
        zipcode: new UntypedFormControl('', Validators.required),
        city: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl('', Validators.required),
        registration: new UntypedFormControl('', Validators.required),
        phone: new UntypedFormControl('', Validators.required)
    });
    public supportedCountries: Country[];

    public get address(): UntypedFormControl {
        return this.companyForm.get('address') as UntypedFormControl;
    }
    public get city(): UntypedFormControl {
        return this.companyForm.get('city') as UntypedFormControl;
    }
    public get country(): UntypedFormControl {
        return this.companyForm.get('country') as UntypedFormControl;
    }
    public get registration(): UntypedFormControl {
        return this.companyForm.get('registration') as UntypedFormControl;
    }
    public get name(): UntypedFormControl {
        return this.companyForm.get('name') as UntypedFormControl;
    }
    public get zipcode(): UntypedFormControl {
        return this.companyForm.get('zipcode') as UntypedFormControl;
    }
    public get phone(): UntypedFormControl {
        return this.companyForm.get('phone') as UntypedFormControl;
    }

    @Input() public get company(): Company {
        return this._company;
    }
    public set company(value: Company) {
        this._company = value;
        this.companyChange.emit(value);
    }

    constructor(
        private countryService: CountryService,
        public sessionService: SessionService,
        private agreementService: AgreementService
    ) {}

    public ngAfterViewInit(): void {
        if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
            this.supportedCountries = this.countryService.getUnzerCountries();
        } else {
            this.supportedCountries = this.countryService.getEU28Countries();
        }

        this.companyForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(this.companyForm.valid);
        });

        this.address.setValue(this.company?.address_line_1);
        this.city.setValue(this.company?.city);
        this.country.setValue(this.company?.country?.length === 2 ? this.convertToAlpha3(this.company?.country) : this.company?.country);
        this.registration.setValue(this.company.registration_number);
        this.name.setValue(this.company.name);
        this.zipcode.setValue(this.company.zipcode);
        this.phone.setValue(this.company.phone);
        this.validityChange.emit(this.companyForm.valid);
    }

    public convertToAlpha3(value: string): string {
        return this.countryService.alpha2ToAlpha3(value) ?? '';
    }

    public onCountryChange(value: any): void {
        this.company = { ...this.company, country: value };
    }

    public modelCompany(propName: keyof Company, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        this.company = { ...this.company, [propName]: input.value };
    }
}
