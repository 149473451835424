import { Acquirers } from './acquirers';

export interface AcquirerData {
    id: string; // facilitators is '' or same as acquirer // This will also be fased out from the backend at some point
    title: string;
    tpl: string;
    acquirer_type: keyof Acquirers,
    logos: {
        path: string;
        height: number;
        width: number;
    }[];
    website_link?: string;
    card_acquirer: boolean;
}

const base_path = 'assets/images/acquirers/';

export const ACQUIRERDATA: { [key: string]: AcquirerData } = {
    anydaysplit: {
        id: '25',
        title: 'Anyday',
        tpl: 'anydaysplit',
        acquirer_type: 'anydaysplit',
        logos: [{
            path: base_path + 'anydaysplit.svg',
            height: 14,
            width: 54
        }],
        website_link: 'payment-methods/anyday/',
        card_acquirer: false
    },
    applepay: {
        id: '',
        title: 'Apple Pay',
        tpl: 'applepay',
        acquirer_type: 'clearhaus',
        logos: [{
            path: base_path + 'applepay-icon.svg',
            height: 20.5,
            width: 50
        }],
        website_link: 'payment-methods/apple-pay/',
        card_acquirer: false
    },
    bambora: {
        id: '13',
        title: 'Bambora',
        tpl: 'bambora',
        acquirer_type: 'bambora',
        logos: [{
            path: base_path + 'bambora.svg',
            height: 8.7,
            width: 50
        }],
        website_link: 'helpdesk/acquirers/bambora/',
        card_acquirer: true
    },
    clearhaus: {
        id: '3',
        title: 'Clearhaus',
        tpl: 'clearhaus',
        acquirer_type: 'clearhaus',
        logos: [{
            path: base_path + 'clearhaus.png',
            height: 14,
            width: 54
        }],
        website_link: 'helpdesk/acquirers/clearhaus/',
        card_acquirer: true
    },
    elavon: {
        id: '15',
        title: 'Elavon',
        tpl: 'elavon',
        acquirer_type: 'elavon',
        logos: [{
            path: base_path + 'elavon.svg',
            height: 26,
            width: 54
        }],
        card_acquirer: true
    },
    visa_mastercard: { // same as clearhaus but only for unzer merchants
        id: '3',
        title: 'Visa and Mastercard',
        tpl: 'visa-mastercard',
        acquirer_type: 'clearhaus',
        logos: [{
            path: base_path + 'visa-mastercard.png',
            height: 14,
            width: 54
        }],
        card_acquirer: true
    },
    googlepay: {
        id: '',
        title: 'Google Pay',
        tpl: 'googlepay',
        acquirer_type: 'clearhaus',
        logos: [{
            path: base_path + 'googlepay-icon.svg',
            height: 23.8,
            width: 50
        }],
        website_link: 'payment-methods/google-pay/',
        card_acquirer: false
    },
    handelsbanken: {
        id: '14',
        title: 'Handelsbanken',
        tpl: 'handelsbanken',
        acquirer_type: 'handelsbanken',
        logos: [{
            path: base_path + 'handelsbanken.svg',
            height: 5.1,
            width: 50
        }],
        website_link: 'helpdesk/acquirers/handelsbanken/',
        card_acquirer: true
    },
    klarnapayments: {
        id: '22',
        title: 'Klarna Payments',
        tpl: 'klarnapayments',
        acquirer_type: 'klarnapayments',
        logos: [{
            path: base_path + 'klarnapayments.svg',
            height: 20,
            width: 50
        }],
        website_link: 'helpdesk/acquirers/klarna-payments/',
        card_acquirer: false
    },
    mobilepayonline: {
        id: '4',
        title: 'MobilePay Online',
        tpl: 'mobilepayonline',
        acquirer_type: 'mobilepayonline',
        logos: [{
            path: base_path + 'mobilepay-icon.svg',
            height: 16.1,
            width: 49.8
        }],
        website_link: 'payment-methods/mobilepay-online/',
        card_acquirer: false
    },
    vmpepayment: {
        id: '',
        title: 'Vipps MobilePay',
        tpl: 'vmpepayment',
        acquirer_type: 'vmpepayment',
        logos: [
            {
                path: base_path + 'vipps.svg',
                height: 20.1,
                width: 50
            },
            {
                path: base_path + 'mobilepay-icon.svg',
                height: 16.1,
                width: 49.8
            }
        ],
        card_acquirer: false,
        website_link: 'payment-methods/vipps-mobilepay/'
    },
    mobilepaysubscriptions: {
        id: '23',
        title: 'MobilePay Subscriptions',
        tpl: 'mobilepaysubscriptions',
        acquirer_type: 'mobilepaysubscriptions',
        logos: [{
            path: base_path + 'mobilepay-icon.svg',
            height: 16.1,
            width: 49.8
        }],
        website_link: 'payment-methods/mobilepay-subscriptions/',
        card_acquirer: false
    },
    nets: {
        id: '1',
        title: 'Nets - Dankort',
        tpl: 'nets',
        acquirer_type: 'nets',
        logos: [{
            path: base_path + 'nets.png',
            height: 15,
            width: 52
        }],
        website_link: 'helpdesk/acquirers/nets/',
        card_acquirer: true
    },
    paypal: {
        id: '5',
        title: 'PayPal',
        tpl: 'paypal',
        acquirer_type: 'paypal',
        logos: [{
            path: base_path + 'paypal.svg',
            height: 14.5,
            width: 54
        }],
        website_link: 'helpdesk/acquirers/paypal/',
        card_acquirer: false
    },
    paypalpayments: {
        id: '5',
        title: 'PayPal Payments',
        tpl: 'paypalpayments',
        acquirer_type: 'paypalpayments',
        logos: [{
            path: base_path + 'paypal.svg',
            height: 14.5,
            width: 54
        }],
        website_link: 'helpdesk/acquirers/paypal/',
        card_acquirer: false
    },
    paysafecard: {
        id: '9',
        title: 'Paysafecard',
        tpl: 'paysafecard',
        acquirer_type: 'paysafecard',
        logos: [{
            path: base_path + 'paysafecard.svg',
            height: 9.1,
            width: 51.2
        }],
        website_link: 'payment-methods/paysafecard/',
        card_acquirer: false
    },
    resurs: {
        id: '21',
        title: 'Resursbank',
        tpl: 'resurs',
        acquirer_type: 'resurs',
        logos: [{
            path: base_path + 'resurs.png',
            height: 18.9,
            width: 49.6
        }],
        website_link: 'payment-methods/resurs-bank/',
        card_acquirer: false
    },
    sofort: {
        id: '7',
        title: 'Sofort',
        tpl: 'sofort',
        acquirer_type: 'sofort',
        logos: [{
            path: base_path + 'sofort.png',
            height: 9.6,
            width: 53.1
        }],
        website_link: 'payment-methods/klarna-pay-now/',
        card_acquirer: false
    },
    swedbank: {
        id: '12',
        title: 'Swedbank',
        tpl: 'swedbank',
        acquirer_type: 'swedbank',
        logos: [{
            path: base_path + 'swedbank.png',
            height: 11,
            width: 50
        }],
        website_link: 'helpdesk/acquirers/swedbank/',
        card_acquirer: true
    },
    swish: {
        id: '17',
        title: 'Swish',
        tpl: 'swish',
        acquirer_type: 'swish',
        logos: [{
            path: base_path + 'swish.svg',
            height: 37.7,
            width: 28
        }],
        website_link: 'payment-methods/swish/',
        card_acquirer: false
    },
    teller: {
        id: '11',
        title: 'Nets (Teller) - Visa and Mastercard',
        tpl: 'teller',
        acquirer_type: 'teller',
        logos: [{
            path: base_path + 'nets.png',
            height: 15,
            width: 52
        }],
        website_link: 'helpdesk/acquirers/teller/',
        card_acquirer: true
    },
    trustly: {
        id: '19',
        title: 'Trustly',
        tpl: 'trustly',
        acquirer_type: 'trustly',
        logos: [{
            path: base_path + 'trustly.svg',
            height: 16.6,
            width: 50
        }],
        website_link: 'payment-methods/trustly/',
        card_acquirer: false
    },
    unzerpaylater: {
        id: '27',
        title: 'Unzer Pay Later',
        tpl: 'unzer-pay-later',
        acquirer_type: 'unzerpaylater',
        logos: [{
            path: base_path + 'unzer-invoice.png',
            height: 14,
            width: 54
        }],
        card_acquirer: false
    },
    unzerinvoice: {
        id: '',
        title: 'Unzer Invoice',
        tpl: 'unzer-invoice',
        acquirer_type: 'unzerpaylater',
        logos: [{
            path: base_path + 'unzer-invoice.png',
            height: 27.7,
            width: 44.7
        }],
        card_acquirer: false
    },
    unzerdirectdebit: {
        id: '',
        title: 'Unzer Direct Debit',
        tpl: 'unzer-direct-debit',
        acquirer_type: 'unzerpaylater',
        logos: [{
            path: base_path + 'unzer-direct-debit.png',
            height: 27.7,
            width: 44.7
        }],
        card_acquirer: false
    },
    valitor: {
        id: '16',
        title: 'Valitor',
        tpl: 'valitor',
        acquirer_type: 'valitor',
        logos: [{
            path: base_path + 'valitor.png',
            height: 7.6,
            width: 50
        }],
        card_acquirer: true
    },
    viabill: {
        id: '6',
        title: 'ViaBill',
        tpl: 'viabill',
        acquirer_type: 'viabill',
        logos: [{
            path: base_path + 'viabill.png',
            height: 10.4,
            width: 50
        }],
        website_link: 'payment-methods/viabill/',
        card_acquirer: false
    },
    vipps: {
        id: '20',
        title: 'Vipps',
        tpl: 'vipps',
        acquirer_type: 'vipps',
        logos: [{
            path: base_path + 'vipps.svg',
            height: 20.1,
            width: 50
        }],
        website_link: 'payment-methods/vipps/',
        card_acquirer: false
    },
    vippspsp: {
        id: '',
        title: 'Vipps via Quickpay',
        tpl: 'vippspsp',
        acquirer_type: 'vippspsp',
        logos: [{
            path: base_path + 'vipps.svg',
            height: 20.1,
            width: 50
        }],
        website_link: 'payment-methods/vipps/ ',
        card_acquirer: false
    },
    pprowechatpay: {
        id: '26',
        title: 'WeChat Pay',
        tpl: 'pprowechatpay',
        acquirer_type: 'pprowechatpay',
        card_acquirer: false,
        logos: [{
            path: base_path + 'wechat.png',
            height: 17,
            width: 54
        }]
    },
    pprobancontact: {
        id: '26',
        title: 'Bancontact',
        tpl: 'pprobancontact',
        acquirer_type: 'pprobancontact',
        card_acquirer: false,
        logos: [{
            path: base_path + 'bancontact.png',
            height: 40,
            width: 54
        }]
    },
    pproprzelewy24: {
        id: '26',
        title: 'Przelewy24 (P24)',
        tpl: 'pproprzelewy24',
        acquirer_type: 'pproprzelewy24',
        card_acquirer: false,
        logos: [{
            path: base_path + 'przelewy24.png',
            height: 18,
            width: 54
        }]
    },
    pproblik: {
        id: '26',
        title: 'Blik',
        tpl: 'pproblik',
        card_acquirer: false,
        acquirer_type: 'pproblik',
        logos: [{
            path: base_path + 'blik.png',
            height: 25,
            width: 54
        }]
    },
    pprosofort: {
        id: '26',
        title: 'Sofort',
        tpl: 'pprosofort',
        acquirer_type: 'pprosofort',
        card_acquirer: false,
        logos: [{
            path: base_path + 'sofort.png',
            height: 9.6,
            width: 53.1
        }]
    }
};
