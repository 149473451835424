import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@helpers/windowprovider/window-provider';
import { Branding, BrandingResource } from '@models/branding';
import { BrandingService } from '@services/branding/branding.service';
import { DownloaderService } from '@services/downloader/downloader.service';
import { FileService } from '@services/file/file.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { Animations } from 'app/animations/animations';
import { BrandingRepo } from 'app/endpoints/api/branding-repo';
import { forkJoin } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'qp-branding',
    styleUrls: ['./branding.scss'],
    templateUrl: 'branding.html',
    animations: [Animations.getFadeAnimation(), Animations.getDetailExpandAnimation()]
})
export class BrandingComponent extends BaseLoadingDirective implements OnInit {
    @ViewChild('showImageRef') public showImageRef: TemplateRef<any>;

    public branding: Branding = new Branding();
    public selected_resource: BrandingResource | undefined;
    public branding_resource: Array<BrandingResource> = [];
    public imageUrl: SafeResourceUrl;
    public show_code_files = false;
    public show_translations_files = false;
    public show_image_files = false;

    constructor(
        private brandingRepo: BrandingRepo,
        private brandingService: BrandingService,
        private dialogService: DialogService,
        private downloaderService: DownloaderService,
        private qpFile: FileService,
        private sanitizer: DomSanitizer,
        private snackBar: QpSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        @Inject(WINDOW) private window: Window
    ) {
        super();
    }

    public ngOnInit(): void {
        this.getBranding();
    }

    public getBranding(): void {
        this.brandingRepo.get(this.activatedRoute.snapshot.params.branding_id).subscribe((branding: Branding) => {
            this.branding = branding;
            this.branding_resource = branding.resources;
            this.setBrandingResourceType();
        });
    }

    public setBrandingResourceType(): void {
        this.branding_resource.forEach((resource: BrandingResource) => {
            const str: string = resource.name.split('.').pop() as string;
            if (['pot', 'po', 'mo'].includes(str)) {
                resource.type = 'translation';
            } else if (!!resource.mime && resource.mime.indexOf('image') === 0) {
                resource.type = 'image';
            } else {
                resource.type = 'code';
            }
        });

        if (this.branding_resource.length > 2) {
            this.branding_resource[0].type = undefined;
            this.branding_resource[1].type = undefined;
        }
    }

    public getBrandingResource(condition: string): Array<BrandingResource> {
        return this.branding_resource.filter(r => r.type === condition);
    }

    public deleteBranding(): void {
        this.dialogService.confirm(
            $localize`Delete branding`,
            $localize`Sure you want to delete this branding?`,
            $localize`Ok`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() => this.brandingRepo.destroy(this.branding.id ?? NaN)),
            tap(() => this.router.navigate(['../../brandings'], { relativeTo: this.activatedRoute }))
        ).subscribe();
    }

    public downloadFile(resource: BrandingResource): void {
        this.brandingRepo.getResource(this.branding.id ?? NaN, resource.name, 'blob').subscribe((data: any) => {
            this.downloaderService.saveFile(data, resource.name);
        });
    }

    public downloadBranding(): void {
        const resourceObservables = this.branding.resources.map(resource =>
            this.brandingRepo.getResource(
                this.branding?.id ?? NaN,
                resource.name,
                'blob'
            ).pipe(map(arrayBuffer => Object.assign(arrayBuffer, { name: resource.name })))
        );
        forkJoin(resourceObservables).subscribe(branding => {
            this.downloaderService.saveFiles(branding, 'branding_' + this.branding.name);
        });
    }

    public deleteFile(resource: BrandingResource): void {
        this.dialogService.confirm(
            $localize`Delete file`,
            $localize`Sure you want to delete this file: ${resource.name} ?`,
            $localize`Delete`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() => this.brandingRepo.deleteFile(this.branding.id ?? NaN, resource.name))
        ).subscribe(() => {
            this.branding.resources = this.branding.resources.filter(r => r.name !== resource.name);
            this.branding_resource = this.branding_resource.filter(r => r.name !== resource.name);
        });
    }

    public showImage(resource: BrandingResource): void {
        this.brandingRepo.getResource(this.branding.id ?? NaN, resource.name, 'blob').subscribe((data: ArrayBuffer) => {
            const urlCreator = URL || (this.window as any).webkitURL;
            const imgUrl = urlCreator.createObjectURL(data as any);
            this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imgUrl);
            this.dialogService.template(resource.name, this.showImageRef);
        });
    }

    public renameBranding(): void {
        const fields: PromptField[] = [
            {
                value: this.branding.name,
                placeholder: $localize`Name`,
                label: $localize`New name`,
                required: true,
                type: 'text',
                name: 'name',
                helpText: $localize`Please write a new name`
            }
        ];

        this.dialogService.prompt(
            $localize`New name`,
            '',
            $localize`Save`,
            fields
        ).afterClosed().pipe(
            filter(value => value !== undefined),
            tap((returnFields: PromptField[]) => this.branding.name = returnFields[0].value),
            switchMap(() => this.brandingRepo.update(this.branding))
        ).subscribe(() => {
            this.snackBar.open($localize`Name updated`);
        });
    }

    public newFile(eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const inputElement = eventTarget as HTMLInputElement;
        const fileToUpload = inputElement.files?.item(0);
        if (!fileToUpload) {
            return;
        }
        if (this.selected_resource) {
            this.brandingService.uploadFile({ file: fileToUpload, file_name: this.selected_resource.name }, this.branding).subscribe(() => {
                this.uploadFile(fileToUpload, this.selected_resource?.name ?? '');
            });
        } else {
            if (this.qpFile.isZip(fileToUpload)) {
                this.uploadZip(fileToUpload, this.branding);
            } else {
                const fields: PromptField[] = [
                    {
                        value: fileToUpload.name,
                        placeholder: $localize`Filename`,
                        label: $localize`Filename`,
                        required: true,
                        type: 'text',
                        name: 'filename'
                    }
                ];
                this.dialogService.prompt(
                    $localize`Upload file`,
                    '',
                    $localize`Save`,
                    fields
                ).afterClosed().subscribe((returnfields: PromptField[]) => {
                    if (returnfields !== undefined) {
                        const filename = returnfields[0].value;
                        if (this.qpFile.isExtensionAllowed(filename.split('.').pop().toLowerCase())) {
                            this.uploadFile(fileToUpload, filename);
                        }
                    }
                });
            }
        }
    }

    public uploadZip(file: File, branding: Branding, standard_template = false): void {
        let files_with_error: string[] = [];
        const loading = this.dialogService.loading($localize`Creating the branding, this might take a while.`);
        this.brandingService.extractZip(file, branding).pipe(
            finalize(() => loading.close())
        ).subscribe({
            next: (saved_file => {
                files_with_error = saved_file.errors;
                this.branding_resource = [
                    ...this.branding_resource.filter(d => d.name !== saved_file.name),
                    {
                        mime: saved_file.mime,
                        name: saved_file.name,
                        size: saved_file.size
                    }
                ];
            }),
            complete: () => {
                let msg = $localize`${file.name} is created`;

                if (files_with_error.length > 0 && !standard_template) {
                    msg +=
                        '\n' +
                        $localize`The following files were not uploaded as their extensions are not supported:` +
                        '\n';

                    msg += files_with_error.join('\n');
                }

                this.dialogService.alert($localize`Branding created`, msg);
            }
        });
    }

    public uploadFile(file: File, name: string): void {
        this.brandingService.uploadFile({ file, file_name: name }, this.branding).subscribe(() => {
            this.snackBar.open(name + ' ' + $localize`is uploaded`);
            this.selected_resource = undefined;
            this.resetFileUpload();
        });
    }

    public resetFileUpload(): void {
        const el: any = document.getElementById('brandingFile');
        el.value = '';
    }
}
