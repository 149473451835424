import { Component, OnInit, ViewChild, signal } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Agreement } from '@interfaces/agreement';
import { AgreementService } from '@services/agreement/agreement.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { Mixin } from 'app/helpers/decorators/mixins.decorator';
import { PaginatorMixin } from 'app/helpers/paginator/paginator.mixin';
import { finalize } from 'rxjs';
import { ShopifyService } from './shopify.service';

@Component({
    selector: 'qp-activate-shopify',
    templateUrl: './activate-shopify.html'
})
@Mixin([PaginatorMixin])
export class ActivateShopifyComponent implements OnInit, PaginatorMixin {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    public agreement: Agreement;
    public is_loading = signal<boolean>(false);
    public state: string;
    public account_id: number;
    public agreement_id: number;
    public timestamp: string;
    public valid_state: boolean;
    public valid_hmac: boolean;
    public agreements: Agreement[] = [];
    public page = 1;
    public page_size: number;
    public resultsLength: number;
    public displayPaginator: boolean;
    public verificationString: string;
    public getTotalItems: (items: number) => number;

    constructor(
        private agreementRepo: AgreementRepo,
        private shopifyService: ShopifyService,
        private activatedRoute: ActivatedRoute,
        private agreementService: AgreementService,
        private router: Router,
        private snackBar: QpSnackBar
    ) {}

    public ngOnInit(): void {
        this.state = this.activatedRoute.snapshot.queryParams.state;
        this.shopifyService.handleSecrets(this.activatedRoute.snapshot.queryParams);
        this.timestamp = this.activatedRoute.snapshot.queryParams.timestamp;
        this.page_size = 10;
        this.displayPaginator = false;
        this.verificationString = this.getVerificationString(this.activatedRoute.snapshot.queryParams);
        this.getAgreements();
    }

    public getAgreements(): void {
        const params: any = {
            page: this.page,
            page_size: this.page_size,
            support: false,
            sort_by: 'account_type'
        };

        this.agreementRepo.getAll(params, undefined).subscribe((agreements: Agreement[]) => {
            if (agreements.length > 0) {
                this.agreements = agreements;
                this.resultsLength = (this.page - 1) * this.page_size + this.agreements.length + 1;

                if (this.resultsLength > this.page_size && !this.displayPaginator) {
                    this.displayPaginator = true;
                }
            }
        });
    }

    public validateState(): void {
        const storedState = localStorage.getItem('nonce');
        if (this.state === storedState) {
            this.valid_state = true;
            localStorage.removeItem('nonce');
        }
    }

    public selectAgreement(agreement: Agreement): void {
        this.agreementService.setAgreement(agreement);
        this.agreement = agreement;
        this.is_loading.set(true);

        this.shopifyService.setup(this.verificationString, this.agreement?.account?.id).pipe(
            finalize(() => this.is_loading.set(false))
        ).subscribe((shopifyData: any) => {
            const shopifyAppKey = this.shopifyService.getAppKeyFromAppName(
                this.shopifyService.translateAppNameFromServer(shopifyData.app_name)
            );
            if (shopifyData.app_name === 'quickpay-tagorders') {
                this.router.navigate(['/shopify_success']);
                this.snackBar.open($localize`Integration succesful`);
            } else if (shopifyAppKey) {
                document.location.href = `https://${this.shopifyService.shop}/services/payments_partners/gateways/${shopifyAppKey}/settings`;
                this.snackBar.open($localize`Integration succesful`);
            }
        });;
    }

    public changePage(): void {
        this.page_size = this.paginator.pageSize;
        this.page = this.paginator.pageIndex + 1;
        this.getAgreements();
    }

    private getVerificationString(query_params: Params): string {
        let params = structuredClone({ ...query_params });
        delete params['hmac'];
        return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    }
}
