import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '@environments/environment';
import { Acquirers } from '@models/acquirers/acquirers';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';

@Component({
    selector: 'qp-filters-acquirer',
    templateUrl: './filters-acquirer.html'
})
export class FiltersAcquirerComponent implements OnInit {
    @Output() public acquirerChange = new EventEmitter();
    public acquirers: string[];

    public _data: string;
    @Input()
    public get acquirer(): string {
        return this._data;
    }

    public set acquirer(val: string) {
        this._data = val;
        this.acquirerChange.emit(this._data);
    }
    constructor(
        private acquirerService: AcquirerService,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        if (this.agreementService.isMerchant()) {
            const acquirers = Object.assign(new Acquirers(), this.acquirerService.acquirers);
            const active: any[] = [];
            Object.keys(acquirers).sort().forEach(key => {
                const acquirer = (acquirers as any)[key];
                if (acquirer !== null && environment.acquirersList.indexOf(key) !== -1 && key !== 'mobilepayonline') {
                    let title = key.charAt(0).toUpperCase() + key.substring(1);
                    switch (title) {
                        case 'Paypal':
                            title = 'PayPal';
                            break;
                        case 'Viabill':
                            title = 'ViaBill';
                            break;
                        default:
                            title = title;
                    }
                    if (acquirer.active && title !== 'Vippspsp') {
                        active.push(title);
                    }
                }
            });
            this.acquirers = active.sort();
        } else {
            this.acquirers = environment.acquirersList;
            this.acquirers = this.acquirers.filter(item => item !== 'applepay');
        }
    }
}
