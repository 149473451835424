<h5 i18n mat-dialog-title>Filter by</h5>

<mat-dialog-content class="flex-col rg8 grey-border-bottom">
    <div *ngIf="filters.expired.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.expired.selected" i18n-aria-label aria-label="Expired" i18n>
            Expired
        </mat-checkbox>

        <div class="flex-row">
            <mat-radio-group
                color="accent"
                required
                *ngIf="filters.expired.selected"
                [(ngModel)]="filters.expired.data.expired"
                class="flex-col rg8 margin l16"
            >
                <mat-radio-button value="true" i18n-aria-label aria-label="expired" i18n> Expired </mat-radio-button>
                <mat-radio-button value="false" i18n-aria-label aria-label="unexpired" i18n>
                    Unexpired
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <mat-checkbox
        *ngIf="filters.suspended.active"
        [(ngModel)]="filters.suspended.selected"
        i18n-aria-label
        aria-label="Suspended merchants"
        i18n
    >
        Suspended merchants
    </mat-checkbox>

    <div *ngIf="filters.live_transactions.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.live_transactions.selected" i18n-aria-label aria-label="Live merchants" i18n>
            Live merchants
        </mat-checkbox>

        <div class="flex-row">
            <div [class.hide]="!filters.live_transactions.selected" class="text-grey margin l16">
                <div i18n>Show merchants with at least</div>
                <div i18n>1 non-test transaction</div>
            </div>
        </div>
    </div>

    <div *ngIf="filters.suspected_fraud.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.suspected_fraud.selected" i18n-aria-label aria-label="Suspected fraud" i18n>
            Suspected fraud
        </mat-checkbox>

        <div class="flex-row">
            <span [class.hide]="!filters.suspected_fraud.selected" class="text-grey margin l16" i18n>
                Only show {{ this.data.transaction_type }} with suspected fraud
            </span>
        </div>
    </div>

    <div *ngIf="filters.date.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.date.selected" i18n-aria-label aria-label="Date" i18n> Date </mat-checkbox>

        <div class="flex-row">
            <qp-filters-date [class.hide]="!filters.date.selected" [(data)]="filters.date.data" class="margin l16"> </qp-filters-date>
        </div>
    </div>

    <div *ngIf="filters.acquirer.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.acquirer.selected" i18n-aria-label aria-label="Acquirer" i18n>
            Acquirer
        </mat-checkbox>

        <div class="flex-row">
            <qp-filters-acquirer [class.hide]="!filters.acquirer.selected" [(acquirer)]="filters.acquirer.data.acquirer" class="margin l16">
            </qp-filters-acquirer>
        </div>
    </div>

    <div *ngIf="filters.active_acquirer.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.active_acquirer.selected" i18n-aria-label aria-label="Active acquirer" i18n>
            Active acquirer
        </mat-checkbox>

        <div class="flex-row">
            <qp-filters-acquirer
                [class.hide]="!filters.active_acquirer.selected"
                [(acquirer)]="filters.active_acquirer.data.active_acquirer"
                class="margin l16"
            >
            </qp-filters-acquirer>
        </div>
    </div>

    <div *ngIf="filters.subscription_group.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.subscription_group.selected" i18n-aria-label aria-label="Subscription group" i18n>
            Subscription group
        </mat-checkbox>

        <div class="flex-row">
            <qp-filters-subscription-group
                [class.hide]="!filters.subscription_group.selected"
                [(selected_group)]="filters.subscription_group.data.subscription_group"
                class="margin l16">
            </qp-filters-subscription-group>
        </div>
    </div>

    <div *ngIf="filters.country_code.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.country_code.selected" i18n-aria-label aria-label="Country" i18n>
            Country
        </mat-checkbox>

        <mat-form-field [class.hide]="!filters.country_code.selected" color="accent">
            <mat-select i18n-placeholder placeholder="Country" [(ngModel)]="filters.country_code.data.country_code" name="country_code">
                <mat-option i18n>Select</mat-option>
                <mat-option *ngFor="let country of countries" value="{{ country.code }}"> {{ country.name }} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="filters.locale.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.locale.selected" i18n-aria-label aria-label="Locale (Language)" i18n>
            Locale (Language)
        </mat-checkbox>

        <mat-form-field [class.hide]="!filters.locale.selected" color="accent">
            <mat-select i18n-placeholder placeholder="Locale (Language)" [(ngModel)]="filters.locale.data.locale" name="locale">
                <mat-option i18n>Select</mat-option>
                <mat-option *ngFor="let locale of locales" value="{{ locale.code }}"> {{ locale.name }} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="filters.shopsystem.active" class="flex-col">
        <mat-checkbox [(ngModel)]="filters.shopsystem.selected" i18n-aria-label aria-label="Shop system" i18n>
            Shop system
        </mat-checkbox>

        <mat-form-field [class.hide]="!filters.shopsystem.selected" color="accent">
            <mat-select i18n-placeholder placeholder="Shop system" [(ngModel)]="filters.shopsystem.data.shopsystem" name="locale">
                <mat-option i18n>Select</mat-option>
                <mat-option *ngFor="let shop of shopsystems" value="{{ shop.name }}"> {{ shop.name }} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <span></span>
</mat-dialog-content>

<mat-dialog-actions class="flex-row justify-end">
    <button mat-button color="accent" mat-dialog-close i18n>Close</button>
    <button mat-button color="accent" (click)="addFilter()" i18n>Filter</button>
</mat-dialog-actions>
