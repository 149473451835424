import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AgreementRepo } from '@endpoints/api/agreement-repo';
import { AgreementService } from '@services/agreement/agreement.service';
import { SessionService } from '@services/session/session.service';
import { map, switchMap } from 'rxjs/operators';

export const adminGuard: CanActivateFn = (route, _state) => {
    const agreementService = inject(AgreementService);
    const sessionService = inject(SessionService);
    const agreementRepo = inject(AgreementRepo);
    const router = inject(Router);
    const account_id = route.parent?.paramMap.get('account_id');

    if (agreementService.agreement) {
        return agreementService.isAdmin();
    } else if (!account_id) {
        router.navigate(['/my-user']);
        return false;
    } else {
        return agreementRepo.get(Number(account_id)).pipe(
            switchMap(agreement => {
                return sessionService.selectAgreement(agreement);
            }),
            map(() => {
                if (agreementService.isAdmin()) {
                    return true;
                } else {
                    router.navigate(['/my-user']);
                    return false;
                }
            })
        );
    }


    // console.log(agreementService.agreement, route.parent?.paramMap);

    // if (agreementService.isAdmin()) {
    //     return true;
    // } else {
    //     router.navigate(['/my-user']);
    //     return false;
    // }
};
