import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentRepo } from '@endpoints/api/payment-repo';
import { SearchParams } from '@interfaces/params';
import { Card } from '@models/card';
import { Payment } from '@models/payment';
import { AgreementService } from '@services/agreement/agreement.service';
import { TransactionService } from '@services/transaction/transaction.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { CapturePaymentDialogComponent } from '../../transaction-dialogs/capture-payment-dialog/capture-payment-dialog.component';
import { RefundPaymentDialogComponent } from '../../transaction-dialogs/refund-payment-dialog/refund-payment-dialog.component';

@Component({
    selector: 'qp-transaction-table-action',
    templateUrl: './action-handler.html'
})
export class TransactionTableActionComponent implements OnInit {
    @Output() public transactionChange = new EventEmitter();
    @Input() public transaction: Payment | Card;
    @Input() public transaction_type: string;
    @Input() public search_params: SearchParams;

    public is_capturable = false;
    public is_refundable = false;
    public is_cancelable = false;
    public is_link = false;

    constructor(
        private agreementService: AgreementService,
        private dialogService: DialogService,
        private paymentRepo: PaymentRepo,
        private transactionService: TransactionService,
        private activatedRoute: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        if (this.transaction_type === 'payments') {
            if (this.agreementService.hasPermission('/payments/:id/capture', 'post')) {
                this.is_capturable = this.transactionService.isCapturable(this.transaction as Payment);
            }

            if (this.agreementService.hasPermission('/payments/:id/refund', 'post')) {
                this.is_refundable = this.transactionService.isRefundable(this.transaction as Payment);
            }

            if (this.agreementService.hasPermission('/payments/:id/cancel', 'post')) {
                this.is_cancelable = this.transactionService.isCancelable(this.transaction as Payment);
            }

            if (this.agreementService.hasPermission('/payment-links', 'post') && this.transaction.link) {
                this.is_link = !!this.search_params.states && (this.search_params.states[this.activatedRoute.snapshot.queryParams.state] === 'initial' ||
                    this.search_params.states[this.activatedRoute.snapshot.queryParams.state] === 'rejected');
            }
        }

        if (this.transaction_type === 'cards') {
            this.is_link = this.transaction.link && this.transactionService.isAuth(this.transaction);
        }
    }


    public onClickCapture(): void {
        this.dialogService.component(
            CapturePaymentDialogComponent,
            this.transaction as Payment
        ).afterClosed().pipe(
            filter(amount => !!amount),
            switchMap((amount: number) =>
                this.paymentRepo.capture(this.transaction.id ?? NaN, { amount }).pipe(
                    map((transaction: Payment) => {
                        this.transactionChange.emit({ transaction, operation_type: 'capture' });
                    })
                )
            )
        ).subscribe();
    }

    public onClickRefund(): void {
        this.dialogService.component(
            RefundPaymentDialogComponent,
            this.transaction as Payment
        ).afterClosed().pipe(
            filter(data => !!data),
            switchMap((data: any) => {
                if (this.transaction.acquirer !== 'klarnapayments') {
                    delete data.vat_rate;
                }

                return this.paymentRepo.refund(this.transaction.id ?? NaN, data).pipe(
                    map((transaction: Payment) => {
                        this.transactionChange.emit({ transaction, operation_type: 'refund' });
                    })
                );
            })
        ).subscribe();
    }

    public onClickRetry(): void {
        this.dialogService.copy(
            $localize`Payment link`,
            this.transaction.link.url,
            $localize`Link copied to clipboard`
        );
    }

    public onClickCancel(): void {
        const payment = this.transaction as Payment;
        const dialog_text =
            $localize`Payment ID: ${payment.id}` +
            '\n' +
            $localize`Order ID: ${payment.order_id}` +
            '\n' +
            $localize`Are you sure you want to cancel the payment?`;

        this.dialogService.confirm(
            $localize`Cancel payment?`,
            dialog_text,
            $localize`Cancel payment`,
            $localize`Close`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() =>
                this.paymentRepo.cancel(payment.id ?? NaN).pipe(
                    map((transaction: Payment) => {
                        this.transactionChange.emit({ transaction, operation_type: 'cancel' });
                    })
                )
            )
        ).subscribe();
    }
}
