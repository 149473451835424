<div class="flex-row bg-white container">
    <div class="flex-row fill flex-100">
        <div class="flex-col qp rcorners">
            <div class="flex-col justify-start fill-container">
                <div class="flex-col justify-start items-center">
                    <img [src]="theme.logo.dark" class="hide-lt-sm login-layout-logo" alt="logo" />
                    <img [src]="theme.logo.dark" class="hide-gt-xs login-layout-xs-logo" alt="logo" />
                </div>

                <div class="flex-row width-100p height-fix items-center justify-center">
                    <div class="flex-col flex-70-60-60-50 login-container">
                        <div *ngIf="!passwordSet">

                            <form class="flex-col rg24 justify-start" name="activate_user_form" [formGroup]="activate_user_form" [ngClass]="{'login-form' : activateUser === false && hostname !== 'Unzer'}">
                                <div class="header-login-layout" *ngIf="activateUser">
                                    <h4 *ngIf="hostname === 'Unzer'" i18n>Your email has been verified!</h4>
                                    <h3 *ngIf="hostname !== 'Unzer'" i18n>Your email was verified!</h3>
                                </div>

                                <div *ngIf="!activateUser">
                                    <h4 i18n>Choose a new password</h4>
                                </div>
                                <span i18n>Please set your account password.</span>

                                <div class="flex-row flex-100 justify-center form-container password">
                                    <mat-form-field class="flex-65 white-space-pre" color="accent" hideRequiredMarker>
                                        <mat-label i18n>Password</mat-label>
                                        <input matInput name="password" [(ngModel)]="password" formControlName="password"
                                            [type]="hide ? 'password' : 'text'">
                                        <button mat-icon-button matSuffix class="pw-eye" (click)="hide = !hide" type="button"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="!step_valid && !activate_user_form.controls.password.value" i18n>This field is required.</mat-error>
                                    </mat-form-field>

                                    <div class="flex-col justify-center items-end flex-35">
                                        <qp-password-strength-bar class="flex-row" *ngIf="activate_user_form.dirty" [password]="password"></qp-password-strength-bar>
                                    </div>
                                </div>

                                <div class="error-container" *ngIf="activate_user_form.controls.password">
                                    <div class="justify-start items-center">
                                        <mat-icon class="message" *ngIf="
                                        activate_user_form.controls.password.pristine ||
                                        activate_user_form.controls.password.errors?.required">
                                            lens
                                        </mat-icon>

                                        <mat-icon class="error" *ngIf="
                                            !activate_user_form.controls.password.pristine &&
                                            activate_user_form.controls.password.errors?.minlength">
                                            clear
                                        </mat-icon>

                                        <mat-icon class="check" *ngIf="
                                            !activate_user_form.controls.password.errors?.minlength &&
                                            !activate_user_form.controls.password.errors?.required">
                                            check
                                        </mat-icon>
                                        <span i18n>Must be minimum 10 characters long.</span>
                                    </div>

                                    <div class="justify-start items-center">
                                        <mat-icon class="message" *ngIf="
                                            activate_user_form.controls.password.pristine ||
                                            activate_user_form.controls.password.errors?.required">
                                            lens
                                        </mat-icon>

                                        <mat-icon class="error" *ngIf="
                                            !activate_user_form.controls.password.pristine &&
                                            activate_user_form.controls.password.errors?.pattern">
                                            clear
                                        </mat-icon>

                                        <mat-icon class="check" *ngIf="
                                            !activate_user_form.controls.password.errors?.pattern &&
                                            !activate_user_form.controls.password.errors?.required">
                                            check
                                        </mat-icon>
                                        <span i18n>Must contain at least one letter and one number.</span>
                                    </div>
                                </div>

                                <div *ngIf="activateUser">
                                    <button
                                        mat-raised-button
                                        class="margintop password"
                                        color="accent"
                                        id="direct_complete_registration"
                                        (click)="save()" i18n>
                                        Complete registration
                                    </button>
                                </div>

                                <div *ngIf="!activateUser">
                                    <button mat-raised-button class="margintop password" color="accent" (click)="save()" i18n>Set new password</button>
                                </div>
                            </form>
                        </div>

                        <div *ngIf="passwordSet">

                            <h3 class="header-login-layout" i18n>Congratulations!</h3>

                            <form class="flex-col rg24 justify-start" [formGroup]="loginForm" #f="ngForm">

                                <span i18n>You have been successfully registrered. Please sign in to continue.</span>

                                <div class="flex-col flex-80 justify-center form-container">
                                    <mat-form-field class="flex-100" color="accent" hideRequiredMarker>
                                        <mat-label i18n>Email</mat-label>
                                        <input matInput type="email" name="email" autocomplete formControlName="email" />
                                    </mat-form-field>

                                    <mat-form-field class="flex-100-mobile-70" color="accent" hideRequiredMarker>
                                        <mat-label i18n>Password</mat-label>
                                        <input matInput type="password" name="password" autocomplete formControlName="password" />
                                    </mat-form-field>

                                    <mat-error *ngIf="showErrorIncorrect" class="flex-row cg8">
                                        <mat-icon color="warn">info</mat-icon>
                                        <span i18n>Incorrect email or password</span>
                                    </mat-error>

                                    <div *ngIf="showErrorLocked" class="flex-row cg8">
                                        <mat-icon color="warn">info</mat-icon>
                                        <mat-error class="flex-col rg8 flex-60 text-left">
                                            <span i18n>Your account has been locked because of too many login failures.</span>
                                            <span i18n>Reset your password to unlock your account.</span>
                                        </mat-error>
                                    </div>
                                </div>

                                <div>
                                    <button mat-raised-button color="accent" [disabled]="f.invalid" (click)="login()" i18n>Log in</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

