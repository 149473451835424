<div class="flex-row justify-center">
    <mat-card class="flex-col rg32 justify-start items-center flex-632px-mobile-100 card02">
        <mat-card-title i18n> Activate Shopify </mat-card-title>

        <div class="flex-col rg16 justify-center items-start">
            <h2 i18n>Starting Shopify Activation</h2>
            <div class="flex-row">
                <mat-icon class="text-yellow">warning</mat-icon>
                <span i18n>Starting Quickpay app activation page will redirect automaticaly when the process is done</span>
            </div>
        </div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
</div>
