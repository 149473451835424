import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { filter, map } from 'rxjs';

@Component({
    selector: 'qp-shopurls',
    templateUrl: 'shopurls.html'
})
export class ShopurlsComponent {
    @Output() public add = new EventEmitter<undefined>();
    @Output() public remove = new EventEmitter<undefined>();
    @Output() public shopUrlsChange = new EventEmitter<string[]>();
    @Input() public readonly: boolean;

    public _shopUrls: string[] = [];
    public disable_unzer = false;

    constructor(
        private acquirerService: AcquirerService,
        private agreementService: AgreementService,
        private dialogService: DialogService,
        private snackBar: QpSnackBar
    ) {
        if (this.acquirerService.acquirers?.clearhaus?.api_key) {
            this.disable_unzer = true;
        }
    }

    @Input()
    public get shopUrls(): Array<string> {
        return this._shopUrls;
    }
    public set shopUrls(shopUrls: string[]) {
        this._shopUrls = shopUrls;
        this.shopUrlsChange.emit(shopUrls);
    }

    public addUrl(): void {
        let hint = '';
        let registered = false;
        const fields: PromptField[] = [
            {
                label: 'Shop url',
                required: true,
                type: 'url',
                name: 'url',
                value: 'https://'
            }
        ];

        if (this.shopUrls && this.shopUrls.length > 0) {
            hint = $localize`Please note that there is a monthly fee for each additional domain.`;

            if (new QpMerchantTypePipe(this.agreementService).transform(['Quickpay'])) {
                hint += $localize`\nSee <a href="https://quickpay.net/prices-eu/" target="_blank">https://quickpay.net/prices-eu/</a>`;
            }
        }

        this.dialogService.prompt(
            $localize`Add shop URL`,
            hint,
            $localize`Add URL`,
            fields
        ).afterClosed().subscribe((returnFields: PromptField[]) => {
            if (returnFields) {
                const merchant_urls: string[] = this.agreementService.agreement?.account?.shop_urls ?? [];
                const url_suffix: string = returnFields[0].value.split('.').pop();
                const url_domains: string[] = returnFields[0].value.split('//').pop().split('.').slice(0, -1);

                if (merchant_urls.length > 0) {
                    merchant_urls.forEach(url => {
                        const domain = url.split('//').pop()?.split('.').slice(0, -1).pop();

                        if (domain === url_domains.pop()) {
                            this.dialogService.confirm(
                                $localize`Domain already exists`,
                                $localize`The domain ${domain + '.' + url_suffix.split('/')[0]} seems to be a duplicate. You do not need to include additional subdomains or paths.`,
                                $localize`Add url`,
                                $localize`close`
                            ).afterClosed().pipe(
                                filter(confirmed => !!confirmed),
                                map(() => {
                                    this.shopUrls.push(returnFields[0].value.replace(/\s/g, ''));
                                    this.snackBar.open($localize`${url} successfully added`);
                                    this.add.emit();
                                })
                            ).subscribe();
                            registered = true;
                        }
                    });
                }

                if (!registered) {
                    this.shopUrls.push(returnFields[0].value.replace(/\s/g, ''));
                    this.snackBar.open($localize`${returnFields[0].value} successfully added`);
                    this.add.emit();
                }
            }
        });
    }

    public removeUrl(index: number, url: string): void {
        this.dialogService.confirm(
            $localize`Remove url`,
            $localize`Are you sure you want to remove ${url} from the list?`,
            $localize`Remove`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            map(() => {
                this.shopUrls.splice(index, 1);
                this.snackBar.open($localize`${url} successfully removed`);
                this.remove.emit();
            })
        ).subscribe();
    }
}
