import { Injectable } from '@angular/core';
import { InvoiceClient } from './invoice-client';
import { MerchantSpecification } from '@models/merchant-specification';
import { Observable } from 'rxjs';
import { Params } from '@interfaces/params';

@Injectable({
    providedIn: 'root'
})
export class MerchantSpecificationRepo {
    constructor(private client: InvoiceClient) {}

    public getAll(invoice_id: string, sort_params: Params, merchant_id: string): Observable<MerchantSpecification[]> {
        return this.client.get<MerchantSpecification[]>('/merchant-specifications', {
            params: {
                'filter[invoice_id]': invoice_id || '',
                'page[number]': sort_params.page?.toString() || '',
                'page[size]': sort_params.page_size?.toString() || '',
                sort: sort_params.sort_by ?? '',
                'filter[merchant_id]': merchant_id
            }
        });
    }

    public getAllAsCSV(invoice_id: string): Observable<any> {
        return this.client.get('/merchant-specifications.csv', {
            params: {
                'filter[invoice_id]': invoice_id,
                'page[size]': '1000000'
            },
            responseType: 'blob'
        });
    }
}
