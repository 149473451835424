import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ShopifyService } from './shopify.service';

@Component({
    selector: 'qp-shopify-activation',
    templateUrl: './shopify-activation.html'
})
export class ShopifyActivationComponent implements OnInit, AfterViewInit {
    constructor(private shopifyService: ShopifyService, private activatedRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this.shopifyService.handleSecrets(this.activatedRoute.snapshot.queryParams);
    }

    public ngAfterViewInit(): void {
        let params = structuredClone(this.activatedRoute.snapshot.queryParams);
        delete params.hmac;
        const paramsStringified = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');

        this.shopifyService.installOrManage(paramsStringified);
    }

    public redirectToShop(): void {
        const redirect_uri = this.shopifyService.secrets.redirect_url;
        const nonce = this.generateRandomNumber();
        const api_key = this.shopifyService.secrets.app_key;
        const scope = 'write_payment_gateways,write_payment_sessions';
        // eslint-disable-next-line
        const url = `https://${this.shopifyService.shop}/admin/oauth/authorize?client_id=${api_key}&scope=${scope}&redirect_uri=${redirect_uri}&state=${nonce}`;
        document.location.href = url;
    }

    public generateRandomNumber(): string {
        const randomNumber = Math.floor(Math.random() * 100000) + 1;
        localStorage.setItem('nonce', `QP${randomNumber}`);
        return `QP${randomNumber}`;
    }
}
