import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Params } from '@interfaces/params';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'qp-dialog-loading',
    templateUrl: 'loading.html'
})
export class LoadingDialogComponent {
    public params: Params;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            headline: string;
            params: BehaviorSubject<Params>;
        },
        public dialog_ref: MatDialogRef<LoadingDialogComponent>
    ) {
        if (this.data.params) {
            this.data.params.subscribe((params: Params) => {
                this.params = params;
            });
        }
    }
}
