export class Branding {
    public id?: number;
    public name?: string;
    public account_id?: number;
    public resources: BrandingResource[] = [];
    public created_at?: any;

    public addResource(name: string, mime: string, size: string): void {
        let update = true;
        const resource: BrandingResource = {
            name,
            mime,
            size
        };

        for (let i = 0, count = this.resources.length; i < count; i++) {
            if (this.resources[i].name === name) {
                this.resources[i].name = name;
                this.resources[i].mime = mime;
                this.resources[i].size = size;
                update = false;
                break;
            }
        }
        if (update) {
            this.resources.push(resource);
        }
    }
}

export type ResourceType = 'code' | 'image' | 'translation';
export interface BrandingResource {
    name: string;
    mime: string;
    size: string;
    type?: ResourceType;
}

export interface BrandingFileUploadOptions {
    file_name: string;
    file: Blob;
    size?: string;
    type?: string;
};
