import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FilterDialogComponent } from './filters.dialog.component';
import { FiltersAcquirerComponent } from '@widgets/filters/acquirer/filters-acquirer.component';
import { FiltersDateComponent } from './date/filters-date.component';
import { FilterService } from './filters.service';
import { FiltersSubscriptionGroupComponent } from './subscription-group/filters-subscription-group.component';
import { LayoutModule } from '@widgets/layout/layout.module';

@NgModule({
    providers: [FilterService],
    imports: [
        FormsModule,
        CommonModule,
        LayoutModule
    ],
    declarations: [
        FiltersAcquirerComponent,
        FiltersSubscriptionGroupComponent,
        FiltersDateComponent,
        FilterDialogComponent
    ]
})
export class FiltersModule {}
