<mat-card *ngIf="!loading" class="card02" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card-content class="flex-col rg8">
        <mat-toolbar *ngIf="!selected_transactions.selected.length" class="top-toolbar">
            <span class="flex-1" *ngIf="search_params.states && search_params.states.length <= 1"></span>
            <qp-transaction-states
                class="flex-1"
                *ngIf="search_params.states && search_params.states.length > 1"
                [states]="search_params.states"
                [state]="search_params.state"
                (tabChange)="onTabChange($event)">
            </qp-transaction-states>

            <qp-search-field-small
                *ngIf="transaction_type === 'cards'"
                [class.show-search]="show_search"
                [autoFocus]="search_params.auto_focus ?? false"
                (searchInvoked)="onSearch($event)"
                [(showSearch)]="show_search"
                i18n-placeholder
                placeholder="Search..."
                [(search)]="search_params.search">
            </qp-search-field-small>

            <qp-search-field-small
                *ngIf="transaction_type !== 'cards'"
                [class.show-search]="search_params.show_search"
                [autoFocus]="search_params.auto_focus ?? false"
                (searchInvoked)="onSearch($event)"
                [(showSearch)]="search_params.show_search"
                i18n-placeholder
                placeholder="Search..."
                [searchByOptions]="search_params.search_by_options"
                [searchBy]="search_params.search_by ?? ''"
                [(search)]="search_params.search">
            </qp-search-field-small>
            <div class="alignment-fix flex-row">
                <button mat-icon-button *ngIf="transaction_type === 'subscriptions'" aria-label="Subscription groups" [routerLink]="['../subscription-groups']" i18n-matTooltip matTooltip="Subscription groups">
                    <mat-icon color="primary">group</mat-icon>
                </button>

                <qp-subscriptions-group-tool *ngIf="transaction_type === 'subscriptions'" [filters]="filters" (actionComplete)="getPage()"></qp-subscriptions-group-tool>

                <qp-shopify-order-filter-tool
                    *ngIf="transaction_type === 'payments'"
                    [search_params]="search_params"
                    (actionComplete)="setPaginatorPageSize(search_params.page_size)">
                </qp-shopify-order-filter-tool>

                <button mat-icon-button (click)="showFilterDialog(transaction_type)">
                    <mat-icon
                        aria-label="Filter options"
                        i18n-placeholder
                        matTooltip="Filter options">
                        filter_list
                    </mat-icon>
                </button>

                <qp-export-transactions-tool [transaction_type]="transaction_type"></qp-export-transactions-tool>
                <qp-columns-settings [table]="transaction_type"></qp-columns-settings>
            </div>
        </mat-toolbar>

        <mat-toolbar mat-card-image *ngIf="selected_transactions.selected.length">
            <mat-toolbar-row>
                <h5 i18n>{{ selected_transactions.selected.length }} items selected</h5>
                <div class="flex-1"></div>

                <qp-subscriptions-group-tool
                    *ngIf="transaction_type === 'subscriptions'"
                    [filters]="filters"
                    [selected_transactions]="selected_transactions"
                    (actionComplete)="getPage()">
                </qp-subscriptions-group-tool>

                <qp-payment-action-tool
                    *ngIf="transaction_type === 'payments'"
                    (actionComplete)="getPage()"
                    [(selection)]="selected_transactions">
                </qp-payment-action-tool>

                <qp-export-transactions-tool [transaction_type]="transaction_type" [selected_transactions]="selected_transactions"></qp-export-transactions-tool>
            </mat-toolbar-row>
        </mat-toolbar>

        <mat-chip-listbox class="flex-row justify-start items-center margin l8 tm8">
            <mat-chip *ngIf="filters.subscription_group" removable (removed)="resetFilter('subscription_group')">
                <span i18n>Group:</span> &nbsp; {{ filters.subscription_group.name }} -
                <span class="amount">{{ filters.subscription_group.amount | qpCurrency: filters.subscription_group.currency }}</span>
                {{ filters.subscription_group.currency }}
                <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="filters.min_time" removable (removed)="resetFilter('min_time')">
                <span *ngIf="filters.timestamp_type === 'created_at'" i18n>Created after</span>
                <span *ngIf="filters.timestamp_type === 'updated_at'" i18n>Updated after</span>
                <span *ngIf="!filters.timestamp_type" i18n>After:</span>
                : &nbsp; {{ filters.min_time | date }}
                <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="filters.max_time" removable (removed)="resetFilter('max_time')">
                <span *ngIf="filters.timestamp_type === 'created_at'" i18n>Created before</span>
                <span *ngIf="filters.timestamp_type === 'updated_at'" i18n>Updated before</span>
                <span *ngIf="!filters.timestamp_type" i18n>Before:</span>
                : &nbsp; {{ filters.max_time | date }}
                <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="filters?.acquirer" removable (removed)="resetFilter('acquirer')">
                <span i18n>Acquirer:</span> {{ filters.acquirer }}
                <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="filters.suspected_fraud" removable (removed)="resetFilter('suspected_fraud')">
                <span i18n>Suspected fraud</span>
                <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="filters.expired" removable (removed)="resetFilter('expired')">
                <span *ngIf="filters.expired" i18n>Expired</span>
                <span *ngIf="!filters.expired" i18n>Not expired</span>
                <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>
        </mat-chip-listbox>

        <mat-progress-bar *ngIf="loading_results()" mode="indeterminate"></mat-progress-bar>

        <div [hidden]="loading_results()" class="margin-negative">
            <qp-transaction-table
                [data_source]="data_source"
                [(selection)]="selected_transactions"
                [search_params]="search_params"
                [table_type]="transaction_type"
                [columns]="transaction_columns"
                (getPage)="getPageFromTable($event)">
            </qp-transaction-table>

            <qp-no-results
                *ngIf="!data_source.data.length"
                i18n-type
                type="payments"
                [searchByOptions]="search_params.search_by_options ?? []"
                [states]="search_params.states ?? []"
                [(filters)]="filters"
                [(search)]="search_params.search"
                [(searchBy)]="search_params.search_by"
                [(state)]="search_params.state"
                (getPage)="getPage()"
            >
            </qp-no-results>

            <qp-keyset-paginator [data]="data_source.data" [config]="paginator_options" (pageChange)="onKeySetPageChange($event)"></qp-keyset-paginator>
        </div>
    </mat-card-content>
</mat-card>
