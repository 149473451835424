import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Directive, Input, OnInit, HostListener, ElementRef, Renderer2, forwardRef } from '@angular/core';

import { CurrencyService } from '@services/currency/currency.service';

@Directive({
    selector: '[inputDecimal]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputDecimalDirective),
            multi: true
        }
    ]
})
export class InputDecimalDirective implements OnInit, ControlValueAccessor {
    @Input() public currency: string;
    public regex = new RegExp(/[0-9]/);
    public value = '0';

    constructor(
        private _renderer: Renderer2,
        private el: ElementRef,
        private currencyService: CurrencyService
    ) {}

    @HostListener('keydown', ['$event'])
    @HostListener('input', ['$event'])
    public onInput(e: any): void {
        this.parseKeyDown(e);
    }

    public onChange = (_: any): void => {};
    public onTouched = (_: any): void => {};

    public ngOnInit(): void {
        this.writeValue('0');
    }

    public writeValue(value: string): void {
        this.value = value || '0';
        this._renderer.setProperty(this.el.nativeElement, 'value', this.formatValue(this.value));
        this.onChange(this.value);
    }

    public parseKeyDown(e: any): boolean {
        const key = e.key;
        if (key) {
            // Browsers WITH key events

            // Allow: backspace and delete
            if (['Backspace', 'Delete'].includes(key)) {
                let str = String(this.value).substring(0, this.value.length - 1);
                if (str.length === 0) {
                    str = '0';
                }
                this.writeValue(str);
            }

            // Allow: 0-9
            if (key.match(this.regex)) {
                if (this.value.length >= 1 && this.value[0] === '0') {
                    const str = this.value.substring(0, this.value.length - 1);
                    this.writeValue(str + key);
                } else {
                    this.writeValue(this.value + key);
                }
            }

            // Allow: default action 'tab'
            if (key === 'Tab') {
                return true;
            }
        } else {
            // Browsers WITHOUT key events
            const data = e.data;
            const inputType = e.inputType;

            // Allow: backspace and delete
            if (['deleteContentBackward', 'deleteContentForward'].includes(inputType)) {
                let str = this.value.substring(0, this.value.length - 1);
                if (str.length === 0) {
                    str = '0';
                }
                this.writeValue(str);
            }

            // Disallow: char !0-9
            if (inputType === 'insertText' && !data.match(this.regex)) {
                this.value = this.value.substring(0, this.value.length - 1);
            }

            // Allow: 0-9
            if ('insertText' === inputType && data.match(this.regex)) {
                if (this.value.length >= 1 && this.value[0] === '0') {
                    const str = this.value.substring(0, this.value.length - 1);
                    this.writeValue(str + data);
                } else {
                    const str = this.value + data;
                    this.writeValue(str);
                }
            }

            if (inputType === 'insertCompositionText' && data.match(this.regex)) {
                this.writeValue(data);
            }

            // Allow: default action 'tab'
            if (inputType === 'Tab') {
                return true;
            }
        }

        return e.preventDefault();
    }

    public formatValue(value: any): string {
        if (!this.currency) {
            return value;
        }
        return this.currencyService.format(value, this.currency);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public registerOnTouched(fn: (_: any) => {}): void {
        this.onTouched = fn;
    }
}
