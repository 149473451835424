export class ClearhausApplication {
    public application: Application;
    public bank_account: BankAccount;
    public company: Company;
    public contact: Contact;
    public websites: Website[];
}

export class Application {
    public voucher_code: string;
    public business_model: {
        description: string;
        estimated_monthly_turnover: number;
    };
    public signer: Contact;
    public metadata?: Metadata;
}

export class BankAccount {
    public currency?: string;
    public iban?: string;
}

export class Company {
    public name?: string;
    public registration_number?: string;
    public address_line_1?: string;
    public city?: string;
    public country?: string;
    public phone?: string;
    public zipcode?: string;
}

export class Contact {
    public name?: string;
    public email?: string;
}

export class Website {
    public url?: string;
    public comment?: string;
}

class Metadata {
    public created_at?: string;
    public state?: string;
}
