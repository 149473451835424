<div class="flex-col">
    <mat-form-field class="flex-1">
        <mat-select [disabled]="readonly" [(ngModel)]="selected_shopsystem" (selectionChange)="selectShopsystem()" [placeholder]="placeholder">
            <mat-option *ngFor="let shopsystem of shopsystems" [value]="shopsystem['name']">
                {{ shopsystem['name'] }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-1" *ngIf="selected_shopsystem === 'Other'">
        <input
            matInput
            i18n-placeholder
            placeholder="Please type in your system"
            [(ngModel)]="custom_shopsystem"
            type="text"
            [disabled]="readonly"
            (change)="setCustomShopsystem()"
            [required]="selected_shopsystem === 'Other'"
        />
    </mat-form-field>
</div>
