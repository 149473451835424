export class Plan {
    public id?: string;
    public type: string;
    public attributes: PlanAttributes;
}

export interface PlanAttributes {
    reseller_id?: number;
    merchant_id?: number;
    discount?: number;
    shop_url?: string;
    country_code?: string;
    currency?: string;
    tier?: string;
    price_per_month?: number;
    price_per_transaction?: number;
    included_transactions?: number;
    mobilepay_monthly_price?: number;
    mobilepay_transaction_price?: number;
    vippspsp_transaction_price?: number;
    no_credit_card_fee?: number;
    free_to?: any;
    apple_pay_monthly_price?: number;
    description?: string;
    shop_url_fee?: number;
    branding_fee?: number;
    samsung_pay_monthly_price?: number;
    google_pay_monthly_price?: number;
    price_per_clearhaus_transaction?: number;
    shopify_transaction_price?: number;
    price_per_add_pm_transaction?: number;
    add_pm_monthly_price?: number;
}
