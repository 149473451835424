<mat-vertical-stepper linear #stepper *ngIf="!loading">
    <mat-step label="Contact information" [completed]="validity.contact" i18n-label>
        <qp-clearhaus-contact-form [contact]="clearhausApplication.contact" (contactChange)="clearhausApplication.contact = $event; clearhausApplication.application.signer = $event;" (validityChange)="updateValidity({ contact: $event })"> </qp-clearhaus-contact-form>
    </mat-step>

    <mat-step label="Company information" [completed]="validity.company" i18n-label>
        <qp-clearhaus-company-form [company]="clearhausApplication.company" (companyChange)="clearhausApplication.company = $event" (validityChange)="updateValidity({ company: $event})"> </qp-clearhaus-company-form>
    </mat-step>

    <mat-step label="Websites" [completed]="validity.websites" i18n-label>
        <qp-clearhaus-website-form [websites]="clearhausApplication.websites" (validityChange)="updateValidity({ websites: $event})"> </qp-clearhaus-website-form>
    </mat-step>

    <mat-step label="Business model" [completed]="validity.business" i18n-label>
        <qp-clearhaus-buisness-form [application]="clearhausApplication.application" (applicationChange)="clearhausApplication.application = $event" (validityChange)="updateValidity({ business: $event})"> </qp-clearhaus-buisness-form>
    </mat-step>

    <mat-step label="Bank Account" [completed]="validity.bank" i18n-label>
        <qp-clearhaus-bank-form [bank_account]="clearhausApplication.bank_account" (bankAccountChange)="clearhausApplication.bank_account = $event" (validityChange)="updateValidity({ bank: $event})"> </qp-clearhaus-bank-form>
    </mat-step>

    <mat-step label="Signatory" [completed]="validity.signatory" i18n-label>
        <qp-clearhaus-signatory-form [stepper]="myStepper" [signer]="clearhausApplication.application.signer" [contact]="clearhausApplication.contact" (signerChange)="clearhausApplication.application.signer = $event" (validityChange)="updateValidity({ signatory: $event })">
        </qp-clearhaus-signatory-form>
    </mat-step>

    <mat-step label="Documentation" *ngIf="displayDocumentation" [completed]="validity.documentation" i18n-label>
        <qp-clearhaus-documentation-form [documentationFile]="documentationFile" (fileChange)="documentationFile = $event" (validityChange)="updateValidity({ documentation: $event })"> </qp-clearhaus-documentation-form>
    </mat-step>

    <mat-step label="Submit" i18n-label>
        <qp-clearhaus-instant-signup-submit [application]="clearhausApplication" [documentationFile]="documentationFile" [validity]="validity"> </qp-clearhaus-instant-signup-submit>
    </mat-step>
</mat-vertical-stepper>
