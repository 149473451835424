<div *ngIf="application.metadata?.state === 'submitted' && showForm" class="flex-row cg8 justify-start items-center">
    <mat-icon aria-label="submitted" matTooltip="Submitted" class="text-green" i18n-matTooltip> verified </mat-icon>
    <span class="text-grey" i18n>Your application is being reviewed. At this point changes cannot be made.</span>
</div>

<div *ngIf="application.metadata?.state === 'unsubmitted' && showForm" class="flex-row cg8 justify-start items-center">
    <mat-icon aria-label="unsubmitted" matTooltip="Unsubmitted" class="text-light-blue" i18n-matTooltip> info </mat-icon>
    <span class="text-grey" i18n>Your application is uploaded, but incomplete. Please fill out missing information and submit again.</span>
</div>

<div *ngIf="!applicationExists && showForm" class="flex-row cg8 justify-start items-center">
    <span class="text-grey" i18n>Fill in your company information to submit the Clearhaus application.</span>
</div>

<div *ngIf="!showForm" class="flex-row cg16 justify-start items-center">
    <small class="text-grey" i18n>Loading Clearhaus company information... {{ loaded | number:'1.0-0' }}%</small>
    <mat-progress-spinner diameter="30" mode="indeterminate" color="accent"></mat-progress-spinner>
</div>

<mat-vertical-stepper linear #stepper *ngIf="application.metadata?.state !== 'submitted' && showForm">
    <ng-template matStepperIcon="edit" let-index="index"> {{ index + 1 }} </ng-template>

    <mat-step label="Contact information" [completed]="validity.contact" i18n-label>
        <qp-clearhaus-signup-step-one [contact]="contact" (contactChange)="contact = $event" (validityChange)="updateValidity({ contact: $event })"> </qp-clearhaus-signup-step-one>
    </mat-step>

    <mat-step label="Company information" [completed]="validity.company" i18n-label>
        <qp-clearhaus-signup-step-two *ngIf="account" [account]="account" [company]="company" [supportedCountries]="supportedCountries" (companyChange)="handleCompanyChange($event)" (validityChange)="updateValidity({ company: $event})"> </qp-clearhaus-signup-step-two>
    </mat-step>

    <mat-step label="Director" [completed]="validity.director" i18n-label>
        <qp-clearhaus-signup-step-three *ngIf="account" [account]="account" [countries]="countries" [director]="director" (directorChange)="director = $event" (validityChange)="updateValidity({ director: $event})"> </qp-clearhaus-signup-step-three>
    </mat-step>

    <mat-step label="Ownership" [completed]="validity.ownership" i18n-label>
        <qp-clearhaus-signup-step-four
            #ownershipStep
            *ngIf="account"
            [account]="account"
            [company]="company"
            [countries]="countries"
            [director]="director"
            [owners]="owners"
            (companyChange)="handleCompanyChange($event)"
            (ownersChange)="owners = $event"
            (validityChange)="updateValidity({ ownership: $event})"
        >
        </qp-clearhaus-signup-step-four>
    </mat-step>

    <mat-step label="Websites" [completed]="validity.websites" i18n-label>
        <qp-clearhaus-website-form [websites]="websites" (validityChange)="updateValidity({ websites: $event})"> </qp-clearhaus-website-form>
    </mat-step>

    <mat-step label="Business model" [completed]="validity.business" i18n-label>
        <qp-clearhaus-signup-step-six [application]="application" [currencies]="currencies" (applicationChange)="application = $event" (validityChange)="updateValidity({ business: $event})"> </qp-clearhaus-signup-step-six>
    </mat-step>

    <mat-step label="Bank Account" [completed]="validity.bank" i18n-label>
        <qp-clearhaus-signup-step-seven [bank_account]="bank_account" [currencies]="currencies" (bankAccountChange)="bank_account = $event" (validityChange)="updateValidity({ bank: $event})"> </qp-clearhaus-signup-step-seven>
    </mat-step>

    <mat-step *ngIf="company.country !== 'GBR' && company.form !== 'Other'" label="Documentation" [completed]="validity.documentation" i18n-label>
        <qp-clearhaus-signup-step-eight #documentationStep [company]="company" [documentationFile]="documentationFile" (validityChange)="updateValidity({ documentation: $event})"> </qp-clearhaus-signup-step-eight>
    </mat-step>

    <mat-step label="Additional information" completed i18n-label>
        <qp-clearhaus-signup-step-nine [additional_information]="additional_information" [files]="files" (additionalInfoChange)="additional_information = $event" (filesChange)="files = $event"> </qp-clearhaus-signup-step-nine>
    </mat-step>

    <mat-step label="Submit" i18n-label>
        <qp-clearhaus-signup-submit
            [additional_information]="additional_information"
            [application]="application"
            [bank_account]="bank_account"
            [company]="company"
            [contact]="contact"
            [director]="director"
            [files]="files"
            [filesFromServer]="filesFromServer"
            [applicationExists]="applicationExists"
            [websitesFromServer]="websitesFromServer"
            [owners]="owners"
            [people]="people"
            [websites]="websites"
            [validity]="validity"
            (applicationUploaded)="getApplication()"
            (getPersonFiles)="getPersonFiles($event)"
            (postGetApplication)="postGetApplication()"
            (validateDocumentation)="documentationStepComponent.validateDocumentation()"
        >
        </qp-clearhaus-signup-submit>
    </mat-step>
</mat-vertical-stepper>
