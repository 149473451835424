import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionGroupRepo } from '@endpoints/api/subscription-group-repo';
import { SubscriptionRepo } from '@endpoints/api/subscription-repo';
import { FilterParams } from '@interfaces/params';
import { Card } from '@models/card';
import { Payment } from '@models/payment';
import { Payout } from '@models/payout';
import { Subscription } from '@models/subscription';
import { SubscriptionGroup } from '@models/subscription-group';
import { SubscriptionGroupResult } from '@models/subscription-group-result';
import { CurrencyService } from '@services/currency/currency.service';
import { SearchService } from '@services/search/search.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { FilterService } from '@widgets/filters/filters.service';
import { of as observableOf } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { TransactionsService } from '../../transactions.service';

@Component({
    selector: 'qp-subscriptions-group-tool',
    templateUrl: './subscriptions-group-tool.html'
})
export class SubscriptionGroupToolbarComponent implements OnInit {
    @Output() public actionComplete = new EventEmitter();

    @Input() public filters: FilterParams;
    @Input() public selected_transactions = new SelectionModel<Card | Payment | Payout | Subscription>(true, []);

    public objectKeys = Object.keys;
    public groups: SubscriptionGroup[];

    constructor(
        private currencyService: CurrencyService,
        private dialogService: DialogService,
        private subscriptionGroupRepo: SubscriptionGroupRepo,
        public filtersService: FilterService,
        public searchService: SearchService,
        public subscriptionRepo: SubscriptionRepo,
        private transactionService: TransactionsService
    ) {}

    public ngOnInit(): void {
        this.getSubscriptionGroups();
    }

    public getSubscriptionGroups(): void {
        this.groups = [];
        this.subscriptionGroupRepo.getAll({ page_size: 100 }).subscribe((subscribtiongroups: SubscriptionGroup[]) => {
            this.groups = subscribtiongroups;
        });
    }

    public captureGroup(): void {
        this.transactionService.captureSubscriptionGroup(this.filters.subscription_group).subscribe((result: SubscriptionGroupResult[]) => {
            let success = '';
            let msg = '';

            if (result) {
                for (const key in result) {
                    if (result.hasOwnProperty(key)) {
                        const row: SubscriptionGroupResult = result[key];
                        if (row.message) {
                            let err = '';

                            if (row.errors) {
                                Object.keys(row.errors).forEach(error => {
                                    err += error + row.errors[error] + '\n';
                                });
                            }

                            msg +=
                                $localize`Subscription id: ` +
                                row.subscription_id +
                                ' ' +
                                row.message +
                                '\n' +
                                err +
                                '\n';
                        } else {
                            success += row.subscription_id + '\n';
                        }
                    }
                }
            }

            if (msg !== '') {
                const replaceMsg = msg.replace('undefined', '');
                this.dialogService.alert(
                    $localize`The capture request has been processed`,
                    $localize`The request was rejected for the following subscriptions:` +
                    '\n' +
                    replaceMsg +
                    $localize`The request was approved for the following subscriptions:` +
                    '\n' +
                    success
                );
            } else {
                this.dialogService.alert(
                    $localize`The capture request has been processed`,
                    $localize`All subscriptions are successfully captured`
                );
            }
        });
    }

    public addToGroup(group: SubscriptionGroup): void {
        let count = 0;
        const groupErrors: string[] = [];

        if (this.selected_transactions.selected.length > 0) {
            for (const key in this.selected_transactions.selected) {
                if (this.selected_transactions.selected.hasOwnProperty(key)) {
                    const subscription = this.selected_transactions.selected[key] as Subscription;
                    subscription.group_ids.push(group.id);

                    this.subscriptionRepo.update(
                        { id: subscription.id, group_ids: subscription.group_ids } as Subscription
                    ).pipe(
                        catchError((err: any) => {
                            groupErrors.push(
                                $localize`Subscription with id ` +
                                subscription.id +
                                $localize` got error: ` +
                                err.error.message
                            );
                            return observableOf(err);
                        })
                    ).subscribe(() => {
                        if (count < this.selected_transactions.selected.length - 1) {
                            count++;
                        } else {
                            if (groupErrors.length) {
                                const msg: string = groupErrors.join('\n');
                                this.dialogService.alert(
                                    $localize`Error`,
                                    msg
                                ).afterClosed().subscribe();
                            } else {
                                this.selected_transactions.clear();
                                this.actionComplete.emit();
                            }
                        }
                    });
                }
            }
        }
    }

    public removeFromGroup(): void {
        let count = 0;
        const groupErrors: string[] = [];

        this.dialogService.confirm(
            $localize`Remove from group?`,
            $localize`Are you sure you want to remove the group from the selected subscriptions?`
        ).afterClosed().pipe(
            filter(confirmed => confirmed),
            map(() => {
                for (const key in this.selected_transactions.selected) {
                    if (this.selected_transactions.selected.hasOwnProperty(key)) {
                        const subscription = this.selected_transactions.selected[key] as Subscription;
                        const removeIndex = subscription.group_ids.map((item: any) => item.id).indexOf(this.filters.subscription_group?.id);
                        subscription.group_ids.splice(removeIndex, 1);

                        this.subscriptionRepo.update(
                            { id: subscription.id, group_ids: subscription.group_ids } as Subscription
                        ).pipe(
                            catchError((err: any) => {
                                groupErrors.push(
                                    $localize`Subscription with id ` +
                                    subscription.id +
                                    $localize` got error: ` +
                                    err.error.message
                                );
                                return observableOf(err);
                            })
                        ).subscribe(() => {
                            if (count < this.selected_transactions.selected.length - 1) {
                                count++;
                            } else {
                                if (groupErrors.length) {
                                    const msg: string = groupErrors.join('\n');
                                    this.dialogService.alert(
                                        $localize`Error`,
                                        msg
                                    ).afterClosed().subscribe();
                                }
                                this.selected_transactions.clear();
                                this.actionComplete.emit();
                            }
                        });
                    }
                }
            })
        ).subscribe();
    }
}
