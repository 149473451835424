import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class QpSnackBar {
    constructor(private snackBar: MatSnackBar) {}

    public open(msg: string, action?: string, config?: MatSnackBarConfig): void {
        config = Object.assign(
            {
                duration: 2000,
                horizontalPosition: 'right',
                verticalPosition: 'top'
            },
            config
        );
        this.snackBar.open(msg, action, config);
    }
}
