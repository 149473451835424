import { ApiClient } from './api-client';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaymentLinkUrl } from '@models/payment-link-url';
import { Payout } from '@models/payout';
import { Transaction } from '@models/transaction';

@Injectable({
    providedIn: 'root'
})
export class PayoutRepo {
    constructor(private client: ApiClient) {}

    public get<Payout>(id: number): Observable<Payout> {
        return this.client.get<Payout>('/payouts/' + id).pipe(
            map((item: any) => Object.assign(new Payout(), item)
        ));
    }

    public getAll(params: any): Observable<Payout[]> {
        params = params || {};
        return this.client.get<Payout>('/payouts', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Payout(), item))
        ));
    }

    public update(transaction: Transaction): Observable<Payout> {
        return this.client.patch<Payout>('/payouts/' + transaction.id, transaction).pipe(
            map((item: any) => Object.assign(new Payout(), item)
        ));
    }

    public credit(id: number, params: any): Observable<Payout> {
        params = params || {};
        return this.client.post<Payout>('/payouts/' + id + '/credit', params).pipe(
            map((item: any) => Object.assign(new Payout(), item)
        ));
    }

    public create(params: any): Observable<Payout> {
        params = params || {};
        return this.client.post<Payout>('/payouts', params).pipe(
            map((item: any) => Object.assign(new Payout(), item)
        ));
    }

    public replaceLink(id: number, params: any): Observable<PaymentLinkUrl> {
        return this.client.put<Payout>('/payouts/' + id + '/link', params).pipe(
            map((item: any) => Object.assign(new PaymentLinkUrl(), item)
        ));
    }
}
