import { DirectorFile } from './director-file';

export class Person {
    public id?: string;
    public name?: string;
    public social_security_number?: string;
    public date_of_birth?: string;
    public address_line_1?: string;
    public address_line_2?: string;
    public zipcode?: string;
    public city?: string;
    public country?: string;
    public role_director?: boolean;
    public role_owner?: boolean;
    public files?: DirectorFile[];
}
