import { Injectable } from '@angular/core';
import { PaypalApiClient } from '@endpoints/api/paypal-client';
import { environment } from '@environments/environment';
import { Base64 } from '@services/base64/base64';
import { ApiClient } from 'app/endpoints/api/api-client';
import { InvoiceClient } from 'app/endpoints/invoice/invoice-client';
import { ServerClient } from 'app/endpoints/server/server-client';
import { StatisticsClient } from 'app/endpoints/statistics/statistics-client';

@Injectable({
    providedIn: 'root'
})
export class CredentialsService {
    public password: string;
    public username: string;

    constructor(
        private apiClient: ApiClient,
        private base64: Base64,
        private invoiceClient: InvoiceClient,
        private paypalClient: PaypalApiClient,
        private serverClient: ServerClient,
        private statisticsClient: StatisticsClient
    ) {}

    public setCredentials(username = this.username, password = this.password): void {
        const api_key = this.generateBasicKey(username, password);
        this.apiClient.setHeader('Authorization', api_key);
        this.invoiceClient.setHeader('Authorization', api_key);
        this.statisticsClient.setHeader('Authorization', api_key);
        this.serverClient.setHeader('Authorization', api_key);
        this.paypalClient.setHeader('Authorization', api_key);
    }

    public clearCredentials(): void {
        this.apiClient.deleteHeader('Authorization');
        this.invoiceClient.deleteHeader('Authorization');
        this.serverClient.deleteHeader('Authorization');
        this.statisticsClient.deleteHeader('Authorization');
        this.paypalClient.deleteHeader('Authorization');
        if (environment.devMode) {
            window.sessionStorage.clear();
        }
    }

    public generateBasicKey(username: string, password: string): string {
        return 'Basic ' + this.base64.encodeUnicode(username + ':' + password);
    }

    public setUsername(username: string): void {
        this.username = username;
    }

    public setPassword(password: string): void {
        this.password = password;
    }

    public getUserCredentials(): string {
        return this.username + ':' + this.password;
    }

    public getUserApiKey(): string {
        return this.generateBasicKey(this.username, this.password);
    }

    public getBase64Credentials(api_key: string): string {
        return 'Basic ' + this.base64.encodeUnicode(':' + api_key);
    }
}
