import { Account } from '@interfaces/account';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { Agreement } from '@interfaces/agreement';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { AgreementService } from '@services/agreement/agreement.service';
import { Animations } from 'app/animations/animations';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { DialogService } from '@widgets/dialog/dialog.service';
import { EMPTY, of } from 'rxjs';
import { HomepageService } from '@services/homepage/homepage.service';
import { isEqual } from 'lodash-es';
import { Locale } from '@services/locales/locales.interface';
import { LocalesService } from '@services/locales/locales.service';
import { Me } from '@interfaces/me';
import { PermissionTemplatesService } from '@services/permission-templates/permission-templates.service';
import { PrivateKey } from '@models/private-key';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { SessionService } from '@services/session/session.service';
import { SHOPSYSTEMS } from '@widgets/shopsystems/shopsystems.constants';
import { switchMap, exhaustMap, catchError, tap, filter } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'qp-integration',
    templateUrl: './integration.html',
    animations: [Animations.getFadeAnimation()]
})
export class IntegrationComponent extends BaseLoadingDirective implements OnInit {
    public integrationForm: UntypedFormGroup;
    public account: Account | undefined;
    public agreement_id: number;
    public agreements: Agreement[] = [];
    public api_key_agreements: Agreement[] = [];
    public locales: Locale[] = [];
    public me: Me | undefined;
    public permissionTemplates: any;
    public privateKey: string;
    public sessionAgreement: Agreement | undefined;
    public shopsystem: any = {};
    public shopsystemLink: string;
    public takingDownProxy: boolean;
    public helpdeskLink: string;
    public formSaved = false;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private accountRepo: AccountRepo,
        private agreementRepo: AgreementRepo,
        private dialogService: DialogService,
        private homepageService: HomepageService,
        private localesService: LocalesService,
        private permissionTemplateService: PermissionTemplatesService,
        private sessionService: SessionService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        super();
        this.account = this.agreementService.agreement?.account;
        this.locales = this.localesService.getMasterLocales();
        this.me = this.sessionService.me;
        this.permissionTemplates = this.permissionTemplateService.getTemplates();
        this.sessionAgreement = this.agreementService.agreement;
    }

    public ngOnInit(): void {
        const disable_autofee = this.account?.shopsystem === 'Shopify';
        this.integrationForm = new UntypedFormGroup({
            callbackUrl: new UntypedFormControl({ value: this.account?.callback_url, disabled: false }),
            defaultTextOnStatement: new UntypedFormControl({ value: this.account?.default_text_on_statement, disabled: false }),
            defaultPaymentMethods: new UntypedFormControl({ value: this.account?.default_payment_methods, disabled: false }),
            defaultPaymentLanguage: new UntypedFormControl({ value: this.account?.default_payment_language, disabled: false }),
            autofee: new UntypedFormControl({ value: this.account?.autofee, disabled: disable_autofee }),
            allowTestTransactions: new UntypedFormControl({ value: this.account?.allow_test_transactions, disabled: false })
        });
        this.helpdeskLink = this.homepageService.getHelpdeskLink('integration-setup/');
        this.getPrivateKey();
        this.getShopsystem();
        this.getAgreements();
    }

    public getPrivateKey(): void {
        this.accountRepo.getPrivateKey().subscribe((privateKey: PrivateKey) => {
            this.privateKey = privateKey.private_key;
        });
    }

    public getShopsystem(): void {
        for (const shopsystem of SHOPSYSTEMS) {
            if (shopsystem['name'] === this.account?.shopsystem) {
                this.shopsystem = shopsystem;
                this.shopsystemLink = this.getShopsystemLink();
            }
        }
    }

    public getShopsystemLink(): string {
        const locale = this.locale !== 'da' ? 'en' : 'da';
        const link = this.shopsystem.links ? this.shopsystem.links[locale] : null;

        return link;
    }

    public getAgreements(): void {
        this.agreementRepo.getAll({ page_size: 100 }).subscribe(agreements => {
            this.agreement_id = agreements.find(agreement => agreement.service === 'payment_window')?.id ?? NaN;
            this.agreements = agreements.filter(agreement =>
                agreement.service !== null || agreement.id === this.agreementService.agreement?.id
            );
            this.api_key_agreements = this.agreements.filter(agreement =>
                !this.shopsystem.options ||
                (this.shopsystem.options && agreement.service && this.shopsystem.options[agreement.service])
            );
        });
    }

    public isTemplateSet(agreement: any, templateName: string): boolean {
        let template: any;
        let tempGroup: any;
        if (!this.account?.type) {
            return false;
        }
        for (const group of Object.keys(this.permissionTemplates[this.account.type])) {
            tempGroup = this.permissionTemplates[this.account.type][group];
            for (const temp of tempGroup) {
                if (temp.name === templateName) {
                    template = temp.acl_permissions;
                }
            }
        }

        if (!template) {
            return true;
        }

        return isEqual(template, agreement.acl_permissions);
    }

    public setPermissions(agreement: Agreement, templateName: string): void {
        this.dialogService.confirm(
            $localize`Update permissions`,
            $localize`Update permissions for ${agreement.service}`,
            $localize`Ok`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            exhaustMap(() => {
                let template: any;
                if (!this.account?.type) {
                    return EMPTY;
                }
                for (const key of Object.keys(this.permissionTemplates[this.account.type])) {
                    this.permissionTemplates[this.account.type][key].forEach((entry: any) => {
                        if (entry.name === templateName) {
                            template = entry.acl_permissions;
                        }
                    });
                }

                const tempAgreement: any = Object.assign({}, agreement);
                agreement.acl_permissions = template;
                return this.agreementRepo.replace(agreement).pipe(
                    switchMap((returnAgreement: Agreement) => {
                        this.agreements[this.agreements.indexOf(tempAgreement)] = returnAgreement;
                        this.snackBar.open($localize`Permissions updated`);
                        return EMPTY;
                    })
                );
            })
        ).subscribe();
    }

    public clearDefaultLanguage(): void {
        this.getIntegrationFormControlByKey('defaultPaymentLanguage').setValue(null);
        this.integrationForm.markAsTouched();
        this.integrationForm.markAsDirty();
    }

    public saveAccount(showSnackbar: boolean): void {
        const values = {
            callback_url: this.getIntegrationFormControlByKey('callbackUrl').value,
            default_text_on_statement: this.getIntegrationFormControlByKey('defaultTextOnStatement').value,
            default_payment_methods: this.getIntegrationFormControlByKey('defaultPaymentMethods').value,
            default_payment_language: this.getIntegrationFormControlByKey('defaultPaymentLanguage').value,
            autofee: this.getIntegrationFormControlByKey('autofee').value,
            allow_test_transactions: this.getIntegrationFormControlByKey('allowTestTransactions').value
        };

        if (values.callback_url === '') {
            values.callback_url = null;
        }

        this.accountRepo.update(values, {}, '').pipe(
            tap((account: Account) => {
                if (!this.agreementService.agreement) {
                    return;
                }
                this.agreementService.agreement.account = account;
                this.account = account;
                if (showSnackbar) {
                    this.snackBar.open($localize`Account updated`);
                }
            }),
            catchError((err: any) => {
                this.setupApiErrors(err);
                return of();
            })
        ).subscribe();

        this.integrationForm.markAsPristine();
        this.integrationForm.markAsUntouched();
        this.formSaved = !showSnackbar;
    }

    public setupApiErrors(errors: any): void {
        let error: any;
        let errorText = '';
        for (const key in errors.error.errors) {
            if (errors.error.errors.hasOwnProperty(key)) {
                error = errors.error.errors[key];
                if (Array.isArray(error)) {
                    for (const errorKey in error) {
                        if (error.hasOwnProperty(errorKey)) {
                            if (errorText !== '') {
                                errorText += ', ';
                            }
                            errorText += error[errorKey];
                        }
                    }
                } else {
                    errorText = error;
                }
            }
        }

        this.dialogService.alert($localize`Error`, errorText);
    }

    public copyApiKey(agreement: Agreement): void {
        this.dialogService.copy(
            $localize`${this.getUserName(agreement)} - API key`,
            agreement.api_key ?? '',
            $localize`Key copied to clipboard`
        );
    }

    public copyPrivateKey(): void {
        this.dialogService.copy(
            $localize`Private key`,
            this.privateKey,
            $localize`Key copied to clipboard`
        );
    }

    public getIntegrationFormControlByKey(
        key:
            | 'allowTestTransactions'
            | 'autofee'
            | 'defaultPaymentLanguage'
            | 'defaultPaymentMethods'
            | 'defaultTextOnStatement'
            | 'callbackUrl'
    ): UntypedFormControl {
        return this.integrationForm.get(key) as UntypedFormControl;
    }

    public getUserName(agreement: Agreement): string {
        if (agreement?.user?.system_user) {
            return agreement?.user?.name ?? '';
        }
        return agreement?.user?.email ?? '';
    }
}
