<div class="flex-row">
    <button mat-icon-button (click)="onClickCapture()" *ngIf="is_capturable" i18n-matTooltip matTooltip="Capture payment">
        <mat-icon color="primary">file_download</mat-icon>
    </button>

    <button mat-icon-button (click)="onClickRefund()" *ngIf="is_refundable" i18n-matTooltip matTooltip="Refund payment">
        <mat-icon color="primary">undo</mat-icon>
    </button>

    <button mat-icon-button (click)="onClickCancel()" *ngIf="is_cancelable" i18n-matTooltip matTooltip="Cancel payment">
        <mat-icon color="primary">close</mat-icon>
    </button>

    <button mat-icon-button (click)="onClickRetry()" *ngIf="is_link && transaction_type === 'payments'" i18n-matTooltip matTooltip="Get payment link">
        <mat-icon color="primary">link</mat-icon>
    </button>

    <button mat-icon-button (click)="onClickRetry()" *ngIf="is_link && transaction_type === 'cards'" i18n-matTooltip matTooltip="Retry card">
        <mat-icon color="primary">link</mat-icon>
    </button>
</div>
