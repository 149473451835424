<br />
<div class="flex-row justify-center items-center">
    <div class="flex-col flex-80-mobile-100">
        <div class="flex-col centered-text">
            <span *ngIf="searchBy" i18n> Searched {{ getPresentOptions().name }} </span>
            <span i18n> No {{ type }} were found. </span>
            <span *ngIf="search" i18n> for <span class="subtitle1">'{{ search }}'.</span> </span>
            <span *ngIf="searchBy === 'id'" i18n> Make sure you are searching for an exact ID. </span>
        </div>

        <span class="subtitle1"
            *ngIf="(state && states[state] !== 'all') ||
                anyFiltersActive() ||
                (getOtherOptions().length > 0) ||
                (getPresentOptions().wildcard === true && !isWildcardSearch()) || allowWildcard"
                i18n
        >
            Search suggestions:
        </span>

        <ul class="flex-col rg8">
            <li *ngIf="state && states[state] !== 'all'" (click)="setStateAll()">
                <span class="no-results-link" i18n> Search for ALL {{ type }} </span>
            </li>
            <li *ngIf="anyFiltersActive()" (click)="removeFilters()">
                <span class="no-results-link" i18n>Remove filters</span>
            </li>
            <li *ngFor="let option of getOtherOptions()" (click)="setSearchBy(option, search)">
                <span class="no-results-link" i18n>Search {{ option.name }}</span>
            </li>
            <li *ngIf="(getPresentOptions().wildcard === true && !isWildcardSearch()) || allowWildcard" (click)="setWildcardSearch()">
                <span i18n>Use wildcards. Search for</span>
                <span class="no-results-link">%{{ searchWithoutWildcard() }}%</span>
            </li>
        </ul>
    </div>
</div>
