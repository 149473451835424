<h5>Payouts</h5>

<ng-container *ngIf="settlement">
    <table mat-card-image class="mat-table details-table">
            <tr class="text-left text-grey">
                <th class="code font-weight-normal" i18n>Type</th>
                <th class="code font-weight-normal" i18n>Date</th>
                <th class="code font-weight-normal" i18n>Descriptor</th>
                <th class="code font-weight-normal" i18n>Reference number</th>
                <th class="text-right code font-weight-normal" i18n>Amount</th>
            </tr>
            <tr class="text-left code">
                <td i18n>Payout</td>
                <td><qp-date-field *ngIf="settlement.payout" [date]="settlement.payout.date"></qp-date-field></td>
                <td>{{ settlement.payout?.descriptor }}</td>
                <td>{{ settlement.payout?.reference_number }}</td>
                <td class="text-right">{{ settlement.payout?.amount ?? 0 | qpCurrency: settlement.currency }} {{ settlement.currency }}</td>
            </tr>
            <tr class="text-left code" *ngIf="!!settlement?.reserve">
                <td i18n>Reserve</td>
                <td><qp-date-field *ngIf="settlement.reserve" [date]="settlement.reserve.date"></qp-date-field></td>
                <td>{{ settlement.reserve?.descriptor }}</td>
                <td>{{ settlement.reserve?.reference_number }}</td>
                <td class="text-right">{{ settlement.reserve?.amount ?? 0 | qpCurrency: settlement.currency }} {{ settlement.currency }}</td>
            </tr>
    </table>
    <div class="flex-row items-center code margin r8" *ngIf="!!settlement?.reserve">
        <span class="flex-1"></span>
        <span class="subtitle1 margin r8" i18n>Total:</span>
        <span class="align-right">{{ ((settlement.payout?.amount ?? 0) + (settlement.reserve?.amount ?? 0)) | qpCurrency: settlement.currency }} {{ settlement.currency }}</span>
    </div>
</ng-container>
