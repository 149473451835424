export const PAYMENT_WINDOW_PERMISSIONS = [
    {
        resource: '/account',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/account/logo',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/authorize',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/tokens',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payment-methods',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/payments/:id/authorize',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/cancel',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/capture',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/session',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payouts/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/payouts/:id/credit',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payouts/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/subscriptions/:id/authorize',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/session',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    }
];
