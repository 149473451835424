<h5 i18n mat-dialog-title>Include</h5>
<mat-dialog-content class="grey-border-bottom">
    <form class="flex-col rg8 form">
        <div class="flex-col">
            <mat-checkbox
                [checked]="fields.invoice_address"
                name="invoice_address"
                [(ngModel)]="fields.invoice_address"
                *ngIf="data.fields.display"
                i18n
            >
                Invoice address
            </mat-checkbox>
            <mat-checkbox
                [checked]="fields.shipping_address"
                name="shipping_address"
                [(ngModel)]="fields.shipping_address"
                *ngIf="data.fields.display"
                i18n
            >
                Shipping address
            </mat-checkbox>
            <mat-checkbox [checked]="fields.variablesAssembled" name="variablesAssembled" [(ngModel)]="fields.variablesAssembled" i18n>
                Variables
            </mat-checkbox>
            <mat-checkbox [checked]="fields.variablesSplit" name="variablesSplit" [(ngModel)]="fields.variablesSplit" i18n>
                Specify variables
            </mat-checkbox>
            <mat-checkbox [checked]="fields.basket" name="basket" [(ngModel)]="fields.basket" *ngIf="data.fields.display" i18n>
                Basket
            </mat-checkbox>
        </div>

        <div *ngIf="fields.variablesSplit" class="flex-col">
            <span i18n>Specified variables will be exported with the variable name as column header</span>
            <div>
                <button mat-raised-button color="accent" (click)="addVariable()" i18n>Add variable</button>
            </div>

            <div *ngFor="let variable of fields.variablesSplitValue; let i = index">
                <div class="flex-row space-between items-center">
                    <div class="inner-t-xs">{{ variable }}</div>
                    <button mat-icon-button (click)="fields.variablesSplitValue.splice(i, 1)" color="accent" class="inline-save large">
                        <mat-icon class="s18">clear</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button mat-button color="accent" mat-dialog-close i18n>Close</button>
    <button mat-button color="accent" (click)="this.dialogRef.close(fields)" i18n>Export</button>
</mat-dialog-actions>
