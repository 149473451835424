import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Agreement } from '@interfaces/agreement';
import { Theme } from '@interfaces/theme';
import { QpAccountTypePipe } from '@pipes/qp-account-type.pipe';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { CredentialsService } from '@services/credentials/credentials.service';
import { SessionService } from '@services/session/session.service';
import { ThemingService } from '@services/theming/theming.service';
import { Animations } from 'app/animations/animations';
import { Subscription } from 'rxjs';

@Component({
    selector: 'qp-account-layout',
    templateUrl: 'layout.html',
    styleUrls: ['./layout.scss'],
    animations: [Animations.getRotateChevronAnimation()]
})
export class AccountLayoutComponent implements OnInit, OnDestroy {
    @ViewChild('sideNav') public sideNav: MatSidenav;

    private _agreement: Agreement | undefined;
    public breakpointSubscription: Subscription;
    public countObjects: any[] = [];
    public is_suspended: boolean;
    public mode: MatDrawerMode = 'side';
    public resellerAgreement: Agreement | undefined = undefined;
    public routesRoot: Route[] = [];
    public routesSettings?: Route;;
    public routesTools?: Route;
    public sessionAgreements: Agreement[] = [];
    public showSettings = false;
    public showTools = false;
    public showSupport = false;
    public theme: Theme;
    public dark_mode: boolean;

    private params_subscription = new Subscription();

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private acquirerService: AcquirerService,
        public agreementService: AgreementService,
        private breakpointObserver: BreakpointObserver,
        private sessionService: SessionService,
        private themingService: ThemingService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private credentialService: CredentialsService
    ) {
        this.theme = this.themingService.theme;
    }

    public get agreement(): Agreement | undefined {
        return this._agreement;
    }

    public set agreement(agreement: Agreement | undefined) {
        this._agreement = agreement;
    }

    public ngOnInit(): void {
        this.init();
        this.params_subscription = this.activatedRoute.params.subscribe(() => {
            this.init();
        });
    }

    public ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
    }

    private init(): void {
        this.setMode();
        this.agreement = this.agreementService.agreement;
        this.is_suspended = !!(this.agreement && this.agreement.account?.suspended_at);
        this.sessionAgreements = this.agreementService.agreements;

        if (!(new QpAccountTypePipe(this.agreementService).transform(['Merchant', 'Reseller'])) &&
            this.agreementService.hasAgreements()) {
            this.agreementService.setAgreements([]);
            this.sessionAgreements = [];
        }

        if (this.agreementService.agreement) {
            this.routesRoot = this.getRootNavigtion();
            this.routesSettings = this.getSubNavigation('settings');
            this.routesTools = this.getSubNavigation('tools');
            this.sortRoutes();

            if ((new QpAccountTypePipe(this.agreementService).transform(['Merchant', 'Reseller'])) &&
                this.locale !== 'en') {
            }
        }
        this.getResellerAgreement(this.agreementService.agreements);

        this.dark_mode = this.sessionService.dark_mode;
        document.body.classList.toggle('dark-theme', this.dark_mode);
    }

    public routeTo(route = ''): void {
        if (!route) {
            this.router.navigate(['/account', this.agreement?.account?.id]);
            return;
        }
        if (this.mode === 'over') {
            this.sideNav.toggle();
        }
        this.router.navigate([route], { relativeTo: this.activatedRoute });
    }

    public isActive(destination = ''): boolean {
        const base_path = `/account/${this.agreement?.account?.id}`;
        const match_path = `${base_path}${destination ? '/' : ''}${destination}`;

        return this.router.isActive(match_path, {
            fragment: 'ignored',
            queryParams: 'ignored',
            matrixParams: 'ignored',
            paths: 'exact'
        });
    }

    public isSubentryActive(destination: string): boolean {
        return location.pathname.includes(destination);
    }

    public signOut(): void {
        this.sessionService.logout();
        window.onbeforeunload = null;
        this.router.navigate(['/login']);
        document.body.classList.toggle('dark-theme', false);
    }

    public getAccountName(agreement: Agreement | undefined): string {
        return agreement?.account?.customer_address && agreement.account?.customer_address.name ?
            agreement.account?.customer_address.name :
            agreement?.account?.type ?? '';
    }

    public getSubNavigation(path_name: string): Route | undefined {
        const sortRoutes = (a: Route, b: Route): number =>
            a.data?.settings.content < b.data?.settings.content ? -1 : a.data?.settings.content > b.data?.settings.content ? 1 : 0;

        const routeSettings = this.activatedRoute.routeConfig?.children?.find((route) => route.path === path_name);
        if (routeSettings && !this.hasRequiredRole(routeSettings)) {
            return;
        }
        return {
            ...routeSettings,
            children: routeSettings?.children?.filter(route =>
                !!route.path && !!route.data?.settings.index &&
                !this.isSuspended(route) &&
                !this.isOnlyQpReseller(route) &&
                this.hasPermissions(route) &&
                this.isOwner(route) &&
                this.hasRequiredRole(route))
                .sort((a: Route, b: Route) => sortRoutes(a, b))
        };
    }

    public hasRequiredRole(route: Route): boolean {
        if (route.data?.settings.roles) {
            return route.data?.settings.roles.includes(this.agreement?.account?.type);
        }
        return true;
    }

    public isOwner(route: Route): boolean {
        if (route.data?.settings.owner && !this.agreementService.isOwner()) {
            return false;
        }
        return true;
    }

    public hasPermissions(route: Route): boolean {
        if (route.data?.settings.permission) {
            let hasPermission = true;
            Object.keys(route.data?.settings.permission).forEach(key => {
                const permissions = route.data?.settings.permission[key];
                for (const permission of permissions) {
                    if (!this.agreementService.hasPermission(key, permission)) {
                        hasPermission = false;
                    }
                }
            });
            if (!hasPermission) {
                return false;
            }
        }
        return true;
    }

    public isSuspended(route: Route): boolean {
        if (
            !this.agreement?.support &&
            this.is_suspended &&
            !route.data?.settings.allowSuspended
        ) {
            return true;
        }
        return false;
    }

    public isOnlyQpReseller(route: Route): boolean {
        if (route.data?.settings.onlyQpReseller) {
            if (new QpAccountTypePipe(this.agreementService).transform(['Merchant']) && !new QpMerchantTypePipe(this.agreementService).transform(['OldQuickpay'])) {
                return true;
            }
        }
        return false;
    }

    public showPayouts(route: Route): boolean {
        if (route.data?.settings.title === 'Payouts') {
            if (
                this.acquirerService.acquirers &&
                this.acquirerService.acquirers.clearhaus &&
                !this.acquirerService.acquirers.clearhaus.payout
            ) {
                return false;
            }
        }
        return true;
    }

    public showSettlementsAndDisputes(route: Route): boolean {
        if (route.data?.settings.title === 'Settlements' || route.data?.settings.title === 'Disputes') {
            if (!new QpAccountTypePipe(this.agreementService).transform(['Merchant']) || !this.agreementService.isOwner()) {
                return false;
            }

            if (
                !this.agreementService.hasPermission('/acquirers', 'get') ||
                !new QpMerchantTypePipe(this.agreementService).transform(['Quickpay', 'Unzer'])
            ) {
                return false;
            }
        }
        return true;
    }

    public showAgreements(route: Route): boolean {
        if (route.data?.settings.title === 'Agreements') {
            if (this.agreement?.account?.reseller.id !== environment.unzerResellerId) {
                return false;
            }
        }
        return true;
    }

    public showPackages(route: Route): boolean {
        if (route.data?.settings.title === 'Packages') {
            return this.agreementService.isReseller() && this.agreementService.isQpReseller();
        }
        return true;
    }

    public hasIndex(route: Route): boolean {
        return !!route.data?.settings.index;
    }

    public getRootNavigtion(): any {
        const routes = this.activatedRoute.routeConfig?.children ?? [];
        return routes
            .filter((route: Route) =>
                !route.data?.settings.header &&
                !!route.data?.settings.index &&
                !this.isSuspended(route) &&
                !this.isOnlyQpReseller(route) &&
                this.hasPermissions(route) &&
                this.isOwner(route) &&
                this.hasRequiredRole(route) &&
                this.showPayouts(route) &&
                this.showSettlementsAndDisputes(route) &&
                this.showAgreements(route) &&
                this.showPackages(route)
            );
    }

    public sortRoutes(): void {
        if (this.agreement?.account?.type === 'Merchant' || this.agreement?.account?.type === 'Reseller') {
            this.routesRoot.sort((a: Route, b: Route) => a.data?.settings?.index - b.data?.settings?.index);

            const disputeIndex = this.routesRoot.findIndex(item => item.data?.settings.title === 'Disputes');
            if (disputeIndex > 0) {
                const dispute = this.routesRoot[disputeIndex];
                this.routesRoot.splice(disputeIndex, 1);
                this.routesRoot.push(dispute);
            }

            const settlementIndex = this.routesRoot.findIndex(item => item.data?.settings.title === 'Settlements');
            if (settlementIndex > 0) {
                const settlement = this.routesRoot[settlementIndex];
                this.routesRoot.splice(settlementIndex, 1);
                this.routesRoot.push(settlement);
            }
        } else {
            this.routesRoot.sort((a: Route, b: Route) =>
                a.data?.settings.title < b.data?.settings.title ? -1 : a.data?.settings.title > b.data?.settings.title ? 1 : 0
            );

            const index = this.routesRoot.findIndex(item => item.data?.settings.title === 'Dashboard');
            const dashboard = this.routesRoot[index];
            this.routesRoot.splice(index, 1);
            this.routesRoot.unshift(dashboard);
        }
    }

    public translateRootRoute(route: Route): string {
        switch (route.data?.settings.title) {
            case 'Dashboard':
                return $localize`Dashboard`;
            case 'Payments':
                return $localize`Payments`;
            case 'Payouts':
                return $localize`Payouts`;
            case 'Saved cards':
                return $localize`Saved cards`;
            case 'Subscriptions':
                return $localize`Subscriptions`;
            case 'Disputes':
                return $localize`Disputes`;
            case 'Settlements':
                return $localize`Settlements`;
            case 'Binbase':
                return $localize`Binbase`;
            case 'Dashboard CMS':
                return $localize`Dashboard CMS`;
            case 'Merchants':
                return $localize`Merchants`;
            case 'Resellers':
                return $localize`Resellers`;
            case 'Transactions':
                return $localize`Transactions`;
            case 'Plans':
                return $localize`Plans`;
            case 'Audit log':
                return $localize`Audit log`;
            case 'Users':
                return $localize`Users`;
            case 'Sales':
                return $localize`Sales`;
            default:
                return route.data?.settings.title;
        }
    }

    public translateSettingRoute(route: Route): string {
        switch (route.data?.settings.title) {
            case 'Account':
                return $localize`Account`;
            case 'Acquirers':
                return $localize`Acquirers`;
            case 'Agreements':
                return $localize`Agreements`;
            case 'Billing':
                return $localize`Billing`;
            case 'Brandings':
                return $localize`Brandings`;
            case 'External Integrations':
                return $localize`External Integrations`;
            case 'Fraud filter':
                return $localize`Fraud filter`;
            case 'Integration':
                return $localize`Integration`;
            case 'Merchant':
                return $localize`Merchant`;
            case 'Users':
                return $localize`Users`;
            case 'Reseller':
                return $localize`Reseller`;
            case 'KMS':
                return $localize`KMS`;
            default:
                return route.data?.settings.title;
        }
    }

    public translateToolsRoute(route: Route): string {
        switch (route.data?.settings.title) {
            case 'Audit log':
                return $localize`Audit log`;
            case 'Payment link':
                return $localize`Payment link`;
            case 'Statistics':
                return $localize`Statistics`;
            case 'Virtual Terminal':
                return $localize`Virtual Terminal`;
            default:
                return route.data?.settings.title;
        }
    }

    // eslint-disable-next-line
    public addHotKey(key: string, route: any) {}

    public getResellerAgreement(agreements: Agreement[]): any {
        agreements.some((agreement: Agreement, _index: number, _array: Agreement[]) => {
            if (agreement.account?.type === 'Reseller') {
                this.resellerAgreement = agreement;
                return true;
            }
        });
    }

    public setMode(): void {
        this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((result: BreakpointState) => {
            this.mode = result.matches ? 'side' : 'over';
        });
    }

    public goToUnzerHelp(): void {
        if (this.locale !== 'de') {
            window.open('https://help.unzerdirect.com/kb/en/', '_blank');
        } else {
            window.open('https://help.unzerdirect.com/kb/de/', '_blank');
        }
    }

    public signOutTo(agreement: Agreement | undefined): void {
        if (!agreement) {
            this.credentialService.setCredentials();
            this.router.navigate(['/my-user']);
            return;
        }

        this.sessionService.selectAgreement(agreement).subscribe(() => {
            const stateResult = this.agreementService.agreementsParams.find((obj: any) => obj.agreement_id === agreement.id);

            if (stateResult) {
                this.agreementService.agreementsParams.splice(this.agreementService.agreementsParams.indexOf(stateResult), 1);
            }

            if (agreement.account?.type !== 'Reseller') {
                this.agreementService.setAgreements([]);
            } else {
                const index = this.sessionAgreements.indexOf(agreement);
                if (index === 0) {
                    this.agreementService.setAgreements([]);
                } else {
                    this.agreementService.setAgreements(this.agreementService.agreements.filter(agr => agr.id !== agreement.id));
                }
            }

            this.router.navigate(['../', agreement.account?.id], { relativeTo: this.activatedRoute });
        });
    }
}
