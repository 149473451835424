import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
    private loadingCount = 0;
    private isLoading = new BehaviorSubject<boolean>(false);

    public loading$ = this.isLoading.asObservable();

    public show(): void {
        this.loadingCount++;

        this.updateLoadingState();
    }

    public hide(): void {
        this.loadingCount--;

        this.updateLoadingState();
    }

    private updateLoadingState(): void {
        this.isLoading.next(this.loadingCount > 0);
    }
}
