import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export class LoadingState {
    private state = new BehaviorSubject<{ isLoading: boolean; message: string }>({ isLoading: false, message: '' });

    public isLoading$ = this.state.asObservable().pipe(map(state => state.isLoading));
    public isIdle$ = this.state.asObservable().pipe(map(state => !state.isLoading));
    public message$ = this.state.asObservable().pipe(map(state => state.message));

    constructor() {}

    public start(message = ''): void {
        this.state.next({ isLoading: true, message });
    }

    public stop(): void {
        this.state.next({ isLoading: false, message: '' });
    }
}
