<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../../billing']" class="mat-button-back" color="accent" i18n>&lt; Back to Invoices</button>
    </div>

    <mat-card class="card02">
        <mat-card-content class="flex-col rg16">
            <h4 class="margin b0" i18n>Invoice {{ invoice.attributes.number }}</h4>
            <div class="flex-row" *ngIf="invoice.attributes.paid_at">
                <span class="subtitle1" i18n>Paid at:</span>&nbsp;{{ invoice.attributes.paid_at | date: 'dd-MM-yyyy' }}
            </div>
            <div class="flex-row" *ngIf="!invoice.attributes.paid_at">
                <span class="subtitle1" i18n>Status: Not Paid</span>
            </div>

            <table mat-table mat-card-image [dataSource]="invoiceDataSource">
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef i18n>Description</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="subtitle1">{{ row.text }}</span
                        ><br />
                        {{ row.description }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef i18n>Quantity</th>
                    <td mat-cell *matCellDef="let row">{{ row.quantity }}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef i18n>Amount</th>
                    <td mat-cell *matCellDef="let row">{{ row.unit_price | qpCurrency: invoice.attributes.currency }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="invoiceDisplayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: invoiceDisplayColumns"></tr>
            </table>

            <div class="flex-row">
                <span class="flex-1">
                    <span i18n>Total:</span>
                    {{ invoice.attributes.amount | qpCurrency: invoice.attributes.currency }}
                    {{ invoice.attributes.currency }}
                </span>
            </div>

            <div class="flex-row cg8">
                <button mat-raised-button i18n-matTooltip matTooltip="Download as PDF" (click)="downloadInvoiceAsPDF()">
                    <mat-icon>file_download</mat-icon>
                    PDF
                </button>
                <button mat-raised-button i18n-matTooltip matTooltip="Download as CSV" (click)="downloadInvoiceAsCSV()">
                    <mat-icon>file_download</mat-icon>
                    CSV
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="card02" *ngIf="!merchantSpecification">
        <mat-card-content>
            <div class="flex-row">
                <span class="flex-1"></span>
                <qp-search-field-small
                    (searchInvoked)="onSearch($event)"
                    i18n-placeholder
                    placeholder="Search for merchant id..."
                    [(search)]="merchantID"
                >
                </qp-search-field-small>
            </div>

            <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>

            <table mat-table mat-card-image [dataSource]="dataSource">
                <ng-container matColumnDef="merchantID_shop_name">
                    <th mat-header-cell *matHeaderCellDef i18n>Merchant ID - Merchant shop name</th>
                    <td mat-cell *matCellDef="let merchant">
                        <span class="subtitle1">{{ merchant.attributes.merchant_id }}</span>
                        - {{ merchant.attributes.merchant_shop_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef i18n>Currency</th>
                    <td mat-cell *matCellDef="let merchant">
                        {{ merchant.attributes.currency }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_price">
                    <th mat-header-cell *matHeaderCellDef i18n>Total price ex. VAT</th>
                    <td mat-cell *matCellDef="let merchant">
                        {{ merchant.attributes.total_price | qpCurrency: merchant.attributes.currency }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
                    <td mat-cell *matCellDef="let merchant">
                        <button
                            mat-icon-button
                            [routerLink]="['../../specifications', merchant.id, merchant.attributes.merchant_id]"
                            [queryParams]="{ invoice_id: invoice.id }"
                            i18n-matTooltip
                            matTooltip="Show Details"
                        >
                            <mat-icon>info_outline</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="specificationsDisplayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: specificationsDisplayColumns"></tr>
            </table>

            <div class="flex-row justify-center items-center" *ngIf="!(pageNumber === 1 && dataSource.data.length < pageSize)">
                <button mat-icon-button (click)="previousPage()" [disabled]="pageNumber < 2">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                {{ pageNumber }}
                <button mat-icon-button (click)="nextPage()" [disabled]="dataSource.data.length < pageSize">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
