<h5 i18n mat-dialog-title>Select columns</h5>

<mat-dialog-content class="flex-col rg16 grey-border-bottom">
    <div>
        <div *ngFor="let key of dataKeys;">
            <mat-checkbox [disabled]="!data[key].allowChange" [(ngModel)]="data[key].active"> {{ data[key].name }} </mat-checkbox>
            <div class="flex-row" *ngIf="data[key].format && data[key].active">
                <br />
                <div class="margin l16">
                    <mat-radio-group class="flex-row cg8" [(ngModel)]="data[key].format">
                        <mat-radio-button *ngFor="let formatKey of dateFormatsKeys;" [value]="formatKey">
                            {{ dateFormats[formatKey].name }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <span></span>
</mat-dialog-content>

<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button mat-button color="accent" mat-dialog-close i18n>Close</button>
    <button mat-button color="accent" (click)="saveAndClose()" i18n>Save</button>
</mat-dialog-actions>
