<mat-paginator
    (page)="changePage($event)"
    [pageSize]="page_size"
    [pageIndex]="page - 1"
    [length]="paginator_length"
    [pageSizeOptions]="page_size_options"
    showFirstLastButtons
    class="removeLastButton"
>
</mat-paginator>
