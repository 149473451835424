import { Address } from '@interfaces/address';
import { Basket } from './basket';
import { Metadata } from './metadata';
import { Operation } from './operation';
import { PaymentLink } from './payment-link';
import { ClearhausTransactionSettlement, Transaction } from './transaction';

export class Payment implements Transaction {
    public type?: string | undefined;
    public basket: Basket[];
    public facilitator: string;
    public fee?: number | undefined;
    public invoice_address?: Address | undefined;
    public metadata: Metadata;
    public operations: Operation[];
    public retented_at?: Date | undefined;
    public shipping: any;
    public shipping_address?: Address | undefined;
    public test_mode: boolean;
    public updated_at: Date;
    public variables: any;
    public text_on_statement?: string | undefined;
    public threed_secure?: string | undefined;
    public amount?: number | undefined;
    public reference?: number | undefined;
    public processed_at: Date;
    public rrn?: string | undefined;
    public arn?: string | undefined;
    public settlement?: ClearhausTransactionSettlement | undefined;
    public id?: number | undefined;
    public ulid?: string | undefined;
    public accepted: boolean;
    public acquirer: AcquirerType;
    public balance: number;
    public branding_id?: number;
    public created_at: Date;
    public currency: string;
    public link: PaymentLink;
    public order_id: string;
    public state: string;
    public subscription_id?: number;
    public due_date?: string;
    public description?: string;
}

export type AcquirerType = 'clearhaus' | 'klarnapayments' | 'sofort' | 'klarna' | 'mobilepaysubscriptions';
