import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';

@Component({
    selector: 'qp-wildcard',
    styles: [':host { max-width: 700px }'],
    templateUrl: 'wildcard.component.html',
    styleUrls: ['wildcard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatCardModule, MatButtonModule, CommonModule]
})
export class WildcardComponent {
    constructor(private router: Router) {}

    public goBack(): void {
        this.router.navigate(['my-user']);
    }
}
