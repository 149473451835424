<button
    mat-icon-button
    *ngIf="!selected_transactions.selected.length"
    (click)="onExportAllTransactions()"
    i18n-aria-label
    aria-label="Export to csv"
    i18n-matTooltip
    matTooltip="Export to csv">
    <mat-icon>
        cloud_download
    </mat-icon>
</button>

<button
    mat-icon-button
    *ngIf="selected_transactions.selected.length"
    (click)="onExportSelectedTransactions()"
    i18n-aria-label
    aria-label="Export selected"
    i18n-matTooltip
    matTooltip="Export selected to csv">
    <mat-icon>
        cloud_download
    </mat-icon>
</button>
