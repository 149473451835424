import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { AgreementService } from '@services/agreement/agreement.service';

export const unzerGuard = (): boolean => {
    const router = inject(Router);
    const agreementService = inject(AgreementService);
    const state = router.routerState.snapshot;

    if (new QpMerchantTypePipe(agreementService).transform(['Unzer'])) {
        return true;
    } else {
        const url_fragments = state.url.split('/');
        url_fragments.pop();
        url_fragments.push('acquirers');
        router.navigate([`/${url_fragments.join('/')}`]);
        return false;
    }
};
