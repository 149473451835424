import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegExpAddressName } from '@helpers/reg-exp/reg-exp-adress-names';
import { Address } from '@interfaces/address';
import { AgreementService } from '@services/agreement/agreement.service';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';

@Component({
    selector: 'qp-address-form',
    templateUrl: './address-form.html'
})
export class AddressFormComponent implements OnInit {
    @Input() public address?: Address;
    @Input() public address_field: string; // [customer_address, billing_address]
    @Input() public readOnly?: boolean;
    @Output() public updated = new EventEmitter<any>();

    public address_form: UntypedFormGroup;
    public countries: Country[];
    public country_index: number;
    public formSaved: boolean;
    public hasPermission: boolean;

    constructor(
        private countryService: CountryService,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        this.hasPermission = !this.agreementService.hasPermission('/account', 'patch');
        this.setupCountrySelector();
        this.setUpForm();

        // TODO: Use router to check for unsaved changes

        // this.transitions.onBefore({}, () => {
        //     if (this.address_form.dirty) {
        //         return new Promise((resolve, reject) =>
        //             this.dialogService
        //                 .unsavedChangesConfirm()
        //                 .afterClosed()
        //                 .subscribe((result: boolean) => {
        //                     if (result) {
        //                         this.address_form.markAsPristine();
        //                         this.address_form.markAsUntouched();
        //                         return resolve();
        //                     } else {
        //                         return reject();
        //                     }
        //                 })
        //         );
        //     }
        // });
    }

    public setupCountrySelector(): void {
        this.countries = this.agreementService.isUnzerReseller() ?
            this.countryService.getUnzerCountries() :
            this.countryService.getCountries();

        if (this.address?.country_code) {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.address.country_code === this.countries[i].code) {
                    this.country_index = i;
                    break;
                }
            }
        }
    }

    public setUpForm(): void {
        this.address_form = new UntypedFormGroup({
            name: new UntypedFormControl({ value: this.address?.name, disabled: this.hasPermission }, [Validators.required]),
            street: new UntypedFormControl({ value: this.address?.street, disabled: this.hasPermission }, [
                Validators.pattern(new RegExpAddressName()),
                Validators.required
            ]),
            zip_code: new UntypedFormControl({ value: this.address?.zip_code, disabled: this.hasPermission }, [Validators.required]),
            city: new UntypedFormControl({ value: this.address?.city, disabled: this.hasPermission }, [
                Validators.pattern(new RegExpAddressName()),
                Validators.required
            ]),
            region: new UntypedFormControl({ value: this.address?.region, disabled: this.hasPermission }),
            country_code: new UntypedFormControl({ value: this.countries[this.country_index], disabled: this.hasPermission }, [
                Validators.required
            ]),
            vat_no: new UntypedFormControl({ value: this.address?.vat_no, disabled: this.hasPermission })
        });
    }

    public removeTrailingSpaces(): void {
        Object.keys(this.address_form.controls).forEach(key => {
            if (typeof this.getAddressFormControlByKey(key).value === 'string') {
                let value = this.getAddressFormControlByKey(key).value.replace(/^\s+|\s+$/g, '');

                if (key === 'vat_no') {
                    value = value.replaceAll(' ', '');
                }

                this.getAddressFormControlByKey(key).setValue(value);
            }
        });
    }

    public saveChanges(): void {
        this.removeTrailingSpaces();
        const data: { [key: string]: any } = {};
        const value = this.address_form.value as Address;
        value.country_code = this.getAddressFormControlByKey('country_code').value.code;
        data[this.address_field] = value;

        this.updated.emit(data);
        this.address_form.markAsPristine();
        this.address_form.markAsUntouched();
        this.formSaved = true;
    }

    public getAddressFormControlByKey(key: string): UntypedFormControl {
        return this.address_form.get(key) as UntypedFormControl;
    }
}
