import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { ClearhausTransaction, ClearhausTransactionResponse } from '@models/transaction';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerClient } from './server-client';

@Injectable({
    providedIn: 'root'
})
export class ClearhausTransactionRepo {

    constructor(
        private client: ServerClient
    ) {}

    public getCaptureTransaction(order_id: string): Observable<ClearhausTransaction | undefined> {
        return this.client.get<ClearhausTransactionResponse>('/clearhaus/account/transactions', { params: { query: `reference:${order_id} is:capture` } }).pipe(
            catchHttpStatusError([HttpStatusCode.Unauthorized, HttpStatusCode.NotFound, HttpStatusCode.BadRequest], () => ({
                transactions: [],
                links: {}
            })),
            map(response => response.transactions.find(transaction => transaction.type === 'capture'))
        );
    }
}
