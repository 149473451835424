import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router'; // CLI imports router
import { AccountLayoutComponent } from 'app/components/layout/account-layout.component';
import { SetPasswordComponent } from 'app/components/login/set-password/set-password.component';
import { ActivateShopifyComponent } from 'app/components/settings/thirdparty/integrations/shopify/activate-shopify.component';
import { ShopifyActivationComponent } from 'app/components/settings/thirdparty/integrations/shopify/shopify-activation.component';
import { ShopifySuccessComponent } from 'app/components/settings/thirdparty/integrations/shopify/shopify-success.component';
import { account_routes } from './account-routes';
import { loggedInGuard } from './guards/logged-in.guard';
import { login_routes } from './login-routes';
import { accountResolver } from './resolvers/account.resolver';
import { user_routes } from './user-routes';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadComponent: () => import('app/components/login/login-layout.component').then(c => c.LoginLayoutComponent),
        data: {
            anonymousAccess: true
        },
        children: login_routes
    },
    {
        path: 'reset-password/:token',
        component: SetPasswordComponent,
        data: {
            anonymousAccess: true
        }
    },
    {
        path: 'account/:account_id',
        component: AccountLayoutComponent,
        data: {
            anonymousAccess: false
        },
        resolve: {
            account: accountResolver
        },
        children: account_routes,
        canActivate: [loggedInGuard]
    },
    {
        path: 'my-user',
        loadComponent: () => import('app/components/myuser/user-layout.component').then(c => c.UserLayoutComponent),
        children: user_routes,
        canActivate: [loggedInGuard]
    },
    {
        path: 'activate_shopify',
        component: ActivateShopifyComponent,
        canActivate: [loggedInGuard]
    },
    {
        path: 'shopify_activation',
        component: ShopifyActivationComponent,
        data: { anonymousAccess: true }
    },
    {
        path: 'shopify-success',
        component: ShopifySuccessComponent,
        data: { anonymousAccess: true }
    },
    {
        path: 'onboarding/:account_id',
        loadComponent: () => import('app/components/login/onboarding/onboarding.component').then(c => c.OnboardingComponent),
        data: { anonymousAccess: true }
    }
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: NoPreloading })],
    exports: [RouterModule]
})
export class AppRoutingModule {}

