import { Pipe, PipeTransform } from '@angular/core';
import { NotArray } from '@models/generic-types';
import { NameValue } from 'app/components/settings/kms/kms-new/name-value.interface';

@Pipe({
    name: 'qpToArray'
})
export class QPToArrayPipe implements PipeTransform {

    constructor() {}

    public transform<T>(value: NotArray<T> | undefined): NameValue<any>[] {
        if (!value) {
            return [];
        }
        return Object.entries(value).map(entry => ({ name: entry[0], value: entry[1] }));
    }
}
