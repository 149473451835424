import { Component, Input, OnInit } from '@angular/core';
import { FraudRuleset } from '@interfaces/fraud-ruleset';
import { FraudRulesetRepo } from 'app/endpoints/api/fraud-ruleset-repo';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Metadata } from '@models/metadata';

@Component({
    selector: 'qp-transaction-fraud',
    templateUrl: './transaction-fraud.html'
})
export class TransactionFraudComponent implements OnInit {
    @Input() public metadata: Metadata;
    public objectKeys = Object.keys;
    public rulesetNotFound = false;
    public showMore = false;
    public rulesets: Array<any> = [];

    constructor(
        private fraudRulesetRepo: FraudRulesetRepo
    ) {}

    public ngOnInit(): void {
        let rulesetRes = [];

        if (this.metadata) {
            for (const fraudRemarks of this.metadata.fraud_remarks) {
                rulesetRes = fraudRemarks.match(/\(id: (.*)\)/) ?? [];
                this.fraudRulesetRepo.get(Number(rulesetRes[1])).pipe(
                    map((ruleset: FraudRuleset) => {
                        const rule = ruleset.filter_rules?.filter((newRule: any) => {
                            if (newRule.metric_name !== 'fraudfilter_score') {
                                return newRule;
                            }
                            return false;
                        });
                        if (rule?.length === ruleset.filter_rules?.length) {
                            this.rulesets.push(ruleset);
                        }
                    }),
                    catchError(() => {
                        this.rulesetNotFound = true;
                        return of();
                    })
                ).subscribe();
            }
        }
    }
}
