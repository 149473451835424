import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Account } from '@interfaces/account';
import { AgreementService } from '@services/agreement/agreement.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { UnsavedChanges } from 'app/routing/guards/unsaved-changes.interface';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
    selector: 'qp-branding-config',
    templateUrl: 'config.html',
    animations: [Animations.getFadeAnimation()]
})
export class BrandingConfigComponent extends BaseLoadingDirective implements OnInit, UnsavedChanges {
    public account: Account | undefined;
    public helpdeskLink: string;
    public showEnable3dCardField: boolean;
    public configForm: UntypedFormGroup;
    public formSaved = false;
    public formError: any;

    constructor(
        private accountRepo: AccountRepo,
        private dialogService: DialogService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.account = this.agreementService.agreement?.account;
        this.showEnable3dCardField = !!this.account?.default_branding_config?.enable_3d_card_field;
        this.helpdeskLink = this.agreementService.isDanishMerchant()
            ? 'https://quickpay.net/dk/helpdesk/payment-window/'
            : 'https://quickpay.net/helpdesk/payment-window/';

        this.setupForm();
    }

    public hasUnsavedChanges(): Observable<boolean> {
        if (this.configForm.dirty) {
            return this.dialogService
                .unsavedChangesConfirm()
                .afterClosed()
                .pipe(map((result) => !!result));
        }
        return of(true);
    }

    public setupForm(): void {
        this.configForm = new UntypedFormGroup({
            enable_card_holder_field: new UntypedFormControl({
                value: this.account?.default_branding_config.enable_card_holder_field,
                disabled: false
            }),
            enable_3d_card_field: new UntypedFormControl({
                value: this.account?.default_branding_config.enable_3d_card_field,
                disabled: false
            }),
            autojump: new UntypedFormControl({ value: this.account?.default_branding_config.autojump, disabled: false }),
            '3dsv2': new UntypedFormControl({
                value: this.account?.default_branding_config['3dsv2'],
                disabled: this.account?.default_branding_config['3dsv2']
            }),
            title: new UntypedFormControl({ value: this.account?.default_branding_config.title, disabled: false }),
            reservation_notice: new UntypedFormControl({ value: this.account?.default_branding_config.reservation_notice, disabled: false })
        });
    }

    public discardChanges(): void {
        this.formError = undefined;
        this.setupForm();
    }

    public changeToggle(toggle: string): void {
        if (this.getConfigFormControlByKey('title').pristine && this.getConfigFormControlByKey('reservation_notice').pristine) {
            this.configForm.markAsPristine();
            this.configForm.markAsUntouched();
        }
        if (!this.account?.default_branding_config) {
            return;
        }
        this.account.default_branding_config[toggle] = !this.account?.default_branding_config[toggle];
        this.save(true);
    }

    public save(toggle: boolean): void {
        const values = {
            enable_card_holder_field: this.account?.default_branding_config.enable_card_holder_field,
            enable_3d_card_field: this.account?.default_branding_config.enable_3d_card_field,
            autojump: this.account?.default_branding_config.autojump,
            '3dsv2': this.account?.default_branding_config['3dsv2'],
            title: this.getConfigFormControlByKey('title').value,
            reservation_notice: this.getConfigFormControlByKey('reservation_notice').value
        };

        this.accountRepo
            .update({ default_branding_config: values }, null, undefined)
            .pipe(
                tap((account: Account) => {
                    if (!this.agreementService.agreement) {
                        return;
                    }
                    this.agreementService.agreement.account = account;
                    this.account = account;
                    this.formSaved = !toggle;
                    if (toggle) {
                        this.snackBar.open($localize`Default branding updated`);
                    }
                    this.configForm.markAsPristine();
                    this.configForm.markAsUntouched();
                }),
                catchError(error => {
                    this.formError = error;
                    return of();
                })
            )
            .subscribe();
    }

    public getConfigFormControlByKey(key: string): UntypedFormControl {
        return this.configForm.get(key) as UntypedFormControl;
    }
}
