import { Injectable } from '@angular/core';
import { GithubApiClient } from './github-api-client';
import { GithubClient } from './github-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandingRelease } from '@interfaces/branding-release';

@Injectable({
    providedIn: 'root'
})
export class GithubRepo {
    constructor(
        private githubClient: GithubClient,
        private githubApiClient: GithubApiClient
    ) {}

    public get(tag: string): Observable<any> {
        return this.githubClient.get('/' + tag + '.zip', { responseType: 'blob' }).pipe(
            map((item: any) => item)
        );
    }

    public getLatest(): Observable<BrandingRelease> {
        return this.githubApiClient.get<BrandingRelease>('/repos/QuickPay/standard-branding/releases/latest');
    }

    public getList(): Observable<BrandingRelease[]> {
        return this.githubApiClient.get<BrandingRelease[]>('/repos/QuickPay/standard-branding/releases');
    }
}
