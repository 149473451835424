import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@pipes/pipes.module';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { LayoutModule } from '@widgets/layout/layout.module';
import { ShopsystemsModule } from '@widgets/shopsystems/shopsystems.module';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';
import { ApiModule } from 'app/endpoints/api/api.module';
import { WindowModule } from 'app/helpers/windowprovider/window.module';
import { NgxIbanModule } from 'ngx-iban';
import { AcquirerHelpdeskModule } from './acquirer-helpdesk/acquirer-helpdesk.module';
import { ClearhausAccountInfoDialogComponent } from './acquirer/clearhaus/account-info-dialog/clearhaus-account-info-dialog.component';
import { ClearhausSignupModule } from './acquirer/clearhaus/signup/signup.module';
import { UnzerPricingComponent } from './acquirer/unzer/unzer-pricing.component';
import { AcquirersComponent } from './acquirers.component';
import { MerchantAcquirerListComponent } from './list/merchant-list.component';
import { AdvanceTellerBasedComponent } from './show/advance/teller-based.component';
import { AcquirerFeeComponent } from './show/fee/fee.component';
import { PrioritizeComponent } from './show/prioritize/prioritize.component';
import { AcquirerShowComponent } from './show/show.component';

@NgModule({
    imports: [
        ApiModule,
        ClearhausSignupModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        NgxIbanModule,
        PipesModule,
        ReactiveFormsModule,
        ShopsystemsModule,
        SpinnerButtonModule,
        WindowModule,
        RouterModule,
        WindowModule,
        NgOptimizedImage,
        AcquirerHelpdeskModule
    ],
    exports: [
        AcquirersComponent,
        MerchantAcquirerListComponent
    ],
    declarations: [
        AcquirerFeeComponent,
        AcquirersComponent,
        AcquirerShowComponent,
        AdvanceTellerBasedComponent,
        ClearhausAccountInfoDialogComponent,
        MerchantAcquirerListComponent,
        PrioritizeComponent,
        UnzerPricingComponent,
        PrioritizeComponent,
        UnzerPricingComponent
    ]
})
export class AcquirersModule {}
