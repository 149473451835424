<div class="wide-content" [hidden]="loading" *ngIf="agreement">
    <div class="flex-row items-center no-print p-1">
        <button mat-button [routerLink]="['../../users']" class="mat-button-back" color="accent" i18n>&lt; Back to Users</button>
        <span class="flex-1"></span>
        <button mat-raised-button class="ml-1 hide-lt-sm" *ngIf="(['/activity', 'get'] | qpPermission)" [routerLink]="['../../../tools/auditlog']" [queryParams]="{target_type: 'User', target_id: agreement.id}" i18n>Audit log</button>
        <mat-menu #moreMenu="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item (click)="regenerateApiKey()" *ngIf="(['/agreements/:id/api-key', 'patch'] | qpPermission)" i18n>Regenerate API key</button>
            <button mat-menu-item (click)="removeUser()" *ngIf="is_removable" class="text-darkred">
                <span class="mat-button-wrapper" i18n>Remove user agreement</span>
            </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon color="primary">more_vert</mat-icon>
        </button>
    </div>

    <mat-card class="card02">
        <mat-card-content>
            <h5 *ngIf="!support">{{ user_name }}</h5>
            <h5 *ngIf="support" i18n>Support agreement</h5>
            <div class="flex-col rg32">
                <div class="flex-col rg16">
                    <div *ngIf="!support" class="flex-row">
                        <span class="text-grey justify-start items-center" i18n>Agreement id: {{ agreement.id }}</span>
                        <span *ngIf="!agreement.accepted" class="qp-label" i18n-matTooltip matTooltip="Invitation pending"  i18n> Pending </span>
                    </div>

                    <div>
                        <button mat-raised-button color="accent" *ngIf="!support || (support && current_agreement?.support)" (click)="copyApiKey()" i18n>See Api key</button>
                    </div>
                </div>

                <qp-user-ip [agreement]="agreement" *ngIf="is_system_user"></qp-user-ip>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="card02" *ngIf="!support">
        <qp-user-permissions class="flex-col rg24" [(agreement)]="agreement" [support]="support"> </qp-user-permissions>
    </mat-card>
</div>
