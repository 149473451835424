import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { Website } from '@models/clearhaus-application/ch-instant';

@Component({
    selector: 'qp-clearhaus-website-form',
    templateUrl: './websites.html'
})
export class ClearhausSignupFormWebsiteStepComponent implements AfterViewInit {
    @Input() public websites: Website[] = [];
    @Output() public validityChange = new EventEmitter();

    public websitesForm = new UntypedFormGroup({
        websites: new UntypedFormControl('', Validators.required)
    });

    constructor(
        private dialogService: DialogService
    ) {}

    public get websitesControl(): AbstractControl {
        return this.websitesForm.get('websites') as UntypedFormControl;
    }

    public ngAfterViewInit(): void {
        this.websitesForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(this.websitesForm.valid);
        });

        this.websitesControl.setValue(this.websites);
        this.validityChange.emit(this.websitesForm.valid);
    }

    public addSiteDialog(): void {
        const fields: PromptField[] = [
            {
                label: $localize`Url`,
                required: true,
                type: 'text',
                name: 'url'
            },
            {
                label: $localize`Comment`,
                required: false,
                type: 'textarea',
                name: 'comment',
                rows: 5
            }
        ];

        this.dialogService
            .prompt(
                $localize`Add site`,
                '',
                $localize`Add site`,
                fields
            )
            .afterClosed()
            .subscribe((returnFields: PromptField[]) => {
                if (returnFields) {
                    const arrUrls: string[] =
                        this.websites.map(website => website.url ?? '') ?? [];
                    const urlSuffix: string = returnFields[0].value
                        .split('.')
                        .pop();
                    const urlDomains: string[] = returnFields[0].value
                        .split('//')
                        .pop()
                        .split('.')
                        .slice(0, -1);

                    if (arrUrls.length > 0) {
                        arrUrls.forEach(url => {
                            const domain = url
                                .split('//')
                                .pop()
                                ?.split('.')
                                .slice(0, -1)
                                .pop();

                            if (domain === urlDomains.pop()) {
                                this.dialogService.alert(
                                    $localize`Domain already exists`,
                                    $localize`${domain + '.' + urlSuffix.split('/')[0]} is already registered`
                                );
                                return;
                            }
                        });
                    }

                    this.websites.push({
                        url: returnFields[0].value.replace(/\s/g, ''),
                        comment: returnFields[1].value
                    });

                    this.websitesControl.setValue(this.websites);
                }
            });
    }

    public addMissingComment(i: number): void {
        const fields: PromptField[] = [
            {
                label: $localize`Comment`,
                required: false,
                type: 'textarea',
                name: 'comment',
                rows: 5
            }
        ];

        this.dialogService
            .prompt(
                $localize`Add comment`,
                '',
                $localize`Add comment`,
                fields
            )
            .afterClosed()
            .subscribe((returnFields: PromptField[]) => {
                if (returnFields) {
                    this.websites[i].comment = returnFields[0].value;
                    this.websitesControl.setValue(this.websites);
                }
            });
    }

    public removeSite(i: number): void {
        this.websites.splice(i, 1);
        this.websitesControl.setValue(this.websites);
    }
}
