<mat-card class="flex-col rg36 card02">
  <div class="flex-col rg8">
    <qp-section-heading headingText="Invoice requisition number" icon="folder" i18n-headingText></qp-section-heading>
    <mat-card-subtitle class="text-grey" i18n>
      Add a requisition number to your invoices. This will be displayed on the invoice PDF.
    </mat-card-subtitle>
    <mat-card-content class="flex-col rg16">
      <div *ngIf="customer?.attributes?.requisition_number" class="flex-row cg8 justify-start stretch">
        <button mat-button class="mat-icon-button" (click)="removeRequisitionNumber()" i18n-matTooltip matTooltip="Remove requisition number">
          <mat-icon class="text-black">delete</mat-icon>
        </button>
        <span class="justify-start items-center">{{ customer?.attributes?.requisition_number }}</span>
      </div>
      <div *ngIf="!customer?.attributes?.requisition_number">
        <button mat-raised-button (click)="addRequisitionNumber()" i18n>
          Add requisition number
        </button>
      </div>
    </mat-card-content>
  </div>
</mat-card>
