<div class="bottom-border" [ngClass]="{ 'merchant-info': true, 'account-layout-merchant_info': !(['Quickpay'] | qpMerchantType) }">
    <div *ngFor="let agreement of session_agreements; let index = index">
        <div class="flex-row">
            <div class="flex-1 flex-col">
                <p class="truncate truncate-130 margin b0">{{ getAccountName(agreement) }}</p>
                <p class="text-grey margin b0 fs14">ID: {{ agreement.account?.id }} </p>
            </div>
            <div *ngIf="index !== 0" class="justify-center items-center">
                <button mat-icon-button matTooltip="Leave {{ agreement.account?.type }}" (click)="signOutTo(session_agreements[index - 1])" i18n-matTooltip>
                    <mat-icon class="mat-18" color="accent">exit_to_app</mat-icon>
                </button>
            </div>
        </div>
        <mat-icon class="mat-18">keyboard_arrow_up</mat-icon>
    </div>

    <div class="flex-row" *ngIf="agreement?.account?.type !== 'Merchant'">
        <div class="flex-col flex-1">
            <p class="truncate truncate-130 margin b0">{{ getAccountName(agreement) }}</p>
            <p class="text-grey margin b0 fs14">ID: {{ agreement?.account?.id }}</p>
        </div>
        <div class="justify-center items-center margin r16">
            <button mat-icon-button i18n-matTooltip matTooltip="Leave reseller" [routerLink]="['/my-user']" *ngIf="!session_agreements.length">
                <mat-icon class="mat-18" color="accent">exit_to_app</mat-icon>
            </button>
            <button mat-icon-button i18n-matTooltip matTooltip="Leave reseller" (click)="signOutTo(session_agreements[session_agreements.length - 1])" *ngIf="session_agreements.length">
                <mat-icon class="mat-18" color="accent">exit_to_app</mat-icon>
            </button>
        </div>
    </div>

    <div class="flex-row" *ngIf="agreement?.account?.type === 'Merchant'">
        <div class="flex-1 flex-col">
            <p *ngIf="!logo" class="truncate truncate-130 margin b0" matTooltip="{{ agreement?.account?.shop_name }}">{{ agreement?.account?.shop_name }}</p>
            <img [src]="logo" *ngIf="logo" class="size-90 merchant-logo" matTooltip="{{ agreement?.account?.shop_name }}" />
            <p class="text-grey margin b0 fs14">ID: {{ agreement?.account?.id }} </p>
        </div>
        <div class="justify-center items-center margin r16">
            <button mat-icon-button i18n-matTooltip matTooltip="Leave merchant" (click)="signOutTo(reseller_agreement)" *ngIf="!session_agreements.length">
                <mat-icon color="accent">exit_to_app</mat-icon>
            </button>
            <button mat-icon-button i18n-matTooltip matTooltip="Leave merchant" (click)="signOutTo(session_agreements[session_agreements.length - 1])" *ngIf="session_agreements.length">
                <mat-icon color="accent">exit_to_app</mat-icon>
            </button>
        </div>
    </div>
</div>
