import { Routes } from '@angular/router';
import { unsavedChangesGuard } from './guards/unsaved-changes.guard';

export const user_routes: Routes = [
    {
        path: '',
        loadComponent: () => import('app/components/myuser/myuser.component').then(c => c.MyuserComponent),
        children: [
            {
                path: '',
                loadComponent: () => import('app/components/myuser/edit/edit.component').then(c => c.EditComponent),
                outlet: 'edit',
                canDeactivate: [unsavedChangesGuard]
            },
            {
                path: '',
                loadComponent: () => import('app/components/myuser/agreements/agreements.component').then(c => c.MyuserAgreementsComponent),
                outlet: 'agreements'
            }
        ]
    },
    {
        path: 'create-reseller',
        loadComponent: () => import('app/components/myuser/agreements/create/reseller/reseller.component').then(c => c.CreateResellerComponent)
    },
    {
        path: 'create-merchant',
        loadComponent: () => import('app/components/myuser/agreements/create/merchant/merchant.component').then(c => c.CreateMerchantComponent)
    },
    {
        path: 'create',
        loadComponent: () => import('app/components/myuser/agreements/create/choose-account-type.component').then(c => c.ChooseAccountTypeComponent)
    }
];
