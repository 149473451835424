<p *ngIf="!metadata?.fraud_suspected && !metadata?.is_3d_secure">
    <span class="subtitle1" i18n>No fraud remarks</span>
    <br />
    <span i18n>You should always verify payments that deviate from your norm.</span>
</p>

<p *ngIf="!metadata?.fraud_suspected && metadata?.is_3d_secure">
    <span class="subtitle1" i18n>No fraud remarks</span>
    <mat-icon class="s18 text-green">lock</mat-icon>
    <br />
    <span i18n>This payment has been through 3-D Secure.</span>
</p>

<p *ngIf="metadata?.fraud_suspected && !metadata?.is_3d_secure && !rulesets.length">
    <span class="subtitle1" i18n>Fraud remarks</span>
    <mat-icon class="s18 text-yellow">verified_user</mat-icon>
    <br />
    <span i18n>This payment has been flagged by our automatic fraud detection.</span>
    <span class="subtitle1" class="cursor no-print" (click)="showMore = !showMore">
        <span [hidden]="showMore" i18n>Read more</span>
        <span [hidden]="!showMore" i18n>Read less</span>
    </span>
</p>

<div *ngIf="metadata?.fraud_suspected && !metadata?.is_3d_secure && showMore">
    <span i18n>You should verify this payment by checking the order information or by contacting the customer.</span>
    <br />
    <span i18n>Please note that all payments above 300 EUR are flagged.</span>
</div>

<div *ngIf="rulesets.length && !metadata?.is_3d_secure">
    <br />
    <span class="subtitle1" i18n>Fraud remarks</span>
    <mat-icon class="s18 text-yellow">verified_user</mat-icon>
    <div>
        <span i18n>This payment has been flagged by the Fraud Filter - by the following rule(s):</span>
        <ng-container *ngFor="let ruleset of rulesets">
            <span>{{ ruleset.name }}</span>
        </ng-container>
    </div>
</div>

<p *ngIf="metadata?.fraud_suspected && metadata?.is_3d_secure">
    <span class="subtitle1" i18n>Fraud remarks</span>
    <mat-icon class="s18 text-green">lock</mat-icon>
    <br />
    <span i18n>This payment has been completed using 3-D Secure</span>
    <br />
    <span *ngIf="rulesetNotFound" i18n>This payment has been flagged by a ruleset that has since been deleted</span>
</p>

<p *ngIf="rulesetNotFound && !metadata?.is_3d_secure">
    <span class="subtitle1" i18n>Fraud remarks</span>
    <mat-icon class="s18 text-green">lock</mat-icon>
    <span i18n>This payment has been flagged by the Fraud Filter - by a ruleset that has since been deleted.</span>
</p>
