import { Injectable } from '@angular/core';
import { Address } from '@interfaces/address';
import { Operation } from '@models/operation';
import { Payment } from '@models/payment';
import { Transaction } from '@models/transaction';

@Injectable({
    providedIn: 'root'
})
export class TransactionService {
    public isCapturable(transaction: Payment): boolean {
        if (this.isRefunded(transaction) || !!transaction.retented_at) {
            return false;
        }
        return transaction.accepted &&
            !this.isCancelled(transaction) &&
            !this.isPending(transaction) &&
            transaction.balance < this.getAuthAmount(transaction) - this.getRefunded(transaction);
    }

    public isRefundable(transaction: Payment): boolean {
        if (!!transaction.retented_at || transaction.acquirer === 'sofort') {
            return false;
        }
        return transaction.balance > 0 && !this.isPending(transaction);
    }

    public isCancelable(transaction: Payment): boolean {
        if (!transaction.accepted || this.isCancelled(transaction) || this.isPending(transaction) || transaction.state !== 'new') {
            return false;
        }
        return true;
    }

    public isCustomerInfoDeletable(transaction: Payment): boolean {
        const checkValuesExists = (address: Address): boolean => {
            const values = Object.values(address);
            if (!values.length) {
                return false;
            } else {
                return values.some(value => !!value);
            }
        };

        if (transaction.shipping_address && transaction.invoice_address) {
            return checkValuesExists(transaction.shipping_address) || checkValuesExists(transaction.invoice_address);
        } else if (transaction.shipping_address && !transaction.invoice_address) {
            return checkValuesExists(transaction.shipping_address);
        } else if (transaction.invoice_address && !transaction.shipping_address) {
            return checkValuesExists(transaction.invoice_address);
        }

        return false;
    }

    public isRefunded(transaction: Payment): boolean {
        if (transaction.operations) {
            for (const operation of transaction.operations) {
                if (operation.type === 'refund' && operation.qp_status_code === '20000') {
                    return true;
                }
            }
        }
        return false;
    }

    public isCancelled(transaction: Transaction): boolean {
        let operation: Operation;

        for (let i = transaction.operations.length; i > 0; i--) {
            operation = transaction.operations[i - 1];
            if (operation.type === 'authorize' && operation.qp_status_code === '20000') {
                return false;
            }
            if (operation.type === 'cancel' && operation.qp_status_code === '20000') {
                return true;
            }
        }
        return false;
    }

    public isPending(transaction: Transaction): boolean {
        return this.lastOperation(transaction)?.pending || false;
    }

    public lastOperation(transaction: Transaction): Operation | null {
        if (transaction.operations) {
            return transaction.operations.length > 0 ? transaction.operations[transaction.operations.length - 1] : null;
        }
        return null;
    }

    public getAuthAmount(transaction: Transaction): number {
        const operations = transaction.operations.filter(operation => (operation.type === 'authorize' || operation.type === 'recurring'));

        if (!operations.length) {
            return 0;
        }

        const approved_operation = operations.find(operation => operation.operationStatus?.status === 'Approved');

        if (approved_operation) {
            return approved_operation.amount ?? 0;
        }

        const latest_operation = operations.reduce((op_a, op_b) => op_a.created_at > op_b.created_at ? op_a : op_b);

        return latest_operation ? latest_operation.amount : 0;
    }

    public getRefunded(transaction: Payment): number {
        let refunded = 0;
        if (transaction.operations) {
            for (const operation of transaction.operations) {
                if (operation.type === 'refund' && operation.qp_status_code === '20000' && operation.amount > refunded) {
                    refunded += operation.amount;
                }
            }
        }
        return refunded;
    }

    public isAuth(transaction: Transaction): boolean {
        let isAuth = false;

        for (const operation of transaction.operations) {
            if (operation.type === 'authorize' && operation.qp_status_code === '20000') {
                isAuth = true;
            }
        }
        return isAuth;
    }

    public maxCapture(transaction: Payment): number {
        return this.getAuthAmount(transaction) - this.getRefunded(transaction) - transaction.balance;
    }

    public latestPendingAmount(transaction?: Transaction): number {
        const operation = transaction?.operations.find(operation => operation.pending && ['authorize', 'credit', 'recurring'].includes(operation.type));

        return operation ? operation.amount : 0;
    }

    public latestFailedAmount(transaction?: Transaction): number {
        const operation = transaction?.operations.find(operation =>
            ['authorize', 'credit', 'recurring', 'refund'].includes(operation.type) &&
            ['30100', '40000', '40001', '40002', '40003', '50000', '50300'].includes(operation.qp_status_code)
        );

        return operation ? operation.amount : 0;
    }
}
