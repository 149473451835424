import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Acquirers } from '@models/acquirers/acquirers';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { PaymentMethodPriorityRepo } from 'app/endpoints/api/payment-method-priority-repo';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { PaymentMethodPriority } from '@interfaces/payment-method-priority';
import { ACQUIRERDATA } from '@models/acquirers/acquirerdata.constant';
import { environment } from '@environments/environment';

@Component({
    selector: 'qp-prioritize-acquirer',
    templateUrl: 'prioritize.html'
})
export class PrioritizeComponent implements OnInit {
    public active_card_acquirers: string[];
    public selected: string[];
    public form: UntypedFormControl;

    constructor(
        private acquirerService: AcquirerService,
        private paymentMethodPriorityRepo: PaymentMethodPriorityRepo,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        this.getActiveCardAcquirers();
        this.getCurrentPrioritySettings();
    }

    public getActiveCardAcquirers(): void {
        if (this.agreementService.agreement) {
            this.active_card_acquirers = [];

            Object.keys(this.acquirerService.acquirers).forEach(key => {
                const acquirer = this.acquirerService.acquirers[key as keyof Acquirers];

                if (acquirer !== null && environment.acquirersList.indexOf(key) !== -1 && !!ACQUIRERDATA[key]) {
                    if (acquirer.active && ACQUIRERDATA[key].card_acquirer && key !== 'nets') {
                        this.active_card_acquirers.push(key);
                    }
                };
            });
        }
    }

    public getCurrentPrioritySettings(): void {
        this.selected = [];
        this.paymentMethodPriorityRepo.get().subscribe(data => {
            data.payment_method_priority?.visa?.forEach((acquirer: any) => {
                if (this.active_card_acquirers.includes(acquirer)) {
                    this.selected.push(acquirer);
                }
            });
            if (this.selected.length === 0) {
                this.selected.push('none');
            }
            this.form = new UntypedFormControl(this.selected[0]);
        });
    }

    public save(value: string): void {
        let data = { payment_method_priority: {} };

        if (value !== 'none') {
            const priorityList: Array<string> = [value];

            this.active_card_acquirers.forEach(acquirer => {
                if (acquirer !== value && acquirer !== 'none') {
                    priorityList.push(acquirer);
                }
            });

            data = {
                payment_method_priority: {
                    visa: priorityList,
                    'visa-electron': priorityList,
                    maestro: priorityList,
                    mastercard: priorityList,
                    'mastercard-debet': priorityList
                }
            } as PaymentMethodPriority;
        }

        this.paymentMethodPriorityRepo.update(data).subscribe(() => {
            this.snackBar.open($localize`Priority settings saved`);
        });
    }
}
