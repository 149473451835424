import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@interfaces/address';

@Component({
    selector: 'qp-customer-info-address',
    templateUrl: './customer-info-address.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerInfoAddressComponent {
    @Input() public address: Address | undefined;
    public objectKeys = Object.keys;

    constructor() {}

    public valuesNotEmpty(): boolean {
        if (!this.address) {
            return false;
        }
        return Object.values(this.address).some(value => !!value);
    }
}
