import { Transaction } from './transaction';

export class DisputeDetail {
    public count: number;
    public links?: {
        first?: string;
        last?: string;
        next?: string;
        previous?: string;
    };
    public disputes: Array<Dispute>;
}

export class Dispute {
    public id: string;
    public currency: string;
    public account: Account;
    public amount: number;
    public due_at: Date;
    public events: Array<Event>;
    public opened_at: Date;
    public partial: boolean;
    public reason: string;
    public reasone_code: string;
    public reference: string;
    public status: string;
    public transaction: Transaction;
    public type: string;
    public outcome: string;
}

export class DocumentationFile {
    public id?: string;
    public label?: string;
    public content_type?: string;
    public name?: string;
    public file?: File;
}

export class Reason {
    public title: string;
    public explanation: string;
    public overturn: string;
}

export class Comment {
    public id: string;
    public body: string;
    public created_at: Date;
    public tags: string[];
    public author: {
        name: string;
        email: string;
    };
    public comments?: Comment[];
}

export class Event {
    public date: Date;
    public event: string;
    public reason: string;
    public reson_code: string;
}

class Account {
    public name: string;
    public merchant_id: number;
    public mcc: number;
    public descriptor: string;
    public currency: string;
    public country: string;
}

