export const MERCHANT_SUPPORT = [
    {
        resource: '/account',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acl-resources',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/activity',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/api-key',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id/*resource_name',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id/copy',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/changelog',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id/certificate',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id/csr',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-subscriptions',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-subscriptions/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-topics',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/merchants/:id/agreements',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants/:id/private-key',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants/:id/suspend',
        get: false,
        post: true,
        put: false,
        delete: true,
        patch: false
    },
    {
        resource: '/operational-status/acquirers',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/ping',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    }
];
