import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Account } from '@interfaces/account';
import { Company } from '@models/clearhaus-application/company';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';

@Component({
    selector: 'qp-clearhaus-signup-step-two',
    templateUrl: './company.html'
})
export class ClearhausSignupFormStepTwoComponent implements AfterViewInit {
    @ViewChild('vat') public vatInput: ElementRef;
    @Input() public account: Account;
    @Input() public supportedCountries: Country[];
    @Output() public companyChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _company: Company = new Company();
    public companyForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        form: new UntypedFormControl('', Validators.required),
        address: new UntypedFormControl('', Validators.required),
        zipcode: new UntypedFormControl('', Validators.required),
        city: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl('', Validators.required),
        vat: new UntypedFormControl('', Validators.required),
        phone: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email]))
    });

    public get address(): UntypedFormControl {
        return this.companyForm.get('address') as UntypedFormControl;
    }
    public get city(): UntypedFormControl {
        return this.companyForm.get('city') as UntypedFormControl;
    }
    public get country(): AbstractControl {
        return this.companyForm.get('country') as UntypedFormControl;
    }
    public get email(): AbstractControl {
        return this.companyForm.get('email') as UntypedFormControl;
    }
    public get form(): UntypedFormControl {
        return this.companyForm.get('form') as UntypedFormControl;
    }
    public get name(): UntypedFormControl {
        return this.companyForm.get('name') as UntypedFormControl;
    }
    public get phone(): UntypedFormControl {
        return this.companyForm.get('phone') as UntypedFormControl;
    }
    public get vatControl(): UntypedFormControl {
        return this.companyForm.get('vat') as UntypedFormControl;
    }
    public get zipcode(): UntypedFormControl {
        return this.companyForm.get('zipcode') as UntypedFormControl;
    }

    @Input() public get company(): Company {
        return this._company;
    }

    public set company(c: Company) {
        this._company = c;
        this.companyChange.emit(c);
    }

    constructor(private countryService: CountryService) {}

    public ngAfterViewInit(): void {
        this.companyForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(!this.companyForm.invalid);
        });

        this.address.setValue(this.company?.address_line_1);
        this.city.setValue(this.company?.city);
        this.country.setValue(this.company?.country?.length === 2 ? this.convertToAlpha3(this.company?.country) : this.company?.country);
        this.setFormValidators();
        this.email.setValue(this.company.email);
        this.form.setValue(this.company.form);
        this.name.setValue(this.company.name);
        this.phone.setValue(this.company.phone);
        this.vatControl.setValue(this.company.registration_number);
        this.zipcode.setValue(this.company.zipcode);
        this.validityChange.emit(!this.companyForm.invalid);
    }

    public convertToAlpha3(value: string): string {
        return this.countryService.alpha2ToAlpha3(value) ?? '';
    }

    public setFormValidators(): void {
        if (this.company?.country === 'DNK') {
            this.form.setValidators(Validators.required);
        } else {
            this.form.clearValidators();
            this.form.updateValueAndValidity();
        }
    }

    public onCompanyFormChange(value: boolean): void {
        this.company = Object.assign(this.company, { form: value });
    }

    public modelCompany(propName: keyof Company, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        const company: Partial<Company> = {
            [propName]: input.value
        };
        if (company.hasOwnProperty('registration_number')) {
            company.registration_number = company.registration_number?.split('.').join('');
            this.vatInput.nativeElement.value = company.registration_number;
            this.company = Object.assign(this.company, company);
        } else {
            this.company = Object.assign(this.company, company);
        }
    }
}
