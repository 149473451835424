<table
    mat-table
    mat-card-image
    #table
    [dataSource]="data_source"
    matSort
    matSortDisableClear
    [matSortActive]="search_params.sort_by ?? ''"
    [matSortDirection]="search_params.sort_dir ?? ''"
    (matSortChange)="changeSortParams()"
    >

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                (change)="masterToggle()"
                [checked]="selection.hasValue() && is_all_selected"
                [indeterminate]="selection.hasValue() && !is_all_selected">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selectRow(row)"
                [checked]="isRowSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-right">ID</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">{{ row.id }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="order_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Order ID</th>
        <td mat-cell *matCellDef="let row">{{ row.order_id }}</td>
    </ng-container>

    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Created</th>
        <td mat-cell *matCellDef="let row">
            <qp-date-field [format]="created_at_format" [date]="row.created_at"></qp-date-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Currency </th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">{{ row?.currency }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Amount</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center" [ngSwitch]="row.state">
                <ng-container *ngSwitchCase="'pending'">
                    {{ latestTransactionPendingAmount(row) | qpCurrency: row.currency }}
                </ng-container>
                <ng-container *ngSwitchCase="'rejected'">
                    {{ latestTransactionFailedAmount(row) | qpCurrency: row.currency }}
                </ng-container>
                <ng-container *ngSwitchCase="'initial'">
                    {{ transactionLinkAmount(row) | qpCurrency: row.currency }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{ getTransactionAuthAmount(row) | qpCurrency: row.currency }}
                </ng-container>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef [class.hide]="hide_balance" class="align-right" i18n>Balance</th>
        <td mat-cell *matCellDef="let row" [class.hide]="hide_balance">
            <div class="flex-row justify-end items-center">{{ row.balance | qpCurrency: row.currency }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="fee">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Fee </th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">{{ row.fee | qpCurrency: row.currency }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef i18n>Method </th>
        <td mat-cell *matCellDef="let row">{{ row.metadata.brand || row.acquirer | qpBrand }}</td>
    </ng-container>

    <ng-container matColumnDef="acquirer">
        <th mat-header-cell *matHeaderCellDef i18n>Acquirer</th>
        <td mat-cell *matCellDef="let row">{{ row.acquirer | qpAcquirer }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef i18n>Description</th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef [class.hide]="hide_state" i18n>State</th>
        <td mat-cell *matCellDef="let row" [class.hide]="hide_state">{{ row.state }}</td>
    </ng-container>

    <ng-container matColumnDef="invoice_name">
        <th mat-header-cell *matHeaderCellDef i18n>Invoice name</th>
        <td mat-cell *matCellDef="let row">{{ row.invoice_address?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="shipping_name">
        <th mat-header-cell *matHeaderCellDef i18n>Shipping name</th>
        <td mat-cell *matCellDef="let row">{{ row.shipping_address?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="flags">
        <th mat-header-cell *matHeaderCellDef i18n>Flags</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row">
                <mat-icon *ngIf="row.facilitator" class="text-light-blue s18"
                    matTooltip="{{ row.facilitator | qpAcquirer }}">
                    smartphone
                </mat-icon>

                <mat-icon *ngIf="row.acquirer === 'vmpepayment'" class="text-light-blue s18"
                    matTooltip="{{ row.acquirer | qpAcquirer }}">
                    smartphone
                </mat-icon>

                <mat-icon *ngIf="row.test_mode" class="text-yellow s18"
                    i18n-matTooltip
                    matTooltip="Payment is made with a testcard">
                    warning
                </mat-icon>

                <mat-icon *ngIf="row.metadata.fraud_suspected  && !row.metadata.is_3d_secure" class="text-yellow s18"
                    i18n-matTooltip
                    matTooltip="Possible fraudulent transaction. See payment details for more information.">
                    verified_user
                </mat-icon>
                <mat-icon *ngIf="row.metadata.fraud_reported" class="text-darkred s18"
                    i18n-matTooltip
                    matTooltip="Fraud report created. See payment details for more information.">
                    verified_user
                </mat-icon>
                <mat-icon *ngIf="row.metadata.is_3d_secure" class="text-green s18"
                    i18n-matTooltip
                    matTooltip="This payment has been through 3-D Secure">
                    lock
                </mat-icon>
                <mat-icon *ngIf="row.metadata.moto" class="text-light-blue s18"
                    i18n-matTooltip
                    matTooltip="Moto transaction">
                    mail
                </mat-icon>
                <mat-icon *ngIf="cardExpired(row.metadata.exp_year, row.metadata.exp_month) && table_type === 'subscriptions'"
                    i18n-matTooltip
                    class="text-darkred s18" matTooltip="Card is expired">
                    event_busy
                </mat-icon>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="last_operation">
        <th mat-header-cell *matHeaderCellDef i18n>Last operation</th>
        <td mat-cell *matCellDef="let row"
            matTooltip="{{ transactionLastOperation(row)?.created_at | date: 'MMM dd, yyyy - HH:mm:ss' }}">
            {{ transactionLastOperation(row)?.type }}
        </td>
    </ng-container>

    <ng-container matColumnDef="text_on_statement">
        <th mat-header-cell *matHeaderCellDef i18n>Text on statement</th>
        <td mat-cell *matCellDef="let row">{{ row.text_on_statement }}</td>
    </ng-container>

    <ng-container matColumnDef="date_last_operation">
        <th mat-header-cell *matHeaderCellDef i18n>Date of last operation</th>
        <td mat-cell *matCellDef="let row">
            <qp-date-field [format]="created_at_format" [date]="transactionLastOperation(row)?.created_at ?? ''">
            </qp-date-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef i18n>Action</th>
        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
            <qp-transaction-table-action
                [transaction]="row"
                [transaction_type]="table_type"
                [search_params]="search_params"
                (transactionChange)="transactionChange($event)">
            </qp-transaction-table-action>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display_columns"></tr>
    <tr mat-row
        class="cursor"
        [ngClass]="{
            'row-success': result_row.id === row.id && result_row.success === true,
            'row-failed': result_row.id === row.id && result_row.success === false
        }"
        *matRowDef="let row; columns: display_columns;"
        (click)="goToDetails(row)">
    </tr>
</table>

<ng-template #payment_details>
    <div class="flex-row justify-end">
        <button mat-button [routerLink]="['../payment', clicked_payment.id]" class="mat-button-back" color="accent" i18n>Go to payment</button>
    </div>
    <qp-payment-detail [show_toolbar]="false" [payment]="clicked_payment"></qp-payment-detail>
</ng-template>
