import { Account } from '@interfaces/account';
import { ApiClient } from './api-client';
import { Branding, BrandingResource } from '@models/branding';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrandingRepo {
    constructor(private apiClient: ApiClient) {}

    public getAll(params = {}): Observable<Branding[]> {
        return this.apiClient.get<Branding[]>('/brandings', { params }).pipe(
            map((items: Branding[]) => items.map((item: any) => Object.assign(new Branding(), item)))
        );
    }

    public destroy(id: number): Observable<Account> {
        return this.apiClient.delete<Account>('/brandings/' + id);
    }

    public deleteFile(id: number, resourceName: string): Observable<Branding> {
        return this.apiClient.delete<Branding>('/brandings/' + id + '/' + resourceName);
    }

    public get(id: number): Observable<Branding> {
        return this.apiClient.get<Branding>('/brandings/' + id).pipe(
            map((item: any) => Object.assign(new Branding(), item))
        );
    }

    public copy(id: number): Observable<Branding> {
        return this.apiClient.post<Branding>('/brandings/' + id + '/copy', {}).pipe(
            map((item: any) => Object.assign(new Branding(), item))
        );
    }

    public create(branding: Branding): Observable<Branding> {
        return this.apiClient.post<Branding>('/brandings', branding).pipe(
            map((item: any) => Object.assign(new Branding(), item))
        );
    }

    public putResource(id: number, resourceName: string, file: File | Blob): Observable<BrandingResource> {
        const fd: FormData = new FormData();
        fd.append('file', file);
        return this.apiClient.put<BrandingResource>('/brandings/' + id + '/' + resourceName, fd, {});
    }

    public update(branding: Branding): Observable<Branding> {
        return this.apiClient.patch<Branding>('/brandings/' + branding.id, branding).pipe(
            map((item: any) => Object.assign(new Branding(), item))
        );
    }

    public getResource(id: number, resourceName: string, responseType?: string): Observable<ArrayBuffer> {
        let config: any = {};
        if (responseType) {
            config = { responseType };
        } else {
            config.transformResponse = undefined;
        }
        return this.apiClient.get('/brandings/' + id + '/' + resourceName, config);
    }
}
