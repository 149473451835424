import { Routes } from '@angular/router';

export const login_routes: Routes = [
    {
        path: '',
        loadComponent: () => import('app/components/login/login/login.component').then(c => c.LoginComponent),
        data: {
            anonymousAccess: true
        }
    },
    {
        path: 'create-user',
        loadComponent: () => import('app/components/login/create-user/create-user.component').then(c => c.CreateUserComponent),
        data: {
            anonymousAccess: true
        }
    },
    {
        path: 'confirm-email',
        loadComponent: () => import('app/components/login/confirm-mail/confirm-mail.component').then(c => c.ConfirmMailComponent),
        data: {
            anonymousAccess: true
        }
    },
    {
        path: 'activate/:token',
        loadComponent: () => import('app/components/login/set-password/set-password.component').then(c => c.SetPasswordComponent),
        data: {
            anonymousAccess: true
        }
    },
    {
        path: 'connect',
        loadComponent: () => import('app/components/login/connect/connect.component').then(c => c.ConnectUserComponent),
        data: {
            anonymousAccess: false
        }
    },
    {
        path: 'signup',
        loadComponent: () => import('app/components/login/signup/signup.component').then(c => c.SignupComponent),
        data: {
            anonymousAccess: true
        }
    }
];
