import { ApiModule } from 'app/endpoints/api/api.module';
import { BrandingComponent } from './branding/branding.component';
import { BrandingConfigComponent } from './config/config.component';
import { BrandingEditComponent } from './branding/edit/edit.component';
import { BrandingsComponent } from './brandings.component';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PaginatorModule } from '@widgets/qp-paginator/qp-paginator.module';
import { PipesModule } from '@pipes/pipes.module';
import { PreviewBrandingComponent } from './preview/preview.component';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        PaginatorModule,
        PipesModule,
        ReactiveFormsModule,
        SpinnerButtonModule,
        RouterModule
    ],
    declarations: [
        BrandingComponent,
        BrandingConfigComponent,
        BrandingEditComponent,
        BrandingsComponent,
        PreviewBrandingComponent
    ]
})
export class BrandingsModule {}
