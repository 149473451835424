import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { StatisticsClient, statisticsClientFactory } from './statistics-client';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: StatisticsClient,
            useFactory: statisticsClientFactory,
            deps: [HttpClient]
        }
    ]
})
export class AuthorizesModule {}
