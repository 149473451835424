<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null" class="flex-col rg24">
    <mat-card class="card02" *ngIf="!acquirer.active">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Settlements" icon="paid" i18n-headingText></qp-section-heading>
            <mat-card-content class="flex-col rg8">
                <span i18n>Please enable Clearhaus to access to your settlement files.</span>
                <button mat-button [routerLink]="['../settings/acquirers']" color="accent" i18n>Go to Clearhaus</button>
            </mat-card-content>
        </div>
    </mat-card>

    <mat-card class="card02" *ngIf="error_loading && acquirer.active">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Settlements" icon="paid" i18n-headingText></qp-section-heading>
            <mat-card-content class="flex-col rg8">
                <span i18n>It was not possible to access to your settlement files.</span>
                <span i18n>Please contact support to gain access.</span>
            </mat-card-content>
        </div>
    </mat-card>

    <mat-card class="card02" *ngIf="!error_loading && acquirer.active">
        <mat-toolbar class="flex-row cg8 top-toolbar">
            <mat-tab-group selectedIndex="Clearhaus" (selectedTabChange)="changeTab($event)" color="accent">
                <mat-tab label="Main" i18n-label></mat-tab>
                <mat-tab *ngFor="let ch_account of acquirer.currency_specific_account | keyvalue" label="{{ ch_account.key }}" i18n-label></mat-tab>
            </mat-tab-group>

            <span class="flex-1"></span>

            <button mat-icon-button (click)="exportSettlements()">
                <mat-icon aria-label="Export Settlement" i18n-matTooltip matTooltip="Export settlement" class="text-grey">
                    cloud_download
                </mat-icon>
            </button>

            <qp-columns-settings table="settlements" class="text-grey"></qp-columns-settings>
        </mat-toolbar>

        <mat-card-content class="flex-col rg16">
            <qp-settlements-table
                *ngIf="data_source.data.length > 0"
                [dataSource]="data_source"
                [page]="page_params.page ?? 1"
                [pageSize]="page_params.page_size ?? 10"
            >
            </qp-settlements-table>

            <mat-progress-bar *ngIf="is_loading_results" mode="indeterminate"></mat-progress-bar>

            <div *ngIf="data_source.data.length === 0 && !is_loading_results" class="flex-col justify-center items-center" i18n>
                No settlements were found.
            </div>

            <qp-paginator [config]="paginator_options" [data_length]="data_source.data.length" (pageChange)="onPageChange($event)"></qp-paginator>
        </mat-card-content>
    </mat-card>
</div>
