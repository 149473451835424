<div class="flex-row justify-center">
    <mat-card class="flex-col rg32 justify-start items-center flex-632px-mobile-100 card02">
        <mat-card-title i18n> Activate Shopify </mat-card-title>
        <div class="flex-col rg16 justify-center items-start" *ngIf="!agreement">
            <h2 i18n>Select account</h2>
            <span class="flex-1 margin l16" i18n>In order to finish Shopify activation select an account</span>

            <mat-nav-list>
                <ng-container *ngFor="let agreement of agreements; let last = last">
                    <a mat-list-item [class.cursor]="agreement.accepted" (click)="selectAgreement(agreement)">
                        <div class="flex-col">
                            <p class="margin b0" matLine>{{ agreement.account?.shop_name || agreement.account?.name }}</p>
                            <span matLine class="text-grey"> {{ agreement.account?.type }} - {{ agreement.account?.id }} </span>
                        </div>
                        <span class="flex-1"></span>

                        <img [src]="agreement.logo" *ngIf="agreement.logo" class="size-50" />
                    </a>
                    <mat-divider *ngIf="!last"></mat-divider>
                </ng-container>
            </mat-nav-list>

            <mat-paginator (page)="changePage()" [length]="resultsLength" [pageSize]="page_size" [pageIndex]="page - 1" [pageSizeOptions]="[10, 100]" *ngIf="displayPaginator"> </mat-paginator>
        </div>
        <div class="flex-col rg16 justify-center items-start" *ngIf="!!agreement">
            <h2 i18n>Completing shopify Activation</h2>
            <div class="flex-row">
                <mat-icon class="text-yellow">warning</mat-icon>
                <span i18n>merging shopify and quickpay accounts, the tab wil close automaticaly when the process is done</span>
            </div>
        </div>
        <mat-progress-bar *ngIf="is_loading()" mode="indeterminate"></mat-progress-bar>
    </mat-card>
</div>
