import { Injectable, Inject } from '@angular/core';
import tinycolor, { Instance } from 'tinycolor2';
import { themes } from './themes';
import { Theme, Logo, Color } from '@interfaces/theme';
import { WINDOW } from 'app/helpers/windowprovider/window-provider';

@Injectable({
    providedIn: 'root'
})
export class ThemingService {
    public hostname: string;
    public background: string;
    public brand_name: string;
    public logo: Logo;
    public name: string;
    public theme: Theme = { name: '', brand_name: '', logo: { dark: '', light: '' }, background: '', primary_color: '' };
    public primaryColorPalette: Color[] = [];

    constructor(
        @Inject(WINDOW) window: any
    ) {
        this.hostname = window.location.hostname !== 'localhost' ? window.location.hostname : 'manage.quickpay.net';
        if (this.hostname === 'insights.unzer-staging.quickpay.net') {
            this.hostname = 'insights.unzerdirect.com';
        }
        this.theme = themes[this.hostname] ? themes[this.hostname] : themes['manage.quickpay.net'];
        this.name = this.theme.name;
        this.brand_name = this.theme.brand_name;
        this.background = this.theme.background;
        this.logo = this.theme.logo;
        this.primaryColorPalette = this.computeColors(this.theme.primary_color);
    }

    public computeColors(hex: string): Color[] {
        return [
            this.getColorObject(tinycolor(hex).lighten(52), '50'),
            this.getColorObject(tinycolor(hex).lighten(37), '100'),
            this.getColorObject(tinycolor(hex).lighten(26), '200'),
            this.getColorObject(tinycolor(hex).lighten(12), '300'),
            this.getColorObject(tinycolor(hex).lighten(6), '400'),
            this.getColorObject(tinycolor(hex), '500'),
            this.getColorObject(tinycolor(hex).darken(6), '600'),
            this.getColorObject(tinycolor(hex).darken(12), '700'),
            this.getColorObject(tinycolor(hex).darken(18), '800'),
            this.getColorObject(tinycolor(hex).darken(24), '900'),
            this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
            this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
            this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
            this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
        ];
    }

    public getColorObject(value: Instance, name: string): Color {
        const c = tinycolor(value);
        return {
            name,
            hex: c.toHexString(),
            dark_contrast: c.isLight()
        };
    }
}
