<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card class="card02">
        <div class="flex-row cg16">
            <mat-icon color="accent">tune</mat-icon>
            <h5 i18n>Reseller settings</h5>
        </div>

        <form class="flex-col rg16" name="reseller" autocomplete="off" [formGroup]="resellerForm">
            <div class="flex-row" [class.error-border]="formError" [class.warning-border]="resellerForm.dirty && !formError" [class.success-border]="formSaved && resellerForm.pristine">

                <div class="flex-col rg12 flex-1">
                    <mat-form-field color="accent">
                        <mat-label i18n>Contact Email</mat-label>
                        <input matInput name="contact_email" formControlName="contactEmail" />
                        <mat-hint *ngIf="!getResellerFormControl('contactEmail').hasError('pattern')" i18n> Provide an email to receive relevant updates and information from Quickpay </mat-hint>
                        <mat-error *ngIf="getResellerFormControl('contactEmail').hasError('pattern')" i18n> Must be a valid email </mat-error>
                    </mat-form-field>
                    <mat-form-field color="accent">
                        <mat-label i18n>Support email</mat-label>
                        <input matInput name="support_email" formControlName="supportEmail" />
                        <mat-hint *ngIf="!getResellerFormControl('supportEmail').hasError('pattern')"> Provide an email to receive support emails from your merchants </mat-hint>
                        <mat-error *ngIf="getResellerFormControl('supportEmail').hasError('pattern')"> Must be a valid email </mat-error>
                    </mat-form-field>
                    <mat-form-field color="accent">
                        <mat-label i18n>No reply email</mat-label>
                        <input matInput name="noreply_email" formControlName="noreplyEmail" />
                        <mat-hint *ngIf="!getResellerFormControl('noreplyEmail').hasError('pattern')" i18n> Provide a no-reply email that will be used to send system mails to your merchants. </mat-hint>
                        <mat-error *ngIf="getResellerFormControl('noreplyEmail').hasError('pattern')" i18n> Must be a valid email </mat-error>
                    </mat-form-field>

                    <qp-form-state-info [form_dirty]="resellerForm.dirty" [form_pristine]="resellerForm.pristine" [form_saved]="formSaved" [form_error]="formError"> </qp-form-state-info>
                </div>
            </div>

            <qp-form-save-discard [form_dirty]="resellerForm.dirty" [form_invalid]="resellerForm.invalid" (form_save)="save()" (form_discard)="discardChanges()"> </qp-form-save-discard>
        </form>
    </mat-card>
</div>
