import { DirectorFile } from './director-file';

export class Company {
    public name?: string;
    public registration_number?: string;
    public email?: string;
    public phone?: string;
    public address_line_1?: string;
    public address_line_2?: string;
    public city?: string;
    public country?: string;
    public form?: 'Other' | 'Forening' | 'Entrepreneurial';
    public zipcode?: string;
    public ownership_structure?: 'sole_director' | 'sole_not_director' | 'one_or_more_25' | 'none_25';
    public ownership_structure_comment?: string;
    public files?: DirectorFile[];

    constructor(init?: Partial<Company>) {
        Object.assign(this, init);
    }
}
