import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RequestInterceptor } from './interceptor/request.interceptor';
import { ResponseInterceptor } from './interceptor/response.interceptor';
import { InvoiceClient, invoiceClientFactory } from './invoice-client';

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        {
            provide: InvoiceClient,
            useFactory: invoiceClientFactory,
            deps: [HttpClient]
        },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true }
    ]
})
export class InvoiceModule {}
