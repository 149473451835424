import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Account } from '@interfaces/account';
import { Application } from '@models/clearhaus-application/application';
import { BankAccount } from '@models/clearhaus-application/bank-account';
import { Company } from '@models/clearhaus-application/company';
import { Contact } from '@models/clearhaus-application/contact';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { Person } from '@models/clearhaus-application/person';
import { Website } from '@models/clearhaus-application/website';
import { Me } from '@interfaces/me';
import { AgreementService } from '@services/agreement/agreement.service';
import { CurrencyService } from '@services/currency/currency.service';
import { ClearhausRepo } from 'app/endpoints/server/clearhaus-repo';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';
import { SessionService } from '@services/session/session.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Observable, of, onErrorResumeNext } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ClearhausSignupFormStepEightComponent } from './documentation-step/documentation.component';
import { ClearhausSignupFormStepFourComponent } from './ownership-step/ownership.component';

@Component({
    selector: 'qp-clearhaus-signup-form',
    templateUrl: './form.html',
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ]
})
export class ClearhausSignupFormComponent extends BaseLoadingDirective implements AfterViewInit {
    @ViewChild('documentationStep') public documentationStepComponent: ClearhausSignupFormStepEightComponent;
    @ViewChild('ownershipStep') public ownershipStepComponent: ClearhausSignupFormStepFourComponent;

    public account: Account | undefined = {} as Account;
    public additional_information: string;
    public application: Application = Object.assign(
        {
            business_model: {
                trading_name: null,
                description: null,
                recurring: false,
                physical_delivery: false,
                delivery_delay: null,
                drop_shipping: null,
                estimate_currency: null,
                estimated_monthly_turnover: null,
                estimated_average_transaction_amount: null
            }
        },
        new Application()
    );
    public applicationExists = false;
    public bank_account: BankAccount = new BankAccount();
    public company: Company = new Company();
    public contact: Contact = new Contact();
    public countries: Country[] = [];
    public currencies: {
        value: string;
        name: string;
    }[] = [];
    public director: Person = Object.assign(
        {
            role_director: true,
            files: []
        },
        new Person()
    );
    public documentationFile: DirectorFile;
    public fileInfo: any;
    public files: DirectorFile[] = [];
    public filesFromServer: any = {
        director: {
            picture_legitimation: null,
            address_legitimation: null
        },
        owners: [],
        documentation: null,
        additional: []
    };
    public loaded = 0;
    public loadStep = 100 / 8;
    public owners: Person[] = [];
    public people: Person[] = [];
    public showForm = false;
    public supportedCountries: Country[] = [];
    public validity: {
        contact: boolean;
        company: boolean;
        director: boolean;
        ownership: boolean;
        websites: boolean;
        business: boolean;
        bank: boolean;
        documentation: boolean;
    };
    public websites: Website[] = [];
    public websitesFromServer: Website[] = [];

    constructor(
        private countryService: CountryService,
        private clearhausRepo: ClearhausRepo,
        private currencyService: CurrencyService,
        private sessionService: SessionService,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngAfterViewInit(): void {
        this.validity = {
            contact: false,
            company: false,
            director: false,
            ownership: false,
            websites: false,
            business: false,
            bank: false,
            documentation: false
        };
        this.account = this.agreementService.agreement?.account;
        this.currencies = this.currencyService.getArray();
        this.countries = this.countryService.getCountries();
        this.supportedCountries = this.countryService.getEU28Countries();
        this.supportedCountries.push({ code: 'JEY', name: $localize`Jersey`, currency: '', eu28: false });

        this.getApplication();
    }

    public updateValidity(params: any): void {
        this.validity = { ...this.validity, ...params };
    }

    public handleCompanyChange(newCompany: Company): void {
        this.company = newCompany;
        this.documentationStepComponent?.setFormValidators();
    }

    public getApplication(): void {
        this.loaded = 0;
        this.showForm = false;

        onErrorResumeNext(
            this.clearhausRepoGetApplication(),
            this.clearhausRepoGetContact(),
            this.clearhausRepoGetCompany(),
            this.clearhausRepoGetPeople(),
            this.clearhausRepoGetAllWebsites(),
            this.clearhausRepoGetBankAccount(),
            this.clearhausRepoGetDocumentationFile(),
            this.clearhausRepoGetAdditionalFiles()
        )
            .pipe(finalize(() => this.postGetApplication()))
            .subscribe();
    }

    public clearhausRepoGetApplication(): Observable<any> {
        return this.clearhausRepo.getApplication().pipe(
            map(application => {
                this.application = application;
                this.additional_information = application.additional_information || '';
                this.applicationExists = true;
            }),
            catchError(_ => {
                this.applicationExists = false;
                if (this.application.business_model) {
                    this.application.business_model.trading_name = this.account?.shop_name || '';
                }
                this.additional_information = '';
                this.application.metadata = { state: '' };
                return of();
            }),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public clearhausRepoGetContact(): Observable<any> {
        return this.clearhausRepo.getContact().pipe(
            map(contact => {
                this.contact = contact;
            }),
            catchError(_ => {
                const user: Me | undefined = this.sessionService.me;
                this.contact = {
                    name: user?.name,
                    email: user?.email
                };
                return of();
            }),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public clearhausRepoGetCompany(): Observable<any> {
        return this.clearhausRepo.getCompany().pipe(
            map(company => {
                if (company.country?.length === 2) {
                    company.country = this.countryService.alpha2ToAlpha3(company.country) ?? '';
                }
                this.company = company;
            }),
            catchError(_ => {
                this.company = {
                    name: this.account?.customer_address?.name,
                    address_line_1: this.account?.customer_address?.street,
                    zipcode: this.account?.customer_address?.zip_code,
                    city: this.account?.customer_address?.city,
                    email: this.account?.contact_email,
                    country: this.convertToAlpha3(this.account?.customer_address?.country_code ?? ''),
                    registration_number: this.account?.customer_address?.vat_no,
                    files: []
                };
                return of();
            }),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public clearhausRepoGetPeople(): Observable<any> {
        return this.clearhausRepo.getPeople().pipe(
            map(people => {
                this.people = people;
            }),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public clearhausRepoGetAllWebsites(): Observable<any> {
        return this.clearhausRepo.getAllWebsites().pipe(
            map(websites => {
                this.websites = websites;
            }),
            catchError(_ => {
                const websites: Website[] = [];
                this.account?.shop_urls?.forEach(url => {
                    const website = new Website();
                    website.url = url;
                    websites.push(website);
                });
                this.websites = websites;
                return of();
            }),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public clearhausRepoGetBankAccount(): Observable<any> {
        return this.clearhausRepo.getBankAccount().pipe(
            map(bank_account => {
                this.bank_account = bank_account;
            }),
            catchError(_ => of()),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public clearhausRepoGetDocumentationFile(): Observable<any> {
        return this.clearhausRepo.getCompanyFiles().pipe(
            map(files => {
                this.company.files = files;
                this.filesFromServer.documentation = files.find(file => file.label === 'documentation');
            }),
            catchError(_ => {
                this.company.files = [];
                return of(null);
            }),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public clearhausRepoGetAdditionalFiles(): Observable<any> {
        return this.clearhausRepo.getAllFiles().pipe(
            map(files => {
                this.files = files;
                files?.forEach(file => {
                    this.filesFromServer.additional.push(file.name);
                });
            }),
            catchError(_ => {
                this.files = [];
                return of();
            }),
            finalize(() => {
                this.loaded += this.loadStep;
            })
        );
    }

    public getPersonFiles(person: Person): Observable<any> {
        return this.clearhausRepo.getPersonFiles(person.id ?? '');
    }

    public postGetApplication(): void {
        this.showForm = true;

        if (this.company.country?.length === 2) {
            this.company.country = this.countryService.alpha2ToAlpha3(this.company.country) ?? '';
        } else {
            this.company.country = this.company.country || undefined;
        }

        this.websitesFromServer = this.websites;

        this.people.forEach(person => {
            // Problem: Clearhaus sometimes return a person with an ID, that wasn't created by us
            // This person can not be found when trying to update it, or get it's files.
            if (!person.name && !person.address_line_1) {
                return;
            }

            if (person.role_director) {
                const director = { ...{}, ...person };
                if (director.country?.length === 2) {
                    director.country = this.countryService.alpha2ToAlpha3(director.country) ?? '';
                } else {
                    director.country = director.country || undefined;
                }
                this.clearhausRepo
                    .getPersonFiles(director.id ?? '')
                    .pipe(
                        map(files => {
                            director.files = files ? files : [];
                            director.files.forEach(file => {
                                if (!file.label) {
                                    return;
                                }
                                this.filesFromServer.director[file.label] = file.name;
                            });
                        }),
                        finalize(() => {
                            this.director = director;
                        })
                    )
                    .subscribe();
            }

            if (person.role_owner) {
                const owner = Object.assign({}, person);

                if (owner.country?.length === 2) {
                    owner.country = this.countryService.alpha2ToAlpha3(owner.country) || '';
                }

                this.clearhausRepo
                    .getPersonFiles(person.id ?? '')
                    .pipe(
                        map(files => {
                            owner.files = files ? files : [];
                            const fileObject: any = {
                                picture_legitimation: null,
                                address_legitimation: null
                            };
                            owner.files.forEach(file => {
                                if (!file.label) {
                                    return;
                                }
                                fileObject[file.label] = file.name;
                            });
                            this.filesFromServer.owners.push(fileObject);
                        }),
                        catchError(_ => of({})),
                        finalize(() => {
                            const owners = [...this.owners].filter(o => !!o.name);
                            owners.push(owner);
                            this.owners = owners;
                            this.ownershipStepComponent?.validateOwnerFiles();
                        })
                    )
                    .subscribe();
            }
        });
    }

    public convertToAlpha3(value: string): string {
        return value.length === 3 ? value : this.countryService.alpha2ToAlpha3(value) ?? '';
    }
}
