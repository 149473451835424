import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AgreementsComponent } from './agreements.component';
import { ApiModule } from '@endpoints/api/api.module';
import { LayoutModule } from '@widgets/layout/layout.module';
import { PipesModule } from '@pipes/pipes.module';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        FormsModule,
        LayoutModule,
        LayoutModule,
        PipesModule,
        SpinnerButtonModule
    ],
    declarations: [AgreementsComponent],
    exports: [AgreementsComponent]
})
export class AgreementsModule {}
