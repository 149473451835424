import { Injectable } from '@angular/core';
import { ALPHA3_TO_ALPHA2 } from '@services/country/alpha3_to_alpha2.constant';
import { Country } from '@services/country/country.interface';
import { PSD2_REQUIRED_COUNTRIES } from '@services/country/psd2-required-countries.constant';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    public countries: Array<Country>;

    constructor() {
        this.countries = this.getCountries();
    }

    public getCountries(): Array<Country> {
        return [
            { code: 'AFG', name: $localize`Afghanistan`, currency: '', eu28: false },
            { code: 'ALB', name: $localize`Albania`, currency: 'ALL', eu28: false },
            { code: 'DZA', name: $localize`Algeria`, currency: '', eu28: false },
            { code: 'ASM', name: $localize`American Samoa`, currency: '', eu28: false },
            { code: 'AND', name: $localize`Andorra`, currency: '', eu28: true },
            { code: 'AGO', name: $localize`Angola`, currency: '', eu28: false },
            { code: 'AIA', name: $localize`Anguilla`, currency: '', eu28: false },
            { code: 'ATA', name: $localize`Antarctica`, currency: '', eu28: false },
            { code: 'ATG', name: $localize`Antigua and Barbuda`, currency: '', eu28: false },
            { code: 'ARG', name: $localize`Argentina`, currency: '', eu28: false },
            { code: 'ARM', name: $localize`Armenia`, currency: '', eu28: false },
            { code: 'ABW', name: $localize`Aruba`, currency: '', eu28: false },
            { code: 'AUS', name: $localize`Australia`, currency: '', eu28: false },
            { code: 'AUT', name: $localize`Austria`, currency: 'EUR', eu28: true },
            { code: 'AZE', name: $localize`Azerbaijan`, currency: '', eu28: false },
            { code: 'BHS', name: $localize`Bahamas`, currency: '', eu28: false },
            { code: 'BHR', name: $localize`Bahrain`, currency: '', eu28: false },
            { code: 'BGD', name: $localize`Bangladesh`, currency: '', eu28: false },
            { code: 'BRB', name: $localize`Barbados`, currency: '', eu28: false },
            { code: 'BLR', name: $localize`Belarus`, currency: '', eu28: false },
            { code: 'BEL', name: $localize`Belgium`, currency: 'EUR', eu28: true },
            { code: 'BLZ', name: $localize`Belize`, currency: '', eu28: false },
            { code: 'BEN', name: $localize`Benin`, currency: '', eu28: false },
            { code: 'BMU', name: $localize`Bermuda`, currency: '', eu28: false },
            { code: 'BTN', name: $localize`Bhutan`, currency: '', eu28: false },
            { code: 'BOL', name: $localize`Bolivia, Plurinational State of`, currency: '', eu28: false },
            { code: 'BES', name: $localize`Bonaire, Sint Eustatius and Saba`, currency: '', eu28: false },
            { code: 'BIH', name: $localize`Bosnia and Herzegovina`, currency: '', eu28: false },
            { code: 'BWA', name: $localize`Botswana`, currency: '', eu28: false },
            { code: 'BVT', name: $localize`Bouvet Island`, currency: '', eu28: false },
            { code: 'BRA', name: $localize`Brazil`, currency: '', eu28: false },
            { code: 'IOT', name: $localize`British Indian Ocean Territory`, currency: '', eu28: false },
            { code: 'BRN', name: $localize`Brunei Darussalam`, currency: '', eu28: false },
            { code: 'BGR', name: $localize`Bulgaria`, currency: 'BGN', eu28: true },
            { code: 'BFA', name: $localize`Burkina Faso`, currency: '', eu28: false },
            { code: 'BDI', name: $localize`Burundi`, currency: '', eu28: false },
            { code: 'CPV', name: $localize`Cabo Verde`, currency: '', eu28: false },
            { code: 'KHM', name: $localize`Cambodia`, currency: '', eu28: false },
            { code: 'CMR', name: $localize`Cameroon`, currency: '', eu28: false },
            { code: 'CAN', name: $localize`Canada`, currency: '', eu28: false },
            { code: 'CYM', name: $localize`Cayman Islands`, currency: '', eu28: false },
            { code: 'CAF', name: $localize`Central African Republic`, currency: '', eu28: false },
            { code: 'TCD', name: $localize`Chad`, currency: '', eu28: false },
            { code: 'CHL', name: $localize`Chile`, currency: '', eu28: false },
            { code: 'CHN', name: $localize`China`, currency: '', eu28: false },
            { code: 'CXR', name: $localize`Christmas Island`, currency: '', eu28: false },
            { code: 'CCK', name: $localize`Cocos (Keeling) Islands`, currency: '', eu28: false },
            { code: 'COL', name: $localize`Colombia`, currency: '', eu28: false },
            { code: 'COM', name: $localize`Comoros`, currency: '', eu28: false },
            { code: 'COG', name: $localize`Congo`, currency: '', eu28: false },
            { code: 'COD', name: $localize`Congo, the Democratic Republic of the`, currency: '', eu28: false },
            { code: 'COK', name: $localize`Cook Islands`, currency: '', eu28: false },
            { code: 'CRI', name: $localize`Costa Rica`, currency: '', eu28: false },
            { code: 'HRV', name: $localize`Croatia`, currency: 'EUR', eu28: true },
            { code: 'CUB', name: $localize`Cuba`, currency: '', eu28: false },
            { code: 'CUW', name: $localize`Curaçao`, currency: '', eu28: false },
            { code: 'CYP', name: $localize`Cyprus`, currency: 'EUR', eu28: true },
            { code: 'CZE', name: $localize`Czech Republic`, currency: 'CZK', eu28: true },
            { code: 'CIV', name: $localize`Côte d'Ivoire`, currency: '', eu28: false },
            { code: 'DNK', name: $localize`Denmark`, currency: 'DKK', eu28: true },
            { code: 'DJI', name: $localize`Djibouti`, currency: '', eu28: false },
            { code: 'DMA', name: $localize`Dominica`, currency: '', eu28: false },
            { code: 'DOM', name: $localize`Dominican Republic`, currency: '', eu28: false },
            { code: 'ECU', name: $localize`Ecuador`, currency: '', eu28: false },
            { code: 'EGY', name: $localize`Egypt`, currency: '', eu28: false },
            { code: 'SLV', name: $localize`El Salvador`, currency: '', eu28: false },
            { code: 'GNQ', name: $localize`Equatorial Guinea`, currency: '', eu28: false },
            { code: 'ERI', name: $localize`Eritrea`, currency: '', eu28: false },
            { code: 'EST', name: $localize`Estonia`, currency: 'EUR', eu28: true },
            { code: 'ETH', name: $localize`Ethiopia`, currency: '', eu28: false },
            { code: 'FLK', name: $localize`Falkland Islands (Malvinas)`, currency: '', eu28: false },
            { code: 'FRO', name: $localize`Faroe Islands`, currency: '', eu28: true },
            { code: 'FJI', name: $localize`Fiji`, currency: '', eu28: false },
            { code: 'FIN', name: $localize`Finland`, currency: 'EUR', eu28: true },
            { code: 'FRA', name: $localize`France`, currency: 'EUR', eu28: true },
            { code: 'GUF', name: $localize`French Guiana`, currency: '', eu28: false },
            { code: 'PYF', name: $localize`French Polynesia`, currency: '', eu28: false },
            { code: 'ATF', name: $localize`French Southern Territories`, currency: '', eu28: false },
            { code: 'GAB', name: $localize`Gabon`, currency: '', eu28: false },
            { code: 'GMB', name: $localize`Gambia`, currency: '', eu28: false },
            { code: 'GEO', name: $localize`Georgia`, currency: '', eu28: false },
            { code: 'DEU', name: $localize`Germany`, currency: 'EUR', eu28: true },
            { code: 'GHA', name: $localize`Ghana`, currency: '', eu28: false },
            { code: 'GIB', name: $localize`Gibraltar`, currency: '', eu28: true },
            { code: 'GRC', name: $localize`Greece`, currency: 'EUR', eu28: true },
            { code: 'GRL', name: $localize`Greenland`, currency: '', eu28: true },
            { code: 'GRD', name: $localize`Grenada`, currency: '', eu28: false },
            { code: 'GLP', name: $localize`Guadeloupe`, currency: '', eu28: false },
            { code: 'GUM', name: $localize`Guam`, currency: '', eu28: false },
            { code: 'GTM', name: $localize`Guatemala`, currency: '', eu28: false },
            { code: 'GGY', name: $localize`Guernsey`, currency: '', eu28: false },
            { code: 'GIN', name: $localize`Guinea`, currency: '', eu28: false },
            { code: 'GNB', name: $localize`Guinea-Bissau`, currency: '', eu28: false },
            { code: 'GUY', name: $localize`Guyana`, currency: '', eu28: false },
            { code: 'HTI', name: $localize`Haiti`, currency: '', eu28: false },
            { code: 'HMD', name: $localize`Heard Island and McDonald Islands`, currency: '', eu28: false },
            { code: 'VAT', name: $localize`Holy See (Vatican City State)`, currency: '', eu28: false },
            { code: 'HND', name: $localize`Honduras`, currency: '', eu28: false },
            { code: 'HKG', name: $localize`Hong Kong`, currency: '', eu28: false },
            { code: 'HUN', name: $localize`Hungary`, currency: 'HUF', eu28: true },
            { code: 'ISL', name: $localize`Iceland`, currency: 'ISK', eu28: true },
            { code: 'IND', name: $localize`India`, currency: '', eu28: false },
            { code: 'IDN', name: $localize`Indonesia`, currency: '', eu28: false },
            { code: 'IRN', name: $localize`Iran, Islamic Republic of`, currency: '', eu28: false },
            { code: 'IRQ', name: $localize`Iraq`, currency: '', eu28: false },
            { code: 'IRL', name: $localize`Ireland`, currency: 'EUR', eu28: true },
            { code: 'IMN', name: $localize`Isle of Man`, currency: '', eu28: false },
            { code: 'ISR', name: $localize`Israel`, currency: '', eu28: false },
            { code: 'ITA', name: $localize`Italy`, currency: 'EUR', eu28: true },
            { code: 'JAM', name: $localize`Jamaica`, currency: '', eu28: false },
            { code: 'JPN', name: $localize`Japan`, currency: '', eu28: false },
            { code: 'JEY', name: $localize`Jersey`, currency: '', eu28: true },
            { code: 'JOR', name: $localize`Jordan`, currency: '', eu28: false },
            { code: 'KAZ', name: $localize`Kazakhstan`, currency: '', eu28: false },
            { code: 'KEN', name: $localize`Kenya`, currency: '', eu28: false },
            { code: 'KIR', name: $localize`Kiribati`, currency: '', eu28: false },
            { code: 'PRK', name: $localize`Korea, Democratic People's Republic of`, currency: '', eu28: false },
            { code: 'KOR', name: $localize`Korea, Republic of`, currency: '', eu28: false },
            { code: 'KWT', name: $localize`Kuwait`, currency: '', eu28: false },
            { code: 'KGZ', name: $localize`Kyrgyzstan`, currency: '', eu28: false },
            { code: 'LAO', name: $localize`Lao People's Democratic Republic`, currency: '', eu28: false },
            { code: 'LVA', name: $localize`Latvia`, currency: 'EUR', eu28: true },
            { code: 'LBN', name: $localize`Lebanon`, currency: '', eu28: false },
            { code: 'LSO', name: $localize`Lesotho`, currency: '', eu28: false },
            { code: 'LBR', name: $localize`Liberia`, currency: '', eu28: false },
            { code: 'LBY', name: $localize`Libya`, currency: '', eu28: false },
            { code: 'LIE', name: $localize`Liechtenstein`, currency: 'CHF', eu28: true },
            { code: 'LTU', name: $localize`Lithuania`, currency: 'EUR', eu28: true },
            { code: 'LUX', name: $localize`Luxembourg`, currency: 'EUR', eu28: true },
            { code: 'MAC', name: $localize`Macao`, currency: '', eu28: false },
            {
                code: 'MKD',
                name: $localize`Macedonia, the former Yugoslav Republic of`,
                currency: 'MKD',
                eu28: false
            },
            { code: 'MDG', name: $localize`Madagascar`, currency: '', eu28: false },
            { code: 'MWI', name: $localize`Malawi`, currency: '', eu28: false },
            { code: 'MYS', name: $localize`Malaysia`, currency: '', eu28: false },
            { code: 'MDV', name: $localize`Maldives`, currency: '', eu28: false },
            { code: 'MLI', name: $localize`Mali`, currency: '', eu28: false },
            { code: 'MLT', name: $localize`Malta`, currency: 'EUR', eu28: true },
            { code: 'MHL', name: $localize`Marshall Islands`, currency: '', eu28: false },
            { code: 'MTQ', name: $localize`Martinique`, currency: '', eu28: false },
            { code: 'MRT', name: $localize`Mauritania`, currency: '', eu28: false },
            { code: 'MUS', name: $localize`Mauritius`, currency: '', eu28: false },
            { code: 'MYT', name: $localize`Mayotte`, currency: '', eu28: false },
            { code: 'MEX', name: $localize`Mexico`, currency: '', eu28: false },
            { code: 'FSM', name: $localize`Micronesia, Federated States of`, currency: '', eu28: false },
            { code: 'MDA', name: $localize`Moldova, Republic of`, currency: '', eu28: false },
            { code: 'MCO', name: $localize`Monaco`, currency: '', eu28: true },
            { code: 'MNG', name: $localize`Mongolia`, currency: '', eu28: false },
            { code: 'MNE', name: $localize`Montenegro`, currency: 'EUR', eu28: false },
            { code: 'MSR', name: $localize`Montserrat`, currency: '', eu28: false },
            { code: 'MAR', name: $localize`Morocco`, currency: '', eu28: false },
            { code: 'MOZ', name: $localize`Mozambique`, currency: '', eu28: false },
            { code: 'MMR', name: $localize`Myanmar`, currency: '', eu28: false },
            { code: 'NAM', name: $localize`Namibia`, currency: '', eu28: false },
            { code: 'NRU', name: $localize`Nauru`, currency: '', eu28: false },
            { code: 'NPL', name: $localize`Nepal`, currency: '', eu28: false },
            { code: 'NLD', name: $localize`Netherlands`, currency: 'EUR', eu28: true },
            { code: 'NCL', name: $localize`New Caledonia`, currency: '', eu28: false },
            { code: 'NZL', name: $localize`New Zealand`, currency: '', eu28: false },
            { code: 'NIC', name: $localize`Nicaragua`, currency: '', eu28: false },
            { code: 'NER', name: $localize`Niger`, currency: '', eu28: false },
            { code: 'NGA', name: $localize`Nigeria`, currency: '', eu28: false },
            { code: 'NIU', name: $localize`Niue`, currency: '', eu28: false },
            { code: 'NFK', name: $localize`Norfolk Island`, currency: '', eu28: false },
            { code: 'MNP', name: $localize`Northern Mariana Islands`, currency: '', eu28: false },
            { code: 'NOR', name: $localize`Norway`, currency: 'NOK', eu28: true },
            { code: 'OMN', name: $localize`Oman`, currency: '', eu28: false },
            { code: 'PAK', name: $localize`Pakistan`, currency: '', eu28: false },
            { code: 'PLW', name: $localize`Palau`, currency: '', eu28: false },
            { code: 'PSE', name: $localize`Palestine, State of`, currency: '', eu28: false },
            { code: 'PAN', name: $localize`Panama`, currency: '', eu28: false },
            { code: 'PNG', name: $localize`Papua New Guinea`, currency: '', eu28: false },
            { code: 'PRY', name: $localize`Paraguay`, currency: '', eu28: false },
            { code: 'PER', name: $localize`Peru`, currency: '', eu28: false },
            { code: 'PHL', name: $localize`Philippines`, currency: '', eu28: false },
            { code: 'PCN', name: $localize`Pitcairn`, currency: '', eu28: false },
            { code: 'POL', name: $localize`Poland`, currency: 'PLN', eu28: true },
            { code: 'PRT', name: $localize`Portugal`, currency: 'EUR', eu28: true },
            { code: 'PRI', name: $localize`Puerto Rico`, currency: '', eu28: false },
            { code: 'QAT', name: $localize`Qatar`, currency: '', eu28: false },
            { code: 'ROU', name: $localize`Romania`, currency: 'RON', eu28: true },
            { code: 'RUS', name: $localize`Russian Federation`, currency: 'RUB', eu28: false },
            { code: 'RWA', name: $localize`Rwanda`, currency: '', eu28: false },
            { code: 'REU', name: $localize`Réunion`, currency: '', eu28: false },
            { code: 'BLM', name: $localize`Saint Barthélemy`, currency: '', eu28: false },
            {
                code: 'SHN',
                name: $localize`Saint Helena, Ascension and Tristan da Cunha`,
                currency: '',
                eu28: false
            },
            { code: 'KNA', name: $localize`Saint Kitts and Nevis`, currency: '', eu28: false },
            { code: 'LCA', name: $localize`Saint Lucia`, currency: '', eu28: false },
            { code: 'MAF', name: $localize`Saint Martin (French part)`, currency: '', eu28: false },
            { code: 'SPM', name: $localize`Saint Pierre and Miquelon`, currency: '', eu28: false },
            { code: 'VCT', name: $localize`Saint Vincent and the Grenadines`, currency: '', eu28: false },
            { code: 'WSM', name: $localize`Samoa`, currency: '', eu28: false },
            { code: 'SMR', name: $localize`San Marino`, currency: '', eu28: true },
            { code: 'STP', name: $localize`Sao Tome and Principe`, currency: '', eu28: false },
            { code: 'SAU', name: $localize`Saudi Arabia`, currency: '', eu28: false },
            { code: 'SEN', name: $localize`Senegal`, currency: '', eu28: false },
            { code: 'SRB', name: $localize`Serbia`, currency: '', eu28: false },
            { code: 'SYC', name: $localize`Seychelles`, currency: '', eu28: false },
            { code: 'SLE', name: $localize`Sierra Leone`, currency: '', eu28: false },
            { code: 'SGP', name: $localize`Singapore`, currency: '', eu28: false },
            { code: 'SXM', name: $localize`Sint Maarten (Dutch part)`, currency: '', eu28: false },
            { code: 'SVK', name: $localize`Slovakia`, currency: 'EUR', eu28: true },
            { code: 'SVN', name: $localize`Slovenia`, currency: 'EUR', eu28: true },
            { code: 'SLB', name: $localize`Solomon Islands`, currency: '', eu28: false },
            { code: 'SOM', name: $localize`Somalia`, currency: '', eu28: false },
            { code: 'ZAF', name: $localize`South Africa`, currency: '', eu28: false },
            {
                code: 'SGS',
                name: $localize`South Georgia and the South Sandwich Islands`,
                currency: '',
                eu28: false
            },
            { code: 'SSD', name: $localize`South Sudan`, currency: '', eu28: false },
            { code: 'ESP', name: $localize`Spain`, currency: 'EUR', eu28: true },
            { code: 'LKA', name: $localize`Sri Lanka`, currency: '', eu28: false },
            { code: 'SDN', name: $localize`Sudan`, currency: '', eu28: false },
            { code: 'SUR', name: $localize`Suriname`, currency: '', eu28: false },
            { code: 'SJM', name: $localize`Svalbard and Jan Mayen`, currency: '', eu28: false },
            { code: 'SWZ', name: $localize`Swaziland`, currency: '', eu28: false },
            { code: 'SWE', name: $localize`Sweden`, currency: 'SEK', eu28: true },
            { code: 'CHE', name: $localize`Switzerland`, currency: 'CHF', eu28: true },
            { code: 'SYR', name: $localize`Syrian Arab Republic`, currency: '', eu28: false },
            { code: 'TWN', name: $localize`Taiwan, Province of China`, currency: '', eu28: false },
            { code: 'TJK', name: $localize`Tajikistan`, currency: '', eu28: false },
            { code: 'TZA', name: $localize`Tanzania, United Republic of`, currency: '', eu28: false },
            { code: 'THA', name: $localize`Thailand`, currency: '', eu28: false },
            { code: 'TLS', name: $localize`Timor-Leste`, currency: '', eu28: false },
            { code: 'TGO', name: $localize`Togo`, currency: '', eu28: false },
            { code: 'TKL', name: $localize`Tokelau`, currency: '', eu28: false },
            { code: 'TON', name: $localize`Tonga`, currency: '', eu28: false },
            { code: 'TTO', name: $localize`Trinidad and Tobago`, currency: '', eu28: false },
            { code: 'TUN', name: $localize`Tunisia`, currency: '', eu28: false },
            { code: 'TUR', name: $localize`Turkey`, currency: 'TRY', eu28: false },
            { code: 'TKM', name: $localize`Turkmenistan`, currency: '', eu28: false },
            { code: 'TCA', name: $localize`Turks and Caicos Islands`, currency: '', eu28: false },
            { code: 'TUV', name: $localize`Tuvalu`, currency: '', eu28: false },
            { code: 'UGA', name: $localize`Uganda`, currency: '', eu28: false },
            { code: 'UKR', name: $localize`Ukraine`, currency: '', eu28: false },
            { code: 'ARE', name: $localize`United Arab Emirates`, currency: '', eu28: false },
            { code: 'GBR', name: $localize`United Kingdom`, currency: 'GBP', eu28: true },
            { code: 'USA', name: $localize`United States`, currency: '', eu28: false },
            { code: 'UMI', name: $localize`United States Minor Outlying Islands`, currency: '', eu28: false },
            { code: 'URY', name: $localize`Uruguay`, currency: '', eu28: false },
            { code: 'UZB', name: $localize`Uzbekistan`, currency: '', eu28: false },
            { code: 'VUT', name: $localize`Vanuatu`, currency: '', eu28: false },
            { code: 'VEN', name: $localize`Venezuela, Bolivarian Republic of`, currency: '', eu28: false },
            { code: 'VNM', name: $localize`Viet Nam`, currency: '', eu28: false },
            { code: 'VGB', name: $localize`Virgin Islands, British`, currency: '', eu28: false },
            { code: 'VIR', name: $localize`Virgin Islands, U.S.`, currency: '', eu28: false },
            { code: 'WLF', name: $localize`Wallis and Futuna`, currency: '', eu28: false },
            { code: 'ESH', name: $localize`Western Sahara`, currency: '', eu28: false },
            { code: 'YEM', name: $localize`Yemen`, currency: '', eu28: false },
            { code: 'ZMB', name: $localize`Zambia`, currency: '', eu28: false },
            { code: 'ZWE', name: $localize`Zimbabwe`, currency: '', eu28: false },
            { code: 'ALA', name: $localize`Åland Islands`, currency: '', eu28: false }
        ];
    }

    public getEU28Country(countryCode: string): Country | false {
        const euCountry = this.countries.find(country => country.code === countryCode);
        return euCountry?.eu28 ? euCountry : false;
    }

    public alpha3ToAlpha2(code: string): string {
        return ALPHA3_TO_ALPHA2[code];
    }

    public getEU28Countries(): Country[] {
        return this.getCountries().filter(country => country.eu28);
    }

    public getNonEU28Countries(): Country[] {
        return this.getCountries().filter(country => !country.eu28);
    }

    public getPSD2Countries(): string[] {
        return PSD2_REQUIRED_COUNTRIES;
    }

    public getUnzerCountries(): Country[] {
        return [
            { code: 'DEU', name: $localize`Germany`, currency: 'EUR', eu28: true },
            { code: 'NLD', name: $localize`Netherlands`, currency: 'EUR', eu28: true },
            { code: 'AUT', name: $localize`Austria`, currency: 'EUR', eu28: true },
            { code: 'CHE', name: $localize`Switzerland`, currency: 'CHF', eu28: true }
        ];
    }

    public alpha2ToAlpha3(code: string): string | undefined {
        let country;

        for (const [key, value] of Object.entries(ALPHA3_TO_ALPHA2)) {
            if (value === code) {
                country = key;
            }
        }
        return country;
    }

    public getCountryFromCode(countryCode = ''): Country | undefined {
        const alpha3 = countryCode.length === 2 ? this.alpha2ToAlpha3(countryCode) : countryCode;
        const country = this.getCountries().find(({ code }) => code === alpha3);
        return country ?? { code: 'DNK', name: $localize`Denmark`, currency: 'DKK', eu28: true };
    }

    public getCurrencyFromCountryCode(countryCode: string): string {
        const country = this.getCountries().find(c => c.code === countryCode && c.currency !== '' && c.currency !== undefined);
        return country ? country.currency ?? '' : 'EUR';
    }

    public autoCompleteFilter(countries: Array<Country>, name: string): Country[] {
        const filterValue = name.toLowerCase();
        return countries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
    }

    public getCountryArrayIndex(countries: Array<Country>, countryCode: string): number | null {
        const index = countries.findIndex(country => country.code === countryCode);
        return index === -1 ? null : index;
    }
}
