import { Routes } from '@angular/router';
import { adminGuard } from './guards/admin.guard';


export const admin_routes: Routes = [
    {
        path: 'admin/auditlog',
        canMatch: [adminGuard],
        loadChildren: () => import('app/components/admin-auditlog/admin-auditlog.module').then(m => m.AdminAuditlogModule),
        data: {
            settings: {
                index: 40,
                icon: 'clear_all',
                title: 'Audit log',
                permission: { '/activity': ['get'] },
                roles: ['Administrator'],
                hotkeys: {
                    combo: 'g m',
                    description: 'Go to audit log'
                }
            }
        }
    },
    {
        path: 'admin/transactions',
        loadChildren: () => import('app/components/admin-transactions/admin-transactions.module').then(m => m.AdminTransactionsModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                index: 20,
                icon: 'local_atm',
                title: 'Transactions',
                permission: { '/transactions': ['get'] },
                roles: ['Administrator'],
                hotkeys: {
                    combo: 'g t',
                    description: 'Go to transactions'
                }
            }
        }
    },
    {
        path: 'admin/transaction/:transaction_id',
        loadChildren: () => import('app/components/admin-transactions/admin-transaction/admin-transaction.module').then(m => m.AdminTransactionModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Transaction',
                permission: { '/transactions/:id': ['get'] },
                roles: ['Administrator']
            }
        }
    },
    {

        path: 'admin/users',
        loadChildren: () => import('app/components/admin-users/admin-users.module').then(m => m.AdminUsersModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                index: 20,
                icon: 'supervisor_account',
                title: 'Users',
                permission: { '/users': ['get'] },
                roles: ['Administrator'],
                hotkeys: {
                    combo: 'g u',
                    description: 'Go to users'
                }
            }
        }
    },
    {
        path: 'admin/settings/kms',
        loadComponent: () => import('app/components/settings/kms/kms.component').then(m => m.KmsComponent),
        canActivate: [adminGuard],
        data: {
            settings: {
                index: 20,
                header: 'settings',
                content: 'KMS',
                title: 'KMS',
                permission: { '/cryptography/keys': ['get'] },
                roles: ['Administrator'],
                icon: 'style'
            }
        }
    },
    {
        path: 'admin/kms-key/:key_id',
        loadComponent: () => import('app/components/settings/kms/kms-edit/kms-edit.component').then(m => m.KmsEditComponent),
        canActivate: [adminGuard],
        data: {
            settings: {
                title: 'KMS'
            }
        }
    },
    {
        path: 'admin/user/:user_id',
        loadComponent: () => import('app/components/admin-users/admin-user/admin-user.component').then(m => m.AdminUserComponent),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'User',
                permission: { '/users/:id': ['get'] }
            }
        }
    },
    {
        path: 'admin/resellers',
        loadChildren: () => import('app/components/admin-resellers/admin-resellers.module').then(m => m.AdminResellersModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                index: 20,
                icon: 'business',
                title: 'Resellers',
                permission: { '/resellers': ['get'] },
                roles: ['Administrator'],
                hotkeys: {
                    combo: 'g r',
                    description: 'Go to resellers'
                }
            }
        }
    },
    {
        path: 'admin/reseller-details',
        loadChildren: () => import('app/components/admin-resellers/admin-reseller-info/admin-reseller-info.module').then(m => m.AdminResellerInfoModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Reseller',
                permission: { '/resellers': ['get'] }
            }
        }
    },
    {
        path: 'admin/reseller/:reseller_id',
        loadChildren: () => import('app/components/admin-resellers/admin-reseller/admin-reseller.module').then(m => m.AdminResellerModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Reseller',
                permission: { '/resellers/:id': ['get'] }
            }
        }
    },
    {
        path: 'admin/reseller-creditnote/:reseller_id',
        loadChildren: () => import('app/components/admin-resellers/admin-reseller-credit/admin-reseller-credit.module').then(m => m.AdminResellerCreditModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Reseller',
                permission: { '/resellers/:id': ['get'] }
            }
        }
    },
    {
        path: 'admin/plans',
        loadChildren: () => import('app/components/admin-plans/admin-plans.module').then(m => m.AdminPlansModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                index: 20,
                icon: 'dns',
                title: 'Plans',
                permission: { '/plans': ['get'] },
                roles: ['Administrator']
            }
        }
    },
    {
        path: 'admin/plan/:plan_id',
        loadChildren: () => import('app/components/admin-plans/admin-plan-detail/admin-plan-detail.module').then(m => m.AdminPlanDetailModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Plan',
                permission: { '/plans/:id': ['get'] }
            }
        }
    },
    {
        path: 'admin/create-plan',
        loadChildren: () => import('app/components/admin-plans/admin-plan-create/admin-plan-create.module').then(m => m.AdminPlanCreateModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Create Plan',
                permission: { '/plans': ['get'] }
            }
        }
    },
    {
        path: 'admin/binbase',
        loadChildren: () => import('app/components/admin-binbase/admin-binbase.module').then(m => m.AdminBinbaseModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                index: 40,
                icon: 'search',
                title: 'Binbase',
                permission: { '/binbase': ['get'] },
                roles: ['Administrator'],
                hotkeys: {
                    combo: 'g b',
                    description: 'Go to binbase'
                }
            }
        }
    },
    {
        path: 'admin/merchant/export',
        loadComponent: () => import('app/components/admin-export-merchants/admin-export-merchants.component').then(c => c.AdminExportMerchantsComponent),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Merchant',
                permission: { '/merchants': ['get'] }
            }
        }
    },
    {
        path: 'admin/merchant-creditnote/:merchant_id',
        loadChildren: () => import('app/components/admin-merchant-detail/admin-merchant-credit/admin-merchant-credit.module').then(m => m.AdminMerchantCreditModule),
        canMatch: [adminGuard],
        data: {
            settings: {
                title: 'Merchant',
                permission: { '/merchants/:id': ['get'] }
            }
        }
    },
    {
        path: 'admin/merchants',
        loadComponent: () => import('app/components/merchants/merchants.component').then(m => m.MerchantsComponent),
        canActivate: [adminGuard],
        data: {
            settings: {
                index: 40,
                icon: 'store',
                title: 'Merchants',
                permission: { '/merchants': ['get'] },
                roles: ['Administrator'],
                hotkeys: {
                    combo: 'g m',
                    description: 'Go to merchants'
                }
            }
        }
    },
    {
        path: 'admin/merchant/:merchant_id',
        canActivate: [adminGuard],
        loadComponent: () => import('app/components/admin-merchant-detail/admin-merchant-detail.component').then(c => c.AdminMerchantDetailComponent),
        data: {
            settings: {
                title: 'Merchant',
                permission: { '/merchants/:id': ['get'] }
            }
        }
    },
    {
        path: 'admin/cms',
        loadComponent: () => import('app/components/admin-dashboard-cms/admin-dashboard-cms.component').then(c => c.AdminDashboardCMSComponent),
        canMatch: [adminGuard],
        data: {
            settings: {
                index: 10,
                icon: 'dashboard_customize',
                title: 'Dashboard CMS',
                roles: ['Administrator']
            }
        }
    },
    {
        path: 'admin/cms-edit',
        loadComponent: () => import('app/components/admin-dashboard-cms/admin-dashboard-cms-edit/admin-dashboard-cms-edit.component').then(c => c.DashboardCMSEditComponent),
        canMatch: [adminGuard],
        data: {
            settings: {
                roles: ['Administrator']
            }
        }
    }
];
