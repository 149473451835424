import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { exhaustMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DialogService } from '../../widgets/dialog/dialog.service';
import { PaymentLinkRepo } from '../../endpoints/invoice/payment-link-repo';
import { PaymentWindowComponent } from '../../components/settings/billing/payment-window/payment-window.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BillingService {
    constructor(
        private breakpointObserver: BreakpointObserver,
        private dialogService: DialogService,
        private sanitizer: DomSanitizer,
        private paymentLinkRepo: PaymentLinkRepo
    ) {}

    public createPaymentRepoLink(): any {
        const paymentLinks = {
            type: 'payment-links',
            attributes: {}
        };
        return this.paymentLinkRepo.create(paymentLinks).pipe(
            exhaustMap((data: { [key: string]: any }) =>
                this.createPaymentWindow(this.sanitizer.bypassSecurityTrustResourceUrl(data['attributes'].url))
            )
        );
    }

    public createPaymentWindow(url: SafeResourceUrl): Observable<any> {
        let config: { width: string; maxWidth: string; panelClass: string } = {
            width: '620px',
            maxWidth: '',
            panelClass: 'no-padding'
        };

        if (this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall])) {
            config = {
                width: '100%',
                maxWidth: '100vw',
                panelClass: 'no-padding'
            };
        }

        return this.dialogService.component(PaymentWindowComponent, { url }, config).afterClosed();
    }
}
