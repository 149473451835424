import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { WINDOW } from '@helpers/windowprovider/window-provider';
import { AgreementService } from '@services/agreement/agreement.service';
import { DialogService } from '@widgets/dialog/dialog.service';

@Injectable()
export class ErrorsHandler extends Sentry.SentryErrorHandler {
    private handlingError = false;

    constructor(
        private dialogService: DialogService,
        private ngZone: NgZone,
        private agreementService: AgreementService,
        @Inject(WINDOW) private window: Window
    ) {
        super();
    }

    public handleError(error: Error | HttpErrorResponse): void {
        super.handleError(error);

        if (error.name === 'ObjectUnsubscribedError') {
            return; // prevent rxjs subscription error
        }

        if (error.message.includes('NG0100:')) {
            // NG0100 error is only be visible in development mode, so we don't need to see an error dialog about it.
            console.warn(error.message);
            return;
        }

        if (error instanceof HttpErrorResponse && error.status === 401 && !this.agreementService.isOwner()) {
            // dont show unauthorized for non owners.
            console.warn(error.message);
            return;
        }

        if (!this.handlingError && error instanceof HttpErrorResponse && error.url && error.url.includes('status/api/v1/components')) {
            // A developer has been informed about this error.
        } else if (!this.handlingError) {
            this.handlingError = true;
            let message = '';

            if (error instanceof HttpErrorResponse) {
                const errorUrl = error.url ?? '';
                if (error.status === 404) {
                    // 404 is when a resource is not found and should not be forwarded to the user
                    return;
                } else if (errorUrl.includes('clearhaus')) {
                    message = this.clearhausErrorMessage(error.status, error.error);
                } else if (errorUrl.includes('github')) {
                    message = this.githubErrorMessage(error.error);
                } else if (/\/(api)/.exec(errorUrl)) {
                    message = this.qapiErrorMessage(error.error);
                } else if (/\/(invoicing|server|statistics)/.exec(errorUrl)) {
                    message = this.quickpayErrorMessage(error.error);
                } else {
                    message = error.message;
                }
            } else if (error.message.includes('NG0100')) {
                // this is the ExpressionChangedAfterItHasBeenCheckedError, which should not trigger a dialog.
                return;
            } else {
                message = $localize`Please contact support if you experience this error often`;
            }

            // https://github.com/angular/angular/issues/19984
            this.ngZone.run(() => {
                this.dialogService
                    .alert($localize`Error`, message, 'warning')
                    .afterClosed()
                    .subscribe(() => {
                        this.handlingError = false;
                    });
            });
        }
    }

    public clearhausErrorMessage(status: number, error: any): string {
        // https://developer.clearhaus.com/merchant-api/start/#error-handling
        let msg = '';

        switch (status) {
            case 400:
                msg = $localize`The request contains invalid parameters` + '\n';

                if (!Array.isArray(error.errors)) {
                    msg = Object.entries(error.errors).map(entry => `${entry[0]} ${entry[1]}`).toString();
                    return msg;
                }

                // Check if there is an existing application to avoid error message 'undefined'
                for (const err of error.errors) {
                    if (err.detail === 'merchant already has an application') {
                        msg = $localize`Merchant already has an application at Clearhaus`;
                        break;
                        /* eslint-disable */
                    } else if (err.detail === "Quickpay doesn't have account access to Clearhaus mpi_merchant_id: '2000005'") {
                        msg = $localize`Quickpay doesn't have account access to Clearhaus mpi_merchant_id: '2000005'`;
                        break;
                        /* eslint-enable */
                    } else if (err.detail === 'Clearhaus mpi_merchant_id is null') {
                        msg = $localize`Clearhaus merchant id is null`;
                    } else {
                        msg += `${err.source?.parameter ?? ''} ${err.detail ?? ''} \n`;
                    }
                }
                break;
            case 401:
                msg = error.detail + '\n';
                msg += 'oauth2-token: ' + error['oauth2-token'] + '\n';
                msg += 'Id: ' + error['request-id'];
                break;
            case 503:
                msg = 'Id: ' + error['request-id'];
                break;
        }

        return msg;
    }

    public githubErrorMessage(error: any): string {
        // https://developer.github.com/v3/#client-errors
        let msg = error.message;

        if (error.errors) {
            msg += '\n\n';

            for (const err of error.errors) {
                for (const key in err) {
                    if (err.hasOwnProperty(key)) {
                        const str = key + ' ' + err[key] + '\n';
                        msg += str;
                    }
                }
                msg += '\n';
            }
        }

        return msg;
    }

    public qapiErrorMessage(error: any): string {
        let message = error.message || '';

        if (error.errors) {
            message += '\n\n';
            for (const key in error.errors) {
                if (error.errors.hasOwnProperty(key)) {
                    const paramError = error.errors[key];
                    let paramMessage = '';
                    if (Array.isArray(paramError)) {
                        for (let i = 0; i < paramError.length; i++) {
                            if (i > 0) {
                                paramMessage += ', ';
                            }
                            paramMessage += paramError[i];
                        }
                    } else {
                        paramMessage = paramError;
                    }
                    message += key + ': ' + paramMessage + '\n';
                }
            }
        }

        return message;
    }

    public quickpayErrorMessage(error: any): string {
        let message = '';

        if (error.errors) {
            for (let i = 0; i < error.errors.length; i++) {
                const err = error.errors[i];

                if (i !== 0) {
                    message += '\n';
                }

                if (err.source) {
                    message += `${err.title}: <b>${err.source.parameter}</b> ${err.detail}`;
                } else if (err.error) {
                    // Manager-Server Viabill
                    message += err.error;
                } else {
                    // Unhandled thirdparty error
                    for (const key in err) {
                        if (err.hasOwnProperty(key)) {
                            message += err[key];
                        }
                    }
                }
            }
        }

        return message;
    }
}
