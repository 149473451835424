import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SearchByOptions } from '@interfaces/params';
import { ScreenSizeService } from '@services/screen-size/screen-size.service';

@Component({
    selector: 'qp-search-field',
    template: ''
})
export class SearchFieldComponent {
    @Input() public placeholder: string;
    @Input() public searchBy: string;
    @Input() public searchByOptions: SearchByOptions[] | undefined;
    @Input() public autoFocus = false;
    @Input() public tooltip: string;
    @Output() public searchInvoked: EventEmitter<any> = new EventEmitter();
    @Output() public showSearchChange: EventEmitter<any> = new EventEmitter();
    @Output() public searchChange: EventEmitter<any> = new EventEmitter();
    public timeout: any;
    public searchId: string;
    public focus = false;

    public showSearchValue = false;
    public searchValue: string;

    constructor(
        protected focusMonitor: FocusMonitor,
        protected media: ScreenSizeService
    ) {}

    @Input()
    public get showSearch(): boolean | undefined {
        return this.showSearchValue;
    }
    public set showSearch(val: boolean | undefined) {
        this.showSearchValue = val ?? false;
        this.showSearchChange.emit(this.showSearchValue);
    }

    @Input()
    public get search(): string | undefined {
        return this.searchValue ?? '';
    }
    public set search(val: string | undefined) {
        this.searchValue = val ? val.trim() : '';
        this.searchChange.emit(this.searchValue);
    }

    public onFocus(): void {
        this.focus = true;
    }

    public onBlur(): void {
        this.timeout = setTimeout(() => {
            this.focus = false;
        }, 150);
    }

    public invokeSearch(): void {
        this.searchInvoked.emit({ search: this.search, search_by: this.searchBy });
    }

    public getId(preFix: string): string {
        return preFix + this.searchId;
    }

    public uniqueNumber(): number {
        return Date.now();
    }
}
