<form [formGroup]="bankForm" class="flex-col rg8">
    <span class="text-grey" i18n>Why do we ask about this? With Clearhaus you can accept money from your customers in any currency.</span>
    <span class="text-grey"><span i18n>Clearhaus can pay you in the following currencies:</span> DKK, EUR, SEK, NOK, GBP, USD.</span>

    <mat-form-field>
        <mat-label i18n>Account currency</mat-label>
        <mat-select formControlName="currency" (selectionChange)="onCurrencyChange($event.value)" required>
            <mat-option *ngFor="let currency of currencies" [value]="currency.name"> {{ currency.name }} </mat-option>
        </mat-select>
        <mat-error *ngIf="currency.errors?.required" i18n>An account currency is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Bank</mat-label>
        <input matInput name="bank_account_bank" formControlName="bank" type="text" (change)="modelBankAccount('bank', $event.target)" required />
        <mat-error *ngIf="bank.errors?.required" i18n>A bank is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>SWIFT (BIC) code</mat-label>
        <input matInput name="bank_swift_code" formControlName="swift_code" type="text" (change)="modelBankAccount('swift_code', $event.target)" required />
        <mat-error *ngIf="swift_code.errors?.required" i18n>A swift code is required.</mat-error>
        <mat-error *ngIf="swift_code.errors?.pattern" i18n>Swift code invalid.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>IBAN number</mat-label>
        <input matInput name="bank_account_iban" formControlName="iban" type="text" (change)="modelBankAccount('iban', $event.target)" required />
        <mat-error *ngIf="iban.errors?.required" i18n>An IBAN code is required.</mat-error>
        <mat-error *ngIf="bankForm.errors?.ibanError" i18n>IBAN code invalid.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="bankForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
