import { Client } from '../client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class GithubApiClient extends HttpClient {}

/* eslint-disable */
export function githubApiClientFactory(httpClient: HttpClient) {
    return new Client(httpClient, 'https://api.github.com', {});
}
