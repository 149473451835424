export class Customer {
    public id: number;
    public type = 'customers';
    public attributes: Attributes;
}

export interface Attributes {
    account_id?: number;
    account_type?: string;
    billing_info?: BillingInfo;
    payment_method?: any;
    ean_number?: string;
    requisition_number?: string;
    invoice_emails: string[];
    receive_headsup_email?: boolean;
    reseller_id?: number;
    subscription_info?: any;
    to?: any;
    on_hold_since?: string;
}

interface BillingInfo {
    graced_to?: Date;
    payment_method?: string;
    subscription_info?: any;
    updated_at?: Date;
    user_email?: string;
    user_id?: number;
}
