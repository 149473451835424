<form class="form" role="form" name="terms">
    <div mat-dialog-content class="flex-col rg16 grey-border-bottom">
        <h5 i18n>Add credit card</h5>
        <span class="text-grey" i18n> The billing period will begin when you add the credit card </span>
        <div class="flex-row justify-start items-center">
            <mat-checkbox [checked]="accept" (change)="accept = !accept"></mat-checkbox>
            &nbsp;<span i18n>I have read and accepted the</span>&nbsp;
            <a href="https://quickpay.net/dk/terms-of-service" target="_blank" i18n> terms </a>
        </div>
        <span i18n> The card fee will be surcharged for corporate cards. The fee will be itemised on the invoices. </span>
    </div>

    <div mat-dialog-actions class="flex-row">
        <span class="flex-1"></span>
        <button mat-button (click)="dialogRef.close(false)" i18n>Close</button>
        <button mat-button type="submit" color="accent" [disabled]="!accept" (click)="acceptTerms()" i18n>
            Add credit card
        </button>
    </div>
</form>
