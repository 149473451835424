export const ADMINSUPPORT_PERMISSIONS = [
    {
        resource: '/account',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acl-resources',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/activity',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/api-key',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/backends',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/backends/available',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/binbase',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/binbase.json',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/hidden-data',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/operations/:operation_id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/changelog',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id/certificate',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id/csr',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id/public-key',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-subscriptions',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-subscriptions/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-topics',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/import/merchants/:id/payments',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/import/merchants/:id/subscriptions',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/import/payments/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/import/subscriptions/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/import/transactions/:id/operations',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/merchants/:id/agreements/:agreement_id/api-key',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants/:id/cryptography/keys/:key_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants/:id/move-to-reseller',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/merchants/:id/private-key',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/merchants/:id/subscription-groups',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/operational-status/acquirers',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/import',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/ping',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/remote-agreement',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/resellers',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/resellers/:id',
        get: true,
        post: false,
        put: false,
        delete: true,
        patch: true
    },
    {
        resource: '/resellers/:id/agreements',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/resellers/:id/suspend',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/subscriptions/:id/import',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions/:id',
        get: true,
        post: false,
        put: false,
        delete: true,
        patch: true
    },
    {
        resource: '/transactions/:id/acquirer-data',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/transactions/:id/callbacks',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions/:id/export',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions/:id/hidden-data',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: true
    },
    {
        resource: '/transactions/:id/migrate',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions/:id/operation',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions/:id/operation/:operation_id',
        get: false,
        post: false,
        put: false,
        delete: true,
        patch: true
    },
    {
        resource: '/transactions/:id/operation/:operation_id/finalize',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/transactions/:id/payments',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/users',
        get: true,
        post: true,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/users/:id',
        get: true,
        post: false,
        put: false,
        delete: true,
        patch: false
    },
    {
        resource: '/users/:id/agreements',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/users/:id/send-activation-mail',
        get: false,
        post: true,
        put: false,
        delete: false,
        patch: false
    }
];
