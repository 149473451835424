export const PSD2_REQUIRED_COUNTRIES = [
    'AUT',
    'BEL',
    'BGR',
    'CYP',
    'CZE',
    'DEU',
    'DNK',
    'ESP',
    'EST',
    'FIN',
    'FRA',
    'GBR',
    'GRC',
    'HRV',
    'HUN',
    'IRL',
    'ISL',
    'ITA',
    'LTU',
    'LUX',
    'LVA',
    'MLT',
    'NLD',
    'NOR',
    'POL',
    'PRT',
    'ROU',
    'SVK',
    'SVN',
    'SWE'
];
