import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-dialog-alert',
    templateUrl: 'alert.html',
    styleUrls: ['../dialog.scss']
})
export class AlertDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            headline: string;
            headline_icon: string;
            msg: string;
        }
    ) {}
}
