<mat-card class="flex-col rg36 card02">
    <div class="flex-col rg8">
        <qp-section-heading headingText="Invoice notifications" icon="email" i18n-headingText></qp-section-heading>
        <mat-card-subtitle class="text-grey" i18n>
            Your invoice is sent to the email(s) listed here. If none is added, the invoice is sent to your merchant contact email.
        </mat-card-subtitle>

        <mat-card-content class="flex-col rg16">
            <div
                class="flex-row cg8 justify-start stretch"
                *ngFor="let email of customer?.attributes?.invoice_emails; let i = index"
            >
                <button mat-button class="mat-icon-button" (click)="removeEmail(i)" i18n-matTooltip matTooltip="Remove email from list">
                    <mat-icon class="text-black">delete</mat-icon>
                </button>
                <span class="justify-start items-center">{{ email }}</span>
            </div>

            <div>
                <button mat-raised-button (click)="addEmail()" i18n>
                    Add email
                </button>
            </div>
        </mat-card-content>
    </div>

    <div class="flex-col rg8">
        <qp-section-heading headingText="Invoice notice" icon="notifications" i18n-headingText></qp-section-heading>
        <mat-card-subtitle class="text-grey" i18n>
            Receive an email a week before the invoice date with a preliminary balance.
        </mat-card-subtitle>
        <div class="flex-row">
            <mat-slide-toggle *ngIf="customer" [(ngModel)]="customer.attributes.receive_headsup_email" (change)="save()" (checked)="(false)" i18n>
                Receive invoice heads up
            </mat-slide-toggle>
        </div>
    </div>
</mat-card>
