<div class="flex-row no-print">
    <div class="flex-100">
        <button mat-raised-button *ngIf="permission && !transaction?.metadata?.fraud_reported" (click)="onReportFraud()" i18n>
            Report fraud
        </button>
        <div class="flex-row" *ngIf="transaction?.metadata?.fraud_reported">
            <div class="flex-1">
                <div class="flex-row">
                    <span class="subtitle1" i18n>Fraud reported</span>
                    <mat-icon class="s18 text-darkred"> verified_user </mat-icon>
                </div>
                <span *ngIf="transaction?.metadata?.fraud_reported">
                    {{ transaction.metadata.fraud_report_description }}
                </span>
            </div>
        </div>
    </div>
</div>
