import { ApiClient } from './api-client';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaymentMethodPriority } from '@interfaces/payment-method-priority';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodPriorityRepo {
    constructor(private client: ApiClient) {}

    public get(): Observable<PaymentMethodPriority> {
        return this.client.get<PaymentMethodPriority>('/payment-method-priority').pipe(
            map((item: PaymentMethodPriority) => Object.assign(item))
        );
    }

    public update(data: PaymentMethodPriority): Observable<PaymentMethodPriority> {
        return this.client.post<PaymentMethodPriority>('/payment-method-priority/', data).pipe(
            map((item: PaymentMethodPriority) => Object.assign(item))
        );
    }
}
