import { ApiClient } from './api-client';
import { Card } from '@models/card';
import { FraudRuleset } from '@interfaces/fraud-ruleset';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FraudRulesetRepo {
    constructor(private client: ApiClient) {}

    public getAll(params?: any): Observable<FraudRuleset[]> {
        params = params || {};
        return this.client.get<FraudRuleset[]>('/fraud-rulesets', { params });
    }

    public get(id: number): Observable<FraudRuleset> {
        return this.client.get<FraudRuleset>('/fraud-rulesets/' + id);
    }

    public update(fraudRuleset: FraudRuleset): Observable<FraudRuleset> {
        return this.client.patch<Card>('/fraud-rulesets/' + fraudRuleset.id, fraudRuleset);
    }

    public create(params: any): Observable<FraudRuleset> {
        params = params || {};
        return this.client.post<FraudRuleset>('/fraud-rulesets', params);
    }

    public destroy(id: number): Observable<FraudRuleset> {
        return this.client.delete<FraudRuleset>('/fraud-rulesets/' + id);
    }
}
