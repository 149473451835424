import { FilterParams } from '@interfaces/params';
import { FilterService } from '@widgets/filters/filters.service';

export class FiltersMixin {
    public filters: FilterParams;
    public filtersService: FilterService;

    public getPage: () => void;

    public showFilterDialog(type: string): void {
        this.filtersService.show(this.filters, type).afterClosed().subscribe(result => {
            if (result) {
                this.filters = this.filtersService.parseFilters(this.filters, result);
                this.getPage();
            }
        });
    }

    public resetFilter(type: string): void {
        this.filters[type as keyof FilterParams] = undefined;
        this.getPage();
    }
}
