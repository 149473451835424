<div *ngIf="payment && !loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row no-print" *ngIf="show_toolbar">
        <button mat-button [routerLink]="['/account', agreement?.account?.id, 'subscriptions']" *ngIf="subscriptionId" class="mat-button-back" color="accent">&lt; <span i18n>Back to Subscription</span> {{ subscriptionId }}</button>
        <button mat-button [routerLink]="['/account', agreement?.account?.id, 'payments']" *ngIf="!subscriptionId" class="mat-button-back" color="accent">&lt; <span i18n>Back to Payments</span></button>
        <span class="flex-1"></span>

        <div class="flex-row items-center cg8">
            <mat-spinner *ngIf="showSpinner" [diameter]="30"></mat-spinner>
            <button mat-raised-button [routerLink]="['../../support']" [queryParams]="{ payment_id: payment.id }" *ngIf="agreement && agreement?.account?.reseller?.support_email" i18n>Support</button>
            <mat-menu class="flex-col" #paymentMenu="matMenu">
                <button mat-menu-item [routerLink]="['../../tools/auditlog']" [queryParams]="{ target_type: 'Payment', target_id: payment.id }" i18n>Audit log</button>
                <button mat-menu-item (click)="showJSON()" i18n>json</button>
                <button mat-menu-item [useExistingCss]="true" printSectionId="print-section" ngxPrint i18n>Print</button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="paymentMenu">
                <mat-icon color="primary">more_vert</mat-icon>
            </button>
            <span></span>
        </div>
    </div>

    <div class="flex-row-mobile-col" id="print-section">
        <div class="flex-35-mobile-100">
            <mat-card class="card02" *ngIf="!payment.retented_at">
                <mat-card-content>
                    <ng-container *ngIf="payment.state !== 'initial' && payment.metadata?.type === 'card'">
                        <qp-transaction-details-card-basic (callBack)="getPayment()" [permission]="(['/payments/:id/fraud-report', 'get'] | qpPermission)" [transactionRepo]="paymentRepo" [transaction]="payment"> </qp-transaction-details-card-basic>
                    </ng-container>
                    <ng-container *ngIf="payment.metadata?.type !== 'card'">
                        <qp-transaction-details-other-basic (callBack)="getPayment()" [permission]="(['/payments/:id/fraud-report', 'get'] | qpPermission)" [transactionRepo]="paymentRepo" [transaction]="payment"> </qp-transaction-details-other-basic>
                    </ng-container>
                </mat-card-content>
            </mat-card>
            <qp-transaction-details-retended [transaction]="payment"></qp-transaction-details-retended>
            <qp-transaction-details-acquirer [transaction]="payment" [branding]="branding"></qp-transaction-details-acquirer>
        </div>

        <div class="flex-1">
            <mat-card class="card02 avoid-page-break">
                <mat-card-content class="flex-col rg16">
                    <div class="flex-col">
                        <mat-card-title class="font-weight-normal" i18n>Payment created</mat-card-title>

                        <div class="flex-row flex-wrap cg12 items-center print">
                            <div class="flex-col">
                                <p class="subtitle1 hide-lt-sm" i18n>Created at</p>
                                <p class="subtitle1 no-print hide-gt-xs" i18n>Created</p>
                                <p class="hide-lt-sm">{{ payment.created_at | date:'dd-MM-yyyy HH:mm:ss' }}</p>
                                <p class="no-print hide-gt-xs">{{ payment.created_at | date:'dd/MM yyyy HH:mm' }}</p>
                            </div>

                            <div class="flex-col">
                                <p class="subtitle1 hide-lt-sm" i18n>Payment ID</p>
                                <p class="subtitle1 no-print hide-gt-xs" i18n>Paym.</p>
                                <p>{{ payment.id }}</p>
                            </div>

                            <div class="flex-col">
                                <p class="subtitle1 hide-lt-sm" i18n>Order ID</p>
                                <p class="subtitle1 no-print hide-gt-xs" i18n>Order</p>
                                <p>{{ payment.order_id }}</p>
                            </div>

                            <div class="flex-col">
                                <p class="subtitle1" i18n>Currency</p>
                                <p>{{ payment.currency }}</p>
                            </div>

                            <div class="flex-col" *ngIf="payment.subscription_id">
                                <p class="subtitle1 hide-lt-sm" i18n>Sub. ID</p>
                                <p class="subtitle1 no-print hide-gt-xs" i18n>Sub.</p>
                                <a [routerLink]="['/account', agreement?.account?.id, 'subscription', payment.subscription_id]" [queryParams]="{ payment_id: payment.id}" *ngIf="payment.subscription_id && !subscriptionId"> {{ payment.subscription_id }} </a>
                                <a [routerLink]="['/account', agreement?.account?.id, 'subscription', payment.subscription_id]" [queryParams]="previousParams" *ngIf="subscriptionId"> {{ payment.subscription_id }} </a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="payment?.operations?.length">
                        <mat-card-title class="font-weight-normal" i18n>Operations</mat-card-title>
                        <div>
                            <qp-operations *ngIf="payment.id" [currency]="payment.currency" [transactionId]="payment.id" [transactionState]="payment.state" [operations]="payment.operations" [acquirer]="payment.acquirer" mat-card-image> </qp-operations>
                        </div>
                    </div>

                    <div *ngIf="payment.state === 'initial' && payment.link">
                        <mat-card-title class="font-weight-normal" i18n>Payment link</mat-card-title>
                        <div class="flex-row cg20p hide.lt.sm">
                            <div class="flex-col">
                                <span class="subtitle1" i18n>Amount</span>
                                <span>{{ payment.currency }}: {{ payment.link.amount | qpCurrency: payment.currency }}</span>
                            </div>
                            <div class="flex-col">
                                <span class="subtitle1" i18n>Payment methods</span>
                                <span>{{ payment.link.payment_methods }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row cg8 no-print">
                        <button mat-raised-button (click)="onUpdateStatus()" *ngIf="payment.state === 'pending' && payment.acquirer === 'mobilepaysubscriptions'" i18n>UDPATE STATUS</button>
                        <button mat-raised-button (click)="onCapture()" *ngIf="(['/payments/:id/capture', 'post'] | qpPermission) && is_capturable" i18n>Capture</button>
                        <button mat-raised-button *ngIf="(['/payments/:id/refund', 'post'] | qpPermission) && is_refundable" (click)="refund()" i18n>Refund</button>
                        <button mat-raised-button (click)="onCancel()" *ngIf="(['/payments/:id/cancel', 'post'] | qpPermission) && is_cancelable" i18n>Cancel</button>
                        <button mat-raised-button (click)="onRetryFailedPayment()" *ngIf="(payment.state === 'rejected' || payment.state === 'initial') && payment.link" i18n>Get link</button>
                        <button mat-raised-button *ngIf="payment.state === 'initial' && payment.link" (click)="onEditPaymentLink()" i18n>Edit link</button>
                        <button mat-raised-button *ngIf="payment.state === 'initial' && payment.link" class="text-darkred" (click)="onDeletePaymentLink()" i18n>Delete link</button>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="card02" *ngIf="payment?.settlement">
                <mat-card-title i18n>Settlement</mat-card-title>
                <mat-card-content>
                    <table class="info-table">
                        <tr>
                            <td i18n>Amount gross</td>
                            <td>{{ payment.settlement?.amount_gross | qpCurrency: payment.settlement?.currency }} {{ payment.settlement?.currency }}</td>
                        </tr>
                        <tr>
                            <td i18n>Fees</td>
                            <td class="font-weight-normal">{{ payment.settlement?.fees | qpCurrency: payment.settlement?.currency }} {{ payment.settlement?.currency }}</td>
                        </tr>
                        <tr>
                            <td i18n>Amount net</td>
                            <td>{{ payment.settlement?.amount_net | qpCurrency: payment.settlement?.currency }} {{ payment.settlement?.currency }}</td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>

            <qp-transaction-details-variables [transaction]="payment"> </qp-transaction-details-variables>
            <qp-transaction-details-customer-info [transaction]="payment" [is_delete_enabled]="true" (transactionChange)="updatePayment($event)"> </qp-transaction-details-customer-info>
            <qp-transaction-details-basket [transaction]="payment"></qp-transaction-details-basket>
        </div>
    </div>
</div>
