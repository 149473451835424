import { Animations } from 'app/animations/animations';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Dispute } from '@models/clearhaus-dispute';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'qp-disputes-table',
    templateUrl: './table-disputes.html',
    animations: [Animations.getFadeAnimation()]
})
export class DisputesTableComponent extends BaseLoadingDirective implements OnInit {
    @Input() public dataSource: MatTableDataSource<Dispute>;
    @Input() public pageSize: number;
    @Input() public page: number;
    @Input() public set displayColumns(value: string) {
        const baseColumns = ['date', 'reference', 'amount', 'type', 'reason'];
        this.columns = baseColumns;
        if (value !== '') {
            this.columns.push(value);
        }
    }
    @Output() public getPage: EventEmitter<any> = new EventEmitter<any>();

    public columns: Array<string> = [];

    constructor() {
        super();
    }

    public ngOnInit(): void {
        if (this.dataSource.data.length > 0) {
            this.dataSource.data.forEach(dispute => {
                if (!!dispute.reason) {
                    dispute.reason = dispute.reason.split('_').join(' ');
                    dispute.reason = dispute.reason.charAt(0).toUpperCase() + dispute.reason.slice(1);
                }

                if (!!dispute.outcome) {
                    dispute.outcome = dispute.outcome.split('_').join(' ');
                    dispute.outcome = dispute.outcome.charAt(0).toUpperCase() + dispute.outcome.slice(1);
                }
            });
        }
    }
}
