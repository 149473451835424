<div *ngIf="address && valuesNotEmpty()" class="flex-col avoid-page-break">
    <table class="card-table">
        <thead>
            <tr>
                <th i18n>Variable</th>
                <th i18n>Value</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let entry of address | qpToArray">
                <tr class="border-bottom" *ngIf="!!entry.value">
                    <td class="capitalize">{{ entry?.name || '' }}</td>
                    <td>{{ entry?.value || '' }}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
