import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnsService } from '@services/columns/columns.service';

@Component({
    selector: 'qp-filter-dialog',
    templateUrl: 'dialog.html'
})
export class ColumnsSettingsDialogComponent implements OnInit {
    public data: any;
    public dateFormats: any;
    public dateFormatsKeys: any[] = [];
    public dataKeys: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<ColumnsSettingsDialogComponent>,
        private columnService: ColumnsService,
        @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) {}

    public ngOnInit(): void {
        this.dateFormats = this.columnService.getDateFormats();
        this.dateFormatsKeys = Object.keys(this.dateFormats);
        this.data = Object.assign({}, this.columnService.getColumns(this.dialogData.table));
        this.dataKeys = Object.keys(this.data);
    }

    public saveAndClose(): void {
        this.columnService.setColumns(this.dialogData.table, this.data);
        this.dialogRef.close();
    }
}
