import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Base64 } from '@services/base64/base64';
import { FileService } from '@services/file/file.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { BrandingRepo } from 'app/endpoints/api/branding-repo';

@Component({
    selector: 'qp-branding-edit',
    styleUrls: ['./edit.scss'],
    templateUrl: 'edit.html',
    animations: [Animations.getFadeAnimation()]
})
export class BrandingEditComponent extends BaseLoadingDirective implements OnInit, AfterViewInit {
    @ViewChild('codeEditor') public codeEditor: ElementRef;
    @ViewChild('lineCounter') public lineCounter: ElementRef;
    public line_count_cache = 0;
    public branding_id: number;
    public data: any;
    public extension: string;
    public filename: string;

    constructor(
        private base64: Base64,
        private brandingRepo: BrandingRepo,
        private qpFile: FileService,
        private snackBar: QpSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    public ngOnInit(): void {
        this.filename = this.activatedRoute.snapshot.queryParams.file.replace(/%2F/g, '/');
        this.branding_id = this.activatedRoute.snapshot.queryParams.branding_id.replace(/%2F/g, '/');
        this.extension = this.filename.split('.').pop()?.toLowerCase() ?? '';
        this.downloadFile();
    }

    public ngAfterViewInit(): void {
        this.codeEditor.nativeElement.addEventListener('scroll', () => {
            this.lineCounter.nativeElement.scrollTop = this.codeEditor.nativeElement.scrollTop;
            this.lineCounter.nativeElement.scrollLeft = this.codeEditor.nativeElement.scrollLeft;
        });

        this.codeEditor.nativeElement.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'Tab') {
                e.preventDefault();
                const { value, selectionStart, selectionEnd } = this.codeEditor.nativeElement;
                this.codeEditor.nativeElement.value = value.slice(0, selectionStart) + '\t' + value.slice(selectionEnd);
                this.codeEditor.nativeElement.setSelectionRange(selectionStart + 2, selectionStart + 2);
            }
        });

        this.codeEditor.nativeElement.addEventListener('input', () => {
            this.line_counter();
        });
    }

    public line_counter(): void {
        const lineCount = this.codeEditor.nativeElement.value.split('\n').length;
        const code_editor_height = lineCount * 14.5 + 18.5;
        const outarr = new Array();

        if (this.line_count_cache !== lineCount) {
            for (let x = 0; x < lineCount; x++) {
                outarr[x] = (x + 1) + '.';
            }
            this.lineCounter.nativeElement.value = outarr.join('\n');
        }

        this.line_count_cache = lineCount;
        this.codeEditor.nativeElement.style.height = `${code_editor_height}px`;
        this.lineCounter.nativeElement.style.height = `${code_editor_height}px`;
    }

    public downloadFile(): void {
        this.brandingRepo.getResource(this.branding_id, this.filename, 'text').subscribe((data: any) => {
            if (this.extension === 'json') {
                this.data = JSON.stringify(JSON.parse(data), null, 4);
            } else {
                this.data = data;
            }

            this.codeEditor.nativeElement.value = this.data;
            this.line_counter();
        });
    }

    public save(): void {
        const data = this.extension === 'json' ? JSON.stringify(JSON.parse(this.data)) : this.data;
        const mimeType = this.qpFile.getMimeType(this.extension);
        const blob = this.qpFile.b64toBlob(this.base64.encodeUnicode(data), mimeType, 512);

        this.brandingRepo.putResource(this.branding_id, this.filename, blob).subscribe(() => {
            this.snackBar.open($localize`${this.filename} is updated`);
            this.router.navigate(['../branding', this.branding_id]);
        });
    }
}
