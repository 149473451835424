<h5 i18n>Visa and Mastercard Transactions</h5>
<div *ngIf="loading_state.isLoading$ | async" class="flex-row justify-center">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="loading_state.isIdle$ | async as is_idle" [@fade_animation]="is_idle ? 'fadeIn' : null">
    <table
        mat-table
        [dataSource]="data_source">

    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef i18n>Order ID</th>
        <td mat-cell *matCellDef="let row">{{ row.reference }}</td>
    </ng-container>

    <ng-container matColumnDef="processed_at">
        <th mat-header-cell *matHeaderCellDef i18n>Processed at</th>
        <td mat-cell *matCellDef="let row">
            <qp-date-field [format]="'MMM dd, yyyy'" [date]="row.processed_at"></qp-date-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="payment_method">
        <th mat-header-cell *matHeaderCellDef i18n>Scheme</th>
        <td mat-cell *matCellDef="let row">{{ row.card.scheme | qpBrand }}</td>
    </ng-container>

    <ng-container matColumnDef="card">
        <th mat-header-cell *matHeaderCellDef i18n>Card</th>
        <td mat-cell *matCellDef="let row">{{ row.card.last4 | qpBrand }}</td>
    </ng-container>

    <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Currency</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">{{ row.settlement.currency }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount_gross">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Amount gross</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">{{ row.settlement.amount_gross | qpCurrency: row.settlement.currency }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="fee">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Fee</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">{{ row.settlement.fees | qpCurrency: row.settlement.currency }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount_net">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Amount net</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">{{ row.settlement.amount_net | qpCurrency: row.settlement.currency }}</div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="display_columns"></tr>
    <tr mat-row *matRowDef="let row; columns: display_columns;" (click)="goToPayment(row)">
    </tr>
    </table>
    <qp-paginator [config]="paginator_options" [data_length]="data_source.data.length" (pageChange)="onPageChange($event)"> </qp-paginator>
</div>

