<button mat-raised-button *ngIf="isButton; else noButton" [disabled]="(['Reseller'] | qpAccountType)" (click)="preview()" i18n>PREVIEW</button>

<ng-template #noButton>
    <button mat-menu-item [disabled]="(['Reseller'] | qpAccountType)" (click)="preview()">
        <mat-icon>remove_red_eye</mat-icon>
        <span i18n>PREVIEW</span>
    </button>
</ng-template>

<ng-template #openIframe>
    <iframe [src]="link" width="100%" height="650"></iframe>
</ng-template>
