<form [formGroup]="companyForm" class="flex-col rg8">
    <mat-form-field>
        <mat-label i18n>Country</mat-label>
        <mat-select name="company_country" formControlName="country" (selectionChange)="modelCompany('country', $event.value)" required>
            <ng-container *ngFor="let country of supportedCountries">
                <mat-option [value]="country.code"> {{ country.name }} </mat-option>
            </ng-container>
        </mat-select>
        <mat-error *ngIf="country.errors?.required" i18n>A country is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Company name</mat-label>
        <input matInput name="company_name" formControlName="name" type="text" (change)="modelCompany('name', $event.target)" required />
        <mat-error *ngIf="name.errors?.required" i18n>A name is required.</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="company.country === 'DNK'">
        <mat-label i18n>Company form</mat-label>
        <mat-select name="company_form" formControlName="form" (selectionChange)="onCompanyFormChange($event.value)" required>
            <mat-option value="Other"> <span i18n>Other</span> (ApS, A/S, I/S, ...) </mat-option>
            <mat-option value="Forening" i18n>Association</mat-option>
            <mat-option value="Entrepreneurial"> <span i18n>Entrepreneurial company</span> (IVS) </mat-option>
        </mat-select>
        <mat-error *ngIf="form.errors?.required" i18n>A company form is required for Danish companies.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Address</mat-label>
        <input matInput name="company_address_line_1" formControlName="address" type="text" (change)="modelCompany('address_line_1', $event.target)" required />
        <mat-error *ngIf="address.errors?.required" i18n>An address is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Zip code</mat-label>
        <input matInput name="company_zipcode" formControlName="zipcode" type="text" (change)="modelCompany('zipcode', $event.target)" required />
        <mat-error *ngIf="zipcode.errors?.required" i18n>A Zip code is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>City</mat-label>
        <input matInput name="company_city" type="text" formControlName="city" (change)="modelCompany('city', $event.target)" required />
        <mat-error *ngIf="city.errors?.required" i18n>A city is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Registration number</mat-label>
        <input matInput #vat name="company_vat" formControlName="vat" type="text" (change)="modelCompany('registration_number', $event.target)" required />
        <mat-error *ngIf="vatControl.errors?.required" i18n>A registration number is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Phone</mat-label>
        <input matInput type="tel" formControlName="phone" name="company_phone" (change)="modelCompany('phone', $event.target)" required />
        <mat-error *ngIf="phone.errors?.required" i18n>A phone number is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Email</mat-label>
        <input matInput name="company_email" formControlName="email" type="text" (change)="modelCompany('email', $event.target)" required />
        <mat-error *ngIf="email.errors?.required" i18n>An email address is required.</mat-error>
        <mat-error *ngIf="email.errors?.email" i18n>Invalid email address.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="companyForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
