import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Account } from '@interfaces/account';
import { Customer } from '@models/customer';
import { AgreementService } from '@services/agreement/agreement.service';

export interface AddOtherPaymentMethodData {
    customer: Customer;
}

@Component({
    selector: 'qp-add-other-payment-method',
    templateUrl: 'add-other-payment-method.component.html'
})
export class AddOtherPaymentMethodDialogComponent {
    public accept = false;
    public account: Account | undefined = this.agreementService.agreement?.account;
    public customer: Customer = this.data.customer;
    public payment_method: string;
    public paymentMethods = [
        { type: 'fi', name: $localize`Payment slip` },
        { type: 'ean', name: $localize`EAN invoice` },
        { type: 'betalingsservice', name: $localize`Nets Paymentservice` },
        { type: 'supplier_service', name: $localize`Supplier service` }
    ];

    constructor(
        public dialogRef: MatDialogRef<AddOtherPaymentMethodDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: AddOtherPaymentMethodData,
        private agreementService: AgreementService
    ) {}

    public acceptTerms(): void {
        this.dialogRef.close(this.customer);
    }

    public changePaymentMethod(payment_method: string): void {
        if (this.customer.attributes.billing_info?.payment_method) {
            this.customer.attributes.billing_info.payment_method = payment_method;
        } else {
            this.customer.attributes.billing_info = {
                ...this.customer.attributes.billing_info,
                payment_method
            };
        }
    }
}
