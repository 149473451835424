import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShopifyRepo } from '@endpoints/server/shopify-repo';
import { SearchParams } from '@interfaces/params';
import { AgreementService } from '@services/agreement/agreement.service';
import { SearchService } from '@services/search/search.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'qp-shopify-order-filter-tool',
    templateUrl: './shopify-order-filter-tool.html'
})
export class ShopifyFilterToolbarComponent implements OnInit {
    @Output() public actionComplete = new EventEmitter();
    @Input() public search_params: SearchParams;

    public is_shopify_merchant = false;

    constructor(
        private agreementService: AgreementService,
        private dialogService: DialogService,
        private searchService: SearchService,
        private shopifyRepo: ShopifyRepo
    ) {}

    public ngOnInit(): void {
        if (this.agreementService.hasPermission('/shopify-settings', 'get')) {
            this.shopifyRepo.getApps().subscribe((apps: any) => {
                if (apps.length !== 0) {
                    this.is_shopify_merchant = true;
                }
            });
        }
    }

    public openOrderFilter(): void {
        const fields: PromptField[] = [
            {
                placeholder: 'Order number',
                label: $localize`Order number`,
                required: true,
                type: 'text',
                name: 'order_number',
                value: ''
            }
        ];

        this.dialogService.prompt(
            $localize`Search by Shopify id`,
            $localize`Paste in the id from Shopify e.g. "nBNG7anSJu4jj7c0BlK2mgssi"`,
            $localize`Filter`,
            fields
        ).afterClosed().pipe(
            filter(params => !!params),
            map((params: PromptField[]) => {
                const search = params[0].value.slice(- 20);
                if (search.length !== 20) {
                    this.dialogService.alert(
                        $localize`Wrong order id`,
                        $localize`Order id is too short, double check and try again`
                    );
                } else {
                    this.search_params = this.searchService.setSearchParams({ search_by: 'order_id', search: params[0].value.slice(- 20), state: 5 });
                    this.actionComplete.emit();
                }
            })
        ).subscribe();
    }
}
