<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null" class="width-780px">
    <mat-card class="flex-col rg32 card02">
        <div class="flex-col rg16">
            <div class="flex-col rg8">
                <qp-section-heading headingText="PSD2" icon="business_center" i18n-headingText></qp-section-heading>
                <mat-card-subtitle class="text-grey" i18n>
                    All payments above €30 and all new subscriptions should go through 3-D Secure to comply with PSD2.<br />
                    <a href="https://quickpay.net/dk/blog/payment-news/psd2-q-a/" target="_blank" *ngIf="(['Quickpay'] | qpMerchantType)">Read more on our blog.</a>
                </mat-card-subtitle>
            </div>
            <div class="flex-col rg16" *ngIf="!psd2Active; else psd2Added">
                <mat-card-subtitle class="text-grey" i18n>
                    Add PSD2 rulesets to automatically require all transactions above €30 and all new subscriptions to go through 3-D Secure.
                </mat-card-subtitle>
                <div>
                    <button mat-raised-button (click)="addPSD2Ruleset()" i18n>Add PSD2 rulesets</button>
                </div>
            </div>
        
            <ng-template #psd2Added>
                <div class="flex-row cg16 items-center">
                    <mat-icon class="text-green">check_circle</mat-icon>
                    <span i18n>PSD2 Rulesets added</span>
                </div>
            </ng-template>
        </div>

        <div class="bottom-border margin-negative" *ngIf="acquirer.active"></div>

        <div class="flex-col rg16" *ngIf="acquirer.active">
            <qp-section-heading headingText="Secured by Nets" icon="verified_user" i18n-headingText></qp-section-heading>

            <span class="text-grey marginleft-align" i18n>Secured by Nets is the 3-D Secure system for the Dankort.</span>

            <div class="flex-col rg16" *ngIf="!acquirer.secured_by_nets; else sbnAdded">
                <p i18n>
                    Secured by Nets is not enabled on this merchant. You will need to enable Secured by Nets to comply with PSD2.
                </p>
                <div>
                    <button mat-raised-button (click)="enableSecureByNets()" i18n>Enable Secured by Nets</button>
                </div>
            </div>

            <ng-template #sbnAdded>
                <div class="flex-row cg16 items-center">
                    <mat-icon class="text-green">check_circle</mat-icon>
                    <span i18n>Secured by Nets enabled</span>
                </div>
            </ng-template>
        </div>
    </mat-card>

    <mat-card class="flex-col rg16 card02">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Fraud filter" icon="security" i18n-headingText></qp-section-heading>
            <mat-card-subtitle class="text-grey">
                <span i18n>By adding fraud rulesets it is possible to flag, escalate to 3-D Secure or block transactions - filter by transaction and/or cardholder metrics.</span><br />
                <a [href]="helpdeskLink" target="_blank" *ngIf="theme.brand_name === 'Quickpay'" i18n>Read more on our helpdesk</a>.
            </mat-card-subtitle>
        </div>
        <ng-container *ngIf="rules.length">
            <h5 class="margin b0" i18n>Ruleset</h5>
            <mat-nav-list>
                <mat-list-item *ngFor="let rule of rules" [routerLink]="['../fraud-ruleset/edit', rule.id]">
                    <div matListItemTitle>{{ rule.name }}</div>
                    <div matListItemLine><span class="text-black">{{ readableActionName(rule.action ?? '') }} if (</span></div>
                    <div matListItemLine class="text-grey ml-4" *ngFor="let filter of rule.filter_rules; let last = last">
                        {{ readableMetricName(filter.metric_name ?? '') }} {{ filter.operator }}

                        <span *ngIf="filter.metric_name === 'amount_eur'"> {{ parseInt(filter.value) | qpCurrency: "EUR" }} </span>
                        <span *ngIf="filter.metric_name === 'request_ip_country_alpha3' || filter.metric_name === 'card_country_alpha3'"> {{ readableCountryLenght(filter.value) }} </span>
                        <span *ngIf="filter.metric_name === 'acquirer_id'"> {{ readableAcquirerName(filter.value) }} </span>
                        <span
                            *ngIf="filter.metric_name !== 'amount_eur' &&
                                    filter.metric_name !== 'card_country_alpha3' &&
                                    filter.metric_name !== 'request_ip_country_alpha3' &&
                                    filter.metric_name !== 'acquirer_id'"
                        >
                            {{ filter.value }}
                        </span>
                        <span *ngIf="!last" [ngClass]="dark_mode ? 'text-white' : 'text-black'">
                            {{ readableCombinator(rule.combinator ?? '') }}
                        </span>
                    </div>
                    <div matListItemLine><span class="text-black">)</span></div>
                </mat-list-item>
            </mat-nav-list>
        </ng-container>

        <div class="flex-col rg16 space-around items-start">
            <span>
                <mat-divider></mat-divider>
            </span>
            <button mat-raised-button color="accent" [routerLink]="['../fraud-ruleset/add']" i18n>Add new ruleset</button>
        </div>
    </mat-card>
</div>
