import { Injectable } from '@angular/core';
import { ShopifyApp, ShopifyAppExists, ShopifySetupParams } from '@models/integration/shopify-app';
import { Observable } from 'rxjs';
import { ApiClient } from '../api/api-client';
import { ServerClient } from './server-client';

@Injectable({
    providedIn: 'root'
})
export class ShopifyRepo {
    constructor(
        private apiClient: ApiClient,
        private client: ServerClient
    ) {}

    public setupShopify(params: ShopifySetupParams): Observable<any> {
        return this.client.post<ShopifyAppExists>('/shopify/setup', params);
    }

    public checkShopExistence(params: any): Observable<ShopifyAppExists> {
        return this.client.post<ShopifyAppExists>('/shopify/shop-exists', params);
    }

    public verifySignature(params: any): Observable<ShopifyAppExists> {
        return this.client.post<ShopifyAppExists>('/shopify/verify-signature', params);
    }

    public getApps(): Observable<Array<ShopifyApp>> {
        return this.apiClient.get<Array<ShopifyApp>>('/shopify-settings');
    }

    public deleteInstalledApp(id: number): Observable<any> {
        return this.client.delete(`/shopify/${id}`);
    }
}
