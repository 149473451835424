import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServerClient, serverClientFactory } from './server-client';
import { ClearhausClient, clearhausClientFactory } from './clearhaus-client';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: ClearhausClient,
            useFactory: clearhausClientFactory,
            deps: [HttpClient]
        },
        {
            provide: ServerClient,
            useFactory: serverClientFactory,
            deps: [HttpClient]
        }
    ]
})
export class ServerModule {}
