import { ActivateShopifyComponent } from './integrations/shopify/activate-shopify.component';
import { ApiModule } from 'app/endpoints/api/api.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { EconomicComponent } from './integrations/economic/economic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { LoyaltyKeyApplicationComponent } from './integrations/loyaltykey/application/loyaltykey-application.component';
import { LoyaltyKeyComponent } from './integrations/loyaltykey/loyaltykey.component';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';
import { ShopifyActivationComponent } from './integrations/shopify/shopify-activation.component';
import { ShopifyComponent } from './integrations/shopify/shopify.component';
import { ShopifyService } from './integrations/shopify/shopify.service';
import { ShopifySuccessComponent } from './integrations/shopify/shopify-success.component';
import { ThirdpartyComponent } from './thirdparty.component';
import { ThirdpartyShowComponent } from './show/thirdparty-show.component';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        NgOptimizedImage,
        ApiModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        SpinnerButtonModule,
        PipesModule,
        ReactiveFormsModule,
        RouterModule
    ],
    providers: [
        ShopifyService
    ],
    declarations: [
        ActivateShopifyComponent,
        EconomicComponent,
        LoyaltyKeyComponent,
        LoyaltyKeyApplicationComponent,
        ShopifyActivationComponent,
        ShopifyComponent,
        ShopifySuccessComponent,
        ThirdpartyComponent,
        ThirdpartyShowComponent
    ]
})
export class ThirdpartyModule {}
