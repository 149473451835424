import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export class Client {
    constructor(
        private httpClient: HttpClient,
        private urlPrefix: string = '',
        private defaultOptions: {
            headers?: HttpHeaders;
            responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
        } = {
            headers: new HttpHeaders()
        }
    ) {}

    public setHeader(name: string, value: string): void {
        if (!this.defaultOptions.headers) {
            return;
        }
        this.defaultOptions.headers = this.defaultOptions.headers.set(name, value);
    }

    public deleteHeader(name: string): void {
        if (!this.defaultOptions.headers) {
            return;
        }
        this.defaultOptions.headers = this.defaultOptions.headers.delete(name);
    }

    public setHeaders(optHeaders: HttpHeaders | { [header: string]: string | string[] }): HttpHeaders {
        let headers: HttpHeaders = this.defaultOptions.headers ?? new HttpHeaders();
        Object.keys(optHeaders).forEach((key: string) => {
            if (headers.has(key)) {
                headers = headers.delete(key);
            }
            if ((optHeaders as any)[key] !== undefined) {
                headers = headers.append(key, (optHeaders as any)[key]);
            }
        });
        return headers;
    }

    public delete(
        url: string,
        options: {
            headers?: HttpHeaders | { [header: string]: string | string[] };
            observe?: 'body' | 'events' | 'response';
            reportProgress?: boolean;
            responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
            withCredentials?: boolean;
        } = {}
    ): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return this.httpClient.delete(this.urlPrefix + url, { ...this.defaultOptions, ...options } as any);
    }

    public get(
        url: string,
        options: {
            headers?: HttpHeaders | { [header: string]: string | string[] };
            observe?: 'body' | 'events' | 'response';
            params?: HttpParams | { [param: string]: string | string[] };
            reportProgress?: boolean;
            responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
            withCredentials?: boolean;
        } = {}
    ): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return this.httpClient.get(this.urlPrefix + url, { ...this.defaultOptions, ...options } as any);
    }

    public patch(
        url: string,
        body: any | null,
        options: {
            headers?: HttpHeaders | { [header: string]: string | string[] };
            observe?: 'body' | 'events' | 'response';
            params?: HttpParams | { [param: string]: string | string[] };
            reportProgress?: boolean;
            responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
            withCredentials?: boolean;
        } = {}
    ): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return this.httpClient.patch(this.urlPrefix + url, body, { ...this.defaultOptions, ...options } as any);
    }

    public post(
        url: string,
        body: any | null,
        options: {
            headers?: HttpHeaders | { [header: string]: string | string[] };
            observe?: 'body' | 'events' | 'response';
            params?: HttpParams | { [param: string]: string | string[] };
            reportProgress?: boolean;
            responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
            withCredentials?: boolean;
        } = {}
    ): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return this.httpClient.post(this.urlPrefix + url, body, { ...this.defaultOptions, ...options } as any);
    }

    public put(
        url: string,
        body: any | null,
        options: {
            headers?: HttpHeaders | { [header: string]: string | string[] };
            observe?: 'body' | 'events' | 'response';
            params?: HttpParams | { [param: string]: string | string[] };
            reportProgress?: boolean;
            responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
            withCredentials?: boolean;
        } = {}
    ): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return this.httpClient.put(this.urlPrefix + url, body, { ...this.defaultOptions, ...options } as any);
    }
}
