import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Acquirers } from '@models/acquirers/acquirers';
import { AcquirerService } from '@services/acquirer/acquirer.service';

export const cardGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    const acquirerService = inject(AcquirerService);
    const router = inject(Router);
    const tpl = next.data.acquirer.tpl as keyof Acquirers;

    if (acquirerService.acquirers && !!acquirerService.acquirers[tpl]) {
        return true;
    } else {
        const url_fragments = state.url.split('/');
        url_fragments.pop();
        url_fragments.pop();
        url_fragments.push('acquirers');
        router.navigate([`/${url_fragments.join('/')}`]);
        return false;
    }
};
