import { AfterViewInit, ApplicationRef, Component, HostListener, Inject } from '@angular/core';
import { AgreementService } from '@services/agreement/agreement.service';
import { CredentialsService } from '@services/credentials/credentials.service';
import { SessionService } from '@services/session/session.service';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { concat, interval } from 'rxjs';
import { environment } from './../../environments/environment';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QpAccountTypePipe } from '@pipes/qp-account-type.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@helpers/windowprovider/window-provider';
import { SwUpdate } from '@angular/service-worker';

@Component({
    selector: 'qp-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.scss']
})
export class AppComponent implements AfterViewInit {
    public shortcuts: ShortcutInput[] = [];
    constructor(
        private sessionService: SessionService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private agreementService: AgreementService,
        private credentialsService: CredentialsService,
        private appRef: ApplicationRef,
        private snackbar: MatSnackBar,
        private swUpdate: SwUpdate,
        @Inject(WINDOW) private window: Window
    ) {
        if (environment.production && !environment.devMode) {
            this.initUpdater();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    public onBeforeUnload(): void {
        if (this.sessionService.isLoggedIn()) {
            this.sessionService.saveUserToStorage(this.credentialsService.username, this.credentialsService.password);
        }
    }

    public ngAfterViewInit(): void {
        const is_merchant = new QpAccountTypePipe(this.agreementService).transform(['Merchant']);

        this.shortcuts.push(
            {
                key: 'g d',
                label: 'Go to Dashboard',
                description: 'g + d',
                command: () =>
                    new QpAccountTypePipe(this.agreementService).transform(['Merchant', 'Reseller'])
                        ? this.router.navigate(['/account', this.agreementService.agreement?.account?.id])
                        : false,
                preventDefault: true
            },
            {
                key: 'g c',
                label: 'Go to saved Cards',
                description: 'g + c',
                command: () =>
                    is_merchant ? this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'cards']) : false,
                preventDefault: true
            },
            {
                key: 'g p',
                label: 'Go to Payments',
                description: 'g + p',
                command: () =>
                    is_merchant ? this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'payments']) : false,
                preventDefault: true
            },
            {
                key: 'g o',
                label: 'Go to Payouts',
                description: 'g + o',
                command: () =>
                    is_merchant ? this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'payouts']) : false,
                preventDefault: true
            },
            {
                key: 'g s',
                label: 'Go to Subscriptions',
                description: 'g + s',
                command: () =>
                    is_merchant ? this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'subscriptions']) : false,
                preventDefault: true
            },
            {
                key: 'g l',
                label: 'Go to payment Link',
                description: 'g + l',
                command: () =>
                    is_merchant ? this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'tools', 'paymentlink']) : false,
                preventDefault: true
            },
            {
                key: 'g t',
                label: 'Go to virtual Terminal',
                description: 'g + t',
                command: () =>
                    is_merchant ? this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'cards', 'virtuelterminal']) : false,
                preventDefault: true
            },
            {
                key: 'r',
                label: 'Refresh payments/transactions table',
                description: 'r',
                command: () =>
                    this.activatedRoute.snapshot.routeConfig?.path?.includes('payments') ||
                        this.activatedRoute.snapshot.routeConfig?.path?.includes('subscriptions') ||
                        this.activatedRoute.snapshot.routeConfig?.path?.includes('payouts')
                        ? this.window.location.reload()
                        : false,
                preventDefault: true
            }
        );
    }

    public initUpdater(): void {
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everyHalfHour$ = interval(30 * 60 * 1000);
        const everyHalfHourOnceAppIsStable$ = concat(appIsStable$, everyHalfHour$);

        everyHalfHourOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());

        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.subscribe(async evt => {
                switch (evt.type) {
                    case 'VERSION_DETECTED':
                        this.snackbar.open($localize`Downloading new app version: ${evt.version.hash}`);
                        break;

                    case 'VERSION_READY':
                        const snack = this.snackbar.open(
                            $localize`An update to the manager is available`,
                            $localize`Reload`
                        );

                        snack.onAction().subscribe(() => {
                            this.swUpdate.activateUpdate().then(() => location.reload());
                        });
                        break;

                    case 'VERSION_INSTALLATION_FAILED':
                        this.snackbar.open(
                            $localize`The Manager failed to update in the background. Manually reload the page in order to get the newest features.`
                        );
                        break;
                }
            });
        }
    }
}
