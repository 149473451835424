import { Component, Input, EventEmitter, Output } from '@angular/core';
import { switchMap, map, filter } from 'rxjs/operators';
import { BaseRepo } from 'app/endpoints/api/base-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { FraudReport } from '@models/fraud-report';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { Transaction } from '@models/transaction';
import { TransactionFraudReportDialogComponent } from './transaction-fraud-report.dialog.component';

@Component({
    selector: 'qp-transaction-fraud-report',
    templateUrl: './transaction-fraud-report.html'
})
export class TransactionFraudReportComponent {
    @Input() public transaction: Transaction;
    @Input() public disableFraud: boolean;
    @Input() public transactionRepo: BaseRepo;
    @Output() public callBack: EventEmitter<any> = new EventEmitter<any>();
    @Input() public permission: boolean;

    constructor(
        private snackBar: QpSnackBar,
        private dialogService: DialogService
    ) {}

    public onReportFraud(): void {
        this.dialogService.component(
            TransactionFraudReportDialogComponent,
            {},
            { maxWidth: '350px' }
        ).afterClosed().pipe(
            filter(x => !!x),
            switchMap((fraudReport: FraudReport) =>
                this.transactionRepo.createFraudReport(
                    this.transaction.id,
                    { description: fraudReport.description }
                ).pipe(
                    map(() => {
                        this.snackBar.open('Report created');
                        this.callBack.emit();
                    })
                )
            )
        ).subscribe();
    }
}
