import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Company } from '@models/clearhaus-application/company';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { FileInput, FileInputComponent } from 'ngx-material-file-input';

@Component({
    selector: 'qp-clearhaus-signup-step-eight',
    templateUrl: './documentation.html'
})
export class ClearhausSignupFormStepEightComponent implements AfterViewInit {
    @Input() public company: Company;
    @Output() public validityChange = new EventEmitter();

    @ViewChild('documentationInput') public docInput: FileInputComponent;

    public _documentationFile: DirectorFile;
    public documentationForm = new UntypedFormGroup({
        documentation: new UntypedFormControl('', Validators.required)
    });

    public get documentation(): UntypedFormControl {
        return this.documentationForm.get('documentation') as UntypedFormControl;
    }
    public get hasDocumentationFiles(): boolean {
        return !!this.company.files?.some(file => file.label === 'documentation');
    }

    @Input() public get documentationFile(): DirectorFile {
        return this._documentationFile;
    }

    public set documentationFile(value: DirectorFile) {
        if (value) {
            this._documentationFile = value;
            this.setFileInput(value);
        }
    }

    constructor() {}

    public ngAfterViewInit(): void {
        const documentationFile = this.company?.files?.find(file => file.label === 'documentation');
        if (documentationFile) {
            this.setFileInput(documentationFile);
        }
        this.documentationForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(!this.documentationForm.invalid);
        });
        this.validityChange.emit(!this.documentationForm.invalid);
        this.documentation.valueChanges.subscribe(fileInput => {
            if (fileInput.files?.length) {
                this.addFile(fileInput.files[0]);
            }
        });
    }

    public setFileInput(file: any): void {
        this.documentation.setValue(file.name);
        if (this.docInput) {
            this.docInput.value = new FileInput([this.generateHollowFile(file)]);
        }
    }

    public generateHollowFile(file: any): File {
        // NOTE: Needed for ngx-material-file-input
        return new File([''], file.name, { type: file.content_type });
    }

    public addFile(file: any): void {
        const directorFile: DirectorFile = {
            label: 'documentation',
            name: file.name,
            size: file.size,
            content_type: file.type,
            file
        };
        if (!this.company.files) {
            this.company.files = [];
        }
        this.company.files.push(directorFile);
    }

    public removeFile(i: number): void {
        (this.company.files as any)[i].remove = true;
        this.validateDocumentation();
    }

    public validateDocumentation(): void {
        this.company.files = this.company.files?.filter(file => {
            if (file.label === 'documentation') {
                this.documentation.setValue(!file.remove ? file.name : null);
                this.documentation.updateValueAndValidity();
                return !file.remove;
            }
        });
    }

    public setFormValidators(): void {
        if (this.company.country !== 'GBR' && this.company.form !== 'Other') {
            this.documentation.setValidators(Validators.required);
        } else {
            this.documentation.clearValidators();
            this.documentation.updateValueAndValidity();
        }
    }
}
