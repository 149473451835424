<h5 mat-dialog-title i18n>You have unsaved changes!</h5>
<mat-dialog-content class="grey-border-bottom">
    <div class="flex-col rg8">
        <span i18n>Click "Discard" to discard the data and navigate away!</span>
        <span i18n>Click "Stay" to stay and handle the data.</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button tabindex="-1" mat-button color="accent" (click)="dialogRef.close(true)" i18n>Discard</button>
    <button tabindex="-1" mat-button color="accent" (click)="dialogRef.close(false)" i18n>Stay</button>
</mat-dialog-actions>
