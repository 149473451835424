<div class="flex-col rg8">
    <qp-file-input 
        i18n-label
        label="Click here to add documentation"
        (file_change)="addFile($event)"
        [multiple_files]="false"
        required_filetype="application/pdf,image/*">
    </qp-file-input>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="_documentationFile.length === 0" color="accent" matStepperNext i18n>
            Next
        </button>
    </div>
</div>