<div class="flex-col rg8" *ngIf="customer">
    <div class="flex-row" *ngIf="paymentMethod && paymentMethod !== 'credit_card'">
        <div class="flex-1">
            <mat-radio-button checked>
                {{ paymentMethodName }}
            </mat-radio-button>
        </div>
        <div class="flex-1" *ngIf="customer.attributes.billing_info?.user_id">
            <ng-container [ngTemplateOutlet]="user_info_tpl"></ng-container>
        </div>
    </div>

    <div class="flex-col rg16" *ngIf="paymentMethod === 'credit_card'">
        <div class="flex-row cg16">
            <div class="flex-50" *ngIf="!!customer.attributes.billing_info?.subscription_info">
                <div class="flex-col margin b0">
                    <span class="subtitle1">{{ getBrand() | titlecase }}</span>
                    <span>
                        {{ (customer.attributes.billing_info?.subscription_info.bin || 'XXXX XX') + 'XXXXXX' + customer.attributes.billing_info?.subscription_info.last4 | qpFormatCard }}
                    </span>
                </div>
                <div class="flex-col margin b0">
                    <span class="subtitle1" i18n>Expires</span>
                    <span>
                        {{ ('0' + customer.attributes.billing_info?.subscription_info.exp_month).slice(-2) + ' / ' + (' ' + customer.attributes.billing_info?.subscription_info.exp_year).slice(-2) }}
                    </span>
                </div>
            </div>

            <div class="flex-50" *ngIf="!customer.attributes.billing_info?.subscription_info">
                <div class="flex-col">
                    <span class="subtitle1 text-red" i18n>Your account is inactive</span>
                    <span i18n>
                        Add a credit card or select another method to activate your account
                    </span>
                </div>
            </div>

            <div *ngIf="customer.attributes.billing_info?.user_id">
                <ng-container [ngTemplateOutlet]="user_info_tpl"></ng-container>
            </div>
        </div>

        <div class="flex-row cg8" *ngIf="!!customer.attributes.billing_info?.subscription_info">
            <button mat-raised-button aria-label="'Change creditcard'" (click)="changeCreditCard()" i18n>
                Update card
            </button>
            <button mat-raised-button aria-label="'Remove card'" (click)="removePaymentInfo()" i18n>
                Remove card
            </button>
        </div>
    </div>

    <qp-select-payment-method (updated)="updateCustomer()" [customer]="customer" [plan]="plan"></qp-select-payment-method>
</div>
