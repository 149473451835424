import { ApiModule } from 'app/endpoints/api/api.module';
import { ColumnsSettingsModule } from '@widgets/columnsSettings/columns-settings.module';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PaginatorModule } from '@widgets/qp-paginator/qp-paginator.module';
import { PipesModule } from '@pipes/pipes.module';
import { SettlementDetailsComponent } from './settlement-details/settlement-details.component';
import { SettlementFilterDialogComponent } from './filter-dialog/filter.component';
import { SettlementsComponent } from './settlements.component';
import { SettlementsTableComponent } from './table-settlements/table-settlements.component';
import { SettlementTransactionsComponent } from './settlement-details/settlement-transactions/settlement-transactions.component';
import { SettlementSummaryComponent } from './settlement-details/settlement-summary/settlement-summary.component';
import { SettlementPayoutsComponent } from './settlement-details/settlement-payouts/settlement-payouts.component';
import { SettlementPeriodComponent } from './settlement-details/settlement-period/settlement-period.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        ApiModule,
        ColumnsSettingsModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        PaginatorModule,
        PipesModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        SettlementsTableComponent,
        SettlementDetailsComponent,
        SettlementsComponent,
        SettlementFilterDialogComponent,
        SettlementTransactionsComponent,
        SettlementSummaryComponent,
        SettlementPayoutsComponent,
        SettlementPeriodComponent
    ]
})
export class SettlementstModule {}
