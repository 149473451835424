import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrandingRepo } from '@endpoints/api/branding-repo';
import { PaymentRepo } from '@endpoints/api/payment-repo';
import { ClearhausTransactionRepo } from '@endpoints/server/clearhaus-transaction-repo.service';
import { Agreement } from '@interfaces/agreement';
import { Branding } from '@models/branding';
import { Payment } from '@models/payment';
import { Transaction } from '@models/transaction';
import { AgreementService } from '@services/agreement/agreement.service';
import { ParamService } from '@services/param/params.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { TransactionService } from '@services/transaction/transaction.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { EMPTY, Observable, iif, of, timer } from 'rxjs';
import { exhaustMap, expand, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { CapturePaymentDialogComponent } from '../../transaction-dialogs/capture-payment-dialog/capture-payment-dialog.component';
import { PaymentLinkDialogComponent } from '../../transaction-dialogs/payment-link-dialog/payment-link-dialog.component';
import { RefundPaymentDialogComponent } from '../../transaction-dialogs/refund-payment-dialog/refund-payment-dialog.component';

@Component({
    selector: 'qp-payment-detail',
    templateUrl: './payment-detail.html',
    animations: [Animations.getFadeAnimation()]
})
export class PaymentDetailComponent extends BaseLoadingDirective implements OnInit {
    @Input() public show_toolbar = true;
    @Input() public payment: any;
    public showSpinner = false;
    public previousParams: any;
    public subscriptionId: string;
    public agreement?: Agreement;
    public branding: Branding;

    public get is_capturable(): boolean {
        return this.transactionService.isCapturable(this.payment);
    }
    public get is_cancelable(): boolean {
        return this.transactionService.isCancelable(this.payment);
    }
    public get is_refundable(): boolean {
        return this.transactionService.isRefundable(this.payment);
    }

    constructor(
        private brandingRepo: BrandingRepo,
        private dialogService: DialogService,
        private paramService: ParamService,
        private snackBar: QpSnackBar,
        private transactionService: TransactionService,
        public paymentRepo: PaymentRepo,
        private agreementService: AgreementService,
        private chTransactionRepo: ClearhausTransactionRepo,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    public ngOnInit(): void {
        this.agreement = this.agreementService.agreement;
        this.subscriptionId = this.activatedRoute.snapshot.params.subscription_id;
        this.previousParams = this.paramService.returnPrevParam();
        this.getPayment();
    }

    public getPayment(): void {
        this.showSpinner = true;
        const payment$ = this.payment ? of(this.payment) : this.paymentRepo.get<Payment>(this.activatedRoute.snapshot.params.payment_id);
        payment$.pipe(
            switchMap(payment => iif(
                () => payment.acquirer === 'clearhaus' && !payment.test_mode,
                this.getChTransaction(payment),
                of(payment)
            )),
            tap((payment) => this.payment = payment),
            filter(payment => !!payment.branding_id),
            switchMap(payment => this.brandingRepo.get(payment.branding_id ?? NaN)),
            finalize(() => this.showSpinner = false),
            tap((branding: Branding) => this.branding = branding)
        ).subscribe();
    }

    private getChTransaction(payment: Payment): Observable<Payment> {
        return this.chTransactionRepo.getCaptureTransaction(payment.order_id).pipe(
            map((ch_transaction) => ({
                ...payment,
                rrn: ch_transaction?.rrn,
                arn: ch_transaction?.arn,
                settlement: ch_transaction?.settlement
            }))
        );
    }

    public updatePayment(transaction: Transaction): void {
        this.paymentRepo.update(transaction).pipe(
            tap((payment: Payment) => {
                this.payment = payment;
                this.snackBar.open($localize`Transaction updated`);
            })
        ).subscribe();
    }

    public onRetryFailedPayment(): void {
        this.dialogService.copy(
            $localize`The payment link`,
            this.payment.link.url,
            $localize`Link copied to clipboard`
        );
    }

    public onCapture(): void {
        this.dialogService.component(
            CapturePaymentDialogComponent,
            this.payment
        ).afterClosed().pipe(
            filter(amount => !!amount),
            tap(() => this.showSpinner = true),
            switchMap((amount: number) =>
                this.paymentRepo.capture(this.payment.id ?? NaN, { amount }).pipe(
                    map((payment: Payment) => {
                        if (payment.operations) {
                            this.payment = payment;
                        } else {
                            setTimeout(() => this.getPayment(), 2000);
                        }
                    })
                )
            ),
            finalize(() => this.showSpinner = false)
        ).subscribe();
    }

    public onUpdateStatus(): void {
        this.paymentRepo.getMpsStatus(this.payment.id ?? NaN).subscribe(() => {
            this.dialogService.alert(
                $localize`Updating status`,
                $localize`The payment status will be updated in a few minutes. Please reload to see the new status.`
            );
        });
    }

    public onCancel(): void {
        const dialogText =
            $localize`Payment ID:` +
            ' ' +
            this.payment.id +
            '\n' +
            $localize`Order ID:` +
            ' ' +
            this.payment.order_id +
            '\n' +
            $localize`Are you sure you want to cancel the payment?`;

        this.dialogService.confirm(
            $localize`Cancel payment?`,
            dialogText,
            $localize`Cancel payment`,
            $localize`Close`
        ).afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.paymentRepo.cancel(this.payment.id ?? NaN).pipe(
                    filter(x => !!x),
                    tap(() => this.showSpinner = true),
                    expand((payment: Payment) => {
                        if (!this.transactionService.lastOperation(payment)?.pending) {
                            return EMPTY;
                        }

                        return timer(2000).pipe(
                            exhaustMap(_ => this.paymentRepo.get<Payment>(payment.id ?? NaN))
                        );
                    }),
                    finalize(() => this.showSpinner = false)
                ).subscribe((payment: Payment) => {
                    if (payment.operations) {
                        this.payment = payment;
                    } else {
                        setTimeout(() => this.getPayment(), 2000);
                    }
                });
            }
        });
    }

    public refund(): void {
        this.dialogService.component(
            RefundPaymentDialogComponent,
            this.payment
        ).afterClosed().pipe(
            filter(data => !!data),
            tap(() => this.showSpinner = true),
            switchMap((data: any) => {
                if (this.payment.acquirer !== 'klarnapayments') {
                    delete data.vat_rate;
                }

                return this.paymentRepo.refund(this.payment.id ?? NaN, data).pipe(
                    map((payment: Payment) => {
                        if (payment.operations) {
                            this.payment = payment;
                        } else {
                            setTimeout(() => this.getPayment(), 2000);
                        }
                    })
                );
            }),
            finalize(() => this.showSpinner = false)
        ).subscribe();
    }

    public onEditPaymentLink(): void {
        const link = Object.assign({}, this.payment.link);

        this.dialogService.component(
            PaymentLinkDialogComponent, {
            currency: this.payment.currency,
            link
        }
        ).afterClosed().pipe(
            filter(x => !!x),
            exhaustMap((result: any) => {
                this.payment.link.amount = parseInt(result.amount, 10);
                return this.paymentRepo.replaceLink(this.payment.id ?? NaN, result).pipe(
                    map(() => {
                        this.snackBar.open($localize`Payment link updated`);
                    })
                );
            })
        ).subscribe();
    }

    public onDeletePaymentLink(): void {
        this.dialogService.confirm(
            $localize`Delete link`,
            $localize`Sure you want to delete payment link?`,
            $localize`Delete`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            exhaustMap(() =>
                this.paymentRepo.destroyLink(this.payment.id ?? NaN).pipe(
                    map(() => {
                        this.snackBar.open($localize`Payment link deleted`);
                        this.getPayment();
                    })
                )
            )
        ).subscribe();
    }

    public showJSON(): void {
        const obj = JSON.stringify(this.payment, null, 4);
        obj.replace(/%/g, '%%');

        this.dialogService.copy(
            '',
            obj,
            $localize`Copied to clipboard`
        );
    }
}
