import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccountRepo } from '@endpoints/api/account-repo';
import { Agreement } from '@interfaces/agreement';
import { AgreementService } from '@services/agreement/agreement.service';
import { of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
    selector: 'qp-agreements-list',
    templateUrl: './agreements-list.component.html',
    styleUrls: ['./agreements-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgreementsListComponent implements OnInit, OnDestroy {
    @Input() public agreement?: Agreement;
    @Input() public reseller_agreement?: Agreement = undefined;
    @Output() public sign_out = new EventEmitter<Agreement>();
    public session_agreements = this.agreementService.agreements;
    public logo: SafeUrl;

    private params_subscription = new Subscription();

    constructor(
        private domSanitizer: DomSanitizer,
        private accountRepo: AccountRepo,
        private agreementService: AgreementService,
        private activatedRoute: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.params_subscription = this.activatedRoute.params.subscribe(() => {
            this.session_agreements = this.agreementService.agreements;
            this.loadLogo();
        });
    }

    public ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
    }

    public getAccountName(agreement: Agreement | undefined): string {
        return agreement?.account?.customer_address && agreement.account?.customer_address.name ?
            agreement.account?.customer_address.name :
            agreement?.account?.type ?? '';
    }

    public signOutTo(agreement: Agreement | undefined): void {
        this.sign_out.emit(agreement);
    }

    public loadLogo(): void {
        if (this.agreement?.account?.type === 'Merchant' && this.agreementService.hasPermission('/account/logo', 'get')) {
            this.accountRepo
                .getLogo('', '')
                .pipe(
                    map((data: Blob) => {
                        const urlCreator: any = window.URL || window['webkitURL'];
                        this.logo = this.domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
                    }),
                    catchError(() => of())
                )
                .subscribe();
        }
    }
}
