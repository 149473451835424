<form [formGroup]="signatoryForm" class="flex-col rg8">
    <mat-form-field hideRequiredMarker>
        <mat-label i18n>Signers full name</mat-label>
        <input matInput name="contact_name" formControlName="name" type="text" autofocus (change)="modelSigner('name', $event.target)" />
        <mat-error *ngIf="name.errors?.required" i18n>Signers name is required.</mat-error>
    </mat-form-field>

    <mat-form-field hideRequiredMarker>
        <mat-label i18n>Signers email</mat-label>
        <input matInput name="contact_email" formControlName="email" type="email" (change)="modelSigner('email', $event.target)" />
        <mat-error *ngIf="email.errors?.required" i18n>Signers email address is required.</mat-error>
        <mat-error *ngIf="email.errors?.email" i18n>Invalid email address.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="signatoryForm.invalid" color="accent" (click)="goForwardValidation()" i18n>Next</button>
    </div>
</form>
