import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ApiClient, apiClientFactory } from './api-client';
import { PaypalApiClient, paypalApiClientFactory } from './paypal-client';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: ApiClient,
            useFactory: apiClientFactory,
            deps: [HttpClient]
        },
        {
            provide: PaypalApiClient,
            useFactory: paypalApiClientFactory,
            deps: [HttpClient]
        }
    ]
})
export class ApiModule {}
