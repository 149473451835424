import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { environment } from '@environments/environment';
import { AgreementService } from '@services/agreement/agreement.service';
import { CountryService } from '../country/country.service';

@Injectable({
    providedIn: 'root'
})
export class HomepageService {
    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private countryService: CountryService,
        private agreementService: AgreementService
    ) {}

    public getLink(url: string): string {
        let language = '/';
        const qpHomepage: string = environment.homePageUrl;
        if (!!this.agreementService.agreement?.account?.customer_address?.country_code) {
            const countryCode = this.agreementService.agreement.account.customer_address.country_code;
            const alpha2Code = this.countryService.alpha3ToAlpha2(countryCode);
            if (
                alpha2Code &&
                qpHomepage.indexOf(alpha2Code.toLowerCase()) !== -1
            ) {
                language = '/' + this.countryService.alpha3ToAlpha2(countryCode)?.toLowerCase() + '/';
            }
        }
        return qpHomepage + language + url;
    }

    public getLearnLink(url: string, subFix: string): string {
        let language = '/en/';
        const qpLearnHomepage = environment.learnHomePageUrl;

        subFix = subFix || '';
        if (qpLearnHomepage.indexOf(this.locale) !== -1) {
            language = '/' + qpLearnHomepage[qpLearnHomepage.indexOf(this.locale)].toLowerCase() + '/';
        }
        return qpLearnHomepage + subFix + language + url;
    }

    public getHelpdeskLink(url: string, subFix?: string): string {
        subFix = subFix || '';
        const language = !!this.agreementService.isDanishMerchant() ? '/dk' : '';
        const helpdeskBaseLink = environment.homePageUrl;
        return helpdeskBaseLink + language + '/helpdesk/' + url + subFix;
    }
}
