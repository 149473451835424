import { Pipe, PipeTransform } from '@angular/core';
import { AgreementService } from '@services/agreement/agreement.service';

@Pipe({ name: 'qpPermission' })
export class QpPermissionPipe implements PipeTransform {
    constructor(private agreementService: AgreementService) {}

    public transform(permission: string[]): boolean {
        return this.agreementService.hasPermission(permission[0], permission[1]);
    }
}
