import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Contact } from '@models/clearhaus-application/ch-instant';

@Component({
    selector: 'qp-clearhaus-contact-form',
    templateUrl: './contact-instant.html'
})
export class ClearhausInstantSignupFormStepOneComponent implements AfterViewInit {
    @Output() public contactChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _contact: Contact = new Contact();

    public contactForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email]))
    });

    @Input() public get contact(): Contact {
        return this._contact;
    }
    public set contact(value: Contact) {
        this._contact = value;
        this.contactChange.emit(value);
    }

    public get email(): UntypedFormControl {
        return this.contactForm.get('email') as UntypedFormControl;
    }
    public get name(): UntypedFormControl {
        return this.contactForm.get('name') as UntypedFormControl;
    }

    constructor() {}

    public ngAfterViewInit(): void {
        this.contactForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(this.contactForm.valid);
        });

        this.email.setValue(this.contact.email);
        this.name.setValue(this.contact.name);
        this.validityChange.emit(this.contactForm.valid);
    }

    public modelContact(propName: keyof Contact, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        this.contact = { ...this.contact, [propName]: input.value };
    }
}
