import { Agreement } from '@interfaces/agreement';
import { ApiClient } from './api-client';
import { Base64 } from '@services/base64/base64';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AgreementCreateParams } from '@interfaces/agreement-create-params';

@Injectable({
    providedIn: 'root'
})
export class AgreementRepo {
    constructor(
        private client: ApiClient,
        private base64: Base64
    ) {}

    public getAll(params: any = null, authorization?: string): Observable<Agreement[]> {
        const config: any = {
            params
        };
        if (authorization) {
            config.headers = { Authorization: authorization };
        }
        return this.client.get<Agreement[]>('/agreements', config).pipe(
            catchError((error: any) => {
                throw error;
            }),
            map((items: any) => items.map((item: any) => item as Agreement))
        );
    }

    public get(id: number): Observable<Agreement> {
        return this.client.get<Agreement>('/agreements/' + id).pipe(
            map((item: any) => Object.assign(item as Agreement))
        );
    }

    public update(agreement: Agreement): Observable<Agreement> {
        return this.client.patch<Agreement>('/agreements/' + agreement.id, agreement).pipe(
            map((item: any) => Object.assign(item as Agreement))
        );
    }

    public regenerateApiKey(id: number): Observable<Agreement> {
        return this.client.patch<Agreement>('/agreements/' + id + '/api-key', {}).pipe(
            map((item: any) => Object.assign(item as Agreement))
        );
    }

    public create(params: Partial<AgreementCreateParams>, apiKey: string | undefined): Observable<Agreement> {
        const config: any = {};
        if (apiKey) {
            config.headers = { Authorization: 'Basic ' + this.base64.encodeUnicode(':' + apiKey) };
        }

        return this.client.post<Agreement>('/agreements', params, config).pipe(
            map((item: any) => Object.assign(item as Agreement))
        );
    }

    public accept(id: number): Observable<void> {
        return this.client.post<void>('/agreements/' + id + '/accept', null);
    }

    public destroy(id: number): Observable<Agreement> {
        return this.client.delete<Agreement>('/agreements/' + id);
    }

    public replace(agreement: Agreement): Observable<Agreement> {
        return this.client.put<Agreement>('/agreements/' + agreement.id, agreement).pipe(
            map((item: any) => Object.assign(item as Agreement))
        );
    }
}
