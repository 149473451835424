import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CurrencyService } from '@services/currency/currency.service';

@Component({
    selector: 'qp-currency-input',
    templateUrl: './currency-input.html'
})
export class CurrencyInputComponent implements OnInit {
    @Input() public autoFocus: boolean;
    @Input() public allowedCurrencies: Array<string>;
    @Input() public required: boolean;
    @Input() public disabled: boolean;
    @Output() public currencyChange = new EventEmitter();

    public currencies: { value: string; name: string }[];
    public _currency: string;

    public set currency(val) {
        this._currency = val ?? '';
        this.currencyChange.emit(this._currency);
    }

    @Input()
    public get currency(): string | undefined {
        return this._currency;
    }

    constructor(private currencyService: CurrencyService) {}

    public ngOnInit(): void {
        this.currencies = this.currencyService.getArray();
        if (this.allowedCurrencies) {
            this.filterCurrencies();
        }
    }

    public filterCurrencies(): void {
        this.currencies = this.currencies.filter(currency => this.allowedCurrencies.indexOf(currency.name) !== -1);
    }
}
