<h5 *ngIf="data.headline" mat-dialog-title>
    <span class="pre-wrap">{{ data.headline }}</span>
</h5>
<mat-dialog-content>
    <div class="flex-row justify-center items-center">
        <mat-spinner diameter="70" color="accent"></mat-spinner>
    </div>
    <div *ngIf="!!params">
        <span i18n>Exported</span>&nbsp;{{ ((params.page || 1) * (params.page_size || 10)) - (params.page_size || 0) }}&nbsp;<span i18n>row(s)</span>
    </div>

    <div *ngIf="!!params">
        <button tabindex="-1" mat-button color="accent" (click)="dialog_ref.close()">
            Export in background
        </button>
    </div>
</mat-dialog-content>
