<mat-card class="card02 avoid-page-break" *ngIf="transaction.retented_at">
    <mat-card-content class="p-1">
        <span i18n>The payment information is no longer available.</span>
        <br />
        <span>
            <span i18n>See</span>&nbsp; <a [href]="retentedLink" target="_blank" i18n>our helpdesk</a>&nbsp;
            <span i18n>for more information.</span>
        </span>
    </mat-card-content>
</mat-card>
