import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

export interface IFile extends ArrayBuffer {
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class DownloaderService {
    private jsZip: any;
    constructor() {
        this.jsZip = JSZip;
    }

    public saveFile(file: File | Blob, name: string): void {
        saveAs(file, name);
    }

    public saveFiles(files: IFile[], name: string): void {
        const zip = new this.jsZip();
        for (const file of files) {
            zip.file(file.name, file);
        }
        zip.generateAsync({ type: 'blob' }).then((zipBlob: any) => {
            saveAs(zipBlob, name + '.zip');
        });
    }
}
