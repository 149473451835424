import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FreePipe } from './free.pipe';
import { QpAccountTypePipe } from './qp-account-type.pipe';
import { QpAcquirerPipe } from './qp-acquirer.pipe';
import { QpBrandPipe } from './qp-brand.pipe';
import { QpCurrencyPipe } from './qp-currency.pipe';
import { QpFilterPipe } from './qp-filter.pipe';
import { QpFormatcardPipe } from './qp-formatcard.pipe';
import { QPHumanizeBoolPipe } from './qp-humanize-bool.pipe';
import { QpMerchantTypePipe } from './qp-merchant-type.pipe';
import { QpNumberPipe } from './qp-number.pipe';
import { QpPermissionPipe } from './qp-permission.pipe';
import { QPToArrayPipe } from './qp-to-array.pipe';
import { QpAccountValidPipe } from './qp-account-valid.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        QpAccountTypePipe,
        QpAcquirerPipe,
        QpBrandPipe,
        QpCurrencyPipe,
        QpFilterPipe,
        QpFormatcardPipe,
        QPHumanizeBoolPipe,
        QpMerchantTypePipe,
        QpNumberPipe,
        QpPermissionPipe,
        QPToArrayPipe,
        FreePipe,
        QpAccountValidPipe
    ],
    exports: [
        QpAccountTypePipe,
        QpAcquirerPipe,
        QpBrandPipe,
        QpCurrencyPipe,
        QpFilterPipe,
        QpFormatcardPipe,
        QPHumanizeBoolPipe,
        QpMerchantTypePipe,
        QpNumberPipe,
        QpPermissionPipe,
        QPToArrayPipe,
        FreePipe,
        QpAccountValidPipe
    ]
})
export class PipesModule {}
