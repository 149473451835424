export class PaginatorMixin {
    public page: number;
    public page_size: number;

    public getTotalItems(items: number): number {
        const totalItems = this.page * this.page_size;
        if (this.page_size === items) {
            return totalItems + 1;
        }
        return totalItems - this.page_size + items;
    }
}
