<form name="plan" #f="ngForm" class="flex-col">
    <h5 i18n>Invite user</h5>

    <div mat-dialog-content class="flex-col rg16 grey-border-bottom">
        <p i18n>Which email should we send the invitation to?</p>
        <form [formGroup]="form_group">
            <mat-form-field hideRequiredMarker>
                <mat-label i18n>Email</mat-label>
                <input matInput formControlName="email" name="email" />
                <mat-error *ngIf="form_group.get('email')?.hasError('required') && form_group.touched" i18n>This field is required.</mat-error>
            </mat-form-field>

            <p i18n>Select which role the user should have. You can always update the permissions later.</p>
            <mat-form-field hideRequiredMarker>
                <mat-label i18n>Role</mat-label>
                <mat-select formControlName="role" name="role">
                    <mat-option *ngFor="let role of roles" [value]="role">
                        {{ role.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form_group.get('role')?.hasError('required') && form_group.touched" i18n>Please select a user role.</mat-error>
            </mat-form-field>
        </form>
    </div>

    <div mat-dialog-actions class="flex-row justify-end">
        <button mat-button color="accent" mat-dialog-close i18n>Cancel</button>
        <button mat-button color="accent" (click)="inviteUser()" type="submit" i18n>Send</button>
    </div>
</form>
