import { Injectable } from '@angular/core';
import { ApiClient } from './api-client';
import { EventSubscription } from '@models/event-subscription';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventSubscriptionsRepo {
    constructor(private client: ApiClient) {}

    public getAll(params: any): Observable<EventSubscription[]> {
        params = params || {};
        return this.client.get<EventSubscription>('/event-subscriptions', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new EventSubscription(), item)))
        );
    }

    public create(params: any): Observable<EventSubscription> {
        params = params || {};
        return this.client.post<EventSubscription>('/event-subscriptions', params).pipe(
            map((item: any) => Object.assign(new EventSubscription(), item))
        );
    }

    public destroy(id: number): Observable<EventSubscription> {
        return this.client.delete<EventSubscription>('/event-subscriptions/' + id);
    }
}
