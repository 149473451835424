import { Component, OnInit, Inject } from '@angular/core';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';
import { FilterParams } from '@interfaces/params';
import { Locale } from '@services/locales/locales.interface';
import { LocalesService } from '@services/locales/locales.service';
import { ShopSystem, SHOPSYSTEMS } from '@widgets/shopsystems/shopsystems.constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-filter-dialog',
    templateUrl: 'filter-dialog.html'
})
export class FilterDialogComponent implements OnInit {
    public searchText = '';
    public shopsystems: Array<ShopSystem> = SHOPSYSTEMS;
    public countries: Array<Country>;
    public locales: Array<Locale>;
    public filters:  { [key: string]: { active: boolean; selected: boolean; data?: any } } = {
        acquirer: {
            active: false,
            selected: false,
            data: {}
        },
        active_acquirer: {
            active: false,
            selected: false,
            data: {}
        },
        country_code: {
            active: false,
            selected: false,
            data: {}
        },
        date: {
            active: false,
            selected: false,
            data: {}
        },
        expired: {
            active: false,
            selected: false,
            data: {}
        },
        live_transactions: {
            active: false,
            selected: false
        },
        locale: {
            active: false,
            selected: false,
            data: {}
        },
        shopsystem: {
            active: false,
            selected: false,
            data: {}
        },
        subscription_group: {
            active: false,
            selected: false,
            data: {}
        },
        suspected_fraud: {
            active: false,
            selected: false
        },
        suspended: {
            active: false,
            selected: false
        }
    };

    constructor(
        private countryService: CountryService,
        private localesService: LocalesService,
        public dialogRef: MatDialogRef<FilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { active_filters: FilterParams; transaction_type: string }
    ) {}

    public ngOnInit(): void {
        this.locales = this.localesService.getLocales();
        this.countries = this.countryService.getCountries();
        this.activateFilters(this.filters, this.data.active_filters);
    }

    public addFilter(): void {
        const params: { [key: string]: string } = {};
        let value: any;

        for (const key in this.filters) {
            if (this.filters.hasOwnProperty(key)) {
                value = this.filters[key];

                if (
                    value.active &&
                    (!value.hasOwnProperty('selected') || value.selected) &&
                    (!value.hasOwnProperty('data') ||
                        ![undefined, null].includes(value.data[key]) ||
                        value.data['min_time'] ||
                        value.data['max_time'])
                ) {
                    params[key] = value.data ? value.data : value.selected;
                }
            }
        }

        this.dialogRef.close(params);
    }

    public activateFilters(filters: any, active_filters: FilterParams): void {
        ['live_transactions', 'suspected_fraud', 'suspended'].forEach(filter => {
            if (active_filters.hasOwnProperty(filter)) {
                filters[filter].active = true;
                filters[filter].selected = active_filters[filter as keyof FilterParams];
            }
        });

        ['acquirer', 'active_acquirer', 'expired', 'country_code', 'locale', 'shopsystem', 'subscription_group'].forEach(filter => {
            if (active_filters.hasOwnProperty(filter)) {
                filters[filter].active = true;
                filters[filter].selected = !!active_filters[filter as keyof FilterParams];
                filters[filter].data[filter] = active_filters[filter as keyof FilterParams];
            }
        });

        if (active_filters.hasOwnProperty('min_time') || active_filters.hasOwnProperty('max_time') ) {
            filters.date.active = true;
            filters.date.selected = !!(active_filters.min_time || active_filters.max_time);
            filters.date.data.min_time = active_filters.min_time;
            filters.date.data.max_time = active_filters.max_time;
            filters.date.data.timestamp = active_filters.timestamp;
        }
    }
}
