import { Account } from '@interfaces/account';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { Mail } from '@interfaces/mail';
import { MatDialogRef } from '@angular/material/dialog';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { SessionService } from '@services/session/session.service';
import { SupportRepo } from 'app/endpoints/server/support-repo';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'qp-delete-account-dialog',
    templateUrl: 'delete-account-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteAccountDialogComponent {
    public data = {
        value: 'other',
        reason: ''
    };

    public account: Account | undefined = this.agreementService.agreement?.account;

    constructor(
        private acquirerService: AcquirerService,
        private sessionService: SessionService,
        private snackBar: QpSnackBar,
        private supportRepo: SupportRepo,
        public dialogRef: MatDialogRef<DeleteAccountDialogComponent, boolean>,
        private agreementService: AgreementService
    ) {}

    public close(): void {
        const mail: Mail = {
            to: 'sales@quickpay.net',
            from: this.sessionService.me?.email ?? '',
            subject: `Delete account ${this.account?.shop_name ?? ''}`,
            body_text: `${this.data.value}\n${this.data.reason}`
        };
        if (new QpMerchantTypePipe(this.agreementService).transform(['Quickpay']) && this.acquirerService.isAnyAcquirerActiveWithCredentials()) {
            this.sendMail(mail);
        } else {
            this.dialogRef.close(true);
        }
    }

    private sendMail(mail: Mail): void {
        this.supportRepo.sendSupportMail(mail).pipe(
            catchHttpStatusError([HttpStatusCode.InternalServerError], () => mail),
            tap(() => {
                this.snackBar.open($localize`Thank you for the feedback`);
                this.dialogRef.close(true);
            })
        ).subscribe();
    }
}
