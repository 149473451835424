import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ClearhausApplication } from '@models/clearhaus-application/ch-instant';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { AgreementService } from '@services/agreement/agreement.service';
import { ClearhausRepo } from 'app/endpoints/server/clearhaus-repo';
import { CountryService } from '@services/country/country.service';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { WINDOW } from '@helpers/windowprovider/window-provider';

@Component({
    selector: 'qp-clearhaus-instant-signup-submit',
    templateUrl: './submit-instant.html'
})
export class ClearhausInstantSignupFormSubmitComponent {
    @Input() public application: ClearhausApplication = new ClearhausApplication();
    @Input() public documentationFile: DirectorFile[];
    @Input() public validity: {
        bank: boolean;
        business: boolean;
        company: boolean;
        contact: boolean;
        websites: boolean;
    };

    @Output() public applicationUploaded = new EventEmitter();
    @Output() public validateDocumentation = new EventEmitter();

    public saving = false;
    public withError = false;
    public errorMsg = '';
    public applicationSent = false;

    constructor(
        private clearhausRepo: ClearhausRepo,
        private countryService: CountryService,
        private http: HttpClient,
        @Inject(WINDOW) private window: Window,
        private agreementService: AgreementService
    ) {}

    public get disableSubmit(): boolean {
        return this.saving || !this.validityOk;
    }
    public get submitted(): boolean {
        return this.application.application?.metadata?.state === 'submitted';
    }
    public get validityOk(): boolean {
        return this.validity.contact || this.validity.company || this.validity.websites || this.validity.business || this.validity.bank;
    }

    public send(): void {
        this.saving = true;

        if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
            this.application.application.voucher_code = 'unzer';
        }

        if (this.application.company.country && this.application.company.country.length > 2) {
            this.application.company.country = this.countryService.alpha3ToAlpha2(this.application.company.country);
        }

        const punycode = require('punycode');
        this.application.websites.forEach(url => {
            // eslint-disable-next-line
            url = punycode.encode(url); // ajust for special characters that clearhaus does not support
        });

        this.clearhausRepo.createInstantApplication(this.application).subscribe({
            next: () => {
                this.applicationSent = true;
            },
            error: err => {
                this.withError = true;
                this.errorMsg = err.error.errors.detail;
                this.applicationSent = false;
                this.saving = false;
            },
            complete: () => {
                this.saving = false;
                this.applicationSent = true;

                if (!!this.documentationFile) {
                    this.documentationFile.forEach(file => {
                        this.uploadFileObservable(this.documentationFile, this.clearhausRepo.createCompanyFile(file)).pipe(
                            map(documentation => ({ documentation })),
                            catchError(_ => of({ documentation: null }))
                        );
                    });
                }

                if (!this.withError) {
                    this.window.location.reload();
                }
            }
        });
    }

    public uploadFileObservable(file: any, observable: Observable<any>): Observable<any> {
        return observable.pipe(
            mergeMap(newFile => this.clearhausRepo.createUploadLink(newFile.id)),
            mergeMap(uploadLink =>
                this.http.put(uploadLink.upload, file.file, {
                    headers: { 'Content-Type': file.file?.type || file.content_type }
                })
            )
        );
    }
}
