import { Injectable } from '@angular/core';
import { TIMEZONES } from './timezone.constant';
import { Timezone } from './timezone.interface';

@Injectable({
    providedIn: 'root'
})
export class TimezoneService {
    constructor() {}

    public getTimezoneFromCode(code: string): Timezone | undefined {
        const timeZone = TIMEZONES.find(zone => zone.value === code);
        return timeZone;
    }

    public getTimezones(): Timezone[] {
        return TIMEZONES;
    }

    public calculate_time_zone(): string {
        const rightNow: Date = new Date();
        const jan1: Date = new Date(rightNow.getFullYear(), 0, 1, 0, 0, 0, 0); // jan 1st
        const june1: Date = new Date(rightNow.getFullYear(), 6, 1, 0, 0, 0, 0); // june 1st
        let temp: string = jan1.toUTCString();
        const jan2: Date = new Date(temp.substring(0, temp.lastIndexOf(' ') - 1));
        temp = june1.toUTCString();

        const june2: Date = new Date(temp.substring(0, temp.lastIndexOf(' ') - 1));
        let std_time_offset: number = (jan1.getTime() - jan2.getTime()) / (1000 * 60 * 60);
        const daylight_time_offset: number = (june1.getTime() - june2.getTime()) / (1000 * 60 * 60);
        let dst: string;
        /* istanbul ignore else: we can't mock timezones like this in Jasmine */
        if (std_time_offset === daylight_time_offset) {
            dst = '0'; // daylight savings time is NOT observed
        } else {
            // positive is southern, negative is northern hemisphere
            const hemisphere = std_time_offset - daylight_time_offset;
            if (hemisphere >= 0) {
                std_time_offset = daylight_time_offset;
            }

            // eslint-disable-next-line
            dst = '1'; // daylight savings time is observed
        }
        return this.convert(std_time_offset);
    }

    public convert(valueNumber: number): string {
        const hours: number = Math.floor(valueNumber);
        valueNumber -= Math.floor(valueNumber);
        valueNumber *= 60;
        const mins: number = Math.floor(valueNumber);
        valueNumber -= Math.floor(valueNumber);
        valueNumber *= 60;
        let displayHours: string = hours.toString();
        // handle GMT case (00:00)
        if (hours === 0) {
            displayHours = '00';
        } else if (hours > 0) {
            // add a plus sign and perhaps an extra 0
            displayHours = hours < 10 ? '+0' + hours : '+' + hours;
        } else {
            // add an extra 0 if needed
            displayHours = hours > -10 ? '-0' + Math.abs(hours).toString() : hours.toString();
        }

        const displayMins: string = mins < 10 ? '0' + mins.toString() : mins.toString();
        return displayHours + ':' + displayMins;
    }
}
