import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionGroup } from '@models/subscription-group';

@Component({
    selector: 'qp-subscriptiongroup-dialog',
    templateUrl: 'subscriptiongroup-dialog.html'
})
export class SubscriptionGroupDialogComponent {
    public group = new SubscriptionGroup();

    constructor(
        public dialogRef: MatDialogRef<SubscriptionGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialog_data?: SubscriptionGroup
    ) {
        if (this.dialog_data) {
            this.group = this.dialog_data;
        } else {
            this.group.currency = '';
        }
    }
}
