import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { SectionHeadingComponent } from './section-heading.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [SectionHeadingComponent],
    exports: [SectionHeadingComponent]
})
export class SectionHeadingModule {}
