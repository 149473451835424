import { switchMap, map, filter, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { InviteUserDialogComponent } from './invite-user-dialog/invite-user-dialog.component';
import { Agreement } from '@interfaces/agreement';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { Me } from '@interfaces/me';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { AgreementCreateParams } from '@interfaces/agreement-create-params';

@Component({
    selector: 'qp-users',
    templateUrl: './users.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class UsersComponent extends BaseLoadingDirective implements OnInit {
    public system_agreements: Agreement[] = [];
    public agreements: Agreement[] = [];
    public user: Me | undefined;

    constructor(
        private agreementRepo: AgreementRepo,
        private dialogService: DialogService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.user = this.agreementService.agreement?.user;
        this.getAgreements();
    }

    public getAgreements(): void {
        this.agreementRepo.getAll({ page: 1, page_size: 100 }, undefined).subscribe((agreements: Agreement[]) => {
            this.system_agreements = agreements.filter((agreement: Agreement) => agreement.user?.system_user && !agreement.support);
            this.agreements = agreements.filter((agreement: Agreement) =>
                !agreement.user?.system_user && (!agreement.support || (agreement.support && agreement.user?.email === this.user?.email))
            );
        });
    }

    public inviteUser(): void {
        this.dialogService.component(
            InviteUserDialogComponent
        ).afterClosed().pipe(
            filter(value => !!value),
            tap((agreement: Agreement) => this.agreements.push(agreement))
        ).subscribe();
    }

    public createSystemUser(): void {
        const fields: PromptField[] = [
            {
                placeholder: $localize`System name`,
                label: $localize`System name`,
                required: true,
                type: 'text',
                name: 'name'
            }
        ];

        this.dialogService.prompt(
            $localize`Create system user`,
            $localize`Remember to grant the system user the right permissions`,
            $localize`Create`,
            fields
        ).afterClosed().pipe(
            filter(value => !!value),
            map((returnFields: PromptField[]) => ({
                system_user: returnFields[0].value,
                owner: false,
                acl_permissions: []
            })),
            switchMap((data: Partial<AgreementCreateParams>) => this.agreementRepo.create(data, undefined)),
            tap((agreement: Agreement) => this.system_agreements.push(agreement)),
            tap(() => this.snackBar.open($localize`User created`))
        ).subscribe();
    }
}
