import { Injectable } from '@angular/core';
import { Locale } from './locales.interface';

@Injectable({
    providedIn: 'root'
})
export class LocalesService {
    public getLocales(): Array<Locale> {
        return [
            { code: 'ab', name: $localize`Abkhaz`, master: false },
            { code: 'aa', name: $localize`Afar`, master: false },
            { code: 'af', name: $localize`Afrikaans`, master: false },
            { code: 'ak', name: $localize`Akan`, master: false },
            { code: 'sq', name: $localize`Albanian`, master: false },
            { code: 'am', name: $localize`Amharic`, master: false },
            { code: 'ar', name: $localize`Arabic`, master: false },
            { code: 'an', name: $localize`Aragonese`, master: false },
            { code: 'hy', name: $localize`Armenian`, master: false },
            { code: 'as', name: $localize`Assamese`, master: false },
            { code: 'av', name: $localize`Avaric`, master: false },
            { code: 'ae', name: $localize`Avestan`, master: false },
            { code: 'ay', name: $localize`Aymara`, master: false },
            { code: 'az', name: $localize`Azerbaijani`, master: false },
            { code: 'bm', name: $localize`Bambara`, master: false },
            { code: 'ba', name: $localize`Bashkir`, master: false },
            { code: 'eu', name: $localize`Basque`, master: false },
            { code: 'be', name: $localize`Belarusian`, master: false },
            { code: 'bn', name: $localize`Bengali; Bangla`, master: false },
            { code: 'bh', name: $localize`Bihari`, master: false },
            { code: 'bi', name: $localize`Bislama`, master: false },
            { code: 'bs', name: $localize`Bosnian`, master: false },
            { code: 'br', name: $localize`Breton`, master: false },
            { code: 'bg', name: $localize`Bulgarian`, master: true },
            { code: 'my', name: $localize`Burmese`, master: false },
            { code: 'ca', name: $localize`Catalan; Valencian`, master: false },
            { code: 'ch', name: $localize`Chamorro`, master: false },
            { code: 'ce', name: $localize`Chechen`, master: false },
            { code: 'ny', name: $localize`Chichewa; Chewa; Nyanja`, master: false },
            { code: 'zh', name: $localize`Chinese`, master: true },
            { code: 'cv', name: $localize`Chuvash`, master: false },
            { code: 'kw', name: $localize`Cornish`, master: false },
            { code: 'co', name: $localize`Corsican`, master: false },
            { code: 'cr', name: $localize`Cree`, master: false },
            { code: 'hr', name: $localize`Croatian`, master: true },
            { code: 'cs', name: $localize`Czech`, master: true },
            { code: 'da', name: $localize`Danish`, master: true },
            { code: 'dv', name: $localize`Divehi; Dhivehi; Maldivian;`, master: false },
            { code: 'nl', name: $localize`Dutch`, master: true },
            { code: 'dz', name: $localize`Dzongkha`, master: false },
            { code: 'en', name: $localize`English`, master: true },
            { code: 'eo', name: $localize`Esperanto`, master: false },
            { code: 'et', name: $localize`Estonian`, master: true },
            { code: 'ee', name: $localize`Ewe`, master: false },
            { code: 'fo', name: $localize`Faroese`, master: true },
            { code: 'fj', name: $localize`Fijian`, master: false },
            { code: 'fi', name: $localize`Finnish`, master: true },
            { code: 'fr', name: $localize`French`, master: true },
            { code: 'ff', name: $localize`Fula; Fulah; Pulaar; Pular`, master: false },
            { code: 'gl', name: $localize`Galician`, master: false },
            { code: 'ka', name: $localize`Georgian`, master: true },
            { code: 'de', name: $localize`German`, master: true },
            { code: 'el', name: $localize`Greek, Modern`, master: true },
            { code: 'gn', name: $localize`Guaran`, master: false },
            { code: 'gu', name: $localize`Gujarati`, master: false },
            { code: 'ht', name: $localize`Haitian; Haitian Creole`, master: false },
            { code: 'ha', name: $localize`Hausa`, master: false },
            { code: 'he', name: $localize`Hebrew (modern)`, master: false },
            { code: 'hz', name: $localize`Herero`, master: false },
            { code: 'hi', name: $localize`Hindi`, master: false },
            { code: 'ho', name: $localize`Hiri Motu`, master: false },
            { code: 'hu', name: $localize`Hungarian`, master: true },
            { code: 'ia', name: $localize`Interlingua`, master: false },
            { code: 'id', name: $localize`Indonesian`, master: false },
            { code: 'ie', name: $localize`Interlingue`, master: false },
            { code: 'ga', name: $localize`Irish`, master: false },
            { code: 'ig', name: $localize`Igbo`, master: false },
            { code: 'ik', name: $localize`Inupiaq`, master: false },
            { code: 'io', name: $localize`Ido`, master: false },
            { code: 'is', name: $localize`Icelandic`, master: true },
            { code: 'it', name: $localize`Italian`, master: true },
            { code: 'iu', name: $localize`Inuktitut`, master: false },
            { code: 'ja', name: $localize`Japanese`, master: true },
            { code: 'jv', name: $localize`Javanese`, master: false },
            { code: 'kl', name: $localize`Kalaallisut, Greenlandic`, master: true },
            { code: 'kn', name: $localize`Kannada`, master: false },
            { code: 'kr', name: $localize`Kanuri`, master: false },
            { code: 'ks', name: $localize`Kashmiri`, master: false },
            { code: 'kk', name: $localize`Kazakh`, master: false },
            { code: 'km', name: $localize`Khmer`, master: false },
            { code: 'ki', name: $localize`Kikuyu, Gikuyu`, master: false },
            { code: 'rw', name: $localize`Kinyarwanda`, master: false },
            { code: 'ky', name: $localize`Kyrgyz`, master: false },
            { code: 'kv', name: $localize`Komi`, master: false },
            { code: 'kg', name: $localize`Kongo`, master: false },
            { code: 'ko', name: $localize`Korean`, master: true },
            { code: 'ku', name: $localize`Kurdish`, master: false },
            { code: 'kj', name: $localize`Kwanyama, Kuanyama`, master: false },
            { code: 'la', name: $localize`Latin`, master: false },
            { code: 'lb', name: $localize`Luxembourgish, Letzeburgesch`, master: false },
            { code: 'lg', name: $localize`Ganda`, master: false },
            { code: 'li', name: $localize`Limburgish, Limburgan, Limburger`, master: false },
            { code: 'ln', name: $localize`Lingala`, master: false },
            { code: 'lo', name: $localize`Lao`, master: false },
            { code: 'lt', name: $localize`Lithuanian`, master: true },
            { code: 'lu', name: $localize`Luba-Katanga`, master: false },
            { code: 'lv', name: $localize`Latvian`, master: false },
            { code: 'gv', name: $localize`Manx`, master: false },
            { code: 'mk', name: $localize`Macedonian`, master: false },
            { code: 'mg', name: $localize`Malagasy`, master: false },
            { code: 'ms', name: $localize`Malay`, master: false },
            { code: 'ml', name: $localize`Malayalam`, master: false },
            { code: 'mt', name: $localize`Maltese`, master: false },
            { code: 'mi', name: $localize`MÄori`, master: false },
            { code: 'mr', name: $localize`Marathi`, master: false },
            { code: 'mh', name: $localize`Marshallese`, master: false },
            { code: 'mn', name: $localize`Mongolian`, master: false },
            { code: 'na', name: $localize`Nauru`, master: false },
            { code: 'nv', name: $localize`Navajo, Navaho`, master: false },
            { code: 'nb', name: $localize`Norwegian bokmål`, master: false },
            { code: 'nd', name: $localize`North Ndebele`, master: false },
            { code: 'ne', name: $localize`Nepali`, master: false },
            { code: 'ng', name: $localize`Ndonga`, master: false },
            { code: 'nn', name: $localize`Norwegian Nynorsk`, master: false },
            { code: 'no', name: $localize`Norwegian`, master: true },
            { code: 'ii', name: $localize`Nuosu`, master: false },
            { code: 'nr', name: $localize`South Ndebele`, master: false },
            { code: 'oc', name: $localize`Occitan`, master: false },
            { code: 'oj', name: $localize`Ojibwe, Ojibwa`, master: false },
            {
                code: 'cu',
                name: $localize`Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic`,
                master: false
            },
            { code: 'om', name: $localize`Oromo`, master: false },
            { code: 'or', name: $localize`Oriya`, master: false },
            { code: 'os', name: $localize`Ossetian, Ossetic`, master: false },
            { code: 'pa', name: $localize`Panjabi, Punjabi`, master: false },
            { code: 'pi', name: $localize`PÄli`, master: false },
            { code: 'fa', name: $localize`Persian (Farsi)`, master: false },
            { code: 'pl', name: $localize`Polish`, master: true },
            { code: 'ps', name: $localize`Pashto, Pushto`, master: false },
            { code: 'pt', name: $localize`Portuguese`, master: true },
            { code: 'qu', name: $localize`Quechua`, master: false },
            { code: 'rm', name: $localize`Romansh`, master: false },
            { code: 'rn', name: $localize`Kirundi`, master: false },
            { code: 'ro', name: $localize`Romanian`, master: true },
            { code: 'ru', name: $localize`Russian`, master: true },
            { code: 'sa', name: $localize`Sanskrit`, master: false },
            { code: 'sc', name: $localize`Sardinian`, master: false },
            { code: 'sd', name: $localize`Sindhi`, master: false },
            { code: 'se', name: $localize`Northern Sami`, master: false },
            { code: 'sm', name: $localize`Samoan`, master: false },
            { code: 'sg', name: $localize`Sango`, master: false },
            { code: 'sr', name: $localize`Serbian`, master: true },
            { code: 'gd', name: $localize`Scottish Gaelic`, master: false },
            { code: 'sn', name: $localize`Shona`, master: false },
            { code: 'si', name: $localize`Sinhala, Sinhalese`, master: false },
            { code: 'sk', name: $localize`Slovak`, master: true },
            { code: 'sl', name: $localize`Slovene`, master: true },
            { code: 'so', name: $localize`Somali`, master: false },
            { code: 'st', name: $localize`Southern Sotho`, master: false },
            { code: 'es', name: $localize`Spanish; Castilian`, master: true },
            { code: 'su', name: $localize`Sundanese`, master: false },
            { code: 'sw', name: $localize`Swahili`, master: false },
            { code: 'ss', name: $localize`Swati`, master: false },
            { code: 'sv', name: $localize`Swedish`, master: true },
            { code: 'ta', name: $localize`Tamil`, master: false },
            { code: 'te', name: $localize`Telugu`, master: false },
            { code: 'tg', name: $localize`Tajik`, master: false },
            { code: 'th', name: $localize`Thai`, master: false },
            { code: 'ti', name: $localize`Tigrinya`, master: false },
            { code: 'bo', name: $localize`Tibetan Standard, Tibetan, Central`, master: false },
            { code: 'tk', name: $localize`Turkmen`, master: false },
            { code: 'tl', name: $localize`Tagalog`, master: false },
            { code: 'tn', name: $localize`Tswana`, master: false },
            { code: 'to', name: $localize`Tonga (Tonga Islands)`, master: false },
            { code: 'tr', name: $localize`Turkish`, master: true },
            { code: 'ts', name: $localize`Tsonga`, master: false },
            { code: 'tt', name: $localize`Tatar`, master: false },
            { code: 'tw', name: $localize`Twi`, master: false },
            { code: 'ty', name: $localize`Tahitian`, master: false },
            { code: 'ug', name: $localize`Uyghur, Uighur`, master: false },
            { code: 'uk', name: $localize`Ukrainian`, master: true },
            { code: 'ur', name: $localize`Urdu`, master: false },
            { code: 'uz', name: $localize`Uzbek`, master: false },
            { code: 've', name: $localize`Venda`, master: false },
            { code: 'vi', name: $localize`Vietnamese`, master: false },
            { code: 'wa', name: $localize`Walloon`, master: false },
            { code: 'cy', name: $localize`Welsh`, master: false },
            { code: 'wo', name: $localize`Wolof`, master: false },
            { code: 'fy', name: $localize`Western Frisian`, master: false },
            { code: 'xh', name: $localize`Xhosa`, master: false },
            { code: 'yi', name: $localize`Yiddish`, master: false },
            { code: 'yo', name: $localize`Yoruba`, master: false },
            { code: 'za', name: $localize`Zhuang, Chuang`, master: false },
            { code: 'zu', name: $localize`Zulu`, master: false }
        ];
    }

    public getTranslationsOptions(): Locale[] {
        return [
            { code: 'da', name: 'Dansk', master: true },
            { code: 'en', name: 'English', master: true },
            { code: 'de', name: 'Deutsch', master: true },
            { code: 'nb', name: 'Norsk', master: true },
            { code: 'sv', name: 'Svenska', master: true }
        ];
    }

    public getUnzerLocales(): Locale[] {
        return [
            { code: 'en', name: $localize`English`, master: true },
            { code: 'de', name: $localize`German`, master: true }
        ];
    }

    public getLocaleNameFromCode(code: string): Locale | undefined {
        const locales = this.getLocales();
        const locale = locales.find(loc => loc.code === code);
        return locale;
    }

    public getMasterLocales(): Locale[] {
        return this.getLocales().filter(local => local.master === true);
    }

    public getUsersLocale(): string {
        const navigator: any = window.navigator;
        const locale = navigator['userLanguage'] || window.navigator.language;
        return locale.substr(0, locale.indexOf('-'));
    }
}
