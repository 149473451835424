<div class="flex-row no-print">
    <button mat-button [routerLink]="['../acquirers']" class="mat-button-back" color="accent">
        &lt; <span i18n>Back to Acquirers</span>
    </button>
</div>

<mat-card class="card02">
    <mat-card-content class="flex-col flex-632px-mobile-100">
        <router-outlet></router-outlet>
    </mat-card-content>
</mat-card>
