import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BaseRepo } from 'app/endpoints/api/base-repo';
import { Transaction } from '@models/transaction';

@Component({
    selector: 'qp-transaction-details-other-basic',
    templateUrl: './transaction-other-basic.html'
})
export class TransactionOtherBasicComponent {
    @Input() public transaction: Transaction;
    @Input() public disableFraud: boolean;
    @Input() public transactionRepo: BaseRepo;
    @Output() public callBack: EventEmitter<any> = new EventEmitter<any>();
    @Input() public permission: boolean;

    public onMakeCallBack(): void {
        this.callBack.emit();
    }
}
