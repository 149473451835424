<div>
    <button mat-button [routerLink]="['../branding', branding_id]" class="mat-button-back" color="accent">
        &lt; <span i18n>Back to branding</span>
    </button>
</div>

<mat-card class="card02 editor" *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card-title i18n>Edit {{ filename }}</mat-card-title>

    <mat-card-content>
        <textarea #lineCounter class="custom-editor line-counter" wrap='off' readonly>1.</textarea>
        <textarea #codeEditor class="custom-editor code-editor" wrap='off' [(ngModel)]="data"></textarea>
    </mat-card-content>

    <mat-card-actions class="flex-row justify-end">
        <button mat-button [routerLink]="['../branding', branding_id]" i18n>Cancel</button>
        <button mat-button color="accent" (click)="save()" i18n>Save</button>
    </mat-card-actions>
</mat-card>
