<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card *ngIf="account" class="flex-col card02">
        <mat-card-header class="flex-row">
            <mat-icon mat-card-avatar color="accent">place</mat-icon>
            <mat-card-title class="flex-row justify-start items-center" i18n>Company address</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <qp-address-form *ngIf="account.customer_address" [address]="account.customer_address" address_field="customer_address" [readOnly]="address_readonly" (updated)="update($event)"></qp-address-form>
            <p *ngIf="address_readonly" i18n>
                Please contact Unzer to update this information
            </p>
        </mat-card-content>
    </mat-card>

    <mat-card class="flex-col card02" *ngIf="account && !hide_billing_card">
        <mat-card-header class="flex-row">
            <mat-icon mat-card-avatar color="accent">work</mat-icon>
            <mat-card-title class="flex-row justify-start items-center" i18n>Billing address</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-slide-toggle color="accent" [(ngModel)]="hide_billing" (ngModelChange)="setBillingAsCustomer()" [checked]="hide_billing" color="accent" [disabled]="!(['/account', 'patch'] | qpPermission)" i18n>
                Same as customer address
            </mat-slide-toggle>

            <qp-address-form *ngIf="!hide_billing" [address]="account.billing_address" address_field="billing_address" (updated)="update($event)"></qp-address-form>
        </mat-card-content>
    </mat-card>

    <mat-card class="flex-col card02" *ngIf="account && (['/account', 'delete'] | qpPermission)">
        <mat-card-header class="flex-row">
            <mat-icon mat-card-avatar color="accent">tune</mat-icon>
            <mat-card-title class="flex-row justify-start items-center" i18n>Account settings</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <button mat-raised-button (click)="deleteAccount()" class="delete-btn" i18n>Delete account</button>
        </mat-card-content>
    </mat-card>
</div>
