<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card class="flex-col rg8 card02">
        <div class="flex-row cg16">
            <mat-icon color="accent">list</mat-icon>
            <h5 i18n>Users</h5>
        </div>

        <mat-card-content class="flex-col rg16">
            <mat-nav-list>
                <mat-list-item *ngFor="let agreement of agreements; let last = last;" [ngClass]="{ 'bottom-border': !last }" [routerLink]="['../user', agreement.id]">
                    <div matListItemTitle>
                        <span>{{ agreement.user?.email }}</span>
                        <span *ngIf="agreement.support"> (support)</span>
                    </div>
                    <div matListItemMeta *ngIf="agreement.owner">
                        <span
                            class="qp-label success"
                            i18n-matTooltip
                            matTooltip="An owner automatically has all permissions enabled"
                            i18n
                        >Owner</span>
                    </div>
                    <div matListItemMeta *ngIf="!agreement.accepted">
                        <span class="qp-label" matTooltip="Invitation pending" i18n>Pending</span>
                    </div>
                </mat-list-item>
            </mat-nav-list>
            <div>
                <button mat-raised-button (click)="inviteUser()" i18n>INVITE USER</button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="flex-col rg8 card02">
        <div class="flex-row cg16">
            <mat-icon color="accent">list</mat-icon>
            <h5 i18n>System users</h5>
        </div>

        <mat-card-content class="flex-col rg16">
            <div class="margin-negative">
                <mat-nav-list>
                    <mat-list-item
                        *ngFor="let agreement of system_agreements; let last = last;"
                        [ngClass]="{ 'bottom-border': !last }"
                        [routerLink]="['../user', agreement.id]">
                            <div matListItemTitle>{{ agreement.user?.name }}</div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
            <div>
                <button mat-raised-button (click)="createSystemUser()" i18n>
                    CREATE SYSTEM USER
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
