import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { AgreementService } from '@services/agreement/agreement.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { ShopifyRepo } from 'app/endpoints/server/shopify-repo';
import { Observable } from 'rxjs';

@Injectable()
export class ShopifyService {
    public secrets: any;
    public hmac: string;
    public shop: string;
    public code: string;
    public nonce: string;
    public installedApps: Array<any>;
    public activeDomains: Array<string>;
    public domainInstalledApps: any;

    constructor(
        private shopifyRepo: ShopifyRepo,
        private snackbar: QpSnackBar,
        private dialogService: DialogService,
        private router: Router,
        private agreementService: AgreementService
    ) {}

    public handleSecrets(params: Params): void {
        this.hmac = params?.hmac;
        this.shop = params?.shop;
        this.code = params?.code;
        this.nonce = params?.nonce;
    }

    // Shopify Setup should be triggered when starting the flow from our end
    public setup(data: string | undefined, merchantId: number | undefined): Observable<any> {
        return this.shopifyRepo.setupShopify({
            data,
            code: this.code,
            signature: this.hmac,
            merchant_id: merchantId,
            shop_domain: this.shop
        }).pipe(
            catchHttpStatusError([HttpStatusCode.Unauthorized], () => {
                this.dialogService.alert(
                    $localize`Invalid signature`,
                    $localize`<span>The request from Shopify could not be verified</span>`
                );
                return {
                    app_name: ''
                };
            }, false)
        );
    }

    // Shopify Activate should be triggered when pressing "install" or "manage" from Shopify Admin page
    public installOrManage(data: string): void {
        this.shopifyRepo.verifySignature({
            data,
            signature: this.hmac
        }).pipe(
            catchHttpStatusError([HttpStatusCode.BadRequest], (error: HttpErrorResponse) => {
                if (error.error?.message?.includes('Invalid signature')) {
                    this.dialogService.alert(
                        $localize`Invalid signature`,
                        $localize`<span>The request from Shopify could not be verified</span>`
                    );
                }
                throw error;
            })
        ).subscribe((shopifyData: any) => {
            // The shop does not exist, so we begin the install
            const randomNumber = Math.floor(Math.random() * 100000) + 1;
            const nonce = `QP${randomNumber}`;
            const appKey = this.getAppKeyFromAppName(this.translateAppNameFromServer(shopifyData.app_name));
            let scope = 'write_payment_gateways,write_payment_sessions';
            if (shopifyData.app_name === 'quickpay-tagorders') {
                scope = 'read_orders,write_orders';
            }
            document.location.href = `https://${this.shop}/admin/oauth/authorize?client_id=${appKey}&scope=${scope}&redirect_uri=${this.secrets.redirect_url}&state=${nonce}`;
        });
    }

    public getAppKeyFromAppName(name: string): string | null {
        if (this.getSecrets()[name]) {
            return this.getSecrets()[name].appKey;
        } else {
            return null;
        }
    }

    private getSecrets(): any {
        if (this.secrets === undefined || this.secrets === null) {
            this.secrets = environment.shopifyApps;
        }

        return this.secrets;
    }

    public translateAppNameToServer(name: string): string {
        switch (name) {
            case 'default':
                return 'quickpay';
            case 'mobilePayOnline':
                return 'quickpay-mobilepay';
            case 'vipps':
                return 'quickpay-vippspsp';
            case 'anyday':
                return 'quickpay-anydaysplit';
            default:
                return 'quickpay-' + name;
        }
    }

    public translateAppNameFromServer(name: string): string {
        switch (name) {
            case 'quickpay':
                return 'default';
            case 'quickpay-mobilepay':
                return 'mobilePayOnline';
            case 'quickpay-vippspsp':
                return 'vipps';
            case 'quickpay-anydaysplit':
                return 'anyday';
            default:
                return name.replace('quickpay-', '');
        }
    }

    public possibleApps(): Array<string> {
        const apps: any = { ...environment.shopifyApps };
        if (this.agreementService.agreement?.account?.id === 151897) {
            delete apps?.redirect_url;
            delete apps?.tagorders;
            return Object.keys(apps);
        } else if (window.location.href.includes('staging')) {
            return ['default', 'mobilePayOnline', 'anyday', 'vipps', 'swish', 'trustly'];
        } else {
            return ['default', 'mobilePayOnline', 'anyday', 'vipps', 'swish'];
        };
    }
}
