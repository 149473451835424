import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountModule } from './account/account.module';
import { AcquirersModule } from './acquirers/acquirers.module';
import { AgreementsModule } from './agreements/agreements.module';
import { BillingModule } from './billing/billing.module';
import { BrandingsModule } from './brandings/brandings.module';
import { FraudfilterModule } from './fraudfilter/fraudfilter.module';
import { IntegrationModule } from './integration/integration.module';
import { MerchantModule } from './merchant/merchant.module';
import { ResellerModule } from './reseller/reseller.module';
import { ThirdpartyModule } from './thirdparty/thirdparty.module';
import { UsersModule } from './users/users.module';

@NgModule({
    imports: [
        AcquirersModule,
        AccountModule,
        AgreementsModule,
        BillingModule,
        BrandingsModule,
        FormsModule,
        FraudfilterModule,
        IntegrationModule,
        MerchantModule,
        ReactiveFormsModule,
        ResellerModule,
        ThirdpartyModule,
        UsersModule
    ]
})
export class SettingsModule {}
