import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Transaction } from '@models/transaction';
import { DialogService } from '@widgets/dialog/dialog.service';
import { TransactionsService } from 'app/components/transactions/transactions.service';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'qp-transaction-details-customer-info',
    templateUrl: 'customer-info.component.html'
})
export class TransactionCustomerInfoComponent {
    @Output() public transactionChange = new EventEmitter();
    @Input() public is_delete_enabled = false;

    public different_address = false;
    public data_exists = false;

    private _transaction: Transaction;

    constructor(
        private dialogService: DialogService,
        private transactionsService: TransactionsService
    ) {}

    @Input()
    public get transaction(): Transaction {
        return this._transaction;
    }
    public set transaction(val) {
        this._transaction = val;
        this.different_address = this.differentAddress(val);
        this.data_exists = this.dataExists(val);
    }

    public deleteAddress(): void {
        this.dialogService.confirm(
            $localize`Deleting customer information?`,
            $localize`This action deletes all customer shipping and invoice information. The transaction data will not be deleted.`,
            $localize`Delete info`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            map(() => this.transactionsService.deleteCustomerInfo(this.transaction))
        ).subscribe((transaction) => this.transactionChange.emit(transaction));
    }

    private dataExists(transaction: Transaction): boolean {
        return transaction.invoice_address
            ? Object.values(transaction.invoice_address).some(value => !!value)
            : false || transaction.shipping_address
                ? Object.values(transaction.shipping_address).some(value => !!value)
                : false;
    }

    private differentAddress(transaction: Transaction): boolean {
        if (!transaction.shipping_address && !!transaction.invoice_address) {
            return true;
        }

        if (!transaction.invoice_address) {
            return false;
        }

        if (!transaction.shipping_address || JSON.stringify(transaction.invoice_address) !== JSON.stringify(transaction.shipping_address)) {
            return true;
        }

        return false;
    }
}
