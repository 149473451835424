<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card class="card02" *ngIf="is_wizard_visible">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Activate account" icon="credit_card" i18n-headingText></qp-section-heading>
            <qp-billing-wizard layout-padding [(account)]="account" [customer]="customer" [plan]="plan" (accountUpdated)="updatePlan()" (finished)="finishWizard($event)"> </qp-billing-wizard>
        </div>
    </mat-card>

    <mat-card class="card02" *ngIf="!owner">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Info" icon="credit_card" i18n-headingText></qp-section-heading>
            <mat-card-subtitle class="text-grey" i18n>
                This user is not an owner-user of this account - You have to be an owner to see and update billing information.
            </mat-card-subtitle>
        </div>
    </mat-card>

    <mat-card class="card02 flex-col rg12" *ngIf="!is_wizard_visible">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Billing method" icon="credit_card" i18n-headingText></qp-section-heading>
            <mat-card-subtitle class="text-grey" i18n>
                The billing method used for paying your Quickpay invoices.
            </mat-card-subtitle>
        </div>

        <qp-billing-payment-method [customer]="customer" [plan]="plan" (updated)="getCustomer()" [user_info_tpl]="reseller_info_tpl"> </qp-billing-payment-method>

        <ng-template #reseller_info_tpl>
            <div><span class="subtitle1" i18n>Updated by</span>&nbsp;<span>{{ customer?.attributes?.billing_info?.user_email }}</span></div>
            <div><span i18n>on</span>&nbsp;<qp-date-field [date]="customer?.attributes?.billing_info?.updated_at"></qp-date-field></div>
        </ng-template>

        <qp-pricing-and-terms *ngIf="(['Merchant'] | qpAccountType) && plan && plan.attributes" [plan]="plan" [domains]="account?.shop_urls" (switchToDefaultPlan)="defaultPlanSelected()"></qp-pricing-and-terms>
    </mat-card>

    <qp-invoice-emails [customer]="customer" *ngIf="!is_clearhaus"></qp-invoice-emails>

    <qp-invoice-requisition-number [customer]="customer" *ngIf="!is_clearhaus"></qp-invoice-requisition-number>

    <mat-card class="card02" *ngIf="!is_clearhaus">
        <qp-section-heading headingText="Invoices" icon="folder" i18n-headingText></qp-section-heading>
        <qp-invoices class="margin t8" [customer]="customer" [plan]="plan"></qp-invoices>
    </mat-card>
</div>
