<h5 mat-dialog-title *ngIf="data.headline">{{ data.headline }}</h5>
<mat-dialog-content class="grey-border-bottom" *ngIf="data.msg">
    <span class="pre-wrap" [innerHTML]="data.msg"></span>
</mat-dialog-content>
<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button tabindex="-1" mat-button color="accent" (click)="dialogRef.close(false)">
        {{ data.cancelButtonText }}
    </button>
    <button tabindex="-1" mat-raised-button color="accent" (click)="dialogRef.close(true)">
        {{ data.confirmButtonText }}
    </button>
</mat-dialog-actions>
