export const CARD_HEADER: Array<string> = [
    'Id',
    'Accepted',
    'Test mode',
    'Acquirer',
    'Created at',
    'Type',
    'Brand',
    'First 6',
    'Last 4',
    'Exp month',
    'Exp year',
    'Country',
    'Is 3d secure',
    'Customer ip',
    'Customer country',
    'Qp status code',
    'Qp status msg',
    'Aq status code',
    'Aq status msg',
    'Callback url',
    'Callback success',
    'Callback response code'
];

export const MERCHANT_HEADER: Array<string> = [
    'Id',
    'Contact email',
    'Contact phone',
    'Shop name',
    'Shop urls',
    'Shopsystem',
    'Active acquirers',
    'Timezone',
    'Locale',
    'Company name',
    'Company street',
    'Company city',
    'Company zipcode',
    'Company region',
    'Company country code',
    'Company vat no',
    'Callback url',
    'Default branding id',
    'Default payment language',
    'Default payment methods',
    'Default text on statement',
    'Allow test transactions',
    'Autofee',
    'Created at',
    'Suspended at',
    'Logging stops at',
    'Suspended'
];

export const PAYMENT_HEADER: Array<string> = [
    'Id',
    'Order id',
    'Accepted',
    'Test mode',
    'Branding id',
    'Acquirer',
    'Facilitator',
    'Created at',
    'Currency',
    'Amount',
    'Balance',
    'Fee',
    'Type',
    'Brand',
    'First 6',
    'Last 4',
    'Exp month',
    'Exp year',
    'Country',
    'Is 3d secure',
    'Fraud suspected',
    'Customer ip',
    'Customer country',
    'Operation type',
    'Operation date',
    'Qp status code',
    'Qp status msg',
    'Aq status code',
    'Aq status msg',
    'Callback url',
    'Callback success',
    'Callback response code'
];

export const PAYOUT_HEADER: Array<string> = [
    'Id',
    'Order id',
    'Accepted',
    'Test mode',
    'Branding id',
    'Acquirer',
    'Facilitator',
    'Created at',
    'Currency',
    'Type',
    'Brand',
    'First 6',
    'Last 4',
    'Exp month',
    'Exp year',
    'Country',
    'Is 3d secure',
    'Customer ip',
    'Customer country',
    'Qp status code',
    'Qp status msg',
    'Aq status code',
    'Aq status msg',
    'Callback url',
    'Callback success',
    'Callback response code'
];

export const SETTLEMENT_HEADER: Array<string> = [
    'Id',
    'Settled',
    'Currency',
    'Period',
    'Payout amount',
    'Payout date',
    'Payout reference number',
    'Payout descriptor',
    'Summary sales',
    'Summary credits',
    'Summary refunds',
    'Summary chargebacks',
    'Summary fees',
    'Summary other postings',
    'Summary net',
    'Fees sales',
    'Fees refunds',
    'Fees authorisations',
    'Fees credits',
    'Fees interchange',
    'Fees scheme',
    'Fees minimum processing',
    'Fees service',
    'Fees wire transfer',
    'Fees chargebacks',
    'Fees retrieval requests',
    'Reserve amount',
    'Reserve date',
    'Reserve cancelled date',
    'Reserve reference number',
    'Reserve descriptor'
];

export const SUBSCRIPTION_HEADER: Array<string> = [
    'Id',
    'Description',
    'Order id',
    'Accepted',
    'Test mode',
    'Branding id',
    'Acquirer',
    'Facilitator',
    'Created at',
    'Currency',
    'Type',
    'Brand',
    'First 6',
    'Last 4',
    'Exp month',
    'Exp year',
    'Country',
    'Is 3d secure',
    'Customer ip',
    'Customer country',
    'Qp status code',
    'Qp status msg',
    'Aq status code',
    'Aq status msg',
    'Callback url',
    'Callback success',
    'Callback response code'
];
