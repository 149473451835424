import { Component, Input } from '@angular/core';
import { Customer } from '@models/customer';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { Animations } from 'app/animations/animations';
import { CustomerRepo } from 'app/endpoints/invoice/customer-repo';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'qp-invoice-requisition-number',
    templateUrl: './requisition_number.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class InvoiceRequisitionNumberComponent extends BaseLoadingDirective {
    @Input() public customer?: Customer;

    constructor(
        private customerRepo: CustomerRepo,
        private dialogService: DialogService,
        private snackBar: QpSnackBar
    ) {
        super();
    }

    public removeRequisitionNumber(): void {
        this.dialogService
            .prompt(
                $localize`Are you sure you want to remove this requisition number?`,
                this.customer?.attributes?.requisition_number ?? '',
                $localize`Remove`,
                []
            )
            .afterClosed()
            .pipe(filter((val) => !!val))
            .subscribe(() => {
                if (this.customer?.attributes) {
                    this.customer.attributes.requisition_number = '';
                    this.save();
                }
            });
    }

    public addRequisitionNumber(): void {
        const fields: PromptField[] = [
            {
                placeholder: $localize`Add requisition number`,
                label: $localize`Add requisition number`,
                required: true,
                type: 'text',
                name: 'requisition_number'
            }
        ];

        this.dialogService
            .prompt(
                $localize`Add requisition number`,
                '',
                $localize`Add requisition number`,
                fields,
                {
                    width: '450px'
                }
            )
            .afterClosed()
            .subscribe((returnFields: PromptField[]) => {
                if (returnFields && returnFields[0].value) {
                    if (this.customer?.attributes) {
                        this.customer.attributes.requisition_number =
                            returnFields[0].value;
                        this.save();
                    }
                }
            });
    }

    public save(): void {
        if (this.customer && !this.customer.id) {
            this.customerRepo
                .create(this.customer)
                .subscribe((customer: Customer) => {
                    this.customer = customer;
                    this.showSnackBar();
                });
        } else {
            this.customerRepo.update(this.customer).subscribe(() => {
                this.showSnackBar();
            });
        }
    }

    private showSnackBar(): void {
        this.snackBar.open($localize`Information updated`);
    }
}
