import { Injectable } from '@angular/core';
import { LoyaltyKeyApplication } from '@models/integration/loyalty-key';
import { Observable } from 'rxjs';
import { ServerClient } from './server-client';

@Injectable({
    providedIn: 'root'
})
export class LoyaltyKeyRepo {
    constructor(
        private client: ServerClient
    ) {}

    public checkApplicationActive(): Observable<any> {
        return this.client.get<any>('/loyaltykey-agreement');
    }

    public sendApplication(params: LoyaltyKeyApplication): Observable<any> {
        return this.client.post<LoyaltyKeyApplication>('/loyaltykey-agreement', params);
    }
}
