import { Animations } from 'app/animations/animations';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { catchError, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { Comment, Dispute, DocumentationFile, Event, Reason } from '@models/clearhaus-dispute';
import { Component, OnInit } from '@angular/core';
import { DialogService } from '@widgets/dialog/dialog.service';
import { DisputeRepo } from '@endpoints/server/dispute-repo';
import { EMPTY } from 'rxjs';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { TimeLine } from './time-line';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'qp-dispute-details',
    templateUrl: 'details.html',
    animations: [Animations.getFadeAnimation()]
})
export class DisputeDetailsComponent extends BaseLoadingDirective implements OnInit {
    public dispute?: Dispute;
    public documentation_files_current: Array<DocumentationFile> = [];
    public documentation_files: Array<File> = [];
    public disputeReason?: Reason;
    public timeLine: TimeLine[] = [];
    public previousParams?: any;
    public message = '';

    constructor(
        private disputeRepo: DisputeRepo,
        private dialogService: DialogService,
        private snackBar: QpSnackBar,
        private acivatedRoute: ActivatedRoute,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.getDispute();
    }

    public searchPayment(id?: string): void {
        if (!id) {
            return;
        }
        this.router.navigate(['../../payments'], {
            queryParams: {
                search: id,
                search_by: 'order_id'
            }
        });
    }

    public getDispute(): void {
        this.disputeRepo.getDisputeByID(this.acivatedRoute.snapshot.params.dispute_id).pipe(
            tap((dispute: Dispute) => {
                this.dispute = dispute;
                this.translateDisputeEvents();
            }),
            switchMap((dispute) => this.disputeRepo.getDocumentationFromDispute(dispute.id)),
            tap((documentation_files: DocumentationFile[]) => {
                this.documentation_files_current = documentation_files;

                // backend does not send files back, so we mock a file to display data
                documentation_files.forEach((file: DocumentationFile) => {
                    if (file.name) {
                        this.documentation_files.push(
                            new File(new Array<Blob>(), file.name, { type: file.content_type })
                        );
                    }
                });
                this.setDisputeReason(this.dispute?.reason || '');
            }),
            switchMap(() => this.disputeRepo.getDisputeComment(this.dispute?.id ?? '')),
            map((comment) => this.updateDisputeCommentList(comment.comments))
        ).subscribe();
    }

    public updateDisputeCommentList(comments: Comment[] = []): void {
        this.timeLine = [];

        this.dispute?.events.forEach(event => {
            this.timeLine.push({
                type: 'event',
                date: event.date,
                body: event.event,
                author: ''
            });
        });

        comments.forEach(comment => {
            this.timeLine.push({
                type: 'comment',
                date: comment.created_at,
                body: comment.body,
                author: comment.author.name
            });
        });

        this.timeLine.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    }

    public addFile(files: Array<File>): void {
        files.forEach(file => {
            if(!this.documentation_files_current.some(e => e.name === file.name)) {
                const document: DocumentationFile = {
                    label: 'documentation',
                    name: file.name,
                    content_type: file.type,
                    file
                };

                this.documentation_files_current.push(document);

                this.disputeRepo.addDocumentationToDispute(this.dispute?.id ?? '', document).subscribe(() => {
                    this.snackBar.open($localize`File successfully added to dispute`);
                });
            }
        });
    }

    public addComment(comment: string): void {
        const message = { body: comment };

        this.disputeRepo.addCommentToDispute(this.dispute?.id ?? '', message).subscribe(() => {
            this.message = '';

            this.timeLine.push({
                type: 'comment',
                date: new Date(),
                body: comment,
                author: 'Quickpay'
            });

            this.snackBar.open($localize`Comment successfully added to dispute`);
        });
    }

    public addStamp(stamp: string): void {
        let text = $localize`Are you sure you want to accept the dispute?`;

        if (stamp === 'refuted') {
            text = $localize`Are you sure you want to refute the dispute?`;
        }

        this.dialogService.confirm(
            '',
            text
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            exhaustMap(() =>
                this.disputeRepo.addStampToDispute(this.dispute?.id ?? '', stamp).pipe(
                    map((returnDispute: Dispute) => {
                        this.dispute = returnDispute;
                        this.snackBar.open($localize`Dispute updated`);
                    }),
                    catchError(() => {
                        this.snackBar.open($localize`Error while updating dispute status`);
                        return EMPTY;
                    })
                )
            )
        ).subscribe();
    }

    public setDisputeReason(reason: string): void {
        switch (reason) {
            case 'general':
                this.disputeReason = {
                    title: $localize`General`,
                    explanation: $localize`This is an uncategorised dispute. See below comments for further explanation.`,
                    overturn: '-'
                };
                break;
            case 'incorrect_amount_or_currency':
                this.disputeReason = {
                    title: $localize`Incorrect amount/currency`,
                    explanation: $localize`The customer says they were presented with another amount or currency.`,
                    overturn: $localize`Demonstrate that the correct amount or currency was presented to the customer.`
                };
                break;
            case 'duplicate':
                this.disputeReason = {
                    title: $localize`Duplicate`,
                    explanation: $localize`The customer says you have charged their card multiple times for the same product or service.`,
                    overturn: $localize`Demonstrate that the charges were for separate products or services.`
                };
                break;
            case 'credit_not_processed':
                this.disputeReason = {
                    title: $localize`Credit not processed`,
                    explanation: $localize`The cardholder says that the purchased product was returned or the transaction was otherwise cancelled, but you have not yet refunded or credited the cardholder.`,
                    overturn: $localize`Demonstrate that you in fact refunded the cardholder or that the cardholder is not entitled to a refund.`
                };
                break;
            case 'subscription_cancelled':
                this.disputeReason = {
                    title: $localize`Subscription cancelled`,
                    explanation: $localize`Demonstrate that the subscription was still active and that the cardholder was aware of, and did not follow, your cancellation procedure.`,
                    overturn: $localize`The cardholder says that you continued to charge them after a subscription was cancelled.`
                };
                break;
            case 'product_not_provided':
                this.disputeReason = {
                    title: $localize`Product not provided`,
                    explanation: $localize`The cardholder says they did not receive the product or service purchased.`,
                    overturn: $localize`Demonstrate that the cardholder received a physical product or offline service prior to the date the dispute was initiated, or made use of a digital product or online service prior to the date the dispute was initiated.`
                };
                break;
            case 'product_unacceptable':
                this.disputeReason = {
                    title: $localize`Product unacceptable`,
                    explanation: $localize`The product or service was delivered but was defective, damaged, or not as described.`,
                    overturn: $localize`Demonstrate that the product or service was delivered as described at the time of purchase.`
                };
                break;
            case 'unrecognised':
                this.disputeReason = {
                    title: $localize`Unrecognised`,
                    explanation: $localize`The cardholder can\'t recognise the charge on their bank statement.`,
                    overturn: $localize`Get your customer to withdraw the dispute by helping them identify the charge.`
                };
                break;
            case 'fraud':
                this.disputeReason = {
                    title: $localize`Fraud`,
                    explanation: $localize`The cardholder states that they didn\'t authorise the charge. Typically, this happens when the card was lost/stolen or the cardholder cannot recognise the charge.`,
                    overturn: $localize`Get the cardholder to withdraw the dispute by helping them identify the charge or prove to the bank that the cardholder did authorise the charge.`
                };
                break;
            default:
                this.disputeReason = {
                    title: $localize`No reason present`,
                    explanation: $localize`No explanation present`,
                    overturn: $localize`No overturn information present`
                };
                break;
        }
    }

    public translateDisputeEvents(): void {
        this.dispute?.events.forEach((event: Event) => {
            switch (event.event) {
                case '1st_chargeback':
                    event.event = $localize`The cardholder has disputed this transaction.`;
                    break;
                case 'arbitration':
                    event.event = $localize`The cardholders bank has declined your response and has initiated the arbitration process.`;
                    break;
                case 'retrieval_request':
                    event.event = $localize`The cardholders bank requested documentation about the related transaction.`;
                    break;
                case '1st_chargeback_reversal':
                    event.event = $localize`The cardholders bank withdrew the dispute.`;
                    break;
                case '2nd_chargeback':
                    event.event = $localize`The cardholders bank replied to your documentation/evidence.`;
                    break;
                case 'representment':
                    event.event = $localize`The documentation has been sent to the cardholders bank.`;
                    break;
                case 'dispute_amount_credit':
                    event.event = $localize`The disputed amount has been credited to your account.`;
                    break;
                case 'dispute_amount_debit':
                    event.event = $localize`The disputed amount has been debited from your account.`;
                    break;
            }
        });
    }
}
