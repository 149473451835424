export class ClearhausContract {
    public merchant_id?: string;
    public currency?: string;
    public mcc?: string;
    public plan?: string;
    public gateway?: string;
    public descriptor?: string;
    public threed_secure_mandatory?: boolean;
    public contact: Contact;
    public company: Company;
    public bank_account: BankAccount;
    public payment: Payment;
    public rolling_reserve: Reserve;
    public account_fees: AccountFee;
    public transaction_fees: TransactionFee;
    public signer: Signer;
    public metadata: {
        state: string;
        effective_at: string;
        declined_reason?: string;
    };
    public api_key: ApiKey;
}

export class Contact {
    public name?: string;
    public email?: string;
}

export class Company {
    public name?: string;
    public registration_number?: string;
    public country?: string;
    public address?: string;
    public zipcode_city?: string;
    public websites?: string;
    public business_model?: string;
    public estimated_monthly_turnover?: string;
}

export class BankAccount {
    public iban?: string;
}

export class Payment {
    public period?: string;
    public delay?: number;
    public minimum_amount?: string;
}

export class Reserve {
    public percentage?: string;
    public delay?: number;
    public minimum_amount?: string;
}

export class AccountFee {
    public base: {
        setup?: string;
        payout?: string;
        monthly?: string;
        yearly?: string;
        retrieval?: string;
        chargeback?: string;
        payouts?: string;
        exchange?: string;
        credit?: string;
        refund?: string;
    };
    public percentage: {
        exchange?: string;
    };
}

export class TransactionFee {
    public base: {
        '3dsecure'?: string;
        authorization?: number;
        capture?: string;
        refund?: string;
        credit?: string;
    };
    public minimum: {
        domestic?: string;
        intra?: string;
        inter?: string;
    };
    public percentage: {
        domestic: {
            visa: PaymentCard;
            mastercard: PaymentCard;
        };
        intra: {
            visa: PaymentCard;
            mastercard: PaymentCard;
        };
        inter: {
            visa: PaymentCard;
            mastercard: PaymentCard;
        };
    };
}

class PaymentCard {
    public business: {
        debit?: string;
        credit?: string;
    };
    public consumer: {
        debit?: string;
        credit?: string;
    };
}

export class Signer {
    public name?: string;
    public email?: string;
}

export class ApiKey {
    public token?: string;
    public scope?: string;
    public enabled?: boolean;
}
