import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ColumnsService } from '@services/columns/columns.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { Settlement } from '@models/clearhaus-settlement';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'qp-settlements-table',
    templateUrl: './table-settlements.html',
    animations: [Animations.getFadeAnimation()]
})
export class SettlementsTableComponent extends BaseLoadingDirective implements OnInit {
    @Input() public dataSource: MatTableDataSource<Settlement>;
    @Input() public pageSize: number;
    @Input() public page: number;
    @Output() public getPage: EventEmitter<any> = new EventEmitter<any>();

    public displayColumns: Array<string> = [];
    public columns: Array<string> = [
        'period',
        'sales',
        'refunds',
        'chargebacks',
        'fees',
        'other_postings',
        'total',
        'payout',
        'payout_date',
        'reserve',
        'reserve_date'
    ];

    constructor(
        private columnService: ColumnsService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.dataSource.data.length > 0) {
            let reserveFound = false;
            const data = {
                period: { active: true, name: $localize`Period`, allowChange: true },
                sales: { active: true, name: $localize`Sales`, allowChange: true },
                refunds: { active: false, name: $localize`Refunds`, allowChange: true },
                chargebacks: { active: false, name: $localize`Chargebacks`, allowChange: true },
                fees: { active: false, name: $localize`Fees`, allowChange: true },
                other_postings: { active: false, name: $localize`Other postings`, allowChange: true },
                total: { active: false, name: $localize`Total`, allowChange: true },
                payout: { active: true, name: $localize`Payout`, allowChange: true },
                payout_date: { active: true, name: $localize`Payout date`, allowChange: true },
                reserve: { active: true, name: $localize`Reserve`, allowChange: true },
                reserve_date: { active: true, name: $localize`Reserve date`, allowChange: true }
            };

            this.dataSource.data.forEach((settlement) => {
                if (!!settlement.reserve && !reserveFound) {
                    this.columnService.setColumns('settlements', data);
                    reserveFound = true;
                }
            });

            this.columnService.getColumnsAsObservable().subscribe(() => {
                this.displayColumns = this.columnService.formatColumns('settlements', this.columns);
            });
        }
    }
}
