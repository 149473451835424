import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Payment } from '@models/payment';
import { TransactionService } from '@services/transaction/transaction.service';

@Component({
    selector: 'qp-capture-payment-dialog',
    templateUrl: 'capture-payment-dialog.html'
})
export class CapturePaymentDialogComponent implements OnInit {
    public amount: number;
    public max_capture: number;
    public type: string;

    constructor(
        private transactionService: TransactionService,
        public dialogRef: MatDialogRef<CapturePaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public payment: Payment
    ) {}

    public ngOnInit(): void {
        this.amount = 0;
        this.type = 'full';
        this.max_capture = this.transactionService.maxCapture(this.payment);
    }

    public capture(): void {
        this.dialogRef.close(this.type === 'partial' ? this.amount : this.max_capture);
    }
}
