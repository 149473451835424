import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LoaderService } from 'app/interceptors/loader/loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'qp-loader',
  standalone: true,
  imports: [CommonModule, MatProgressBar],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit {
    public loading$: Observable<boolean>;

    constructor(private loaderService: LoaderService) {}

    public ngOnInit(): void {
        this.loading$ = this.loaderService.loading$;
    }
}
