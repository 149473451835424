<form name="group" #f="ngForm">
    <h5 mat-dialog-title *ngIf="!dialog_data?.currency" i18n>Create group</h5>
    <h5 mat-dialog-title *ngIf="!!dialog_data?.currency" i18n>Edit group</h5>
    <mat-dialog-content class="grey-border-bottom">
        <div *ngIf="group" class="flex-col">
            <mat-form-field>
                <mat-label i18n>Name</mat-label>
                <input matInput [(ngModel)]="group.name" type="text" required name="name" />
            </mat-form-field>

            <div class="flex-row justify-start items-center">
                <qp-currency-input
                    class="flex-48"
                    [autoFocus]="true"
                    name="currency"
                    [(currency)]="group.currency"
                    [disabled]="!!dialog_data?.currency"
                    >
                </qp-currency-input>

                <mat-form-field class="flex-48 margin l4p">
                    <mat-label i18n>Amount</mat-label>
                    <input
                        inputDecimal
                        class="input-right"
                        [currency]="group.currency"
                        matInput
                        name="amount"
                        type="text"
                        required
                        [(ngModel)]="group.amount"
                        [disabled]="!group.currency"
                    />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label i18n>Description</mat-label>
                <textarea matInput name="description" rows="5" [(ngModel)]="group.description"> </textarea>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-row">
        <span class="flex-1"></span>
        <button mat-button color="accent" [mat-dialog-close]="undefined" i18n>
            Close
        </button>
        <button mat-button color="accent" [disabled]="f.invalid" (click)="dialogRef.close(group)" i18n>
            Save
        </button>
    </mat-dialog-actions>
</form>
