<form name="ipForm" #f="ngForm">
    <div class="flex-col rg8">
        <h5 class="margin b0" i18n>Add IP restriction</h5>
        <div class="flex-col">
            <div *ngFor="let ip of ips; let index = index" class="flex-row">
                <span class="self-center">{{ ip.address }}</span>
                <span class="flex-1"></span>
                <button
                    mat-icon-button
                    *ngIf="(['/agreements/:id', 'put'] | qpPermission)"
                    (click)="remove(index)"
                    i18n-matTooltip
                    matTooltip="Remove ip from list"
                >
                    <mat-icon class="mat-18">close</mat-icon>
                </button>
            </div>
        </div>
        <div class="flex-1">
            <button
                mat-raised-button
                color="accent"
                (click)="showInput = true"
                [disabled]="showInput"
                *ngIf="(['/agreements/:id', 'put'] | qpPermission) && agreement.service !== 'payment_window'"
                i18n
            >Add IP restriction</button>
        </div>
        <mat-form-field class="flex-1" *ngIf="showInput" hideRequiredMarker>
            <input
                matInput
                required
                i18n-placeholder
                placeholder="IP address"
                pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$"
                [(ngModel)]="ip"
                type="text"
                name="ip"
            />
            <span class="flex-1"></span>
            <button mat-button matSuffix (click)="save()" [disabled]="f.invalid" i18n>Add IP</button>
            <mat-hint i18n>
                It is also possible to restrict to a network range using CIDR notation, e.g. "1.2.3.4/24"
            </mat-hint>
        </mat-form-field>
    </div>
</form>
