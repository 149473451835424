import { Component, Input } from '@angular/core';
import { Transaction } from '@models/transaction';

@Component({
    selector: 'qp-transaction-details-basket',
    templateUrl: './transaction-details-basket.html'
})
export class TransactionDetailsBasketComponent {
    @Input() public transaction: Transaction;
    public objectKeys = Object.keys;
}
