import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Client } from '../client';

@Injectable()
export abstract class InvoiceClient extends HttpClient {
    public abstract setHeader(name: string, value: string): any;
    public abstract deleteHeader(name: string): any;
}

/* eslint-disable */
export function invoiceClientFactory(httpClient: HttpClient) {
    return new Client(httpClient, '/invoicing', {
        headers: new HttpHeaders({
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
        })
    });
}
