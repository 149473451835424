import { ApiClient } from './api-client';
import { BaseRepo } from './base-repo';
import { Card } from '@models/card';
import { CardToken } from '@models/card-token';
import { FraudReport } from '@models/fraud-report';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaymentLinkUrl } from '@models/payment-link-url';
import { Transaction } from '@models/transaction';

@Injectable({
    providedIn: 'root'
})
export class CardRepo extends BaseRepo {
    constructor(private client: ApiClient) {
        super();
    }

    public createFraudReport(id: number, params: FraudReport): Observable<FraudReport> {
        return this.client.post<FraudReport>('/cards/' + id + '/fraud-report', params).pipe(
            map((item: any) => Object.assign(new FraudReport(), item))
        );
    }

    public get<Card>(id: number): Observable<Card> {
        return this.client.get<Card>('/cards/' + id).pipe(
            map((item: any) => Object.assign(new Card(), item))
        );
    }

    public update(transaction: Transaction): Observable<Card> {
        return this.client.patch<Card>('/cards/' + transaction.id, transaction).pipe(
            map((item: any) => Object.assign(new Card(), item))
        );
    }

    public getAll(params: any): Observable<Card[]> {
        params = params || {};
        return this.client.get<Card>('/cards', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Card(), item)))
        );
    }

    public cancel(id: number): Observable<Card> {
        return this.client.post<Card>('/cards/' + id + '/cancel', {}).pipe(
            map((card: any) => Object.assign(new Card(), card))
        );
    }

    public tokens(id: number): Observable<CardToken> {
        return this.client.post<CardToken>('/cards/' + id + '/tokens', {}).pipe(
            map((item: any) => Object.assign(new CardToken(), item))
        );
    }

    public create(params: any): Observable<Card> {
        params = params || {};
        return this.client.post<Card>('/cards', params).pipe(
            map((card: any) => Object.assign(new Card(), card))
        );
    }

    public replaceLink(id: number, params: any): Observable<PaymentLinkUrl> {
        return this.client.put<Card>('/cards/' + id + '/link', params).pipe(
            map((item: any) => Object.assign(new PaymentLinkUrl(), item))
        );
    }
}
