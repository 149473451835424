import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Transaction } from '@models/transaction';
import { BaseRepo } from 'app/endpoints/api/base-repo';
import { HomepageService } from '@services/homepage/homepage.service';

@Component({
    selector: 'qp-transaction-details-card-basic',
    templateUrl: './transaction-details-card-basic.html'
})
export class TransactionCardBasicComponent {
    @Input() public transaction: Transaction;
    @Input() public disableFraud: boolean;
    @Input() public transactionRepo: BaseRepo;
    @Input() public permission: boolean;
    @Output() public callBack: EventEmitter<any> = new EventEmitter<any>();

    public helpdeskLink: string = this.homepageService.getHelpdeskLink('3d-secure/');
    public showInfo = false;

    constructor(
        private homepageService: HomepageService
    ) {}

    public onMakeCallBack(): void {
        this.callBack.emit();
    }

    public get3DSecureStatus(): string {
        let status = '';
        for (let i = this.transaction.operations.length - 1; i >= 0; i--) {
            if (this.transaction.operations[i].type === 'authorize') {
                status = this.transaction.operations[i]['3d_secure_status'];
                return status ? ' - ' + status : '';
            }
        }
        return '';
    }

    public cardExpired(year?: number, month = -1): boolean {
        return year === null || year === undefined
            ? false
            : year < new Date().getFullYear() || (year === new Date().getFullYear() && month < new Date().getMonth());
    }


    public getCardExpired(): string {
        if (this.transaction.metadata && this.transaction.metadata.exp_month && this.transaction.metadata.exp_year) {
            return ('0' + this.transaction.metadata.exp_month).slice(-2) + '/' + (' ' + this.transaction.metadata.exp_year).slice(-2);
        }
        return '';
    }
}
