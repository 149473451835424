    <mat-dialog-content class="flex-col rg16 grey-border-bottom">
    <h5>{{ header_text }}</h5>
    <p>{{ body_text }}: {{ total_success }}</p>

    <div *ngIf="handled_errors.length >= 1">
        <br />
        {{ error_text }}
        <br />
        <span *ngFor="let error of handled_errors">
            {{ error }}<br />
        </span>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button mat-button color="accent" (click)="dialogRef.close()" i18n>
        Close
    </button>
</mat-dialog-actions>

