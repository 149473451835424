<div class="flex-row no-print">
    <button mat-button [routerLink]="['../acquirers']" color="accent">&lt; <span i18n>Back to</span> {{ acquirerName }}</button>
</div>

<mat-card class="card02">
    <mat-card-content class="flex-col">
        <form class="flex-col rg36" aria-label="Tellerform" autocomplete="off" *ngIf="acquirer" [formGroup]="tellerForm">
            <div class="flex-row" [class.error-border]="formError" [class.warning-border]="tellerForm.dirty && !formError" [class.success-border]="formSaved && tellerForm.pristine">
                <div class="flex-col rg12 flex-1">
                    <h5 i18n>Visa & Mastercard</h5>
                    <mat-form-field>
                        <mat-label i18n>Visa bin</mat-label>
                        <input matInput aria-label="Visa bin" formControlName="visa_bin"/>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n>Mastercard bin</mat-label>
                        <input matInput aria-label="Mastercard bin" formControlName="master_bin"/>
                    </mat-form-field>

                    <qp-form-state-info [form_dirty]="tellerForm.dirty" [form_pristine]="tellerForm.pristine" [form_saved]="formSaved" [form_error]="formError"> </qp-form-state-info>
                </div>
            </div>

            <qp-form-save-discard *ngIf="tellerForm.dirty" [form_dirty]="tellerForm.dirty" [form_invalid]="tellerForm.invalid" (form_save)="save()" (form_discard)="discardChanges()"> </qp-form-save-discard>

            <div class="flex-col rg16" *ngIf="is_admin">
                <h5 i18n>SecurePay</h5>
                <div *ngIf="acquirerName === 'teller'" class="text-grey">
                    <div i18n>SecurePay is per default required for all Teller transactions, and should only be disabled</div>
                    <div i18n>if you have an explicit agreement with Teller to do so.</div>
                </div>
                <mat-slide-toggle formControlName="securepay" name="securepay" aria-label="Accept Teller" (change)="save()" i18n>
                    Enable SecurePay
                </mat-slide-toggle>
            </div>
        </form>
    </mat-card-content>
</mat-card>
