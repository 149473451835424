import { Country } from '@services/country/country.interface';

export class VisaAgreement {
    public id: string;
    public application: Application;
    public contact: Contact;
    public company: Company;
    public people?: People[];
    public websites?: Website[];
    public bank_account: BankAccount;
    public state: string;
    constructor() {
        this.application = new Application();
        this.contact = new Contact();
        this.company = new Company();
        this.bank_account = new BankAccount();
        this.websites = [];
        this.people = [];
        this.state = '';
    }
}

export class Application {
    public voucher_code: string;
    public business_model?: BuisnessModel;
    public signer?: Contact;
    public additional_information?: string;

    constructor() {
        this.signer = new Contact();
        this.business_model = new BuisnessModel();
    }
}

export class BuisnessModel {
    public trading_name?: string;
    public description?: string;
    public recurring?: boolean;
    public physical_delivery?: boolean;
    public delivery_delay?: string;
    public drop_shipping?: boolean;
    public estimate_currency?: string;
    public estimated_monthly_turnover?: number;
    public estimated_average_transaction_amount?: number;
    public files?: DirectorFile[];
}

export class Contact {
    public name?: string;
    public email?: string;
    public phone?: string;
    public skype?: string;
}

export class Company {
    public registration_number?: string;
    public name?: string;
    public address_line_1?: string;
    public zipcode: string;
    public city?: string;
    public country?: string;
    public country_code?: Country;
    public phone?: string;
    public email?: string;
    public ownership_structure?: string;
    public ownership_structure_comment?: string;
}

export class People {
    public id?= '';
    public name?= '';
    public social_security_number?= '';
    public date_of_birth?= '';
    public address_line_1?= '';
    public zipcode = '';
    public city?= '';
    public country?= '';
    public role_director?= false;
    public role_owner?= false;
    public files?= [] as DirectorFile[];
}

export class Website {
    public url?: string;
    public comment?: string;
}

export class BankAccount {
    public currency?: string;
    public bank?: string;
    public swift_code?: string;
    public iban?: string;
}

export class DirectorFile {
    public label?: string;
    public type?: string;
    public file?: File;
    public content_type?: string;
    public name?: string;
    public size?: number;
    public remove?: boolean;
    public id?: string;
}
