import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule } from '@angular/forms';
import { CustomMaterialModule } from '../layout/material/custom-material.module';
import { ShopsystemsComponent } from './shopsystems.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        LayoutModule,
        CustomMaterialModule
    ],
    declarations: [ShopsystemsComponent],
    exports: [ShopsystemsComponent]
})
export class ShopsystemsModule {}
