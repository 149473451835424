import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ColumnsSettingsComponent } from './columns-settings.component';
import { ColumnsSettingsDialogComponent } from './dialog.component';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LayoutModule
    ],
    declarations: [
        ColumnsSettingsComponent,
        ColumnsSettingsDialogComponent
    ],
    exports: [
        ColumnsSettingsComponent
    ]
})
export class ColumnsSettingsModule {}
