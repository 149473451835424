<form [formGroup]="contactForm" class="flex-col rg8">
    <span class="text-grey" i18n>Contact information for the person responsible for creating the application.</span>

    <mat-form-field>
        <mat-label i18n>Your full name</mat-label>
        <input matInput name="contact_name" formControlName="name" type="text" autofocus (change)="modelContact('name', $event.target)" required />
        <mat-error *ngIf="name.errors?.required" i18n>Your name is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Email</mat-label>
        <input matInput name="contact_email" formControlName="email" type="email" (change)="modelContact('email', $event.target)" required />
        <mat-error *ngIf="email.errors?.required" i18n>An email address is required.</mat-error>
        <mat-error *ngIf="email.errors?.email" i18n>Invalid email address.</mat-error>
    </mat-form-field>

    <div>
        <button mat-raised-button [disabled]="contactForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
