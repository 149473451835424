import { Routes } from '@angular/router';
import { PayoutRepo } from '@endpoints/api/payout-repo';
import { SubscriptionRepo } from '@endpoints/api/subscription-repo';
import { CARD_COLUMNS, PAYMENT_COLUMNS, PAYOUT_COLUMNS, SUBSCRIPTION_COLUMNS } from '@services/columns/columns.constants';
import { WildcardComponent } from 'app/components/dashboard/wildcard/wildcard.component';
import { DisputeDetailsComponent } from 'app/components/disputes/dispute-details/details.component';
import { DisputesComponent } from 'app/components/disputes/disputes.component';
import { SettlementDetailsComponent } from 'app/components/settlements/settlement-details/settlement-details.component';
import { SettlementsComponent } from 'app/components/settlements/settlements.component';
import { SupportComponent } from 'app/components/support/support.component';
import { SubscriptionGroupComponent } from 'app/components/transactions/subscription-group/subscription-group.component';
import { CardDetailComponent } from 'app/components/transactions/transaction-detail/card-detail/card-detail.component';
import { PaymentDetailComponent } from 'app/components/transactions/transaction-detail/payment-detail/payment-detail.component';
import { PayoutDetailComponent } from 'app/components/transactions/transaction-detail/payout-detail/payout-detail.component';
import { SubscriptionDetailComponent } from 'app/components/transactions/transaction-detail/subscription-detail/subscription-detail.component';
import { TransactionsComponent } from 'app/components/transactions/transactions.component';
import { admin_routes } from './admin-routes';
import { settings_routes } from './settings-routes';
import { tools_routes } from './tools-routes';

export const account_routes: Routes = [
    ...admin_routes,
    {
        path: '',
        loadComponent: () => import('app/components/dashboard/dashboard.component').then(m => m.DashboardComponent),
        data: {
            settings: {
                allowSuspended: true,
                hotkeys: {
                    combo: ['g d'],
                    description: 'Go to dashboard'
                },
                index: 1,
                title: 'Dashboard',
                icon: 'dashboard'
            }
        }
    },
    {
        path: 'disputes',
        component: DisputesComponent,
        data: {
            settings: {
                index: 20,
                title: 'Disputes',
                icon: 'chat_bubble_outline',
                roles: ['Merchant', 'Reseller']
            }
        }
    },
    {
        path: 'dispute/:dispute_id',
        component: DisputeDetailsComponent,
        data: {
            settings: {
                title: 'Dispute'
            }
        }
    },
    {
        path: 'merchants',
        loadComponent: () => import('app/components/merchants/merchants.component').then(m => m.MerchantsComponent),
        data: {
            settings: {
                index: 10,
                title: 'Merchants',
                icon: 'people',
                permission: { '/merchants': ['get'] },
                roles: ['Reseller'],
                hotkeys: {
                    combo: 'g m',
                    description: 'Go to merchants'
                }
            }
        }
    },
    {
        path: 'merchant/create',
        loadComponent: () => import('app/components/merchants/reseller-create-merchant/reseller-create-merchant.component').then(m => m.ResellerCreateMerchantComponent),
        data: {
            settings: {
                permission: { '/merchants': ['get'] },
                roles: ['Reseller']
            }
        }
    },
    {
        path: 'merchant/delete',
        loadComponent: () => import('app/components/merchants/reseller-bulk-delete-merchant/reseller-bulk-delete-merchant.component').then(m => m.ResellerBulkDeleteMerchantComponent),
        data: {
            settings: {
                permission: { '/merchants': ['get'] },
                roles: ['Reseller']
            }
        }
    },
    {
        path: 'payments',
        component: TransactionsComponent,
        data: {
            columns: PAYMENT_COLUMNS,
            transaction_type: 'payments',
            settings: {
                index: 19,
                roles: ['Merchant'],
                title: 'Payments',
                icon: 'local_atm',
                permission: { '/payments': ['get'] },
                hotkeys: {
                    combo: 'g p',
                    description: 'Go to payments'
                }
            }
        }
    },
    {
        path: 'payment/:payment_id',
        component: PaymentDetailComponent,
        data: {
            settings: {
                title: 'Payment',
                icon: 'local_atm',
                permission: { '/payments/:id': ['get'] }
            }
        }
    },
    {
        path: 'payouts',
        component: TransactionsComponent,
        data: {
            columns: PAYOUT_COLUMNS,
            transaction_type: 'payouts',
            settings: {
                index: 20,
                noneRepo: PayoutRepo,
                roles: ['Merchant'],
                title: 'Payouts',
                icon: 'receipt',
                permission: { '/payouts': ['get'] },
                hotkeys: {
                    combo: 'g o',
                    description: 'Go to payouts'
                }
            }
        }
    },
    {
        path: 'payout/:payout_id',
        component: PayoutDetailComponent,
        data: {
            settings: {
                title: 'Payout',
                permission: { '/payouts/:id': ['get'] }
            }
        }
    },
    {
        path: 'cards',
        component: TransactionsComponent,
        data: {
            columns: CARD_COLUMNS,
            transaction_type: 'cards',
            settings: {
                index: 25,
                roles: ['Merchant'],
                title: 'Saved cards',
                permission: { '/cards': ['get'] },
                icon: 'credit_card',
                hotkeys: {
                    combo: 'g c',
                    description: 'Go to saved cards'
                }
            }
        }
    },
    {
        path: 'card/:card_id',
        component: CardDetailComponent,
        data: {
            settings: {
                title: 'Card'
            }
        }
    },
    {
        path: 'settlements',
        component: SettlementsComponent,
        data: {
            settings: {
                index: 20,
                title: 'Settlements',
                icon: 'paid',
                roles: ['Merchant', 'Reseller']
            }
        }
    },
    {
        path: 'settlement/:settlement_id',
        component: SettlementDetailsComponent,
        data: {
            settings: {
                title: 'Settlement'
            }
        }
    },
    {
        path: 'subscriptions',
        component: TransactionsComponent,
        data: {
            columns: SUBSCRIPTION_COLUMNS,
            transaction_type: 'subscriptions',
            settings: {
                index: 30,
                title: 'Subscriptions',
                noneRepo: SubscriptionRepo,
                icon: 'autorenew',
                roles: ['Merchant'],
                permission: { '/subscriptions': ['GET'] },
                hotkeys: {
                    combo: 'g s',
                    description: 'Go to subscription'
                }
            }
        }
    },
    {
        path: 'subscription/:subscription_id',
        component: SubscriptionDetailComponent,
        data: {
            settings: {
                title: 'Subscription',
                permission: { '/subscriptions/:id': ['GET'] }
            }
        }
    },
    {
        path: 'subscription-groups',
        component: SubscriptionGroupComponent,
        data: {
            settings: {
                title: 'Subscription Groups',
                permission: { '/subscriptions': ['GET'] }
            }
        }
    },
    {
        path: 'support',
        component: SupportComponent,
        data: {
            settings: {
                title: 'Support'
            }
        }
    },
    {
        path: 'tools',
        children: tools_routes,
        data: {
            settings: {
                index: 10,
                header: 'tools',
                content: 'Audit log',
                title: 'Audit log',
                permission: { '/activity': ['get'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'settings',
        children: settings_routes,
        data: {
            settings: {
                index: 40,
                header: 'settings',
                content: 'Users',
                title: 'Users',
                permission: { '/agreements': ['get'] },
                icon: 'people'
            }
        }
    },
    {
        path: 'transactions',
        loadComponent: () => import('app/components/transactions/reseller-transactions.component').then(c => c.ResellerTransactionsComponent),
        data: {
            settings: {
                index: 20,
                icon: 'local_atm',
                title: 'Transactions',
                permission: { '/transactions': ['get'] },
                roles: ['Reseller'],
                hotkeys: {
                    combo: 'g t',
                    description: 'Go to transactions'
                }
            }
        }
    },
    {
        path: 'auditlog',
        loadComponent: () => import('app/components/tools/auditlog/auditlog.component').then(c => c.AuditlogComponent),
        data: {
            settings: {
                index: 21,
                roles: ['Reseller'],
                title: 'Audit log',
                permission: { '/activity': ['get'] },
                icon: 'clear_all'
            }
        }
    },
    {
        path: 'packages',
        loadComponent: () => import('app/components/packages/packages.component').then(c => c.PackagesComponent),
        data: {
            settings: {
                index: 20,
                title: 'Packages',
                icon: 'inventory_2'
            }
        }
    },
    {
        path: 'package/:id',
        loadComponent: () => import('app/components/packages/package-detail/package-detail.component').then(c => c.PackagesDetailsComponent),
        data: {
            settings: {
                title: 'Packages'
            }
        }
    },
    {
        path: 'create-package',
        loadComponent: () => import('app/components/packages/package-create/package-create.component').then(c => c.PackageCreateComponent),
        data: {
            settings: {
                title: 'Packages'
            }
        }
    },
    {
        path: '**',
        component: WildcardComponent
    }
];
