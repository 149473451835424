export const STOREBUDDY_PERMISSIONS = [
    {
        resource: '/account',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/account/logo',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/account/private-key',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acl-resources',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/:acquirer/test',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/bambora',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/clearhaus',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/elavon',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/handelsbanken',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/klarnapayments',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/mobilepayonline',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/mobilepaysubscriptions',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/nets',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/paypal',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/paysafecard',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/resurs',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/sofort',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/swedbank',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/swish',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/teller',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/trustly',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/valitor',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/viabill',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/acquirers/vipps',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/activity/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/activity',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/agreements/:id/api-key',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id/*resource_name',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/brandings/:id/copy',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/authorize',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/cancel',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/fraud-report',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/link',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cards/:id/tokens',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/changelog',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id/certificate',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/cryptography/keys/:id/csr',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-subscriptions',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-subscriptions/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/event-topics',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/:acquirer/:payment_method',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/formulas',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/formulas/:acquirer',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fees/formulas/:acquirer/:payment_method',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fraud-rulesets',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/fraud-rulesets/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/operational-status/acquirers',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payment-method-priority',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payment-methods',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/authorize',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/cancel',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/capture',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/fraud-report',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/link',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/refund',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/renew',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payments/:id/session',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payouts',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payouts/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payouts/:id/credit',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payouts/:id/link',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/payouts/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/ping',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscription-groups',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscription-groups/:id',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscription-groups/:id/capture',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/authorize',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/cancel',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/fraud-report',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/link',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/operations/:operation_id',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/payments',
        get: true,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/recurring',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    },
    {
        resource: '/subscriptions/:id/session',
        get: false,
        post: false,
        put: false,
        delete: false,
        patch: false
    }
];
