import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'qpBrand' })
export class QpBrandPipe implements PipeTransform {
    public transform(brand_name?: string): string {
        const brands: { [key: string]: string } = {
            mastercard: 'Mastercard',
            'mastercard-debet': 'Mastercard Debet',
            'mastercard-debet-dk': 'Mastercard Debet DK',
            mobilepay: 'MobilePay',
            vmpepayment: 'Vipps MobilePay',
            paypal: 'PayPal',
            viabill: 'ViaBill',
            fbg1886: 'Forbrugsforeningen'
        };

        if (brand_name === null || brand_name === undefined) {
            return '';
        }
        if (brands[brand_name]) {
            return brands[brand_name];
        }
        return brand_name.charAt(0).toUpperCase() + brand_name.substring(1);
    }
}
