import { Component } from '@angular/core';
import { Animations } from 'app/animations/animations';
import { SessionService } from '@services/session/session.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';

@Component({
    selector: 'qp-acquirers',
    templateUrl: 'acquirers.html',
    animations: [Animations.getFadeAnimation()]
})
export class AcquirersComponent extends BaseLoadingDirective {

    constructor(
        public sessionService: SessionService
    ) {
        super();
    }
}
