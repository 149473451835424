import { Injectable } from '@angular/core';
import { Mail } from '@interfaces/mail';
import { Observable } from 'rxjs';
import { ServerClient } from './server-client';

@Injectable({
    providedIn: 'root'
})
export class SupportRepo {
    constructor(private client: ServerClient) {}

    public sendSupportMail(data: Mail): Observable<void> {
        return this.client.post<void>('/support/merchant', data);
    }

    public sendSalesMail(data: Mail): Observable<void> {
        return this.client.post<void>('/sales/merchant', data);
    }
}
