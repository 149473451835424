export interface ShopSystem {
    name: string;
    version?: string;
    releaseDate?: string;
    links?: {
        da: string;
        en: string;
    };
    options?: {
        api_user: {
            agreement: boolean;
            api_key: boolean;
            permissions?: string;
        };
        merchant?: {
            agreement: boolean;
            private_key: boolean;
        };
        payment_window?: {
            agreement: boolean;
            api_key: boolean;
        };
    };
}

export const SHOPSYSTEMS: ShopSystem[] = [
    // Names are in alphabetical order to save ordering on every load
    { name: 'amaster Webshop', version: '', releaseDate: '' },
    { name: 'Arastta', version: '', releaseDate: '' },
    { name: 'BGsys', version: '', releaseDate: '' },
    { name: 'CBSubs', version: '', releaseDate: '' },
    { name: 'Chainbox', version: '', releaseDate: '' },
    { name: 'Checkfront', version: '', releaseDate: '' },
    { name: 'Combine Shop', version: '', releaseDate: '' },
    { name: 'Collectaz', version: '', releaseDate: '' },
    { name: 'Craft Commerce', version: '', releaseDate: '' },
    {
        name: 'Custom-made',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://learn.quickpay.net/tech-talk/',
            en: 'https://learn.quickpay.net/tech-talk/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true
            },
            payment_window: {
                agreement: true,
                api_key: true
            }
        }
    },
    { name: 'DanaWeb-Shop', version: '', releaseDate: '' },
    {
        name: 'Dandomain WebShop',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/dandomain/',
            en: 'https://quickpay.net/helpdesk/integrations/dandomain/'
        },
        options: {
            merchant: {
                agreement: false,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true
            }
        }
    },
    { name: 'Danhost', version: '', releaseDate: '' },
    { name: 'Denus Webshop', version: '', releaseDate: '' },
    {
        name: 'DoCas',
        version: '',
        releaseDate: '',
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true
            }
        }
    },
    { name: 'Drupal - Commerce', version: '', releaseDate: '' },
    { name: 'Drupal - Ubercart', version: '', releaseDate: '' },
    { name: 'Dynamicweb', version: '', releaseDate: '' },
    { name: 'Easy Digital Downloads', version: '', releaseDate: '' },
    { name: 'EasyMe', version: '', releaseDate: '' },
    {
        name: 'ECWID',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/ecwid/',
            en: 'https://quickpay.net/helpdesk/integrations/ecwid/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true
            }
        }
    },
    { name: 'E-conomic', version: '', releaseDate: '' },
    { name: 'eRestaurant Suite Pro' },
    { name: 'eShop+', version: '', releaseDate: '' },
    { name: 'Event Booking', version: '', releaseDate: '' },
    { name: 'Event Espresso', version: '', releaseDate: '' },
    { name: 'Eventii', version: '', releaseDate: '' },
    { name: 'FacilityNet', version: '', releaseDate: '' },
    { name: 'Fenerum', version: '', releaseDate: '' },
    { name: 'ForeningLet.dk', version: '', releaseDate: '' },
    { name: 'Foreningsadministrator', version: '', releaseDate: '' },
    { name: 'gohosting.camp', version: '', releaseDate: '' },
    { name: 'Golden Planet - OpenBizBox', version: '', releaseDate: '' },
    { name: 'Groupcare', version: '', releaseDate: '' },
    {
        name: 'HikaShop',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/hikashop/',
            en: 'https://quickpay.net/helpdesk/integrations/hikashop/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: false
            },
            payment_window: {
                agreement: true,
                api_key: true
            }
        }
    },
    { name: 'ideal.shop', version: '', releaseDate: '' },
    { name: 'Iteras', version: '', releaseDate: '' },
    { name: 'Ivaldi', version: '', releaseDate: '' },
    { name: 'Klubmodul', version: '', releaseDate: '' },
    { name: 'Loaded Commerce', version: '', releaseDate: '' },
    {
        name: 'Magento 1',
        version: '3.5.9',
        releaseDate: '2021-09-28',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/magento/',
            en: 'https://quickpay.net/helpdesk/integrations/magento/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: true,
                api_key: true
            }
        }
    },
    {
        name: 'Magento 2',
        version: '2.1.2',
        releaseDate: '2021-06-29',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/magento/',
            en: 'https://quickpay.net/helpdesk/integrations/magento/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: true,
                api_key: true
            }
        }
    },
    { name: 'Mentoclub', version: '', releaseDate: '' },
    { name: 'mono.net webshop', version: '', releaseDate: '' },
    {
        name: 'nopCommerce',
        version: '4.42',
        releaseDate: '2021-03-21',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/nopcommerce/',
            en: 'https://quickpay.net/helpdesk/integrations/nopcommerce/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true,
                permissions: 'nopCommerce'
            },
            payment_window: {
                agreement: true,
                api_key: true
            }
        }
    },
    {
        name: 'NemmeHjemmesider',
        version: '',
        releaseDate: '',
        options: {
            merchant: {
                agreement: true,
                private_key: false
            },
            api_user: {
                agreement: true,
                api_key: true
            }
        }
    },
    { name: ' NEWWWEB CMS WEBSHOP', version: '', releaseDate: '' },
    { name: 'Onlinebooq', version: '', releaseDate: '' },
    { name: 'OnlinePOS', version: '', releaseDate: '' },
    { name: 'OpenCart', version: '', releaseDate: '' },
    { name: 'osCommerce', version: '', releaseDate: '' },
    { name: 'osCommerce - Unique Free', version: '', releaseDate: '' },
    { name: 'Other', version: '', releaseDate: '' },
    { name: 'PayPlans', version: '', releaseDate: '' },
    {
        name: 'PrestaShop',
        version: '4.1.4',
        releaseDate: '2021-11-2',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/prestashop/',
            en: 'https://quickpay.net/helpdesk/integrations/prestashop/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true,
                permissions: 'PrestaShop'
            }
        }
    },
    { name: 'redSHOP', version: '', releaseDate: '' },
    { name: 'Safeticket', version: '', releaseDate: '' },
    { name: 'Scannet WebShop', version: '', releaseDate: '' },
    {
        name: 'Shopware 5',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/shopware/',
            en: 'https://quickpay.net/helpdesk/integrations/shopware/'
        },
        options: {
            merchant: {
                agreement: false,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true
            }
        }
    },
    {
        name: 'Shopware 6',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/shopware/',
            en: 'https://quickpay.net/helpdesk/integrations/shopware/'
        },
        options: {
            merchant: {
                agreement: false,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true
            }
        }
    },
    {
        name: 'Shopify',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/shopify/',
            en: 'https://quickpay.net/helpdesk/integrations/shopify/'
        },
        options: {
            api_user: {
                agreement: true,
                api_key: true,
                permissions: 'Shopify'
            }
        }
    },
    {
        name: 'Shopify + Bold Subscriptions',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/shopify/',
            en: 'https://quickpay.net/helpdesk/integrations/shopify/'
        },
        options: {
            api_user: {
                agreement: true,
                api_key: true,
                permissions: 'Shopify'
            }
        }
    },
    { name: 'Shoporama', version: '', releaseDate: '' },
    { name: 'SHUP', version: '', releaseDate: '' },
    { name: 'Simplero', version: '', releaseDate: '' },
    { name: 'SmartWeb', version: '', releaseDate: '' },
    { name: 'Spreedly', version: '', releaseDate: '' },
    { name: 'Suitcase', version: '', releaseDate: '' },
    { name: 'Sumo Shop', version: '', releaseDate: '' },
    { name: 'Sylius', version: '', releaseDate: '' },
    { name: 'Tallykey', version: '', releaseDate: '' },
    { name: 'Tea Commerce', version: '', releaseDate: '' },
    { name: 'The Cart Press', version: '', releaseDate: '' },
    { name: 'Tomato Cart', version: '', releaseDate: '' },
    { name: 'Typo3 Multishop', version: '', releaseDate: '' },
    { name: 'Ucommerce', version: '', releaseDate: '' },
    { name: 'Umbraco', version: '', releaseDate: '' },
    { name: 'UniFi', version: '', releaseDate: '' },
    {
        name: 'VirtueMart',
        version: '',
        releaseDate: '',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/virtuemart/',
            en: 'https://quickpay.net/helpdesk/integrations/virtuemart/'
        },
        options: {
            merchant: {
                agreement: true,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true
            },
            payment_window: {
                agreement: true,
                api_key: false
            }
        }
    },
    { name: 'Wannafind', version: '', releaseDate: '' },
    { name: 'Weebio', version: '', releaseDate: '' },
    { name: 'WHMCS', version: '', releaseDate: '' },
    {
        name: 'WooCommerce',
        version: '6.4.0',
        releaseDate: '2021-08-20',
        links: {
            da: 'https://quickpay.net/dk/helpdesk/integrations/woocommerce/',
            en: 'https://quickpay.net/helpdesk/integrations/woocommerce/'
        },
        options: {
            merchant: {
                agreement: false,
                private_key: true
            },
            api_user: {
                agreement: false,
                api_key: true,
                permissions: 'WooCommerce'
            }
        }
    },
    { name: 'Wordpress eCommerce', version: '', releaseDate: '' },
    { name: 'ZenCart', version: '', releaseDate: '' }
];
