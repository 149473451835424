import { Component, Input } from '@angular/core';
import { ColumnsSettingsDialogComponent } from './dialog.component';

import { DialogService } from '@widgets/dialog/dialog.service';

@Component({
    selector: 'qp-columns-settings',
    template:
        '<button mat-icon-button (click)="openDialog()">' +
        '<mat-icon matTooltip="Columns settings">settings_applications</mat-icon>' +
        '</button>'
})
export class ColumnsSettingsComponent {
    @Input() public table: string;

    constructor(private dialogService: DialogService) {}

    public openDialog(): void {
        this.dialogService.component(ColumnsSettingsDialogComponent, { table: this.table });
    }
}
