import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Acquirer, TellerBased } from '@models/acquirers/acquirer';
import { Acquirers } from '@models/acquirers/acquirers';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { Animations } from 'app/animations/animations';
import { AcquirerRepo } from 'app/endpoints/api/acquirer-repo';

@Component({
    selector: 'qp-advance-teller-based',
    templateUrl: 'teller-based.html',
    animations: [Animations.getFadeAnimation()]
})
export class AdvanceTellerBasedComponent implements OnInit {
    public acquirerName: keyof Acquirers;
    public acquirer: TellerBased | undefined;
    public tellerForm: UntypedFormGroup;
    public formSaved = false;
    public formError?: Error;
    public is_admin = this.agreementService.isAdmin();

    constructor(
        private acquirerRepo: AcquirerRepo,
        private acquirerService: AcquirerService,
        private agreementService: AgreementService,
        private snackBack: QpSnackBar,
        private activatedRoute: ActivatedRoute
    ) {}

    public get is_merchant(): boolean {
        return this.agreementService.isMerchant();
    }

    public ngOnInit(): void {
        this.acquirerName = this.activatedRoute.snapshot.queryParams.acquirer;
        this.acquirer = this.acquirerService.acquirers ? this.acquirerService.acquirers[this.acquirerName as keyof Acquirers] : undefined;
        this.setupForm();
    }

    public setupForm(): void {
        this.tellerForm = new UntypedFormGroup({
            securepay: new UntypedFormControl({ value: this.acquirer?.securepay, disabled: false }),
            visa_bin: new UntypedFormControl({ value: this.acquirer?.visa_bin, disabled: this.is_merchant }),
            master_bin: new UntypedFormControl({ value: this.acquirer?.mastercard_bin, disabled: this.is_merchant })
        });
    }

    public discardChanges(): void {
        this.formError = undefined;
        this.acquirer = this.acquirerService.acquirers ? this.acquirerService.acquirers[this.acquirerName as keyof Acquirers] : undefined;
        this.setupForm();
    }

    public save(): void {
        if (!this.acquirer) {
            return;
        }

        this.acquirer.securepay = this.getFormControlByKey('securepay').value;
        this.acquirer.visa_bin = this.getFormControlByKey('visa_bin').value;
        this.acquirer.mastercard_bin = this.getFormControlByKey('master_bin').value;

        this.acquirerRepo.update<Acquirer>(this.acquirerName, this.acquirer).subscribe(acquirer => {
            (this.acquirerService.acquirers as any)[this.acquirerName] = acquirer;
            this.acquirer = acquirer;
            this.formSaved = true;
            this.formError = undefined;
            this.tellerForm.markAsPristine();
            this.tellerForm.markAsUntouched();
            const msg =
                $localize`Updated the ${this.acquirerName} settings`;
            this.snackBack.open(msg);
        });
    }

    public getFormControlByKey(key: string): UntypedFormControl {
        return this.tellerForm.get(key) as UntypedFormControl;
    }
}
