import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule } from '@angular/forms';
import { SearchFieldComponent } from './base/searchfield.component';
import { SearchFieldLargeComponent } from './large/search-field-large.component';
import { SearchFieldSmallComponent } from './small/search-field-small.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        LayoutModule
    ],
    declarations: [
        SearchFieldSmallComponent,
        SearchFieldComponent,
        SearchFieldLargeComponent
    ],
    exports: [
        SearchFieldSmallComponent,
        SearchFieldLargeComponent
    ]
})
export class SearchFieldModule {}
