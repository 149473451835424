<form name="deleteAccount" #f="ngForm">
    <h5 i18n mat-dialog-title>Are you sure you want to delete {{ account?.shop_name }}?</h5>

    <div mat-dialog-content class="flex-col rg8" *ngIf="(['Quickpay'] | qpMerchantType)">
        <span i18n>To help us improve our service, we would like to ask why you want to delete your account</span>

        <mat-radio-group name="value" [(ngModel)]="data.value" class="flex-col rg12">
            <mat-radio-button value="I do not want to disclose" color="accent" i18n>I do not want to disclose</mat-radio-button>
            <mat-radio-button value="We no longer need a payment gateway" color="accent" i18n>We no longer need a payment gateway</mat-radio-button>
            <mat-radio-button value="We are switching to another payment gateway" color="accent" i18n>We are switching to another payment gateway</mat-radio-button>
            <mat-radio-button value="Duplicate account" color="accent" i18n>Duplicate account</mat-radio-button>
            <mat-radio-button value="other" color="accent" i18n>Other</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="data.value === 'We are switching to another payment gateway' || data.value === 'other'">
            <mat-label i18n>Why do you want to delete your account?</mat-label>
            <textarea matInput [(ngModel)]="data.reason" name="reason" rows="5"> </textarea>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="justify-end">
        <button mat-button [mat-dialog-close]="false" i18n>Cancel</button>
        <button mat-button color="accent" (click)="close()" type="submit" i18n>Delete account</button>
    </div>
</form>
