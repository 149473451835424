import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { SpinnerButtonComponent } from './spinner-button.component';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule, MatTooltipModule, MatIconModule],
    declarations: [SpinnerButtonComponent],
    exports: [SpinnerButtonComponent]
})
export class SpinnerButtonModule {}
