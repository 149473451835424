<h5 class="margin t40" i18n>Pricing and terms</h5>

<div class="subtitle1 margin b10" i18n>Gateway agreement</div>

<div class="flex-col rg10">
    <table class="gateway-table" *ngIf="plan && plan.attributes">
        <tbody>
            <tr>
                <td i18n>Monthly fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.price_per_month ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
            <tr>
                <td i18n>Gateway fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ ((plan.attributes.price_per_transaction ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
            </tr>

            <tr *ngIf="!!domains && domains.length > 1">
                <td i18n>
                    Additional domains - {{ ((plan.attributes.shop_url_fee ?? 0) / 100) | number: '1.2-2' }} {{ plan.attributes.currency }} / domain / month
                </td>

                <td class="width-100px">
                    <strong class="subtitle2" >
                        {{ ((plan.attributes.shop_url_fee ?? 0) / 100) * (domains.length -1) | number: '1.2-2' }} {{ plan.attributes.currency }}
                    </strong>
                </td>
                <td class="width-100px">/ <span i18n>month</span></td>
            </tr>
        </tbody>
    </table>

    <div class="flex-row">
        <p class="small-text" i18n>Additional fees apply.</p>&nbsp;
        <p class="small-text cursor text-blue" (click)="showAdditionalPrices()" i18n>View detailed list.</p>
    </div>
    <div *ngIf="plan?.attributes?.tier === 'customized'" class="flex-row items-center width-75pct">
        <p class="flex-1 small-text" i18n>Your gateway agreement is set up to a customized plan.</p>
        <div class="flex-1 flex-row justify-center">
            <button mat-raised-button class="max-height-38" (click)="switchToDefaultPrices()" i18n>Switch to default prices</button>
        </div>
    </div>
    <div *ngIf="plan?.attributes?.tier !== 'customized' && plan?.attributes?.tier !== 'merchant'" class="flex-row items-center width-75pct">
        <p class="flex-1 small-text" i18n>Your gateway agreement is set up to a old pricing plan. You can switch to the new <a href="https://quickpay.net/prices-eu/">default pricing agreement</a> . You will not be able to switch back to your current plan</p>
        <div class="flex-1 flex-row justify-center">
            <button mat-raised-button class="max-height-38" (click)="switchToDefaultPrices()" i18n>Switch to default prices</button>
        </div>
    </div>
</div>

<ng-container *ngIf="contract_state.state$ | async as contract">
    <div class="subtitle1 margin b10 t40" i18n>Visa and Mastercard prices</div>

    <div class="flex-col rg10">
        <table>
            <tbody>
                <tr>
                    <td i18n>EU/EEA consumer cards</td>
                    <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.percentage.intra.visa.consumer.credit | number: '1.2-2' }} %</strong></td>
                    <td class="width-100px">/ <span i18n>transaction</span></td>
                    <td class="width-150px" i18n>minimum {{contract.transaction_fees.minimum.intra | number: '1.2-2' }} {{contract.currency}}</td>
                </tr>
                <tr>
                    <td i18n>Non-EU/EEA consumer cards</td>
                    <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.percentage.inter.visa.consumer.credit | number: '1.2-2' }} %</strong></td>
                    <td class="width-100px">/ <span i18n>transaction</span></td>
                    <td class="width-150px" i18n>minimum {{contract.transaction_fees.minimum.inter | number: '1.2-2' }} {{contract.currency}}</td>
                </tr>
                <tr>
                    <td>
                        <div class="flex-row">
                            <p class="small-text" i18n>Capture</p>
                            <mat-icon class="s18 text-grey" i18n-matTooltip matTooltip="Request to debit funds from the buyer's card after the delivery of goods/services.">info_outline</mat-icon>
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td class="width-100px"><p class="text-green subtitle2">{{ contract.transaction_fees.base.capture || '0' | free }}</p></td>
                </tr>
                <tr>
                    <td>
                        <div class="flex-row">
                            <p class="small-text" i18n>Refund</p>
                            <mat-icon class="s18 text-grey" i18n-matTooltip matTooltip="Crediting back funds to the buyer's card.">info_outline</mat-icon>
                        </div>
                    </td>
                    <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.base.refund | number: '1.2-2' }} {{contract.currency}}</strong></td>
                    <td class="width-100px">/ <span i18n>transaction</span></td>
                    <td></td>
                </tr>
                <tr>
                    <td i18n>Authorization</td>
                    <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.base.authorization | number: '1.2-2' }} {{contract.currency}}</strong></td>
                    <td class="width-100px">/ <span i18n>transaction</span></td>
                    <td></td>
                </tr>
                <tr>
                    <td i18n>Settlement currency</td>
                    <td></td>
                    <td></td>
                    <td class="width-100px">{{contract.currency}}</td>
                </tr>
                <tr>
                    <td i18n>Settlement</td>
                    <td></td>
                    <td></td>
                    <td class="width-100px"><p class="text-green subtitle2">{{contract.account_fees.base.payout || '0' | free}}</p></td>
                </tr>
                <tr>
                    <td i18n>Settlement cycle</td>
                    <td></td>
                    <td colspan="2"><p class="small-text subtitle2 text-right" i18n>{{ payout_period }} with a delay of {contract.payment?.delay, plural, =1 {{{contract.payment?.delay}} day} other {{{contract.payment?.delay}} days}}</p></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <div class="flex-row">
            <p class="small-text" i18n>Additional fees apply.</p>&nbsp;
            <p class="small-text cursor text-blue" (click)="showAdditionalClearhausPrices()" i18n>View detailed list.</p>
        </div>
    </div>
</ng-container>
