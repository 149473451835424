import { AgreementService } from '@services/agreement/agreement.service';
import { Animations } from 'app/animations/animations';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';

@Component({
    selector: 'qp-third-integration',
    templateUrl: './thirdparty.html',
    styleUrls: ['thirdparty.scss'],
    animations: [Animations.getFadeAnimation()]
})
export class ThirdpartyComponent extends BaseLoadingDirective implements OnInit {
    public integration_settings: Array<{ title: string; tpl: string }>;

    constructor(
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.integration_settings = [];
        environment.thirdpartyList.forEach((integration: string) => {
            const integration_setting = {
                title: '',
                tpl: integration
            };

            switch (integration) {
                case 'economic':
                    integration_setting.title = 'e-conomic';
                    break;

                case 'loyaltykey':
                    if (new QpMerchantTypePipe(this.agreementService).transform(['Quickpay'])) {
                        integration_setting.title = 'Loyalty Key';
                    }
                    break;

                default:
                    integration_setting.title = integration.charAt(0).toUpperCase() + integration.substring(1);
                    break;
            }

            if (!!integration_setting.title) {
                this.integration_settings.push(integration_setting);
            }
        });

        this.integration_settings.sort();
    }
}
