import { Client } from '../client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ClearhausClient extends HttpClient {
    public abstract setHeader(name: string, value: string): any;
}

/* eslint-disable */
export function clearhausClientFactory(httpClient: HttpClient) {
    return new Client(httpClient, '/clearhaus');
}
