import { Pipe, PipeTransform } from '@angular/core';
import { AgreementService } from '@services/agreement/agreement.service';

@Pipe({ name: 'qpMerchantType' })
export class QpMerchantTypePipe implements PipeTransform {

    constructor(private agreementService: AgreementService) {}

    public transform(merchant_types: string[] | undefined): boolean {
        return this.agreementService.isMerchantType(merchant_types);
    }
}
