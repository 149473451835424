import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Customer } from '@models/customer';
import { Plan } from '@models/plan';
import { QpAccountTypePipe } from '@pipes/qp-account-type.pipe';
import { AgreementService } from '@services/agreement/agreement.service';
import { BillingService } from '@services/billing/billing.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { CustomerRepo } from 'app/endpoints/invoice/customer-repo';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'qp-billing-payment-method',
    templateUrl: './payment.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class BillingPaymentMethodComponent extends BaseLoadingDirective implements OnChanges, OnInit {
    @Input() public user_info_tpl: TemplateRef<any> | null = null;
    @Input() public customer?: Customer;
    @Input() public plan?: Plan;

    @Output() public updated = new EventEmitter<void>();

    public paymentMethod: string;
    public paymentMethods: Array<{ type: string; name: string }>;
    public paymentMethodName: string;

    constructor(
        private billingService: BillingService,
        private customerRepo: CustomerRepo,
        private dialogService: DialogService,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (new QpAccountTypePipe(this.agreementService).transform(['Merchant'])) {
            this.paymentMethods = [
                { type: 'fi', name: $localize`Payment slip` },
                { type: 'ean', name: $localize`EAN invoice` },
                { type: 'betalingsservice', name: $localize`Nets Paymentservice` },
                { type: 'supplier_service', name: $localize`Supplier service` }
            ];
        }

        this.setPaymentMethodVariables();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.customer?.currentValue) {
            this.setPaymentMethodVariables();
        }
    }

    public setPaymentMethodVariables(): void {
        this.paymentMethod = this.customer?.attributes?.billing_info?.payment_method || '';

        if (this.paymentMethods) {
            const target_payment_method = this.paymentMethods.find(payment_method => payment_method.type === this.paymentMethod);
            this.paymentMethodName = target_payment_method ? target_payment_method.name : this.paymentMethodName;
        }
    }

    public getBrand(): string {
        if (this.customer?.attributes?.billing_info?.subscription_info?.brand) {
            return this.customer.attributes.billing_info.subscription_info.brand.replace(/-\w{2}$/, '');
        }
        return '';
    }

    public changeCreditCard(): void {
        this.billingService.createPaymentRepoLink().subscribe(() => {
            this.updateCustomer();
        });
    }

    public removePaymentInfo(): void {
        const bodyText =
            '<span class="material-icons text-darkred">warning</span>' +
            $localize`Your account will become inactive, and you will no longer be able to accept payments through Quickpay`;

        this.dialogService.confirm(
            $localize`Remove card`,
            bodyText,
            $localize`Remove`,
            $localize`Cancel`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() => this.customerRepo.deleteSubscriptionInfo(this.customer?.id ?? NaN)),
            map(() => this.updateCustomer())
        ).subscribe();
    }

    public updateCustomer(): void {
        this.updated.emit();
    }
}
