import { Routes } from '@angular/router';

export const tools_routes: Routes = [
    {
        path: '',
        redirectTo: 'auditlog',
        pathMatch: 'full'
    },
    {
        path: 'auditlog',
        loadComponent: () => import('app/components/tools/auditlog/auditlog.component').then(c => c.AuditlogComponent),
        data: {
            settings: {
                index: 10,
                header: 'tools',
                content: 'Audit log',
                title: 'Audit log',
                permission: { '/activity': ['get'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'paymentlink',
        loadComponent: () => import('app/components/tools/paymentlink/paymentlink.component').then(c => c.PaymentlinkComponent),
        data: {
            settings: {
                index: 20,
                header: 'tools',
                content: 'Payment link',
                title: 'Payment link',
                permission: { '/payments': ['post', 'get'], '/payments/:id/link': ['put'] },
                roles: ['Merchant'],
                hotkeys: {
                    combo: 'g l',
                    description: 'Go to payment link'
                }
            }
        }
    },
    {
        path: 'paymentlink-bulk',
        loadComponent: () => import('app/components/tools/paymentlink/paymentlink-bulk/paymentlink-bulk.component').then(c => c.PaymentlinkBulkComponent),
        data: {
            settings: {
                title: 'Payment link bulk',
                permission: { '/payments': ['post', 'get'], '/payments/:id/link': ['put'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'statistics',
        loadComponent: () => import('app/components/tools/statistics/statistics.component').then(c => c.StatisticsComponent),
        data: {
            settings: {
                index: 10,
                header: 'tools',
                content: 'Statistics',
                title: 'Statistics',
                permission: { '/payments': ['post', 'get'], '/payments/:id/link': ['put'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'virtuelterminal',
        loadComponent: () => import('app/components/tools/paymentlink/paymentlink-virtual-terminal/paymentlink-virtual-terminal.component').then(c => c.PaymentlinkVirtualTerminalComponent),
        data: {
            settings: {
                index: 20,
                header: 'tools',
                content: 'Virtual Terminal',
                title: 'Virtual Terminal',
                permission: { '/payments': ['post'] },
                roles: ['Merchant'],
                hotkeys: {
                    combo: 'g t',
                    description: 'Go to virtual terminal'
                }
            }
        }
    }
];
