import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '@widgets/dialog/dialog.service';
import { ViewChild } from '@angular/core';

@Component({
    selector: 'qp-file-input',
    templateUrl: './file-input.html'
})
export class FileInputComponent {
    @ViewChild('fileInput') public file_input: ElementRef;

    @Input() public label: string;
    @Input() public required_filetype: string;
    @Input() public multiple_files = true;
    @Input() public delete_files = true;
    @Input() public display_files = true;
    @Input() public files: File[] = [];

    @Output() public file_change = new EventEmitter();

    constructor(
        private dialogService: DialogService
    ) {}

    public addFile(event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        let file_exist = false;

        if (fileList) {
            Array.from(fileList).forEach(element => {
                if (this.files.some(e => e.name === element.name)) {
                    file_exist = true;
                } else {
                    this.files.push(element);
                }
            });
        }

        if (file_exist) {
            this.dialogService.alert(
                $localize`Already attached`,
                $localize`One or more of the files with the same content is already attached.`
            );
        }

        this.file_input.nativeElement.value = '';
        this.file_change.emit(this.files);
    }

    public removeFile(index: number): void {
        this.files.splice(index, 1);
        this.file_change.emit(this.files);
    }
}
