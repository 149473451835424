<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card class="card02">
        <div class="flex-row cg16">
            <mat-icon color="accent">tune</mat-icon>
            <h5 i18n>Default payment settings</h5>
        </div>

        <form name="integration" autocomplete="off" [formGroup]="integrationForm" class="flex-col rg16" (ngSubmit)="saveAccount(false)">
            <div class="flex-row" [class.warning-border]="integrationForm.dirty" [class.success-border]="formSaved && integrationForm.pristine">

                <div class="flex-col rg16 flex-1">
                    <mat-form-field>
                        <mat-label i18n>Callback url</mat-label>
                        <input matInput aria-label="callbackUrl" name="callbackUrl" formControlName="callbackUrl" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n>Default text on statement</mat-label>
                        <input matInput aria-label="defaultTextOnStatement" name="defaultTextOnStatement" formControlName="defaultTextOnStatement" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n>Default payment methods</mat-label>
                        <input matInput aria-label="defaultPaymentMethods" name="defaultPaymentMethods" formControlName="defaultPaymentMethods" />
                    </mat-form-field>

                    <div class="flex-row">
                        <mat-form-field class="flex-1">
                            <mat-label i18n>Default payment language</mat-label>
                            <mat-select aria-label="defaultPaymentLanguage" name="defaultPaymentLanguage" formControlName="defaultPaymentLanguage">
                                <mat-option *ngFor="let locale of locales" [value]="locale.code">{{ locale.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-icon-button *ngIf="getIntegrationFormControlByKey('defaultPaymentLanguage').value" (click)="clearDefaultLanguage()" aria-label="Clear">
                            <mat-icon i18n-matTooltip matTooltip="Clear">close</mat-icon>
                        </button>
                    </div>

                    <qp-form-state-info [form_dirty]="integrationForm.dirty" [form_pristine]="integrationForm.pristine" [form_saved]="formSaved" form_error=""> </qp-form-state-info>
                </div>
            </div>

            <div *ngIf="integrationForm.dirty">
                <button mat-raised-button color="accent" type="submit" i18n>Save changes</button>
            </div>

            <mat-slide-toggle aria-label="Autofee" name="autofee" formControlName="autofee" (change)="saveAccount(true)" i18n>
                Autofee
            </mat-slide-toggle>

            <mat-slide-toggle aria-label="allowTestTransactions" name="allowTestTransactions" formControlName="allowTestTransactions" (change)="saveAccount(true)">
                Allow test transactions
            </mat-slide-toggle>

            <a href="https://learn.quickpay.net/tech-talk/appendixes/test/" target="_blank">
                <div class="flex-row cg8 ustify-center items-center">
                    <mat-icon i18n-matTooltip matTooltip="Allow transactions completed using test data">info</mat-icon>
                    <span i18n>Test card information</span>
                </div>
            </a>
        </form>
    </mat-card>

    <mat-card class="card02">
        <div class="flex-row cg16">
            <mat-icon color="accent">map</mat-icon>
            <h5 i18n>Integration</h5>
        </div>

        <div class="flex-row" *ngIf="(['Quickpay'] | qpMerchantType)">
            <span *ngIf="shopsystemLink">
                <a color="accent" [href]="shopsystemLink" target="_blank" i18n> {{ account?.shopsystem }} setup guide </a>
            </span>

            <div *ngIf="!shopsystemLink">
                <a [href]="helpdeskLink" target="_blank" color="accent" i18n> Integration guide </a>
            </div>
        </div>

        <div *ngIf="(['Unzer'] | qpMerchantType)" i18n>
            You can find our currently available plug-ins here:
            <a href="https://www.unzer.com/de/direct_shopsystems" target="_blank" color="accent">Unzer Direct Plug-Ins</a>
        </div>

        <div class="margin-negative">
            <mat-list class="flex-col rg4">
                <mat-list-item [ngClass]="{ 'bottom-border': !!agreements.length }" *ngIf="!shopsystem.options || (shopsystem.options && shopsystem.options.merchant)">
                    <div matListItemTitle>{{ sessionAgreement?.account?.shop_name }}</div>
                    <div matListItemLine class="text-grey font-weight-normal" *ngIf="!shopsystem.options || (shopsystem.options && shopsystem.options.merchant.agreement)" i18n>Merchant id: {{ sessionAgreement?.account?.id }}</div>
                    <div matListItemMeta>
                        <button mat-raised-button color="accent" *ngIf="!shopsystem.options || (shopsystem.options && shopsystem.options.merchant.private_key)" (click)="copyPrivateKey()" i18n>Private key</button>
                    </div>
                </mat-list-item>

                <ng-container *ngFor="let agreement of api_key_agreements; let last = last">
                    <mat-list-item [ngClass]="{ 'bottom-border': !last }">
                        <div matListItemTitle>{{ getUserName(agreement) }}</div>
                        <div matListItemLine *ngIf="!shopsystem.options || (shopsystem.options && agreement.service && shopsystem.options[agreement.service].agreement)" i18n>
                            Agreement id: {{ agreement.id }}
                        </div>
                        <div matListItemMeta *ngIf="shopsystem.options && agreement.service" [hidden]="isTemplateSet(agreement, shopsystem.options[agreement.service].permissions)">
                            <button mat-button color="accent" *ngIf="!shopsystem.options || (shopsystem.options && agreement.service && shopsystem.options[agreement.service].permissions)" (click)="setPermissions(agreement, shopsystem.options[agreement.service].permissions)" i18n>
                                Update permissions
                            </button>
                        </div>
                        <div matListItemMeta>
                            <button mat-raised-button color="accent" *ngIf="!shopsystem.options || (shopsystem.options && agreement.service && shopsystem.options[agreement.service].api_key)" (click)="copyApiKey(agreement)" i18n>
                                Api key
                            </button>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
    </mat-card>
</div>
