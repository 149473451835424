import { ApiModule } from 'app/endpoints/api/api.module';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { DisputeDetailsComponent } from './dispute-details/details.component';
import { DisputesComponent } from './disputes.component';
import { DisputesTableComponent } from './table-disputes/table-disputes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PaginatorModule } from '@widgets/qp-paginator/qp-paginator.module';
import { PipesModule } from '@pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        PaginatorModule,
        PipesModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        DisputeDetailsComponent,
        DisputesComponent,
        DisputesTableComponent
    ]
})
export class DisputesModule {}
