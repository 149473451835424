import { AlertDialogComponent } from './alert/alert.dialog.component';
import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/portal';
import { ConfirmDialogComponent } from './confirm/confirm.dialog.component';
import { CopyDialogComponent } from './copy/copy.dialog.component';
import { Injectable, TemplateRef } from '@angular/core';
import { LoadingDialogComponent } from './loading/loading.dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Params } from '@interfaces/params';
import { PromptDialogComponent } from './prompt/prompt.component';
import { PromptField } from './prompt/options.interface';
import { TemplateDialogComponent } from './template/template.dialog.component';
import { UnsavedChnagesDialogComponent } from './unsaved-change/unsaved-change.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private breakpointObserver: BreakpointObserver,
        private matDialog: MatDialog
    ) {}

    public alert(
        headline: string,
        msg: string,
        headline_icon?: string
    ): MatDialogRef<AlertDialogComponent> {
        return this.matDialog.open(AlertDialogComponent, {
            data: {
                headline,
                headline_icon,
                msg
            },
            ...{ maxWidth: '600px' } as MatDialogConfig
        });
    }

    public unsavedChangesConfirm(
        config: MatDialogConfig = { maxWidth: '600px' }
    ): MatDialogRef<UnsavedChnagesDialogComponent> {
        return this.matDialog.open(UnsavedChnagesDialogComponent, {
            data: {},
            ...config
        });
    }

    public component(
        component: ComponentType<any> | TemplateRef<any>,
        data = {},
        config: MatDialogConfig = { maxWidth: '600px' }
    ): MatDialogRef<ComponentType<any> | TemplateRef<any>> {
        return this.matDialog.open(component, {
            data,
            ...config
        });
    }

    public confirm(
        headline: string,
        msg: string,
        confirmButtonText: string = $localize`Confirm`,
        cancelButtonText: string = $localize`Cancel`
    ): MatDialogRef<ConfirmDialogComponent> {
        return this.matDialog.open(ConfirmDialogComponent, {
            data: {
                headline,
                msg,
                confirmButtonText,
                cancelButtonText
            }
        });
    }

    public copy(
        header: string,
        content: string,
        msg: string,
        isSensitive = false
    ): MatDialogRef<CopyDialogComponent> {
        const fullscreen = this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall]);
        let config: MatDialogConfig = {
            maxWidth: '600px'
        };

        if (fullscreen) {
            config = {
                height: '100%',
                width: '100%',
                maxHeight: '100vh',
                maxWidth: '100vw'
            };
        }

        return this.matDialog.open(CopyDialogComponent, {
            data: {
                header,
                content,
                msg,
                isSensitive
            },
            ...config
        });
    }

    public template(
        headline: string,
        template: TemplateRef<any>,
        config: MatDialogConfig = { maxWidth: '600px' }
    ): MatDialogRef<TemplateDialogComponent> {
        return this.matDialog.open(TemplateDialogComponent, {
            data: {
                headline,
                template
            },
            ...config
        });
    }

    public prompt(
        headline: string,
        body_text: string,
        result_button_text: string,
        options: PromptField[] = [],
        config: MatDialogConfig = { maxWidth: '600px' }
    ): MatDialogRef<PromptDialogComponent> {
        return this.matDialog.open(PromptDialogComponent, {
            autoFocus: true,
            data: {
                headline,
                body_text,
                result_button_text,
                options
            },
            ...config
        });
    }

    public loading(
        headline: string,
        params?: BehaviorSubject<Params>
    ): MatDialogRef<LoadingDialogComponent> {
        return this.matDialog.open(LoadingDialogComponent, {
            data: {
                headline,
                params
            },
            ...{ maxWidth: '600px', height: '200px', disableClose: true } as MatDialogConfig
        });
    }
}
