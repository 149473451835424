import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Account } from '@interfaces/account';
import { AgreementService } from '@services/agreement/agreement.service';
import { HomepageService } from '@services/homepage/homepage.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { AccountRepo } from 'app/endpoints/api/account-repo';

@Component({
    selector: 'qp-economic',
    templateUrl: './economic.html',
    styleUrls: ['../../thirdparty.scss']
})
export class EconomicComponent implements OnInit {
    public account: Account | undefined;
    public agreementID: number | undefined;
    public type = 'token';
    public helpdeskLink: string;
    public disabled: boolean;
    public eform: UntypedFormGroup;
    public formSaved: boolean;
    public formError: any;

    constructor(
        private accountRepo: AccountRepo,
        private homepageService: HomepageService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        this.account = this.agreementService.agreement?.account;
        this.agreementID = this.agreementService.agreement?.id;
        if (this.account?.integration_settings?.economic?.username) {
            this.type = 'user';
        }
        this.disabled = !this.agreementService.hasPermission('/account', 'patch');
        this.helpdeskLink = this.homepageService.getHelpdeskLink('integrations/e-conomic/');
        this.setupForm();
    }

    public setupForm(): void {
        this.eform = new UntypedFormGroup({
            active: new UntypedFormControl({ value: this.account?.integration_settings?.economic?.active, disabled: this.disabled }),
            agreement_no: new UntypedFormControl({
                value: this.account?.integration_settings?.economic?.agreement,
                disabled: this.disabled
            }),
            agreement_token: new UntypedFormControl({
                value: this.account?.integration_settings?.economic?.agreement_token,
                disabled: this.disabled
            })
        });
    }

    public discardChanges(): void {
        this.formError = undefined;
        this.setupForm();
    }

    public saveEconomic(): void {
        if (!this.account?.integration_settings.economic) {
            return;
        }
        const data: any = {};

        this.account.integration_settings.economic.active = this.getEFormControlByKey('active').value;
        this.account.integration_settings.economic.agreement = this.getEFormControlByKey('agreement_no').value;
        this.account.integration_settings.economic.agreement_token = this.getEFormControlByKey('agreement_token').value;
        data.integration_settings = this.account.integration_settings;

        if (this.type === 'token') {
            data.integration_settings.economic.username = null;
            data.integration_settings.economic.password = null;
        } else {
            data.integration_settings.economic.agreement_token = null;
        }

        this.accountRepo
            .update(data, {}, '')
            .pipe(
                tap((account: Account) => {
                    if (!this.agreementService.agreement?.account) {
                        return;
                    }
                    this.agreementService.agreement.account = account;
                    this.snackBar.open($localize`Economic saved`);
                    this.formSaved = true;
                    this.eform.markAsPristine();
                    this.eform.markAsUntouched();
                }),
                catchError(error => {
                    this.formError = error;
                    return of();
                })
            )
            .subscribe();
    }

    public openEconomic(): void {
        window.open(
            'https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=pONklS7SHNync7kJuTZxpsCbOieBAbINFs9a0yNDwcY1',
            '_blank'
        );
    }

    public getEFormControlByKey(key: 'active' | 'agreement_token' | 'agreement_no'): UntypedFormControl {
        return this.eform.get(key) as UntypedFormControl;
    }
}
