import { Component, Input } from '@angular/core';

@Component({
    selector: 'qp-form-state-info',
    templateUrl: './form-state-info.html'
})
export class FormStateInfoComponent {
    @Input() public form_dirty: boolean;
    @Input() public form_pristine: boolean;
    @Input() public form_saved: boolean;
    @Input() public form_error: any;

    public errorMessage(): string {
        const error = this.form_error.error;
        let message = error.message || '';

        if (error.errors) {
            message += '\n\n';
            for (const key in error.errors) {
                if (error.errors.hasOwnProperty(key)) {
                    const paramError = error.errors[key];
                    let paramMessage = '';
                    if (Array.isArray(paramError)) {
                        for (let i = 0; i < paramError.length; i++) {
                            if (i > 0) {
                                paramMessage += ', ';
                            }
                            paramMessage += paramError[i];
                        }
                    } else {
                        paramMessage = paramError;
                    }
                    message += key + ': ' + paramMessage + '\n';
                }
            }
        }

        return message;
    }
}
