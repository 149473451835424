import { Component, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-template-dialog',
    styleUrls: ['template-dialog.scss'],
    templateUrl: 'template-dialog.html'
})
export class TemplateDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<TemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            headline: string;
            template: TemplateRef<any>;
        }
    ) {}
}
