import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiClient } from './api-client';
import { FraudReport } from '@models/fraud-report';
import { Payment } from '@models/payment';
import { PaymentLinkUrl } from '@models/payment-link-url';
import { Subscription } from '@models/subscription';
import { Transaction } from '@models/transaction';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionRepo {
    constructor(private client: ApiClient) {}

    public createFraudReport(id: number, params: FraudReport): Observable<FraudReport> {
        return this.client.post<FraudReport>('/subscriptions/' + id + '/fraud-report', params).pipe(
            map((item: any) => Object.assign(new FraudReport(), item)
        ));
    }

    public createRecurring(id: number, params: any): Observable<Payment> {
        return this.client.post<Payment>('/subscriptions/' + id + '/recurring', params).pipe(
            map((item: any) => Object.assign(new Payment(), item)
        ));
    }

    public update(transaction: Transaction | Subscription): Observable<Subscription> {
        return this.client.patch<Subscription>('/subscriptions/' + transaction.id, transaction).pipe(
            map((item: any) => Object.assign(new Subscription(), item)
        ));
    }

    public get<Subscription>(id: number): Observable<Subscription> {
        return this.client.get<Subscription>('/subscriptions/' + id).pipe(map((item: any) => Object.assign(new Subscription(), item)));
    }

    public getAll(params: any): Observable<Subscription[]> {
        params = params || {};
        return this.client.get<Subscription>('/subscriptions', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Subscription(), item))
        ));
    }

    public getPayments(id: number, params: any): Observable<Payment[]> {
        params = params || {};
        return this.client.get<Payment>('/subscriptions/' + id + '/payments', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Payment(), item)
        )));
    }

    public cancel(id: number): Observable<Subscription> {
        return this.client.post<Subscription>('/subscriptions/' + id + '/cancel', {}).pipe(
            map((subscription: any) => Object.assign(new Subscription(), subscription)
        ));
    }

    public create(params: any): Observable<Subscription> {
        params = params || {};
        return this.client.post<Subscription>('/subscriptions', params).pipe(
            map((payment: any) => Object.assign(new Subscription(), payment)
        ));
    }

    public replaceLink(id: number, params: any): Observable<PaymentLinkUrl> {
        return this.client.put<Subscription>('/subscriptions/' + id + '/link', params).pipe(
            map((item: any) => Object.assign(new PaymentLinkUrl(), item)
        ));
    }
}
