import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Agreement } from '@interfaces/agreement';
import { Link } from '@interfaces/link';
import { Payment } from '@models/payment';
import { PaymentLinkUrl } from '@models/payment-link-url';
import { AgreementService } from '@services/agreement/agreement.service';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { PaymentRepo } from 'app/endpoints/api/payment-repo';
import { CountryService } from '@services/country/country.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { EMPTY } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

@Component({
    selector: 'qp-preview-branding',
    templateUrl: 'preview.html'
})
export class PreviewBrandingComponent {
    @ViewChild('openIframe') public template: TemplateRef<any>;

    @Input() public brandingId: number;
    @Input() public isButton = true;

    public transaction: { currency: string; order_id: number };
    public linkParams: Link;
    public link: SafeResourceUrl;

    constructor(
        private agreementRepo: AgreementRepo,
        private countryService: CountryService,
        private dialogService: DialogService,
        private paymentRepo: PaymentRepo,
        private sanitizer: DomSanitizer,
        private agreementService: AgreementService
    ) {}

    public preview(): void {
        this.transaction = {
            currency: this.countryService.getCurrencyFromCountryCode(
                this.agreementService.agreement?.account?.customer_address?.country_code ?? ''
            ),
            order_id: Math.floor(Math.random() * 100000000000 + 1)
        };
        this.linkParams = {
            amount: 10,
            autofee: false,
            moto: false,
            framed: true,
            language: 'en',
            branding_id: this.brandingId
        };
        const params = this.linkParams;
        this.agreementRepo.getAll({}, undefined).subscribe((agreements: Agreement[]) => {
            Object.keys(agreements).forEach((key: string) => {
                const agreement: Agreement = agreements[Number(key)];
                if (agreement.service === 'payment_window') {
                    params.agreement_id = agreement.id;
                }
            });
            this.createPaymentLink(params);
        });
    }

    public createPaymentLink(params: any): void {
        this.paymentRepo.create(this.transaction).pipe(
            exhaustMap((payment: Payment) =>
                this.paymentRepo.replaceLink(payment.id ?? NaN, params).pipe(
                    exhaustMap((link: PaymentLinkUrl) => {
                        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(link.url);
                        this.dialogService.template(
                            $localize`Branding`,
                            this.template,
                            { width: '80%' }
                        );
                        return EMPTY;
                    })
                )
            )
        ).subscribe();
    }
}
