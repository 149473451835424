import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@pipes/pipes.module';
import { LayoutModule } from '@widgets/layout/layout.module';
import { ApiModule } from 'app/endpoints/api/api.module';
import { AccountLayoutComponent } from './account-layout.component';
import { AgreementsListComponent } from './agreements-list/agreements-list.component';
import { BaseStateComponent } from './base-state.component';

@NgModule({
    imports: [
        CommonModule,
        ApiModule,
        LayoutModule,
        PipesModule,
        RouterModule
    ],
    declarations: [
        BaseStateComponent,
        AgreementsListComponent,
        AccountLayoutComponent
    ],
    exports: [
        BaseStateComponent
    ]
})
export class BaseLayoutModule {}
