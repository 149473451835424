import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from './material/custom-material.module';
import { SectionHeadingModule } from './section-heading/section-heading.module';

@NgModule({
    imports: [CommonModule],
    exports: [
        CustomMaterialModule,
        SectionHeadingModule
    ]
})
export class LayoutModule {}
