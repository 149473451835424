import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Application, BusinessModel } from '@models/clearhaus-application/application';

@Component({
    selector: 'qp-clearhaus-signup-step-six',
    templateUrl: './business.html'
})
export class ClearhausSignupFormStepSixComponent implements AfterViewInit {
    @Output() public applicationChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _application: Application = Object.assign(
        {
            business_model: {
                trading_name: null,
                description: null,
                recurring: false,
                physical_delivery: false,
                delivery_delay: null,
                drop_shipping: null,
                estimate_currency: null,
                estimated_monthly_turnover: null,
                estimated_average_transaction_amount: null
            }
        },
        new Application()
    );
    public _currencies: { value: string; name: string }[] = [];
    public businessForm = new UntypedFormGroup({
        trading_name: new UntypedFormControl('', Validators.required),
        description: new UntypedFormControl('', Validators.required),
        recurring: new UntypedFormControl('', []),
        physical_delivery: new UntypedFormControl('', []),
        delivery_delay: new UntypedFormControl('', []),
        currency: new UntypedFormControl('', Validators.required),
        turnover: new UntypedFormControl('', Validators.required),
        transaction_amount: new UntypedFormControl('', Validators.required)
    });

    public get currency(): UntypedFormControl {
        return this.businessForm.get('currency') as UntypedFormControl;
    }
    public get delivery_delay(): UntypedFormControl {
        return this.businessForm.get('delivery_delay') as UntypedFormControl;
    }
    public get description(): UntypedFormControl {
        return this.businessForm.get('description') as UntypedFormControl;
    }
    public get physical_delivery(): UntypedFormControl {
        return this.businessForm.get('physical_delivery') as UntypedFormControl;
    }
    public get recurring(): UntypedFormControl {
        return this.businessForm.get('recurring') as UntypedFormControl;
    }
    public get trading_name(): UntypedFormControl {
        return this.businessForm.get('trading_name') as UntypedFormControl;
    }
    public get transaction_amount(): UntypedFormControl {
        return this.businessForm.get('transaction_amount') as UntypedFormControl;
    }
    public get turnover(): UntypedFormControl {
        return this.businessForm.get('turnover') as UntypedFormControl;
    }

    @Input() public get application(): Application {
        return this._application;
    }
    public set application(value: Application) {
        this._application = value;
        this.applicationChange.emit(value);
    }

    @Input() public get currencies(): { value: string; name: string }[] {
        return this._currencies;
    }
    public set currencies(val: { value: string; name: string }[]) {
        this._currencies = val.filter(currency => ['DKK', 'EUR', 'SEK', 'NOK', 'GBP', 'USD'].includes(currency.name));
    }

    constructor() {}

    public ngAfterViewInit(): void {
        this.businessForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(!this.businessForm.invalid);
        });

        this.currency.setValue(this.application.business_model?.estimate_currency);
        this.delivery_delay.setValue(this.application.business_model?.delivery_delay);
        this.description.setValue(this.application.business_model?.description);
        this.physical_delivery.setValue(this.application.business_model?.physical_delivery);
        this.recurring.setValue(this.application.business_model?.recurring);
        this.trading_name.setValue(this.application.business_model?.trading_name);
        this.transaction_amount.setValue(this.application.business_model?.estimated_average_transaction_amount);
        this.turnover.setValue(this.application.business_model?.estimated_monthly_turnover);
        this.validityChange.emit(!this.businessForm.invalid);
    }

    public setFormValidators(): void {
        if (this.application.business_model?.physical_delivery) {
            this.delivery_delay.setValidators(Validators.required);
        } else {
            this.delivery_delay.clearValidators();
        }
        this.delivery_delay.updateValueAndValidity();
    }

    public currencyChange(): void {
        this.currency.setValue(this.application.business_model?.estimate_currency);
    }

    public onCheckboxChange(propName: keyof BusinessModel, changeEvent: MatCheckboxChange): void {
        this.application = {
            ...this.application,
            ...{ business_model: { ...(this.application.business_model ?? new BusinessModel()), [propName]: changeEvent.checked } }
        };
        if (propName === 'physical_delivery') {
            this.setFormValidators();
        }
    }

    public onSelectChange(propName: keyof BusinessModel, changeEvent: any): void {
        this.application = {
            ...this.application,
            ...{ business_model: { ...(this.application.business_model ?? new BusinessModel()), [propName]: changeEvent.value } }
        };
    }

    public modelBusinessModel(propName: keyof BusinessModel, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        this.application = {
            ...this.application,
            ...{ business_model: { ...(this.application.business_model ?? new BusinessModel()), [propName]: input.value } }
        };
    }
}
