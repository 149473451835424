import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentRepo } from '@endpoints/api/payment-repo';
import { Card } from '@models/card';
import { Payment } from '@models/payment';
import { PaymentAction } from '@models/payment-action';
import { Payout } from '@models/payout';
import { Subscription } from '@models/subscription';
import { TransactionService } from '@services/transaction/transaction.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { filter, map, of } from 'rxjs';
import { TransactionsService } from '../../transactions.service';
import { PaymentActionResultDialogComponent } from './payment-action-result-dialog/payment-action-result-dialog.component';

@Component({
    selector: 'qp-payment-action-tool',
    templateUrl: './payment-action-tool.html'
})
export class PaymentActionToolbarComponent implements OnInit {
    @Output() public selectionChange = new EventEmitter();
    @Output() public actionComplete = new EventEmitter();

    @Input() public get selection(): SelectionModel<Card | Payment | Payout | Subscription> {
        return this.selection_value;
    }

    public set selection(val: SelectionModel<Card | Payment | Payout | Subscription>) {
        this.selection_value = val;
        this.selectionChange.emit(this.selection_value);
    }

    public selection_value: SelectionModel<Card | Payment | Payout | Subscription>;
    public actions: Array<PaymentAction> = [
        {
            value: 'capture',
            translated_action_name: $localize`capture`,
            permission: ['/payments/:id/capture', 'post'],
            active: false
        },
        {
            value: 'refund',
            translated_action_name: $localize`refund`,
            permission: ['/payments/:id/refund', 'post'],
            active: false
        },
        {
            value: 'cancel',
            translated_action_name: $localize`cancel`,
            permission: ['/payments/:id/cancel', 'post'],
            active: false
        },
        {
            value: 'delete_customer_info',
            translated_action_name: $localize`delete customer info`,
            permission: ['/payments/:id', 'patch'],
            active: false
        }
    ];
    public action: string;
    public action_process: {
        total_payments: number;
        complete: number;
        errors: Array<{ payment: Payment; error?: Error }>;
    } = {
            total_payments: 0,
            complete: 0,
            errors: []
        };

    constructor(
        private dialogRef: MatDialog,
        private dialogService: DialogService,
        private paymentRepo: PaymentRepo,
        private transactionService: TransactionService,
        private transactionsService: TransactionsService
    ) {}

    public ngOnInit(): void {
        this.updateActions(); // First initialize does not subscribe to change
        this.selection.changed.subscribe(() => {
            this.updateActions();
        });
    }

    public updateActions(): void {
        this.actions[0].active = false;
        this.actions[1].active = false;
        this.actions[2].active = false;
        this.actions[3].active = false;

        for (const payment of this.selection.selected) {
            if (!this.actions[0].active) {
                this.actions[0].active = this.transactionService.isCapturable(payment as Payment);
            }

            if (!this.actions[1].active) {
                this.actions[1].active = this.transactionService.isRefundable(payment as Payment);
            }

            if (!this.actions[2].active) {
                this.actions[2].active = this.transactionService.isCancelable(payment as Payment);
            }

            if (!this.actions[3].active) {
                this.actions[3].active = this.transactionService.isCustomerInfoDeletable(payment as Payment);
            }
        }
    }

    public checkPaymentActionValid(action: string): void {
        this.action = action;
        const invalid_payments = [];
        const valid_payments = [];

        for (const transaction of this.selection.selected) {
            const payment = transaction as Payment;
            let payment_valid = false;

            switch (action) {
                case 'capture':
                    payment_valid = this.transactionService.isCapturable(payment);
                    break;
                case 'refund':
                    payment_valid = this.transactionService.isRefundable(payment) && payment.acquirer !== 'klarna';
                    break;
                case 'cancel':
                    payment_valid = this.transactionService.isCancelable(payment);
                    break;
                case 'delete_customer_info':
                    payment_valid = this.transactionService.isCustomerInfoDeletable(payment);
                    break;
            }

            if (payment_valid) {
                valid_payments.push(payment);
            } else {
                invalid_payments.push(payment);
            }
        }

        this.onPaymentsAction(valid_payments, invalid_payments);
    }

    public onPaymentsAction(valid_payments: Array<Payment>, invalid_payments: Array<Payment>): void {
        let header = '';
        let body = '';
        let error = '';

        switch (this.action) {
            case 'capture':
                header = $localize`Capture payments`;
                body = $localize`Please note that this will complete a full capture on the selected payments.`;
                error = $localize`It is not possible to capture the following payments`;
                break;

            case 'refund':
                header = $localize`Refund payments`;
                body = $localize`Complete a full refund on the selected payments`;
                error = $localize`It is not possible to refund the following payments`;
                break;

            case 'cancel':
                header = $localize`Cancel payments`;
                body = $localize`Cancel the selected payments`;
                error = $localize`It is not possible to cancel the following payments`;
                break;

            case 'delete_customer_info':
                header = $localize`Delete customer information?`;
                body = $localize`This action deletes all customer shipping and invoice information of the selected transactions. The transaction data will not be deleted.`;
                error = $localize`It is not possible to delete the following payments customer info.`;
                break;
        }

        if (invalid_payments.length) {
            body += '\n\n' + error + '\n';
            invalid_payments.forEach(failure => {
                body += failure.id + '\n';
            });
        }

        this.dialogService.confirm(header, body).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            map(() => {
                this.dialogService.loading(header);
                this.action_process.total_payments = this.selection.selected.length;

                for (const payment of valid_payments) {
                    switch (this.action) {
                        case 'capture':
                            this.capturePayment(payment);
                            break;
                        case 'refund':
                            this.refundPayment(payment);
                            break;
                        case 'cancel':
                            this.cancelPayment(payment);
                            break;
                        case 'delete_customer_info':
                            this.deleteCustomerInfo(payment);
                            break;
                    }
                }
            })
        ).subscribe();
    }

    public capturePayment(payment: Payment): void {
        this.paymentRepo.capture(
            payment.id ?? NaN,
            { amount: this.transactionService.maxCapture(payment) }
        ).pipe(
            map((returnedPayment: Payment) => {
                if (returnedPayment) {
                    this.observeAction(returnedPayment);
                } else {
                    setTimeout(() => this.getPayment(payment.id ?? NaN), 2000);
                }
            })
        ).subscribe();
    }

    public refundPayment(payment: Payment): void {
        this.paymentRepo.refund(payment.id ?? NaN, { amount: payment.balance }).pipe(
            map((returnedPayment: Payment) => {
                if (returnedPayment) {
                    this.observeAction(returnedPayment);
                } else {
                    setTimeout(() => this.getPayment(payment.id ?? NaN), 2000);
                }
            })
        ).subscribe();
    }

    public cancelPayment(payment: Payment): void {
        this.paymentRepo.cancel(payment.id ?? NaN).pipe(
            map((returnedPayment: Payment) => {
                if (returnedPayment) {
                    this.observeAction(returnedPayment);
                } else {
                    setTimeout(() => this.getPayment(payment.id ?? NaN), 2000);
                }
            })
        ).subscribe();
    }

    public deleteCustomerInfo(payment: Payment): void {
        const transaction = this.transactionsService.deleteCustomerInfo(payment);
        this.paymentRepo.update(transaction).pipe(
            map((returnedPayment: Payment) => {
                if (returnedPayment) {
                    this.observeAction(returnedPayment);
                } else {
                    setTimeout(() => this.getPayment(payment.id ?? NaN), 2000);
                }
            })
        ).subscribe();
    }

    public observeAction(payment: Payment): void {
        of(payment).subscribe({
            error: e => {
                this.processDone(payment, true, e.error);
            },
            complete: () => {
                this.processDone(payment, false);
            }
        });
    }

    public getPayment(paymentID: number): void {
        this.paymentRepo.get<Payment>(paymentID).subscribe(payment => {
            this.processDone(payment, false);
        });
    }

    public processDone(payment: Payment, forceError: boolean, error?: Error): void {
        this.action_process.complete++;

        switch (this.action) {
            case 'capture':
                if (this.transactionService.isCapturable(payment) || forceError) {
                    this.action_process.errors.push({ payment, error });
                }
                break;
            case 'refund':
                if (this.transactionService.isRefundable(payment) || forceError) {
                    this.action_process.errors.push({ payment, error });
                }
                break;
            case 'cancel':
                if (this.transactionService.isCancelable(payment) || forceError) {
                    this.action_process.errors.push({ payment, error });
                }
                break;
            case 'delete_customer_info':
                if (this.transactionService.isCustomerInfoDeletable(payment) || forceError) {
                    this.action_process.errors.push({ payment, error });
                }
                break;
        }

        if (this.action_process.complete === this.action_process.total_payments) {
            this.dialogRef.closeAll();

            this.dialogService.component(
                PaymentActionResultDialogComponent,
                {
                    action: this.action,
                    total_payments: this.action_process.total_payments,
                    errors: this.action_process.errors
                }
            ).afterClosed().subscribe(() => {
                this.selection.clear();
                this.actionComplete.emit();
            });
        }
    }
}
