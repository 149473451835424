import { Injectable } from '@angular/core';
import { Account } from '@interfaces/account';
import { PrivateKey } from '@models/private-key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Base64 } from '../../services/base64/base64';
import { ApiClient } from './api-client';

@Injectable({
    providedIn: 'root'
})
export class AccountRepo {
    constructor(
        private client: ApiClient,
        private base64: Base64
    ) {}

    public get(): Observable<Account> {
        return this.client.get<Account>('/account').pipe(
            map((item: any) => Object.assign(item as Account))
        );
    }

    public getLogo(apiKey: string, type: string): Observable<any> {
        const config: any = {};
        config.responseType = 'blob';
        config.params = {};

        if (apiKey) {
            config.headers = { Authorization: 'Basic ' + this.base64.encodeUnicode(':' + apiKey) };
        }

        if (type) {
            config.params.logo_type = type;
        }

        return this.client.get<any>('/account/logo', config);
    }

    public saveLogo(file: File, type: string): Observable<any> {
        const fd: FormData = new FormData();
        fd.append('file', file);
        if (type) {
            fd.append('logo_type', type);
        }
        return this.client.put<any>('/account/logo', fd, undefined).pipe(
            map((model: any) => Object.assign(new Object(), model))
        );
    }

    public destroyLogo(type: string): Observable<any> {
        const params: any = {};
        if (type) {
            params.logo_type = type;
        }
        return this.client.delete('/account/logo', { params });
    }

    public update(account: Account | any, config?: any, apiKey?: string): Observable<Account> {
        config = config || {};
        if (apiKey) {
            config.headers = { Authorization: 'Basic ' + this.base64.encodeUnicode(':' + apiKey) };
        }
        return this.client.patch<Account>('/account', account, config).pipe(
            map((item: any) => Object.assign(item as Account))
        );
    }

    public getPrivateKey(): Observable<PrivateKey> {
        return this.client.get<PrivateKey>('/account/private-key').pipe(
            map((model: PrivateKey) => Object.assign(new PrivateKey(), model))
        );
    }

    public regeneratePrivateKey(): Observable<PrivateKey> {
        return this.client.patch<PrivateKey>('/account/private-key', null).pipe(
            map((model: PrivateKey) => Object.assign(new PrivateKey(), model))
        );
    }

    public destroy(): Observable<Account> {
        return this.client.delete<Account>('/account');
    }
}
