import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessModel } from '@models/clearhaus-application/application';
import { Application } from '@models/clearhaus-application/ch-instant';

@Component({
    selector: 'qp-clearhaus-buisness-form',
    templateUrl: './business-instant.html'
})
export class ClearhausInstantSignupFormStepSixComponent implements AfterViewInit {
    @Output() public applicationChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _application: Application = Object.assign(
        {
            business_model: {
                description: null,
                estimated_monthly_turnover: null
            }
        },
        new Application()
    );

    public businessForm = new UntypedFormGroup({
        description: new UntypedFormControl('', Validators.required),
        turnover: new UntypedFormControl('', Validators.required)
    });

    public get description(): UntypedFormControl {
        return this.businessForm.get('description') as UntypedFormControl;
    }
    public get turnover(): UntypedFormControl {
        return this.businessForm.get('turnover') as UntypedFormControl;
    }

    @Input() public get application(): Application {
        return this._application;
    }

    public set application(value: Application) {
        this._application = value;
        this.applicationChange.emit(value);
    }

    constructor() {}

    public ngAfterViewInit(): void {
        this.businessForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(this.businessForm.valid);
        });

        this.description.setValue(this.application.business_model.description);
        this.turnover.setValue(this.application.business_model.estimated_monthly_turnover);
        this.validityChange.emit(this.businessForm.valid);
    }

    public modelBusinessModel(propName: keyof BusinessModel, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        this.application = {
            ...this.application,
            ...{ business_model: { ...this.application.business_model, [propName]: input.value } }
        };
    }
}
