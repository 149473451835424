import { BehaviorSubject, Observable } from 'rxjs';

export class LocalState<T> {
    private readonly state_sub: BehaviorSubject<T | undefined>;
    public readonly state$: Observable<T | undefined>;

    constructor(initial_state?: T) {
        this.state_sub = new BehaviorSubject<T | undefined>(initial_state);
        this.state$ = this.state_sub.asObservable();
    }

    public setState(state: T): void {
        this.state_sub.next(state);
    }

    public hasValue(): boolean {
        return !!this.state_sub.getValue();
    }
}
