<form class="flex-col rg36" name="account" autocomplete="off" [formGroup]="address_form" (ngSubmit)="saveChanges()">
    <div class="flex-row" [class.warning-border]="address_form.dirty" [class.success-border]="formSaved && address_form.pristine">

        <div class="flex-col rg12 flex-1">
            <mat-form-field>
                <mat-label i18n>Name</mat-label>
                <input matInput aria-label="Name" formControlName="name" />
                <mat-error *ngIf="getAddressFormControlByKey('name').hasError('required')" i18n> Name is required </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n>Street</mat-label>
                <input matInput aria-label="Street" formControlName="street" />
                <mat-error *ngIf="getAddressFormControlByKey('street').hasError('required')" i18n> Street is required </mat-error>
            </mat-form-field>

            <div class="flex-row-mobile-col cg4p">
                <mat-form-field class="flex-28-mobile-100">
                    <mat-label i18n>Zip code</mat-label>
                    <input matInput aria-label="Zip code" formControlName="zip_code" />
                    <mat-error *ngIf="getAddressFormControlByKey('zip_code').hasError('required')" i18n> Zip code is required </mat-error>
                </mat-form-field>

                <mat-form-field class="flex-68-mobile-100">
                    <mat-label i18n>City</mat-label>
                    <input matInput aria-label="city" formControlName="city" />
                    <mat-error *ngIf="getAddressFormControlByKey('city').hasError('required')" i18n> City is required </mat-error>
                </mat-form-field>
            </div>

            <div class="flex-row-mobile-col cg4p">
                <mat-form-field class="flex-48-mobile-100">
                    <mat-label i18n>Country</mat-label>
                    <mat-select aria-label="Country" formControlName="country_code">
                        <mat-option *ngFor="let country of countries" [value]="country"> {{ country.name }} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="getAddressFormControlByKey('country_code').hasError('required')" i18n> Country is required </mat-error>
                </mat-form-field>

                <mat-form-field class="flex-48-mobile-100">
                    <mat-label i18n>Region (EU Optional)</mat-label>
                    <input matInput aria-label="Region" name="region" formControlName="region" />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label i18n>VAT No.</mat-label>
                <input matInput aria-label="VAT No." formControlName="vat_no" />
            </mat-form-field>

            <!-- <qp-form-state-info [form_dirty]="address_form.dirty" [form_pristine]="address_form.pristine" [form_saved]="formSaved" form_error=""> </qp-form-state-info> -->
        </div>
    </div>

    <div *ngIf="address_form.dirty">
        <button mat-raised-button color="accent" type="submit" [disabled]="address_form.invalid" i18n>Save changes</button>
    </div>
</form>
