import { Injectable } from '@angular/core';
import { Plan } from '@models/plan';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceClient } from './invoice-client';

@Injectable({
    providedIn: 'root'
})
export class PlanRepo {
    constructor(
        private invoiceClient: InvoiceClient
    ) {}

    public getPlan(id: string): Observable<Plan> {
        return this.invoiceClient.get<Plan>(`/plans/${id}`).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public getMerchantPlan(id: number): Observable<Plan> {
        return this.invoiceClient.get<Plan>(`/merchants/${id}/plan`).pipe(
            map((item: Plan) => ({ attributes: item?.attributes ?? {}, type: item?.type ?? '', id: item?.id }))
        );
    }

    public getResellerPlan(params: any): Observable<Plan> {
        return this.invoiceClient.get<Plan[]>('/reseller-plans', { params }).pipe(
            map((items: Plan[]) => items[0] ?? undefined),
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public getAll(countryCode?: string): Observable<Plan[]> {
        const params: any = {};
        if (typeof countryCode === 'string') {
            params['filter[country_code]'] = countryCode;
        }

        return this.invoiceClient.get<Plan[]>('/plans', { params });
    }

    public createPlan(plan: Plan): Observable<Plan> {
        return this.invoiceClient.post<Plan>('/plans', plan).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public createMerchantPlanByID(merchantID: number, params: Plan): Observable<Plan> {
        return this.invoiceClient.post<Plan>(`/merchants/${merchantID}/plans`, params).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public createMerchantPlan(merchantPlan: Plan): Observable<Plan> {
        return this.invoiceClient.post<Plan>('/merchant-plans', merchantPlan).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public createResellerPlan(plan: Plan): Observable<Plan> {
        return this.invoiceClient.post<Plan>('/reseller-plans', plan).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public updatePlan(plan: Plan): Observable<Plan> {
        return this.invoiceClient.patch<Plan>(`/plans/${plan.id}`, plan).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public updateMerchantPlan(params: Plan): Observable<Plan> {
        return this.invoiceClient.patch<Plan>(`/merchant-plans/${params.id}`, params).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public updateResellerPlan(plan: Plan): Observable<Plan> {
        return this.invoiceClient.patch<Plan>(`/reseller-plans/${plan.id}`, plan).pipe(
            map((item: Plan) => Object.assign(new Plan(), item))
        );
    }

    public destroyPlan(plan: Plan): Observable<Plan> {
        return this.invoiceClient.delete<Plan>(`/plans/${plan.id}`);
    }
}
