<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null" class="flex-col min-width-400px">
    <div class="flex-row margin l8 no-print">
        <button mat-button [routerLink]="['/account', agreement?.account?.id, 'settings', 'fraudfilter']" class="mat-button-back" color="accent">
            &lt; <span i18n>Back to Fraud filter</span>
        </button>
    </div>

    <mat-card class="card02">
        <qp-section-heading headingText="Ruleset" icon="ruleset" i18n-headingText></qp-section-heading>

        <mat-card-content>
            <form name="ruleset" #rulesetForm="ngForm">
                <mat-form-field hideRequiredMarker>
                    <mat-label i18n>Ruleset name</mat-label>
                    <input matInput [(ngModel)]="ruleset.name" type="text" name="name" required />
                    <mat-error i18n>Missing descriptive ruleset name</mat-error>
                </mat-form-field>

                <div class="flex-row cg8 justify-start items-center">
                    <span i18n>If</span>
                    <mat-form-field class="width-60">
                        <mat-select [(ngModel)]="ruleset.combinator" name="combinator">
                            <mat-option value="and" i18n>all</mat-option>
                            <mat-option value="or" i18n>any</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span i18n>of the following apply</span>
                </div>

                <div class="justify-start">
                    <div class="flex-row cg8 items-center margin b16" *ngFor="let rule of ruleset.filter_rules; let index = index;">
                        <mat-form-field class="flex-desktop-30" hideRequiredMarker>
                            <mat-label i18n>Metric</mat-label>
                            <mat-select
                                [(ngModel)]="rule.metric_name"
                                name="metric_name[{{ index }}]"
                                (selectionChange)="changeMetric(rule)"
                                required
                            >
                                <mat-option value="acquirer_id" i18n>Acquirer</mat-option>
                                <mat-option value="amount_eur" i18n>Amount (EUR)</mat-option>
                                <mat-option value="card_country_alpha3" i18n>Card country</mat-option>
                                <mat-option value="facilitator" i18n>Facilitator</mat-option>
                                <mat-option value="fraudfilter_score" i18n>Fraud score</mat-option>
                                <mat-option value="request_ip_country_alpha3" i18n>Request IP country</mat-option>
                                <mat-option value="transaction_type" i18n>Transaction type</mat-option>
                            </mat-select>
                            <mat-error i18n>Metric is missing</mat-error>
                        </mat-form-field>

                        <mat-form-field
                            class="flex-desktop-30"
                            *ngIf="rule.metric_name === 'amount_eur' ||
                                            rule.metric_name === 'fraudfilter_score' ||
                                            !rule.metric_name"
                            hideRequiredMarker
                        >
                            <mat-label i18n>Operator</mat-label>
                            <mat-select [panelClass]="'wide-select'" [panelWidth]="'275px'" [(ngModel)]="rule.operator" name="operator[{{ index }}]" required>
                                <mat-select-trigger>{{ rule.operator }}</mat-select-trigger>
                                <mat-option value="=="> <span class="option-block">==</span><span i18n>Equal to</span></mat-option>
                                <mat-option value="!="> <span class="option-block">!=</span><span i18n>Not equal to</span></mat-option>
                                <mat-option value="<"> <span class="option-block">&lt;</span><span i18n>Less than</span></mat-option>
                                <mat-option value="<=">
                                    <span class="option-block">&lt;=</span><span i18n>Less than or equal to</span>
                                </mat-option>
                                <mat-option value=">"> <span class="option-block">&gt;</span><span i18n>Greater than</span></mat-option>
                                <mat-option value=">=">
                                    <span class="option-block">&gt;=</span><span i18n>Greater than or equal to</span>
                                </mat-option>
                            </mat-select>
                            <mat-error i18n>Operator is missing</mat-error>
                        </mat-form-field>

                        <mat-form-field
                            class="flex-desktop-30"
                            *ngIf="rule.metric_name === 'card_country_alpha3' ||
                                            rule.metric_name === 'facilitator' ||
                                            rule.metric_name === 'request_ip_country_alpha3' ||
                                            rule.metric_name === 'transaction_type' ||
                                            rule.metric_name === 'acquirer_id'"
                            hideRequiredMarker
                        >
                            <mat-label i18n>Operator</mat-label>
                            <mat-select [(ngModel)]="rule.operator" name="operator[{{ index }}]" required>
                                <mat-option value="in" i18n>in</mat-option>
                                <mat-option value="not_in" i18n>not in</mat-option>
                                <mat-option value="==" disabled>==</mat-option>
                                <mat-option value="!=" disabled>!=</mat-option>
                            </mat-select>
                            <mat-error i18n>Operator is missing</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flex-mobile-30" *ngIf="!rule.metric_name">
                            <mat-label i18n>Value</mat-label>
                            <input matInput type="text" />
                        </mat-form-field>

                        <mat-form-field class="flex-mobile-30" *ngIf="rule.metric_name === 'fraudfilter_score'" hideRequiredMarker>
                            <mat-label i18n>Value</mat-label>
                            <input matInput [(ngModel)]="rule.value" type="number" name="value[{{ index }}]" min="0" max="100" required />
                            <mat-error i18n>Fraudfilter score is missing</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flex-mobile-30" *ngIf="rule.metric_name === 'amount_eur'" hideRequiredMarker>
                            <mat-label i18n>Value</mat-label>
                            <input
                                matInput
                                [(ngModel)]="rule.value"
                                inputDecimal
                                class="input-right"
                                name="value[{{ index }}]"
                                [currency]="currency"
                                required
                            />
                            <mat-error i18n>Amount in euro is missing</mat-error>
                        </mat-form-field>

                        <div
                            *ngIf="rule.metric_name === 'card_country_alpha3' ||
                                    rule.metric_name === 'request_ip_country_alpha3'"
                            class="flex-col country_selecter"
                        >
                            <mat-form-field class="flex-mobile-30" hideRequiredMarker>
                                <mat-label i18n>Countries</mat-label>
                                <mat-select [(ngModel)]="rule.value" name="value[{{ index }}]" multiple required>
                                    <mat-option *ngFor="let country of countries" [value]="country.code"> {{ country.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div>
                                <mat-checkbox
                                    *ngIf="rule.metric_name === 'card_country_alpha3'"
                                    class="country_selecer_checkbox"
                                    (change)="selectAllPSD2Countries($event, index)"
                                    [checked]="isAllPSD2CountriesSelected(index)"
                                >
                                    <span *ngIf="!isAllPSD2CountriesSelected(index)" i18n>Select all PSD2 required.</span>
                                    <span *ngIf="isAllPSD2CountriesSelected(index)" i18n>Deselect all PSD2 required.</span>
                                </mat-checkbox>
                            </div>
                        </div>

                        <mat-form-field class="flex-desktop-30" *ngIf="rule.metric_name === 'acquirer_id'" hideRequiredMarker>
                            <mat-label i18n>Acquirers</mat-label>
                            <mat-select [(ngModel)]="rule.value" name="value[{{ index }}]" multiple required>
                                <mat-optgroup i18n-label label="Active">
                                    <mat-option *ngFor="let acquirer of activeAcquirers" [value]="acquirer.id">
                                        {{ acquirer.title }}
                                    </mat-option>
                                </mat-optgroup>
                                <mat-optgroup i18n-label label="Inactive">
                                    <mat-option *ngFor="let acquirer of inActiveAcquirers" [value]="acquirer.id">
                                        {{ acquirer.title }}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                            <mat-error i18n>Acquirers is missing</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flex-desktop-30" *ngIf="rule.metric_name === 'facilitator'" hideRequiredMarker>
                            <mat-label i18n>Facilitator</mat-label>
                            <mat-select [(ngModel)]="rule.value" name="value[{{ index }}]" multiple required>
                                <mat-option value="mobilepay">Mobilepay</mat-option>
                                <mat-option value="apple_pay">Apple Pay</mat-option>
                            </mat-select>
                            <mat-error i18n>Facilitator is missing</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flex-desktop-30" *ngIf="rule.metric_name === 'transaction_type'" hideRequiredMarker>
                            <mat-label i18n>Transaction type</mat-label>
                            <mat-select [(ngModel)]="rule.value" name="value[{{ index }}]" multiple required>
                                <mat-option value="Payment" i18n>Payment</mat-option>
                                <mat-option value="Subscription" i18n>Subscription</mat-option>
                                <mat-option value="Card" i18n>Card</mat-option>
                                <mat-option value="Payout" i18n>Payout</mat-option>
                            </mat-select>
                            <mat-error i18n>Transaction is missing</mat-error>
                        </mat-form-field>

                        <button mat-icon-button type="button" (click)="removeRule(index)" *ngIf="ruleset.filter_rules && ruleset.filter_rules.length > 1">
                            <mat-icon class="mat-18">remove_circle_outline</mat-icon>
                        </button>
                        <br />
                    </div>
                </div>

                <button mat-raised-button type="button" (click)="addRule()">
                    <mat-icon>control_point</mat-icon>&nbsp;
                    <span class="mat-button-wrapper" i18n>Add rule</span>
                </button>

                <div class="flex-row cg8 justify-start items-center">
                    <span i18n>Do</span>
                    <mat-form-field>
                        <mat-select [(ngModel)]="ruleset.action" name="action">
                            <mat-option value="nothing" i18n>Nothing</mat-option>
                            <mat-option value="flag" i18n>Flag</mat-option>
                            <mat-option value="force_3d" i18n>Enforce 3-D Secure</mat-option>
                            <mat-option value="force_sca" i18n>Enforce 3-D Secure challenge</mat-option>
                            <mat-option value="block" i18n>Block</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </mat-card-content>

        <mat-divider class="divider"></mat-divider>
        <div class="flex-row cg8 justify-end">
            <button mat-raised-button (click)="destroy()" *ngIf="ruleset.id" class="text-darkred" i18n>Delete</button>
            <span class="flex-1"></span>
            <button mat-raised-button (click)="cancel()" i18n>Cancel</button>
            <button mat-raised-button [disabled]="rulesetForm.invalid" (click)="save()" color="accent" i18n>Save ruleset</button>
        </div>
    </mat-card>
</div>
