import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { RegExpEmail } from '@helpers/reg-exp/reg-exp-email';
import { Account } from '@interfaces/account';
import { Me } from '@interfaces/me';
import { Theme } from '@interfaces/theme';
import { PrivateKey } from '@models/private-key';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { Locale } from '@services/locales/locales.interface';
import { LocalesService } from '@services/locales/locales.service';
import { SessionService } from '@services/session/session.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { ThemingService } from '@services/theming/theming.service';
import { Timezone } from '@services/timezone/timezone.interface';
import { TimezoneService } from '@services/timezone/timezone.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { UnsavedChanges } from 'app/routing/guards/unsaved-changes.interface';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map } from 'rxjs/operators';

@Component({
    selector: 'qp-settings-merchant',
    templateUrl: 'merchant.html',
    styleUrls: ['merchant.scss'],
    animations: [Animations.getFadeAnimation()]
})
export class SettingsMerchantComponent extends BaseLoadingDirective implements OnInit, UnsavedChanges {
    public locales: Locale[];
    public logo: any;
    public me: Me | undefined;
    public merchant: Account;
    public privateKey: string;
    public readonly = !this.agreementService.hasPermission('/account', 'put');
    public zones: Timezone[];
    public theme: Theme;
    public regionFormSaved: boolean;
    public shopInfoFormSaved: boolean;
    public accountingSoftwares: Array<string>;
    public shopInfoForm: UntypedFormGroup;
    public regionForm: UntypedFormGroup;
    public accountingProgramForm: UntypedFormGroup;

    public get shopName(): AbstractControl {
        return this.shopInfoForm.get('shopName') as AbstractControl;
    }

    public get email(): AbstractControl {
        return this.shopInfoForm.get('email') as AbstractControl;
    }

    constructor(
        private acquirerService: AcquirerService,
        private accountRepo: AccountRepo,
        private dialogService: DialogService,
        private domSanitizer: DomSanitizer,
        private localeService: LocalesService,
        private sessionService: SessionService,
        private snackBar: QpSnackBar,
        private themingService: ThemingService,
        private timezoneService: TimezoneService,
        private agreementService: AgreementService
    ) {
        super();
        this.theme = this.themingService.theme;
    }

    public ngOnInit(): void {
        this.accountingSoftwares = [
            'e-conomic',
            'zenegy',
            'billy',
            'dinero',
            'xero',
            'quickbook',
            'uniconta',
            'business central',
            'Nav',
            'SAP',
            'NetSuite',
            'Other'
        ];
        this.locales = this.localeService.getLocales();
        this.me = this.sessionService.me;
        this.zones = this.timezoneService.getTimezones();

        this.getPrivateKey();
        this.loadAccount();
        this.loadLogo();
        this.setupForms();

        if (this.merchant.accounting_program) {
            this.accountingSoftwares.unshift(this.merchant.accounting_program);
        }
    }

    public setupForms(): void {
        this.shopInfoForm = new UntypedFormGroup({
            shopName: new UntypedFormControl({ value: this.merchant.shop_name, disabled: this.readonly }, Validators.required),
            email: new UntypedFormControl(
                { value: this.merchant.contact_email, disabled: this.readonly },
                Validators.compose([Validators.required, Validators.pattern(new RegExpEmail())])
            ),
            phoneNumber: new UntypedFormControl({ value: this.merchant.contact_phone, disabled: this.readonly })
        });

        this.regionForm = new UntypedFormGroup({
            timezone: new UntypedFormControl({ value: this.merchant.timezone, disabled: this.readonly }),
            locale: new UntypedFormControl({ value: this.merchant.locale, disabled: this.readonly })
        });

        if (this.theme.brand_name !== 'Unzer') {
            this.accountingProgramForm = new UntypedFormGroup({
                selectedProgram: new UntypedFormControl({ value: this.merchant.accounting_program, disabled: false }),
                customProgram: new UntypedFormControl({ value: '', disabled: false })
            });
        }

        if (this.merchant.accounting_program) {
            this.accountingSoftwares.unshift(this.merchant.accounting_program);
        }
    }

    public loadLogo(): void {
        if (this.agreementService.hasPermission('/account/logo', 'get')) {
            this.accountRepo.getLogo('', '').pipe(
                map((data: Blob) => {
                    const urlCreator: any = window.URL || window['webkitURL'];
                    this.logo = this.domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
                }),
                catchError(() => of())
            ).subscribe();
        }
    }

    public deleteLogo(): void {
        this.dialogService.confirm(
            $localize`Remove logo`,
            $localize`Are you sure you want to remove the logo?`,
            $localize`Remove`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            exhaustMap(() =>
                this.accountRepo.destroyLogo('').pipe(
                    map(() => {
                        this.logo = null;
                    })
                )
            )
        ).subscribe();
    }

    public loadAccount(): void {
        if (this.agreementService.agreement && this.agreementService.hasPermission('/account', 'get')) {
            this.merchant = Object.assign({}, this.agreementService.agreement.account);
        }
    }

    public getPrivateKey(): void {
        if (this.agreementService.isOwner()) {
            this.accountRepo.getPrivateKey().subscribe((privateKey: PrivateKey) => {
                this.privateKey = privateKey.private_key;
            });
        }
    }

    public hasUnsavedChanges(): Observable<boolean> {
        if (this.regionForm.dirty || this.shopInfoForm.dirty) {
            return this.dialogService.unsavedChangesConfirm().afterClosed().pipe(
                map((result: boolean) => result)
            );
        }
        return of(true);
    }

    public regenerateApiKey(): void {
        this.dialogService.confirm(
            $localize`Regenerate api key`,
            $localize`Are you sure you want to regenerate the api key?`,
            $localize`Ok`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            exhaustMap(() =>
                this.accountRepo.regeneratePrivateKey().pipe(
                    map((privateKey: PrivateKey) => {
                        if (this.agreementService.isOwner()) {
                            this.privateKey = privateKey.private_key;
                        }
                    })
                )
            )
        ).subscribe();
    }

    public copyPrivateKey(): void {
        this.dialogService.copy(
            $localize`Private key`,
            this.privateKey,
            $localize`Your key has been copied`
        );
    }

    public uploadLogo(eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const inputElement: HTMLInputElement = eventTarget as HTMLInputElement;
        const files = inputElement.files;
        if (!files) {
            return;
        }
        const file = files.item(0);
        if (!file) {
            return;
        }
        let isValid = true;

        if (!['svg', 'jpg', 'png'].includes(file.name.slice(-3).toLowerCase())) {
            isValid = false;
            this.dialogService.alert(
                $localize`Image wrong type`,
                $localize`The image should be either JPG, PNG or SVG`
            );
        }

        if (isValid) {
            this.accountRepo.saveLogo(file, '').subscribe(() => {
                this.loadLogo();
                const el: any = document.getElementById('logo');
                el.value = '';
            });
        }
    }

    public preSaveShopInfo(formSaved: string): void {
        const shopInfo = {
            shop_name: this.shopInfoForm.value.shopName,
            contact_email: this.shopInfoForm.value.email,
            contact_phone: this.shopInfoForm.value.phoneNumber,
            timezone: this.regionForm.value.timezone,
            locale: this.regionForm.value.locale
        };
        this.save(shopInfo, formSaved);
    }

    public saveShopsystem(data: any): void {
        this.snackBar.open($localize`Shopsystem updated`);
        this.save(data);
    }

    public saveSelectedProgram(): void {
        const selectedProgram = this.getAccountingProgramFormControl('selectedProgram').value;
        if (selectedProgram !== 'Other') {
            this.snackBar.open($localize`Shopsystem updated`);
            this.save({ accounting_program: selectedProgram });
        }
    }

    public saveCustomProgram(): void {
        const customProgram = this.getAccountingProgramFormControl('customProgram').value;

        this.snackBar.open($localize`Shopsystem updated`);
        this.save({ accounting_program: customProgram });
    }

    public save(submitData: any, formSaved?: string): void {
        this.accountRepo.update(submitData).subscribe((returnAccount: Account) => {
            if (!this.agreementService.agreement) {
                return;
            }
            this.merchant = returnAccount;
            this.agreementService.agreement.account = returnAccount;

            if (formSaved === 'shopInfoForm') {
                this.shopInfoFormSaved = true;
                this.regionFormSaved = false;
            } else if (formSaved === 'regionForm') {
                this.regionFormSaved = true;
                this.shopInfoFormSaved = false;
            } else {
                this.shopInfoFormSaved = false;
                this.regionFormSaved = false;
            }
        });

        this.shopInfoForm.markAsPristine();
        this.shopInfoForm.markAsUntouched();
        this.regionForm.markAsPristine();
        this.regionForm.markAsUntouched();
    }

    public getAccountingProgramFormControl(key: 'customProgram' | 'selectedProgram'): UntypedFormControl {
        return this.accountingProgramForm.get(key) as UntypedFormControl;
    }
}
