import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Application } from '@models/clearhaus-application/application';
import { BankAccount } from '@models/clearhaus-application/bank-account';
import { Company } from '@models/clearhaus-application/company';
import { Contact } from '@models/clearhaus-application/contact';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { Person } from '@models/clearhaus-application/person';
import { Website } from '@models/clearhaus-application/website';
import { ClearhausRepo } from 'app/endpoints/server/clearhaus-repo';
import { CountryService } from '@services/country/country.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'qp-clearhaus-signup-submit',
    templateUrl: './submit.html'
})
export class ClearhausSignupFormSubmitComponent {
    @Input() public additional_information: string;
    @Input() public application: Application = new Application();
    @Input() public applicationExists = false;
    @Input() public bank_account: BankAccount = new BankAccount();
    @Input() public company: Company = new Company();
    @Input() public contact: Contact = new Contact();
    @Input() public director: Person = new Person();
    @Input() public documentationFile: DirectorFile;
    @Input() public files: DirectorFile[] = [];
    @Input() public filesFromServer: any = {
        director: {
            picture_legitimation: null,
            address_legitimation: null
        },
        owners: [],
        documentation: null,
        additional: []
    };
    @Input() public owners: Person[] = [];
    @Input() public people: Person[] = [];
    @Input() public validity: {
        contact: boolean;
        company: boolean;
        director: boolean;
        ownership: boolean;
        websites: boolean;
        business: boolean;
        bank: boolean;
        documentation: boolean;
    };
    @Input() public websites: Website[] = [];
    @Input() public websitesFromServer: Website[] = [];

    @Output() public applicationUploaded = new EventEmitter();
    @Output() public getPersonFiles = new EventEmitter();
    @Output() public postGetApplication = new EventEmitter();
    @Output() public validateDocumentation = new EventEmitter();

    public fileObservables: Observable<any>[] = [];
    public ownerObservables: Observable<any>[] = [];
    public patchObservables: Observable<any>[] = [];
    public saving = false;
    public websiteObservables: Observable<any>[] = [];

    public get disableSubmit(): boolean {
        return this.saving || !this.validityOk;
    }
    public get submitted(): boolean {
        return this.application.metadata?.state === 'submitted';
    }
    public get validityOk(): boolean {
        return (
            this.validity.contact ||
            this.validity.company ||
            this.validity.director ||
            this.validity.ownership ||
            this.validity.websites ||
            this.validity.business ||
            this.validity.bank ||
            this.validity.documentation
        );
    }

    constructor(
        private clearhausRepo: ClearhausRepo,
        private countryService: CountryService,
        private dialogService: DialogService,
        private http: HttpClient
    ) {}

    public send(): void {
        this.saving = true;

        this.application.signer = {
            name: this.contact.name ?? '',
            email: this.contact.email ?? ''
        };

        this.director.role_director = true;
        this.owners = this.owners.map(owner => {
            owner.role_owner = true;
            owner.role_director = false;
            return owner;
        });
        if (this.additional_information) {
            this.application.additional_information = this.additional_information;
        }

        // NOTE: Manually set application here because our models don't match CH standards
        // https://developer.clearhaus.com/rels/applications#applications

        const compiledApplication: { [key: string]: any } = {
            application: {
                voucher_code: '',
                signer: this.application.signer,
                business_model: {
                    trading_name: this.application.business_model?.trading_name,
                    description: this.application.business_model?.description,
                    recurring: this.application.business_model?.recurring,
                    physical_delivery: this.application.business_model?.physical_delivery,
                    estimate_currency: this.application.business_model?.estimate_currency,
                    estimated_monthly_turnover: this.application.business_model?.estimated_monthly_turnover,
                    estimated_average_transaction_amount: this.application.business_model?.estimated_average_transaction_amount
                }
            },
            contact: this.contact,
            company: {
                name: this.company.name,
                registration_number: this.company.registration_number,
                email: this.company.email,
                phone: this.company.phone,
                address_line_1: this.company.address_line_1,
                address_line_2: this.company.address_line_2,
                zipcode: this.company.zipcode,
                city: this.company.city,
                country: this.convertToAlpha2(this.company.country ?? ''),
                form: this.company.form,
                ownership_structure: this.company.ownership_structure,
                ownership_structure_comment: this.company.ownership_structure_comment
            },
            people: [this.director].concat([...this.owners]).map(person => ({
                id: person.id || null,
                name: person.name,
                social_security_number: person.social_security_number,
                date_of_birth: person.date_of_birth,
                address_line_1: person.address_line_1,
                address_line_2: person.address_line_2,
                zipcode: person.zipcode,
                city: person.city,
                country: this.convertToAlpha2(person.country ?? ''),
                role_director: person.role_director,
                role_owner: person.role_owner,
                files: [...(person.files ?? [])]
            })),
            websites: [...this.websites],
            bank_account: this.bank_account
        };

        if (this.application.additional_information) {
            compiledApplication.application['additional_information'] = this.application.additional_information;
        }
        if (this.application.business_model?.drop_shipping) {
            compiledApplication.application.business_model['drop_shipping'] = this.application.business_model.drop_shipping;
        }
        if (compiledApplication.application.business_model.physical_delivery) {
            compiledApplication.application.business_model['delivery_delay'] = this.application.business_model?.delivery_delay;
        }

        const currentFiles: any = {
            director: {
                picture_legitimation: null,
                address_legitimation: null
            },
            owners: [],
            documentation: this.company.files?.map(file => file.name),
            additional: this.files.map(file => file.name)
        };

        this.director.files?.forEach(file => {
            if (file.label) {
                currentFiles.director[file.label] = file.name;
            }
        });
        // eslint-disable-next-line
        this.owners.forEach((owner, i) => {
            const fileObject: any = {
                picture_legitimation: null,
                address_legitimation: null
            };
            owner.files?.forEach(file => {
                if (file.label) {
                    fileObject[file.label] = file.name;
                }
            });
            currentFiles.owners.push(fileObject);
        });

        const observable = this.applicationExists
            ? this.clearhausRepo.updateApplication(compiledApplication.application).pipe(
                map(application => {
                    this.applicationExists = true;
                    return { application };
                }),
                finalize(() => {
                    this.patchInfo(compiledApplication);
                })
            )
            : this.clearhausRepo.createApplication(compiledApplication.application).pipe(
                map(application => {
                    this.applicationExists = true;
                    return { application };
                }),
                catchError(_ => {
                    this.applicationExists = false;
                    return of({ application: null });
                }),
                finalize(() => {
                    this.patchInfo(compiledApplication);
                })
            );

        observable.subscribe();
    }

    public convertToAlpha2(value: string): string | undefined {
        return value.length === 2 ? value : this.countryService.alpha3ToAlpha2(value);
    }

    public patchInfo(compiledApplication: any): void {
        this.patchObservables.push(
            this.clearhausRepo.patchContact(compiledApplication.contact).pipe(
                map(contact => ({ contact })),
                catchError(_ => of({ contact: null }))
            )
        );
        this.patchObservables.push(
            this.clearhausRepo.patchCompany(compiledApplication.company).pipe(
                map(company => ({ company })),
                catchError(_ => of({ company: null }))
            )
        );

        const director = compiledApplication.people.find((person: Person) => person.role_director);
        const files = director.files;
        delete director.files;
        this.patchObservables.push(
            director?.id
                ? this.updatePerson(director, files).pipe(
                    map(person => ({ director: person })),
                    catchError(_ => of({ director: null }))
                )
                : this.createPerson(director, files).pipe(
                    map(person => ({ director: person })),
                    catchError(_ => of({ director: null }))
                )
        );

        this.patchOwners(compiledApplication);
        this.createUpdateWebsites(compiledApplication);

        this.patchObservables.push(
            this.clearhausRepo.patchBankAccount(compiledApplication.bank_account).pipe(
                map(bank_account => ({ bank_account })),
                catchError(_ => of({ bank_account: null }))
            )
        );

        const docFile = this.company.files?.find(file => file.label === 'documentation');
        if (docFile) {
            if (docFile.remove && docFile.id) {
                this.fileObservables.push(this.clearhausRepo.destroyFile(docFile.id));
            } else if (!docFile.id && !docFile.remove) {
                this.fileObservables.push(
                    this.uploadDocumentationFile(docFile).pipe(
                        map(documentation => ({ documentation })),
                        catchError(_ => of({ documentation: null }))
                    )
                );
            } else {
                this.fileObservables.push(
                    this.clearhausRepo.getCompanyFiles().pipe(
                        map(companyFiles => ({
                            documentation: companyFiles.find(file => file.label === 'documentation')
                        })),
                        catchError(_ => of({ documentation: null }))
                    )
                );
            }
        }

        this.files?.forEach(file => {
            if (file.label && !['picture_legitimation', 'address_legitimation', 'documentation'].includes(file.label)) {
                if (file.remove && file.id) {
                    this.fileObservables.push(this.clearhausRepo.destroyFile(file.id));
                }
                if (!file.id && !file.remove) {
                    this.fileObservables.push(this.uploadAdditionalFile(file));
                }
            }
        });

        this.combinedRequests(compiledApplication.company).subscribe(submittable => {
            if (submittable) {
                this.clearhausRepo
                    .submitApplication()
                    .pipe(
                        map(data => {
                            this.dialogService.alert(
                                $localize`Application sent`,
                                $localize`The application was submitted and is being reviewed.`
                            );
                            return data;
                        }),
                        catchError(_ => of({})),
                        finalize(() => {
                            this.saving = false;
                            this.postSave();
                        })
                    )
                    .subscribe();
            } else {
                this.saving = false;
                this.dialogService.alert(
                    $localize`Application sent`,
                    $localize`Thank you. You should now have received an email from Clearhaus. Click the link in that email to follow the status of your application.`
                );
                this.postSave();
            }
        });
    }

    public createPerson(localPerson: Person, files?: any[]): Observable<Person | Observable<{ [key: string]: any }>> {
        if (!files) {
            return EMPTY;
        }
        return this.clearhausRepo.createPerson(localPerson).pipe(mergeMap(serverPerson => this.uploadPersonFiles(serverPerson, files)));
    }

    public updatePerson(localPerson: Person, files?: any[]): Observable<Person | Observable<{ [key: string]: any }>> {
        if (!files) {
            return EMPTY;
        }
        return this.clearhausRepo.updatePerson(localPerson).pipe(mergeMap(serverPerson => this.uploadPersonFiles(serverPerson, files)));
    }

    // eslint-disable-next-line max-len
    public uploadPersonFiles(serverPerson: Person, files: DirectorFile[]): Observable<Person> | Observable<Observable<{ [key: string]: any }>> {
        const filesToCreate = files?.filter(file => !file.remove && !file.id) || [];
        const filesToDelete = files?.filter(file => file.remove) || [];
        const fileObservables = [];

        filesToDelete.forEach(file => {
            if (file.id) {
                fileObservables.push(this.clearhausRepo.destroyFile(file.id));
            }
        });

        filesToCreate.forEach(file => {
            fileObservables.push(this.uploadFileObservable(file, this.clearhausRepo.createPersonFile(serverPerson.id ?? '', file)));
        });

        // NOTE: Don't change until the role_* bug on CH portal is fixed.
        let role = '';
        if (serverPerson.role_director) {
            role = 'director';
        }
        if (serverPerson.role_owner) {
            role = 'owner';
        }

        fileObservables.push(
            this.clearhausRepo.getPersonFiles(serverPerson.id ?? '').pipe(
                map(personFiles => {
                    const obj: { [key: string]: any } = {};
                    obj[role] = serverPerson;
                    obj['files'] = personFiles;
                    return obj;
                })
            )
        );

        if (!fileObservables.length) {
            return of([]).pipe(map(_ => serverPerson));
        } else {
            return forkJoin([fileObservables]).pipe(map(res => res[res.length - 1]));
        }
    }

    public uploadFileObservable(file: any, observable: Observable<any>): Observable<any> {
        return observable.pipe(
            mergeMap(newFile => this.clearhausRepo.createUploadLink(newFile.id)),
            mergeMap(uploadLink =>
                this.http.put(uploadLink.upload, file.file, {
                    headers: { 'Content-Type': file.file?.type || file.content_type }
                })
            )
        );
    }

    public uploadDocumentationFile(file: DirectorFile): Observable<any> {
        file.label = !!file.label ? file.label : 'documentation';
        return this.uploadFileObservable(file, this.clearhausRepo.createCompanyFile(file));
    }

    public uploadAdditionalFile(file: DirectorFile): Observable<any> {
        file.label = !!file.label ? file.label : 'additional_file';
        return this.uploadFileObservable(file, this.clearhausRepo.createFile(file));
    }

    public patchOwners(compiledApplication: any): void {
        const structure = compiledApplication.company.ownership_structure;
        const owners: Person[] = compiledApplication.people.filter((person: Person) => person.role_owner);
        owners.forEach((owner, i: number) => {
            if (owner.id) {
                switch (structure) {
                    case 'sole_director':
                        this.ownerObservables.push(this.clearhausRepo.destroyPerson(owner.id));
                        break;
                    case 'sole_not_director':
                        if (i === 0) {
                            this.savePerson(owner);
                        }
                        if (i >= 1) {
                            this.ownerObservables.push(this.clearhausRepo.destroyPerson(owner.id));
                        }
                        break;
                    case 'one_or_more_25':
                        this.savePerson(owner);
                        break;
                    case 'none_25':
                        this.ownerObservables.push(this.clearhausRepo.destroyPerson(owner.id));
                        break;
                }
            } else {
                switch (structure) {
                    case 'sole_not_director':
                        if (i === 0) {
                            this.savePerson(owner);
                        }
                        break;
                    case 'one_or_more_25':
                        this.savePerson(owner);
                        break;
                }
            }
        });
    }

    public savePerson(person: Person): void {
        const person_clone = Object.assign({}, person);
        const files = person_clone.files;
        delete person_clone.files;

        if (person_clone.country?.length === 3) {
            person_clone.country = this.countryService.alpha3ToAlpha2(person_clone.country);
        } else {
            person_clone.country = person_clone.country || undefined;
        }

        if (person_clone.id) {
            this.ownerObservables.push(
                this.updatePerson(person_clone, files).pipe(
                    map(owner => ({ owner })),
                    catchError(_ => of({ owner: null }))
                )
            );
        } else if (person_clone.name !== '') {
            this.ownerObservables.push(
                this.createPerson(person_clone, files).pipe(
                    map(owner => ({ owner })),
                    catchError(_ => of({ owner: null }))
                )
            );
        }
    }

    public createUpdateWebsites(compiledApplication: any): void {
        this.websitesFromServer.forEach(orgWebsite => {
            const found = compiledApplication.websites.some((website: Website) => orgWebsite.id === website.id);
            if (found && orgWebsite.id) {
                delete compiledApplication.websites[orgWebsite.id];
            }
        });

        compiledApplication.websites.forEach((website: Website) => {
            if (website.id) {
                this.websiteObservables.push(
                    this.clearhausRepo.updateWebsite(website).pipe(
                        map(w => ({ website: w })),
                        catchError(_ => of({ website: null }))
                    )
                );
            } else {
                this.websiteObservables.push(
                    this.clearhausRepo.createWebsite(website).pipe(
                        map(w => ({ website: w })),
                        catchError(_ => of({ website: null }))
                    )
                );
            }
        });
    }

    public combinedRequests(company: Company): Observable<boolean> {
        const combinedObservables = this.patchObservables.concat(this.fileObservables, this.websiteObservables, this.ownerObservables);
        return forkJoin(combinedObservables).pipe(
            map(forkJoinVariables => this.readyToSubmit(forkJoinVariables, company)),
            catchError(_ => of(false))
        );
    }

    public postSave(): void {
        this.applicationUploaded.emit();
        for (const person of this.people) {
            this.getPersonFiles.emit(person);
        }
        this.getFiles();
    }

    public getFiles(): Subscription {
        return this.clearhausRepo.getAllFiles().subscribe((files: Array<DirectorFile>) => {
            this.files = files;
            this.validateDocumentation.emit();
        });
    }

    public readyToSubmit(forkJoinVariables: any[], company: Company): boolean {
        let submitCheck = true;

        forkJoinVariables.forEach(res => {
            if (res) {
                Object.keys(res).forEach(key => {
                    const value = res[key];
                    if (typeof value === 'undefined') {
                        submitCheck = false;
                    }

                    if (['contact', 'company', 'bank_account', 'website'].includes(key) && !this.objectIsValid(value, key)) {
                        submitCheck = false;
                    }

                    if (
                        key === 'documentation' &&
                        company.country !== 'GB' &&
                        company.form !== 'Other' &&
                        !this.objectIsValid(value, key)
                    ) {
                        submitCheck = false;
                    }

                    if (key === 'director') {
                        if (!this.objectIsValid(value.director, 'director')) {
                            submitCheck = false;
                        }

                        const files = value['files'];
                        if (files.length < 2) {
                            submitCheck = false;
                        } else {
                            if (!files.some((f: DirectorFile) => f.label === 'address_legitimation')) {
                                submitCheck = false;
                            }
                            if (!files.some((f: DirectorFile) => f.label === 'picture_legitimation')) {
                                submitCheck = false;
                            }
                        }
                    }

                    if (key === 'owner') {
                        if (!this.objectIsValid(value.owner, 'owner')) {
                            submitCheck = false;
                        }

                        const files = value['files'];
                        if (files.length < 2) {
                            submitCheck = false;
                        } else {
                            if (!files.some((f: DirectorFile) => f.label === 'address_legitimation')) {
                                submitCheck = false;
                            }
                            if (!files.some((f: DirectorFile) => f.label === 'picture_legitimation')) {
                                submitCheck = false;
                            }
                        }
                    }
                });
            }
        });

        return submitCheck;
    }

    public objectHasKeys(obj: any, keys: string[]): boolean {
        let hasAllKeys = true;
        keys.forEach(key => {
            if (!obj.hasOwnProperty(key)) {
                hasAllKeys = false;
            }
        });
        return hasAllKeys;
    }

    public objectIsValid(obj: any, type: string): boolean {
        let valid = true;

        Object.keys(obj).forEach(key => {
            if (valid && typeof obj[key] === 'undefined') {
                valid = false;
            }
        });

        if (valid) {
            const attributes: { [key: string]: string[] } = {
                contact: ['name', 'email', 'phone'],
                company: [
                    'name',
                    'registration_number',
                    'email',
                    'phone',
                    'address_line_1',
                    'zipcode',
                    'city',
                    'country',
                    'ownership_structure'
                ],
                director: ['name', 'social_security_number', 'date_of_birth', 'address_line_1', 'zipcode', 'city', 'country'],
                documentation: [] as string[],
                owner: ['name', 'social_security_number', 'date_of_birth', 'address_line_1', 'zipcode', 'city', 'country'],
                bank_account: ['currency', 'bank', 'swift_code', 'iban'],
                website: ['url']
            };

            if (attributes.hasOwnProperty(type)) {
                attributes[type].forEach((key: any) => {
                    if (valid && !obj.hasOwnProperty(key)) {
                        valid = false;
                    }
                });
            }
        }

        return valid;
    }
}
