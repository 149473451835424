import { MPSProvider } from '@models/mps-provider';

export class Acquirer {
    public active?: boolean;
}

export interface VippsMobilePay extends Acquirer {
    msn?: string;
    currencies?: string[];
}

export interface Anyday extends Acquirer {
    access_token?: string;
    private_key?: string;
}

export interface Clearhaus extends Acquirer {
    api_key?: string;
    apple_pay?: boolean;
    google_pay?: boolean;
    business_code: string;
    mpi_merchant_id?: string;
    payout: boolean;
    recurring?: boolean;
    currency_specific_account: { [key: string]: ClearhausKey };
}

interface ClearhausKey {
    api_key: string;
    mpi_merchant_id?: string | null;
}

export interface KlarnaPayments extends Acquirer {
    uid?: number;
    password?: string;
}

export interface Mobilepay extends Acquirer {
    delivery_limited_to?: string;
    securepay?: boolean;
    merchant_id?: string;
}

export interface MobilePaySubscriptions extends Acquirer {
    auth_state: string;
    code_verifier: string;
    nonce: string;
    pending_status: number;
    provider?: MPSProvider;
    refresh_token: string;
    msn?: string;
}

export interface Paypal extends Acquirer {
    recurring?: boolean;
    credit_card?: boolean;
    token?: string;
    token_secret?: string;
    scope?: string[] | undefined;
}

export interface Paypalpayments extends Acquirer {
    merchant_payer_id: string;
    partner_referral_id: string;
    payments_receivable: boolean;
    primary_email_confirmed: boolean;
}

export interface Paysafecard extends Acquirer {
    username: string;
    password: string;
    currencies: string[];
}


export interface Resursbank extends Acquirer {
    customer_id: string;
    customer_password: string;
}

export interface Sofort extends Acquirer {
    customer_number?: number;
    project_id?: number;
    api_key?: string;
    gateway?: boolean;
    ideal?: boolean;
    ideal_project_id?: number;
    ideal_project_password?: string;
    ideal_notification_password?: string;
}

export interface Swish extends Acquirer {
    merchant_id?: string;
    cryptography_key_id?: null;
}

export interface Trustly extends Acquirer {
    username?: string;
    password?: string;
    ideal?: boolean;
}

export interface Viabill extends Acquirer {
    api_key?: string;
    category?: string;
    contactPhone?: {
        countryCode: string;
        phoneNumber: string;
    };
}

export interface Vipps extends Acquirer {
    client_id?: string;
    client_secret?: string;
    serial_number?: string;
    access_token_subscription_key?: string;
    ecommerce_subscription_key?: string;
    msn: string | undefined;
}

// Bambora, Elavon, Handelsbanken, Swedbank, Valitor and Teller are teller based acquireres
export interface TellerBased extends Acquirer {
    identification_code?: string;
    identification_code_int?: string;
    business_code?: string;
    recurring?: boolean;
    americanexpress?: boolean;
    dinersclub?: boolean;
    securepay?: boolean;
    visa_mpi_merchant_id?: null;
    mastercard_mpi_merchant_id?: null;
    visa_bin?: string;
    mastercard_bin?: string;
}

// nets is an extented teller based acquirer
export interface Nets extends TellerBased {
    fbg1886?: boolean;
    secured_by_nets?: boolean;
}

export interface UnzerPayLater extends Acquirer {
    direct_debit: {
        active: boolean;
        credentials: Credentials[];
    };
    instalment: {
        active: boolean;
        credentials: Credentials[];
    };
    invoice: {
        active: boolean;
        credentials: Credentials[];
    };
}

interface Credentials {
    currency: string;
    user: string;
    password: string;
}
