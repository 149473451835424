<mat-card *ngIf="transaction && valuesNotEmpty()" class="flex-col card02 avoid-page-break">
        <mat-card-title class="font-weight-normal" i18n>Shipping information</mat-card-title>
        <table class="card-table">
            <thead>
                <tr>
                    <th class="text-left" i18n>Variable</th>
                    <th class="text-left" i18n>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let key of objectKeys(transaction.shipping)" class="border-bottom">
                    <td class="maxwidth-250 capitalize">{{ key }}</td>
                    <td class="maxwidth-250">{{ transaction.shipping[key] }}</td>
                </tr>
            </tbody>
        </table>
</mat-card>
