import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'qp-add-credit-card',
    templateUrl: 'add-credit-card.component.html'
})
export class AddCreditCardDialogComponent {
    public accept = false;

    constructor(
        public dialogRef: MatDialogRef<AddCreditCardDialogComponent>
    ) {}

    public acceptTerms(): void {
        this.dialogRef.close(true);
    }
}
