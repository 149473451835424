<div class="flex-row justify-start items-center">
    <div *ngFor="let action of actions">
        <button
            mat-button
            class="margin r8"
            (click)="checkPaymentActionValid(action.value)"
            *ngIf="action.active && (action.permission | qpPermission)"
        >
            {{ action.translated_action_name }}
        </button>
    </div>
</div>
