import { Transaction } from '@models/transaction';
import { Injectable } from '@angular/core';
import { ExportFields } from '../dialog/exporter-fields';

@Injectable({
    providedIn: 'root'
})
export class OptionalTransactionFieldsParser {
    public adrField: Array<string> = [
        'name',
        'company name',
        'att',
        'street',
        'city',
        'zip code',
        'region',
        'country code',
        'vat no',
        'email',
        'phone number',
        'mobile number'
    ];

    public getHeader(fields: ExportFields): Array<string> {
        const csvHeader = [];

        for (const key in fields) {
            if (fields.hasOwnProperty(key) && (fields as any)[key]) {
                switch (key) {
                    case 'basket':
                        csvHeader.push('Basket');
                        break;
                    case 'invoice_address':
                        this.adrField.forEach(field => {
                            csvHeader.push('Invoice address ' + field);
                        });
                        break;
                    case 'shipping_address':
                        this.adrField.forEach(field => {
                            csvHeader.push('Shipping address ' + field);
                        });
                        break;
                    case 'variablesAssembled':
                        csvHeader.push('Variables');
                        break;
                    case 'variablesSplit':
                        fields.variablesSplitValue.forEach(variable => {
                            csvHeader.push('Variable_' + variable);
                        });
                        break;
                    default:
                        break;
                }
            }
        }

        return csvHeader;
    }

    public setData(transaction: Transaction, fields: any, data: any): any {
        for (const key in fields) {
            if (fields.hasOwnProperty(key) && fields[key]) {
                switch (key) {
                    case 'basket':
                        let basketText = '';
                        transaction.basket.forEach(item => {
                            for (const itemKey in item) {
                                if (item.hasOwnProperty(itemKey)) {
                                    basketText += `${itemKey}: ${(item as any)[itemKey]}` + '\n';
                                }
                            }
                            basketText += '\n';
                        });
                        data['Basket'] = '"' + basketText.substring(0, basketText.length - 2) + '"';
                        break;

                    case 'invoice_address':
                        const iAddress = transaction.invoice_address;
                        for (const valueKey in iAddress) {
                            if (iAddress.hasOwnProperty(valueKey)) {
                                if (this.adrField.includes(valueKey.replace(/_/g, ' '))) {
                                    data[`Invoice_address_${valueKey}`] = (iAddress as any)[valueKey] ?
                                        `${(iAddress as any)[valueKey]}` :
                                        '';
                                }
                            }
                        }
                        break;

                    case 'shipping_address':
                        const sAddress = transaction.shipping_address;
                        for (const valueKey in sAddress) {
                            if (sAddress.hasOwnProperty(valueKey)) {
                                if (this.adrField.includes(valueKey.replace(/_/g, ' '))) {
                                    data[`Shipping_address_${valueKey}`] = (sAddress as any)[valueKey] ?
                                        `${(sAddress as any)[valueKey]}` :
                                        '';
                                }
                            }
                        }
                        break;

                    case 'variablesAssembled':
                        let variablesText = '';
                        for (const valueKey in transaction.variables) {
                            if (transaction.variables.hasOwnProperty(valueKey)) {
                                variablesText += `${valueKey}: ${transaction.variables[valueKey]}` + '\n';
                            }
                        }
                        data['Variables'] = '"' + variablesText + '"';
                        break;

                    case 'variablesSplit':
                        for (const valueKey in transaction.variables) {
                            if (transaction.variables.hasOwnProperty(valueKey)) {
                                fields['variablesSplitValue'].forEach((variable: any) => {
                                    if (variable === valueKey) {
                                        data[`Variable_${variable}`] = `${transaction.variables[valueKey]}`;
                                    }
                                });
                            }
                        }
                        break;
                }
            }
        }

        return data;
    }
}
