import { Component, Input, OnInit } from '@angular/core';
import { MerchantReseller } from '@models/merchant-reseller';
import { Operation, OperationStatus } from '@models/operation';
import { HomepageService } from '@services/homepage/homepage.service';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { Animations } from 'app/animations/animations';

@Component({
    selector: 'qp-operations',
    templateUrl: './operations.html',
    styleUrls: ['./operations.scss'],
    animations: [Animations.getDetailExpandAnimation()]
})
export class OperationsComponent implements OnInit {
    @Input() public transactionId: number;
    @Input() public transactionState: string;
    @Input() public currency: string;
    @Input() public acquirer: string;

    public reseller: MerchantReseller | undefined = this.agreementService.agreement?.account?.reseller;
    public showRow: Array<boolean> = [];
    public urlCountry: string;
    public columnsToDisplay = ['created_at', 'type', 'amount', 'status', 'callback', 'expand'];
    public helpdeskLink: string = this.homepageService.getHelpdeskLink('3d-secure/');
    private _operations: Array<Operation>;

    constructor(
        private acquirerService: AcquirerService,
        private homepageService: HomepageService,
        private agreementService: AgreementService
    ) {}

    @Input()
    public set operations(value: Array<Operation>) {
        this._operations = value;
        this.operations.forEach(operation => {
            operation.operationStatus = this.getOperationStatus(operation);
        });
    }
    public get operations(): Array<Operation> {
        return this._operations;
    }

    public ngOnInit(): void {
        if (this.transactionState === 'rejected') {
            this.showRow[this.operations.length - 1] = true;
        }

        if (this.transactionState === 'initial' && this.operations[this.operations.length - 1].qp_status_code !== '20000') {
            this.showRow[this.operations.length - 1] = true;
        }
    }

    public is3dSecure(operation: Operation): boolean {
        return operation.qp_status_code === '30100';
    }

    public extendedSecureStatus(operation: Operation): boolean {
        return (
            operation.qp_status_code === '40000' &&
            operation.qp_status_msg === $localize`Rejected by acquirer` &&
            operation.aq_status_code === '40200' &&
            operation.aq_status_msg === $localize`not fully 3-D Secure`
        );
    }

    public parseAcquirerStatus(operation: Operation): string {
        if (!operation.aq_status_code && !operation.aq_status_msg) {
            return '';
        }

        if (operation.pending) {
            return $localize`Pending`;
        }

        if (operation.qp_status_code === '30100') {
            if (operation.type === 'recurring') {
                return (
                    $localize`Failed` +
                    '<br>' +
                    $localize`Acquirer status code` +
                    ': ' +
                    operation.aq_status_code +
                    '<br>' +
                    $localize`Acquirer status message` +
                    ': ' +
                    operation.aq_status_msg
                );
            }
            return $localize`The payment has automatically been redirected to 3-D Secure`;
        }

        if (
            this.acquirerService.isStatusApproved(operation.acquirer || this.acquirer, operation) &&
            operation.qp_status_code === '20000'
        ) {
            return $localize`Approved`;
        }

        const capitalizedAcquirer: string = this.capitalize(operation.acquirer);
        const code = capitalizedAcquirer + ' ' + $localize`status code` + ': ' + operation.aq_status_code;
        const msg = capitalizedAcquirer + ' ' + $localize`status message` + ': ' + operation.aq_status_msg;
        return code + '<br>' + msg;
    }

    public capitalize(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public getOperationStatus(operation: Operation): OperationStatus {
        const operationStatus = { status: '', color: '' };

        if (operation.pending) {
            operationStatus.status = $localize`Pending`;
            return operationStatus;
        }

        if (operation.qp_status_code === '30100' || operation.qp_status_code === '30101') {
            operationStatus.status = $localize`Redirected`;
            operationStatus.color = 'text-yellow';

            if (operation.type === 'recurring') {
                operationStatus.status = $localize`Failed`;
            }

            return operationStatus;
        }

        if (operation.qp_status_code === '20000' && operation.type === 'session') {
            operationStatus.status = $localize`Approved`;
            operationStatus.color = 'text-yellow';
            return operationStatus;
        }

        if (operation.qp_status_code === '20000') {
            operationStatus.status = $localize`Approved`;
            operationStatus.color = 'text-green';
            return operationStatus;
        } else {
            operationStatus.status = $localize`Failed`;
            operationStatus.color = 'text-darkred';
            return operationStatus;
        }
    }
}
