<!-- clearhaus card -->
<mat-card class="flex-col rg24 card02" *ngIf="!(['Unzer'] | qpMerchantType)">
    <div class="flex-col rg8">
        <qp-section-heading headingText="Visa and Mastercard" icon="account_balance" i18n-headingText></qp-section-heading>
        <mat-card-subtitle>
            <div i18n>Accept Visa and Mastercard with Clearhaus.</div>
            <div i18n>Clearhaus is a partner and affiliate of Quickpay.</div>
        </mat-card-subtitle>
    </div>

    <mat-card-content class="margin-negative">
        <mat-nav-list mat-card-image class="width-100p">
            <mat-list-item [routerLink]="['../acquirer', 'clearhaus']" [class.acquirer-success]="clearhaus_active">
                <a matListItemTitle class="semi-bold" mat-h3>
                    <span>Clearhaus</span>
                    <mat-icon
                        *ngIf="clearhaus_missing_credentials && clearhaus_active"
                        class="text-yellow s18"
                        matTooltip="Missing credentials"
                        matTooltipPosition="above"
                    >
                        warning
                    </mat-icon>
                </a>
                <div matListItemMeta class="flex-row items-center cg8">
                    <img height="13" width="40" ngSrc="assets/images/cards/visa-icon.svg" alt="visa">
                    <img height="19" width="25" ngSrc="assets/images/cards/mastercard-icon.svg" alt="mastercard">
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-card-content>
</mat-card>

<!-- nets card -->
<mat-card class="flex-col rg24 card02" *ngIf="is_danish_merchant">
    <div class="flex-col rg8">
        <qp-section-heading headingText="Dankort" icon="account_balance" i18n-headingText></qp-section-heading>
        <mat-card-subtitle i18n>
            Fill in your Dankort agreement details, to begin accepting Dankort payments.
        </mat-card-subtitle>
    </div>

    <mat-card-content class="margin-negative">
        <mat-nav-list>
            <mat-list-item [routerLink]="['../acquirer', 'nets']" [class.acquirer-success]="nets_dankort_active">
                <a class="semi-bold" matListItemTitle mat-h3>
                    <span>Nets - Dankort</span>
                    <mat-icon
                        *ngIf="netsDankort_missing_credentials && nets_dankort_active"
                        class="text-yellow s18"
                        matTooltip="Missing credentials"
                        matTooltipPosition="above"
                    >
                        warning
                    </mat-icon>
                </a>
                <div matListItemMeta class="flex-row items-center">
                    <img width="56" height="36" ngSrc="assets/images/cards/dankort.png" />
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-card-content>
</mat-card>

<!-- other card acquirers reseller view -->
<div *ngIf="!other_card_acquirers_active" class="flex-row justify-center items-center">
    <a (click)="other_card_acquirers_active = !other_card_acquirers_active" i18n>+ See other Visa and Mastercard acquirers</a>
</div>

<!-- other card acquirers -->
<mat-card class="flex-col rg24 card02" *ngIf="other_card_acquirers_active">
    <div class="flex-col rg8">
        <qp-section-heading headingText="External Visa and Mastercard acquirers" icon="account_balance" i18n-headingText></qp-section-heading>
        <mat-card-subtitle *ngIf="(['Quickpay'] | qpMerchantType)" i18n>
            Activating and processing payments through external acquirers adds aditional fees to your invoice. See <a href="https://quickpay.net/prices-eu/" class="cursor" target="_blank">quickpay.net/prices-eu/</a>
        </mat-card-subtitle>
    </div>

    <mat-card-content class="margin-negative">
        <mat-nav-list mat-card-image class="width-100p">
            <mat-list-item *ngFor="let acquirer of acquirers | qpFilter: isCardAcquirer; let last = last;" [ngClass]="{'bottom-border': !last}" [routerLink]="['../acquirer', acquirer.displayData.tpl]" [class.acquirer-success]="acquirer.active">
                <a matListItemTitle class="semi-bold" mat-h3>
                    <span>{{ acquirer.displayData.title }}</span>
                    <mat-icon
                        *ngIf="acquirer.displayData.credentials_missing && acquirer.active"
                        class="text-yellow s18"
                        matTooltip="Missing credentials"
                        matTooltipPosition="above"
                    >
                        warning
                    </mat-icon>
                </a>
                <div matListItemMeta class="flex-row">
                    <img *ngFor="let logo of acquirer.displayData.logos" width="{{ logo.width }}" height="{{ logo.height }}" ngSrc="{{ logo.path }}" />
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-card-content>

    <mat-card-content class="flex-col rg8" *ngIf="prioritize_visa">
        <qp-section-heading headingText="Prioritize Visa and Mastercard acquirer" icon="list" i18n-headingText></qp-section-heading>
        <mat-card-subtitle i18n>
            Specify a prioritized acquirer for Visa and Mastercard payments.
        </mat-card-subtitle>
        <div>
            <button mat-raised-button [routerLink]="['../prioritize']" i18n>
                Prioritize acquirer
            </button>
        </div>
    </mat-card-content>
</mat-card>

<!-- other acquirers -->
<mat-card class="flex-col rg24 card02">
    <div class="flex-col rg8">
        <qp-section-heading *ngIf="(['Unzer'] | qpMerchantType)" headingText="Payment methods" icon="apps" i18n-headingText></qp-section-heading>
        <qp-section-heading *ngIf="!(['Unzer'] | qpMerchantType)" headingText="Other payment methods" icon="apps" i18n-headingText></qp-section-heading>
        <mat-card-subtitle *ngIf="!(['Unzer'] | qpMerchantType)" i18n>
            Expand your customers payment options with more payment methods.
        </mat-card-subtitle>
    </div>

    <mat-card-content class="margin-negative">
        <mat-nav-list mat-card-image class="width-100p">
            <mat-list-item
                [routerLink]="['../acquirer', 'visa-mastercard']"
                [class.acquirer-success]="clearhaus_active"
                *ngIf="(['Unzer'] | qpMerchantType)">
                <a class="semi-bold" matLine mat-h3>Visa and Mastercard</a>
                <img height="13" width="40" ngSrc="assets/images/cards/visa-icon.svg" alt="visa">
                <img height="19" width="25" ngSrc="assets/images/cards/mastercard-icon.svg" alt="mastercard">
                <mat-divider></mat-divider>
            </mat-list-item>

            <mat-list-item *ngFor="let acquirer of acquirers | qpFilter: isOtherAcquirer; let last = last;" [ngClass]="{'bottom-border': !last}" [routerLink]="['../acquirer', acquirer.displayData.tpl]" [class.acquirer-success]="acquirer.active">
                <a matListItemTitle class="semi-bold" mat-h3>
                    <span>{{ acquirer.displayData.title }}</span>
                    <mat-icon
                        *ngIf="acquirer.displayData.credentials_missing && acquirer.active"
                        class="text-yellow s18"
                        matTooltip="Missing credentials"
                        matTooltipPosition="above"
                    >
                        warning
                    </mat-icon>
                </a>
                <div matListItemMeta class="flex-row">
                    <img *ngFor="let logo of acquirer.displayData.logos" width="{{ logo.width }}" height="{{ logo.height }}" ngSrc="{{ logo.path }}" />
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-nav-list>
    </mat-card-content>
</mat-card>
