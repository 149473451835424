import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { WINDOW } from '@helpers/windowprovider/window-provider';
import { AccessControlPermission } from '@interfaces/acl-permission';
import { Agreement } from '@interfaces/agreement';
import { BehaviorSubject } from 'rxjs';

export interface AgreementsParam {
    agreement_id: number;
    state: string;
    params: any;
}

@Injectable({
    providedIn: 'root'
})
export class AgreementService {
    public agreementsParams: AgreementsParam[] = [];
    public readonly agreement$ = new BehaviorSubject<Agreement | undefined>(undefined);
    public readonly agreements$ = new BehaviorSubject<Agreement[]>([]);

    constructor(@Inject(WINDOW) private window: Window) {}

    public get agreement(): Agreement | undefined {
        return this.agreement$.getValue();
    }

    public get agreements(): Agreement[] {
        return this.agreements$.getValue();
    }

    public setAgreement(agreement: Agreement | undefined): void {
        this.agreement$.next(agreement);
    }

    public setAgreements(agreements: Agreement[]): void {
        this.agreements$.next(agreements);
        this.window.sessionStorage.setItem('qp-agreements', JSON.stringify(agreements.map(agreement => agreement.account?.id)));
    }

    public referralID(): number {
        return this.agreement?.id ?? NaN;
    }

    public isAdmin(): boolean {
        return this.agreements.some(agreement => agreement.account?.type === 'Administrator')
            || this.agreement?.account?.type === 'Administrator';
    }

    public isReseller(): boolean {
        return this.agreements.some(agreement => agreement.account?.type === 'Reseller')
            || this.agreement?.account?.type === 'Reseller';
    }

    public isMerchant(): boolean {
        return !this.isAdmin() && !this.isReseller();
    }

    public hasAgreements(): boolean {
        return this.agreements.length > 0;
    }

    public isOwner(): boolean {
        return !!this.agreement?.owner;
    }

    public isUnzerReseller(): boolean {
        return this.agreement?.account?.id === environment.unzerResellerId;
    }

    public isQpOneReseller(): boolean {
        return this.agreement?.account?.id === environment.qpOneResellerId;
    }

    public isQpReseller(): boolean {
        return this.agreement?.account?.id === environment.qpResellerId;
    }

    public isDanishMerchant(): boolean {
        if (this.agreement?.account?.customer_address?.country_code) {
            return this.agreement.account.customer_address.country_code === 'DNK';
        }
        return false;
    }

    public hasPermission(path: string, method: string): boolean {
        if (!this.agreement) {
            return false;
        }

        if (this.isOwner()) {
            return true;
        }

        const permissions = this.agreement.acl_permissions;
        let permission: AccessControlPermission;

        if (!permissions) {
            return false;
        }

        method = (method && method.toLowerCase()) || 'get';
        for (let i = 0, count = permissions.length; i < count; i++) {
            permission = permissions[i];
            if (permission.resource === path) {
                if (permission.hasOwnProperty(method)) {
                    return (permission as any)[method];
                }
                return false;
            }
        }
        return false;
    }

    public isMerchantType(merchant_types: string[] | undefined): boolean {
        if (!Array.isArray(merchant_types)) {
            return false;
        }

        const id = this.agreement?.account?.reseller?.id;
        let is_valid = false;

        merchant_types.forEach(merchant => {
            switch (merchant) {
                case 'Quickpay':
                    if (environment.qpResellerId === id || environment.qpOneResellerId === id) {
                        is_valid = true;
                    }
                    break;
                case 'QuickpayOne':
                    if (environment.qpOneResellerId === id) {
                        is_valid = true;
                    }
                    break;
                case 'OldQuickpay':
                    if (environment.qpResellerId === id) {
                        is_valid = true;
                    }
                    break;
                case 'Unzer':
                    if (environment.unzerResellerId === id) {
                        is_valid = true;
                    }
                    break;
                default:
                    break;
            }
        });

        return is_valid;
    }
}
