<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card class="card02 flex-col rg16" *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Send a message to the support team" icon="mail" i18n-headingText></qp-section-heading>
            <span class="text-grey" *ngIf="(['Quickpay', 'Unzer'] | qpMerchantType)" i18n>
                You can also find answers to the most frequently asked questions on our
                <a *ngIf="!(['Unzer'] | qpMerchantType)" href="{{ faq_link }}" target="_blank">helpdesk</a>
                <a *ngIf="(['Unzer'] | qpMerchantType)" href="https://www.unzerdirect.com/helpdesk/en/" target="_blank">helpdesk</a>.
            </span>
        </div>

        <form [formGroup]="mail_form" class="flex-col rg16">
            <mat-form-field color="accent" hideRequiredMarker>
                <mat-label i18n>To</mat-label>
                <input matInput formControlName="mail_to" type="email" name="mail_to"/>
                <mat-error i18n>This field is required.</mat-error>
            </mat-form-field>
        
            <mat-form-field color="accent" hideRequiredMarker>
                <mat-label i18n>From</mat-label>
                <input matInput formControlName="mail_from" type="email" name="mail_from"/>
                <mat-error i18n>This field is required.</mat-error>
            </mat-form-field>

            <mat-form-field hideRequiredMarker>
                <mat-label i18n>Subject</mat-label>
                <input matInput formControlName="subject" type="text" name="subject"/>
                <mat-error i18n>This field is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n>Message</mat-label>
                <textarea matInput formControlName="message" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5" name="message"></textarea>
                <mat-hint i18n>Payment information is added to the mail</mat-hint>
                <mat-error i18n>This field is required.</mat-error>
            </mat-form-field>
  
            <qp-file-input i18n-label label="Click here to add files" [files]="input_files" (file_change)="addFile($event)"></qp-file-input>
        </form>

        <div class="flex-row">
            <span class="flex-1"></span>
            <qp-spinner-button color="accent" [disabled]="sending || !!mail_form.invalid" [showSpinner]="sending" i18n-btnText btnText="Send" (btnClick)="sendMail()"></qp-spinner-button>
        </div>
    </mat-card>
</div>
