import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Payment } from '@models/payment';
import { TransactionService } from '@services/transaction/transaction.service';

@Component({
    selector: 'qp-payment-action-result-dialog',
    templateUrl: 'payment-action-result-dialog.html'
})
export class PaymentActionResultDialogComponent {
    public handled_errors: string[];
    public header_text: string;
    public body_text: string;
    public error_text: string;
    public total_success: number;

    constructor(
        private transactionService: TransactionService,
        public dialogRef: MatDialogRef<PaymentActionResultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            action: string;
            total_payments: number;
            errors: Array<{ payment: Payment; error: Error }>;
        }
    ) {
        this.handled_errors = [];
        switch (this.data.action) {
            case 'capture':
                this.header_text = $localize`Capture payments`;
                this.body_text = $localize`Number of payments captured`;
                this.error_text = $localize`It was not possible to capture the following payments`;
                break;

            case 'refund':
                this.header_text = $localize`Refund payments`;
                this.body_text = $localize`Number of payments refunded`;
                this.error_text = $localize`It was not possible to refund the following payments`;
                break;

            case 'cancel':
                this.header_text = $localize`Cancel payments`;
                this.body_text = $localize`Number of payments canceled`;
                this.error_text = $localize`It was not possible to cancel the following payments`;
                break;

            case 'delete_customer_info':
                this.header_text = $localize`Delete customer info`;
                this.body_text = $localize`Number of payments modified`;
                this.error_text = $localize`It was not possible to modify the following payments`;
                break;
        }

        this.total_success = this.data.total_payments - this.data.errors.length;

        if (data.errors.length >= 1) {
            data.errors.forEach((payment_error: any) => {
                this.parseError(payment_error);
            });
        }
    }

    public parseError(payment_error: any): void {
        const operation = this.transactionService.lastOperation(payment_error.payment);
        let errorMsg = '';

        if (payment_error.payment.id) {
            errorMsg += payment_error.payment.id + ' - ';

            if (payment_error.error) {
                return payment_error.error.message;
            } else {
                if (operation) {
                    if (!operation.aq_status_code && !operation.aq_status_msg) {
                        errorMsg += operation.qp_status_code + ': ' + operation.qp_status_msg;
                    }
                    errorMsg += operation.aq_status_code + ': ' + operation.aq_status_msg;
                }
            }

            this.handled_errors.push(errorMsg);
        }
    }
}
