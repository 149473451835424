import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BankAccount } from '@models/clearhaus-application/ch-instant';
import { AgreementService } from '@services/agreement/agreement.service';
import { CurrencyService } from '@services/currency/currency.service';
import { CountryService } from '@services/country/country.service';
import { isValidIBAN } from 'ibantools';

@Component({
    selector: 'qp-clearhaus-bank-form',
    templateUrl: './bank-instant.html'
})
export class ClearhausInstantSignupFormStepSevenComponent implements OnInit, AfterViewInit {
    @Output() public bankAccountChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _bank_account: BankAccount;
    public bankForm: UntypedFormGroup;
    public currencies: Array<{ value: string; name: string }>;
    public clearhausCurrencies: string[] = ['DKK', 'EUR', 'SEK', 'NOK', 'GBP', 'USD'];

    public get currency(): UntypedFormControl {
        return this.bankForm.get('currency') as UntypedFormControl;
    }
    public get iban(): UntypedFormControl {
        return this.bankForm.get('iban') as UntypedFormControl;
    }

    @Input() public get bank_account(): BankAccount {
        return this._bank_account;
    }
    public set bank_account(value: BankAccount) {
        this._bank_account = value;
        this.bankAccountChange.emit(value);
    }

    constructor(
        private countryService: CountryService,
        private currencyService: CurrencyService,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        this.bankForm = new UntypedFormGroup(
            {
                currency: new UntypedFormControl('', Validators.required),
                iban: new UntypedFormControl('', Validators.required)
            },
            { validators: [this.ibanValidator] }
        );
    }

    public ngAfterViewInit(): void {
        this.bankForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(this.bankForm.valid);
        });

        this.currencies = this.currencyService.getArray().filter(currency => this.clearhausCurrencies.includes(currency.name));

        const country = this.countryService.getCountryFromCode(
            this.agreementService.agreement?.account?.customer_address?.country_code ?? ''
        );
        let selected = 'EUR';
        selected = this.clearhausCurrencies.includes(country?.currency ?? '') ? country?.currency ?? '' : 'EUR';

        this._bank_account.currency = this._bank_account.currency || selected;

        this.currency.setValue(this.bank_account.currency);
        this.iban.setValue(this.bank_account.iban);
        this.validityChange.emit(this.bankForm.valid);
    }

    public ibanValidator: ValidatorFn =
        () =>
            (control: UntypedFormGroup): ValidationErrors | null => {
                const iban = control.get('iban');
                if (iban?.value) {
                    return isValidIBAN(iban.value) ? null : { ibanError: true };
                }
                return null;
            };

    public onCurrencyChange(value: any): void {
        this.bank_account = { ...this.bank_account, currency: value };
    }

    public onIbanChange(eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        const iban = input.value;
        const ibanReplaced = iban.replace(/\s/g, '');
        const ibanUppercased = ibanReplaced.toUpperCase();
        this.iban.setValue(ibanReplaced);
        this.bank_account = { ...this.bank_account, iban: ibanUppercased };
    }
}
