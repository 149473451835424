<div class="flex-row">
    <div class="flex-col" *ngIf="transaction?.acquirer">
        <span class="subtitle1" i18n>Payment method</span>
        <span>{{ transaction.acquirer | qpAcquirer }}</span>
    </div>
    <div class="flex-col flex-50" *ngIf="transaction?.metadata?.number">
        <span class="subtitle1" i18n>Number</span>
        <span>{{ transaction.metadata.number }}</span>
    </div>
    <div class="flex-col flex-50" *ngIf="transaction?.metadata?.nin_number">
        <span class="subtitle1" i18n>Nin number</span>
        <span>{{ transaction.metadata.nin_number}}</span>
    </div>
</div>

<div class="flex-row">
    <div class="flex-100">
        <qp-transaction-fraud [metadata]="transaction.metadata"></qp-transaction-fraud>
    </div>
</div>

<div class="flex-row" *ngIf="disableFraud !== true">
    <qp-transaction-fraud-report [transaction]="transaction" (callBack)="onMakeCallBack()" [permission]="permission" [transactionRepo]="transactionRepo"> </qp-transaction-fraud-report>
</div>

<div class="flex-row" *ngIf="transaction?.metadata?.nin_gender">
    <br />
    <div class="flex-col flex-50">
        <span class="subtitle1" i18n>Gender</span>
        <span>{{ transaction.metadata.nin_gender }}</span>
    </div>
    <div class="flex-col flex-50">
        <span class="subtitle1" i18n>Country code</span>
        <span>{{ transaction.metadata.nin_country_code}}</span>
    </div>
</div>

<div class="flex-col rg8">
    <div class="flex-1" *ngIf="transaction?.metadata?.customer_ip">
        <div class="subtitle1" i18n>Customer IP</div>
        {{ transaction.metadata.customer_ip }}
    </div>
    <div class="flex-1" *ngIf="transaction?.metadata?.customer_country">
        <div class="subtitle1" i18n>Customer country</div>
        {{ transaction.metadata.customer_country }}
    </div>
</div>
