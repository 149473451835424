<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null" class="flex-col rg24">
    <mat-card class="card02" *ngIf="!acquirer.active">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Disputes" icon="chat_bubble_outline" i18n-headingText></qp-section-heading>
            <mat-card-content class="flex-col rg8">
                <span i18n>Please enable Clearhaus to activate your disputes.</span>
                <button mat-button [routerLink]="['../settings/acquirers/clearhaus']" color="accent" i18n>Go to Clearhaus</button>
            </mat-card-content>
        </div>
    </mat-card>

    <mat-card class="card02" *ngIf="error_loading && acquirer.active">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Disputes" icon="chat_bubble_outline" i18n-headingText></qp-section-heading>
            <mat-card-content class="flex-col rg8">
                <span i18n>It was not possible to access your disputes</span>
                <span i18n>Please contact support to gain access.</span>
            </mat-card-content>
        </div>
    </mat-card>

    <mat-card class="card02" *ngIf="!error_loading && acquirer.active">
        <mat-toolbar class="flex-row cg8 top-toolbar">
            <mat-tab-group selectedIndex="Main" (selectedTabChange)="changeTab($event)" color="accent">
                <mat-tab label="Main" i18n-label></mat-tab>
                <mat-tab *ngFor="let ch_account of acquirer.currency_specific_account | keyvalue" label="{{ ch_account.key }}" i18n-label></mat-tab>
            </mat-tab-group>

            <span class="flex-1"></span>
        </mat-toolbar>

        <mat-toolbar class="flex-row cg8 top-toolbar">
            <mat-tab-group selectedIndex="0"
                           (selectedTabChange)="changeState($event)"
                           color="accent"
                           class="tap-group-border">
                <mat-tab label="Open" i18n-label></mat-tab>
                <mat-tab label="Refuted" i18n-label></mat-tab>
                <mat-tab label="Under review" i18n-label></mat-tab>
                <mat-tab label="Closed" i18n-label></mat-tab>
            </mat-tab-group>

            <span class="flex-1"></span>
        </mat-toolbar>

        <mat-card-content class="flex-col rg16">
            <mat-progress-bar *ngIf="is_loading_results" mode="indeterminate"></mat-progress-bar>
            <qp-disputes-table
                *ngIf="data_source.data.length > 0"
                [dataSource]="data_source"
                [page]="page_params.page ?? 1"
                [pageSize]="page_params.page_size ?? 10"
                [displayColumns]="state"
            >
            </qp-disputes-table>


            <div *ngIf="data_source.data.length === 0 && !is_loading_results" class="flex-col justify-center items-center margin t16" i18n>
                No disputes were found.
            </div>

            <qp-paginator [config]="paginator_options" [data_length]="data_source.data.length" (pageChange)="onPageChange($event)"> </qp-paginator>
        </mat-card-content>
    </mat-card>
</div>
