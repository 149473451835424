import { Injectable } from '@angular/core';
import { Acquirer, Paypalpayments } from '@models/acquirers/acquirer';
import { Acquirers } from '@models/acquirers/acquirers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from './api-client';
import { PaypalApiClient } from './paypal-client';

@Injectable({
    providedIn: 'root'
})
export class AcquirerRepo {
    constructor(
        private api_client: ApiClient,
        private paypal_client: PaypalApiClient
    ) {}

    public getAll(): Observable<Acquirers> {
        return this.api_client.get<Acquirers>('/acquirers').pipe(
            map((item: any) => Object.assign(new Acquirers(), { ...item, vmp_epayments: { active: false } }))
        );
    }

    public get<T extends Acquirer>(acquirer: keyof Acquirers, params: any): Observable<T> {
        return this.api_client.get<T>('/acquirers/' + acquirer, { params }).pipe(
            map((item: any) => Object.assign(new Acquirer(), item))
        );
    }

    public update<T extends Acquirer>(name: keyof Acquirers, acquirer: T): Observable<T> {
        return this.api_client.patch<T>('/acquirers/' + name, acquirer);
    }

    public test(name: string): Observable<any> {
        return this.api_client.post<Acquirer>('/acquirers/' + name + '/test', null);
    }

    public paypalPermissions(): Observable<any> {
        return this.api_client.post<Acquirer>('/acquirers/paypal/callback/permissions', null, { observe: 'response' }).pipe(
            map((response: any) => response.headers.get('location'))
        );
    }

    public paypalOnboarding(id: number, params: any): Observable<{ action_url: string }> {
        return this.paypal_client.post<any>('/acquirers/paypalpayments/direct/merchant/' + id + '/onboard', params);
    }

    public paypalOnboardingStatus(id: number): Observable<Paypalpayments> {
        return this.paypal_client.post<any>('/acquirers/paypalpayments/direct/merchant/' + id + '/onboard/status', {});
    }

    public paypalOnboardedStatus(id: number): Observable<Paypalpayments> {
        return this.paypal_client.get<any>('/acquirers/paypalpayments/direct/merchant/' + id + '/onboard/status', {});
    }
}
