<mat-card *ngIf="!loading" class="flex-col rg32 card02">
    <div class="headerbackground flex-row">
        <div class="flex-col rg24 flex-70 headercontent">
            <div *ngIf="!application_sendt" class="flex-col rg24">
                <div class="flex-col rg8">
                    <h3 class="text-white" i18n>Activate marketing</h3>
                    <h4 class="text-white" i18n>Get started "No Cure No Pay"</h4>
                </div>
                <p class="text-white" i18n>
                    Sign up for Loyalty Key's partnership program and increase your online sales by reaching out to highly engaged consumers across the loyalty platforms, including Spar Nord Cashback, Cashback in partnership with Visa and BonusKroner.
                </p>

                <div class="flex-row cg16 items-center">
                    <button mat-raised-button color="accent" [routerLink]="['../../loyaltykey-application']" i18n>
                        Start application
                    </button>
                    <button mat-raised-button (click)="goToLoyalty()" i18n>
                        Learn more
                    </button>
                </div>
            </div>

            <div *ngIf="application_sendt" class="flex-col rg24">
                <h3 class="text-white" i18n>You're All Set!</h3>
                <p class="text-white" i18n>
                    Thank You for Signing Up! Your partnership with Loyalty Key is ready to kick off!
                </p>

                <p class="text-white" i18n>
                    All necessary information has been shared with Loyalty Key, and they will be in touch shortly to help you with the final steps. If you have any questions or need further assistance? visit the
                    <a href="https://www.loyaltykey.com/quickpay-landingpage/" target=”_blank”>website</a>
                    or feel free to contact us at
                    <a href="partnerskab@loyaltykey.com">partnerskab&#64;loyaltykey.com</a>
                    or at +45 70 20 05 91.
                </p>
            </div>

            <div class="flex-row cg24 items-center text-white fs10">
                <img width="74" height="26" alt="Spar Nord" ngSrc="assets/images/integrations/loyaltykey/sparnord.svg">
                <img width="86" height="13" alt="Visa" ngSrc="assets/images/integrations/loyaltykey/visa.svg">
                <img width="153" height="20" alt="Bonuskroner" ngSrc="assets/images/integrations/loyaltykey/bonuskroner.svg">
            </div>
        </div>

        <div class="flex-col flex-42 items-end justify-end">
            <img width="480" height="230" alt="Preview" ngSrc="{{image_url}}">
        </div>
    </div>
</mat-card>

<div class="flex-row cg16">
    <mat-card class="flex-col rg8 flex-33-mobile-100">
        <p class="subtitle3" i18n>Activate marketing</p>
        <p class="flex-1" i18n>
            Loyalty Key's powerful marketing channels, including digital platforms, apps, newsletters, and push messages, will put your brand directly in front of +150,000 highly engaged consumers in Denmark, helping you increase your online sales.
            <a href="https://www.loyaltykey.com/quickpay-landingpage/" target=”_blank”>Read more</a>
        </p>
        <img width="375" height="202" alt="Marketing" ngSrc="assets/images/integrations/loyaltykey/news_marketing.png" class="newsimage"/>
    </mat-card>

    <mat-card class="flex-col rg8 flex-33-mobile-100">
        <p class="subtitle3" i18n>Trusted by 1,500 merchants</p>
        <p class="flex-1" i18n>
            As a partner, Loyalty Key helps you increase your digital presence while also giving you access to Loyalty Key's cardlinked technology, which allows you to reward your customers for shopping with you.
            <a href="https://www.loyaltykey.com/quickpay-landingpage/" target=”_blank”>Read more</a>
        </p>
        <img width="304" height="148" alt="Trusted" ngSrc="assets/images/integrations/loyaltykey/news_trusted.png" class="newsimage"/>
    </mat-card>

    <mat-card class="flex-col rg8 flex-33-mobile-100">
        <p class="subtitle3" i18n>Get in contact</p>
        <p class="flex-1" i18n>
            Do you want to learn more about what a partnership entails? Find more information on Loyalty Key's website or contact us at
            <a href="partnerskab@loyaltykey.com">partnerskab&#64;loyaltykey.com</a>
            or at +45 70 20 05 91.
        </p>
        <img width="304" height="134" alt="Contact" ngSrc="assets/images/integrations/loyaltykey/news_contact.png" class="newsimage"/>
    </mat-card>
</div>
