import { Component, Input, OnInit } from '@angular/core';
import { Branding } from '@models/branding';
import { Operation } from '@models/operation';
import { Transaction } from '@models/transaction';
import { AgreementService } from '@services/agreement/agreement.service';
import { ShopSystem, SHOPSYSTEMS } from '@widgets/shopsystems/shopsystems.constants';
import { DateTime } from 'luxon';

@Component({
    selector: 'qp-transaction-details-acquirer',
    templateUrl: './transaction-acquirer.html'
})
export class TransactionAcquirerComponent implements OnInit {
    @Input() public transaction: Transaction = {
        operations: [] as Operation[],
        accepted: false,
        acquirer: '',
        basket: [],
        created_at: '',
        facilitator: '',
        link: {
            amount: NaN,
            payment_methods: '',
            url: ''
        },
        metadata: {
            bin: '',
            brand: '',
            corporate: false,
            country: '',
            customer_country: '',
            customer_ip: '',
            exp_month: NaN,
            exp_year: NaN,
            fraud_remarks: [],
            fraud_report_description: '',
            fraud_reported: false,
            fraud_reported_at: '',
            fraud_suspected: false,
            hash: '',
            is_3d_secure: '',
            issued_to: '',
            last4: '',
            moto: false,
            nin_country_code: '',
            nin_gender: '',
            nin_number: '',
            number: '',
            origin: '',
            shopsystem_name: '',
            shopsystem_version: '',
            type: ''
        },
        processed_at: new Date(),
        shipping: {},
        test_mode: false,
        updated_at: new Date(),
        variables: {},
        amount: NaN,
        currency: '',
        fee: NaN,
        id: NaN,
        invoice_address: {},
        reference: NaN,
        retented_at: new Date(),
        shipping_address: {},
        text_on_statement: '',
        threed_secure: '',
        type: ''
    };
    @Input() public branding: Branding;

    public shopsystem: ShopSystem | undefined;
    public display_warning = false;
    public display_rrn_info = false;
    public display_arn_info = false;
    public account_id = this.agreementService.agreement?.account?.id;

    constructor(
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        if (this.transaction.metadata?.shopsystem_version && this.agreementService.isMerchantType(['Quickpay'])) {
            this.shopsystem = SHOPSYSTEMS.find(shopsystem => shopsystem.name === this.transaction.metadata.shopsystem_name);
            if (this.shopsystem?.version !== '' && this.transaction.metadata?.shopsystem_version < (this.shopsystem?.version || '')) {
                if (this.shopsystem?.releaseDate !== '') {
                    const shopsystemDate = this.shopsystem?.releaseDate ? DateTime.fromISO(this.shopsystem.releaseDate) : DateTime.now();
                    const paymentDate = DateTime.fromISO(this.transaction.created_at.toString());

                    if (paymentDate > shopsystemDate) {
                        this.display_warning = true;
                    }
                }
            }
        }
    }
}
