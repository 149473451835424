<div class="flex-col rg24">
    <mat-card class="flex-col rg16 flex-632px-mobile-100 card02">
        <div><img src="assets/images/integrations/shopify_dark_logo.png" class="height-20" /></div>
        <div class="flex-col rg8">
            <h5 class="margin b0" i18n>Set up Shopify</h5>
            <p class="text-grey" *ngIf="(['Quickpay'] | qpMerchantType)" i18n>Note that an integration fee of 0.13 EUR / transaction will be added to your invoice.</p>
        </div>
        <ng-container *ngIf="this.shop_system !== 'Shopify'; else shopify_active">
            <div class="text-danger flex-row">
                <mat-icon class="text-yellow s18" i18n-matTooltip matTooltip="Payment is made with a testcard">
                    warning
                </mat-icon>
                <p i18n>You must set Shopify as <a [routerLink]="['../../merchant']">shop system</a> to allow payments with Shopify</p>
            </div>
            <div>
                <mat-card-content class="margin-negative">
                    <mat-nav-list class="width-100p">
                        <a
                            mat-list-item
                            *ngFor="let app of shopify_apps_list; let last = last;" [ngClass]="{'bottom-border': !last}"
                            href='{{ app.shopify_url }}' target="_blank">
                            <span matListItemTitle>{{ app.name }}</span>
                            <span matListItemMeta class="height-15" i18n>Install app</span>
                        </a>
                    </mat-nav-list>
                </mat-card-content>
            </div>
        </ng-container>
        <ng-template #shopify_active>
            <mat-card-content class="margin-negative">
                <mat-nav-list class="width-100p">
                    <a
                        mat-list-item
                        *ngFor="let app of shopify_apps_list; let last = last;" [ngClass]="{'bottom-border': !last}"
                        href='{{ app.shopify_url }}' target="_blank">
                        <span matListItemTitle>{{ app.name }}</span>
                        <span matListItemMeta class="height-15" i18n>Install app</span>
                    </a>
                </mat-nav-list>
            </mat-card-content>
        </ng-template>
    </mat-card>

    <div *ngFor="let domain of app_domains; let i = index;">
        <mat-card class="flex-col rg16 flex-632px-mobile-100 card02">
            <mat-card-title class="flex-row items-center">
                <a href="http://{{ domain.shop_domain }}"> {{ domain.shop_domain }} </a>
            </mat-card-title>

            <mat-list>
                <mat-list-item *ngFor="let app of domain.installed_apps; let last = last;" [ngClass]="{'bottom-border': !last}">
                    <span matListItemTitle class="semi-bold" matLine mat-h3>{{ reabableAppName(app.app_name) }}</span>
                    <div matListItemMeta>
                        <button mat-raised-button color="warn" (click)="removeApp(domain, app)" i18n>
                            Delete app
                        </button>
                    </div>
                </mat-list-item>
            </mat-list>

            <mat-list class="expandable-element" [@detail_expand]="domain.display_not_installed ? 'expanded' : 'collapsed'">
                <mat-list-item *ngFor="let app of domain.not_installed_apps; let last = last;" [ngClass]="{'bottom-border': !last}">
                    <span matListItemTitle class="semi-bold" matLine mat-h3>{{ reabableAppName(app) }}</span>
                    <div matListItemMeta>
                        <button mat-raised-button color="accent" (click)="installApp(domain, app)" i18n>
                            Install app
                        </button>
                    </div>
                </mat-list-item>
            </mat-list>

            <div class="flex-col flex-1 justify-center items-center" *ngIf="domain.not_installed_apps.length > 0">
                <button mat-raised-button *ngIf="!domain.display_not_installed" (click)="domain.display_not_installed = true" i18n>
                    Show not installed apps
                </button>
                <button mat-raised-button *ngIf="domain.display_not_installed" (click)="domain.display_not_installed = false" i18n>
                    Hide not installed apps
                </button>
            </div>
            
            <div *ngIf="domain.installed_apps.length <= 1">
                <button mat-raised-button color="warn" (click)="removeDomain(domain)" i18n>
                    DELETE DOMAIN
                </button>
            </div>
        </mat-card>
    </div>
</div>
