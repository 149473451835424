import { ApiModule } from 'app/endpoints/api/api.module';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpPrefixModule } from '@widgets/httpprefix/http-prefix.module';
import { IntegrationComponent } from './integration.component';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        HttpPrefixModule,
        LayoutModule,
        LayoutModule,
        PipesModule,
        ReactiveFormsModule
    ],
    declarations: [IntegrationComponent],
    exports: [IntegrationComponent]
})
export class IntegrationModule {}
