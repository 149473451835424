import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Animations } from 'app/animations/animations';

@Component({
    selector: 'qp-acquirer-show',
    templateUrl: 'show.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Animations.getFadeAnimation()]
})
export class AcquirerShowComponent {
}
