<form autocomplete="no">
    <mat-dialog-content class="flex-col rg16 grey-border-bottom">
        <h5 mat-dialog-title i18n>Capture payment</h5>
        <div class="flex-col">
            <div class="flex-row">
                <p class="flex-50" i18n>Payment ID</p>
                <p>{{ payment.id }}</p>
            </div>
            <div class="flex-row">
                <p class="flex-50" i18n>Order ID</p>
                <p>{{ payment.order_id }}</p>
            </div>
        </div>

        <mat-radio-group class="flex-col rg12" name="type" [(ngModel)]="type">
            <mat-radio-button value="full" i18n-aria-label aria-label="Full capture" i18n>
                Full capture ({{ payment.currency }} {{ max_capture | qpCurrency: payment.currency }})
            </mat-radio-button>
            <mat-radio-button value="partial" i18n-aria-label aria-label="Partial capture" i18n>Partial capture</mat-radio-button>
        </mat-radio-group>

        <div [hidden]="type === 'full'">
            <mat-form-field>
                <mat-label i18n>Amount</mat-label>
                <span class="input-left-label">{{ payment.currency }}</span>
                <input inputDecimal class="input-right" [currency]="payment.currency" matInput name="amount" fxFlex="100" type="text" required i18n-placeholder placeholder="Amount" [(ngModel)]="amount" />
                <mat-hint *ngIf="max_capture < amount" i18n> You cannot capture more than is authorized </mat-hint>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-row">
        <button mat-button mat-dialog-close i18n>
            Close
        </button>
        <button mat-button [disabled]="max_capture < amount" (click)="capture()" color="accent" i18n>
            Capture payment
        </button>
    </mat-dialog-actions>
</form>
