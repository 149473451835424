import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QpSnackBar } from '@services/snackbar/snackbar.service';

@Component({
    selector: 'qp-copy-dialog',
    styleUrls: ['copy.scss'],
    templateUrl: 'copy.html'
})
export class CopyDialogComponent {
    public show_tooltip = false;
    public tooltip: string;
    public is_json: boolean;
    public content: string;
    public showFullContent = false;


    constructor(
        public snackBar: QpSnackBar,
        public dialogRef: MatDialogRef<CopyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            header: string;
            content: string;
            msg: string;
            isSensitive: boolean;
        }
    ) {
        this.is_json = this.isJson(data.content);
    }

    public isJson(content: string): boolean {
        try {
            JSON.parse(content);
        } catch (e) {
            return false;
        }

        this.content = JSON.parse(content);
        return true;
    }

    public onError(): void {
        if (/mac/i.test(navigator.userAgent)) {
            this.tooltip = 'Press ⌘-C';
        } else {
            this.tooltip = 'Press Ctrl-C';
        }
        this.show_tooltip = true;
    }

    public onSuccess(): void {
        this.snackBar.open(this.data.msg);
        this.dialogRef.close();
    }

    public toggleContentVisibility(): void {
        if (this.data.isSensitive) {
            this.showFullContent = !this.showFullContent;
        }
    }

    public maskContent(content: string): string {
        if (this.data.isSensitive) {
            return `${content.substring(0, 1)}${'*'.repeat(Math.max(content.length - 2, 0))}${content.substring(content.length - 1)}`;
        }
        return content;
    }
}
