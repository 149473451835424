import { ACQUIRERDATA } from '@models/acquirers/acquirerdata.constant';
import { Acquirers } from '@models/acquirers/acquirers';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { Component, OnInit } from '@angular/core';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { environment } from '@environments/environment';
import { filter, map, tap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { LoyaltyKeyApplication, AcquirerData, WebsiteLocation } from '@models/integration/loyalty-key';
import { LoyaltyKeyRepo } from '@endpoints/server/loyalty-key-repo';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { UnsavedChanges } from 'app/routing/guards/unsaved-changes.interface';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'qp-loyaltykey-application',
    templateUrl: './loyaltykey-application.html',
    styleUrls: ['../../../thirdparty.scss']
})
export class LoyaltyKeyApplicationComponent implements OnInit, UnsavedChanges {
    public application_form: UntypedFormGroup;
    public is_danish_merchant = false;
    public countries: Country[];
    public selected_plan: string;
    public plans = [
        {
            plan: 'basis',
            price_month: 300000,
            value_month: 350000
        },
        {
            plan: 'pro',
            price_month: 500000,
            value_month: 700000
        },
        {
            plan: 'premium',
            price_month: 800000,
            value_month: 1150000
        }
    ];
    public terms_accepted: boolean;
    public saving: boolean;
    public websites: Array<{ name: string; url: string }> = [];

    constructor(
        private acquirerService: AcquirerService,
        private agreementService: AgreementService,
        private countryService: CountryService,
        private dialogService: DialogService,
        private loyaltykeyRepo: LoyaltyKeyRepo,
        private router: Router,
        public snackBar: QpSnackBar
    ) {}

    public ngOnInit(): void {
        if (
            this.agreementService.agreement?.account?.customer_address?.country_code &&
            this.agreementService.agreement.account.customer_address.country_code === 'DNK'
        ) {
            this.is_danish_merchant = true;
        }

        if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
            this.countries = this.countryService.getUnzerCountries();
        } else {
            this.countries = this.countryService.getCountries();
        }

        this.setupForm();
    }

    public hasUnsavedChanges(): Observable<boolean> {
        if (this.application_form && this.application_form.dirty && !this.terms_accepted) {
            return this.dialogService.unsavedChangesConfirm().afterClosed().pipe(map((result) => !!result));
        }
        return of(true);
    }

    public setupForm(): void {
        const account = this.agreementService.agreement?.account;
        const country = account?.customer_address?.country_code ?
            this.countries.find(c => account?.customer_address?.country_code === c.code) : undefined;

        this.application_form = new UntypedFormGroup({
            first_name: new UntypedFormControl({ value: '', disabled: false }),
            last_name: new UntypedFormControl({ value: '', disabled: false }),
            contact_email: new UntypedFormControl({ value: '', disabled: false }),
            registration_number: new UntypedFormControl({ value: account?.customer_address?.vat_no || '', disabled: false }),
            name: new UntypedFormControl({ value: account?.shop_name || '', disabled: false }),
            email: new UntypedFormControl({ value: account?.contact_email || '', disabled: false }),
            phone: new UntypedFormControl({ value: account?.contact_phone || '', disabled: false }),
            postcode: new UntypedFormControl({ value: account?.customer_address?.zip_code || '', disabled: false }),
            city: new UntypedFormControl({ value: account?.customer_address?.city || '', disabled: false }),
            country: new UntypedFormControl({ value: country || '', disabled: false }),
            rate: new UntypedFormControl({ value: '', disabled: false })
        });
    }


    public selectPlan(plan: string): void {
        this.selected_plan = plan;
    }

    public acceptTerms(accepted: boolean): void {
        this.terms_accepted = accepted;
    }

    public addSite(): void {
        const fields: PromptField[] = [
            {
                label: 'Website name',
                required: true,
                type: 'text',
                name: 'website'
            },
            {
                label: 'Shop url',
                required: true,
                type: 'url',
                name: 'url',
                value: 'https://'
            }
        ];

        this.dialogService.prompt(
            $localize`Add website`,
            '',
            $localize`Add website`,
            fields
        ).afterClosed().subscribe((returnFields: PromptField[]) => {
            if (returnFields) {
                const urls: any[] = this.websites;

                const urlSuffix: string = returnFields[1].value.split('.').pop();
                const urlDomains: string[] = returnFields[1].value.split('//').pop().split('.').slice(0, -1);
                let registered = false;

                if (urls.length > 0) {
                    urls.forEach(url => {
                        const domain = url.url.split('//').pop()?.split('.').slice(0, -1).pop();

                        if (domain === urlDomains.pop()) {
                            this.dialogService.confirm(
                                $localize`Website already exists`,
                                $localize`${domain + '.' + urlSuffix.split('/')[0]} seems to be a duplicate. You do not need to include additional subdomains or paths.`,
                                $localize`Add website`,
                                $localize`close`
                            ).afterClosed().subscribe((confirm: boolean) => {
                                if (confirm) {
                                    this.websites.push(
                                        {
                                            name: returnFields[0].value,
                                            url: returnFields[1].value.replace(/\s/g, '')
                                        }
                                    );
                                }
                            });
                            registered = true;
                        }
                    });
                }
                if (!registered) {
                    this.websites.push(
                        {
                            name: returnFields[0].value,
                            url: returnFields[1].value.replace(/\s/g, '')
                        }
                    );
                }
            }
        });
    }

    public removeSite(i: number): void {
        const url = this.websites[i];
        this.dialogService.confirm(
            $localize`Are you sure you want to remove ${url.url}?`,
            '',
            $localize`Remove`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            tap(() => this.websites.splice(i, 1))
        ).subscribe();
    }

    public send(): void {
        const application = {
            bank: {
                registration_number: '',
                account_number: ''
            },
            company: {
                registration_number: this.getFormControlByKey('registration_number').value,
                name: this.getFormControlByKey('name').value,
                email: this.getFormControlByKey('email').value,
                phone: this.getFormControlByKey('phone').value,
                postcode: this.getFormControlByKey('postcode').value,
                city: this.getFormControlByKey('city').value,
                country: this.getFormControlByKey('country').value.code
            },
            contact: {
                first_name: this.getFormControlByKey('first_name').value,
                last_name: this.getFormControlByKey('last_name').value,
                email: this.getFormControlByKey('email').value
            },
            locations: this.websitesToLocations(),
            marketing_package: this.selected_plan
        } as LoyaltyKeyApplication;

        this.loyaltykeyRepo.sendApplication(application).subscribe(() => {
            this.snackBar.open($localize`Application sent`);
            this.router.navigate(['../thirdparties']);
        });
    }

    public websitesToLocations(): Array<WebsiteLocation> {
        const card_acquirer: Array<AcquirerData> = this.getCardAcquireData();
        const locations: Array<WebsiteLocation> = [];

        this.websites.forEach((site) => {
            const website = {
                name: site.name,
                url: site.url,
                acquirer: card_acquirer,
                rewards: {
                    standard: {
                        rate: this.getFormControlByKey('rate').value
                    }
                }
            };
            locations.push(website);
        });

        return locations;
    }

    public getFormControlByKey(key: string): UntypedFormControl {
        return this.application_form.get(key) as UntypedFormControl;
    }

    public getCardAcquireData(): Array<AcquirerData> {
        const card_acquirer: Array<AcquirerData> = [];

        if (this.acquirerService.acquirers) {
            const acquirers = this.acquirerService.acquirers;
            Object.keys(acquirers).forEach(key => {
                if (environment.acquirersList.indexOf(key) !== -1) {
                    const acquirer: any =  JSON.parse(JSON.stringify(acquirers[key as keyof Acquirers]));
                    const data = ACQUIRERDATA[key];

                    if (data.card_acquirer && acquirer.active) {
                        switch (key) {
                            case 'clearhaus':
                                card_acquirer.push({
                                    name: data.title,
                                    id_intl: !!acquirer.mpi_merchant_id ? acquirer.mpi_merchant_id : '',
                                    id_dk: !!acquirer.mpi_merchant_id ? acquirer.mpi_merchant_id : ''
                                });
                                break;

                            case 'nets':
                                card_acquirer.push({
                                    name: data.title,
                                    id_intl: !!acquirer.identification_code ? acquirer.identification_code : '',
                                    id_dk: !!acquirer.identification_code ? acquirer.identification_code : ''
                                });
                                break;

                            default:
                                card_acquirer.push({
                                    name: data.title,
                                    id_intl: !!acquirer.identification_code ? acquirer.identification_code : '',
                                    id_dk: !!acquirer.identification_code_int ? acquirer.identification_code_int : ''
                                });
                                break;
                        }
                    }
                }
            });
        }

        return card_acquirer;
    }
}
