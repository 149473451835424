<div class="flex-row no-print">
    <button mat-button [routerLink]="['../subscriptions']" [queryParams]="{ page_size: 10 }" class="mat-button-back" color="accent">&lt; <span i18n>Back to Subscriptions</span></button>
</div>

<mat-card *ngIf="!loading" class="card02 flex-col rg24" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card-header>
        <mat-icon mat-card-avatar color="accent">group_add</mat-icon>
        <mat-card-title i18n>Subscription groups</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div class="flex-col rg16">

            <div *ngFor="let group of subscription_group; let i = index">
                <div class="flex-col grey-border-bottom">
                    <div class="flex-row space-between items-center">
                        <h5 class="margin-0">{{ group.name }}</h5>

                        <div class="flex-row">
                            <button mat-icon-button *ngIf="!showRow[i]" (click)="showRow[i] = !showRow[i]">
                                <mat-icon color="primary" matTooltip="Show group" i18n-matTooltip>expand_more</mat-icon>
                            </button>

                            <button mat-icon-button *ngIf="showRow[i]" (click)="showRow[i] = !showRow[i]">
                                <mat-icon color="primary" matTooltip="Hide group" i18n-matTooltip>expand_less</mat-icon>
                            </button>

                            <button mat-icon-button (click)="updateGroup(group)">
                                <mat-icon color="primary" matTooltip="Edit group" i18n-matTooltip>edit_outline</mat-icon>
                            </button>

                            <button mat-icon-button (click)="deleteGroup(group)">
                                <mat-icon color="primary" matTooltip="Remove group" i18n-matTooltip>delete_outline</mat-icon>
                            </button>

                            <button mat-icon-button (click)="captureGroup(group)">
                                <mat-icon color="primary" matTooltip="Capture group" i18n-matTooltip aria-label="Capture group">file_download</mat-icon>
                            </button>

                        </div>
                    </div>

                    <div class="expandable-element" [@detail_expand]="showRow[i] ? 'expanded' : 'collapsed'">
                        <div class="flex-col rg8">
                            <div class="flex-row cg12">
                                <p i18n>Currency: {{ group.currency }}</p>
                                <p i18n>Amount: {{ group.amount| qpCurrency: group.currency }}</p>
                            </div>

                            <p i18n>Description: {{ group.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>

    <div class="flex-row">
        <span class="flex-1"></span>
        <button mat-raised-button (click)="createGroup()">
            <span i18n>+ Create group</span>
        </button>
    </div>
</mat-card>
