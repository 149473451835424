import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { AccountRepo } from '@endpoints/api/account-repo';
import { AgreementRepo } from '@endpoints/api/agreement-repo';
import { catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { Account } from '@interfaces/account';
import { Agreement } from '@interfaces/agreement';
import { AgreementService } from '@services/agreement/agreement.service';
import { CredentialsService } from '@services/credentials/credentials.service';
import { SessionService } from '@services/session/session.service';
import { Observable, firstValueFrom, forkJoin } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

export const accountResolver: ResolveFn<Account | Agreement> = (route, _state) => {
    const sessionService = inject(SessionService);
    const agreementService = inject(AgreementService);
    const agreementRepo = inject(AgreementRepo);
    const accountRepo = inject(AccountRepo);
    const credentialsService = inject(CredentialsService);
    const router = inject(Router);
    const account_id = route.paramMap.get('account_id');

    if (!agreementService.agreement) {
        // No agreement is selected.
        // Get agreements and select the first one.

        const account_ids: number[] = JSON.parse(sessionStorage.getItem('qp-agreements') as string) as number[] || [];


        const get_agreement = (id: number): Observable<Agreement[]> =>
            agreementRepo.getAll({ account_id: id }, credentialsService.getUserApiKey());

        if (!account_ids.length) {
            return setupAccount();
        }

        const agreement_requests = account_ids.map(account_id => get_agreement(account_id).pipe(
            map(agr => agr[0]),
            tap((agr) => credentialsService.setCredentials('', agr?.api_key ?? '')),
            switchMap(agr => accountRepo.get().pipe(map(account => ({ ...agr, account }))))
        ));

        return firstValueFrom(
            forkJoin(agreement_requests).pipe(
                tap((res) => agreementService.setAgreements(res)),
                switchMap(() => agreementRepo.getAll({ account_id }, credentialsService.getUserApiKey())),
                switchMap((agreements: Agreement[]) => sessionService.selectAgreement(agreements[0]))
            )
        );
    } else if (agreementService.agreement && Number(agreementService.agreement.account?.id) !== Number(account_id)) {
        // Handle browser returns from different account.

        return setupAccount();
    }
    // Normal login by chosing account agreement or Had only a single owned account agreement
    return Promise.resolve(agreementService.agreement);

    function setupAccount(): Account | Agreement | Observable<Account | Agreement> | Promise<Account | Agreement> {
        return firstValueFrom(
            agreementRepo.getAll({ account_id }, credentialsService.getUserApiKey()).pipe(
                exhaustMap((agreements: Agreement[]) => {
                    if (agreements.length > 0) {
                        // The account has agreements
                        if (agreements[0].account?.type && !['Merchant', 'Reseller'].includes(agreements[0].account.type)) {
                            // First agreement has a type but it is not a merchant or reseller.
                            agreementService.setAgreements([]);
                            agreementService.agreementsParams = [];
                        } else if (agreementService.agreement && agreements[0].account?.type === 'Reseller') {
                            // The selected agreement is a reseller.
                            const index = agreementService.agreements.indexOf(agreementService.agreement);
                            if (index === 0) {
                                agreementService.setAgreements([]);
                            } else {
                                agreementService.agreements.splice(index, 1);
                            }

                            const resellerStateResult = agreementService.agreementsParams.find(
                                obj => agreementService.agreement && obj.agreement_id === agreementService.agreement.id
                            );
                            if (resellerStateResult) {
                                agreementService.agreementsParams.splice(
                                    agreementService.agreementsParams.indexOf(resellerStateResult),
                                    1
                                );
                            }
                        } else if (agreementService.agreement) {
                            // The selected agreement is a merchant.
                            agreementService.setAgreements([...agreementService.agreements, agreementService.agreement]);
                        }

                        // Find the selected agreement id in agreementParams
                        const stateResult = agreementService.agreementsParams.find(
                            obj => obj.agreement_id === agreementService.agreement?.id
                        );
                        if (stateResult) {
                            // If the id is found, put it in the agreementParams
                            agreementService.agreementsParams.splice(
                                agreementService.agreementsParams.indexOf(stateResult),
                                1
                            );
                        }
                    } else if (agreementService.agreement) {
                        // Put the current agreement in the list of agreements.
                        agreementService.setAgreements([...agreementService.agreements, agreementService.agreement]);
                    }

                    return sessionService.selectAgreement(agreements[0]);
                }),
                catchHttpStatusError([HttpStatusCode.Unauthorized], () => {
                    router.navigate(['/my-user']);
                }),
                catchError(() => {
                    throw new HttpErrorResponse({
                        status: 404,
                        statusText: 'Not Found'
                    });
                })
            )
        );
    }
};
