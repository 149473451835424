export class Application {
    public id?: string;
    public name?: string;
    public email?: string;
    public trading_name?: string;
    public description?: string;
    public recurring?: boolean;
    public physical_delivery?: boolean;
    public delivery_delay?: string;
    public drop_shipping?: boolean;
    public estimate_currency?: string;
    public estimated_monthly_turnover?: number;
    public estimated_average_transaction_amount?: number;
    public additional_information?: string;
    public business_model?: BusinessModel;
    public metadata?: Metadata;
    public signer?: {
        name: string;
        email: string;
    };
}

export class BusinessModel {
    public trading_name?: string;
    public description?: string;
    public recurring?: string;
    public physical_delivery?: boolean;
    public delivery_delay?: string;
    public drop_shipping: boolean;
    public estimate_currency?: string;
    public estimated_monthly_turnover?: number;
    public estimated_average_transaction_amount?: number;
}

class Metadata {
    public created_at?: string;
    public state?: string;
}
