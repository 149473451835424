import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { NgModule, ErrorHandler } from '@angular/core';
import { ErrorsHandler } from './errors-handler';

@NgModule({
    imports: [],
    declarations: [],
    providers: [
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler
        }
    ]
})
export class ErrorsModule {}
