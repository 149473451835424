import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { FileInput, FileInputComponent } from 'ngx-material-file-input';

@Component({
    selector: 'qp-clearhaus-signup-step-nine',
    templateUrl: './information.html'
})
export class ClearhausSignupFormStepNineComponent implements AfterViewInit {
    @ViewChild('additionalFilesInput') public fileInputComponent: FileInputComponent;
    @Output() public additionalInfoChange = new EventEmitter();
    @Output() public filesChange = new EventEmitter();

    public _additional_information: string;
    public _files: DirectorFile[] = [];
    public informationForm = new UntypedFormGroup({
        additional_information: new UntypedFormControl('', []),
        additional_files: new UntypedFormControl('', [])
    });

    public get additional_files(): UntypedFormControl {
        return this.informationForm.get('additional_files') as UntypedFormControl;
    }
    public get additional_information_control(): UntypedFormControl {
        return this.informationForm.get('additional_information') as UntypedFormControl;
    }

    @Input() public get additional_information(): string {
        return this._additional_information;
    }
    public set additional_information(value: string) {
        this._additional_information = value;
        this.additionalInfoChange.emit(value);
    }

    @Input() public get files(): DirectorFile[] {
        return this._files;
    }
    public set files(value: DirectorFile[]) {
        if (value.length) {
            this._files = value.filter(
                file => file.label && !['picture_legitimation', 'address_legitimation', 'documentation'].includes(file.label)
            );
            this.filesChange.emit(value);
        }
    }

    constructor() { }

    public ngAfterViewInit(): void {
        this.additional_files.valueChanges.subscribe(fileInput => {
            if (fileInput.files?.length) {
                this.addFiles(fileInput.files);
            }
        });
        this.additional_information_control.setValue(this.additional_information);
        this.setupFileInput();
    }

    public addFiles(files: any[] = []): void {
        const files_clone = [...this.files];
        files.forEach(file => {
            const directorFile: DirectorFile = {
                label: '',
                name: file.name,
                size: file.size,
                content_type: file.type,
                file
            };
            if (!this.files) {
                this.files = [];
            }
            files_clone.push(directorFile);
            this.files = files_clone;
        });
    }

    public removeFiles(): void {
        this.files = [];
        this.fileInputComponent.clear();
    }

    public setupFileInput(): void {
        this.additional_files.setValue(this._files.join(', '));
        if (this.fileInputComponent) {
            this.fileInputComponent.value = new FileInput(
                this.files.map(file => new File([''], file.name ?? '', { type: file.content_type }))
            );
        }
    }

    public modelAdditionalInfo(eventTarget: EventTarget | null): any {
        if (!eventTarget) {
            return null;
        }
        const input = eventTarget as HTMLInputElement;
        this.additional_information = input.value;
    }
}
