import { HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { Agreement } from '@interfaces/agreement';
import { Me } from '@interfaces/me';
import { AgreementService } from '@services/agreement/agreement.service';
import { CredentialsService } from '@services/credentials/credentials.service';
import { SessionService } from '@services/session/session.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { UnsavedChanges } from 'app/routing/guards/unsaved-changes.interface';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UserPermissionsComponent } from './permissions/permissions.component';

@Component({
    selector: 'qp-user',
    templateUrl: 'user.component.html'
})
export class UserComponent extends BaseLoadingDirective implements OnInit, UnsavedChanges {
    @ViewChild(UserPermissionsComponent) public permissions_component?: UserPermissionsComponent;
    @Input()
    public support = false;
    public agreement: Agreement;
    public current_agreement: Agreement | undefined;
    public me: Me | undefined;
    public user_name = '';

    constructor(
        private agreementRepo: AgreementRepo,
        private dialogService: DialogService,
        private sessionService: SessionService,
        private snackBar: QpSnackBar,
        private router: Router,
        private agreementService: AgreementService,
        private credentialsService: CredentialsService,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    public get is_system_user(): boolean {
        return !!this.agreement?.user?.system_user;
    }

    public get is_removable(): boolean {

        if (this.agreement.user?.system_user) {
            return this.agreement.service !== 'payment_window' && this.agreement.service !== 'api_user';
        } else {
            return true;
        }
    }

    public ngOnInit(): void {
        this.me = this.sessionService.me;
        this.current_agreement = this.agreementService.agreement;

        this.agreementRepo.get(this.activatedRoute.snapshot.params.agreement_id).subscribe((agreement: Agreement) => {
            this.agreement = agreement;
            this.support = agreement.support ?? false;
            this.user_name = agreement?.user?.email ?? '';
        });
    }

    public hasUnsavedChanges(): Observable<boolean> {
        if (!this.permissions_component) {
            return of(true);
        }
        return this.permissions_component.hasUnsavedChanges().pipe(
            switchMap(is_dirty => {
                if (is_dirty) {
                    return this.dialogService.unsavedChangesConfirm().afterClosed().pipe(
                        map((result: boolean) => !!result));
                } else {
                    return of(true);
                }
            })
        );
    }

    public removeUser(): void {
        this.dialogService.confirm(
            $localize`Remove agreement`,
            $localize`Are you sure you want to remove the agreement?`,
            $localize`Ok`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() => this.agreementRepo.destroy(this.activatedRoute.snapshot.params.agreement_id)),
            catchHttpStatusError([HttpStatusCode.BadRequest], (error) => {
                this.dialogService.alert($localize`Error`, error.message, 'warning');
            }),
            tap(() => {
                if (this.permissions_component) {
                    this.permissions_component.isDirty = false;
                }
                this.agreement.user?.id === this.me?.id ?
                    this.router.navigate(['./my-user']) :
                    this.router.navigate(['../../users'], { relativeTo: this.activatedRoute });
            })
        ).subscribe();
    }

    public regenerateApiKey(): void {
        this.dialogService.confirm(
            $localize`Regenerate api key`,
            $localize`Are you sure you want to regenerate the api key?`,
            $localize`Regenerate`
        ).afterClosed().pipe(
            switchMap(() => this.agreementRepo.regenerateApiKey(this.activatedRoute.snapshot.params.agreement_id)),
            tap((agreement: Agreement) => {
                if (this.agreementService.agreement?.id === agreement.id) {
                    this.agreementService.agreement.api_key = agreement.api_key;
                    this.credentialsService.setCredentials('', agreement.api_key ?? '');
                }
            }),
            tap(() => this.snackBar.open($localize`API key regenerated`))
        ).subscribe();
    }

    public copyApiKey(): void {
        this.dialogService.copy(
            $localize`${this.user_name} - API key`,
            this.agreement.api_key ?? '',
            $localize`Key copied to clipboard`,
            true
        );
    }
}
