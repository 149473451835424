<table mat-table #table [dataSource]="dataSource" mat-card-image>
    <div matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef i18n>Date</th>
        <td mat-cell *matCellDef="let row">
            <div *ngIf="row?.opened_at; else noperiod">
                <qp-date-field [date]="row?.opened_at"></qp-date-field>
            </div>
            <ng-template #noperiod>-</ng-template>
        </td>
    </div>

    <div matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Reference</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.reference !== 0; else noreference">
                    {{ row.reference }}
                </span>
                <ng-template #noreference>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Amount</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.amount !== 0; else noamount">
                    {{ row.amount | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #noamount>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Type</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.type !== 0; else notype">
                    <span *ngIf="row.type === '1st_chargeback'" i18n>1st chargeback</span>
                    <span *ngIf="row.type === 'arbitration'" i18n>Arbitration</span>
                    <span *ngIf="row.type === 'retrieval_request'" i18n>Retrieval request</span>
                    <span *ngIf="row.type === '1st_chargeback_reversal'" i18n>1st chargeback reversal</span>
                    <span *ngIf="row.type === '2nd_chargeback'" i18n>2nd chargeback</span>
                    <span *ngIf="row.type === 'representment'" i18n>Representment</span>
                    <span *ngIf="row.type === 'dispute_amount_credit'" i18n>Dispute amount credit</span>
                    <span *ngIf="row.type === 'dispute_amount_debit'" i18n>Dispute amount debit</span>
                </span>
                <ng-template #notype>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Reason</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.reason !== 0; else noreason">
                    <span *ngIf="row.reason === 'general'" i18n>General</span>
                    <span *ngIf="row.reason === 'incorrect_amount_or_currency'" i18n>Incorrect amount/currency</span>
                    <span *ngIf="row.reason === 'duplicate'" i18n>Duplicate</span>
                    <span *ngIf="row.reason === 'credit_not_processed'" i18n>Credit not processed</span>
                    <span *ngIf="row.reason === 'subscription_cancelled'" i18n>Subscription cancelled</span>
                    <span *ngIf="row.reason === 'product_not_provided'" class="text-grey" i18n>Product not provided</span>
                    <span *ngIf="row.reason === 'unrecognised'" i18n>Unrecognised</span>
                    <span *ngIf="row.reason === 'fraud'" i18n>Fraud</span>
                </span>
                <ng-template #noreason>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="due">
        <th mat-header-cell *matHeaderCellDef i18n>Due</th>
        <td mat-cell *matCellDef="let row">
            <div *ngIf="row?.due_at; else nodue">
                <qp-date-field [date]="row?.due_at"></qp-date-field>
            </div>
            <ng-template #nodue>-</ng-template>
        </td>
    </div>

    <div matColumnDef="expires">
        <th mat-header-cell *matHeaderCellDef i18n>Expires</th>
        <td mat-cell *matCellDef="let row">
            <div *ngIf="row?.expires_at; else noexpires">
                <qp-date-field [date]="row?.expires_at"></qp-date-field>
            </div>
            <ng-template #noexpires>-</ng-template>
        </td>
    </div>

    <div matColumnDef="outcome">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Outcome</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.status !== 0; else nooutcome">
                    <span *ngIf="row.status === 'won'" i18n>Won</span>
                    <span *ngIf="row.status === 'lost'" i18n>Lost</span>
                </span>
                <ng-template #nooutcome>-</ng-template>
            </div>
        </td>
    </div>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row
        class="cursor"
        *matRowDef="let row; columns: columns"
        [routerLink]="['../dispute', row.id]"
        >
    </tr>
</table>
