<h5 mat-dialog-title *ngIf="data.headline">
    <mat-icon class="material-icons text-darkred align-bottom" *ngIf="data.headline_icon">
        {{ data.headline_icon }}
    </mat-icon>
    <span class="pre-wrap">{{ data.headline }}</span>
</h5>

<mat-dialog-content class="grey-border-bottom" *ngIf="data.msg">
    <span class="pre-wrap" [innerHTML]="data.msg"></span>
</mat-dialog-content>

<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button tabindex="-1" mat-button color="accent" (click)="dialogRef.close(false)" i18n>
        OK
    </button>
</mat-dialog-actions>
