<div class="flex-col rg16">
    <div class="flex-col rg8">
        <qp-section-heading headingText="User permissions" icon="admin_panel_settings" i18n-headingText></qp-section-heading>
        <mat-card-subtitle i18n> The permissions control which parts of the system the user can see and change. An owner has all permissions enabled. </mat-card-subtitle>
    </div>

    <div *ngIf="!agreement?.user?.system_user && !support" class="flex-row margin t16">
        <mat-slide-toggle i18n-matTooltip i18n-aria-label matTooltip="An owner automatically has all permissions enabled" aria-label="Owner" name="active" (change)="updateOwner()" [(ngModel)]="agreement.owner">
            <div class="flex-row items-center cg8">
                <span i18n>Owner</span>
                <mat-icon class="mat-18">info_outline</mat-icon>
            </div>
        </mat-slide-toggle>
    </div>

    <div *ngIf="agreement && agreement.service === 'payment_window'" i18n>It is not possible to change the permissions of the payment window user</div>

    <div class="flex-row cg8 margin b8 t8">
        <button
            mat-raised-button
            (click)="selectPermissionTemplate()"
            *ngIf="
                (agreement?.account?.type === 'Merchant' && !agreement.owner) ||
                agreement?.account?.type === 'Administrator'
            " i18n>Use template</button>
        <button mat-raised-button color="accent" (click)="save()" *ngIf="isDirty" i18n>Save changes</button>
    </div>

    <div class="hide-gt-sm" i18n>Please open the manager on a desktop to change user permissions</div>

    <div *ngIf="agreement && !agreement.owner">
        <div class="flex-row" [class.warning-border]="isDirty" [class.success-border]="isSaved">

            <div class="flex-col rg12 flex-1">
                <mat-table #table [dataSource]="dataSource">
                    <!-- resource Column -->
                    <ng-container matColumnDef="resource">
                        <mat-header-cell class="flex-30" *matHeaderCellDef i18n>Resource</mat-header-cell>
                        <mat-cell class="flex-30" *matCellDef="let row">{{ row.resource }}</mat-cell>
                    </ng-container>
                    <!-- description Column -->
                    <ng-container matColumnDef="description">
                        <mat-header-cell class="flex-1" *matHeaderCellDef i18n>Description</mat-header-cell>
                        <mat-cell class="flex-1" *matCellDef="let row">{{ row.description }}</mat-cell>
                    </ng-container>
                    <!-- get Column -->
                    <ng-container matColumnDef="get">
                        <mat-header-cell class="flex-4 text-center" *matHeaderCellDef i18n>GET</mat-header-cell>
                        <mat-cell class="flex-4" *matCellDef="let permission">
                            <mat-checkbox i18n-matTooltip matTooltip="GET"
                                [disabled]="agreement.service === 'payment_window'"
                                [(ngModel)]="permission.get"
                                (change)="setDirty()"
                                *ngIf="(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('get')">
                            </mat-checkbox>
                            <mat-icon class="mat-18" *ngIf="!(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('get') && permission.get">check</mat-icon>
                        </mat-cell>
                    </ng-container>
                    <!-- post Column -->
                    <ng-container matColumnDef="post">
                        <mat-header-cell class="flex-4" *matHeaderCellDef i18n>POST</mat-header-cell>
                        <mat-cell class="flex-4" *matCellDef="let permission">
                            <mat-checkbox i18n-matTooltip matTooltip="POST"
                                [disabled]="agreement.service === 'payment_window'"
                                [(ngModel)]="permission.post"
                                (change)="setDirty()"
                                *ngIf="(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('post')">
                            </mat-checkbox>
                            <mat-icon class="mat-18" *ngIf="!(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('post') && permission.post"> check </mat-icon>
                        </mat-cell>
                    </ng-container>
                    <!-- patch Column -->
                    <ng-container matColumnDef="patch">
                        <mat-header-cell class="flex-4" *matHeaderCellDef i18n>PATCH</mat-header-cell>
                        <mat-cell class="flex-4" *matCellDef="let permission">
                            <mat-checkbox i18n-matTooltip matTooltip="PATCH"
                                [disabled]="agreement.service === 'payment_window'"
                                [(ngModel)]="permission.patch"
                                (change)="setDirty()"
                                *ngIf="(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('patch')">
                            </mat-checkbox>
                            <mat-icon class="mat-18" *ngIf="!(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('patch') && permission.patch"> check </mat-icon>
                        </mat-cell>
                    </ng-container>
                    <!-- get Column -->
                    <ng-container matColumnDef="put">
                        <mat-header-cell class="flex-4" *matHeaderCellDef i18n>PUT</mat-header-cell>
                        <mat-cell class="flex-4" *matCellDef="let permission">
                            <mat-checkbox i18n-matTooltip matTooltip="PUT"
                                [disabled]="agreement.service === 'payment_window'"
                                [(ngModel)]="permission.put"
                                (change)="setDirty()"
                                *ngIf="(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('put')">
                            </mat-checkbox>
                            <mat-icon class="mat-18" *ngIf="!(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('put') && permission.put"> check </mat-icon>
                        </mat-cell>
                    </ng-container>
                    <!-- delete Column -->
                    <ng-container matColumnDef="delete">
                        <mat-header-cell class="flex-5" *matHeaderCellDef i18n>DELETE</mat-header-cell>
                        <mat-cell class="flex-5" *matCellDef="let permission">
                            <mat-checkbox i18n-matTooltip matTooltip="DELETE"
                                [disabled]="agreement.service === 'payment_window'"
                                [(ngModel)]="permission.delete"
                                (change)="setDirty()"
                                *ngIf="(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('delete')">
                            </mat-checkbox>
                            <mat-icon class="mat-18" *ngIf="!(['/agreements/:id', 'put'] | qpPermission) && permission.hasOwnProperty('delete') && permission.delete"> check </mat-icon>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row class="cursor" *matRowDef="let row; columns: displayedColumns;"> </mat-row>
                </mat-table>

                <span class="flex-row cg12" *ngIf="isDirty">
                    <mat-icon class="text-yellow">announcement</mat-icon>
                    <span i18n>Unsaved changes</span>
                </span>
                <span class="flex-row cg12" *ngIf="isSaved">
                    <mat-icon class="text-green">done</mat-icon>
                    <span i18n>Settings saved</span>
                </span>
            </div>
        </div>
    </div>

    <div class="flex-row">
        <button mat-raised-button color="accent" (click)="save()" *ngIf="isDirty" i18n>Save changes</button>
        <span class="flex-1"></span>
    </div>
</div>
