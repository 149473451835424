<mat-card class="flex-col rg36 card02" *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-col rg8">
        <div class="flex-row cg16">
            <mat-icon color="accent">store</mat-icon>
            <h5 i18n>Shop information</h5>
        </div>

        <form [formGroup]="shopInfoForm" class="flex-col rg8">
            <div class="flex-row" [class.warning-border]="shopInfoForm.dirty" [class.success-border]="shopInfoFormSaved && shopInfoForm.pristine">

                <div class="flex-col rg12 flex-1">
                    <mat-form-field hideRequiredMarker>
                        <mat-label i18n>Shop name</mat-label>
                        <input matInput name="shop_name" formControlName="shopName" type="text" required />
                        <mat-error *ngIf="shopName?.errors?.required" i18n> Shop name is required. </mat-error>
                    </mat-form-field>

                    <mat-form-field hideRequiredMarker>
                        <mat-label i18n>Email</mat-label>
                        <input matInput name="email" formControlName="email" type="email" required />
                        <mat-error *ngIf="email?.errors?.required" i18n> An email address is required. </mat-error>
                        <mat-error *ngIf="email?.errors?.email" i18n> Invalid email address. </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n>Phone number</mat-label>
                        <input matInput name="phoneNumber" formControlName="phoneNumber" type="text" />
                    </mat-form-field>

                    <qp-form-state-info [form_dirty]="shopInfoForm.dirty" [form_pristine]="shopInfoForm.pristine" [form_saved]="shopInfoFormSaved" form_error=""> </qp-form-state-info>
                </div>
            </div>
        </form>

        <div *ngIf="shopInfoForm.dirty">
            <button mat-raised-button color="accent" [disabled]="shopInfoForm.invalid" (click)="preSaveShopInfo('shopInfoForm')" i18n>Save shop information</button>
        </div>
    </div>

    <div class="flex-col rg8" *ngIf="(['/agreements', 'get'] | qpPermission)">
        <div class="flex-row cg16">
            <mat-icon color="accent">integration_instructions</mat-icon>
            <h5 i18n>Shop system</h5>
        </div>

        <qp-shopsystems class="dont-extend-parent" (save)="saveShopsystem($event)" [readonly]="readonly" [(shopsystem)]="merchant.shopsystem!" placeholder="Shop system"> </qp-shopsystems>
    </div>

    <div class="flex-col rg8">
        <qp-section-heading headingText="Shop URL" icon="link" i18n-headingText></qp-section-heading>

        <mat-card-subtitle class="text-grey" i18n> The URL(s) from where you accept payments. </mat-card-subtitle>

        <qp-shopurls 
            [readonly]="!(['/account', 'patch'] | qpPermission)"
            [(shopUrls)]="merchant.shop_urls!"
            (add)="save({ shop_urls: merchant.shop_urls })"
            (remove)="save({ shop_urls: merchant.shop_urls })">
        </qp-shopurls>
    </div>

    <div class="flex-col rg8" *ngIf="theme.brand_name !== 'Unzer'">
        <qp-section-heading headingText="Accounting program" icon="integration_instructions" i18n-headingText></qp-section-heading>

        <form [formGroup]="accountingProgramForm" class="flex-col rg8">
            <mat-form-field class="flex-1">
                <mat-label i18n>Select accounting Program</mat-label>
                <mat-select name="SelectProgram" formControlName="selectedProgram" (selectionChange)="saveSelectedProgram()">
                    <mat-option *ngFor="let software of accountingSoftwares" [value]="software"> {{ software }} </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-1" *ngIf="getAccountingProgramFormControl('selectedProgram').value === 'Other'">
                <mat-label i18n>add custom Accounting Program</mat-label>
                <input matInput name="custom_program" formControlName="customProgram" type="text" (blur)="saveCustomProgram()" />
            </mat-form-field>
        </form>
    </div>

    <div class="flex-col rg8 settings-logo" *ngIf="(['/account', 'put'] | qpPermission)">
        <qp-section-heading headingText="Shop logo" icon="photo" i18n-headingText></qp-section-heading>

        <img [src]="logo" *ngIf="logo" class="size-90" />

        <div class="flex-row cg8">
            <button mat-raised-button (click)="fileInput.click()" i18n>Upload new logo</button>
            <button mat-raised-button *ngIf="logo" (click)="deleteLogo()" class="text-darkred" i18n>Remove logo</button>
        </div>
    </div>
</mat-card>

<mat-card class="flex-col rg8 card02" *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <qp-section-heading headingText="Merchant settings" icon="tune" i18n-headingText></qp-section-heading>

    <div class="flex-row settings-privatekey items-center" *ngIf="privateKey">
        <span i18n> Private key </span>
        <span class="flex-1"></span>
        <button mat-raised-button color="accent" (click)="copyPrivateKey()" i18n>Private key</button>
        <button mat-icon-button i18n-matTooltip matTooltip="Regenerate Private Key" (click)="regenerateApiKey()">
            <mat-icon class="mat-18">settings_backup_restore</mat-icon>
        </button>
    </div>
    <div class="flex-row" *ngIf="(['Merchant'] | qpAccountType) && (['/event-subscriptions', 'get'] | qpPermission) && (['/agreements', 'get'] | qpPermission)">
        <button mat-raised-button [routerLink]="['../events']" i18n>Set up event notifications</button>
    </div>
</mat-card>

<mat-card class="flex-col rg16 card02" *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row cg16">
        <mat-icon color="accent">public</mat-icon>
        <h5 i18n>Region and time zone</h5>
    </div>

    <form name="region" class="flex-col" autocomplete="off" [formGroup]="regionForm">
        <div class="flex-row" [class.warning-border]="regionForm.dirty" [class.success-border]="regionFormSaved && regionForm.pristine">

            <div class="flex-col rg12 flex-1">
                <mat-form-field class="flex-1">
                    <mat-label i18n>Timezone</mat-label>
                    <mat-select name="timezone" formControlName="timezone">
                        <mat-option *ngFor="let zone of zones" [value]="zone.value"> {{ zone.name }} </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-1">
                    <mat-label i18n>Locale (Language)</mat-label>
                    <mat-select name="locale" formControlName="locale">
                        <mat-option *ngFor="let locale of locales" [value]="locale.code"> {{ locale.name }} </mat-option>
                    </mat-select>
                </mat-form-field>

                <qp-form-state-info [form_dirty]="regionForm.dirty" [form_pristine]="regionForm.pristine" [form_saved]="regionFormSaved" form_error=""> </qp-form-state-info>
            </div>
        </div>
    </form>

    <div *ngIf="regionForm.dirty">
        <button mat-raised-button color="accent" [disabled]="shopInfoForm.invalid" (click)="preSaveShopInfo('regionForm')" i18n>Save region and time zone</button>
    </div>
</mat-card>

<input hidden type="file" id="logo" #fileInput (change)="uploadLogo($event.target)" />
