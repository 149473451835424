<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card class="card02">
        <div class="flex-row cg16">
            <mat-icon color="accent">list</mat-icon>
            <h5 i18n>External Integrations</h5>
        </div>
        <mat-card-content class="margin-negative">
            <mat-nav-list class="width-100p">
                <a
                    mat-list-item
                    *ngFor="let setting of integration_settings; let last = last;"
                    [routerLink]="['../thirdparty', setting.tpl]"
                    [ngClass]="{ 'bottom-border': !last}"
                >
                    <span matListItemTitle>{{ setting.title }}</span>
                    <img matListItemMeta class="height-15" src="assets/images/integrations/{{ setting.tpl }}_dark_logo.png" />
                </a>
            </mat-nav-list>
        </mat-card-content>
    </mat-card>
</div>
