import { ApiModule } from 'app/endpoints/api/api.module';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';
import { ResellerComponent } from './reseller.component';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        PipesModule,
        ReactiveFormsModule
    ],
    declarations: [ResellerComponent]
})
export class ResellerModule {}
