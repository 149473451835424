import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Acquirer, Clearhaus, TellerBased } from '@models/acquirers/acquirer';
import { ACQUIRERDATA } from '@models/acquirers/acquirerdata.constant';
import { Acquirers } from '@models/acquirers/acquirers';
import { Operation } from '@models/operation';
import { ACQUIRERSSTATUS } from './status.constants';

@Injectable({
    providedIn: 'root'
})
export class AcquirerService {
    public get acquirers(): Acquirers {
        return this._acquirers;
    }
    public set acquirers(acquirers: Acquirers) {
        this._acquirers = acquirers;
    }
    private _acquirers: Acquirers;

    private credential_property = [
        'access_token',
        'api_key',
        'code_verifier',
        'customer_password',
        'eid',
        'identification_code',
        'merchant_id',
        'msn',
        'password',
        'private_key',
        'serial_number',
        'token',
        'uid',
        'merchant_payer_id',
        'partner_referral_id'
    ];

    public isAnyAcquirerActive(): boolean {
        if (!this.acquirers || !Object.keys(this.acquirers).length) {
            return false;
        }

        return Object.values(this.acquirers).some(acquirer => acquirer?.active);
    }

    public isAnyAcquirerActiveWithCredentials(): boolean {
        if (!this.acquirers || !Object.keys(this.acquirers).length) {
            return false;
        }

        for (const acquirer of Object.keys(this.acquirers)) {
            if (
                !!this.acquirers[acquirer as keyof Acquirers] &&
                environment.acquirersList.indexOf(acquirer) !== -1 &&
                this.acquirers[acquirer as keyof Acquirers]?.active
            ) {
                for (const key of this.credential_property) {
                    if (this.acquirers[acquirer as keyof Acquirers].hasOwnProperty(key)) {
                        if (!!this.acquirers[acquirer as keyof Acquirers][key as keyof object]) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    public isCardAcquirerCredentialsValid(): boolean {
        if (!this.acquirers || !Object.keys(this.acquirers).length) {
            return false;
        }

        const acquirer_keys = Object.keys(this.acquirers);

        const card_acquirers = acquirer_keys.filter(acquirer => !!ACQUIRERDATA[acquirer] && ACQUIRERDATA[acquirer].card_acquirer);
        const enabled_card_acquirers = card_acquirers.filter(acquirer => environment.acquirersList.indexOf(acquirer) !== -1);
        return enabled_card_acquirers.some(acquirer => this.isCredentialsValid(this.acquirers[acquirer as keyof Acquirers]));
    }

    public isCardAcquirerActiveWithCredentials(): boolean {
        if (!this.acquirers || !Object.keys(this.acquirers).length) {
            return false;
        }

        for (const acquirer of Object.keys(this.acquirers)) {
            if (
                !!this.acquirers[acquirer as keyof Acquirers] && ACQUIRERDATA[acquirer] &&
                ACQUIRERDATA[acquirer].card_acquirer &&
                environment.acquirersList.indexOf(acquirer) !== -1 &&
                this.acquirers[acquirer as keyof Acquirers]?.active
            ) {
                if (
                    this.acquirers[acquirer as keyof Acquirers].hasOwnProperty('identification_code') &&
                    !!(this.acquirers[acquirer as keyof Acquirers] as TellerBased).identification_code
                ) {
                    return true;
                }

                if (
                    this.acquirers[acquirer as keyof Acquirers].hasOwnProperty('api_key') &&
                    !!(this.acquirers[acquirer as keyof Acquirers] as Clearhaus).api_key
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    public isCredentialsValid(acquirer: Acquirer): boolean {
        for (const key of this.credential_property) {
            if (acquirer.hasOwnProperty(key)) {
                if (!!acquirer[key as keyof Acquirer]) {
                    return true;
                }
            }
        }
        return false;
    }

    public getActiveAcquirersList(): { name: keyof Acquirers; acquirer: any }[] {
        if (!this.acquirers) {
            return [];
        }
        return Object.entries(this.acquirers)
            .filter(entry => !!entry[1])
            .filter(entry => entry[1]?.active)
            .map(entry => ({ name: entry[0] as keyof Acquirers, acquirer: entry[1] }));
    }

    public isPayoutEnabled(): boolean {
        if (!this.acquirers || !Object.keys(this.acquirers).length) {
            return false;
        }

        return (this.acquirers.clearhaus.payout && !!this.acquirers.clearhaus.active);
    }

    public isSubscriptionEnabled(): boolean {
        if (!this.acquirers || !Object.keys(this.acquirers).length) {
            return false;
        }

        return ((!!this.acquirers.clearhaus.active && this.acquirers.clearhaus.recurring) ||
            (!!this.acquirers.bambora.active && this.acquirers.bambora.recurring) ||
            (!!this.acquirers.valitor.active && this.acquirers.valitor.recurring) ||
            (!!this.acquirers.handelsbanken.active && this.acquirers.handelsbanken.recurring) ||
            (!!this.acquirers.nets.active && this.acquirers.nets.recurring) ||
            (!!this.acquirers.paypal.active && this.acquirers.paypal.recurring) ||
            (!!this.acquirers.swedbank.active && this.acquirers.swedbank.recurring) ||
            (!!this.acquirers.teller.active && this.acquirers.teller.recurring) ||
            !!this.acquirers.mobilepaysubscriptions.active);
    }

    public isStatusApproved(acquirer: string, operation: Operation): boolean {
        if (ACQUIRERSSTATUS[acquirer]) {
            return ACQUIRERSSTATUS[acquirer]['code'].indexOf(operation.aq_status_code) > -1;
        } else {
            return ACQUIRERSSTATUS['base']['code'].indexOf(operation.aq_status_code) > -1;
        }
    }

    public getDisplayNameFromKey(acquirerKey: keyof Acquirers): string {
        return ACQUIRERDATA[acquirerKey]?.title ?? acquirerKey;
    }
}
