import { Component, Input, Output, EventEmitter  } from '@angular/core';

@Component({
    selector: 'qp-form-save-discard',
    templateUrl: './form-save-discard.html'
})
export class FormSaveDiscardComponent {
    @Input() public form_dirty: boolean;
    @Input() public form_invalid: boolean;
    @Output() public form_save = new EventEmitter();
    @Output() public form_discard = new EventEmitter();

    public onSave(): any {
        return this.form_save.emit();
    }

    public onDiscard(): any {
        return this.form_discard.emit();
    }
}
