<mat-card class="card02 wrapper">
    <mat-card-title>404</mat-card-title>
    <mat-card-subtitle i18n>
        The page you are looking for was not found.
    </mat-card-subtitle>
    <div>
        <button mat-raised-button (click)="goBack()" color="accent" i18n>
            back to manager
        </button>
    </div>
</mat-card>
