<span *ngIf="readonly" i18n>Shop URL(s)</span>

<div *ngFor="let url of shopUrls; let index = index" class="flex-row justify-start items-center">
    <a href="{{ url }}" class="flex-1 truncate" rel="noopener" target="_blank">
        <!-- rel and target necessary as this code is not exposed in view -->
        {{ url }}
    </a>

    <div *ngIf="!readonly">
        <button mat-icon-button (click)="removeUrl(index, url)">
            <mat-icon class="text-darkred" matTooltip="Remove URL from list" i18n-matTooltip> delete_outline </mat-icon>
        </button>
    </div>
</div>

<div *ngIf="!readonly">
    <button mat-raised-button (click)="addUrl()" i18n>
        Add URL
    </button> 
    <p *ngIf="(['Unzer'] | qpMerchantType) && disable_unzer" i18n>Please contact Unzer to update this information</p>
</div>
