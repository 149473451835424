import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BankAccount } from '@models/clearhaus-application/bank-account';
import { isValidIBAN } from 'ibantools';

@Component({
    selector: 'qp-clearhaus-signup-step-seven',
    templateUrl: './bank.html'
})
export class ClearhausSignupFormStepSevenComponent implements OnInit, AfterViewInit {
    @Output() public bankAccountChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    public _bank_account: BankAccount;
    public _currencies: { value: string; name: string }[] = [];
    public bankForm: UntypedFormGroup;

    public get currency(): AbstractControl {
        return this.bankForm.get('currency') as UntypedFormControl;
    }
    public get bank(): AbstractControl {
        return this.bankForm.get('bank') as UntypedFormControl;
    }
    public get swift_code(): AbstractControl {
        return this.bankForm.get('swift_code') as UntypedFormControl;
    }
    public get iban(): AbstractControl {
        return this.bankForm.get('iban') as UntypedFormControl;
    }

    public set bank_account(value: BankAccount) {
        this._bank_account = value;
        this.bankAccountChange.emit(value);
    }
    @Input() public get bank_account(): BankAccount {
        return this._bank_account;
    }

    @Input() public get currencies(): { value: string; name: string }[] {
        return this._currencies;
    }
    public set currencies(val: { value: string; name: string }[]) {
        this._currencies = val.filter(currency => ['DKK', 'EUR', 'SEK', 'NOK', 'GBP', 'USD'].includes(currency.name));
    }

    constructor() {}

    public ngOnInit(): void {
        this.bankForm = new UntypedFormGroup(
            {
                currency: new UntypedFormControl('', Validators.required),
                bank: new UntypedFormControl('', Validators.required),
                swift_code: new UntypedFormControl(
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(/^[A-Z]{6}[2-9A-Z][0-9A-NP-Z](X{3}|[0-9A-WY-Z][0-9A-Z]{2})?$/g)
                    ])
                ),
                iban: new UntypedFormControl('', Validators.required)
            },
            { validators: [this.ibanValidator] }
        );
    }

    public ngAfterViewInit(): void {
        this.bankForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(!this.bankForm.invalid);
        });

        this.currency.setValue(this.bank_account.currency);
        this.bank.setValue(this.bank_account.bank);
        this.swift_code.setValue(this.bank_account.swift_code);
        this.iban.setValue(this.bank_account.iban);
        this.validityChange.emit(!this.bankForm.invalid);
    }

    public ibanValidator: ValidatorFn =
        () =>
        (control: UntypedFormGroup): ValidationErrors | null => {
            const iban = control.get('iban');
            if (iban?.value) {
                return isValidIBAN(iban.value) ? null : { ibanError: true };
            }
            return null;
        };

    public onCurrencyChange(value: any): void {
        this.bank_account = { ...this.bank_account, currency: value };
    }

    public modelBankAccount(propName: keyof BankAccount, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        const value = propName === 'iban' ? input.value.replace(/\s/g, '') : input.value;
        this.bank_account = { ...this.bank_account, [propName]: value };
    }
}
