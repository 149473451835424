import {
    AnimationTriggerMetadata,
    animate,
    style,
    transition,
    trigger,
    state
} from '@angular/animations';

export class Animations {
    public static getFadeAnimation(): AnimationTriggerMetadata {
        return trigger('fade_animation', [
            transition('* => fadeIn', [
                style({ opacity: 0 }),
                animate(500, style({ opacity: 1 }))
            ])
        ]);
    }

    public static getFadeInOutAnimation(): AnimationTriggerMetadata {
        return trigger('fade_in_out', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('.2s', style({ opacity: 1 }))
            ]),
            transition(':leave', [animate('.2s', style({ opacity: 0 }))])
        ]);
    }

    public static getFadeUpDownAnmation(): AnimationTriggerMetadata {
        return  trigger('fade_up_down', [
            transition(':enter', [
                style({ 'max-height': '0', opacity: '0' }),
                animate(
                    '600ms ease-in-out',
                    style({
                        'max-height': '400px',
                        opacity: '1'
                    })
                )
            ]),
            transition(':leave', [
                style({ 'max-height': '400px', opacity: '1' }),
                animate(
                    '500ms ease-in-out',
                    style({
                        opacity: '0',
                        'max-height': '0'
                    })
                )
            ])
        ]);
    }

    public static getDetailExpandAnimation(): AnimationTriggerMetadata {
        return trigger('detail_expand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'collapse' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]);
    }

    public static getRotateChevronAnimation(): AnimationTriggerMetadata {
        return trigger('rotate_chevron', [
            state('up', style({ transform: 'rotate(0)' })),
            state('down', style({ transform: 'rotate(180deg)' })),
            transition('up => down', animate('0.2s ease-out')),
            transition('down => up', animate('0.2s ease-in'))
        ]);
    }
}
