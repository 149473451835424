import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'free'
})
export class FreePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    public transform(value: string): string {
        const valueAsNumber = Number(value);
        if (Number.isNaN(valueAsNumber)) {
            return value;
        }
        return valueAsNumber > 0 ?
            valueAsNumber.toLocaleString(this.locale, { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
            $localize`Free`;
    }

}
