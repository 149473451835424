import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PipesModule } from '@pipes/pipes.module';
import { FiltersModule } from '@widgets/filters/filters.module';
import { CustomMaterialModule } from '@widgets/layout/material/custom-material.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { DisputesModule } from './disputes/disputes.module';
import { BaseLayoutModule } from './layout/base-layout.module';
import { LoginModule } from './login/login.module';
import { SaleModule } from './sale/sale.module';
import { SettingsModule } from './settings/settings.module';
import { SettlementstModule } from './settlements/settlements.module';
import { SupportModule } from './support/support.module';
import { TransactionModule } from './transactions/transactions.module';

@NgModule({
    imports: [
        BaseLayoutModule,
        BrowserAnimationsModule,
        CommonModule,
        CustomMaterialModule,
        DisputesModule,
        FiltersModule,
        KeyboardShortcutsModule.forRoot(),
        MatSnackBarModule,
        PipesModule,
        SettingsModule,
        SettlementstModule,
        SaleModule,
        SupportModule,
        TransactionModule,
        LoginModule
    ],
    exports: [
        LoginModule,
        BaseLayoutModule,
        SettingsModule,
        TransactionModule
    ]
})
export class ComponentsModule {}
