import { ApiModule } from 'app/endpoints/api/api.module';
import { BillingComponent } from './billing.component';
import { BillingPaymentMethodComponent } from './payment/payment.component';
import { BillingWizardComponent } from './wizard/wizard.component';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceComponent } from './invoices/invoice/invoice.component';
import { InvoiceEmailsComponent } from './emails/emails.component';
import { InvoiceRequisitionNumberComponent } from './requisition_number/requisition_number.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LayoutModule } from '@widgets/layout/layout.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MerchantSpecificationComponent } from './invoices/invoice/merchant-specification/merchant-specification.component';
import { NgModule } from '@angular/core';
import { PaginatorModule } from '@widgets/qp-paginator/qp-paginator.module';
import { PaymentWindowComponent } from './payment-window/payment-window.component';
import { PipesModule } from '@pipes/pipes.module';
import { SearchFieldModule } from '@widgets/searchfield/search-field.module';
import { SelectPaymentMethodComponent } from './payment/select/select.component';
import { ShopurlsModule } from '@widgets/shopurls/shopurls.module';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PricingAndTermsComponent } from './pricing-and-terms/pricing-and-terms.component';
import { AdditionalPricesDialogComponent } from './pricing-and-terms/additional-prices-dialog/additional-prices-dialog.component';
import { AdditionalChPricesDialogComponent } from './pricing-and-terms/additional-ch-prices-dialog/additional-ch-prices-dialog.component';
import { AddCreditCardDialogComponent } from './payment/add-credit-card/add-credit-card.component';
import { AddOtherPaymentMethodDialogComponent } from './payment/add-other-payment-method/add-other-payment-method.component';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatStepperModule,
        PaginatorModule,
        PipesModule,
        ReactiveFormsModule,
        SearchFieldModule,
        ShopurlsModule,
        RouterModule
    ],
    declarations: [
        AddCreditCardDialogComponent,
        AddOtherPaymentMethodDialogComponent,
        BillingComponent,
        BillingPaymentMethodComponent,
        BillingWizardComponent,
        InvoiceComponent,
        InvoiceEmailsComponent,
        InvoiceRequisitionNumberComponent,
        InvoicesComponent,
        MerchantSpecificationComponent,
        PaymentWindowComponent,
        // PlanComponent,
        // PlansListComponent,
        SelectPaymentMethodComponent,
        // PlansIndexComponent,
        // PlansCreateComponent,
        // PlansShowComponent,
        PricingAndTermsComponent,
        AdditionalPricesDialogComponent,
        AdditionalChPricesDialogComponent
    ]
})
export class BillingModule {}
