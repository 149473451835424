import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { CardRepo } from '@endpoints/api/card-repo';
import { PaymentRepo } from '@endpoints/api/payment-repo';
import { PayoutRepo } from '@endpoints/api/payout-repo';
import { SubscriptionRepo } from '@endpoints/api/subscription-repo';
import { Card } from '@models/card';
import { Payment } from '@models/payment';
import { Payout } from '@models/payout';
import { Subscription } from '@models/subscription';
import { ParamService } from '@services/param/params.service';
import { ExportFields } from 'app/exporter/dialog/exporter-fields';
import { ExporterService } from 'app/exporter/exporter.service';
import { ExporterCardParser } from 'app/exporter/parsers/card.parser';
import { OptionalTransactionFieldsParser } from 'app/exporter/parsers/fields.parser';
import { ExporterPaymentParser } from 'app/exporter/parsers/payment.parser';
import { ExporterPayoutParser } from 'app/exporter/parsers/payout.parser';
import { ExporterSubscriptionParser } from 'app/exporter/parsers/subscription.parser';
import { filter, map } from 'rxjs';

@Component({
    selector: 'qp-export-transactions-tool',
    templateUrl: './export-transactions-tool.html'
})
export class ExportTransactionsToolbarComponent implements OnInit {
    @Input() public transaction_type: string;
    @Input() public selected_transactions = new SelectionModel<Card | Payment | Payout | Subscription>(true, []);

    public transaction_parser: ExporterCardParser | ExporterPaymentParser | ExporterPayoutParser | ExporterSubscriptionParser;
    public transaction_repo: CardRepo | PaymentRepo | PayoutRepo | SubscriptionRepo;

    constructor(
        private cardRepo: CardRepo,
        private exporterCardParser: ExporterCardParser,
        private exporterPaymentParser: ExporterPaymentParser,
        private exporterPayoutParser: ExporterPayoutParser,
        private exporterSubscriptionParser: ExporterSubscriptionParser,
        private exportService: ExporterService,
        private optionalTransactionFieldsParser: OptionalTransactionFieldsParser,
        private paymentRepo: PaymentRepo,
        public payoutRepo: PayoutRepo,
        public subscriptionRepo: SubscriptionRepo,
        private paramService: ParamService
    ) {}

    public ngOnInit(): void {
        switch (this.transaction_type) {
            case 'cards':
                this.transaction_parser = this.exporterCardParser as ExporterCardParser;
                this.transaction_repo = this.cardRepo as CardRepo;
                break;
            case 'payments':
                this.transaction_parser = this.exporterPaymentParser as ExporterPaymentParser;
                this.transaction_repo = this.paymentRepo as PaymentRepo;
                break;
            case 'payouts':
                this.transaction_parser = this.exporterPayoutParser as ExporterPayoutParser;
                this.transaction_repo = this.payoutRepo as PayoutRepo;
                break;
            case 'subscriptions':
                this.transaction_parser = this.exporterSubscriptionParser as ExporterSubscriptionParser;
                this.transaction_repo = this.subscriptionRepo as SubscriptionRepo;
                break;
        }
    }

    public onExportAllTransactions(): void {
        const csv_header = this.transaction_parser.getHeader();
        const params = this.paramService.returnPrevParam();

        Object.keys(params).forEach((key: string) => {
            if (params[key] === undefined || params[key] === null) {
                delete params[key];
            }

            if (key === 'state') {
                const state = params['states'][params['state']];
                if (state !== 'all') {
                    params['state'] = params['states'][params['state']];
                } else {
                    delete params['state'];
                }
            }
        });

        this.exportService.getFieldsToExport(
            { display: true }
        ).afterClosed().pipe(
            filter(x => !!x),
            map((returnFields: ExportFields) => {
                csv_header.push(...this.optionalTransactionFieldsParser.getHeader(returnFields));
                this.exportService.exportObjects(
                    this.transaction_repo,
                    params,
                    csv_header,
                    returnFields,
                    'Export_' + this.transaction_type,
                    this.transaction_parser
                );
            })
        ).subscribe();
    }

    public onExportSelectedTransactions(): void {
        const display_options = this.transaction_type === 'payouts' ? { display: false } : { display: true };
        const export_element: any[] = [];
        const csv_header = this.transaction_parser.getHeader();

        this.exportService.getFieldsToExport(
            display_options
        ).afterClosed().pipe(
            filter(x => !!x),
            map((returnFields: ExportFields) => {
                csv_header.push(...this.optionalTransactionFieldsParser.getHeader(returnFields));

                for (const key in this.selected_transactions.selected) {
                    if (this.selected_transactions.selected.hasOwnProperty(key)) {
                        export_element.push(
                            this.transaction_parser.parse(this.selected_transactions.selected[key] as any, returnFields)
                        );
                    }
                }

                this.exportService.downloadCSVFile(
                    export_element,
                    csv_header,
                    'Export_' + this.transaction_type
                );
            })
        ).subscribe();
    }
}
