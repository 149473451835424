import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.startsWith('/invoicing/')) {
            return next.handle(req);
        }
        if (req.body) {
            return next.handle(
                req.clone({
                    body: { data: req.body }
                })
            );
        }
        return next.handle(req);
    }
}
