import { Routes } from '@angular/router';
import { ACQUIRERDATA } from '@models/acquirers/acquirerdata.constant';
import { AccountComponent } from 'app/components/settings/account/account.component';
import { ClearhausSignupComponent } from 'app/components/settings/acquirers/acquirer/clearhaus/signup/signup.component';
import { AcquirersComponent } from 'app/components/settings/acquirers/acquirers.component';
import { AdvanceTellerBasedComponent } from 'app/components/settings/acquirers/show/advance/teller-based.component';
import { AcquirerFeeComponent } from 'app/components/settings/acquirers/show/fee/fee.component';
import { PrioritizeComponent } from 'app/components/settings/acquirers/show/prioritize/prioritize.component';
import { AcquirerShowComponent } from 'app/components/settings/acquirers/show/show.component';
import { BillingComponent } from 'app/components/settings/billing/billing.component';
import { InvoiceComponent } from 'app/components/settings/billing/invoices/invoice/invoice.component';
import { MerchantSpecificationComponent } from 'app/components/settings/billing/invoices/invoice/merchant-specification/merchant-specification.component';
import { BrandingComponent } from 'app/components/settings/brandings/branding/branding.component';
import { BrandingEditComponent } from 'app/components/settings/brandings/branding/edit/edit.component';
import { BrandingsComponent } from 'app/components/settings/brandings/brandings.component';
import { BrandingConfigComponent } from 'app/components/settings/brandings/config/config.component';
import { FraudfilterComponent } from 'app/components/settings/fraudfilter/fraudfilter.component';
import { RulesetComponent } from 'app/components/settings/fraudfilter/ruleset/ruleset.component';
import { IntegrationComponent } from 'app/components/settings/integration/integration.component';
import { SettingsMerchantEventsComponent } from 'app/components/settings/merchant/events/events.component';
import { SettingsMerchantComponent } from 'app/components/settings/merchant/merchant.component';
import { ResellerComponent } from 'app/components/settings/reseller/reseller.component';
import { LoyaltyKeyApplicationComponent } from 'app/components/settings/thirdparty/integrations/loyaltykey/application/loyaltykey-application.component';
import { ThirdpartyShowComponent } from 'app/components/settings/thirdparty/show/thirdparty-show.component';
import { ThirdpartyComponent } from 'app/components/settings/thirdparty/thirdparty.component';
import { UserComponent } from 'app/components/settings/users/user/user.component';
import { UsersComponent } from 'app/components/settings/users/users.component';
import { cardGuard } from './guards/acquirers/card.guard';
import { unzerGuard } from './guards/acquirers/unzer.guard';
import { unsavedChangesGuard } from './guards/unsaved-changes.guard';

export const settings_routes: Routes = [
    {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full'
    },
    {
        path: 'admin/kms',
        loadComponent: () => import('app/components/settings/kms/kms.component').then(m => m.KmsComponent),
        data: {
            settings: {
                index: 20,
                header: 'settings',
                content: 'KMS',
                title: 'KMS',
                permission: { '/cryptography/keys': ['get'] },
                roles: ['Administrator'],
                icon: 'style'
            }
        }
    },
    {
        path: 'admin/kms-key/:key_id',
        loadComponent: () => import('app/components/settings/kms/kms-edit/kms-edit.component').then(m => m.KmsEditComponent),
        data: {
            settings: {
                title: 'KMS'
            }
        }
    },
    {
        path: 'admin/user/:user_id',
        loadComponent: () => import('app/components/admin-users/admin-user/admin-user.component').then(m => m.AdminUserComponent),
        data: {
            settings: {
                title: 'User',
                permission: { '/users/:id': ['get'] }
            }
        }
    },
    {
        path: 'users',
        component: UsersComponent,
        data: {
            settings: {
                index: 40,
                header: 'settings',
                content: 'Users',
                title: 'Users',
                permission: { '/agreements': ['get'] },
                icon: 'people'
            }
        }
    },
    {
        path: 'user/:agreement_id',
        component: UserComponent,
        canDeactivate: [unsavedChangesGuard],
        data: {
            settings: {
                title: 'User'
            }
        }
    },
    {
        path: 'thirdparties',
        component: ThirdpartyComponent,
        data: {
            settings: {
                index: 90,
                header: 'settings',
                content: 'External Integrations',
                title: 'External Integrations',
                icon: 'extension',
                permission: { '/account': ['get', 'patch'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'thirdparty/:thirdparty',
        component: ThirdpartyShowComponent,
        data: {
            settings: {
                title: 'thirdparty'
            }
        }
    },
    {
        path: 'loyaltykey-application',
        component: LoyaltyKeyApplicationComponent,
        canDeactivate: [unsavedChangesGuard],
        data: {
            settings: {
                title: 'thirdparty'
            }
        }
    },
    {
        path: 'reseller',
        component: ResellerComponent,
        canDeactivate: [unsavedChangesGuard],
        data: {
            settings: {
                index: 10,
                header: 'settings',
                content: 'Reseller',
                title: 'Reseller',
                icon: 'vpn_key',
                permission: { '/account': ['get'] },
                roles: ['Reseller']
            }
        }
    },
    {
        path: 'merchant',
        component: SettingsMerchantComponent,
        canDeactivate: [unsavedChangesGuard],
        data: {
            settings: {
                index: 10,
                header: 'settings',
                content: 'Merchant',
                title: 'Merchant',
                permission: { '/account': ['get'] },
                icon: 'business',
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'events',
        component: SettingsMerchantEventsComponent,
        data: {
            settings: {
                title: 'Merchant',
                permission: { '/account': ['get'] }
            }
        }
    },
    {
        path: 'kms',
        loadComponent: () => import('app/components/settings/kms/kms.component').then(c => c.KmsComponent),
        data: {
            settings: {
                index: 20,
                header: 'settings',
                content: 'KMS',
                title: 'KMS',
                permission: { '/cryptography/keys': ['get'] },
                roles: [],
                icon: 'style'
            }
        }
    },
    {
        path: 'kms-key/:key_id',
        loadComponent: () => import('app/components/settings/kms/kms-edit/kms-edit.component').then(c => c.KmsEditComponent),
        data: {
            settings: {
                title: 'KMS'
            }
        }
    },
    {
        path: 'integration',
        component: IntegrationComponent,
        data: {
            settings: {
                index: 90,
                header: 'settings',
                content: 'Integration',
                title: 'Integration',
                permission: { '/agreements': ['get'] },
                icon: 'vpn_key',
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'fraudfilter',
        component: FraudfilterComponent,
        data: {
            settings: {
                index: 90,
                header: 'settings',
                content: 'Fraud filter',
                title: 'Fraud filter',
                permission: { '/fraud-ruleset': ['get'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'fraud-ruleset/edit/:id',
        component: RulesetComponent,
        data: {
            settings: {
                permission: { '/fraud-ruleset': ['post'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'fraud-ruleset/add',
        component: RulesetComponent,
        data: {
            settings: {
                permission: { '/fraud-ruleset': ['post'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'brandings',
        component: BrandingsComponent,
        data: {
            settings: {
                index: 20,
                icon: 'style',
                header: 'settings',
                content: 'Brandings',
                title: 'Brandings',
                permission: { '/brandings': ['get'] },
                roles: ['Merchant', 'Reseller']
            }
        }
    },
    {
        path: 'branding-edit',
        component: BrandingEditComponent,
        data: {
            settings: {
                title: 'Branding',
                permission: { '/brandings/:id': ['get'] }
            }
        }
    },
    {
        path: 'branding-default-config',
        component: BrandingConfigComponent,
        canDeactivate: [unsavedChangesGuard],
        data: {
            settings: {
                title: 'Branding config',
                permission: { '/account': ['get', 'patch'] },
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'branding/:branding_id',
        component: BrandingComponent,
        data: {
            settings: {
                title: 'Branding',
                permission: { '/brandings/:id': ['get'] }
            }
        }
    },
    {
        path: 'billing',
        component: BillingComponent,
        data: {
            settings: {
                allowSuspended: true,
                content: 'Billing',
                header: 'settings',
                icon: 'business',
                index: 50,
                onlyQpReseller: true,
                owner: true,
                permission: { '/account': ['get'] },
                roles: ['Merchant', 'Reseller'],
                title: 'Billing'
            }
        }
    },
    {
        path: 'account',
        component: AccountComponent,
        data: {
            settings: {
                index: 30,
                header: 'settings',
                content: 'Account',
                title: 'Account',
                icon: 'person',
                permission: { '/account': ['get'] },
                roles: ['Merchant', 'Reseller']
            }
        }
    },
    {
        path: 'invoice/:invoice_id',
        component: InvoiceComponent,
        data: {
            settings: {
                title: 'Account'
            }
        },
        children: [
        ]
    },
    {
        path: 'specifications/:merchant_specification_id/:merchant_id',
        component: MerchantSpecificationComponent,
        data: {
            settings: {
                title: 'Account'
            }
        }
    },
    {
        path: 'acquirer-fees',
        component: AcquirerFeeComponent,
        data: {
            settings: {
                title: 'Acquirer'
            }
        }
    },
    {
        path: 'acquirers',
        component: AcquirersComponent,
        data: {
            settings: {
                index: 50,
                header: 'settings',
                content: 'Acquirers',
                title: 'Acquirers',
                permission: { '/acquirers': ['get'] },
                icon: 'business',
                roles: ['Merchant']
            }
        }
    },
    {
        path: 'prioritize',
        component: PrioritizeComponent,
        data: {
            settings: {
                title: 'Acquirer'
            }
        }
    },
    {
        path: 'paypal-advance',
        loadComponent: () => import('app/components/settings/acquirers/show/advance/paypal.component').then(m => m.AdvancePaypalComponent),
        canDeactivate: [unsavedChangesGuard],
        data: {
            settings: {
                title: 'Acquirer'
            }
        }
    },
    {
        path: 'teller-advance',
        component: AdvanceTellerBasedComponent,
        data: {
            settings: {
                title: 'Acquirer'
            }
        }
    },
    {
        path: 'clearhaus-signup',
        component: ClearhausSignupComponent,
        data: {
            settings: {
                title: 'Acquirer'
            }
        }
    },
    {
        path: 'acquirer',
        component: AcquirerShowComponent,
        data: {
            settings: {
                title: 'Acquirer'
            }
        },
        children: [
            {
                path: '',
                redirectTo: 'clearhaus',
                pathMatch: 'full'
            },
            {
                path: 'anydaysplit',
                loadChildren: () => import('../components/settings/acquirers/acquirer/anyday/anyday.module').then(m => m.AnydayModule)
            },
            {
                path: 'applepay',
                loadChildren: () => import('../components/settings/acquirers/acquirer/applepay/applepay.module').then(m => m.ApplepayModule)
            },
            {
                path: 'clearhaus',
                loadChildren: () => import('../components/settings/acquirers/acquirer/clearhaus/clearhaus.module').then(m => m.ClearhausModule)
            },
            {
                path: 'visa-mastercard',
                loadChildren: () => import('../components/settings/acquirers/acquirer/visa-mastercard/visa-mastercard.module').then(m => m.VisaMastercardModule)
            },
            {
                path: 'googlepay',
                loadChildren: () => import('../components/settings/acquirers/acquirer/google-pay/google-pay.module').then(m => m.GooglePayModule)
            },
            {
                path: 'klarnapayments',
                loadChildren: () => import('../components/settings/acquirers/acquirer/klarna/klarna.module').then(m => m.KlarnaModule)
            },
            {
                path: 'mobilepayonline',
                loadChildren: () => import('../components/settings/acquirers/acquirer/mobilepay-online/mobilepay-online.module').then(m => m.MobilepayOnlineModule)
            },
            {
                path: 'mobilepaysubscriptions',
                loadComponent: () => import('app/components/settings/acquirers/acquirer/mobilepay-subscriptions/mobilepay-subscriptions.component').then(c => c.MobilepaySubscriptionsComponent),
                data: {
                    acquirer: ACQUIRERDATA['mobilepaysubscriptions']
                }
            },
            {
                path: 'paypal',
                loadChildren: () => import('../components/settings/acquirers/acquirer/paypal/paypal.module').then(m => m.PaypalModule)
            },
            {
                path: 'paypalpayments',
                loadChildren: () => import('../components/settings/acquirers/acquirer/paypalpayments/paypalpayments.module').then(m => m.PaypalpaymentsModule)
            },
            {
                path: 'paysafecard',
                loadChildren: () => import('../components/settings/acquirers/acquirer/paysafecard/paysafecard.module').then(m => m.PaysafecardModule)
            },
            {
                path: 'resurs',
                loadChildren: () => import('../components/settings/acquirers/acquirer/resursbank/resursbank.module').then(m => m.ResursbankModule)
            },
            {
                path: 'sofort',
                loadChildren: () => import('../components/settings/acquirers/acquirer/sofort/sofort.module').then(m => m.SofortModule)
            },
            {
                path: 'swish',
                loadChildren: () => import('../components/settings/acquirers/acquirer/swish/swish.module').then(m => m.SwishModule)
            },
            {
                path: 'trustly',
                loadChildren: () => import('../components/settings/acquirers/acquirer/trustly/trustly.module').then(m => m.TrustlyModule)
            },
            {
                path: 'unzer-direct-debit',
                canMatch: [unzerGuard],
                loadChildren: () => import('../components/settings/acquirers/acquirer/unzer-direct-debit/unzer-direct-debit.module').then(m => m.UnzerDirectDebitModule)
            },
            {
                path: 'unzer-invoice',
                canMatch: [unzerGuard],
                loadChildren: () => import('../components/settings/acquirers/acquirer/unzer-invoice/unzer-invoice.module').then(m => m.UnzerInvoiceModule)
            },
            {
                path: 'viabill',
                loadChildren: () => import('../components/settings/acquirers/acquirer/viabill/viabill.module').then(m => m.ViabillModule)
            },
            {
                path: 'vipps',
                loadChildren: () => import('../components/settings/acquirers/acquirer/vipps/vipps.module').then(m => m.VippsModule)
            },
            {
                path: 'vippspsp',
                loadChildren: () => import('../components/settings/acquirers/acquirer/vippspsp/vippspsp.module').then(m => m.VippspspModule)
            },
            {
                path: 'bambora',
                loadChildren: () => import('../components/settings/acquirers/acquirer/teller-based/teller-based.module').then(m => m.TellerBasedModule),
                data: {
                    acquirer: ACQUIRERDATA['bambora']
                }
            },
            {
                path: 'elavon',
                loadChildren: () => import('../components/settings/acquirers/acquirer/teller-based/teller-based.module').then(m => m.TellerBasedModule),
                data: {
                    acquirer: ACQUIRERDATA['elavon']
                }
            },
            {
                path: 'handelsbanken',
                loadChildren: () => import('../components/settings/acquirers/acquirer/teller-based/teller-based.module').then(m => m.TellerBasedModule),
                canActivate: [cardGuard],
                data: {
                    acquirer: ACQUIRERDATA['handelsbanken']
                }
            },
            {
                path: 'swedbank',
                loadChildren: () => import('../components/settings/acquirers/acquirer/teller-based/teller-based.module').then(m => m.TellerBasedModule),
                canActivate: [cardGuard],
                data: {
                    acquirer: ACQUIRERDATA['swedbank']
                }
            },
            {
                path: 'valitor',
                loadChildren: () => import('../components/settings/acquirers/acquirer/teller-based/teller-based.module').then(m => m.TellerBasedModule),
                canActivate: [cardGuard],
                data: {
                    acquirer: ACQUIRERDATA['valitor']
                }
            },
            {
                path: 'teller',
                loadChildren: () => import('../components/settings/acquirers/acquirer/teller-based/teller-based.module').then(m => m.TellerBasedModule),
                canActivate: [cardGuard],
                data: {
                    acquirer: ACQUIRERDATA['teller']
                }
            },
            {
                path: 'nets',
                loadChildren: () => import('../components/settings/acquirers/acquirer/nets/nets.module').then(m => m.NetsModule)
            },
            {
                path: 'pprobancontact',
                loadChildren: () => import('../components/settings/acquirers/acquirer/ppro/ppro.module').then(m => m.PproModule),
                data: {
                    acquirer: ACQUIRERDATA['pprobancontact']
                }
            },
            {
                path: 'pproblik',
                loadChildren: () => import('../components/settings/acquirers/acquirer/ppro/ppro.module').then(m => m.PproModule),
                data: {
                    acquirer: ACQUIRERDATA['pproblik']
                }
            },
            {
                path: 'pproprzelewy24',
                loadChildren: () => import('../components/settings/acquirers/acquirer/ppro/ppro.module').then(m => m.PproModule),
                data: {
                    acquirer: ACQUIRERDATA['pproprzelewy24']
                }
            },
            {
                path: 'paypal-advance',
                loadComponent: () => import('app/components/settings/acquirers/show/advance/paypal.component').then(c => c.AdvancePaypalComponent),
                canDeactivate: [unsavedChangesGuard]
            },
            {
                path: 'vmpepayment',
                loadComponent: () => import('app/components/settings/acquirers/acquirer/vippsmp/vippsmp.component').then(c => c.VippsmpComponent),
                data: {
                    acquirer: ACQUIRERDATA['vmpepayment']
                }
            },
            {
                path: 'pprowechatpay',
                loadChildren: () => import('../components/settings/acquirers/acquirer/ppro/ppro.module').then(m => m.PproModule),
                data: {
                    acquirer: ACQUIRERDATA['pprowechatpay']
                }
            },
            {
                path: 'pprosofort',
                loadChildren: () => import('../components/settings/acquirers/acquirer/ppro/ppro.module').then(m => m.PproModule),
                data: {
                    acquirer: ACQUIRERDATA['pprosofort']
                }
            }
        ]
    }
];
