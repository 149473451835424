<div class="wide-content" *ngIf="payout && !loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../../payouts']" [queryParams]="previousParams" class="mat-button-back" color="accent">&lt; <span i18n>Back to Payouts</span></button>
        <span class="flex-1"></span>
        <div class="flex-row cg8">
            <mat-spinner *ngIf="showSpinner" [diameter]="30"></mat-spinner>
            <button mat-raised-button (click)="onPrint()" class="hide-lt-sm" i18n>Print</button>
            <mat-menu #payoutMenu="matMenu">
                <button mat-menu-item [routerLink]="['../../tools/auditlog']" [queryParams]="{ target_type: 'Payout', target_id: payout.id }" i18n>Audit log</button>
                <button mat-menu-item (click)="showJSON()" i18n>json</button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="payoutMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <span></span>
        </div>
    </div>

    <div class="flex-row-mobile-col">
        <div class="flex-35-mobile-100" *ngIf="payout.state !== 'initial' || payout.acquirer">
            <mat-card class="card02" *ngIf="!payout.retented_at">
                <mat-card-content>
                    <ng-container *ngIf="payout.state !== 'initial' && payout.metadata.type === 'card'">
                        <qp-transaction-details-card-basic [disableFraud]="true" [transaction]="payout"></qp-transaction-details-card-basic>
                    </ng-container>
                    <ng-container *ngIf="payout.metadata.type !== 'card'">
                        <qp-transaction-details-other-basic [disableFraud]="true" [transaction]="payout"></qp-transaction-details-other-basic>
                    </ng-container>
                </mat-card-content>
            </mat-card>

            <qp-transaction-details-retended [transaction]="payout"></qp-transaction-details-retended>
            <qp-transaction-details-acquirer [transaction]="payout"></qp-transaction-details-acquirer>
        </div>
        <div class="flex-1">
            <mat-card class="card02 avoid-page-break">
                <mat-card-content class="flex-col rg16">
                    <div>
                        <h5 class="font-weight-normal" i18n>Payout created</h5>
                        <div class="flex-row space-between items-center print">
                            <div class="flex-col">
                                <span class="subtitle1 hide-lt-sm" i18n>Created at</span>
                                <span class="subtitle1 hide-gt-xs" i18n>Created</span>
                                <span class="hide-lt-sm">{{ payout.created_at | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                                <span class="hide-gt-xs">{{ payout.created_at | date:'dd/MM yyyy HH:mm' }}</span>
                            </div>

                            <div class="flex-col">
                                <span class="subtitle1 hide-lt-sm" i18n>Payout ID</span>
                                <span class="subtitle1 hide-gt-xs" i18n>Payo.</span>
                                <span>{{ payout.id }}</span>
                            </div>

                            <div class="flex-col">
                                <span class="subtitle1 hide-lt-sm">Order ID</span>
                                <span class="subtitle1 hide-gt-xs">Order</span>
                                <span>{{ payout.order_id }}</span>
                            </div>

                            <div class="flex-col">
                                <span class="subtitle1" i18n>Currency</span>
                                <span>{{ payout.currency }}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="payout.state !== 'initial' && payout.operations && payout?.operations!.length > 0">
                        <h5 class="font-weight-normal" i18n>Operations</h5>
                        <div class="avoid-page-break">
                            <qp-operations [currency]="payout.currency" [operations]="payout.operations" [transactionState]="payout.state" [acquirer]="payout.acquirer"> </qp-operations>
                        </div>
                    </div>

                    <div *ngIf="payout.state === 'initial' && payout.link">
                        <h5 class="font-weight-normal" i18n>Payout link</h5>
                        <div class="flex-col hide-lt-sm">
                            <span class="subtitle1" i18n>Amount</span>
                            <span>{{ payout.currency }}: {{ payout.link.amount | qpCurrency: payout.currency }}</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <qp-transaction-details-variables [transaction]="payout"></qp-transaction-details-variables>
        </div>
    </div>
</div>
