<mat-paginator
    (page)="onPageChange($event)"
    [pageSize]="config.page_size"
    [pageIndex]="0"
    [length]="paginator_length"
    [pageSizeOptions]="config.page_size_options"
    showFirstLastButtons
    class="removeLastButton"
>
</mat-paginator>
