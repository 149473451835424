import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DisputeRepo } from '@endpoints/server/dispute-repo';
import { ClearhausParams } from '@interfaces/params';
import { Clearhaus } from '@models/acquirers/acquirer';
import { Dispute } from '@models/clearhaus-dispute';
import { QpPaginatorConfig } from '@models/qp-pagintor';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { catchError, finalize, tap } from 'rxjs/operators';

@Component({
    selector: 'qp-disputes',
    templateUrl: 'disputes.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class DisputesComponent extends BaseLoadingDirective implements OnInit {
    public data_source = new MatTableDataSource<Dispute>();
    public page_params: ClearhausParams;
    public paginator_options: QpPaginatorConfig;
    public acquirer: Clearhaus;
    public state: string;
    public status: string;
    public is_loading_results = false;
    public error_loading = false;

    constructor(
        private acquirerService: AcquirerService,
        private disputeRepo: DisputeRepo
    ) {
        super();
    }

    public ngOnInit(): void {
        this.state = 'due';
        this.status = 'open';

        this.acquirer = this.acquirerService.acquirers.clearhaus;
        this.page_params = {
            page: 1,
            page_size: 10,
            ch_mid: this.acquirer.mpi_merchant_id || ''
        };
        this.setPaginatorData();

        if (this.acquirer.active) {
            this.loadDisputes();
        }
    }

    public setPaginatorData(): void {
        this.paginator_options = {
            page_size_options: [10, 50],
            page: this.page_params.page || 1,
            page_size: this.page_params.page_size || 10,
            label: $localize`Disputes per page`
        };
    }

    public loadDisputes(): void {
        this.is_loading_results = true;
        this.error_loading = false;
        this.data_source.data = [];

        const params = this.page_params;
        params['status'] = this.status;

        this.disputeRepo.getDisputes(params).pipe(
            catchError(error => {
                this.error_loading = true;
                throw error;
            }),
            tap(disputes => this.data_source.data = disputes),
            finalize(() => this.is_loading_results = false)
        ).subscribe();
    }

    public onPageChange(event: { page: number; page_size: number }): void {
        this.page_params.page = event.page;
        this.page_params.page_size = event.page_size;
        this.setPaginatorData();
        this.loadDisputes();
    }

    public changeTab(data: MatTabChangeEvent): void {
        this.page_params.page = 1;
        this.page_params.page_size = 10;

        if (data.tab.textLabel === 'Main') {
            this.page_params.ch_mid = this.acquirer.mpi_merchant_id || '';
        } else {
            this.page_params.ch_mid = this.acquirer.currency_specific_account[data.tab.textLabel].mpi_merchant_id || '';
        }

        this.setPaginatorData();
        this.loadDisputes();
    }

    public changeState(data: MatTabChangeEvent): void {
        switch (data.index) {
            case 0: //open tap
                this.state = 'due';
                this.status = 'open';
                break;
            case 1: //refuted tap
                this.state = 'expires';
                this.status = 'refuted';
                break;
            case 2: // Under review tap
                this.state = '';
                this.status = 'under_review';
                break;
            case 3: //closed tap
                this.state = 'outcome';
                this.status = 'closed';
                break;
        }

        this.setPaginatorData();
        this.loadDisputes();
    }
}
