import { Component, Inject } from '@angular/core';
import { CardToken } from '@models/card-token';
import { Operation } from '@models/operation';
import { Payment } from '@models/payment';
import { Transaction } from '@models/transaction';
import { CardRepo } from 'app/endpoints/api/card-repo';
import { PaymentRepo } from 'app/endpoints/api/payment-repo';
import { TransactionService } from '@services/transaction/transaction.service';
import { EMPTY, exhaustMap, expand, finalize, timer } from 'rxjs';
import { Router } from '@angular/router';
import { AgreementService } from '@services/agreement/agreement.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-card-payment-dialog-component',
    templateUrl: 'card-create-payment.html'
})
export class CardPaymentDialogComponent {
    public transaction: Transaction;
    public payment: any = {
        currency: '',
        order_id: undefined
    };
    public auth: any = {
        acquirer: undefined,
        amount: 0,
        autocapture: undefined,
        autofee: undefined,
        card: undefined
    };
    public creating_payment = false;

    public get last_operation(): Operation | null {
        return this.transactionService.lastOperation(this.transaction);
    }

    constructor(
        private router: Router,
        private transactionService: TransactionService,
        private cardRepo: CardRepo,
        private paymentRepo: PaymentRepo,
        private agreementService: AgreementService,
        public dialogRef: MatDialogRef<CardPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public goToPayment(): void {
        this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'payment', this.transaction.id]);
        this.dialogRef.close(true);
    }

    public createPayment(): void {
        this.creating_payment = true;
        this.auth.acquirer = this.data.card.acquirer;
        this.cardRepo.tokens(this.data.card.id).pipe(
            exhaustMap((token: CardToken) => {
                this.auth.card = { token: token.token };
                return this.paymentRepo.create(this.payment).pipe(
                    exhaustMap((new_payment: Payment) =>
                        this.paymentRepo.authorize(new_payment.id ?? NaN, this.auth).pipe(
                            expand((payment: Payment) => {
                                if (!this.transactionService.lastOperation(payment)?.pending) {
                                    return EMPTY;
                                }

                                return timer(2000).pipe(
                                    exhaustMap(_ => this.paymentRepo.get<Payment>(payment.id ?? NaN))
                                );
                            }),
                            finalize(() => this.creating_payment = false)
                        )
                    )
                );
            })
        ).subscribe((transaction: Transaction) => {
            this.transaction = transaction;
        });
    }

    public generateOrderId(): void {
        this.payment.order_id = Math.floor(Math.random() * 100000000000 + 1);
    }
}
