import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { ThemingService } from '@services/theming/theming.service';

// DO NOT REMOVE FOLLOWING COMMENT LINE!!! https://angular.io/guide/aot-compiler#strictmetadataemit
// @dynamic
@Injectable()
export class AppLoaderService {
    constructor(
        private themingService: ThemingService,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public initializeApp(): Promise<any> {
        return new Promise<any>((resolve: any, _reject: any) => {
            this.titleService.setTitle(this.themingService.brand_name + ' Manager');

            if (this.themingService.name) {
                const targetLinks: { [key: string]: any } = [
                    {
                        rel: 'stylesheet',
                        href: `${this.themingService.name}-theme.css`
                    },
                    {
                        rel: 'icon',
                        type: 'image/x-icon',
                        href: `assets/favicons/${this.themingService.name}/favicon.ico`
                    },
                    {
                        rel: 'shortcut icon',
                        href: `assets/favicons/${this.themingService.name}/favicon.ico`
                    },
                    {
                        rel: 'mask-icon',
                        href: `assets/favicons/${this.themingService.name}/safariicon.svg`,
                        color: `${this.themingService.primaryColorPalette.filter((item) => item.name === '500')}`
                    },
                    {
                        rel: 'apple-touch-icon',
                        type: 'image/png',
                        href: `assets/favicons/${this.themingService.name}/mobile/icon16.png`,
                        sizes: '16x16'
                    },
                    {
                        rel: 'apple-touch-icon',
                        type: 'image/png',
                        href: `assets/favicons/${this.themingService.name}/mobile/icon32.png`,
                        sizes: '32x32'
                    },
                    {
                        rel: 'apple-touch-icon',
                        type: 'image/png',
                        href: `assets/favicons/${this.themingService.name}/mobile/icon192.png`,
                        sizes: '192x192'
                    },
                    {
                        rel: 'apple-touch-icon',
                        type: 'image/png',
                        href: `assets/favicons/${this.themingService.name}/mobile/icon256.png`,
                        sizes: '256x256'
                    }
                ];

                if (this.themingService.name === 'quickpay') {
                    targetLinks.shift();
                }

                targetLinks.forEach((target: any) => {
                    const link = this.document.createElement('link');
                    Object.keys(target).forEach(key => {
                        link.setAttribute(key, target[key]);
                    });
                    this.document.head.appendChild(link);
                });
            }

            resolve();
        });
    }
}
