import { Balance } from '@models/balance';
import { Injectable } from '@angular/core';
import { InvoiceClient } from './invoice-client';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BalanceRepo {
    constructor(private client: InvoiceClient) {}

    public get(params: any): Observable<Balance> {
        return this.client.get<Balance>('/balance', { params }).pipe(
            map((item: any) => Object.assign(new Balance(), item))
        );
    }
}
