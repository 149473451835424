<div class="subtitle1" i18n>Callback status</div>
<div class="mat-body ff-mono-regular" [class]="text_style" i18n-title title="Http Status code {{operation?.callback_response_code}}" *ngIf="this.operation?.callback_response_code || this.operation?.callback_success !== null">
    <ng-container *ngIf="operation?.callback_url === null && this.operation?.callback_success === false">
        <span i18n>The callbackurl defined in Settings</span> &gt; <span i18n>Integration fails. Please check whether it is valid</span>
    </ng-container>

    <div [ngSwitch]="this.operation?.callback_success">
        <div *ngSwitchCase="true" class="mat-body">
        </div>
        <div *ngSwitchCase="false" class="white-space-normal mat-body" i18n> Failed - The callback service will try to deliver its message regularly for the next 24 hours.<br /> </div>
        <div *ngSwitchCase="null">
            <div *ngIf="this.operation?.callback_url !== null" class="mat-body" i18n>Pending<br /></div>
        </div>
    </div>

    <div *ngIf="this.operation?.callback_response_code" class="mat-body" [matTooltip]="'HTTP Status code:' + this.operation?.callback_response_code" i18n>
        HTTP Status code: {{ this.operation?.callback_response_code }}
    </div>

    <div *ngIf="!this.operation?.callback_response_code && this.operation?.callback_success === false" class="mat-body">
        <div i18n-matTooltip matTooltip="No HTTP status code. Could be because of timeout or network problems. Make sure you are using port 80 or 443" i18n>
            No HTTP status code. Could be because of timeout or network problems. Make sure you are using port 80 or 443
        </div>
    </div>
</div>

<div *ngIf="this.operation?.callback_url" [matTooltip]="url" class="truncate truncate-200 ff-mono-regular">URL: {{ url }}</div>

<div class="ff-mono-regular" *ngIf="this.operation?.callback_at && this.operation?.callback_duration">
    <span i18n>At:</span>&nbsp;
    <span matTooltip="{{ this.operation?.callback_at | date: 'dd-MM-yyyy HH:mm:ss' }}" matTooltipPosition="left"> {{ this.operation?.callback_at | date: 'MMM dd, yyyy' }} </span>
    <div i18n>
        Duration: {{ duration }} sec
    </div>
</div>
<div class="ff-mono-regular" *ngIf="!this.operation?.callback_response_code && !this.operation?.callback_success">N/A</div>
