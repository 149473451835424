<div class="flex-col" *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <mat-card class="card02">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Brandings" icon="satellite" i18n-headingText></qp-section-heading>
            <mat-card-subtitle class="text-grey" i18n> Change the look and feel of the payment window. </mat-card-subtitle>
        </div>
        <mat-card-content class="flex-col rg16 margin negative h16">
            <mat-nav-list class="flex-col">
                <mat-list-item class="multiline-list-item list1 padding b8" *ngFor="let branding of data_source.data; let last = last" [ngClass]="{ 'bottom-border': !last }" [disableRipple]="disableRipple" (mouseenter)="hoverEnter(branding.id)" (mouseleave)="hoverLeave()">
                    <a matListItemLine [routerLink]="['../branding', branding.id]">
                        <span>{{ branding.name }}</span><br />
                        <span class="text-grey small-text"> Id {{ branding.id }} - {{ branding.created_at | date }} </span>
                    </a>
                    <qp-preview-branding matListItemMeta *ngIf="!(['Reseller'] | qpAccountType) && hoverIndex === branding.id" (click)="$event.stopPropagation()" [brandingId]="branding.id" (mouseenter)="hoverEnterPreview()" (mouseleave)="hoverLeavePreview()"> </qp-preview-branding>
                </mat-list-item>
            </mat-nav-list>
        </mat-card-content>
        <div>
            <button mat-raised-button (click)="createBranding()">
                <mat-icon>add</mat-icon>
                <span i18n>CREATE BRANDING</span>
            </button>
        </div>
        <qp-paginator
            *ngIf="display_paginator"
            [config]="paginator_options"
            [data_length]="data_source.data.length"
            (pageChange)="onPageChange($event)">
        </qp-paginator>
    </mat-card>

    <mat-card class="flex-col rg32 card02">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Branding settings" icon="satellite" i18n-headingText></qp-section-heading>
            <mat-card-subtitle class="text-grey" i18n>Select the branding used if no branding is specified on the transaction.</mat-card-subtitle>

            <mat-form-field class="flex-1" *ngIf="data_source.data.length">
                <mat-label i18n>Default branding</mat-label>
                <mat-select name="default_branding_id" [(ngModel)]="defaultBrandingId" (selectionChange)="makeBrandingDefault()">
                    <mat-option *ngFor="let branding of data_source.data" value="{{ branding.id }}"> {{ branding.name }} </mat-option>
                    <mat-option value="0" i18n> Standard branding </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex-col rg8" *ngIf="(['Merchant'] | qpAccountType) && (['/account', 'get'] | qpPermission) && (['/account', 'put'] | qpPermission)">
            <qp-section-heading headingText="Default branding configs" icon="tune" i18n-headingText></qp-section-heading>
            <mat-card-subtitle class="text-grey margin b16" i18n>Change the look and feel of the payment window.</mat-card-subtitle>
            <div>
                <button mat-raised-button [routerLink]="['../branding-default-config']" i18n>Edit branding configs</button>
            </div>
        </div>
    </mat-card>
</div>
