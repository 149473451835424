<form name="prompt" #f="ngForm">
    <h5 mat-dialog-title *ngIf="data.headline">{{ data.headline }}</h5>
    <mat-dialog-content class="flex-col rg16 grey-border-bottom">
        <span *ngIf="data.body_text" [innerHTML]="data.body_text"></span>
        <div class="flex-row flex-1" *ngFor="let field of data.options">
            <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="'toggle'">
                    <ng-container *ngTemplateOutlet="toggleField;context:{ $implicit: field }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                    <ng-container *ngTemplateOutlet="textField;context:{ $implicit: field }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'email'">
                    <ng-container *ngTemplateOutlet="emailField;context:{ $implicit: field }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'url'">
                    <ng-container *ngTemplateOutlet="urlField;context:{ $implicit: field }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'select'">
                    <ng-container *ngTemplateOutlet="selectField;context:{ $implicit: field }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'radiogroup'">
                    <ng-container *ngTemplateOutlet="radioGroupField;context:{ $implicit: field }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'textarea'">
                    <ng-container *ngTemplateOutlet="textareaField;context:{ $implicit: field }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="textField;context:{ $implicit: field }"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-row">
        <span class="flex-1"></span>
        <button mat-button color="accent" type="button" (click)="dialog_ref.close()" i18n>
            Close
        </button>
        <button mat-button color="accent" type="submit" (click)="dialog_ref.close(data.options)" [disabled]="f.invalid">
            {{ data.result_button_text }}
        </button>
    </mat-dialog-actions>

    <ng-template #toggleField let-field>
        <mat-slide-toggle [(ngModel)]="field.value" [checked]="field.value" color="accent" [name]="field.name">
            {{ field.label }}
        </mat-slide-toggle>
    </ng-template>

    <ng-template #selectField let-field>
        <mat-form-field class="flex-1">
            <mat-label>{{ field.label }}</mat-label>
            <mat-select [(ngModel)]="field.value" [name]="field.name" [required]="field.required">
                <div *ngIf="field.groups[0].name">
                    <mat-optgroup *ngFor="let group of field.groups" [label]="group.name">
                        <mat-option *ngFor="let choice of group.choices" [value]="choice.value">
                            {{ choice.text }}
                        </mat-option>
                    </mat-optgroup>
                </div>
                <div *ngIf="!field.groups[0].name">
                    <mat-option *ngFor="let choice of field.groups" [value]="choice.value">
                        {{ choice.text }}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </ng-template>

    <ng-template #textField let-field>
        <mat-form-field class="flex-1">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput [(ngModel)]="field.value" [type]="field.type" [required]="field.required"
                [placeholder]="field.placeholder" [value]="field.value" [name]="field.name" [pattern]="field.pattern" />
            <mat-hint *ngIf="field.helpText"> {{ field.helpText }} </mat-hint>
        </mat-form-field>
    </ng-template>

    <ng-template #emailField let-field>
        <mat-form-field class="flex-1">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput [(ngModel)]="field.value" type="email" [required]="field.required"
                [placeholder]="field.placeholder" [value]="field.value" [name]="field.name" [email]="true" />
            <mat-hint *ngIf="field.helpText"> {{ field.helpText }} </mat-hint>
        </mat-form-field>
    </ng-template>

    <ng-template #urlField let-field>
        <mat-form-field class="flex-1">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput [(ngModel)]="field.value" [type]="field.type" [required]="field.required"
                [placeholder]="field.placeholder" [value]="field.value" [name]="field.name" />
            <mat-hint *ngIf="field.helpText"> {{ field.helpText }} </mat-hint>
        </mat-form-field>
    </ng-template>

    <ng-template #textareaField let-field>
        <mat-form-field class="flex-1">
            <mat-label>{{ field.label }}</mat-label>
            <textarea matInput [(ngModel)]="field.value" [type]="field.type" [required]="field.required"
                [placeholder]="field.placeholder" [value]="field.value" [name]="field.name" [rows]="field.rows"
                [cols]="field.cols">
            </textarea>
            <mat-hint *ngIf="field.helpText"> {{ field.helpText }} </mat-hint>
        </mat-form-field>
    </ng-template>

    <ng-template #radioGroupField let-field>
        <div class="flex-col rg8 flex-1">
            <span>{{ field.label }}</span>
            <div>
                <span class="max-width-16px"></span>
                <mat-radio-group class="flex-col" [(ngModel)]="field.value" [required]="field.required" [name]="field.name">
                    <mat-radio-button [checked]="choice.default" *ngFor="let choice of field.choices;" [value]="choice.value">
                        {{ choice.text }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <span></span>
        </div>
    </ng-template>
</form>
