import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import { ALLOW_EXTENSIONS } from './allow-extensions.constants';
import { MIME_TYPES } from './mime-types.constants';
import { Buffer } from 'buffer';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    public extractZip(file: any): Promise<JSZip> {
        const zip: JSZip = new JSZip();
        return zip.loadAsync(file);
    }

    public b64toBlob(b64Data: any, contentType: string, sliceSize: number): Blob {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        window.Buffer = Buffer;
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    public getMimeType(extension: string): string {
        return MIME_TYPES[extension] || '';
    }

    public isExtensionAllowed(extension: string): boolean {
        if (extension.split('/').length >= 2) {
            const arr = extension.split('/');
            extension = arr[arr.length - 1];
        }
        return ALLOW_EXTENSIONS.indexOf(extension) !== -1;
    }

    public isZip(file: File): boolean {
        return file.name.split('.').pop()?.toLowerCase() === 'zip';
    }
}
