<form class="flex-col rg8">
    <span *ngIf="!websites.length" class="text-grey" i18n>Please add a website to continue.</span>
    <span class="text-grey" i18n>Clearhaus will need to access your website, to be able to review your application. If the website isn't active yet, please provide a login to a test website, in the website comments.</span>

    <span class="text-grey" i18n>Please make sure all website(s) respect the Clearhaus acquirer rules:</span>

    <ul class="text-grey">
        <li i18n>The customer can easily find clear pricing of every product/service</li>
        <li i18n>The customer can easily find contact information and business location</li>
        <li i18n>The customer can easily find customer service e-mail and phone number</li>
        <li i18n>The customer can easily find “Terms and conditions”</li>
        <li i18n>The customer can easily find refund and shipping conditions</li>
        <li i18n>The customer can easily find a privacy policy</li>
        <li i18n>The Visa and Mastercard logos are visible on your payment page</li>
    </ul>

    <br>

    <div *ngFor="let website of websites; let i = index">
        <div class="flex-row space-between items-center">
            <span>{{ website.url }}</span>
            <div class="flex-row cg8">
                <button mat-raised-button (click)="addMissingComment(i)" *ngIf="!website.comment" i18n>
                    Add comment
                </button>
                <button mat-raised-button (click)="removeSite(i)" class="text-darkred" i18n>
                    Remove
                </button>
            </div>
        </div>
    </div>

    <div class="flex-row justify-start">
        <button mat-raised-button color="accent" (click)="addSiteDialog()" i18n>+ Add site</button>
    </div>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="!this.websites.length" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
