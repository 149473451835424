import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Base64 {
    public keyStr: string = 'ABCDEFGHIJKLMNOP' + 'QRSTUVWXYZabcdef' + 'ghijklmnopqrstuv' + 'wxyz0123456789+/' + '=';

    public encode(input: string): string {
        let output = '';
        let enc1: any;
        let enc2: any;
        let enc3: any;
        let enc4: any = '';
        let chr1: any;
        let chr2: any;
        let chr3: any = '';
        let i = 0;

        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            /* eslint-disable no-bitwise */
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            /* eslint-enable no-bitwise */

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output + this.keyStr.charAt(enc1) + this.keyStr.charAt(enc2) + this.keyStr.charAt(enc3) + this.keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = '';
            enc1 = enc2 = enc3 = enc4 = '';
        } while (i < input.length);
        return output;
    }

    public encodeUnicode(str: string): string {
        str = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match: any, p1: number) => String.fromCharCode(Number('0x' + p1)));
        return this.encode(str);
    }
}
