import { Component, Input } from '@angular/core';

@Component({
    selector: 'qp-date-field',
    templateUrl: './datefield.html'
})
export class DatefieldComponent {
    @Input() public date: string | Date | undefined;
    @Input() public format = 'MMM dd, yyyy';
    @Input() public tooltipFormat = 'dd-MM-yyyy HH:mm:ss';
}
