import { Injectable } from '@angular/core';

import { FraudReport } from '@models/fraud-report';
import { Observable } from 'rxjs';

@Injectable()
export abstract class BaseRepo {
    constructor() {}

    public abstract get<T>(id: number): Observable<T>;
    public abstract createFraudReport(id: number | undefined, params: FraudReport): any;
    public abstract getAll(params: any): any;
    public abstract update(item: any): any;
}
