import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../services/currency/currency.service';

@Pipe({ name: 'qpCurrency' })
export class QpCurrencyPipe implements PipeTransform {
    constructor(private currencyService: CurrencyService) {}

    public transform(value?: number | string, currencyCode?: string, mode = 'localized'): string | number {
        return this.currencyService.toCurrency(value, currencyCode, mode);
    }
}
