import { Component, OnInit } from '@angular/core';
import { Clearhaus } from '@models/acquirers/acquirer';
import { ClearhausContract } from '@models/clearhaus-application/ch-contract';
import { Theme } from '@interfaces/theme';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { DownloaderService } from '@services/downloader/downloader.service';
import { AcquirerRepo } from 'app/endpoints/api/acquirer-repo';
import { ClearhausRepo } from 'app/endpoints/server/clearhaus-repo';
import { CountryService } from '@services/country/country.service';
import { HomepageService } from '@services/homepage/homepage.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { SessionService } from '@services/session/session.service';
import { ThemingService } from '@services/theming/theming.service';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';

@Component({
    selector: 'qp-clearhaus-signup',
    templateUrl: 'signup.html'
})
export class ClearhausSignupComponent implements OnInit {
    public contract: ClearhausContract;
    public contractSigned = false;
    public apiKey = false;
    public displayContract = false;
    public displayInstant = false;
    public notAllowedToSign = false;
    public owner = false;
    public showForm = false;
    public theme: Theme = this.themingService.theme;
    public whitelistedInstantSignup: Array<string>;
    public clearhausTermsLink: string;
    public signing = false;
    public displayHintBox = false;
    public helpdeskLink: string;

    constructor(
        private acquirerRepo: AcquirerRepo,
        private acquirerService: AcquirerService,
        private clearhausRepo: ClearhausRepo,
        private countryService: CountryService,
        private downloadService: DownloaderService,
        private sessionService: SessionService,
        private snackBar: QpSnackBar,
        private homepageService: HomepageService,
        private themingService: ThemingService,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        this.setWhitelistedCountries();
        this.owner = this.agreementService.isOwner();
        this.helpdeskLink = this.homepageService.getHelpdeskLink('disputes/');

        if (
            this.agreementService.agreement?.account?.customer_address?.country_code &&
            (this.whitelistedInstantSignup.includes(this.agreementService.agreement.account?.customer_address?.country_code) ||
                new QpMerchantTypePipe(this.agreementService).transform(['Unzer']))
        ) {
            this.displayInstant = true;
        }

        if (this.displayInstant) {
            this.clearhausRepo.getContract().pipe(
                map((contract: ClearhausContract) => {
                    if (contract.metadata.state === 'signed') {
                        this.contractSigned = true;
                        if (!!this.acquirerService.acquirers?.clearhaus.api_key) {
                            this.apiKey = true;
                        }
                    } else {
                        this.displayContract = true;
                        this.contract = contract;

                        if (!!contract.signer) {
                            if (
                                this.agreementService.agreement?.account?.contact_email === contract.signer.email ||
                                this.sessionService.me?.email === contract.signer.email
                            ) {
                                this.notAllowedToSign = false;
                            }
                        }
                    }
                    return EMPTY;
                }),
                catchError(() => {
                    this.showForm = true;
                    return EMPTY;
                })
            ).subscribe();
        } else {
            this.clearhausRepo.getApplication().subscribe({
                error: () => {
                    this.showForm = true;
                }
            });
        }

        this.setClearhausTermsLink();
    }

    public setWhitelistedCountries(): void {
        this.whitelistedInstantSignup = [];
        this.whitelistedInstantSignup.push('IMN');
        this.countryService.getEU28Countries().forEach(country => {
            if (country.code !== 'GBR') {
                this.whitelistedInstantSignup.push(country.code);
            }
        });
    }

    public setClearhausTermsLink(): void {
        if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
            this.clearhausTermsLink = 'https://www.clearhaus.com/de/bedingungen/';
        } else {
            this.clearhausTermsLink = 'https://www.clearhaus.com/terms/';
        }
    }

    public getPDF(): void {
        const observable = this.clearhausRepo.getContractPdf(
            this.agreementService.agreement?.account?.acquirer_settings.clearhaus.mpi_merchant_id || ''
        );
        observable.subscribe(file => {
            this.downloadService.saveFile(file, 'contract.pdf');
        });
    }

    public signContract(): void {
        this.signing = true;
        const acquirer = this.acquirerService.acquirers?.clearhaus;
        this.clearhausRepo.signContract().pipe(
            exhaustMap((contract: ClearhausContract) => {
                if (!acquirer) {
                    return EMPTY;
                }
                acquirer.active = true;
                acquirer.api_key = contract.api_key.token;
                return this.acquirerRepo.update<Clearhaus>('clearhaus', acquirer).pipe(
                    map(updatedAcquirer => {
                        if (!this.acquirerService.acquirers) {
                            return EMPTY;
                        }
                        this.acquirerService.acquirers.clearhaus.active = true;
                        this.acquirerService.acquirers.clearhaus = updatedAcquirer;
                        return EMPTY;
                    })
                );
            }),
            catchError(() => {
                this.snackBar.open($localize`Contract signed`);
                return of();
            })
        ).subscribe(() => {
            this.displayContract = false;
            this.contractSigned = true;
            if (!!acquirer?.api_key) {
                this.apiKey = true;
            }
            this.signing = false;
            this.snackBar.open($localize`Contract signed`);
        });
    }
}
