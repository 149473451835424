import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'qpHumanizeBool'
})
export class QPHumanizeBoolPipe implements PipeTransform {

    public transform(value: boolean): unknown {
        return !!value ? $localize`Yes` : $localize`No`;
    }
}
