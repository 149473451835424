import { Component, OnInit } from '@angular/core';
import { Account } from '@interfaces/account';
import { Address } from '@interfaces/address';
import { AgreementService } from '@services/agreement/agreement.service';
import { Animations } from 'app/animations/animations';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { DeleteAccountDialogComponent } from './delete-account-dialog/delete-account-dialog.component';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { Router } from '@angular/router';

@Component({
    selector: 'qp-account',
    templateUrl: 'account.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class AccountComponent extends BaseLoadingDirective implements OnInit {
    public account: Account;
    public hide_billing = true;
    public hide_billing_card: boolean;
    public address_readonly: boolean;

    constructor(
        private acquirerService: AcquirerService,
        private router: Router,
        private accountRepo: AccountRepo,
        private dialogService: DialogService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.loadAccount();
    }

    public loadAccount(): void {
        if (this.agreementService.agreement) {
            this.account = Object.assign({}, this.agreementService.agreement.account);

            if (!this.account.customer_address) {
                this.account.customer_address = {
                    name: '',
                    street: '',
                    zip_code: '',
                    city: '',
                    country_code: '',
                    vat_no: ''
                } as Address;
            }

            if (this.account.billing_address) {
                Object.keys(this.account.billing_address).forEach(key => {
                    if (
                        this.account.customer_address &&
                        this.account.billing_address &&
                        this.account.billing_address[key as keyof Address] &&
                        this.account.billing_address[key as keyof Address] !== this.account?.customer_address[key as keyof Address]
                    ) {
                        this.hide_billing = false;
                    }
                });
            }
        }
    }

    public hideBillingAddressCard(): void {
        if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
            if (this.hide_billing) {
                this.hide_billing_card = true;
            }
        }
    }

    public addressFieldsReadonly(): void {
        if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
            if (this.acquirerService.acquirers?.clearhaus.api_key) {
                this.address_readonly = true;
            }
        }
    }

    public deleteAccount(): void {
        this.dialogService.component(DeleteAccountDialogComponent).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() =>
                this.accountRepo.destroy()
            ),
            tap(() => {
                this.router.navigate(['/my-user']);
                this.snackBar.open($localize`Account deleted`);
            })
        ).subscribe();
    }

    public update(account: Partial<Account>, displaySnackBar = false): void {
        this.accountRepo.update(account).subscribe((returnAccount: Account) => {
            this.account = returnAccount;
            if (this.agreementService.agreement) {
                this.agreementService.agreement.account = returnAccount;
            }

            if (displaySnackBar) {
                this.snackBar.open($localize`Account updated`);
            }
        });
    }

    public setBillingAsCustomer(): void {
        if (this.hide_billing) {
            this.update({ billing_address: undefined }, true);
        }
    }
}
