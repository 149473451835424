import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-confirm-dialog',
    templateUrl: 'confirm.html'
})
export class ConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            headline: string;
            msg: string;
            confirmButtonText: string;
            cancelButtonText: string;
        }
    ) {}
}
