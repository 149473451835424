import { Component, Inject } from '@angular/core';
import { PromptField } from './options.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-prompt-dialog',
    templateUrl: 'prompt.html'
})
export class PromptDialogComponent {
    constructor(
        public dialog_ref: MatDialogRef<PromptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            headline: string;
            body_text: string;
            result_button_text: string;
            options: PromptField[];
        }
    ) {}
}
