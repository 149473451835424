<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null" class="flex-col rg8">
    <div>
        <button mat-button [routerLink]="['../../brandings']" class="mat-button-back" color="accent">&lt; <span i18n>Back to Brandings</span></button>
    </div>

    <mat-card class="card02">
        <mat-card-title class="flex-row justify-start items-center">
            <span>{{ branding.name }}</span>
            <span class="flex-1"></span>
            <button mat-icon-button [matMenuTriggerFor]="brandingMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-card-title>

        <mat-menu mat-card-image xPosition="before" yPosition="below" #brandingMenu="matMenu">
            <button mat-menu-item (click)="renameBranding()">
                <mat-icon>create</mat-icon>
                <span i18n>Rename</span>
            </button>

            <qp-preview-branding *ngIf="!(['Reseller'] | qpAccountType)" (click)="$event.stopPropagation()" [brandingId]="branding.id ?? -1" [isButton]="false" menuLayout="true"> </qp-preview-branding>

            <button mat-menu-item (click)="fileInput.click()">
                <mat-icon>file_upload</mat-icon>
                <span i18n>Upload new file</span>
            </button>
            <button mat-menu-item (click)="downloadBranding()">
                <mat-icon>file_download</mat-icon>
                <span i18n>Download</span>
            </button>
            <button mat-menu-item (click)="deleteBranding()">
                <mat-icon>delete</mat-icon>
                <span i18n>Delete branding</span>
            </button>
        </mat-menu>

        <mat-card-content>
            <mat-list mat-card-image class="width-100p" *ngIf="branding_resource.length > 1">
                <mat-list-item class="bottom-border">
                    <span>{{ branding_resource[0].name }}</span>
                    <div matListItemMeta *ngTemplateOutlet="actionsField; context: { $implicit: branding_resource[0] }"></div>
                </mat-list-item>
                <mat-list-item class="bottom-border">
                    <span>{{ branding_resource[1].name }}</span>
                    <div matListItemMeta *ngTemplateOutlet="actionsField; context: { $implicit: branding_resource[1] }"></div>
                </mat-list-item>

                <mat-list-item class="bottom-border">
                    <span class="subtitle1">Code Files</span>
                    <div matListItemMeta>
                        <button mat-icon-button *ngIf="!show_code_files" (click)="show_code_files = !show_code_files">
                            <mat-icon class="text-grey">expand_more</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="show_code_files" (click)="show_code_files = !show_code_files">
                            <mat-icon class="text-grey">expand_less</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
                <div [@detail_expand]="show_code_files ? 'expanded' : 'collapsed'">
                    <mat-list-item class="bottom-border" *ngFor="let resource of getBrandingResource('code');">
                            <span>{{ resource.name }}</span>
                            <div matListItemMeta>
                                <div matListItemMeta *ngTemplateOutlet="actionsField; context: { $implicit: resource }"></div>
                            </div>
                    </mat-list-item>
                </div>

                <mat-list-item class="bottom-border">
                    <span class="subtitle1">Translation Files</span>
                    <div matListItemMeta>
                        <button mat-icon-button *ngIf="!show_translations_files" (click)="show_translations_files = !show_translations_files">
                            <mat-icon class="text-grey">expand_more</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="show_translations_files" (click)="show_translations_files = !show_translations_files">
                            <mat-icon class="text-grey">expand_less</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
                <div [@detail_expand]="show_translations_files ? 'expanded' : 'collapsed'">
                    <mat-list-item class="bottom-border" *ngFor="let resource of getBrandingResource('translation');">
                            <span>{{ resource.name }}</span>
                            <div matListItemMeta>
                                <div matListItemMeta *ngTemplateOutlet="actionsField; context: { $implicit: resource }"></div>
                            </div>
                    </mat-list-item>
                </div>

                <mat-list-item class="bottom-border">
                    <span class="subtitle1">Image Files</span>
                    <div matListItemMeta>
                        <button mat-icon-button *ngIf="!show_image_files" (click)="show_image_files = !show_image_files">
                            <mat-icon class="text-grey">expand_more</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="show_image_files" (click)="show_image_files = !show_image_files">
                            <mat-icon class="text-grey">expand_less</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
                <div [@detail_expand]="show_image_files ? 'expanded' : 'collapsed'">
                    <mat-list-item *ngFor="let resource of getBrandingResource('image'); let last = last;" [ngClass]="{'bottom-border': !last}">
                        <span>{{ resource.name }}</span>
                        <div matListItemMeta>
                            <div matListItemMeta *ngTemplateOutlet="actionsField; context: { $implicit: resource }"></div>
                        </div>
                    </mat-list-item>
                </div>
            </mat-list>
        </mat-card-content>
    </mat-card>
</div>
<input hidden type="file" id="brandingFile" #fileInput (change)="newFile($event.target)"/>

<ng-template #showImageRef>
    <img [src]="imageUrl" class="max-size" />
</ng-template>

<ng-template #actionsField let-resource>
    <button mat-icon-button
        i18n-matTooltip matTooltip="Edit"
        *ngIf="resource.type !== 'image'"
        [routerLink]="['../../branding-edit']"
        [queryParams]="{ branding_id: branding.id, file: resource.name }">
        <mat-icon class="mat-18 text-grey">edit</mat-icon>
    </button>
    <button mat-icon-button
        i18n-matTooltip
        matTooltip="Show"
        *ngIf="resource.type === 'image'"
        (click)="showImage(resource)">
        <mat-icon class="mat-18 text-grey">search</mat-icon>
    </button>
    <button mat-icon-button
        i18n-matTooltip
        matTooltip="Upload"
        *ngIf="(['/brandings/:id/*resource_name', 'post'] | qpPermission)"
        (click)="selected_resource = resource;
        fileInput.click()">
        <mat-icon class="mat-18 text-grey">file_upload</mat-icon>
    </button>
    <button mat-icon-button
        i18n-matTooltip
        matTooltip="Download"
        *ngIf="(['/brandings/:id/*resource_name', 'get'] | qpPermission)"
        (click)="downloadFile(resource)">
        <mat-icon class="mat-18 text-grey">file_download</mat-icon>
    </button>
    <button mat-icon-button
        i18n-matTooltip
        matTooltip="Delete"
        *ngIf="(['/brandings/:id/*resource_name', 'delete'] | qpPermission)"
        (click)="deleteFile(resource)">
        <mat-icon class="mat-18 text-grey">delete</mat-icon>
    </button>
</ng-template>
