<form [formGroup]="businessForm" class="flex-col rg8">
    <span class="text-grey" i18n>Shortly describe your business model and estimate your average transaction amount and monthly turnover.</span>

    <mat-form-field hideRequiredMarker>
        <mat-label i18n>Business model</mat-label>
        <textarea matInput name="application_business_model_description" formControlName="description" rows="5" (change)="modelBusinessModel('description', $event.target)" required> </textarea>
        <mat-error *ngIf="description.errors?.required" i18n>A business model description is required.</mat-error>
    </mat-form-field>

    <mat-form-field hideRequiredMarker>
        <mat-label i18n>Expected monthly turnover (average)</mat-label>
        <input matInput name="application_business_model_estimated_monthly_turnover" formControlName="turnover" type="number" (change)="modelBusinessModel('estimated_monthly_turnover', $event.target)" required />
        <mat-error *ngIf="turnover.errors?.required" i18n>An average monthly turnover amount is required.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="businessForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
