
<div class="flex-col rg16">
    <div>
        <button mat-raised-button type="button" (click)="fileInput.click()">
            <span>{{ label }}</span>
            <mat-icon class="mat-18" color="accent">attach_file</mat-icon>
        </button>
    </div>
    
    <input 
        type="file"
        #fileInput 
        hidden
        (change)="addFile($event)"
        [multiple]="multiple_files"
        [accept]="required_filetype"
        name="fileInput">
    
    <div *ngIf="display_files">
        <div class="flex-col" *ngFor="let file of files; let i = index">
            <div class="flex-row justify-start items-center">
                <span class="code flex-75">{{ file.name }}</span>
                <mat-icon *ngIf="delete_files" (click)="removeFile(i)" class="text-red cursor">delete</mat-icon>
            </div>
        </div>
    </div>
</div>

