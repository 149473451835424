import { APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppLoaderService } from './app-loader.service';
import { ComponentsModule } from './components/components.module';
import { environment } from '../environments/environment';
import { ErrorsModule } from './errorhandler/errors.module';
import { AppComponent } from './components/app.component';
import { MatCardModule } from '@angular/material/card';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { AppRoutingModule } from './routing/app-routing.module';
import { WindowModule } from '@helpers/windowprovider/window.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/loader/loader.interceptor';

/* eslint-disable */
export function init_app(appLoaderService: AppLoaderService) {
    const inner = () => appLoaderService.initializeApp();
    return inner;
}

const sw_script_location = `/${self.location.pathname.split('/')[1] || 'en'}/ngsw-worker.js`;

@NgModule({
    declarations: [AppComponent],
    imports: [
        LoaderComponent,
        BrowserAnimationsModule,
        MatCardModule,
        KeyboardShortcutsModule,
        ComponentsModule,
        BrowserModule,
        ErrorsModule,
        AppRoutingModule,
        WindowModule,
        ServiceWorkerModule.register(sw_script_location, { enabled: environment.production })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        AppLoaderService,
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppLoaderService, Sentry.TraceService],
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
