<form [formGroup]="ownershipForm" class="flex-col rg32">
    <span class="text-grey" i18n>Why do we ask about this? According to anti-money laundering regulations we are obligated to know who owns (directly or indirectly) more than 25% of your company.</span>

    <mat-radio-group name="company_ownership_structure" formControlName="ownership_structure" class="flex-col" (change)="modelCompany('ownership_structure', $event.value)" required>
        <mat-radio-button value="sole_director" i18n>The merchant is a sole proprietorship owned by the director</mat-radio-button>
        <mat-radio-button value="sole_not_director" i18n>The merchant is a sole proprietorship not owned by the director</mat-radio-button>
        <mat-radio-button value="one_or_more_25" i18n>One or more owners are holding a direct or indirect share of more than 25%</mat-radio-button>
        <mat-radio-button value="none_25" i18n>No owners are holding a direct or indirect share of more than 25%</mat-radio-button>
    </mat-radio-group>

    <div formGroupName="ownerGroup" class="flex-col rg16" *ngIf="companySoleNotDirector || companyOneOrMore25">
        <h5 *ngIf="companySoleNotDirector" i18n>Owner</h5>
        <h5 *ngIf="companyOneOrMore25" i18n>Owners ({{ owners.length }})</h5>

        <div class="flex-col rg8" *ngFor="let owner of owners; let i = index">
            <ng-container [formGroupName]="'owner' + i">
                <b *ngIf="companyOneOrMore25" i18n>Owner #{{i + 1}}.</b>
                <small *ngIf="companyOneOrMore25 && i === 0" class="text-grey" i18n>At least one owner is required.</small>
                <div *ngIf="i > 0">
                    <button mat-raised-button type="button" color="warn" (click)="deleteOwner(i)" i18n>DELETE OWNER</button>
                </div>
                <mat-form-field>
                    <mat-label i18n>Full name</mat-label>
                    <input matInput name="owner_{{i}}_name" formControlName="name" type="text" autofocus (change)="modelOwner(i, 'name', $event.target)" required />
                    <mat-error *ngIf="getOwnerControl(i, 'name')?.errors?.required" i18n>A full name is required.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n>Social security number</mat-label>
                    <input matInput name="owner_{{i}}_ssn" formControlName="social_security_number" type="text" autofocus (change)="modelOwner(i, 'social_security_number', $event.target)" required />
                    <mat-error *ngIf="getOwnerControl(i, 'social_security_number')?.errors?.required" i18n>A social security number is required.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n>Date of birth (YYYY-MM-DD)</mat-label>
                    <input #ownerDateInput formControlName="date_of_birth" matInput name="director_date_of_birth" required type="text" [matDatepicker]="ownerDatePicker" [max]="maxDate" [min]="minDate" (dateChange)="handleDateChange(i, $event.value)" />
                    <mat-datepicker-toggle matSuffix [for]="ownerDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #ownerDatePicker startView="multi-year"></mat-datepicker>
                    <mat-error *ngIf="getOwnerControl(i, 'date_of_birth')?.errors" i18n>A valid date of birth is required.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n>Address</mat-label>
                    <input matInput name="owner_{{i}}_address_line_1" formControlName="address_line_1" type="text" autofocus (change)="modelOwner(i, 'address_line_1', $event.target)" required />
                    <mat-error *ngIf="getOwnerControl(i, 'address_line_1')?.errors?.required" i18n>An address is required.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n>Zip code</mat-label>
                    <input matInput name="owner_{{i}}_zipcode" formControlName="zipcode" type="text" autofocus (change)="modelOwner(i, 'zipcode', $event.target)" required />
                    <mat-error *ngIf="getOwnerControl(i, 'zipcode')?.errors?.required" i18n>A Zip code is required.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n>City</mat-label>
                    <input matInput name="owner_{{i}}_city" formControlName="city" type="text" autofocus (change)="modelOwner(i, 'city', $event.target)" required />
                    <mat-error *ngIf="getOwnerControl(i, 'city')?.errors?.required" i18n>A city is required.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n>Country</mat-label>
                    <mat-select formControlName="country" (selectionChange)="onCountryChange(i, $event.value)" required>
                        <mat-option *ngFor="let country of countries" [value]="country.code"> {{ country.name }} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="getOwnerControl(i, 'country')?.errors?.required" i18n>A country is required.</mat-error>
                </mat-form-field>

                <span class="text-grey" i18n>Upload official documents with the owner's photo and address.</span>

                <mat-form-field>
                    <mat-label i18n>Picture ID</mat-label>
                    <ngx-mat-file-input #ownerPictureIDInput formControlName="pictureID" required accept="application/pdf,image/*"> </ngx-mat-file-input>
                    <mat-icon matSuffix>note_add</mat-icon>
                    <mat-hint i18n>Examples: passport, driver's license</mat-hint>
                    <mat-error *ngIf="getOwnerControl(i, 'pictureID')?.errors?.required" i18n>A Picture ID is required.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n>Address ID</mat-label>
                    <ngx-mat-file-input #ownerAddressIDInput formControlName="addressID" required accept="application/pdf,image/*"> </ngx-mat-file-input>
                    <mat-icon matSuffix>note_add</mat-icon>
                    <mat-hint i18n>Examples: utility bill</mat-hint>
                    <mat-error *ngIf="getOwnerControl(i, 'addressID')?.errors?.required" i18n>An Address ID is required.</mat-error>
                </mat-form-field>
            </ng-container>
        </div>

        <div *ngIf="companyOneOrMore25" class="flex-col">
            <b *ngIf="companyOneOrMore25" i18n>Add another owner</b>
            <div>
                <button mat-raised-button type="button" (click)="addOwner()" color="accent">
                    <span i18n>Add owner</span>
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <mat-form-field *ngIf="companyNone25">
        <mat-label i18n>Ownership structure</mat-label>
        <textarea matInput name="ownership_structure_comment" formControlName="ownership_structure_comment" (change)="onOwnershipStructureCommentChange( 'ownership_structure_comment', $event.target)" rows="5" required> </textarea>
        <mat-error *ngIf="ownership_structure_comment.errors?.required" i18n>A company structure required.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="ownershipForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
