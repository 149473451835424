import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    selector: 'qp-password-strength-bar',
    templateUrl: './password-strength-bar.component.html',
    styleUrls: ['../login-layout.component.scss'],
    standalone: true,
    imports: [CommonModule, MatProgressBarModule]
})
export class PasswordStrengthBarComponent implements AfterViewInit, OnChanges {
    @Input() public password: string;

    @ViewChild('strengthBar', { read: ElementRef }) public strength_bar: ElementRef;

    public styleEl: HTMLStyleElement = document.createElement('style');
    public password_strength: number;
    public color: string;
    public colors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];

    constructor() {}

    public ngAfterViewInit(): void {
        this.password_strength = 0;
        this.strength_bar.nativeElement.appendChild(this.styleEl);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.checkStrength(changes.password.currentValue);
    }

    public checkStrength(password: string): void {
        let strength = 0;

        const lowerLetters = /[a-z]+/.test(password); // check lower case letter
        const upperLetters = /[A-Z]+/.test(password); // check upper case letter
        const numbers = /[0-9]+/.test(password);    // check contains number
        const symbols = /[$-/:-?{-~!"^_@`\[\]]/g.test(password); // check contains special characters
        const flags = [lowerLetters, upperLetters, numbers, symbols]; // set booleans as array of flags

        for (const flag of flags) {
            strength += flag === true ? 10 : 0;
        }

        strength += 3 * password.length;

        this.password_strength = strength;
        this.updateColor();
    }

    public updateColor(): void {
        if (this.password_strength > 90) {
            this.color = this.colors[4];
        }

        if (this.password_strength <= 90) {
            this.color = this.colors[3];
        }

        if (this.password_strength < 75) {
            this.color = this.colors[2];
        }

        if (this.password_strength < 50) {
            this.color = this.colors[1];
        }

        if (this.password_strength < 25) {
            this.color = this.colors[0];
        }

        this.styleEl.innerText = `
            .mat-progress-bar-fill::after {
                background-color: ${this.color};
            }
        `;
    }
}
