import { Injectable } from '@angular/core';
import { Customer } from '@models/customer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceClient } from './invoice-client';

@Injectable({
    providedIn: 'root'
})
export class CustomerRepo {
    constructor(private client: InvoiceClient) {}

    public create(customer: Customer): Observable<Customer> {
        return this.client.post<Customer>('/customers', customer).pipe(
            map((item: Customer) => Object.assign(new Customer(), item))
        );
    }

    public get(params: any): Observable<Customer | null> {
        return this.client.get<Customer>('/customers', { params }).pipe(
            map((item: any) => {
                if (item.length) {
                    return Object.assign(new Customer(), item[0]);
                }
                return null;
            })
        );
    }

    public update(data: any): Observable<Customer> {
        return this.client.patch<Customer>('/customers/' + data.id, data).pipe(
            map((item: Customer) => Object.assign(new Customer(), item))
        );
    }

    public deleteSubscriptionInfo(id: number): Observable<any> {
        return this.client.delete('/customers/' + id + '/billing-info/subscription-info');
    }

    public patchBillingInfo(data: Customer): Observable<Customer> {
        return this.client.patch<Customer>('/customers/' + data.id + '/billing-info', data).pipe(
            map((item: Customer) => Object.assign(new Customer(), item))
        );
    }

    public patchGraceDate(data: Customer): Observable<Customer> {
        return this.client.patch<Customer>('/customers/' + data.id + '/billing-info/grace', data).pipe(
            map((item: Customer) => Object.assign(new Customer(), item))
        );
    }

    public pauseAccount(data: Customer): Observable<Customer> {
        return this.client.post<Customer>('/customers/' + data.id + '/put-on-hold', data).pipe(
            map((item: Customer) => Object.assign(new Customer(), item))
        );
    }

    public unpauseAccount(data: Customer): Observable<Customer> {
        return this.client.post<Customer>('/customers/' + data.id + '/unset-on-hold', data).pipe(
            map((item: Customer) => Object.assign(new Customer(), item))
        );
    }
}
