<div class="flex-col rg16">
    <div class="flex-col rg8">
        <span class="subtitle1" i18n>Choose filter:</span>
        <mat-radio-group [(ngModel)]="data.timestamp" color="accent" class="flex-col rg8">
            <mat-radio-button value="created_at" i18n-aria-label aria-label="Created at" i18n> Created at </mat-radio-button>
            <mat-radio-button value="updated_at" i18n-aria-label aria-label="Latest operation at" i18n>
                Latest operation at
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="flex-col rg8">
        <span class="subtitle1" i18n>Define filter:</span>
        <mat-form-field color="accent" floatLabel="always">
            <mat-label i18n>Range:</mat-label>
            <mat-select [(ngModel)]="range" (selectionChange)="changeDates()" placeholder="Select range">
                <mat-option value="today" i18n> Today</mat-option>
                <mat-option value="yesterday" i18n> Yesterday</mat-option>
                <mat-option value="last_7_days" i18n> Last 7 days</mat-option>
                <mat-option value="last_x_days" i18n> Last x days</mat-option>
                <mat-option value="this_month" i18n> This month</mat-option>
                <mat-option value="last_month" i18n> Last month</mat-option>
                <mat-option value="specify" i18n> Specify</mat-option>
            </mat-select>
        </mat-form-field>

        <div [class.hide]="!range || range !== 'last_x_days'">
            <mat-form-field color="accent" floatLabel="always">
                <mat-label i18n>No. of days</mat-label>
                <input matInput type="number" [(ngModel)]="lastDays" (change)="changeLastDays()" placeholder="Fill in no. of days" />
            </mat-form-field>
        </div>

        <div [class.hide]="!range || range !== 'specify'">
            <mat-form-field class="margin r8" color="accent" floatLabel="always">
                <mat-label i18n>From:</mat-label>
                <input 
                    matInput
                    [matDatepicker]="minTime"
                    [(ngModel)]="data.min_time"
                    [max]="data.max_time"
                    placeholder="yyyy-mm-dd" />
                <mat-datepicker-toggle matSuffix [for]="minTime"></mat-datepicker-toggle>
                <mat-datepicker #minTime></mat-datepicker>
            </mat-form-field>

            <mat-form-field color="accent" floatLabel="always">
                <mat-label i18n>To:</mat-label>
                <input
                    matInput
                    [matDatepicker]="maxTime"
                    [ngModel]="data.max_time"
                    [min]="data.min_time"
                    (dateChange)="changeMaxDate($event)"
                    placeholder="yyyy-mm-dd"
                />
                <mat-datepicker-toggle matSuffix [for]="maxTime"></mat-datepicker-toggle>
                <mat-datepicker #maxTime></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</div>
