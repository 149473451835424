import { Theme } from '@interfaces/theme';

export const themes: { [key: string]: Theme } = {
    'manage.cardpaygo.com': {
        name: 'cardpaygo',
        brand_name: 'cardpaygo',
        background: '',
        logo: {
            light: 'assets/images/cardpaygo-logo-light.png',
            dark: 'assets/images/cardpaygo-logo-dark.png'
        },
        primary_color: '#ef652b'
    },
    'manage.lumi-pay.com': {
        name: 'lumipay',
        brand_name: 'Lumi-Pay',
        background: '',
        logo: {
            light: 'assets/images/lumipay-logo-light.png',
            dark: 'assets/images/lumipay-logo-dark.png'
        },
        primary_color: '#ffbe2e'
    },
    'insights.unzerdirect.com': {
        name: 'unzer',
        brand_name: 'Unzer',
        background: '',
        logo: {
            light: 'assets/images/unzer-logo-light.png',
            dark: 'assets/images/unzer-logo-dark.png'
        },
        primary_color: '#fc1154'
    },
    'manager.coolpay.com': {
        name: 'coolpay',
        brand_name: 'CoolPay',
        background: '',
        logo: {
            light: 'assets/images/coolpay-logo-light.png',
            dark: 'assets/images/coolpay-logo-dark.png'
        },
        primary_color: '#da0f3c'
    },
    'manage.sumopay.dk': {
        name: 'sumopay',
        brand_name: 'Sumopay',
        background: '',
        logo: {
            light: 'assets/images/logo-sumopay-light.png',
            dark: 'assets/images/logo-sumopay-dark.png'
        },
        primary_color: '#FAECE5'
    },
    'manage.one4all-pay.com': {
        name: 'one4all-pay',
        brand_name: 'One4All-Pay',
        background: '',
        logo: {
            light: 'assets/images/logo-one4all-light.png',
            dark: 'assets/images/logo-one4all-dark.jpeg'
        },
        primary_color: '#FAECE5'
    },
    'manage.quickpay.net': {
        name: 'quickpay',
        brand_name: 'Quickpay',
        background: 'assets/images/menu_background.jpg',
        logo: {
            light: 'assets/images/logo-light-resized.png',
            dark: 'assets/images/logo-dark.png'
        },
        primary_color: '#008fff'
    }
};
