import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { MonoTypeOperatorFunction, of, pipe } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const catchHttpStatusError = <T>(
    status_codes: HttpStatusCode[],
    handler: (error: HttpErrorResponse) => any,
    propagate_error = false
): MonoTypeOperatorFunction<T> =>
    pipe(
        catchError((error: HttpErrorResponse) => {
            if (status_codes.includes(error.status) && !propagate_error) {
                if (error.status === HttpStatusCode.BadRequest) {
                    return of(handler(new HttpBadRequestErrorResponse(error)));
                }
                return of(handler(error));
            } else {
                handler(error);
                throw error;
            }
        }),
        map(val => val)
    );

export class HttpBadRequestErrorResponse extends HttpErrorResponse {
    constructor(original_error: HttpErrorResponse) {
        super({
            error: Object.entries<string[]>(original_error.error.errors).reduce((msg, entry) => {
                return `${msg} ${entry[0]} ${entry[1][0]}\n`;
            }, ''),
            headers: original_error.headers,
            status: original_error.status,
            statusText: original_error.error.message || original_error.statusText,
            url: original_error.url ?? undefined
        });
    }
}
