import { Component, Input } from '@angular/core';

import { Transaction } from '@models/transaction';

@Component({
    selector: 'qp-transaction-details-shipping',
    templateUrl: './transaction-details-shipping.html',
    styleUrls: ['../transaction-details.scss']
})
export class TransactionDetailsShippingComponent {
    @Input() public transaction: Transaction;
    public objectKeys = Object.keys;

    public valuesNotEmpty(): boolean {
        for (const key in this.transaction.shipping) {
            if (this.transaction.shipping[key] !== null) {
                return true;
            }
        }
        return false;
    }
}
