import { environment } from '@environments/environment';

export const getEnv = (): 'dev' | 'staging' | 'prod' => {
    if (environment.devMode || window.location.hostname === 'localhost') {
        return 'dev';
    }
    if (environment.production) {
        return 'prod';
    }
    return 'staging';
};
