import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@pipes/pipes.module';
import { HttpPrefixModule } from '@widgets/httpprefix/http-prefix.module';
import { LayoutModule } from '@widgets/layout/layout.module';
import { ClipboardModule } from 'ngx-clipboard';
import { AlertDialogComponent } from './alert/alert.dialog.component';
import { ConfirmDialogComponent } from './confirm/confirm.dialog.component';
import { CopyDialogComponent } from './copy/copy.dialog.component';
import { LoadingDialogComponent } from './loading/loading.dialog.component';
import { PromptDialogComponent } from './prompt/prompt.component';
import { TemplateDialogComponent } from './template/template.dialog.component';
import { UnsavedChnagesDialogComponent } from './unsaved-change/unsaved-change.component';

@NgModule({
    imports: [
        ClipboardModule,
        CommonModule,
        FormsModule,
        HttpPrefixModule,
        LayoutModule,
        PipesModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule
    ],
    declarations: [
        AlertDialogComponent,
        ConfirmDialogComponent,
        CopyDialogComponent,
        LoadingDialogComponent,
        PromptDialogComponent,
        TemplateDialogComponent,
        UnsavedChnagesDialogComponent
    ],
    exports: [
        AlertDialogComponent,
        ConfirmDialogComponent,
        CopyDialogComponent,
        LoadingDialogComponent,
        PromptDialogComponent,
        TemplateDialogComponent,
        UnsavedChnagesDialogComponent
    ]
})
export class DialogModule {}
