<div class="flex-row no-print">
    <button mat-button [routerLink]="['../acquirers']" class="mat-button-back" color="accent">&lt; <span i18n>Back to Acquirers</span></button>
</div>

<mat-card class="flex-col rg24">
    <div>
        <mat-card-header class="flex-row">
            <mat-icon mat-card-avatar color="accent">list</mat-icon>
            <mat-card-title class="flex-row justify-start items-center" i18n> Visa and Mastercard priority </mat-card-title>
        </mat-card-header>
        <mat-card-subtitle i18n>
            Select which acquirer that should be prioritized for Visa and Mastercard transactions.
        </mat-card-subtitle>
    </div>

    <mat-card-content>
        <mat-form-field class="flex-48-mobile-100" *ngIf="form">
            <mat-label i18n>Select prioritized acquirer</mat-label>
            <mat-select (selectionChange)="save($event.value)" [formControl]="form">
                <mat-option value="none">None</mat-option>
                <mat-option *ngFor="let acquirer of active_card_acquirers" [value]="acquirer"> {{ acquirer }} </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
</mat-card>
