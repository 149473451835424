import { Client } from '../client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ApiClient extends HttpClient {
    public abstract setHeader(name: string, value: string): any;
    public abstract deleteHeader(name: string): any;
}
export function apiClientFactory(httpClient: HttpClient): Client {
    return new Client(httpClient, '/api', {
        headers: new HttpHeaders({
            'Accept-Version': 'v10',
            'X-Requested-With': 'XMLHttpRequest'
        })
    });
}
