import { Component, Input, OnInit } from '@angular/core';
import { Operation } from '@models/operation';

@Component({
    selector: 'qp-present-callback-status',
    templateUrl: './callback-status.html'
})
export class PresentCallbackComponent implements OnInit {
    @Input() public onText: string;
    @Input() public offText: string;
    @Input() public operation?: Operation;
    public url: string;
    public text_style: string;
    public duration = this.operation?.callback_duration ? this.operation.callback_duration / 1000 : NaN;

    public ngOnInit(): void {
        this.text_style = this.textStyle();
        this.callbackUrl();
    }

    public textStyle(): string {
        return !this.operation ? '' : this.operation.callback_success ? 'text-green' : 'text-darkred';
    }

    public callbackUrl(): string {
        return !this.operation?.callback_url ? (this.url = '') : (this.url = this.operation.callback_url);
    }
}
