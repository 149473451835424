<div class="flex-row cg8">
    <button mat-raised-button
            color="accent"
            type="button"
            (click)="onSave()"
            [disabled]="form_invalid"
            i18n>
            Save changes
    </button>
    <button mat-raised-button
            type="button"
            (click)="onDiscard()"
            i18n>
            Discard changes
    </button>
</div>
