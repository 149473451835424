import { Dispute, DocumentationFile, Comment, DisputeDetail } from '@models/clearhaus-dispute';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServerClient } from './server-client';

@Injectable({
    providedIn: 'root'
})
export class DisputeRepo {
    constructor(
        private client: ServerClient
    ) {}

    public getDisputes(params: any): Observable<Dispute[]> {
        params = params || {};
        return this.client.get<DisputeDetail>('/clearhaus/account/disputes', { params }).pipe(
            map((result) => result.disputes.map((item) => Object.assign(new Dispute(), item)))
        );
    }

    public getDisputeByID(id: string): Observable<Dispute> {
        return this.client.get<Dispute>('/clearhaus/account/disputes/' + id).pipe(
            map((item: any) => Object.assign(new Dispute(), item))
        );
    }

    public getDisputeComment(id: string): Observable<Comment> {
        return this.client.get<Comment>('/clearhaus/account/disputes/' + id + '/comments').pipe(
            map((item: any) => Object.assign(new Comment(), item))
        );
    }

    public addStampToDispute(id: string, stamp: string): Observable<Dispute> {
        return this.client.put<Dispute>('/clearhaus/account/disputes/' + id + '?stamp=' + stamp, {}).pipe(
            map((item: any) => Object.assign(new Dispute(), item))
        );
    }

    public getDocumentationFromDispute(id: string): Observable<DocumentationFile[]> {
        return this.client.get<DocumentationFile[]>('/clearhaus/account/disputes/' + id + '/files', {}).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new DocumentationFile(), item)))
        );
    }

    public addDocumentationToDispute(id: string, params: DocumentationFile): Observable<DocumentationFile> {
        return this.client.post<DocumentationFile>('/clearhaus/account/disputes/' + id + '/file', params).pipe(
            map((item: any) => Object.assign(new DocumentationFile(), item))
        );
    }

    public addCommentToDispute(id: string, params: any): Observable<Dispute> {
        return this.client.post<Dispute>('/clearhaus/account/disputes/' + id + '/comment', params).pipe(
            map((item: any) => Object.assign(new Dispute(), item))
        );
    }
}
