import { AccountComponent } from './account.component';
import { ApiModule } from 'app/endpoints/api/api.module';
import { CommonModule } from '@angular/common';
import { CustomFormsModule } from '@widgets/custom-forms/custom-forms.module';
import { DeleteAccountDialogComponent } from './delete-account-dialog/delete-account-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CustomFormsModule,
        FormsModule,
        LayoutModule,
        LayoutModule,
        PipesModule,
        ReactiveFormsModule
    ],
    exports: [
        AccountComponent
    ],
    declarations: [
        AccountComponent,
        DeleteAccountDialogComponent
    ]
})
export class AccountModule {}
