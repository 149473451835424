import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { LoyaltyKeyRepo } from '@endpoints/server/loyalty-key-repo';

@Component({
    selector: 'qp-loyaltykey',
    templateUrl: './loyaltykey.html',
    styleUrls: ['../../thirdparty.scss', 'loyaltykey.scss']
})
export class LoyaltyKeyComponent implements OnInit {
    public application_sendt: boolean;
    public loading: boolean;
    public image_url = 'assets/images/integrations/loyaltykey/hands_en.png';

    constructor(
        private loyaltyKeyRepo: LoyaltyKeyRepo,
        @Inject(LOCALE_ID) private locale: string
    ) {}

    public ngOnInit(): void {
        this.image_url = this.locale === 'da' ? 'assets/images/integrations/loyaltykey/hands_da.png' : this.image_url;
        this.loading = true;
        this.loyaltyKeyRepo.checkApplicationActive().subscribe((data) => {
            this.application_sendt = data.id !== null;
            this.loading = false;
        });
    }

    public goToLoyalty(): void {
        window.open('https://loyaltykey.com/quickpay-landingpage/');
    }
}
