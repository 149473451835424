import { Injectable } from '@angular/core';
import { Branding, BrandingFileUploadOptions, BrandingResource } from '@models/branding';
import { JSZipObject } from 'jszip';
import { concat, forkJoin, from, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BrandingRepo } from '../../endpoints/api/branding-repo';
import { FileService } from '../file/file.service';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    constructor(
        private brandingRepo: BrandingRepo,
        private qpFile: FileService
    ) {}

    public uploadFile(options: BrandingFileUploadOptions, branding: Branding): Observable<BrandingResource> {
        const name = options.file_name;
        const file = options.file;
        const size = options.size || options.file.size.toString();
        const type: string = options.type ? '' : options.type === null ? '' : options.file.type;

        return this.brandingRepo.putResource(branding.id ?? NaN, name, file).pipe(
            tap(() => {
                branding.addResource(name, type, size);
            })
        );
    }

    public extractZip(inputZipFile: File, branding: Branding): Observable<{ mime: string; name: string; size: string; errors: string[]; }> {
        let files_with_error: string[] = [];

        return from(this.qpFile.extractZip(inputZipFile)).pipe(
            map(zip => Object.values(zip.files)),
            map(files => files.filter(file => !file.dir)),
            map((files) => {
                const is_extension_allowed = (file: JSZipObject): boolean => {
                    const extension = file.name.split('.').pop() ?? '';
                    const is_extension_allowed = this.qpFile.isExtensionAllowed(extension.toLowerCase());
                    return is_extension_allowed;
                };
                files_with_error = files.filter(file => !is_extension_allowed(file)).map(file => file.name);
                return files.filter(file => is_extension_allowed(file));
            }),
            map((files: JSZipObject[]) => files.filter(file => file.name.split('/')[0] !== '__MACOSX')),
            switchMap((files: JSZipObject[]) => {
                const encoded = files.map(file => from(file.async('base64')).pipe(map(data => ({ file, data }))));
                return forkJoin(encoded);
            }),
            switchMap((files) => {
                const upload_files = files.map((file_data) => {
                    const extension = file_data.file.name.split('.').pop() ?? '';
                    const filename = this.getFileName(file_data.file.name.split('/'));
                    const mimeType = this.qpFile.getMimeType(extension.toLowerCase());
                    const blob = this.qpFile.b64toBlob(file_data.data, mimeType, 512);

                    return this.uploadFile({ file_name: filename, file: blob, size: '1', type: mimeType }, branding).pipe(
                        catchError((err: any) => {
                            files_with_error.push(file_data.file.name);
                            return err;
                        }),
                        map(() => ({ mime: mimeType, name: filename, size: blob.size.toString(), errors: files_with_error }))
                    );
                });

                return concat(...upload_files);
            })
        );
    }

    private getFileName(split_name: string[]): string {
        if (split_name.length === 1) {
            return split_name[0];
        } else if (split_name[0].indexOf('standard-branding-') !== -1) {
            return split_name.splice(1).join('/');
        } else {
            return split_name.join('/');
        }
    }
}
