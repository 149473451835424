import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SearchByOptions } from '@interfaces/params';

@Component({
    selector: 'qp-no-results',
    templateUrl: './no-results.html'
})
export class NoResultsComponent {
    @Input() public allowWildcard = false;
    @Input() public filters: { [key: string]: any } = {};
    @Input() public search?= '';
    @Input() public searchBy?= '';
    @Input() public searchByOptions: Array<SearchByOptions> = [];
    @Input() public state: number;
    @Input() public states: Array<string> = [];
    @Input() public type = 'results';

    @Output() public getPage: EventEmitter<any> = new EventEmitter();
    @Output() public stateChange = new EventEmitter();
    @Output() public searchChange = new EventEmitter();
    @Output() public searchByChange = new EventEmitter();
    @Output() public filtersChange = new EventEmitter();

    constructor() {}

    public getPresentOptions(): SearchByOptions {
        const toReturn = this.searchByOptions.filter(obj => obj.value === this.searchBy);
        if (!toReturn[0]) {
            toReturn[0] = {
                wildcard: false
            };
        }
        return toReturn[0];
    }

    public getOtherOptions(): Array<SearchByOptions> {
        return this.searchByOptions.filter(obj => obj.value !== this.searchBy);
    }

    public getFilters(): Array<string> {
        return Object.keys(this.filters);
    }

    public anyFiltersActive(): boolean {
        let active = false;
        for (const key in this.filters) {
            if (this.filters[key] !== null && this.filters[key] !== '' && this.filters[key] !== false) {
                active = true;
            }
        }
        return active;
    }

    public removeFilters(): void {
        for (const key in this.filters) {
            if (this.filters[key] !== null) {
                this.filters[key] = null;
            }
        }

        this.filtersChange.emit(this.filters);
        this.getPage.emit(null);
    }

    public setSearchBy(option: SearchByOptions, search: string | undefined): void {
        this.searchBy = option.value;
        this.searchChange.emit(search?.replace(/%/g, '') ?? '');
        this.searchByChange.emit(this.searchBy);
        this.getPage.emit(null);
    }

    public setWildcardSearch(): void {
        this.search = '%' + this.searchWithoutWildcard() + '%';
        this.searchChange.emit(this.search);
        this.getPage.emit(null);
    }

    public setStateAll(): void {
        this.state = this.states.indexOf('all');
        this.stateChange.emit(this.state);
        this.getPage.emit(null);
    }

    public isWildcardSearch(): boolean {
        return !!this.search ? this.search.substring(0, 1) === '%' : false;
    }

    public searchWithoutWildcard(): string {
        return !!this.search ? this.search.replace(/%/g, '') : '';
    }
}
