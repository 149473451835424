import { AfterContentInit, Directive } from '@angular/core';

@Directive()
export class BaseLoadingDirective implements AfterContentInit {
    public loading: boolean;

    constructor(public loadingTimeout = 300) {
        this.loading = false;
    }

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.loading = false;
        }, this.loadingTimeout);
    }
}
