<h5 i18n mat-dialog-title>Edit payment link</h5>

<mat-dialog-content class="flex-col rg16 grey-border-bottom">
    <mat-form-field hideRequiredMarker>
        <mat-label i18n>Amount</mat-label>
        <input
            inputDecimal
            class="input-right flex-100"
            [currency]="data.currency"
            matInput
            name="amount"
            id="partial_capture"
            type="text"
            required
            i18n-placeholder
            placeholder="Amount"
            [(ngModel)]="data.link.amount"
        />
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Payment methods</mat-label>
        <input name="payment_methods" matInput [(ngModel)]="data.link.payment_methods" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button tabindex="-1" mat-button color="accent" mat-dialog-close i18n>Cancel</button>
    <button tabindex="-1" mat-button color="accent" (click)="dialogRef.close(data.link)" i18n>Save</button>
</mat-dialog-actions>
