
export const CACHE_DEAFULT = {
    cards: {},
    dark_theme: { mode: false },
    acquirer_details: {
        ppro_reach_out: {
            sofortppro: false,
            wechat: false,
            bancontact: false,
            przelewy24: false,
            blik: false
        }
    },
    dateFormats: {
        only_date: { name: $localize`Date`, format: 'MMM dd, yyyy' },
        date_time: { name: $localize`Date and time`, format: 'MMM dd, yyyy - HH:mm:ss' }
    },
    columns: {
        payments: {
            payment_id: { active: true, name: $localize`Payment ID`, allowChange: true },
            order_id: { active: true, name: $localize`Order ID`, allowChange: true },
            created_at: { active: true, name: $localize`Created at`, allowChange: true, format: 'only_date' },
            currency: { active: true, name: $localize`Currency`, allowChange: true },
            amount: { active: true, name: $localize`Amount`, allowChange: true },
            balance: { active: true, name: $localize`Balance`, allowChange: true },
            fee: { active: false, name: $localize`Fee`, allowChange: true },
            method: { active: true, name: $localize`Method`, allowChange: true },
            acquirer: { active: false, name: $localize`Acquirer`, allowChange: true },
            flags: { active: true, name: $localize`Flags`, allowChange: true },
            actions: { active: true, name: $localize`Actions`, allowChange: false },
            invoice_name: { active: false, name: $localize`Invoice name`, allowChange: true },
            shipping_name: { active: false, name: $localize`Shipping name`, allowChange: true },
            last_operation: { active: false, name: $localize`Last operation`, allowChange: true },
            date_last_operation: { active: false, name: $localize`Date of last operation`, allowChange: true }
        },
        subscriptions: {
            subscription_id: { active: true, name: $localize`Subscription ID`, allowChange: true },
            order_id: { active: true, name: $localize`Order ID`, allowChange: true },
            created_at: { active: true, name: $localize`Created at`, allowChange: true, format: 'only_date' },
            acquirer: { active: false, name: $localize`Acquirer`, allowChange: true },
            description: { active: false, name: $localize`Description`, allowChange: true },
            flags: { active: true, name: $localize`Flags`, allowChange: true },
            method: { active: true, name: $localize`Method`, allowChange: true },
            text_on_statement: { active: true, name: $localize`Text on statement`, allowChange: true },
            last_operation: { active: false, name: $localize`Last operation`, allowChange: true }
        },
        payouts: {
            payout_id: { active: true, name: $localize`Payout ID`, allowChange: true },
            order_id: { active: true, name: $localize`Order ID`, allowChange: true },
            created_at: { active: true, name: $localize`Created at`, allowChange: true, format: 'only_date' },
            acquirer: { active: false, name: $localize`Acquirer`, allowChange: true },
            method: { active: true, name: $localize`Method`, allowChange: true },
            flags: { active: true, name: $localize`Flags`, allowChange: true },
            text_on_statement: { active: false, name: $localize`Text on statement`, allowChange: true },
            last_operation: { active: false, name: $localize`Last operation`, allowChange: true }
        },
        cards: {
            card_id: { active: true, name: $localize`Card ID`, allowChange: true },
            created_at: { active: true, name: $localize`Created at`, allowChange: true, format: 'only_date' },
            acquirer: { active: false, name: $localize`Acquirer`, allowChange: true },
            flags: { active: true, name: $localize`Flags`, allowChange: true },
            actions: { active: true, name: $localize`Actions`, allowChange: true },
            last_operation: { active: false, name: $localize`Last operation`, allowChange: true }
        },
        settlements: {
            period: { active: true, name: $localize`Period`, allowChange: true },
            sales: { active: true, name: $localize`Sales`, allowChange: true },
            refunds: { active: false, name: $localize`Refunds`, allowChange: true },
            chargebacks: { active: false, name: $localize`Chargebacks`, allowChange: true },
            fees: { active: false, name: $localize`Fees`, allowChange: true },
            other_postings: { active: false, name: $localize`Other postings`, allowChange: true },
            total: { active: false, name: $localize`Total`, allowChange: true },
            payout: { active: true, name: $localize`Payout`, allowChange: true },
            payout_date: { active: true, name: $localize`Payout date`, allowChange: true },
            reserve: { active: false, name: $localize`Reserve`, allowChange: true },
            reserve_date: { active: false, name: $localize`Reserve date`, allowChange: true }
        }
    }
};
