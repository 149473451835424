import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiClient } from './api-client';
import { SubscriptionGroup } from '@models/subscription-group';
import { SubscriptionGroupResult } from '@models/subscription-group-result';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionGroupRepo {
    constructor(private client: ApiClient) {}

    public get(id: number): Observable<SubscriptionGroup> {
        return this.client.get<SubscriptionGroup>('/subscription-groups/' + id);
    }

    public getAll(params?: any): Observable<SubscriptionGroup[]> {
        params = params || {};
        return this.client.get<SubscriptionGroup>('/subscription-groups', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new SubscriptionGroup(), item)))
        );
    }

    public create(group: SubscriptionGroup): Observable<SubscriptionGroup> {
        return this.client.post<SubscriptionGroup>('/subscription-groups', group, undefined);
    }

    public update(group: SubscriptionGroup): Observable<SubscriptionGroup> {
        return this.client.patch<SubscriptionGroup>('/subscription-groups/' + group.id, group);
    }

    public delete(id: number): Observable<SubscriptionGroup> {
        return this.client.delete<SubscriptionGroup>('/subscription-groups/' + id);
    }

    public capture(group: SubscriptionGroup | undefined, prefix: string): Observable<SubscriptionGroupResult[]> {
        return this.client.post<SubscriptionGroup>(
            '/subscription-groups/' + group?.id + '/capture',
            { order_id_prefix: prefix }, undefined
        ).pipe(
            map((item: any) => Object.assign(new SubscriptionGroupResult(), item))
        );
    }
}
