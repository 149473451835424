import { CreditNote } from '@models/creditnote';
import { Injectable } from '@angular/core';
import { InvoiceClient } from './invoice-client';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CreditNoteRepo {
    constructor(
        private invoiceClient: InvoiceClient
    ) {}

    public get(id: string): Observable<CreditNote> {
        return this.invoiceClient.get<CreditNote>(`/credit-notes/${id}`).pipe(
            map((activity: CreditNote) => Object.assign(activity as CreditNote))
        );
    }

    public getPDF(id: string): Observable<Blob> {
        return this.invoiceClient.get(`/credit-notes/${id}.pdf`, { responseType: 'blob' });
    }

    public create(creditNode?: CreditNote): Observable<CreditNote> {
        return this.invoiceClient.post('/credit-notes', creditNode).pipe(
            map((item: any) => Object.assign(item as CreditNote))
        );
    }
}
