import { ClearhausInstantSignupDocumentationComponent } from './form/documentation-step/documentation-instant.component';
import { ClearhausInstantSignupFormComponent } from './form/form-instant.component';
import { ClearhausInstantSignupFormStepOneComponent } from './form/contact-step/contact-instant.component';
import { ClearhausInstantSignupFormStepSevenComponent } from './form/bank-step/bank-instant.component';
import { ClearhausInstantSignupFormStepSixComponent } from './form/buisness-step/business-instant.component';
import { ClearhausInstantSignupFormStepTwoComponent } from './form/company-step/company-instant.component';
import { ClearhausInstantSignupFormSubmitComponent } from './form/submit-step/submit-instant.component';
import { ClearhausSignupComponent } from './signup.component';
import { ClearhausSignupFormComponent } from './form/form.component';
import { ClearhausSignupFormSignatoryComponent } from './form/signatory-step/signatory.component';
import { ClearhausSignupFormStepEightComponent } from './form/documentation-step/documentation.component';
import { ClearhausSignupFormStepFourComponent } from './form/ownership-step/ownership.component';
import { ClearhausSignupFormStepNineComponent } from './form/information-step/information.component';
import { ClearhausSignupFormStepOneComponent } from './form/contact-step/contact.component';
import { ClearhausSignupFormStepSevenComponent } from './form/bank-step/bank.component';
import { ClearhausSignupFormStepSixComponent } from './form/buisness-step/business.component';
import { ClearhausSignupFormStepThreeComponent } from './form/director-step/director.component';
import { ClearhausSignupFormStepTwoComponent } from './form/company-step/company.component';
import { ClearhausSignupFormSubmitComponent } from './form/submit-step/submit.component';
import { ClearhausSignupFormWebsiteStepComponent } from './form/website-step/websites.component';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgModule } from '@angular/core';
import { NgxIbanModule } from 'ngx-iban';
import { PipesModule } from '@pipes/pipes.module';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';

@NgModule({
    imports: [
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        MaterialFileInputModule,
        NgxIbanModule,
        PipesModule,
        ReactiveFormsModule,
        SpinnerButtonModule
    ],
    exports: [ClearhausSignupComponent],
    declarations: [
        ClearhausInstantSignupDocumentationComponent,
        ClearhausInstantSignupFormComponent,
        ClearhausSignupFormWebsiteStepComponent,
        ClearhausInstantSignupFormStepOneComponent,
        ClearhausInstantSignupFormStepSevenComponent,
        ClearhausInstantSignupFormStepSixComponent,
        ClearhausInstantSignupFormStepTwoComponent,
        ClearhausInstantSignupFormSubmitComponent,
        ClearhausSignupComponent,
        ClearhausSignupFormComponent,
        ClearhausSignupFormSignatoryComponent,
        ClearhausSignupFormStepEightComponent,
        ClearhausSignupFormStepFourComponent,
        ClearhausSignupFormStepNineComponent,
        ClearhausSignupFormStepOneComponent,
        ClearhausSignupFormStepSevenComponent,
        ClearhausSignupFormStepSixComponent,
        ClearhausSignupFormStepThreeComponent,
        ClearhausSignupFormStepTwoComponent,
        ClearhausSignupFormSubmitComponent
    ]
})
export class ClearhausSignupModule {}
