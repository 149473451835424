<div *ngIf="!selected_transactions.selected.length && filters.subscription_group">
    <button mat-icon-button *ngIf="filters.subscription_group" (click)="captureGroup()">
        <mat-icon aria-label="Capture group" i18n-matTooltip matTooltip="Capture group">
            file_download
        </mat-icon>
    </button>
</div>

<div *ngIf="selected_transactions.selected.length">
    <button mat-icon-button [matMenuTriggerFor]="subscriptionsGroupSelected">
        <mat-icon>group</mat-icon>
    </button>

    <mat-menu #subscriptionsGroupSelected="matMenu">
        <ng-container *ngFor="let group of groups">
            <button mat-menu-item (click)="addToGroup(group)" i18n>
                Add to: {{ group.name }}
            </button>
        </ng-container>
    </mat-menu>

    <button mat-icon-button *ngIf="filters.subscription_group" (click)="removeFromGroup()">
        <mat-icon
            aria-label="Remove subscriptions from group"
            i18n-matTooltip
            matTooltip="Remove subscriptions from group"
        >
            delete
        </mat-icon>
    </button>
</div>
