import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentRepo } from '@endpoints/api/payment-repo';
import { SettlementRepo } from '@endpoints/server/settlement-repo';
import { LoadingState } from '@helpers/loading/loading-state';
import { QpPaginationParams, QpPaginatorConfig } from '@models/qp-pagintor';
import { ClearhausTransaction } from '@models/transaction';
import { Animations } from 'app/animations/animations';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';

@Component({
    selector: 'qp-settlement-transactions',
    templateUrl: './settlement-transactions.component.html',
    animations: [Animations.getFadeAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementTransactionsComponent implements OnInit {
    @Input() public settlement_id = '';
    public loading_state = new LoadingState();
    public data_source: MatTableDataSource<ClearhausTransaction> = new MatTableDataSource();
    public display_columns: string[] = ['reference', 'processed_at', 'payment_method', 'card', 'currency', 'amount_gross', 'fee', 'amount_net'];
    public paginator_options: QpPaginatorConfig = {
        page_size_options: [10, 50],
        page_size: 10,
        page: 1,
        label: $localize`Transactions per page`
    };

    constructor(
        private settlementRepo: SettlementRepo,
        private paymentRepo: PaymentRepo,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.loading_state.start();
        this.loadTransactions({ page: this.paginator_options.page, page_size: this.paginator_options.page_size }).pipe(
            finalize(() => this.loading_state.stop())
        ).subscribe();
    }

    public onPageChange(event: QpPaginationParams): void {
        this.loadTransactions(event).subscribe();
    }

    public goToPayment(transaction: ClearhausTransaction): void {
        this.paymentRepo.getByOrderId(transaction.reference).pipe(
            filter((payment) => !!payment),
            tap((payment) => this.router.navigate(['../../payment', payment?.id], { relativeTo: this.activatedRoute }))
        ).subscribe();
    }

    private loadTransactions(pagination: QpPaginationParams): Observable<ClearhausTransaction[]> {
        return this.settlementRepo.getSettlementTransactions(this.settlement_id, pagination).pipe(
            tap((transactions) => this.data_source.data = transactions)
        );
    }
}
