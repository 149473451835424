<mat-card class="flex-col rg32 flex-632px-mobile-100 card02">
    <div class="flex-col rg16">
        <div>
            <img src="assets/images/integrations/economic_dark_logo.png" class="height-20" />
        </div>
        <h5 i18n>Set up e-conomic</h5>

        <form class="flex-col rg16" name="editeconomic" autocomplete="off" [formGroup]="eform">
            <mat-slide-toggle aria-label="Enable e-conomic" formControlName="active" i18n> Enable e-conomic integration </mat-slide-toggle>

            <div class="flex-row" [class.error-border]="formError" [class.warning-border]="eform.dirty && !formError" [class.success-border]="formSaved && eform.pristine">
                <div class="flex-col rg12 flex-1">
                    <mat-form-field>
                        <mat-label i18n>Agreement no</mat-label>
                        <input name="agreement_no" matInput formControlName="agreement_no" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n>Token</mat-label>
                        <input name="agreement_token" matInput formControlName="agreement_token" />
                    </mat-form-field>

                    <qp-form-state-info [form_dirty]="eform.dirty" [form_pristine]="eform.pristine" [form_saved]="formSaved" [form_error]="formError"> </qp-form-state-info>
                </div>
            </div>

            <qp-form-save-discard *ngIf="eform.dirty" [form_dirty]="eform.dirty" [form_invalid]="eform.invalid" (form_save)="saveEconomic()" (form_discard)="discardChanges()"> </qp-form-save-discard>
        </form>
    </div>

    <div class="flex-col rg16">
        <div class="flex-col rg8">
            <h5 class="margin b0" i18n>e-conomic setup guide</h5>
            <a [href]="helpdeskLink" target="_blank" *ngIf="(['Quickpay'] | qpMerchantType)" i18n>Go to our helpdesk for a complete guide</a>
        </div>

        <div class="flex-col rg8">
            <span i18n>1. Click on the button below to open the e-conomic page</span>
            <div>
                <button mat-raised-button color="accent" (click)="openEconomic()" i18n>Create e-conomic token</button>
            </div>
        </div>

        <span i18n>2. Log in using your credentials</span>
        <span i18n>3. Click Add app</span>
        <span i18n>4. Copy the token, and paste it into the e-conomic settings in the Quickpay Manager</span>
        <span i18n> In e-conomic, navigate to “Categories and Units” > “Number series” and change “Invoice number” so “Next number” is at least 4 digits long. </span>
        <span i18n> The setup is now complete. To send payment links, add the following link when sending out mails from e-conomic: </span>
        <span> https://payment.quickpay.net/e-conomic/pay?merchant_id={{ account?.id }}&agreement_id={{ agreementID }}&invoice_id=[FakturaNr] </span>
    </div>
</mat-card>
