import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@pipes/pipes.module';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { LayoutModule } from '@widgets/layout/layout.module';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';
import { ApiModule } from 'app/endpoints/api/api.module';
import { SupportComponent } from './support.component';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        PipesModule,
        ReactiveFormsModule,
        SpinnerButtonModule
        // UIRouterModule.forChild({ states: SUPPORT_STATES })
    ],
    declarations: [SupportComponent]
})
export class SupportModule {}
