import { ApiClient } from './api-client';
import { Fee } from '@models/fee';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeeRepo {
    constructor(private client: ApiClient) {}

    public get(acquirer: string, payment_method: string): Observable<Fee[]> {
        return this.client.get<Fee>('/fees/formulas/' + acquirer + '/' + payment_method).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Fee(), item)))
        );
    }

    public getByAcquirer(acquirer: string): Observable<Fee[]> {
        return this.client.get<Fee>('/fees/formulas/' + acquirer).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Fee(), item)))
        );
    }

    public update(acquirer: string, payment_method: string, formula: string): Observable<Fee> {
        return this.client.patch<Fee>('/fees/formulas/' + acquirer + '/' + payment_method, { formula }).pipe(
            map((item: any) => Object.assign(new Fee(), item))
        );
    }

    public reset(acquirer: string, payment_method: string): Observable<Fee> {
        return this.client.delete<Fee>('/fees/formulas/' + acquirer + '/' + payment_method);
    }
}
