import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ACQUIRERDATA } from '@models/acquirers/acquirerdata.constant';
import { Acquirers } from '@models/acquirers/acquirers';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { SessionService } from '@services/session/session.service';

export class AcquirerListItem {
    public active: boolean;
    public displayData: {
        title: string;
        logos: { path: string }[]
        tpl: string;
        card_acquirer: boolean;
        credentials_missing?: boolean;
    };
}

@Component({
    selector: 'qp-merchant-acquirer-list',
    styleUrls: ['./acquirers.scss'],
    templateUrl: './merchant-list.html'
})
export class MerchantAcquirerListComponent implements OnInit {
    public clearhaus_active = false;
    public clearhaus_missing_credentials = false;
    public nets_dankort_active = false;
    public netsDankort_missing_credentials = false;
    public acquirers: Array<AcquirerListItem> = [];
    public other_card_acquirers_active = false;
    public prioritize_visa = false;
    public is_danish_merchant = false;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private acquirerService: AcquirerService,
        public sessionService: SessionService,
        private agreementService: AgreementService
    ) {}

    public ngOnInit(): void {
        if (
            this.agreementService.agreement?.account?.customer_address?.country_code &&
            ['DNK', 'FRO', 'GRL'].includes(this.agreementService.agreement.account.customer_address.country_code)
        ) {
            this.is_danish_merchant = true;
        }

        if (this.acquirerService.acquirers) {
            this.updateAcquirers(this.acquirerService.acquirers);
            this.prioritize_visa = this.checkAnyCardAcquirerActive();
        }
    }

    public updateAcquirers(acquirers: Acquirers): void {
        if (this.agreementService.agreement) {
            Object.keys(acquirers).sort().forEach(key => {
                const acquirer = acquirers[key as keyof Acquirers];
                const data = ACQUIRERDATA[key];
                const listItem = new AcquirerListItem();

                if (acquirer !== null && environment.acquirersList.indexOf(key) !== -1 && !!data) {
                    switch (data.tpl) {
                        case 'clearhaus':
                            this.clearhaus_active = !!acquirer.active;
                            this.clearhaus_missing_credentials = !this.acquirerService.isCredentialsValid(acquirer);

                            listItem.active = !!this.acquirerService.acquirers?.clearhaus.apple_pay;
                            listItem.displayData = ACQUIRERDATA['applepay'];
                            listItem.displayData.credentials_missing = this.clearhaus_missing_credentials;
                            this.acquirers.push(listItem);

                            const google_pay = new AcquirerListItem();
                            google_pay.active = !!this.acquirerService.acquirers?.clearhaus.google_pay;
                            google_pay.displayData = ACQUIRERDATA['googlepay'];
                            google_pay.displayData.credentials_missing = this.clearhaus_missing_credentials;
                            this.acquirers.push(google_pay);
                            break;

                        case 'unzer-pay-later':
                            if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
                                const unzer_invoice = new AcquirerListItem();
                                unzer_invoice.active = (acquirer as any)?.invoice?.active;
                                unzer_invoice.displayData = ACQUIRERDATA['unzerinvoice'];

                                const unzer_debit = new AcquirerListItem();
                                unzer_debit.active = (acquirer as any)?.direct_debit?.active;
                                unzer_debit.displayData = ACQUIRERDATA['unzerdirectdebit'];

                                if (this.locale === 'de') {
                                    unzer_invoice.displayData.logos[0].path = unzer_invoice.displayData.logos[0].path.substring(0, unzer_invoice.displayData.logos[0].path.length - 4) + '-de.png';
                                    unzer_debit.displayData.logos[0].path = unzer_debit.displayData.logos[0].path.substring(0, unzer_debit.displayData.logos[0].path.length - 4) + '-de.png';
                                }

                                this.acquirers.push(unzer_invoice);
                                this.acquirers.push(unzer_debit);
                            }
                            break;

                        case 'nets':
                            this.nets_dankort_active = !!acquirer.active;
                            this.netsDankort_missing_credentials = !this.acquirerService.isCredentialsValid(acquirer);
                            break;

                        // Card acquires
                        case 'bambora':
                        case 'elavon':
                        case 'handelsbanken':
                        case 'teller':
                        case 'swedbank':
                        case 'valitor':
                            if (acquirer.active) {
                                this.other_card_acquirers_active = true;
                            }
                            listItem.active = !!acquirer.active;
                            listItem.displayData = data;
                            listItem.displayData.credentials_missing = !this.acquirerService.isCredentialsValid(acquirer);
                            this.acquirers.push(listItem);
                            break;

                        case 'vipps':
                            if (
                                !!(this.acquirerService?.acquirers?.vipps.active && this.acquirerService.acquirers.vipps.serial_number)
                            ) {
                                listItem.active = !!acquirer.active;
                                listItem.displayData = data;
                                listItem.displayData.credentials_missing = !this.acquirerService.isCredentialsValid(acquirer);
                                this.acquirers.push(listItem);
                            }
                            break;

                        case 'resurs': // Remove with rest of resurs bank at some point
                            if (acquirer.active) {
                                listItem.active = acquirer.active;
                                listItem.displayData = data;
                                listItem.displayData.credentials_missing = !this.acquirerService.isCredentialsValid(acquirer);
                                this.acquirers.push(listItem);
                            }
                            break;

                        case 'mobilepayonline':
                            listItem.active = !!acquirer.active;
                            listItem.displayData = data;
                            listItem.displayData.credentials_missing = false;
                            this.acquirers.push(listItem);
                            break;

                        case 'paypal': // old paypal, remove at some point
                            if (this.acquirerService?.acquirers?.paypal.active) {
                                listItem.active = !!acquirer.active;
                                listItem.displayData = data;
                                listItem.displayData.credentials_missing = !this.acquirerService.isCredentialsValid(acquirer);
                                this.acquirers.push(listItem);
                            }
                            break;
                        //ppro only for mid 5
                        case 'pprowechatpay':
                        case 'pprobancontact':
                        case 'pproprzelewy24':
                        case 'pproblik':
                        case 'pprosofort':
                            if (this.agreementService.agreement?.account?.id === 5) {
                                listItem.active = !!acquirer.active;
                                listItem.displayData = data;
                                listItem.displayData.credentials_missing = false;
                                this.acquirers.push(listItem);
                            }
                            break;

                        default:
                            listItem.active = !!acquirer.active;
                            listItem.displayData = data;
                            listItem.displayData.credentials_missing = !this.acquirerService.isCredentialsValid(acquirer);
                            this.acquirers.push(listItem);
                            break;
                    }
                }
            });

            this.acquirers.sort((a: any, b: any) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
            this.checkUnzerSpecifikAllowed();
        }
    }

    public checkUnzerSpecifikAllowed(): void {
        const allowed = ['clearhaus', 'applepay', 'klarnapayments', 'paypal', 'sofort', 'googlepay', 'unzer-invoice', 'unzer-direct-debit', 'visa-mastercard'];
        const unzerAcquires: AcquirerListItem[] = [];
        if (new QpMerchantTypePipe(this.agreementService).transform(['Unzer'])) {
            this.acquirers.forEach(acquirer => {
                if (allowed.includes(acquirer.displayData.tpl)) {
                    unzerAcquires.push(acquirer);
                }
            });
            this.acquirers = unzerAcquires;
        }
    }

    public checkAnyCardAcquirerActive(): boolean {
        let activeCount = 0;
        if (!this.acquirerService.acquirers) {
            return false;
        }
        const card_acquirer = ['bambora', 'clearhaus', 'elavon', 'handelsbanken', 'nets', 'teller', 'swedbank', 'valitor'];

        Object.keys(this.acquirerService.acquirers).forEach(key => {
            if (!this.acquirerService.acquirers) {
                return;
            }
            if (card_acquirer.includes(key) && key !== 'nets' && this.acquirerService.acquirers[key as keyof Acquirers].active) {
                activeCount++;
            }
        });

        return activeCount > 1;
    }

    public isCardAcquirer(acquirer: AcquirerListItem): boolean {
        return acquirer.displayData.card_acquirer;
    }

    public isOtherAcquirer = (acquirer: AcquirerListItem): boolean => {
        return !acquirer.displayData.card_acquirer;
    };
}
