import { Application } from '@models/clearhaus-application/application';
import { BankAccount } from '@models/clearhaus-application/bank-account';
import { Base64 } from '@services/base64/base64';
import { ClearhausApplication } from '@models/clearhaus-application/ch-instant';
import { ClearhausClient } from './clearhaus-client';
import { ClearhausContract } from '@models/clearhaus-application/ch-contract';
import { ClearhausInfo } from '@models/acquirers/clearhaus-info';
import { Company } from '@models/clearhaus-application/company';
import { Contact } from '@models/clearhaus-application/contact';
import { DirectorFile, VisaAgreement } from '@models/clearhaus-application/visa-agreement';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Person } from '@models/clearhaus-application/person';
import { ServerClient } from './server-client';
import { Website } from '@models/clearhaus-application/website';

@Injectable({
    providedIn: 'root'
})
export class ClearhausRepo {
    constructor(
        private base64: Base64,
        private client: ServerClient,
        private clearhausClient: ClearhausClient
    ) {}

    public getAccount(apiKey: string): Observable<ClearhausInfo> {
        const config: any = {};
        config.headers = {
            Authorization: 'Basic ' + this.base64.encodeUnicode(apiKey + ':')
        };

        return this.clearhausClient.get<ClearhausInfo>('/account', config).pipe(
            map((item: any) => Object.assign(new ClearhausInfo(), item)
        ));
    }

    // Instant application endpoints
    public createInstantApplication(application: ClearhausApplication): Observable<ClearhausApplication> {
        return this.client.post<ClearhausApplication>('/clearhaus/application/instant', application).pipe(
            map((item: ClearhausApplication) => Object.assign(new ClearhausApplication(), item))
        );
    }

    public getContract(): Observable<ClearhausContract> {
        return this.client.get<ClearhausContract>('/clearhaus/contract').pipe(
            map((item: ClearhausContract) => Object.assign(new ClearhausContract(), item))
        );
    }

    public getContractPdf(ch_mid: string): Observable<Blob> {
        return this.client.get('/clearhaus/contract.pdf?ch_mid=' + ch_mid, { responseType: 'blob' });
    }

    public signContract(): Observable<ClearhausContract> {
        return this.client.put<ClearhausContract>('/clearhaus/contract', {}).pipe(
            map((item: ClearhausContract) => Object.assign(new ClearhausContract(), item))
        );
    }

    public getApiKey(): Observable<any> {
        return this.client.get<any>('/clearhaus/contract/api-key', {});
    }

    // Standart application endpoints
    public createApplication(application: Application | any): Observable<Application> {
        return this.client.post<Application>('/clearhaus/application', application).pipe(
            map((item: Application) => Object.assign(new Application(), item))
        );
    }

    public getApplication(): Observable<Application> {
        return this.client.get<Application>('/clearhaus/application').pipe(
            map((item: Application) => Object.assign(new Application(), item))
        );
    }

    public updateApplication(application: Application | any): Observable<Application> {
        return this.client.patch<Application>('/clearhaus/application', application).pipe(
            map((item: Application) => Object.assign(new Application(), item))
        );
    }

    public submitApplication(): Observable<any> {
        return this.client.put<any>('/clearhaus/application/submit', {}).pipe(
            map((item: Application) => Object.assign(new Application(), item))
        );
    }

    public getBankAccount(): Observable<BankAccount> {
        return this.client.get<BankAccount>('/clearhaus/application/bank-account');
    }

    public patchBankAccount(bankAccount: BankAccount): Observable<BankAccount> {
        return this.client.patch<BankAccount>('/clearhaus/application/bank-account', bankAccount);
    }

    public getContact(): Observable<Contact> {
        return this.client.get<Contact>('/clearhaus/application/contact');
    }

    public patchContact(contact: Contact): Observable<Contact> {
        return this.client.patch<Contact>('/clearhaus/application/contact', contact);
    }

    public getCompany(): Observable<Company> {
        return this.client.get<Company>('/clearhaus/application/company');
    }

    public patchCompany(company: Company): Observable<Company> {
        return this.client.patch<Company>('/clearhaus/application/company', company);
    }

    public getPeople(): Observable<Person[]> {
        return this.client.get<Person[]>('/clearhaus/application/company/people');
    }

    public createPerson(person: Person): Observable<Person> {
        return this.client.post<Person>('/clearhaus/application/company/people', person);
    }

    public updatePerson(person: Person): Observable<Person> {
        return this.client.patch<Person>('/clearhaus/application/company/people/' + person.id, person);
    }

    public destroyPerson(id: string): Observable<Person> {
        return this.client.delete<Person>('/clearhaus/application/company/people/' + id);
    }

    public createCompanyFile(file: DirectorFile): Observable<DirectorFile> {
        return this.client.post<DirectorFile>('/clearhaus/application/company/files', file);
    }

    public getCompanyFiles(): Observable<DirectorFile[]> {
        return this.client.get<DirectorFile[]>('/clearhaus/application/company/files');
    }

    public createPersonFile(id: string, file: DirectorFile): Observable<DirectorFile> {
        return this.client.post<DirectorFile>('/clearhaus/application/company/people/' + id + '/files', file);
    }

    public getPersonFiles(id: string): Observable<DirectorFile[]> {
        return this.client.get<DirectorFile[]>('/clearhaus/application/company/people/' + id + '/files');
    }

    public createFile(file: DirectorFile): Observable<DirectorFile> {
        return this.client.post<DirectorFile>('/clearhaus/application/files', file);
    }

    public getAllFiles(): Observable<DirectorFile[]> {
        return this.client.get<DirectorFile[]>('/clearhaus/application/files');
    }

    public destroyFile(id: string): Observable<DirectorFile> {
        return this.client.delete<DirectorFile>('/clearhaus/files/' + id);
    }

    public destroyPersonFile(id: string): Observable<DirectorFile> {
        return this.client.delete('/clearhaus/application/destroy_file/' + id);
    }

    public createWebsite(website: Website): Observable<Website> {
        return this.client.post<Website>('/clearhaus/application/websites', website);
    }

    public getAllWebsites(): Observable<Website[]> {
        return this.client.get<Website[]>('/clearhaus/application/websites');
    }

    public updateWebsite(website: Website): Observable<Website> {
        return this.client.patch<Website>('/clearhaus/application/websites/' + website.id, website);
    }

    public destroyWebsite(id: string): Observable<Website> {
        return this.client.delete<Website>('/clearhaus/application/websites/' + id);
    }

    public createDownloadLink(id: string): Observable<any> {
        return this.client.post<any>('/clearhaus/files/' + id + '/download', {}).pipe();
    }

    public createUploadLink(id: string): Observable<any> {
        return this.client.post<any>('/clearhaus/files/' + id + '/upload', {});
    }

    // New activate account flow here !!!

    public getClearhausApplication(): Observable<VisaAgreement> {
        return this.client.get<VisaAgreement>('/clearhaus/application/get').pipe(
            map((item: VisaAgreement) => Object.assign(new VisaAgreement(), item))
        );
    }

    public setClearhausApplication(params: VisaAgreement): Observable<VisaAgreement> {
        return this.client.post<VisaAgreement>('/clearhaus/application/set', params).pipe(
            map((item: VisaAgreement) => Object.assign(new VisaAgreement(), item))
        );
    }
}
