import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'qp-spinner-button',
    styleUrls: ['./spinner.scss'],
    templateUrl: 'spinner.html'
})
export class SpinnerButtonComponent {
    @Input() public showSpinner: boolean;
    @Input() public classes: string;
    @Input() public color: string;
    @Input() public btnText: string;
    @Input() public disabled: boolean;
    @Input() public tooltip: string;
    @Input() public icon = '';

    @Output() public btnClick: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    public clickBtn(): void {
        this.btnClick.emit();
    }
}
