import { Component, OnInit } from '@angular/core';
import { Invoice } from '@models/invoice';
import { LinesEntity, MerchantSpecification } from '@models/merchant-specification';
import { Animations } from 'app/animations/animations';
import { DownloaderService } from '@services/downloader/downloader.service';
import { CreditNoteRepo } from 'app/endpoints/invoice/credit-note-repo';
import { InvoiceRepo } from 'app/endpoints/invoice/invoice-repo';
import { MerchantSpecificationRepo } from 'app/endpoints/invoice/merchant-specification-repo';
import { Observable } from 'rxjs';
import { Params } from '@interfaces/params';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'qp-invoice',
    templateUrl: './invoice.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class InvoiceComponent implements OnInit {
    public loading: boolean;
    public isLoadingResults: boolean;
    public invoice: Invoice;
    public pageNumber: number;
    public pageSize: number;
    public sort: string;
    public merchantID: string;
    public merchantSpecification: MerchantSpecification;
    public invoiceDataSource = new MatTableDataSource<LinesEntity>();
    public dataSource = new MatTableDataSource();

    public invoiceDisplayColumns: Array<string> = ['description', 'quantity', 'amount'];
    public specificationsDisplayColumns: Array<string> = ['merchantID_shop_name', 'currency', 'total_price', 'actions'];

    constructor(
        private creditNoteRepo: CreditNoteRepo,
        private downloadService: DownloaderService,
        private invoiceRepo: InvoiceRepo,
        private merchantSpecificationRepo: MerchantSpecificationRepo,
        private activatedRoute: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.loading = true;
        this.invoiceRepo.get(this.activatedRoute.snapshot.params.invoice_id).subscribe(invoice => {
            this.invoice = invoice;
            this.invoiceDataSource.data = this.invoice.attributes.order?.lines ?? [];
            this.loading = false;
        });
        this.pageNumber = 1;
        this.pageSize = 10;
        this.sort = 'id';
        this.merchantID = '';

        this.getMerchantSpecifications();
    }

    public previousPage(): void {
        this.pageNumber--;
        this.getMerchantSpecifications();
    }

    public nextPage(): void {
        this.pageNumber++;
        this.getMerchantSpecifications();
    }

    public downloadInvoiceAsPDF(): void {
        let observable: Observable<Blob>;

        if (this.invoice.type === 'invoices') {
            observable = this.invoiceRepo.getPDF(this.invoice.id);
        } else {
            observable = this.creditNoteRepo.getPDF(this.invoice.id);
        }

        observable.subscribe(file => {
            this.downloadService.saveFile(file, 'QuickPayPSP_' + this.invoice.type + '_' + this.invoice.attributes.number + '.pdf');
        });
    }

    public downloadInvoiceAsCSV(): void {
        this.merchantSpecificationRepo.getAllAsCSV(this.invoice.id).subscribe(merchantSpecifications => {
            this.downloadService.saveFile(
                merchantSpecifications,
                'QuickPayPSP_' + this.invoice.type + '_' + this.invoice.attributes.number + '.csv'
            );
        });
    }

    public onSearch(event: any): void {
        this.merchantID = event.search;
        this.pageNumber = 1;
        this.getMerchantSpecifications();
    }

    private getMerchantSpecifications(): void {
        this.isLoadingResults = true;
        const params = {
            page: this.pageNumber,
            page_size: this.pageSize,
            sort_by: this.sort
        } as Params;

        this.merchantSpecificationRepo.getAll(
            this.activatedRoute.snapshot.params.invoice_id,
            params,
            this.merchantID
        ).subscribe((merchantSpecifications: Array<MerchantSpecification>) => {
            this.dataSource.data = merchantSpecifications;
            this.isLoadingResults = false;
        });
    }
}
