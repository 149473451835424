<form [formGroup]="directorForm" class="flex-col rg8">
    <span class="text-grey" i18n>Contact information for the Director of the company</span>

    <mat-form-field>
        <mat-label i18n>Full name</mat-label>
        <input matInput name="director_name" formControlName="name" type="text" (change)="modelDirector('name', $event.target)" required />
        <mat-error *ngIf="name.errors?.required" i18n>A name is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Social security number</mat-label>
        <input matInput name="director_ssn" formControlName="social_security_number" type="text" (change)="modelDirector('social_security_number', $event.target)" required />
        <mat-error *ngIf="social_security_number.errors?.required" i18n>A social security number is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label><span i18n>Date of birth</span> (YYYY-MM-DD)</mat-label>
        <input #directorDateInput formControlName="date_of_birth" matInput name="director_date_of_birth" required type="text" [matDatepicker]="directorDatePicker" [max]="maxDate" [min]="minDate" (dateChange)="handleDateChange($event.value)" />
        <mat-datepicker-toggle matSuffix [for]="directorDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #directorDatePicker startView="multi-year"></mat-datepicker>
        <mat-error *ngIf="date_of_birth.errors" i18n>A valid date of birth is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Address</mat-label>
        <input matInput name="director_address_line_1" formControlName="address_line_1" type="text" (change)="modelDirector('address_line_1', $event.target)" required />
        <mat-error *ngIf="address_line_1.errors?.required" i18n>An address is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Zip code</mat-label>
        <input matInput name="director_zipcode" formControlName="zipcode" type="text" (change)="modelDirector('zipcode', $event.target)" required />
        <mat-error *ngIf="zipcode.errors?.required" i18n>A Zip code is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>City</mat-label>
        <input matInput name="director_city" formControlName="city" type="text" (change)="modelDirector('city', $event.target)" required />
        <mat-error *ngIf="city.errors?.required" i18n>A city is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Country</mat-label>
        <mat-select name="director_country" formControlName="country" (selectionChange)="modelDirector('country', $event)" required>
            <mat-option *ngFor="let country of countries" [value]="country.code">
                {{ country.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="country.errors?.required" i18n>A country is required.</mat-error>
    </mat-form-field>

    <span class="text-grey" i18n>Upload official documents with the Directors photo and address.</span>

    <mat-form-field>
        <mat-label i18n>Picture ID</mat-label>
        <ngx-mat-file-input #directorPictureIDInput name="director_pictureID" formControlName="pictureID" required accept="application/pdf,image/*"> </ngx-mat-file-input>
        <mat-icon matSuffix>note_add</mat-icon>
        <mat-hint i18n>Examples: passport, driver's license</mat-hint>
        <mat-error *ngIf="pictureID.errors?.required" i18n>A Picture ID is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Address ID</mat-label>
        <ngx-mat-file-input #directorAddressIDInput name="director_addressID" formControlName="addressID" required accept="application/pdf,image/*"> </ngx-mat-file-input>
        <mat-icon matSuffix>note_add</mat-icon>
        <mat-hint i18n>Examples: utility bill</mat-hint>
        <mat-error *ngIf="addressID.errors?.required" i18n>An Address ID is required.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="directorForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
