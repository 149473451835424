interface Attributes {
    customer_id?: number;
    number?: string;
    amount?: number;
    amount_without_vat?: number;
    currency?: string;
    due_at?: string;
    paid_at?: string;
    payment_failed_at?: null;
    order?: Order;
    manual?: boolean;
}
interface Order {
    lines?: LinesEntity[] | null;
}
interface LinesEntity {
    text: string;
    description: string;
    quantity: number;
    unit: string;
    unit_price: number;
    tax_rate: number;
}

export class Invoice {
    public id: string;
    public type: 'credit-notes' | 'invoices';
    public attributes: Attributes;
    public status?: string;
}
