import { AclPermission } from '@interfaces/acl-permission';
import { ApiClient } from './api-client';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AclResourcesRepo {
    constructor(private client: ApiClient) {}

    public getAll(account_type: string): Observable<AclPermission[]> {
        const config = {
            params: {
                account_type,
                page_size: 100
            }
        };

        return this.client.get<AclPermission[]>('/acl-resources', config).pipe(
            map((permissions: AclPermission[]) => permissions.sort((a: AclPermission, b: AclPermission) => {
                if (!a.resource || !b.resource) {
                    return 0;
                }
                return a.resource < b.resource ? -1 : a.resource > b.resource ? 1 : 0;
            })
        ));
    }
}
