import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceClient } from './invoice-client';
import { PaymentLinks } from '@models/payment-link';

@Injectable({
    providedIn: 'root'
})
export class PaymentLinkRepo {
    constructor(
        private client: InvoiceClient
    ) {}

    public create(paymentLink: PaymentLinks): Observable<PaymentLinks> {
        return this.client.post<PaymentLinks>('/payment-links', paymentLink);
    }
}
