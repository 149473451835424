<div class="flex-row">
    <div *ngIf="!validityOk" class="flex-col rg8">
        <span i18n>Some information is missing.</span><br />
        <span i18n>Make sure all steps are filled out correctly in order to send the application.</span><br /><br />

        <span *ngIf="!validity.contact" class="text-darkred" i18n>Something went wrong: Contact information.</span>
        <span *ngIf="!validity.company" class="text-darkred" i18n>Something went wrong: Company information.</span>
        <span *ngIf="!validity.director" class="text-darkred" i18n>Something went wrong: Director.</span>
        <span *ngIf="!validity.ownership" class="text-darkred" i18n>Something went wrong: Ownership.</span>
        <span *ngIf="!validity.websites" class="text-darkred" i18n>Something went wrong: Websites.</span>
        <span *ngIf="!validity.business" class="text-darkred" i18n>Something went wrong: Business model.</span>
        <span *ngIf="!validity.bank" class="text-darkred" i18n>Something went wrong: Bank Account.</span>
        <span *ngIf="this.company.country !== 'GBR' && this.company.form !== 'Other' && !this.validity.documentation" class="text-darkred" i18n>Something went wrong: Documentation.</span>
    </div>
    <span *ngIf="submitted" class="text-darkred">
        <span i18n>Application state</span>:&nbsp;{{ application.metadata?.state }}<br />
        <span i18n>This state is preventing you from sending the application again.</span><br />
        <span i18n>Go to <a href="https://www.clearhaus.com" target="_blank">https://www.clearhaus.com</a> to make new changes.</span>
    </span>
    <qp-spinner-button *ngIf="!submitted" color="accent" [disabled]="disableSubmit" [showSpinner]="saving" btnText="Send application" (btnClick)="send()" i18n-btnText> </qp-spinner-button>
</div>
