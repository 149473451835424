import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, signal } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionGroupRepo } from '@endpoints/api/subscription-group-repo';
import { Mixin } from '@helpers/decorators/mixins.decorator';
import { HttpBadRequestErrorResponse, catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { RegExpNoNumbers } from '@helpers/reg-exp/reg-exp-numbers';
import { FilterParams, SearchParams, TransactionParams } from '@interfaces/params';
import { Card } from '@models/card';
import { Payment } from '@models/payment';
import { Payout } from '@models/payout';
import { Subscription } from '@models/subscription';
import { Transaction, TransactionSortables, TransactionType } from '@models/transaction';
import { ParamService } from '@services/param/params.service';
import { SearchService } from '@services/search/search.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { FilterService } from '@widgets/filters/filters.service';
import { FiltersMixin } from '@widgets/filters/mixins/filters.mixin';
import { KeySetPageChangeEvent } from '@widgets/keyset-paginator/keyset-paginator-event.interface';
import { KeySetPaginatorOptions } from '@widgets/keyset-paginator/keyset-paginator-options.interface';
import { Animations } from 'app/animations/animations';
import { Observable, Subscription as RxSubscription, of } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { TransactionsService } from './transactions.service';

@Component({
    selector: 'qp-transactions',
    templateUrl: './transactions.component.html',
    animations: [Animations.getFadeAnimation()]
})
@Mixin([FiltersMixin])
export class TransactionsComponent extends BaseLoadingDirective implements OnInit, FiltersMixin {
    public showFilterDialog: (str: string) => void;
    public resetFilter: (str: string) => void;

    public data_source = new MatTableDataSource<Card | Payment | Payout | Subscription>();
    public search_params: SearchParams = {};
    public filters: FilterParams = {};

    public selected_transactions = new SelectionModel<Card | Payment | Payout | Subscription>(true, []);
    public paginator_options: KeySetPaginatorOptions = {
        label: '',
        page_size: 10,
        sort_by: 'id',
        sort_dir: 'desc',
        page_key: '',
        page_size_options: [10, 100]
    };
    public transaction_type: TransactionType;
    public transaction_columns: string[] = [];

    public loading_results = signal<boolean>(false);
    public show_search = false;

    public is_previous = signal<boolean>(false);
    public request_subscription = new RxSubscription();
    public search_id_state: string | undefined;
    public init_state: string;
    public states: Array<string>;

    private readonly paginator_label_map = {
        cards: $localize`Cards per page`,
        payments: $localize`Payments per page`,
        payouts: $localize`Payouts per page`,
        subscriptions: $localize`Subscriptions per page`
    };

    constructor(
        private paramService: ParamService,
        private activatedRoute: ActivatedRoute,
        public filtersService: FilterService,
        public searchService: SearchService,
        private dialogService: DialogService,
        private transactionsService: TransactionsService,
        private subscriptionGroupRepo: SubscriptionGroupRepo
    ) {
        super();
    }

    public ngOnInit(): void {
        this.setTransactionType();
        this.setPaginatorLabel(this.paginator_label_map[this.transaction_type]);
        this.setStates();
        this.initSearchParams();
        this.setFilters();
        this.getPage();
    }

    public setTransactionType(): void {
        this.transaction_type = this.activatedRoute.snapshot.data.transaction_type;
        this.transaction_columns = this.activatedRoute.snapshot.data.columns;
    }

    public initSearchParams(): void {
        let params = this.paramService.returnPrevParam();
        const states = this.states;
        params = { state: this.init_state };

        const search_params = Object.entries(params).length !== 0 ? { ...params, states } : { states };
        this.searchService.handleSearch(search_params);
        this.search_params = this.searchService.getSearchParams();

        if (this.search_params?.search) {
            this.search_params.auto_focus = true;
        }
    }

    private setFilters(): void {
        this.filters = {
            acquirer: this.activatedRoute.snapshot.queryParams.acquirer || null,
            max_time: this.activatedRoute.snapshot.queryParams.max_time ?
                new Date(decodeURIComponent(this.activatedRoute.snapshot.queryParams.max_time)) : undefined,
            min_time: this.activatedRoute.snapshot.queryParams.min_time ?
                new Date(decodeURIComponent(this.activatedRoute.snapshot.queryParams.min_time)) : undefined,
            suspected_fraud: this.activatedRoute.snapshot.queryParams.suspected_fraud || null,
            timestamp: this.activatedRoute.snapshot.queryParams.timestamp || null
        };

        if (this.transaction_type === 'subscriptions') {
            this.filters.expired = this.activatedRoute.snapshot.queryParams.expired || 'false';
            this.filters.subscription_group = this.activatedRoute.snapshot.queryParams.subscription_group || undefined;
            if (this.activatedRoute.snapshot.queryParams.subscription_group) {
                this.subscriptionGroupRepo.get(this.activatedRoute.snapshot.queryParams.subscription_group).subscribe({
                    next: (group) => this.filters.subscription_group = group,
                    error: () => this.filters.subscription_group = undefined
                });
            }
        }
    }

    public getPage(): void {
        this.setPaginatorPageSize(this.search_params.page_size);
        this.request_subscription.unsubscribe();
        this.request_subscription = this.loadTransactions().subscribe();
    }

    public getPageFromTable(options: SearchParams): void {
        this.is_previous.set(false);
        this.paginator_options = {
            ...this.paginator_options,
            sort_dir: options.sort_dir ?? 'desc',
            sort_by: (options.sort_by ?? 'id') as any,
            page_key: '',
            page_size: this.search_params.page_size || 10
        };
        this.search_params.sort_by = options.sort_by;
        this.search_params.sort_dir = options.sort_dir;
        this.search_params.page = options.page;
        this.request_subscription.unsubscribe();
        this.request_subscription = this.loadTransactions().subscribe();
    }

    public setPaginatorPageSize(page_size = 10): void {
        this.paginator_options = {
            ...this.paginator_options,
            page_size
        };
    }

    public setPaginatorLabel(label: string): void {
        this.paginator_options = {
            ...this.paginator_options,
            label
        };
    }

    public onKeySetPageChange(event: KeySetPageChangeEvent): void {
        this.search_params.page_size = event.page_size;
        this.is_previous.set(event.is_previous);
        this.paginator_options = {
            ...this.paginator_options,
            page_key: event.page_key,
            sort_dir: event.sort_dir
        };
        this.request_subscription.unsubscribe();
        this.request_subscription = this.loadTransactions().subscribe();
    }

    public getParams(): TransactionParams {
        const params: any = {
            page_size: this.paginator_options.page_size || 10,
            page_key: this.paginator_options.page_key ?? '',
            sort_by: this.search_params.sort_by,
            sort_dir: this.paginator_options.sort_dir
        };

        if (this.search_params.search) {
            if (this.search_params.search_by === 'id') {
                params.id = this.search_params.search;
            } else {
                params.order_id = this.search_params.search;
            }
        }

        if (this.transaction_type !== 'cards' && this.search_params.states && this.search_params.states[this.search_params.state] !== 'all') {
            params.state = this.search_params.states ? this.search_params.states[this.search_params.state] : '';
        }

        if (this.filters.subscription_group) {
            params.group_id = this.filters.subscription_group?.id ? this.filters.subscription_group.id : this.filters.subscription_group ?? '';
        }
        if (!!this.filters.expired) {
            params.expired = this.filters.expired;
        }
        if (this.filters.min_time) {
            params.min_time = this.filters.min_time;
        }
        if (this.filters.max_time) {
            params.max_time = this.filters.max_time;
        }
        if (this.filters.timestamp) {
            params.timestamp = this.filters.timestamp;
        }
        if (this.filters.acquirer) {
            params.acquirer = this.filters.acquirer.toLowerCase();
        }
        if (this.filters.suspected_fraud) {
            params.fraud_suspected = true;
        }

        this.searchService.setFilterParams(this.filters);
        return { ...params };
    }

    public onSearch($event: SearchParams): void {
        this.search_params = this.setSearchParams(undefined, $event.search_by, $event.search);

        this.setPaginatorPageSize(this.search_params.page_size);
        this.request_subscription.unsubscribe();
        this.request_subscription = this.loadTransactions().subscribe();
    }

    public onTabChange(data: { state: number }): void {
        this.search_params = this.setSearchParams(data.state);
        this.paramService.setParams({ state: data.state }, this.activatedRoute);

        if (this.search_params.search_by === 'id' && this.search_params.search?.match(new RegExpNoNumbers())) {
            this.paramService.setParams({ search: '' }, this.activatedRoute);
            this.data_source.data = [];
        } else {
            this.setPaginatorPageSize();
        }
        this.request_subscription.unsubscribe();
        this.request_subscription = this.loadTransactions().subscribe();
    }

    private setSearchParams(state?: number, search_by?: string, search?: string, page?: number): SearchParams {
        const state_param = (typeof state === 'number' ? state : this.states.length - 1);
        return this.searchService.setSearchParams({ state: state_param, page, search_by, search });
    }

    private loadTransactions(): Observable<Transaction[]> {
        this.loading_results.set(true);
        const params = this.getParams();
        let request: () => Observable<Transaction[]>;

        switch (this.transaction_type) {
            case 'payments':
                request = (): Observable<Payment[]> => this.transactionsService.searchPayments(params);
                break;
            case 'payouts':
                request = (): Observable<Payout[]> => this.transactionsService.searchPayouts(params);
                break;
            case 'subscriptions':
                request = (): Observable<Subscription[]> => this.transactionsService.searchSubscriptions(params);
                break;
            case 'cards':
                request = (): Observable<Card[]> => this.transactionsService.searchCards(params);
                break;
            default:
                request = (): Observable<Transaction[]> => of([]);
        }

        return request().pipe(
            catchHttpStatusError([HttpStatusCode.NotFound], (_error: HttpErrorResponse) => {}),
            catchHttpStatusError([HttpStatusCode.BadRequest], (error: HttpBadRequestErrorResponse) => {
                this.dialogService.alert(error.statusText, error.error, 'warning');
            }),
            filter((result) => !!result),
            map((result: any) => this.handlePaginationRequest(result, params)),
            tap((result) => this.store_data(result)),
            finalize(() => this.loading_results.set(false))
        );
    }

    private handlePaginationRequest(transactions: Transaction[], params: SearchParams): any {
        if (!this.is_previous()) {
            return transactions;
        }

        const first_key = transactions[transactions.length - 1][params.sort_by as keyof TransactionSortables];

        if (params.sort_dir === 'desc') {
            switch (params.sort_by) {
                case 'id':
                    const first_key_id = first_key as number;
                    this.paginator_options.page_key = first_key_id + 1;
                    break;
                case 'order_id':
                    const first_key_order_id = first_key as string;
                    this.paginator_options.page_key = first_key_order_id.slice(0, -1) +
                        String.fromCharCode(first_key_order_id.charCodeAt(first_key_order_id.length - 1) + 1);
                    break;
                case 'created_at':
                    const first_key_created_at = first_key as string;
                    this.paginator_options.page_key = new Date(new Date(first_key_created_at).getTime() + 1).toISOString();
                    break;
            }
        } else {
            switch (params.sort_by) {
                case 'id':
                    const first_key_id = first_key as number;
                    this.paginator_options.page_key = first_key_id - 1;
                    break;
                case 'order_id':
                    const first_key_order_id = first_key as string;
                    this.paginator_options.page_key = first_key_order_id.slice(0, -1) +
                        String.fromCharCode(first_key_order_id.charCodeAt(first_key_order_id.length - 1) - 1);
                    break;
                case 'created_at':
                    const first_key_created_at = first_key as string;
                    this.paginator_options.page_key = new Date(new Date(first_key_created_at).getTime() - 1).toISOString();
                    break;
            }
        }

        return transactions.reverse();
    }

    private store_data(data: any): void {
        this.data_source.data = data;
        if (this.isIdTab() && data.length === 1 && this.search_params.search_by === 'id' && ('state' in data[0])) {
            this.search_id_state = data[0].state;
        } else if (!this.isIdTab() && (typeof this.search_id_state === 'string')) {
            this.data_source.data = [];
        } else {
            this.search_id_state = undefined;
        }
    }

    private isIdTab(): boolean {
        return this.states[this.search_params.state] === 'all' || this.search_id_state === this.states[this.search_params.state];
    }

    private setStates(): void {
        switch (this.transaction_type) {
            case 'cards':
                this.init_state = 'none';
                this.states = ['none'];
                break;
            case 'payments':
                this.init_state = 'new';
                this.states = ['initial', 'new', 'processed', 'pending', 'rejected', 'all'];
                break;
            case 'payouts':
                this.init_state = 'processed';
                this.states = ['initial', 'pending', 'rejected', 'processed', 'all'];
                break;
            case 'subscriptions':
                this.init_state = 'active';
                this.states = ['initial', 'pending', 'active', 'cancelled', 'rejected', 'all'];
                break;
        }
    }
}
