import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Customer } from '@models/customer';
import { Plan } from '@models/plan';
import { QpAccountTypePipe } from '@pipes/qp-account-type.pipe';
import { QpAccountValidPipe } from '@pipes/qp-account-valid.pipe';
import { AgreementService } from '@services/agreement/agreement.service';
import { Animations } from 'app/animations/animations';
import { CustomerRepo } from 'app/endpoints/invoice/customer-repo';
import { PlanRepo } from 'app/endpoints/invoice/plan-repo';

@Component({
    selector: 'qp-billing',
    templateUrl: './billing.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class BillingComponent implements OnInit {
    public account = this.agreementService.agreement?.account;
    public owner = !!this.agreementService.agreement?.owner;
    public is_clearhaus = this.account?.default_payment_methods === 'clearhaus';
    public loading = true;
    public is_wizard_visible = false;
    public customer?: Customer;
    public plan?: Plan;

    constructor(
        private customerRepo: CustomerRepo,
        private planRepo: PlanRepo,
        private agreementService: AgreementService
    ) {}

    public isWizardVisible(): boolean {
        return new QpAccountTypePipe(this.agreementService).transform(['Merchant']) &&
            !new QpAccountValidPipe().transform(this.account) &&
            (!this.has_plan || !this.customer?.id) &&
            !!this.owner &&
            !this.is_clearhaus;
    }

    public ngOnInit(): void {
        if (!this.owner) {
            return;
        }

        this.getCustomer();

        if (this.account?.type === 'Merchant') {
            this.planRepo.getMerchantPlan(this.account.id ?? NaN)
                .pipe(
                    tap((plan) => {
                        this.plan = plan;
                        this.is_wizard_visible = this.isWizardVisible();
                        if (!this.is_wizard_visible) {
                            this.updatePlan();
                        }
                    })
                )
                .subscribe();
        }
    }

    public finishWizard(plan: Plan): void {
        this.plan = plan;
        this.getCustomer();
        this.is_wizard_visible = false;
    }

    public getCustomer(): void {
        this.customerRepo.get({
            'filter[account_id]': this.account?.id,
            'filter[account_type]': this.account?.type
        }).subscribe((data: Customer | null) => {
            if (data) {
                this.customer = data;
            } else {
                this.customer = new Customer();
                this.customer.type = 'customers';
                this.customer.attributes = {
                    invoice_emails: [],
                    receive_headsup_email: true,
                    billing_info: {}
                };
            }
            if (this.customer.attributes.billing_info === null) {
                this.customer.attributes.billing_info = {};
            }
            if (this.customer?.attributes?.billing_info?.payment_method === 'clearhaus') {
                this.is_clearhaus = true;
            }
            this.loading = false;
        });
    }

    public updatePlan(): void {
        if (this.has_plan) {
            return;
        }
        this.defaultPlanSelected();
    }

    public defaultPlanSelected(): void {
        this.createDefaultPlan().subscribe(plan => this.plan = plan);
    }

    public createDefaultPlan(): Observable<Plan> {
        return this.planRepo.createMerchantPlan({
            type: 'merchant-plans',
            attributes: {
                tier: 'merchant'
            }
        });
    }

    private get has_plan(): boolean {
        return !!this.plan?.id;
    }
}
