import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ScreenSizeService } from '@services/screen-size/screen-size.service';

import { SearchFieldComponent } from '@widgets/searchfield/base/searchfield.component';

@Component({
    selector: 'qp-search-field-small',
    templateUrl: './search-field-small.html',
    styleUrls: ['./search-field-small.scss']
})
export class SearchFieldSmallComponent extends SearchFieldComponent implements OnInit, AfterContentInit {
    @ViewChild('searchEle', { static: true }) public searchEle: ElementRef | null;
    @ViewChild('searchSmallEle', { static: true }) public searchSmallEle: ElementRef | null;
    @Input() public clearSection = false;
    @Input() public clearText: string;
    @Input() public inputClass: string;
    @Input() public wrapperClass: string;
    @Input() public searchIconClass: string;

    public searchTimeout: NodeJS.Timeout;

    constructor(
        focusMonitor: FocusMonitor,
        media: ScreenSizeService
    ) {
        super(focusMonitor, media);
    }

    public ngAfterContentInit(): void {
        if (this.autoFocus) {
            this.focusSearchField();
        }
    }

    public ngOnInit(): void {
        this.searchId = this.uniqueNumber().toString();
        this.focus = this.autoFocus;
    }

    public selectSearchOption(option: any): void {
        clearTimeout(this.timeout);
        this.searchBy = option.value;
        this.invokeSearch();
        this.focusSearchField();
    }

    public isScreenWidthBetween600pxAnd959px(): boolean {
        return window.innerWidth >= 600 && window.innerWidth <= 959;
    }

    public focusSearchField(): void {
        const smallScreen = this.media.isActive('sm') || this.media.isActive('xs');

        if (smallScreen) {
            if (this.showSearch !== true) {
                this.showSearch = true;
            }
            if (this.searchSmallEle) {
                this.focusMonitor.focusVia(this.searchSmallEle.nativeElement, 'program');
            }
        } else if (this.searchEle) {
            this.focusMonitor.focusVia(this.searchEle.nativeElement, 'program');
        }
    }

    public clearsearch(): void {
        this.search = '';
        this.invokeSearch();
        this.focusSearchField();
    }

    public toggleSearch(): void {
        if (this.showSearch && this.search) {
            this.search = '';
            this.invokeSearch();
        }
        this.showSearch = !this.showSearch;
        if (this.showSearch) {
            this.focusSearchField();
        }
    }

    public searchChanged(): void {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.invokeSearch();
        }, 1000);
    }
}
