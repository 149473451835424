import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { Settlement } from '@models/clearhaus-settlement';
import { SettlementRepo } from '@endpoints/server/settlement-repo';
import { LoadingState } from '@helpers/loading/loading-state';
import { finalize, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qp-settlement-details',
    templateUrl: 'settlement-details.component.html',
    styleUrls: ['settlement-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Animations.getFadeAnimation()]
})
export class SettlementDetailsComponent extends BaseLoadingDirective implements OnInit {
    public settlement: Settlement;
    public settlement_id = '';
    public previousParams: any;
    public displayHintBox: boolean;

    public state = 1;

    public loading_state = new LoadingState();

    constructor(
        private settlementRepo: SettlementRepo,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    public ngOnInit(): void {
        this.settlement_id = this.activatedRoute.snapshot.params.settlement_id;
        this.getSettlement();
    }

    public getSettlement(): void {
        this.loading_state.start();
        this.settlementRepo.getSettlementByID(this.settlement_id).pipe(
            finalize(() => this.loading_state.stop()),
            tap(settlement => this.settlement = settlement)
        ).subscribe();
    }
}
