<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="dispute && !loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../../disputes']" [queryParams]="previousParams" class="mat-button-back" color="accent">
            &lt; <span i18n>Back to Disputes</span>
        </button>
    </div>

    <div class="flex-row-mobile-col" id="print-section">
        <div class="flex-35">
            <mat-card class="card02">
                <mat-card-content class="flex-col rg16">
                    <h5 i18n>Dispute details</h5>
                    <div class="flex-row">
                        <span class="text-grey flex-30" i18n>Status</span>
                        <span *ngIf="dispute.status === 'won'" class="text-green" i18n>won</span>
                        <span *ngIf="dispute.status === 'pen'" class="text-green" i18n>open</span>
                        <span *ngIf="dispute.status === 'lost'" class="text-red" i18n>Lost</span>
                        <span *ngIf="dispute.status === 'refuted'" class="text-yellow" i18n>refuted</span>
                        <span *ngIf="dispute.status === 'under_review'" class="text-yellow" i18n>under review</span>
                    </div>
                    <div class="flex-row">
                        <span class="text-grey flex-30" i18n>Reference</span>
                        <span>{{ dispute.reference }}</span>
                    </div>

                    <div class="flex-row">
                        <span class="text-grey flex-30" i18n>Opened at</span>
                        <span>{{ dispute.opened_at | date: 'dd MMM yyyy' }}</span>
                    </div>
                    <div class="flex-row">
                        <span class="text-grey flex-30" i18n>Due at</span>
                        <span>{{ dispute.due_at | date: 'dd MMM yyyy' }}</span>
                    </div>
                    <div class="flex-row">
                        <span class="text-grey flex-30" i18n>Amount</span>
                        <span>{{ dispute.amount | qpCurrency: dispute.currency }} {{ dispute.currency }}</span>
                    </div>

                    <div class="flex-row cg16" *ngIf="dispute.status === 'open'">
                        <button mat-raised-button color="accent" (click)="addStamp('accepted')" i18n>
                            ACCEPT
                        </button>
                        <button mat-raised-button class="text-darkred" (click)="addStamp('refuted')" i18n>
                            REFUTE
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="card02" *ngIf="(['Administrator'] | qpAccountType)">
                <mat-card-content>
                    <h5 i18n>Documentation</h5>
                    <span class="subtitle1" i18n>Required documentation</span>

                    <ul class="flex-col rg8">
                        <li i18n>Customer name and contact information (e.g. email and phone number)</li>
                        <li i18n>Customer communication (e.g. emails, letters and order confirmation)</li>
                        <li *ngIf="dispute.reason === 'subscription_cancelled'" i18n>
                            Shipping address, date, carrier, tracking number and documentation (if a physical product was shipped)
                        </li>
                        <li *ngIf="dispute.reason === 'subscription_cancelled'" i18n>
                            Access log (if a digital product or service was provided)
                        </li>
                        <li *ngIf="dispute.reason === 'subscription_cancelled'" i18n>
                            Cancellation policy and proof of presenting proper cancellation terms to customer
                        </li>
                        <li *ngIf="dispute.reason === 'credit_not_processed'" i18n>
                            Refund policy and proof of refund
                        </li>
                        <li *ngIf="dispute.reason !== 'subscription_cancelled' && dispute.reason !== 'credit_not_processed'" i18n>
                            Invoice copy
                        </li>
                        <li *ngIf="dispute.reason !== 'subscription_cancelled' && dispute.reason !== 'credit_not_processed'" i18n>
                            Service date and documentation (if an offline service was provided)
                        </li>
                        <li *ngIf="dispute.reason !== 'subscription_cancelled' && dispute.reason !== 'credit_not_processed'" i18n>
                            Shipping address, date, carrier, tracking number and documentation (if a physical product was shipped)
                        </li>
                        <li *ngIf="dispute.reason !== 'subscription_cancelled' && dispute.reason !== 'credit_not_processed'" i18n>
                            Access log (if a digital product or service was provided)
                        </li>
                    </ul>

                    <span class="subtitle1" i18n>Upload documentation</span>

                    <qp-file-input
                        i18n-label
                        label="Click here to add files"
                        (file_change)="addFile($event)"
                        [files]="documentation_files"
                        required_filetype="application/pdf,image/*"
                        [delete_files]="false">
                    </qp-file-input>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="flex-1">
            <mat-card class="card02">
                <mat-card-content class="flex-col rg24" *ngIf="!disputeReason">
                    <mat-spinner [diameter]="50"></mat-spinner>
                </mat-card-content>

                <mat-card-content class="flex-col rg24" *ngIf="disputeReason">
                    <div class="flex-col rg8">
                        <h5>{{ disputeReason.title }}</h5>
                        <span *ngIf="dispute.type === '1st_chargeback'" class="text-grey" i18n>1st chargeback</span>
                        <span *ngIf="dispute.type === 'arbitration'" class="text-grey" i18n>Arbitration</span>
                        <span *ngIf="dispute.type === 'retrieval_request'" class="text-grey" i18n>Retrieval request</span>
                        <span *ngIf="dispute.type === '1st_chargeback_reversal'" class="text-grey" i18n>1st chargeback reversal</span>
                        <span *ngIf="dispute.type === '2nd_chargeback'" class="text-grey" i18n>2nd chargeback</span>
                        <span *ngIf="dispute.type === 'representment'" class="text-grey" i18n>Representment</span>
                        <span *ngIf="dispute.type === 'dispute_amount_credit'" class="text-grey" i18n>Dispute amount credit</span>
                        <span *ngIf="dispute.type === 'dispute_amount_debit'" class="text-grey" i18n>Dispute amount debit</span>
                    </div>

                    <div class="flex-col rg8">
                        <span class="subtitle1" i18n>Explanation</span>
                        <span>{{ disputeReason.explanation }}</span>
                    </div>

                    <div class="flex-col rg8">
                        <span class="subtitle1" i18n>How to overturn dispute</span>
                        <span>{{ disputeReason.overturn }}</span>
                        <span>
                            <span i18n>Provide required documentation and refute no later than</span>
                            <span class="subtitle1">{{ dispute.due_at | date: 'dd MMM yyyy' }}</span>.
                            <span i18n>Otherwise this dispute will be considered as accepted and marked as lost automatically.</span>
                        </span>
                    </div>
                </mat-card-content>
            </mat-card>

            <h4 class="margin t40">&nbsp;&nbsp;<span i18n>Timeline</span></h4>

            <mat-card class="card02">
                <mat-card-content class="flex-col rg16">
                    <div class="flex-row space-between items-center">
                        <h5 style="margin-bottom:0px;" i18n>Capture of transaction</h5>
                        <span class="text-grey">{{ dispute.transaction.processed_at | date: 'dd MMM yyyy' }}</span>
                    </div>

                    <div class="flex-col rg12">
                        <div class="flex-row">
                            <span class="text-grey flex-30" i18n>Order ID</span>
                            <span (click)="searchPayment(dispute.reference)" class="link">
                                {{ dispute.transaction.reference }}
                            </span>
                        </div>
                        <div class="flex-row">
                            <span class="text-grey flex-30" i18n>Amount</span>
                            <span>{{ dispute.transaction.amount | qpCurrency: dispute.transaction.currency }} {{ dispute.transaction.currency }}</span>
                        </div>
                        <div class="flex-row">
                            <span class="text-grey flex-30" i18n>3-D Secure</span>
                        </div>
                        <div class="flex-row">
                            <span class="text-grey flex-30" i18n>Text on statement</span>
                            <span>{{ dispute.transaction.text_on_statement }}</span>
                        </div>

                    </div>
                </mat-card-content>
            </mat-card>

            <div *ngFor="let event of timeLine">
                <mat-card class="card02">
                    <mat-card-content class="flex-col rg16">
                        <div class="flex-row space-between items-center" *ngIf="event.type === 'comment'">
                            <span *ngIf="event?.author !== 'Quickpay' && event?.author !== 'Unzer'">
                                <span i18n>Comment by</span> <strong>{{ event.author }}</strong>
                            </span>
                            <span class="text-grey">{{ event?.date | date: 'dd MMM yyyy' }}</span>
                        </div>
                        <span *ngIf="event.type === 'comment'">
                            {{ event.body }}
                        </span>

                        <div class="flex-row space-between items-center" *ngIf="event.type ==='event'">
                            <span>{{ event.body }}</span>
                            <span class="text-grey">{{ event.date | date: 'dd MMM yyyy' }}</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <mat-card class="card02">
                <mat-card-content class="flex-col rg16">
                    <span class="subtitle1" i18n>Add comment</span>
                    <form class="flex-col" #f="ngForm">
                        <mat-form-field class="flex-100" hideRequiredMarker>
                            <mat-label i18n>Message</mat-label>
                            <input matInput
                                    name="comment"
                                    [(ngModel)]="message"
                                    required/>
                        </mat-form-field>
                        <div>
                            <button mat-raised-button
                                    color="accent"
                                    [disabled]="f.invalid"
                                    (click)="addComment(message)" i18n>
                                    Send comment
                            </button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
