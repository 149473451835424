<ng-container *ngIf="settlement">
    <div class="flex-col rg8">
        <span class="text-grey" i81n>Settlement period</span>
        <span class="subtitle1"> {{ settlement.period.start_date | date: 'dd MMM yyyy' }} - {{ settlement.period.end_date | date: 'dd MMM yyyy' }} </span>
    </div>

    <div class="flex-col rg8">
        <span class="text-grey" i18n>Payout on - {{ settlement.payout?.date | date: 'dd MMM yyyy' }}</span>
        <span class="code large-text"> {{ settlement.payout?.amount || 0 | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>

    <div class="flex-col rg8" *ngIf="!!settlement?.reserve">
        <span class="text-grey" i18n>Reserve payout on - {{ settlement.reserve?.date | date: 'dd MMM yyyy' }}</span>
        <span class="code large-text"> {{ settlement.reserve?.amount ?? 0 | qpCurrency: settlement.currency }} {{ settlement.currency }} </span>
    </div>
</ng-container>
