<div class="flex-row">
    <div *ngIf="!applicationSent">
        <qp-spinner-button
            *ngIf="!submitted"
            color="accent"
            [disabled]="disableSubmit"
            [showSpinner]="saving"
            btnText="Send application"
            (btnClick)="send()"
            i18n-btnText
        >
        </qp-spinner-button>
    </div>

    <div *ngIf="applicationSent" class="flex-col rg8">
        <span *ngIf="withError" class="text-yellow" i18n>
            Some information was filled in incorrectly but the application was sent to Clearhaus
        </span>
    </div>

    <div *ngIf="!applicationSent && withError" class="flex-col rg8">
        <span class="text-yellow" i18n>Your application wasn't sent to Clearhaus.</span>
        <span class="text-darkred" i18n>Error {{ errorMsg }} </span>
    </div>
</div>
