<div mat-dialog-title class="flex-row space-between padding t24">
    <h5 class="order--1" i18n>Visa and Mastercard prices</h5>
    <mat-icon class="cursor" mat-dialog-close>close</mat-icon>
</div>
<div mat-dialog-content>
    <table class="min-width-600px" *ngIf="data.contract$ | async as contract">
        <tbody>
            <tr>
                <td i18n>EU/EEA consumer cards</td>
                <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.percentage.intra.visa.consumer.credit | number: '1.2-2'}} %</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td class="width-150px" i18n>minimum {{contract.transaction_fees.minimum.intra | number: '1.2-2'}} {{contract.currency}}</td>
            </tr>
            <tr>
                <td i18n>EU/EEA business cards</td>
                <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.percentage.intra.visa.business.credit | number: '1.2-2' }} %</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td class="width-150px" i18n>minimum {{contract.transaction_fees.minimum.intra | number: '1.2-2'}} {{contract.currency}}</td>
            </tr>
            <tr>
                <td i18n>Non-EU/EEA consumer cards</td>
                <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.percentage.inter.visa.consumer.credit | number: '1.2-2' }} %</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td class="width-150px" i18n>minimum {{contract.transaction_fees.minimum.inter | number: '1.2-2'}} {{contract.currency}}</td>
            </tr>
            <tr>
                <td i18n>Non-EU/EEA business cards</td>
                <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.percentage.inter.visa.business.credit | number: '1.2-2' }} %</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td class="width-150px" i18n>minimum {{contract.transaction_fees.minimum.inter | number: '1.2-2'}} {{contract.currency}}</td>
            </tr>
            <tr>
                <td>
                    <div class="flex-row">
                        <p class="small-text" i18n>Refund</p>
                        <mat-icon class="s18 text-grey" i18n-matTooltip matTooltip="Crediting back funds to the buyer's card.">error_outline</mat-icon>
                    </div>
                </td>
                <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.base.refund | number: '1.2-2' }} {{contract.currency}}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <div class="flex-row">
                        <p class="small-text" i18n>Retrieval</p>
                        <mat-icon class="s18 text-grey" i18n-matTooltip matTooltip="Buyer's request to provide a copy of the transaction ticket.">error_outline</mat-icon>
                    </div>
                </td>
                <td class="width-100px"><strong class="subtitle2">{{contract.account_fees.base.retrieval | number: '1.2-2' }} {{contract.currency}}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <div class="flex-row">
                        <p class="small-text" i18n>Chargeback</p>
                        <mat-icon class="s18 text-grey" i18n-matTooltip matTooltip="Forced reversal of payment by the buyer's card issuer.">error_outline</mat-icon>
                    </div>
                </td>
                <td class="width-100px"><strong class="subtitle2">{{contract.account_fees.base.chargeback | number: '1.2-2' }} {{contract.currency}}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <div class="flex-row">
                        <p class="small-text" i18n>Credit</p>
                        <mat-icon class="s18 text-grey" i18n-matTooltip matTooltip="Send money directly to a credit card number.">error_outline</mat-icon>
                    </div>
                </td>
                <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.base.credit | number: '1.2-2' }} {{contract.currency}}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td></td>
            </tr>
            <tr>
                <td i18n>3-D Secure fee</td>
                <td class="width-100px"><strong class="subtitle2">{{ contract.transaction_fees.base['3dsecure'] ?? '0.00' | number: '1.2-2' }} {{contract.currency}}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td></td>
            </tr>
            <tr>
                <td i18n>Authorization</td>
                <td class="width-100px"><strong class="subtitle2">{{contract.transaction_fees.base.authorization | number: '1.2-2' }} {{contract.currency}}</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <div class="flex-row">
                        <p class="small-text" i18n>Exchange</p>
                        <mat-icon class="s18 text-grey" i18n-matTooltip matTooltip="Currency conversion fee for payments made in a currency other than the currency of the price of the purchased goods/services.">error_outline</mat-icon>
                    </div>
                </td>
                <td class="width-100px"><strong class="subtitle2">{{contract.account_fees.percentage.exchange | number: '1.2-2'}} %</strong></td>
                <td class="width-100px">/ <span i18n>transaction</span></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <div class="flex-row">
                        <p class="small-text" i18n>Capture</p>
                        <mat-icon class="s18 text-grey"i18n-matTooltip matTooltip="Request to debit funds from the buyer's card after the delivery of goods/services.">error_outline</mat-icon>
                    </div>
                </td>
                <td></td>
                <td></td>
                <td class="width-100px"><p class="text-green subtitle2">{{ contract.transaction_fees.base.capture || '0' | free }}</p></td>
            </tr>
            <tr>
                <td i18n>Settlement currency</td>
                <td></td>
                <td></td>
                <td class="width-100px">{{contract.currency}}</td>
            </tr>
            <tr>
                <td i18n>Settlement</td>
                <td></td>
                <td></td>
                <td class="width-100px"><p class="text-green subtitle2">{{contract.account_fees.base.payout || '0' | free }}</p></td>
            </tr>
            <tr>
                <td i18n>Settlement cycle</td>
                <td></td>
                <td colspan="2"><p class="small-text subtitle2 text-right" i18n>{{ data.payout_period }} with a delay of {contract.payment?.delay, plural, =1 {{{contract.payment?.delay}} day} other {{{contract.payment?.delay}} days}}</p></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
