import { AdminMe, Me } from '@interfaces/me';
import { Agreement } from '@interfaces/agreement';
import { ApiClient } from './api-client';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MeRepo {
    constructor(
        private apiClient: ApiClient
    ) {}

    public activate(token: string, password: string): Observable<Me> {
        return this.apiClient.post<Me>('/me/activate', { token, password });
    }

    public create(params: any): Observable<Me> {
        const config: any = {
            headers: { Authorization: undefined }
        };

        return this.apiClient.post<Me>('/me', params, config).pipe(
            map((item: any) => item as Me)
        );
    }

    public createWithLocale(email: string, locale: string, country_code: string): Observable<Me> {
        const config: any = {
            headers: { Authorization: undefined }
        };

        return this.apiClient.post<Me>('/me', { email, locale, country_code }, config).pipe(
            map((item: any) => item as Me)
        );
    }

    public save(params: any): Observable<Me> {
        return this.apiClient.patch<Me>('/me', params);
    }

    public updateEmail(email: string): Observable<Me> {
        return this.apiClient.patch<Me>('/me/email', { email });
    }

    public confirmEmail(id: string, email: string, token: string): Observable<Me> {
        return this.apiClient.post<Me>('/me/confirm-email', { user_id: id, email, token }).pipe(
            map((item: any) => Object.assign(item as Me))
        );
    }

    public get(encodedApiKey: string): Observable<Me> {
        this.apiClient.setHeader('Authorization', encodedApiKey);
        return this.apiClient.get<Me>('/me');
    }

    public getByID(id: number): Observable<AdminMe> {
        return this.apiClient.get<AdminMe>(`/users/${id}`).pipe(
            map((item: any) => Object.assign(item as AdminMe))
        );
    }

    public getAll(params: { [key: string]: any }): Observable<Me[]> {
        return this.apiClient.get<Me[]>('/users', { params });
    }

    public getAgreements(id: number, params: any): Observable<Agreement[]> {
        return this.apiClient.get<Agreement[]>(`/users/${id}/agreements`, { params });
    }

    public destroy(): Observable<Me> {
        return this.apiClient.delete<Me>('/me');
    }

    public deleteUserByID(id: number): Observable<null> {
        return this.apiClient.delete<null>(`/users/${id}`);
    }


    public requestPasswordReset(email: string): Observable<Me> {
        this.apiClient.deleteHeader('Authorization');
        return this.apiClient.post<Me>('/me/request-password-reset', { email });
    }

    public resetPassword(token: string, password: string): Observable<Me> {
        return this.apiClient.put<Me>('/me/password', { token, password });
    }

    public sendActivationMail(id: number): Observable<Agreement> {
        return this.apiClient.post<Agreement>(`/users/${id}/send-activation-mail`, null);
    }
}
