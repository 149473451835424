import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '@services/session/session.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const loggedInGuard = (): Observable<boolean> => {
    const router = inject(Router);
    const sessionService = inject(SessionService);
    const route = inject(ActivatedRoute);
    const location = inject(Location);

    sessionService.requestPath = location.path();

    if (!sessionService.isLoggedIn() && !(route.snapshot.data && route.snapshot.data.anonymousAccess)) {
        if (window.sessionStorage.getItem('username') && window.sessionStorage.getItem('username') !== 'undefined') {
            return sessionService
                .login(window.sessionStorage.getItem('username') ?? '', window.sessionStorage.getItem('password') ?? '')
                .pipe(
                    map(() => {
                        window.sessionStorage.removeItem('username');
                        window.sessionStorage.removeItem('password');
                        // skip navigating to the onboarding redirect page, or it will result in being stuck on that page.
                        if (!sessionService.requestPath.includes('onboarding')) {
                            router.navigateByUrl(sessionService.requestPath);
                        }
                        sessionService.requestPath = '';
                        return true;
                    })
                );
        } else {
            router.navigate(['./login']);
            return of(false);
        }
    }
    return of(true);
};
