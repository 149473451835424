import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'qpFilter', pure: false })
export class QpFilterPipe implements PipeTransform {
    public transform(items?: any[], customFilter?: (item: any) => boolean): any {
        if (!items || !customFilter) {
            return items;
        }
        return items.filter(item => customFilter(item));
    }
}
