import { Injectable } from '@angular/core';
import { Settlement } from '@models/clearhaus-settlement';
import { SETTLEMENT_HEADER } from './export-header.constant';

@Injectable({
    providedIn: 'root'
})
export class ExporterSettlementParser {
    constructor() {}

    public getHeader(): Array<string> {
        return SETTLEMENT_HEADER;
    }

    public parse(settlement: Settlement): any {
        const payout = settlement.payout;
        const summary = settlement.summary;
        const fees = settlement.fees;
        const reserve = settlement.reserve;

        const data = {
            /* eslint-disable */
            Id: settlement.id,
            Settled: settlement.settled !== null ? settlement.settled.toString() : '',
            Currency: settlement.currency,
            Period: settlement.period.start_date + ' / ' + settlement.period.end_date,
            Payout_amount: payout ? payout.amount : '',
            Payout_date: payout ? payout.date : '',
            Payout_reference_number: payout ? payout.reference_number : '',
            Payout_descriptor: payout ? payout.descriptor : '',
            Summary_sales: summary ? summary.sales : '',
            Summary_credits: summary ? summary.credits : '',
            Summary_refunds: summary ? summary.refunds : '',
            Summary_chargebacks: summary ? summary.chargebacks : '',
            Summary_fees: summary ? summary.fees : '',
            Summary_other_postings: summary ? summary.other_postings : '',
            Summary_net: summary ? summary.net : '',
            Fees_sales: fees ? fees.sales : '',
            Fees_refunds: fees ? fees.refunds : '',
            Fees_authorisations: fees ? fees.authorisations : '',
            Fees_credits: fees ? fees.credits : '',
            Fees_interchange: fees ? fees.interchange : '',
            Fees_scheme: fees ? fees.scheme : '',
            Fees_minimum_processing: fees ? fees.minimum_processing : '',
            Fees_service: fees ? fees.service : '',
            Fees_wire_transfer: fees ? fees.wire_transfer : '',
            Fees_chargebacks: fees ? fees.chargebacks : '',
            Fees_retrieval_requests: fees ? fees.retrieval_requests : '',
            Reserve_amount: reserve ? reserve.amount : '',
            Reserve_date: reserve ? reserve.date : '',
            Reserve_cancelled_date: reserve ? reserve.cancelled_date : '',
            Reserve_reference_number: reserve ? reserve.reference_number : '',
            Reserve_descriptor: reserve ? reserve.descriptor : ''
            /* eslint-enable */
        };

        return data;
    }
}
