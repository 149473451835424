import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { SearchParams } from '@interfaces/params';
import { Operation } from '@models/operation';
import { Payment } from '@models/payment';
import { QpPaginatorConfig } from '@models/qp-pagintor';
import { Subscription } from '@models/subscription';
import { SubscriptionGroup } from '@models/subscription-group';
import { Transaction } from '@models/transaction';
import { ColumnsService } from '@services/columns/columns.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { TransactionService } from '@services/transaction/transaction.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { Animations } from 'app/animations/animations';
import { SubscriptionGroupRepo } from 'app/endpoints/api/subscription-group-repo';
import { SubscriptionRepo } from 'app/endpoints/api/subscription-repo';
import { Observable } from 'rxjs';
import { exhaustMap, filter, finalize, map, tap } from 'rxjs/operators';
import { SubscriptionRecurringComponent } from './subscription-payment-recurring.component';

@Component({
    selector: 'qp-subscription-detail',
    templateUrl: './subscription-detail.component.html',
    styleUrls: ['./subscription-detail.component.scss'],
    animations: [Animations.getFadeAnimation()]
})
export class SubscriptionDetailComponent extends BaseLoadingDirective implements OnInit {
    public getTotalItems: (items: number) => number;

    public get is_cancelable(): boolean {
        if (
            !this.subscription?.accepted ||
            this.transactionService.isCancelled(this.subscription) ||
            this.transactionService.isPending(this.subscription) ||
            this.subscription.state === 'cancelled' ||
            this.subscription.state === 'rejected' ||
            this.subscription.state === 'expired'
        ) {
            return false;
        }

        return true;
    }

    public get latest_pending_amount(): number {
        return this.transactionService.latestPendingAmount(this.subscription);
    }
    public get latest_failed_amount(): number {
        return this.transactionService.latestFailedAmount(this.subscription);
    }

    public subscription?: Subscription;
    public data_source: MatTableDataSource<Payment> = new MatTableDataSource();
    public paginator_options: QpPaginatorConfig;
    public display_columns = ['payment_id', 'order_id', 'created_at', 'currency', 'amount', 'balance'];
    public groups: Array<SubscriptionGroup> = [];
    public is_loading_results = false;
    public show_spinner: boolean;
    public payment_id: string;
    public search_params: SearchParams;
    public created_at_format: string;

    constructor(
        private dialogService: DialogService,
        private subscriptionGroupRepo: SubscriptionGroupRepo,
        private columnsService: ColumnsService,
        private transactionService: TransactionService,
        public subscriptionRepo: SubscriptionRepo,
        private snackBar: QpSnackBar,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    public ngOnInit(): void {
        this.payment_id = this.activatedRoute.snapshot.queryParams.payment_id;

        this.search_params = {
            page: 1,
            page_size: 10,
            state: 0,
            sort_by: 'payment_id',
            sort_dir: 'desc',
            states: ['accepted', 'not_accepted']
        };

        this.created_at_format = this.columnsService.getColumns('payments')['created_at'] === undefined
            ? 'MMM dd, yyyy'
            : this.columnsService.getDateFormats()[this.columnsService.getColumns('payments')['created_at'].format].format;

        this.getSubscription();
        this.setPaginatorData();
        this.loadPayments().subscribe();
    }

    public updateSubscription(transaction: Subscription): void {
        this.subscriptionRepo.update(transaction).pipe(
            tap((subscription: Subscription) => {
                this.subscription = subscription;
                this.snackBar.open($localize`Transaction updated`);
            })
        ).subscribe();
    }

    public setPaginatorData(): void {
        this.paginator_options = {
            page_size_options: [10, 100],
            page_size: this.search_params.page_size ?? 10,
            page: this.search_params.page ?? 1,
            label: $localize`Payments per page`
        };
    }

    public setDataSource(data: any): void {
        this.data_source.data = data;
        this.is_loading_results = false;
    }

    public getSubscription(): void {
        this.show_spinner = true;
        this.subscriptionRepo.get<Subscription>(this.activatedRoute.snapshot.params.subscription_id).subscribe((subscription) => {
            this.subscription = subscription;
            this.show_spinner = false;
            this.getGroups();
        });
    }

    public getGroups(): void {
        if (!this.subscription) {
            return;
        }

        if (this.subscription.group_ids.length > 0) {
            for (const groupID of this.subscription.group_ids) {
                this.subscriptionGroupRepo.get(groupID).subscribe((group: SubscriptionGroup) => {
                    this.groups.push(group);
                });
            }
        }
    }

    public onCancel(): void {
        if (!this.subscription) {
            return;
        }
        this.dialogService.confirm(
            $localize`Cancel subscription`,
            $localize`Are you sure you want to cancel the subscription?`,
            $localize`Yes`,
            $localize`No`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            exhaustMap(() =>
                this.subscriptionRepo.cancel(this.subscription?.id ?? -1).pipe(
                    map((subscription: Subscription) => {
                        this.subscription = subscription;
                        this.show_spinner = false;
                    })
                )
            )
        ).subscribe();
    }

    public sortPayments(sort: Sort): void {
        this.search_params.sort_by = sort.active;
        this.search_params.sort_dir = sort.direction;
        this.search_params.page = 1;
        this.loadPayments().subscribe();
    }

    public setState(data: any): void {
        this.search_params.state = data.state;
        this.search_params.page = 1;
        this.loadPayments().subscribe();
    }

    public hideBalance(paymentState: string): boolean {
        return ['initial', 'new', 'rejected'].includes(paymentState);
    }

    public createRecurring(): void {
        this.dialogService.component(
            SubscriptionRecurringComponent,
            { subscription: this.subscription },
            { maxWidth: '350px' }
        ).afterClosed().subscribe(() => {
            this.search_params.page = 1;
            this.loadPayments().subscribe();
        });
    }

    public onPrint(): void {
        window.print();
    }

    public showJSON(): void {
        if (!this.subscription) {
            return;
        }

        this.dialogService.copy(
            $localize`Subscription ${this.subscription.id}`,
            JSON.stringify(this.subscription, null, 4),
            $localize`Copied to clipboard`
        );
    }

    public isCancelledRejected(): boolean {
        if (!this.subscription?.operations) {
            return false;
        }

        let operation: Operation;

        for (let i = this.subscription.operations.length; i > 0; i--) {
            operation = this.subscription.operations[i - 1];
            if (operation.type === 'authorize' && operation.qp_status_code === '20000') {
                return false;
            }
            if (
                (operation.type === 'cancel' && operation.qp_status_code === '20000') ||
                (operation.type === 'authorize' && operation.qp_status_code === '40000')
            ) {
                return true;
            }
        }
        return false;
    }

    public getAuthAmount(transaction: Transaction): number {
        return this.transactionService.getAuthAmount(transaction);
    }

    public onPageChange(event: { page: number; page_size: number }): void {
        this.search_params = {
            ...this.search_params,
            ...event
        };
        this.loadPayments().subscribe();
    }

    private loadPayments(): Observable<Payment[]> {
        const query_params: any = {
            page_size: this.search_params.page_size || 10,
            page: this.search_params.page || 1,
            sort_by: this.search_params.sort_by,
            sort_dir: this.search_params.sort_dir,
            accepted: this.search_params.state === 0
        };

        this.is_loading_results = true;

        return this.subscriptionRepo.getPayments(this.activatedRoute.snapshot.params.subscription_id, query_params).pipe(
            tap((payments) => this.data_source.data = payments),
            finalize(() => this.is_loading_results = false)
        );
    }
}
