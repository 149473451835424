<div class="dont-extend-parent">
    <mat-card class="flex-col rg36 card02" *ngIf="!contractSigned">
        <mat-card-header class="flex-row">
            <mat-icon mat-card-avatar color="accent">info</mat-icon>
            <mat-card-title class="flex-row justify-start items-center" i18n>Accept Visa and Mastercard</mat-card-title>
        </mat-card-header>

        <mat-card-content class="flex-col rg8" *ngIf="!displayContract">
            <span i18n>Apply for a Visa and Mastercard agreement with Clearhaus.</span>
            <span i18n>After submitting the application, you can see and sign the Clearhaus contract directly from here, and be ready to accept payments in just 5 minutes.</span>
            <span i18n>Clearhaus is partner of {{ theme.brand_name }}</span>
            <div>
                <span i18n>Read more about Clearhaus on</span>
                <a href="https://www.clearhaus.com" target="_blank">https://www.clearhaus.com</a>
            </div>
        </mat-card-content>

        <mat-card-content class="flex-col rg8" *ngIf="displayContract">
            <span i18n>Your Clearhaus application has been submitted.</span>
            <span i18n>You have received an email to your Clearhaus account.</span>
            <span i18n>See and sign the contract below.</span>
            <span i18n>Clearhaus is a partner of {{ theme.brand_name }}.</span>
        </mat-card-content>
    </mat-card>

    <mat-card class="flex-col rg36 card02" *ngIf="!displayContract">
        <mat-card-header class="flex-row">
            <mat-icon mat-card-avatar color="accent">work</mat-icon>
            <mat-card-title class="flex-row justify-start items-center" i18n>Visa and Mastercard application</mat-card-title>
        </mat-card-header>

        <div *ngIf="owner && showForm">
            <qp-clearhaus-signup-form *ngIf="!displayInstant"></qp-clearhaus-signup-form>
            <qp-clearhaus-instant-signup-form *ngIf="displayInstant"></qp-clearhaus-instant-signup-form>
        </div>

        <div *ngIf="owner && !showForm && !displayInstant">
            <span i18n>An application has already been sent from this merchant.</span> <br />
            <span  i18n>Update and see the progress of your application on</span>&nbsp;
            <a href="https://apply.clearhaus.com" target="_blank">https://apply.clearhaus.com</a>
        </div>

        <div *ngIf="contractSigned && apiKey">
            <span i18n>The contract has been signed.</span> <br /><br />
            <span i18n>You are ready to accept Visa and Mastercard payments through Clearhaus.</span>
        </div>

        <div *ngIf="contractSigned && !apiKey">
            <span i18n>The contract has been signed.</span> <br /><br />
            <span i18n>To finish the setup, you need to get your API key from Clearhaus, and copy it into Settings > Acquirers > Clearhaus. Check your email for access to your Clearhaus account</span>
        </div>

        <div *ngIf="!owner" i18n>Clearhaus application is only available for account owners.</div>
    </mat-card>

    <mat-card class="flex-col rg36 card02" *ngIf="displayContract">
        <mat-card-header class="flex-row">
            <mat-icon mat-card-avatar color="accent">location_on</mat-icon>
            <mat-card-title class="flex-row justify-start items-center" i18n>Clearhaus contract</mat-card-title>
        </mat-card-header>

        <mat-card-content class="flex-col rg16">
            <div>
                <h5 i18n>Pricing summary*</h5>
                <table class="table1">
                    <tr *ngIf="(contract?.transaction_fees?.base?.authorization ?? -1) > 0">
                        <td i18n>Fixed authorization fee</td>
                        <td>{{ contract.transaction_fees.base.authorization }} {{ contract.currency }}</td>
                        <td><small i18n>per transaction</small></td>
                    </tr>
                    <tr>
                        <td i18n>EU/EEA consumer cards</td>
                        <td>{{ contract.transaction_fees.percentage.intra.visa.consumer.credit }} %</td>
                        <td><small i18n>min. {{ contract.transaction_fees.minimum.domestic }} {{ contract.currency }} per transaction</small></td>
                    </tr>
                    <tr>
                        <td i18n>EU/EEA business cards</td>
                        <td>{{ contract.transaction_fees.percentage.intra.visa.business.credit }} %</td>
                        <td><small i18n>min. {{ contract.transaction_fees.minimum.intra }} {{ contract.currency }} per transaction</small></td>
                    </tr>
                    <tr>
                        <td i18n>Non EU/EEA consumer cards</td>
                        <td>{{ contract.transaction_fees.percentage.inter.visa.consumer.credit }} %</td>
                        <td><small i18n>min. {{ contract.transaction_fees.minimum.inter }} {{ contract.currency }} per transaction</small></td>
                    </tr>
                    <tr>
                        <td i18n>Non EU/EEA business cards</td>
                        <td>{{ contract.transaction_fees.percentage.inter.visa.business.credit }} %</td>
                        <td><small i18n>min. {{ contract.transaction_fees.minimum.inter }} {{ contract.currency }} per transaction</small></td>
                    </tr>
                </table>
            </div>

            <div><mat-divider></mat-divider></div>

            <div class="flex-col rg4">
                <h5><span i18n>Fixed fees</span>*</h5>
                <div class="flex-row cg15p">
                    <div class="flex-row flex-25">
                        <span i18n>Chargeback</span>
                        <mat-icon class="s18" color="accent" (click)="displayHintBox = !displayHintBox" matTooltip="Click to expand" i18n-matTooltip> info </mat-icon>
                    </div>

                    <div class="flex-15 text-right">
                        <span>{{ contract.account_fees.base.chargeback }} {{ contract.currency }}</span>
                    </div>
                </div>

                <div class="max-width-800px" *ngIf="displayHintBox">
                    <p i18n>A dispute, also referred to as chargeback, inquiry or retrieval happens when a cardholder contacts their card issuer and demands to have a transaction refunded because they don't recognize or question the payment.</p>

                    <a *ngIf="theme.brand_name === 'Quickpay'" [href]="helpdeskLink" target="_blank" i18n>Read more on our helpdesk</a>
                </div>
            </div>

            <div *ngIf="(['Quickpay'] | qpMerchantType)"><mat-divider></mat-divider></div>

            <div class="flex-col" *ngIf="(['Quickpay'] | qpMerchantType)">
                <p i18n>Additional fees apply. Download contract for details.</p>
                <div>
                    <button mat-raised-button (click)="getPDF()" i18n>Download contract</button>
                </div>
            </div>

            <div><mat-divider></mat-divider></div>

            <div class="flex-col rg16">
                <span> * <span i18n>Depending on your business model Clearhaus might need to adjust the prices and terms in your contract</span></span>
                <div i18n>You accept the general <a [href]="clearhausTermsLink" target="_blank">Clearhaus terms</a> by clicking 'Sign contract'</div>
                <span class="flex-row" *ngIf="notAllowedToSign && contract?.signer">
                    <mat-icon class="text-yellow">info</mat-icon>
                    <span i18n>Only "{{ contract.signer.name }}" {{ contract.signer.email }} can sign the contract.</span>
                </span>

                <span class="flex-row" *ngIf="!contract?.signer">
                    <mat-icon class="text-yellow">info</mat-icon>
                    <span i18n>Signer is missing in your contract, please contact support.</span>
                </span>
            </div>

            <div>
                <qp-spinner-button color="accent" [disabled]="signing || notAllowedToSign || !contract.signer" [showSpinner]="signing" btnText="Sign contract" (btnClick)="signContract()" i18n-btnText> </qp-spinner-button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
