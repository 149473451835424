import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClearhausContract } from '@models/clearhaus-application/ch-contract';
import { Observable } from 'rxjs';

@Component({
    selector: 'qp-additional-ch-prices-dialog',
    templateUrl: './additional-ch-prices-dialog.component.html',
    styleUrls: ['./additional-ch-prices-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalChPricesDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { contract$: Observable<ClearhausContract>; payout_period: string }) {}
}
