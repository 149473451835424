import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpResponse, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.startsWith('/invoicing/')) {
            return next.handle(req);
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body && event.body.hasOwnProperty('data')) {
                        return event.clone({
                            body: event.body.data
                        });
                    }
                }
                return event;
            })
        );
    }
}
