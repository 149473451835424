import { Client } from '../client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class GithubClient extends HttpClient {}

/* eslint-disable */
export function githubClientFactory(httpClient: HttpClient) {
    return new Client(httpClient, '/standard-branding-releases', {});
}
