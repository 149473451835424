export class ClearhausInfo {
    public name: string;
    public country: string;
    public merchant_id: string;
    public text_on_statement?: string;
    public transaction_rules?: string;
    public mcc?: string;
    public descriptor?: string;
    public acquirer?: {
        visa_bin: string;
        mastercard_bin: string;
    };
}
