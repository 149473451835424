import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AclPermission } from '@interfaces/acl-permission';
import { Agreement } from '@interfaces/agreement';
import { InviteUserParams } from '@interfaces/params';
import { AgreementService } from '@services/agreement/agreement.service';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { MeRepo } from 'app/endpoints/api/me-repo';
import { PermissionTemplatesService } from '@services/permission-templates/permission-templates.service';
import { ThemingService } from '@services/theming/theming.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegExpEmail } from '@helpers/reg-exp/reg-exp-email';

@Component({
    selector: 'qp-invite-user-dialog',
    templateUrl: 'invite-user-dialog.html'
})
export class InviteUserDialogComponent implements OnInit {
    public form_group: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.pattern(new RegExpEmail())]),
        role: new UntypedFormControl(null, [Validators.required])
    });
    public roles: Array<{ name: string; acl_permissions: Array<AclPermission> }>;

    constructor(
        public dialogRef: MatDialogRef<InviteUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialog_data: InviteUserParams,
        private permissionTemplateService: PermissionTemplatesService,
        private agreementService: AgreementService,
        private themingService: ThemingService,
        private meRepo: MeRepo,
        private agreementRepo: AgreementRepo
    ) {}

    public ngOnInit(): void {
        const account_type = this.agreementService.agreement?.account?.type;
        const permission_templates = account_type
            ? this.permissionTemplateService.getTemplates()[account_type]
            : undefined;

        for (const key in permission_templates) {
            if (permission_templates.hasOwnProperty(key) && key === 'Roles') {
                this.roles = permission_templates[key];
            }
        }
    }

    public inviteUser(): void {
        if (this.form_group.invalid) {
            this.form_group.markAllAsTouched();
            return;
        }

        const create_agreement_params = {
            user_email: this.invite_user_params.email,
            owner: this.invite_user_params.role?.name === 'Owner',
            acl_permissions: this.invite_user_params.role?.acl_permissions
        };

        this.agreementRepo.create(create_agreement_params, this.agreementService.agreement?.api_key).pipe(
            map((agreement: Agreement) => this.dialogRef.close(agreement)),
            catchError(() => {
                this.createAccountInviteUser(create_agreement_params);
                return of();
            })
        ).subscribe();
    }

    private createAccountInviteUser(agreement_params: any): void {
        const create_me_params = {
            email: this.invite_user_params.email,
            referral: this.themingService.brand_name,
            referral_id: this.agreementService.referralID(),
            locale: new QpMerchantTypePipe(this.agreementService).transform(['Unzer']) ? 'de' : undefined,
            country_code: new QpMerchantTypePipe(this.agreementService).transform(['Unzer']) ? 'DEU' : undefined
        };

        this.meRepo.create(create_me_params).pipe(
            switchMap(() => this.agreementRepo.create(agreement_params, undefined).pipe(
                map((agreement: Agreement) => this.dialogRef.close(agreement))
            ))
        ).subscribe();
    }

    private get invite_user_params(): InviteUserParams {
        return this.form_group.value;
    }
}
