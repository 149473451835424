import { Injectable } from '@angular/core';
import { Invoice } from '@models/invoice';
import { InvoiceClient } from './invoice-client';
import { InvoicePayment } from '@models/invoice-payment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvoiceRepo {
    constructor(
        private invoiceClient: InvoiceClient
    ) {}

    public get(id: string): Observable<Invoice> {
        return this.invoiceClient.get<Invoice>(`/invoices/${id}`).pipe(
            map((item: Invoice) => Object.assign(new Invoice(), item))
        );
    }

    public getAll(params: any): Observable<Invoice[]> {
        return this.invoiceClient.get<Invoice[]>('/invoices', { params }).pipe(
            map((items: any) => items.map((item: Invoice) => Object.assign(new Invoice(), item)))
        );
    }

    public getPDF(id: string): Observable<Blob> {
        return this.invoiceClient.get(`/invoices/${id}.pdf`, { responseType: 'blob' });
    }

    public update(invoice: Invoice): Observable<Invoice> {
        return this.invoiceClient.patch<Invoice>(`/invoices/${invoice.id}`, invoice);
    }

    public economicImport(economic_invoice_id: number): Observable<Invoice> {
        return this.invoiceClient.post<Invoice>('/invoices', {
            type: 'invoices',
            attributes: { economic_invoice_id }
        }).pipe(
            map((item: Invoice) => Object.assign(new Invoice(), item))
        );
    }

    public payInvoice(invoiceID: string): Observable<InvoicePayment> {
        return this.invoiceClient.post<InvoicePayment>('/invoice-payments', {
            type: 'invoice-payments',
            attributes: {
                invoice_id: invoiceID
            }
        }).pipe(
            map((item: InvoicePayment) => Object.assign(new InvoicePayment(), item))
        );
    }
}
