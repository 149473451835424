import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { QpPaginatorConfig } from '@models/qp-pagintor';
import { ParamService } from '@services/param/params.service';
import { Mixin } from 'app/helpers/decorators/mixins.decorator';
import { PaginatorMixin } from 'app/helpers/paginator/paginator.mixin';

@Component({
    selector: 'qp-paginator',
    templateUrl: 'qp-paginator.component.html'
})
@Mixin([PaginatorMixin])
export class PaginatorComponent implements OnInit, PaginatorMixin {
    @Input() public config: QpPaginatorConfig = { label: '', page: 1, page_size: 10, page_size_options: [10, 100] };
    @Input() public data_length = 0;
    @Output() public tableData = new EventEmitter();
    @Output() public pageChange = new EventEmitter<{ page: number; page_size: number }>();

    public page: number;
    public page_size: number;
    public page_size_options: number[] = [];

    public getTotalItems: (items: number) => number;

    constructor(
        public matPaginatorIntl: MatPaginatorIntl,
        private paramsService: ParamService,
        private activatedRoute: ActivatedRoute
    ) {}

    public get paginator_length(): number {
        return this.data_length + 1 + (this.page - 1) * this.page_size;
    }

    public ngOnInit(): void {
        this.page = this.parsePageKey(this.config.page);
        this.page_size_options = this.config.page_size_options;
        this.page_size = this.config.page_size;

        this.matPaginatorIntl.itemsPerPageLabel = !!this.config.label ? this.config.label : $localize`Items per page`;
        this.paramsService.setParams({ page_size: this.page_size }, this.activatedRoute);
    }

    public changePage(event: PageEvent): void {
        this.page_size = event.pageSize;
        this.page = event.pageIndex + 1;
        this.paramsService.setParams({ page_size: event.pageSize }, this.activatedRoute);

        this.pageChange.emit({ page: event.pageIndex + 1, page_size: event.pageSize });
    }

    private parsePageKey(page: number | undefined): number {
        if (!page) {
            return 1;
        }
        return isNaN(page) ? 1 : page;
    }
}
