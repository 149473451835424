import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Transaction } from '@models/transaction';
import { PaymentRepo } from 'app/endpoints/api/payment-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { SubscriptionRepo } from '@endpoints/api/subscription-repo';

@Component({
    selector: 'qp-transaction-details-variables',
    templateUrl: './transaction-details-variables.html',
    styleUrls: ['../transaction-details.scss']
})
export class TransactionDetailsVariablesComponent implements OnInit {
    @Input() public transaction: Transaction;
    public objectKeys = Object.keys;
    public update: boolean;
    public paymentVariableForm: UntypedFormGroup;

    constructor(
        private dialogService: DialogService,
        private snackBar: QpSnackBar,
        public paymentRepo: PaymentRepo,
        public subscriptionRepo: SubscriptionRepo
    ) {}

    public ngOnInit(): void {
        this.update = false;
        this.setupForm();
    }

    public setupForm(): void {
        if (!this.transaction?.variables) {
            this.paymentVariableForm = new UntypedFormGroup({});
            return;
        }
        const formControls: { [key: string]: any } = {};
        /* eslint-disable */
        for (const key of Object.keys(this.transaction.variables)) {
            (formControls[`variable${key}`] = new UntypedFormControl({ value: key, disabled: false })),
                (formControls[`value${key}`] = new UntypedFormControl({ value: this.transaction.variables[key], disabled: false }));
        }
        /* eslint-enable */
        this.paymentVariableForm = new UntypedFormGroup(formControls);
    }

    public updateVariables(): void {
        this.update = true;
        this.setupForm();
    }

    public deleteVariable(variable: any): void {
        this.dialogService.confirm(
            $localize`Delete variable`,
            $localize`Sure you want to delete ${variable} ?`,
            $localize`Delete`
        ).afterClosed().subscribe((confirm: boolean) => {
            if (confirm) {
                delete this.transaction.variables[variable];
                this.saveChanges();
            }
        });
    }

    public discardChanges(): void {
        this.update = false;
        this.setupForm();
    }

    public saveChanges(): void {
        if (!this.transaction.id) {
            return;
        }

        const formData: { [key: string]: string } = {};
        const resultArray = Object.keys(this.paymentVariableForm.controls).map(index => {
            const variable = this.paymentVariableForm.controls[index] as UntypedFormControl;
            return variable;
        });

        for (let i = 0; i < resultArray.length; i += 2) {
            formData[resultArray[i].value] = resultArray[i + 1].value;
        }

        this.transaction.variables = formData;
        this.setupForm();
        this.update = false;

        if (window.location.href.indexOf('subscriptions') > -1) {
            this.subscriptionRepo.update(this.transaction).subscribe();
        } else {
            this.paymentRepo.updateVariables(this.transaction.id, { variables: formData }).subscribe();
        }

        this.snackBar.open($localize`Payment variables updated`);
    }
}
