import { CommonModule } from '@angular/common';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { CurrenciesSelectorComponent } from './currencies-selector/currencies-selector.component';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { CustomMaterialModule } from '../layout/material/custom-material.module';
import { DatefieldComponent } from './datefield/datefield.component';
import { FileInputComponent } from './file-input/file-input.component';
import { FormSaveDiscardComponent } from './form-save-discard/form-save-discard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormStateInfoComponent } from './form-state-info/form-state-info.component';
import { IbanInputComponent } from './iban-input/iban-input.component';
import { InputDecimalDirective } from './input-decimal-directive/input-decimal.directive';
import { LayoutModule } from '../layout/layout.module';
import { MaskedDateInputComponent } from './masked-date-input/masked-date-input.component';
import { NgModule } from '@angular/core';
import { PhoneNumberFieldComponent } from './phone-number-field/phone-number-field.component';

@NgModule({
    imports: [
        CommonModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule
    ],
    declarations: [
        CountrySelectorComponent,
        CurrenciesSelectorComponent,
        CurrencyInputComponent,
        DatefieldComponent,
        FileInputComponent,
        FormSaveDiscardComponent,
        FormStateInfoComponent,
        IbanInputComponent,
        InputDecimalDirective,
        MaskedDateInputComponent,
        PhoneNumberFieldComponent
    ],
    exports: [
        CountrySelectorComponent,
        CurrenciesSelectorComponent,
        CurrencyInputComponent,
        DatefieldComponent,
        FileInputComponent,
        FormSaveDiscardComponent,
        FormStateInfoComponent,
        IbanInputComponent,
        InputDecimalDirective,
        MaskedDateInputComponent,
        PhoneNumberFieldComponent
    ]
})
export class CustomFormElementsModule {}
