import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { SettlementRepo } from '@endpoints/server/settlement-repo';
import { catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { ClearhausParams } from '@interfaces/params';
import { Clearhaus } from '@models/acquirers/acquirer';
import { Settlement, SettlementDetail } from '@models/clearhaus-settlement';
import { QpPaginatorConfig } from '@models/qp-pagintor';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { ExporterService } from 'app/exporter/exporter.service';
import { ExporterSettlementParser } from 'app/exporter/parsers/settlement.parser';
import { filter, finalize, map, tap } from 'rxjs/operators';

@Component({
    selector: 'qp-settlements',
    templateUrl: 'settlements.component.html',
    styleUrls: ['./settlements.component.scss'],
    animations: [Animations.getFadeAnimation()]
})
export class SettlementsComponent extends BaseLoadingDirective implements OnInit {
    public data_source = new MatTableDataSource<Settlement>();
    public page_params: ClearhausParams;
    public paginator_options: QpPaginatorConfig;
    public acquirer: Clearhaus;
    public is_loading_results = false;
    public error_loading = false;

    constructor(
        private acquirerService: AcquirerService,
        private exportService: ExporterService,
        private settlementParser: ExporterSettlementParser,
        private settlementRepo: SettlementRepo,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    public ngOnInit(): void {
        this.acquirer = this.acquirerService.acquirers.clearhaus;
        this.page_params = {
            page: 1,
            page_size: 10,
            ch_mid: this.acquirer.mpi_merchant_id || ''
        };
        this.setPaginatorData();
        if (this.acquirerService.acquirers?.clearhaus.active) {
            this.loadSettlements();
        }
    }

    public setPaginatorData(): void {
        this.paginator_options = {
            page_size_options: [10, 50],
            page: this.page_params.page || 1,
            page_size: this.page_params.page_size || 10,
            label: $localize`Settlements per page`
        };
    }

    public loadSettlements(): void {
        this.error_loading = false;
        this.is_loading_results = true;

        this.settlementRepo.getSettlements(this.page_params).pipe(
            catchHttpStatusError([HttpStatusCode.NotFound], () => {
                this.error_loading = true;
            }),
            filter(settlement => !!settlement),
            map((settlement_detail) => settlement_detail.settlements),
            tap((settlements) => this.data_source.data = settlements),
            finalize(() => this.is_loading_results = false)
        ).subscribe();
    }

    public onPageChange(event: { page: number; page_size: number }): void {
        this.page_params.page = event.page;
        this.page_params.page_size = event.page_size;
        this.setPaginatorData();
        this.loadSettlements();
    }

    public changeTab(data: MatTabChangeEvent): void {
        this.page_params.page = 1;
        this.page_params.page_size = 10;

        if (data.tab.textLabel === 'Main') {
            this.page_params.ch_mid = this.acquirer.mpi_merchant_id || '';
        } else {
            this.page_params.ch_mid = this.acquirer.currency_specific_account[data.tab.textLabel].mpi_merchant_id || '';
        }

        this.setPaginatorData();
        this.loadSettlements();
    }

    public exportSettlements(): void {
        const exportSettlements: Array<any> = [];
        const csvHeader = this.settlementParser.getHeader();

        this.settlementRepo.getSettlements(
            { page_size: 50, page: this.page_params.page }
        ).subscribe((settlementDetail: SettlementDetail) => {
            for (const settlement of settlementDetail.settlements) {
                const parsed = this.settlementParser.parse(settlement);
                exportSettlements.push(parsed);
            }
            this.exportService.downloadCSVFile(exportSettlements, csvHeader, 'Export_settlements');
        });
    }
}
