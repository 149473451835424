import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApiModule } from '@endpoints/api/api.module';
import { PipesModule } from '@pipes/pipes.module';
import { ColumnsSettingsModule } from '@widgets/columnsSettings/columns-settings.module';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { KeysetPaginatorComponent } from '@widgets/keyset-paginator/keyset-paginator.component';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NoResultsModule } from '@widgets/no-results/no-results.module';
import { PaginatorModule } from '@widgets/qp-paginator/qp-paginator.module';
import { SearchFieldModule } from '@widgets/searchfield/search-field.module';
import { SpinnerButtonModule } from '@widgets/spinner-button/spinner-button.module';
import { NgxPrintModule } from 'ngx-print';
import { SubscriptionGroupComponent } from './subscription-group/subscription-group.component';
import { CardPaymentDialogComponent } from './transaction-detail/card-detail/card-create-payment/card-create-payment.component';
import { CardPayoutDialogComponent } from './transaction-detail/card-detail/card-create-payout/card-create-payout.component';
import { CardDetailComponent } from './transaction-detail/card-detail/card-detail.component';
import { PaymentDetailComponent } from './transaction-detail/payment-detail/payment-detail.component';
import { PayoutDetailComponent } from './transaction-detail/payout-detail/payout-detail.component';
import { SubscriptionDetailComponent } from './transaction-detail/subscription-detail/subscription-detail.component';
import { SubscriptionRecurringComponent } from './transaction-detail/subscription-detail/subscription-payment-recurring.component';
import { TransactionDetailsModule } from './transaction-detail/transaction-detail-widgets/transaction-details.module';
import { CapturePaymentDialogComponent } from './transaction-dialogs/capture-payment-dialog/capture-payment-dialog.component';
import { EditBalanceDialogComponent } from './transaction-dialogs/edit-balance-dialog/edit-balance-dialog.component';
import { PaymentLinkDialogComponent } from './transaction-dialogs/payment-link-dialog/payment-link-dialog.component';
import { RefundPaymentDialogComponent } from './transaction-dialogs/refund-payment-dialog/refund-payment-dialog.component';
import { SubscriptionGroupDialogComponent } from './transaction-dialogs/subscriptiongroup-dialog/subscriptiongroup-dialog';
import { TransactionTableActionComponent } from './transaction-table/action-handler/action-handler.component';
import { TransactionTableComponent } from './transaction-table/transaction-table.component';
import { ExportTransactionsToolbarComponent } from './transaction-toolbar/export-transaction/export-transactions-tool.comonent';
import { PaymentActionResultDialogComponent } from './transaction-toolbar/payment-action/payment-action-result-dialog/payment-action-result-dialog.component';
import { PaymentActionToolbarComponent } from './transaction-toolbar/payment-action/payment-action-tool.component';
import { ShopifyFilterToolbarComponent } from './transaction-toolbar/shopify-order-filter/shopify-order-filter-tool.component';
import { SubscriptionGroupToolbarComponent } from './transaction-toolbar/subscription-group/subscriptions-group-tool.component';
import { TransactionStatesModule } from './transaction-toolbar/transaction-states/transaction-states.module';
import { TransactionsComponent } from './transactions.component';

@NgModule({
    imports: [
        ApiModule,
        ColumnsSettingsModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        NgxPrintModule,
        NoResultsModule,
        PaginatorModule,
        PipesModule,
        SearchFieldModule,
        TransactionDetailsModule,
        RouterModule,
        KeysetPaginatorComponent,
        SpinnerButtonModule,
        TransactionStatesModule
    ],
    declarations: [
        CapturePaymentDialogComponent,
        CardDetailComponent,
        CardPaymentDialogComponent,
        CardPayoutDialogComponent,
        SubscriptionGroupDialogComponent,
        EditBalanceDialogComponent,
        ExportTransactionsToolbarComponent,
        PaymentActionResultDialogComponent,
        PaymentActionToolbarComponent,
        PaymentDetailComponent,
        PaymentLinkDialogComponent,
        PayoutDetailComponent,
        RefundPaymentDialogComponent,
        ShopifyFilterToolbarComponent,
        SubscriptionDetailComponent,
        SubscriptionGroupComponent,
        SubscriptionGroupToolbarComponent,
        SubscriptionRecurringComponent,
        TransactionsComponent,
        TransactionTableActionComponent,
        TransactionTableComponent
    ],
    exports: [
        TransactionDetailsModule,
        TransactionTableComponent
    ]
})
export class TransactionModule {}
