import {
    Acquirer,
    Anyday,
    Clearhaus,
    KlarnaPayments,
    MobilePaySubscriptions,
    Mobilepay,
    Nets,
    Paypal,
    Paypalpayments,
    Paysafecard,
    Resursbank,
    Sofort,
    Swish,
    TellerBased,
    Trustly,
    UnzerPayLater,
    Viabill,
    Vipps,
    VippsMobilePay
} from './acquirer';

export class Acquirers {
    public anydaysplit: Anyday;
    public bambora: TellerBased;
    public clearhaus: Clearhaus;
    public elavon: TellerBased;
    public handelsbanken: TellerBased;
    public klarnapayments: KlarnaPayments;
    public mobilepayonline: Mobilepay;
    public mobilepaysubscriptions: MobilePaySubscriptions;
    public nets: Nets;
    public paypal: Paypal;
    public paypalpayments: Paypalpayments;
    public paysafecard: Paysafecard;
    public pprobancontact: Acquirer;
    public pproblik: Acquirer;
    public pproprzelewy24: Acquirer;
    public pprosofort: Acquirer;
    public pprowechatpay: Acquirer;
    public resurs: Resursbank;
    public sofort: Sofort;
    public swedbank: TellerBased;
    public swish: Swish;
    public teller: TellerBased;
    public trustly: Trustly;
    public unzerpaylater: UnzerPayLater;
    public valitor: TellerBased;
    public viabill: Viabill;
    public vipps: Vipps;
    public vippspsp: Vipps;
    public vmpepayment: VippsMobilePay;
}
