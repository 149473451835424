<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../acquirer', acquirer]"
            color="accent">
            &lt; <span i18n>Back to</span> {{ title }}
        </button>
    </div>

    <mat-card class="card02 min-width-650px">
        <mat-card-content>
            <div class="flex-row cg16">
                <span></span>
                <h5 i18n>{{ title }} fee</h5>
                <img height="15" src="assets/images/acquirers/{{ imageName }}" />
            </div>

            <div class="flex-col rg32" *ngIf="(['/fees/formulas/:acquirer', 'get'] | qpPermission)">
                <div class="flex-row justify-start items-center margin l16">
                    <a [href]="helpdeskLink" target="_blank" *ngIf="theme.brand_name === 'Quickpay'" i18n>
                        Fee formula guide
                    </a>
                    <span class="flex-1"></span>
                    <button mat-raised-button
                        *ngIf="(['/fees/formulas/:acquirer/:payment_method', 'patch'] | qpPermission) && !isMobile"
                        (click)="addFee()" i18n>Create fee</button>
                </div>

                <div class="flex-row"  *ngIf="isMobile" i18n>
                    It is not possible to edit fee-formulas on mobile devices
                </div>

                <div class="flex-row margin l16" *ngIf="!!clerhausContract && acquirer === 'clearhaus'">
                    <div class="flex-col flex-1">
                        <h3 i18n>Pricing summary</h3>
                        <table class="table2">
                            <tr>
                                <td i18n>EU/EEA consumer cards</td>
                                <td>{{ clerhausContract.transaction_fees.percentage.intra.visa.consumer.credit }} %</td>
                                <td><small>min. {{ clerhausContract.transaction_fees.minimum.intra }} {{ clerhausContract.currency }} / <span i18n>transaction</span></small></td>
                            </tr>
                            <tr>
                                <td i18n>EU/EEA business cards</td>
                                <td>{{ clerhausContract.transaction_fees.percentage.intra.visa.business.credit }} %</td>
                                <td><small>min. {{ clerhausContract.transaction_fees.minimum.intra }} {{ clerhausContract.currency }} / <span i18n>transaction</span></small></td>
                            </tr>
                            <tr>
                                <td i18n>Non EU/EEA consumer cards</td>
                                <td>{{ clerhausContract.transaction_fees.percentage.inter.visa.consumer.credit }} %</td>
                                <td><small>min. {{ clerhausContract.transaction_fees.minimum.inter }} {{ clerhausContract.currency }} / <span i18n>transaction</span></small></td>
                            </tr>
                            <tr>
                                <td i18n>Non EU/EEA business cards</td>
                                <td>{{ clerhausContract.transaction_fees.percentage.inter.visa.business.credit }} %</td>
                                <td><small>min. {{ clerhausContract.transaction_fees.minimum.inter }} {{ clerhausContract.currency }} / <span i18n>transaction</span></small></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <mat-list *ngIf="fees" class="fees-list">
                    <ng-container *ngFor="let fee of fees; let index = index; let last = last">
                        <mat-list-item class="flex-row align-center">
                            <div class="flex-row items-center">
                                <div class="flex-10">
                                    <img
                                    *ngIf="showImage(fee.payment_method)"
                                    height="15px"
                                    [src]="selectImage(fee.payment_method)"
                                    title="{{ fee.payment_method }}"
                                    alt="{{ fee.payment_method }}"/>
                                    <span class="text-wrap" *ngIf="!showImage(fee.payment_method)">{{ fee.payment_method }}</span>
                                </div>

                                <div class="flex-60-mobile-90 padding tp25" *ngIf="!fee.standard">
                                    <span *ngIf="isMobile">{{ fee.formula }}</span>
                                    <mat-form-field class="flex-1" *ngIf="!isMobile">
                                        <mat-label>{{ fee.payment_method }}</mat-label>
                                        <input matInput name="fees_formula[index]"
                                            i18n-placeholder
                                            placeholder="Keep empty for standard"
                                            (change)="save(fee)"
                                            [disabled]="!(['/fees/formulas/:acquirer/:payment_method', 'patch'] | qpPermission)"
                                            [(ngModel)]="fee.formula" />
                                    </mat-form-field>
                                </div>

                                <div class="flex-95-mobile-90 padding tp25" *ngIf="fee.standard">
                                    <span *ngIf="isMobile">{{ fee.formula }}</span>
                                    <mat-form-field class="flex-1" *ngIf="!isMobile">
                                        <mat-label>{{ fee.payment_method }}</mat-label>
                                        <input matInput name="fees_formula[index]"
                                            i18n-placeholder
                                            placeholder="Keep empty for standard"
                                            (change)="save(fee)"
                                            [disabled]="!(['/fees/formulas/:acquirer/:payment_method', 'patch'] | qpPermission)"
                                            [(ngModel)]="fee.formula" />
                                    </mat-form-field>
                                </div>

                                <div class="flex-1" *ngIf="!fee.standard && !isMobile"></div>
                                <div class="flex-1" *ngIf="!fee.standard && !isMobile">
                                    <button *ngIf="(['/fees/formulas/:acquirer/:payment_method', 'get'] | qpPermission)"
                                        mat-raised-button (click)="useStandard(fee)" i18n>Use standard fee</button>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider *ngIf="!last"></mat-divider>
                    </ng-container>
                </mat-list>
            </div>
        </mat-card-content>
    </mat-card>
</div>
