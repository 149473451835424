<form [formGroup]="informationForm" class="flex-col rg8">
    <span class="text-grey" i18n>Please note if you have an existing acquirer agreement with another acquirer</span>

    <mat-form-field>
        <mat-label i18n>Additional information</mat-label>
        <textarea matInput name="application_additional_information" formControlName="additional_information" rows="5" (change)="modelAdditionalInfo($event.target)"> </textarea>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Additional files</mat-label>
        <ngx-mat-file-input #additionalFilesInput name="additionalFiles" formControlName="additional_files" multiple removable accept="plain/text,application/pdf,image/*,video/*,audio/*"> </ngx-mat-file-input>
        <button *ngIf="!additionalFilesInput.empty" mat-icon-button matSuffix (click)="removeFiles()">
            <mat-icon>clear</mat-icon>
        </button>
        <mat-icon matSuffix>note_add</mat-icon>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
