<table mat-table multiTemplateDataRows [dataSource]="operations" class="print margin-negative" id="operationTable">
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef class="qp-width-25">
            <span class="hide-lt-sm" i18n>Created at</span>
            <span class="no-print hide-gt-xs" i18n>Created</span>
        </th>
        <td mat-cell *matCellDef="let operation">
            <span class="hide-lt-sm">{{ operation.created_at | date:'dd-MM-yyyy HH:mm:ss' }}</span>
            <span class="no-print hide-gt-xs">{{ operation.created_at | date:'dd/MM yyyy HH:mm' }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="qp-width-20" i18n>Operation</th>
        <td mat-cell *matCellDef="let operation">{{ operation.type | titlecase }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="qp-width-15" i18n>Amount</th>
        <td mat-cell *matCellDef="let operation">{{ operation.amount | qpCurrency: currency }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="qp-width-20" i18n>Status</th>
        <td mat-cell *matCellDef="let operation" [class]="operation.operationStatus.color">{{ operation.operationStatus.status }}</td>
    </ng-container>

    <ng-container matColumnDef="callback">
        <th mat-header-cell *matHeaderCellDef class="qp-width-15">
            <span class="hide-lt-sm">Callback</span>
        </th>
        <td mat-cell *matCellDef="let operation; let i = dataIndex" [class.text-red]="operation.callback_success === false" class="fill">
            <div class="hide-lt-sm">
                <div class="flex-100">
                    <span *ngIf="operation.callback_success" i18n>OK</span>
                    <span *ngIf="operation.callback_success === false" i18n>Failed</span>
                    <span *ngIf="operation.callback_success === null || operation.callback_success === undefined">N/A</span>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef class="qp-width-5"></th>
        <td mat-cell *matCellDef="let operation; let i = dataIndex">
            <mat-icon color="accent" inline *ngIf="!showRow[i]">add_circle</mat-icon>
            <mat-icon color="accent" inline *ngIf="showRow[i]">remove_circle</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="expanded_detail">
        <td mat-cell *matCellDef="let operation; let i = dataIndex" [attr.colspan]="columnsToDisplay.length + 1">
            <div class="flex-row" class="expandable-element" [@detail_expand]="showRow[i] ? 'expanded' : 'collapsed'">
                <div class="flex-col">
                    <div class="flex-col margin t16 white-space-normal">
                        <span class="subtitle1" i18n>Operation status</span>
                        <div class="flex-col ff-mono-regular">
                            <span [innerHTML]="parseAcquirerStatus(operation)"></span>
                            <span class="margin t8" i18n> Gateway status code: {{ operation.qp_status_code }} </span>
                            <span i18n> Gateway status message: {{ operation.qp_status_msg }} </span>
                        </div>
                        <ng-container
                            *ngIf="operation.operationStatus.color !== 'text-green' &&
                                    operation.aq_status_code && operation.aq_status_msg &&
                                    operation.aq_status_msg !== 'Rejected test operation'"
                        >
                            <br />
                            <span *ngIf="!operation.acquirer" i18n> No acquirer associated with this operation </span>
                            <ng-container *ngIf="operation.acquirer">
                                <br />
                                <ng-container [ngSwitch]="operation.aq_status_code">
                                    <span *ngSwitchCase="922"> <!-- Deliberately shows nothing as CH has no info on this --> </span>
                                    <span *ngSwitchCase="40413" i18n>The transaction was rejected as there were insufficient funds.</span>
                                    <ng-container *ngSwitchDefault>
                                        <ng-template *ngIf="!operation.aq_status_msg" i18n>Contact {{ operation.acquirer | qpAcquirer }} to learn more</ng-template>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="margin l16 t16">
                        <ng-container *ngIf="operation['3d_secure_status']">
                            <span i18n>3-D Secure status: {{ operation['3d_secure_status'] }}</span>
                            <span *ngIf="extendedSecureStatus(operation)" i18n> - Authentication request sent successfully but the cardholder could not be authenticated. </span>
                            <br />
                            <a [href]="helpdeskLink" target="_blank" *ngIf="(['Quickpay'] | qpMerchantType)" i18n>Read more</a>
                        </ng-container>
                    </div>

                    <qp-present-callback-status [operation]="operation" class="margin r16 t16 b16"></qp-present-callback-status>
                </div>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay; let i = dataIndex" (click)="showRow[i] = !showRow[i]" class="cursor"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expanded_detail']" class="no-print expandable-row"></tr>
</table>
