import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'qp-transaction-states',
    templateUrl: './transaction-states.html'
})
export class TransactionStatesComponent implements OnInit {
    @Input() public states: Array<string>;
    @Input() public state: number;
    @Output() public tabChange: EventEmitter<any> = new EventEmitter<any>();

    public statesTranslated = [
        { state: 'initial', translate: $localize`initial` },
        { state: 'new', translate: $localize`new` },
        { state: 'processed', translate: $localize`processed` },
        { state: 'pending', translate: $localize`pending` },
        { state: 'rejected', translate: $localize`rejected` },
        { state: 'all', translate: $localize`all` },
        { state: 'active', translate: $localize`active` },
        { state: 'cancelled', translate: $localize`cancelled` }
    ];

    public ngOnInit(): void {
        this.states.forEach(state => {
            this.statesTranslated.forEach(translate => {
                if (state === translate.state) {
                    state = translate.translate;
                }
            });
        });
    }

    public changeTab(data: MatTabChangeEvent): void {
        this.tabChange.emit({ state: data.index });
    }
}
