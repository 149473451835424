<h5 mat-dialog-title *ngIf="data.headline">
    <span class="pre-wrap">
        {{ data.headline }}
    </span>
</h5>

<mat-dialog-content class="grey-border-bottom">
    <ng-container *ngTemplateOutlet="data.template"></ng-container>
</mat-dialog-content>

<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button mat-button color="accent" (click)="dialogRef.close(false)" i18n>
        Close
    </button>
</mat-dialog-actions>
