import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';

@Component({
    selector: 'qp-thirdparty-show',
    templateUrl: './thirdparty-show.html',
    animations: [Animations.getFadeAnimation()]
})
export class ThirdpartyShowComponent extends BaseLoadingDirective implements OnInit {
    public component: any;
    public image_name: string;
    public previousParams: any[];
    public thirdparty: string;

    constructor(private activatedRoute: ActivatedRoute) {
        super();
    }

    public ngOnInit(): void {
        this.thirdparty = this.activatedRoute.snapshot.params.thirdparty;
    }
}
