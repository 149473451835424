import { ApiModule } from './api/api.module';
import { AuthorizesModule } from './statistics/authorizes.module';
import { GithubModule } from './github/github.module';
import { InvoiceModule } from './invoice/invoice.module';
import { NgModule } from '@angular/core';
import { ServerModule } from './server/server.module';

@NgModule({
    imports: [
        AuthorizesModule,
        ApiModule,
        GithubModule,
        InvoiceModule,
        ServerModule
    ]
})
export class EndpointsModule {}
