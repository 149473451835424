import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Agreement } from '@interfaces/agreement';
import { Balance } from '@models/balance';
import { Customer } from '@models/customer';
import { Invoice } from '@models/invoice';
import { Plan } from '@models/plan';
import { QpPaginatorConfig } from '@models/qp-pagintor';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { AgreementService } from '@services/agreement/agreement.service';
import { DownloaderService } from '@services/downloader/downloader.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { BalanceRepo } from 'app/endpoints/invoice/balance-repo';
import { CreditNoteRepo } from 'app/endpoints/invoice/credit-note-repo';
import { InvoiceRepo } from 'app/endpoints/invoice/invoice-repo';
import { MerchantSpecificationRepo } from 'app/endpoints/invoice/merchant-specification-repo';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'qp-invoices',
    templateUrl: './invoices.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class InvoicesComponent extends BaseLoadingDirective implements OnInit {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Input() public customer?: Customer = {} as Customer;

    public paginator_options: QpPaginatorConfig;
    public agreement?: Agreement;
    public balance: Balance;
    public displayedColumns: string[] = ['Invoice', 'Amount (VAT incl.)', 'Currency', 'Status', 'Paid at', 'Actions'];
    public data_source_paid = new MatTableDataSource<Invoice>();
    public data_source_unpaid = new MatTableDataSource<Invoice>();

    constructor(
        private balanceRepo: BalanceRepo,
        private creditNoteRepo: CreditNoteRepo,
        private downloadService: DownloaderService,
        private invoiceRepo: InvoiceRepo,
        private merchantSpecificationRepo: MerchantSpecificationRepo,
        private agreementService: AgreementService
    ) {
        super();
    }

    @Input()
    public set plan(plan: Plan | undefined) {
        if (plan && new QpMerchantTypePipe(this.agreementService).transform(['Quickpay'])) {
            this.getBalance();
        }
    }

    public ngOnInit(): void {
        this.agreement = this.agreementService.agreement;
        this.setPaginatorData();
        this.loadInvoices(this.paginator_options.page, this.paginator_options.page_size).subscribe();
    }

    public setPaginatorData(): void {
        this.paginator_options = {
            page_size_options: [10, 100],
            page_size: 10,
            page: 1,
            label: $localize`Invoices per page`
        };
    }

    public onPageChange(event: { page: number; page_size: number }): void {
        this.loadInvoices(event.page || 1, event.page_size || 10).subscribe();
    }

    public getBalance(): void {
        this.balanceRepo.get({}).subscribe(balance => {
            this.balance = balance;
        });
    }

    public downloadInvoiceAsPDF(invoice: Invoice): void {
        let observable: Observable<Blob>;

        if (invoice.type === 'invoices') {
            observable = this.invoiceRepo.getPDF(invoice.id);
        } else {
            observable = this.creditNoteRepo.getPDF(invoice.id);
        }

        observable.subscribe(file => {
            this.downloadService.saveFile(file, 'QuickPayPSP_' + invoice.type + '_' + invoice.attributes.number + '.pdf');
        });
    }

    public downloadInvoiceAsCSV(invoice: Invoice): void {
        this.merchantSpecificationRepo.getAllAsCSV(invoice.id).subscribe(merchantSpecifications => {
            this.downloadService.saveFile(merchantSpecifications, 'QuickPayPSP_' + invoice.type + '_' + invoice.attributes.number + '.csv');
        });
    }

    private loadInvoices(page = 1, page_size = 10): Observable<{ paid: Invoice[]; unpaid: Invoice[] }> {
        const params = {
            'page[size]': page_size,
            'page[number]': page
        };
        return this.invoiceRepo.getAll(params).pipe(
            map(invoices => invoices.map(invoice => ({
                ...invoice,
                status: this.getInvoiceStatus(invoice)
            }))),
            map(invoices => ({
                paid: invoices.filter(invoice => invoice.attributes.paid_at),
                unpaid: invoices.filter(invoice => !invoice.attributes.paid_at)
            })),
            tap(({ paid, unpaid }) => {
                this.data_source_paid.data = paid;
                this.data_source_unpaid.data = unpaid;
            })
        );
    }

    private getInvoiceStatus(invoice: Invoice): string {
        if (invoice.type === 'credit-notes') {
            return $localize`Credit nota (Invoice ${invoice.attributes.number})`;
        }

        if (invoice.attributes.paid_at) {
            if (!invoice.status) {
                return $localize`Paid`;
            }
        } else {
            if (!invoice.status) {
                return $localize`Not Paid`;
            }
        }

        return '';
    }
}
