import { Account } from '@interfaces/account';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { Agreement } from '@interfaces/agreement';
import { AgreementService } from '@services/agreement/agreement.service';
import { Animations } from 'app/animations/animations';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Branding } from '@models/branding';
import { BrandingRelease } from '@interfaces/branding-release';
import { BrandingRepo } from 'app/endpoints/api/branding-repo';
import { BrandingService } from '@services/branding/branding.service';
import { Component, OnInit } from '@angular/core';
import { DialogService } from '@widgets/dialog/dialog.service';
import { exhaustMap, finalize, map, switchMap } from 'rxjs/operators';
import { GithubRepo } from 'app/endpoints/github/github_repo';
import { MatTableDataSource } from '@angular/material/table';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { QpMerchantTypePipe } from '@pipes/qp-merchant-type.pipe';
import { QpPaginatorConfig } from '@models/qp-pagintor';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { SearchParams } from '@interfaces/params';

@Component({
    selector: 'qp-brandings',
    templateUrl: 'brandings.html',
    styleUrls: ['brandings.scss'],
    animations: [Animations.getFadeAnimation()]
})
export class BrandingsComponent extends BaseLoadingDirective implements OnInit {
    public newBranding: Branding | undefined;
    public agreement: Agreement | undefined;
    public defaultBrandingId: string;
    public hoverIndex = NaN;
    public disableRipple = false;
    public paginator_options: QpPaginatorConfig;
    public data_source = new MatTableDataSource<Branding>();
    public search_params: SearchParams = {};
    public display_paginator = false;

    constructor(
        private accountRepo: AccountRepo,
        private brandingRepo: BrandingRepo,
        private brandingService: BrandingService,
        private dialogService: DialogService,
        private githubRepo: GithubRepo,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.agreement = this.agreementService.agreement;
        this.defaultBrandingId = this.agreement?.account?.default_branding_id ? this.agreement.account.default_branding_id.toString() : '0';
        this.setPaginatorData();
    }

    public setPaginatorData(): void {
        this.search_params.page = 1;
        this.search_params.page_size = 10;

        this.paginator_options = {
            page_size_options: [10, 100],
            page: this.search_params.page,
            page_size: this.search_params.page_size,
            label: $localize`Brandings per page`
        };

        this.getBrandings();
    }

    public onPageChange(event: { page: number; page_size: number }): void {
        this.search_params.page = event.page;
        this.search_params.page_size = event.page_size;
        this.getBrandings();
    }

    public getBrandings(): void {
        this.brandingRepo.getAll(this.search_params).subscribe((brandings: Branding[]) => {
            this.data_source.data = brandings;

            if (!!this.search_params.page_size && this.data_source.data.length >= this.search_params.page_size) {
                this.display_paginator = true;
            }
        });
    }

    public hoverEnter(i: number | undefined): void {
        this.hoverIndex = i ? i : NaN;
    }

    public hoverLeave(): void {
        this.hoverIndex = NaN;
    }

    public hoverEnterPreview(): void {
        this.disableRipple = true;
    }

    public hoverLeavePreview(): void {
        this.disableRipple = false;
    }

    public createBranding(): void {
        let bodyText: string;
        if (new QpMerchantTypePipe(this.agreementService).transform(['Quickpay'])) {
            bodyText =
                $localize`Please note that there is a monthly additional charge for brandings.\nSee <a href="https://quickpay.net/prices-eu/" target="_blank">quickpay.net/prices-eu/</a> for more information.`;
        } else {
            bodyText = $localize`Please note that there is a monthly additional charge for brandings.`;
        }

        const fields: PromptField[] = [
            {
                placeholder: $localize`Name`,
                label: $localize`Name`,
                required: true,
                type: 'text',
                name: 'name'
            },
            {
                label: $localize`Branding template`,
                required: true,
                type: 'select',
                name: 'template',
                groups: this.templateChoises() as any // type mismatch which should be investigated
            }
        ];

        this.dialogService.prompt(
            $localize`Create new branding`,
            bodyText,
            $localize`Create`,
            fields
        ).afterClosed().subscribe((returnFields: PromptField[]) => {
            if (returnFields !== undefined) {
                this.saveBranding(returnFields[0].value, returnFields[1].value);
            }
        });
    }

    public templateChoises(): Array<any> {
        const templateChoises = [];

        templateChoises.push(
            {
                text: $localize`Empty`,
                value: 'Empty'
            },
            {
                text: $localize`Standard branding`,
                value: 'Standard'
            }
        );

        if (this.data_source.data.length) {
            this.data_source.data.forEach(branding => {
                templateChoises.push({
                    text: branding.name,
                    value: branding.id
                });
            });
        }

        return templateChoises;
    }

    public saveBranding(name: string, templateID: string): void {
        const branding = new Branding();
        branding.name = name;

        switch (templateID) {
            case 'Empty':
                this.brandingRepo.create(branding).subscribe(() => {
                    this.saveComplete();
                });
                break;

            case 'Standard':
                let createWithId = false;

                if (
                    this.agreementService.agreement?.account?.reseller &&
                    this.agreementService.agreement.account.reseller.default_branding_id &&
                    !!this.data_source.data[this.agreementService.agreement.account.reseller.default_branding_id]
                ) {
                    createWithId = true;

                    this.brandingRepo
                        .create(this.data_source.data[this.agreementService.agreement.account.reseller.default_branding_id])
                        .subscribe(() => {
                            this.saveComplete();
                        });
                }

                if (!createWithId) {
                    this.brandingRepo.create(branding).pipe(
                        switchMap(newBranding =>
                            this.githubRepo.getLatest().pipe(
                                switchMap((data: BrandingRelease) => this.githubRepo.get(data.tag_name)),
                                map((githubData: any) => {
                                    const loading = this.dialogService.loading($localize`Creating the branding, this might take a while.`);
                                    this.brandingService.extractZip(githubData, newBranding).pipe(
                                        finalize(() => loading.close())
                                    ).subscribe({
                                        complete: () => {
                                            this.data_source.data.push(newBranding);
                                            this.saveComplete();
                                        }
                                    });
                                })
                            )
                        )
                    ).subscribe();
                }
                break;

            default:
                this.brandingRepo.copy(Number(templateID)).pipe(
                    map(newBranding => {
                        this.newBranding = newBranding;
                        this.newBranding.name = name;
                        return newBranding;
                    }),
                    exhaustMap(newBranding => this.brandingRepo.update(newBranding))
                ).subscribe(() => {
                    this.saveComplete();
                });
                break;
        }
    }

    public saveComplete(): void {
        this.getBrandings();
        this.newBranding = undefined;
    }

    public makeBrandingDefault(): void {
        const params: any = {};
        if (this.defaultBrandingId === '0') {
            params.default_branding_id = null;
        } else {
            params.default_branding_id = parseInt(this.defaultBrandingId, 10);
        }

        this.accountRepo.update(params).subscribe((account: Account) => {
            if (!this.agreementService.agreement?.account) {
                return;
            }
            this.agreementService.agreement.account = account;
            if (this.agreement) {
                this.agreement.account = account;
            }
            this.defaultBrandingId = this.agreement?.account?.default_branding_id
                ? this.agreement.account.default_branding_id.toString()
                : '0';
            this.snackBar.open($localize`Default branding updated`);
        });
    }
}
