import { Injectable } from '@angular/core';
import { CardRepo } from '@endpoints/api/card-repo';
import { PaymentRepo } from '@endpoints/api/payment-repo';
import { PayoutRepo } from '@endpoints/api/payout-repo';
import { SubscriptionGroupRepo } from '@endpoints/api/subscription-group-repo';
import { SubscriptionRepo } from '@endpoints/api/subscription-repo';
import { TransactionParams } from '@interfaces/params';
import { Card } from '@models/card';
import { Payment } from '@models/payment';
import { Payout } from '@models/payout';
import { Subscription } from '@models/subscription';
import { SubscriptionGroup } from '@models/subscription-group';
import { Transaction } from '@models/transaction';
import { CurrencyService } from '@services/currency/currency.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { Observable, filter, map, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TransactionsService {

    constructor(
        private paymentRepo: PaymentRepo,
        private payoutRepo: PayoutRepo,
        private subscriptionRepo: SubscriptionRepo,
        private cardsRepo: CardRepo,
        private currencyService: CurrencyService,
        private dialogService: DialogService,
        private subscriptionGroupRepo: SubscriptionGroupRepo
    ) {}

    public searchPayments(params: TransactionParams): Observable<Payment[]> {
        return params.id ?
            this.paymentRepo.get<Payment>(params.id).pipe(map(payment => [payment])) :
            this.paymentRepo.getAll(params);
    }

    public searchPayouts(params: TransactionParams): Observable<Payout[]> {
        return params.id ?
            this.payoutRepo.get<Payout>(params.id).pipe(map(payout => [payout])) :
            this.payoutRepo.getAll(params);
    }

    public searchSubscriptions(params: TransactionParams): Observable<Subscription[]> {
        return params.id ?
            this.subscriptionRepo.get<Subscription>(params.id).pipe(map(subscription => [subscription])) :
            this.subscriptionRepo.getAll(params);
    }

    public searchCards(params: TransactionParams): Observable<Card[]> {
        return params.order_id ?
            this.cardsRepo.get<Card>(Number(params.order_id)).pipe(map(card => [card])) :
            this.cardsRepo.getAll(params);
    }

    public captureSubscriptionGroup(group?: SubscriptionGroup): Observable<any> {
        const fields: PromptField[] = [
            {
                placeholder: $localize`Unique order_id prefix`,
                label: $localize`Unique order_id prefix`,
                required: true,
                type: 'text',
                name: 'prefix'
            }
        ];

        const amount = this.currencyService.toCurrency(
            group?.amount ?? NaN,
            group?.currency ?? '',
            'localized'
        );

        return this.dialogService.prompt(
            $localize`Capture on group`,
            $localize`Capture ${group?.currency} ${amount} from all subscriptions in the group`,
            $localize`Capture`,
            fields
        ).afterClosed().pipe(
            filter(value => value !== undefined),
            switchMap((returnFields: PromptField[]) => this.subscriptionGroupRepo.capture(
                group,
                returnFields[0].value
            ))
        );
    }

    public deleteCustomerInfo(payment: Transaction): Transaction {
        const transaction = {
            ...payment,
            shipping_address: {},
            invoice_address: {}
        };
        return transaction;
    }
}
