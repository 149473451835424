export class RegExpNumbers extends RegExp {
    constructor() {
        super(/^[0-9]*$/);
    }
}

export class RegExpFourNumbers extends RegExp {
    constructor() {
        super(/^[0-9]{4}$/);
    }
}

export class RegExpNoNumbers extends RegExp {
    constructor() {
        super(/[^0-9]/g);
    }
}
