import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { FilterDialogComponent } from '@widgets/filters/filters.dialog.component';
import { FilterParams } from '@interfaces/params';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class FilterService {
    constructor(
        private dialog: MatDialog
    ) {}

    public parseFilters(org_filters: FilterParams, new_filters: any): FilterParams {
        if (new_filters.acquirer) {
            org_filters.acquirer = new_filters.acquirer.acquirer;
        } else if (org_filters.hasOwnProperty('acquirer')) {
            org_filters.acquirer = undefined;
        }

        if (new_filters.active_acquirer) {
            org_filters.active_acquirer = new_filters.active_acquirer.active_acquirer;
        } else if (org_filters.hasOwnProperty('active_acquirer')) {
            org_filters.active_acquirer = undefined;
        }

        if (new_filters.country_code && new_filters.country_code.country_code) {
            org_filters.country_code = new_filters.country_code.country_code;
        } else if (org_filters.hasOwnProperty('country_code')) {
            org_filters.country_code = undefined;
        }

        if (new_filters.date) {
            org_filters.min_time = new_filters.date.min_time;
            org_filters.max_time = undefined;
            org_filters.timestamp = new_filters.date.timestamp;

            if (!!new_filters.date.max_time) {
                org_filters.max_time = DateTime.fromJSDate(new_filters.date.max_time).toUTC().toJSDate();
            }
        } else if (org_filters.hasOwnProperty('min_time') || org_filters.hasOwnProperty('max_time')) {
            org_filters.max_time = undefined;
            org_filters.min_time = undefined;
            org_filters.timestamp = undefined;
        }

        if (new_filters.expired) {
            org_filters.expired = new_filters.expired.expired;
        } else if (org_filters.hasOwnProperty('expired')) {
            org_filters.expired = undefined;
        }

        if (new_filters.live_transactions) {
            org_filters.live_transactions = new_filters.live_transactions;
        } else if (org_filters.hasOwnProperty('live_transactions')) {
            org_filters.live_transactions = undefined;
        }

        if (new_filters.locale) {
            org_filters.locale = new_filters.locale.locale;
        } else if (org_filters.hasOwnProperty('locale')) {
            org_filters.locale = undefined;
        }

        if (new_filters.shopsystem && new_filters.shopsystem.shopsystem) {
            org_filters.shopsystem = new_filters.shopsystem.shopsystem;
        } else if (org_filters.hasOwnProperty('shopsystem')) {
            org_filters.shopsystem = undefined;
        }

        if (new_filters.subscription_group) {
            org_filters.subscription_group = new_filters.subscription_group.subscription_group;
        } else if (org_filters.hasOwnProperty('subscription_group')) {
            org_filters.subscription_group = undefined;
        }

        if (new_filters.suspected_fraud) {
            org_filters.suspected_fraud = new_filters.suspected_fraud;
        } else if (org_filters.hasOwnProperty('suspected_fraud')) {
            org_filters.suspected_fraud = undefined;
        }

        if (new_filters.suspended) {
            org_filters.suspended = new_filters.suspended;
        } else if (org_filters.hasOwnProperty('suspended')) {
            org_filters.suspended = undefined;
        }

        return org_filters;
    }

    public show(filters: FilterParams, transaction_type: string): MatDialogRef<FilterDialogComponent> {
        return this.dialog.open(FilterDialogComponent, {
            minWidth: '350px',
            data: {
                active_filters: filters,
                transaction_type
            }
        });
    }
}
