import { Component, Inject } from '@angular/core';
import { CardToken } from '@models/card-token';
import { Operation } from '@models/operation';
import { Payout } from '@models/payout';
import { Transaction } from '@models/transaction';
import { CardRepo } from 'app/endpoints/api/card-repo';
import { PayoutRepo } from 'app/endpoints/api/payout-repo';
import { TransactionService } from '@services/transaction/transaction.service';
import { EMPTY, timer } from 'rxjs';
import { exhaustMap, expand, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AgreementService } from '@services/agreement/agreement.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-card-payout-dialog-component',
    templateUrl: 'card-create-payout.html'
})
export class CardPayoutDialogComponent {
    public transaction: Transaction;
    public payout: any = {
        currency: '',
        order_id: undefined
    };
    public auth: any = {
        acquirer: undefined,
        amount: 0,
        autocapture: undefined,
        autofee: undefined,
        card: undefined
    };
    public creating_payment = false;

    public get last_operation(): Operation | null {
        return this.transactionService.lastOperation(this.transaction);
    }

    constructor(
        private router: Router,
        private transactionService: TransactionService,
        private cardRepo: CardRepo,
        private payoutRepo: PayoutRepo,
        private agreementService: AgreementService,
        public dialogRef: MatDialogRef<CardPayoutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public goToPayout(): void {
        this.router.navigate(['/account', this.agreementService.agreement?.account?.id, 'payout', this.transaction.id]);
        this.dialogRef.close(true);
    }

    public createPayout(): void {
        this.creating_payment = true;
        this.auth.acquirer = this.data.card.acquirer;
        this.cardRepo.tokens(this.data.card.id).pipe(
            exhaustMap((token: CardToken) => {
                this.auth.card = { token: token.token };
                return this.payoutRepo.create(this.payout).pipe(
                    exhaustMap((new_payout: Payout) =>
                        this.payoutRepo.credit(new_payout.id ?? NaN, this.auth).pipe(
                            expand((payout: Payout) => {
                                if (!this.transactionService.lastOperation(payout)?.pending) {
                                    return EMPTY;
                                }

                                return timer(2000).pipe(
                                    exhaustMap(_ => this.payoutRepo.get<Payout>(payout.id ?? NaN))
                                );
                            }),
                            finalize(() => this.creating_payment = false)
                        )
                    )
                );
            })
        ).subscribe((transaction: Transaction) => {
            this.transaction = transaction;
        });
    }

    public generateOrderId(): void {
        this.payout.order_id = Math.floor(Math.random() * 100000000000 + 1);
    }
}
