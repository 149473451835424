import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Payment } from '@models/payment';

@Component({
    selector: 'qp-refund-payment-dialog',
    templateUrl: 'refund-payment-dialog.html'
})
export class RefundPaymentDialogComponent implements OnInit {
    public type: string;
    public amount: number;
    public vat_rate: number;
    public customer_name: string;

    constructor(
        public dialogRef: MatDialogRef<RefundPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public payment: Payment
    ) {}

    public ngOnInit(): void {
        this.amount = NaN;
        this.type = 'partial';
        this.vat_rate = 0.25;

        if (this.payment?.invoice_address?.name) {
            this.customer_name = this.payment.invoice_address.name;
        }
    }

    public refund(): void {
        this.dialogRef.close({
            amount: this.type === 'partial' ? this.amount : this.payment.balance,
            vat_rate: this.vat_rate
        });
    }
}
