<form [formGroup]="businessForm" class="flex-col rg8">
    <span class="text-grey" i18n>Shortly describe your business model and estimate your average transaction amount and monthly turnover.</span>

    <mat-form-field>
        <mat-label i18n>Shop name</mat-label>
        <input matInput name="application_business_model_trading_name" formControlName="trading_name" type="text" autofocus (change)="modelBusinessModel('trading_name', $event.target)" required />
        <mat-error *ngIf="trading_name.errors?.required" i18n>A trading name is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Business model</mat-label>
        <textarea matInput name="application_business_model_description" formControlName="description" rows="5" (change)="modelBusinessModel('description', $event.target)" required> </textarea>
        <mat-error *ngIf="description.errors?.required" i18n>A business model description is required.</mat-error>
    </mat-form-field>

    <mat-checkbox name="application_business_model_recurring" formControlName="recurring" aria-label="Recurring" value="true" (change)="onCheckboxChange('recurring', $event)">
        <span i18n>We have recurring transactions</span>
    </mat-checkbox>

    <mat-checkbox name="application_business_model_physical_delivery" formControlName="physical_delivery" aria-label="Physical_delivery" value="true" (change)="onCheckboxChange('physical_delivery', $event)" i18n>We physically deliver our products</mat-checkbox>

    <mat-form-field *ngIf="application.business_model?.physical_delivery">
        <mat-label i18n>We physically deliver our products in</mat-label>
        <mat-select name="application_business_model.delivery_delay" formControlName="delivery_delay" (selectionChange)="onSelectChange('delivery_delay', $event.value)" [required]="!!application.business_model?.physical_delivery">
            <mat-option value="less5days" i18n>Less than 5 days</mat-option>
            <mat-option value="5to10days" i18n>5 to 10 days</mat-option>
            <mat-option value="10to20days" i18n>10 to 20 days</mat-option>
            <mat-option value="more20days" i18n>More than 20 days</mat-option>
        </mat-select>
        <mat-error *ngIf="delivery_delay.errors?.required" i18n>A delivery period is required with physical delivery.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Currency</mat-label>
        <mat-select formControlName="currency" (selectionChange)="onSelectChange('estimate_currency', $event.value)" required>
            <mat-option *ngFor="let currency of currencies" [value]="currency.name"> {{ currency.name }} </mat-option>
        </mat-select>
        <mat-error *ngIf="currency.errors?.required" i18n>A currency is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Expected monthly turnover (average)</mat-label>
        <span matPrefix>{{ application.business_model?.estimate_currency }} &nbsp;</span>
        <input matInput name="application_business_model_estimated_monthly_turnover" formControlName="turnover" type="number" (change)="modelBusinessModel('estimated_monthly_turnover', $event.target)" required />
        <mat-error *ngIf="turnover.errors?.required" i18n>An average monthly turnover amount is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n>Transaction amount (average)</mat-label>
        <span matPrefix>{{ application.business_model?.estimate_currency }} &nbsp;</span>
        <input matInput name="application_business_model_estimated_average_transaction_amount" formControlName="transaction_amount" type="number" (change)="modelBusinessModel('estimated_average_transaction_amount', $event.target)" required />
        <mat-error *ngIf="transaction_amount.errors?.required" i18n>An average transaction amount is required.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="businessForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
