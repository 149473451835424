import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Settlement } from '@models/clearhaus-settlement';

@Component({
    selector: 'qp-settlement-payouts',
    templateUrl: './settlement-payouts.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementPayoutsComponent {
    @Input() public settlement?: Settlement;
}
