import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionGroupRepo } from '@endpoints/api/subscription-group-repo';
import { SubscriptionGroup } from '@models/subscription-group';

@Component({
    selector: 'qp-filters-subscription-group',
    templateUrl: './filters-subscription-group.html'
})
export class FiltersSubscriptionGroupComponent implements OnInit {
    @Output() public selected_groupChange = new EventEmitter();
    public subscription_groups: SubscriptionGroup[];
    public _data: SubscriptionGroup;

    @Input()
    public get selected_group(): SubscriptionGroup {
        return this._data;
    }

    public set selected_group(val: SubscriptionGroup) {
        this._data = val;
        this.selected_groupChange.emit(this._data);
    }

    constructor(
        private subscriptionGroupRepo: SubscriptionGroupRepo
    ) {}

    public ngOnInit(): void {
        this.subscription_groups = [];
        this.subscriptionGroupRepo.getAll({ page_size: 100 }).subscribe((subscribtiongroups: SubscriptionGroup[]) => {
            this.subscription_groups = subscribtiongroups;
        });
    }

    public compareFn = (o1: SubscriptionGroup, o2: SubscriptionGroup): boolean => {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    };
}
