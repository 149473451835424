import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Account } from '@interfaces/account';
import { Customer } from '@models/customer';
import { Plan } from '@models/plan';
import { AgreementService } from '@services/agreement/agreement.service';
import { Country } from '@services/country/country.interface';
import { CountryService } from '@services/country/country.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Animations } from 'app/animations/animations';
import { AccountRepo } from 'app/endpoints/api/account-repo';

@Component({
    selector: 'qp-billing-wizard',
    templateUrl: './wizard.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class BillingWizardComponent extends BaseLoadingDirective implements OnInit, AfterViewInit {
    @ViewChild('stepper') public stepper: MatStepper;

    @Output() public accountChange = new EventEmitter<Account>();
    @Input() public customer?: Customer;
    @Input() public plan?: Plan;
    @Output() public finished = new EventEmitter();
    @Output() public accountUpdated = new EventEmitter();

    public countries: Country[];
    public addressForm: UntypedFormGroup;
    private _account: Account | undefined;

    constructor(
        private accountRepo: AccountRepo,
        private countryService: CountryService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        super();
    }

    @Input()
    public get account(): Account | undefined {
        return this._account;
    }
    public set account(account: Account | undefined) {
        this._account = account;
        this.accountChange.emit(account);
    }

    public ngOnInit(): void {
        this.account = this.agreementService.agreement?.account;
        this.countries = this.countryService.getCountries();

        this.setupAddressForm();
    }

    public ngAfterViewInit(): void {
        if (this.addressForm.valid) {
            setTimeout(() => {
                this.stepper.next();
            }, 600);
        }
    }

    public setupAddressForm(): void {
        this.addressForm = new UntypedFormGroup({
            name: new UntypedFormControl({
                value: this.account?.customer_address?.name,
                disabled: !this.agreementService.hasPermission('/account', 'patch')
            }, [Validators.required]),
            street: new FormControl({
                value: this.account?.customer_address?.street,
                disabled: !this.agreementService.hasPermission('/account', 'patch')
            }, [Validators.required]),
            zip_code: new FormControl({
                value: this.account?.customer_address?.zip_code,
                disabled: !this.agreementService.hasPermission('/account', 'patch')
            }, [Validators.required]),
            city: new FormControl({
                value: this.account?.customer_address?.city,
                disabled: !this.agreementService.hasPermission('/account', 'patch')
            }, [Validators.required]),
            country_code: new FormControl({
                value: this.account?.customer_address?.country_code,
                disabled: !this.agreementService.hasPermission('/account', 'patch')
            }, [Validators.required]),
            region: new FormControl({
                value: this.account?.customer_address?.region,
                disabled: !this.agreementService.hasPermission('/account', 'patch')
            }),
            vat_no: new FormControl({
                value: this.account?.customer_address?.vat_no,
                disabled: !this.agreementService.hasPermission('/account', 'patch')
            }, [Validators.required])
        });
    }

    public finish(): void {
        this.finished.emit();
    }

    public updateAccount(): void {
        const address = this.addressForm.value;

        this.accountRepo
            .update({
                customer_address: address
            })
            .subscribe((returnAccount: Account) => {
                if (!this.agreementService.agreement) {
                    return;
                }
                this.account = returnAccount;
                this.agreementService.agreement.account = returnAccount;
                this.snackBar.open($localize`Account updated`);
            });
    }

    public addRemoveShopUrl(urls: Array<string>): void {
        if (!this.account?.shop_urls) {
            return;
        }
        this.account.shop_urls = urls;
    }

    public updateShopUrl(): void {
        if (!this.account?.shop_urls) {
            return;
        }

        const urls = this.account.shop_urls;
        this.accountRepo.update({ shop_urls: urls }).subscribe((account: Account) => {
            if (!this.agreementService.agreement) {
                return;
            }
            this.account = account;
            this.agreementService.agreement.account = account;
            this.snackBar.open($localize`Shop url updated`);
        });

        this.accountUpdated.emit();
    }
}
