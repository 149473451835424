import { AddressFormComponent } from './address-form/address-form.component';
import { CommonModule } from '@angular/common';
import { CreateMerchantFormComponent } from './create-merchant-form/create-merchant-form.component';
import { CustomMaterialModule } from '@widgets/layout/material/custom-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ShopsystemsModule } from '@widgets/shopsystems/shopsystems.module';
import { ShopurlsModule } from '@widgets/shopurls/shopurls.module';

@NgModule({
    imports: [
        CommonModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ShopsystemsModule,
        ShopurlsModule
    ],
    declarations: [
        AddressFormComponent,
        CreateMerchantFormComponent
    ],
    exports: [
        AddressFormComponent,
        CreateMerchantFormComponent
    ]
})
export class CustomFormsModule {}
