import { AdminTransactionDetailsBasicCardComponent } from './card-basic/admin-basic-card.component';
import { AdminTransactionDetailsBasicOtherComponent } from './other-basic/admin-basic-other.component';
import { AdminTransactionFraudComponent } from './fraud/admin-fraud.component';
import { CommonModule } from '@angular/common';
import { CustomerInfoAddressComponent } from './customer-info/customer-info-address/customer-info-address.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';
import { TransactionAcquirerComponent } from './acquirer/transaction-acquirer.component';
import { TransactionCardBasicComponent } from './card-basic/transaction-card-basic.component';
import { TransactionCustomerInfoComponent } from './customer-info/customer-info.component';
import { TransactionDetailsBasketComponent } from './basket/transaction-details-basket.component';
import { TransactionDetailsShippingComponent } from './shipping/transaction-details-shipping.component';
import { TransactionDetailsVariablesComponent } from './variables/transaction-details-variables.component';
import { TransactionFraudComponent } from './fraud/transaction-fraud.component';
import { TransactionFraudReportComponent } from './fraud-report/transaction-fraud-report.component';
import { TransactionFraudReportDialogComponent } from './fraud-report/transaction-fraud-report.dialog.component';
import { TransactionOtherBasicComponent } from './other-basic/transaction-other-basic.component';
import { TransactionRetendedComponent } from './retented/transaction-retended.component';
import { AdminOperationsComponent } from './operations/admin-operations.component';
import { OperationsComponent } from './operations/operations.component';
import { PresentCallbackComponent } from './operations/presentCallback/present-callback.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        PipesModule,
        RouterModule
    ],
    exports: [
        AdminTransactionDetailsBasicCardComponent,
        AdminTransactionDetailsBasicOtherComponent,
        AdminTransactionFraudComponent,
        TransactionAcquirerComponent,
        TransactionCardBasicComponent,
        TransactionCustomerInfoComponent,
        TransactionDetailsBasketComponent,
        TransactionDetailsShippingComponent,
        TransactionDetailsVariablesComponent,
        TransactionFraudComponent,
        TransactionFraudReportComponent,
        TransactionFraudReportDialogComponent,
        TransactionOtherBasicComponent,
        TransactionRetendedComponent,
        AdminOperationsComponent,
        OperationsComponent,
        PresentCallbackComponent
    ],
    declarations: [
        AdminTransactionDetailsBasicCardComponent,
        AdminTransactionDetailsBasicOtherComponent,
        AdminTransactionFraudComponent,
        CustomerInfoAddressComponent,
        TransactionAcquirerComponent,
        TransactionCardBasicComponent,
        TransactionCustomerInfoComponent,
        TransactionDetailsBasketComponent,
        TransactionDetailsShippingComponent,
        TransactionDetailsVariablesComponent,
        TransactionFraudComponent,
        TransactionFraudReportComponent,
        TransactionFraudReportDialogComponent,
        TransactionOtherBasicComponent,
        TransactionRetendedComponent,
        AdminOperationsComponent,
        OperationsComponent,
        PresentCallbackComponent
    ]
})
export class TransactionDetailsModule {}
