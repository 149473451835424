<table mat-table #table [dataSource]="dataSource" mat-card-image>
    <div matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef i18n>Period</th>
        <td mat-cell *matCellDef="let row">
            <div *ngIf="row?.period?.start_date; else noperiod">
                <qp-date-field [date]="row?.period?.start_date"></qp-date-field> -
                <qp-date-field [date]="row?.period?.end_date"></qp-date-field>
            </div>
            <ng-template #noperiod>-</ng-template>
        </td>
    </div>

    <div matColumnDef="sales">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Sales</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.summary?.sales && row?.summary?.sales !== 0; else nosales">
                    {{ row.summary.sales | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #nosales>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="refunds">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Refunds</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.summary?.refunds && row?.summary?.refunds !== 0; else norefunds">
                    {{ row.summary.refunds | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #norefunds>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="chargebacks">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Chargebacks</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.summary?.chargebacks && row?.summary?.chargebacks !== 0; else nochargebacks">
                    {{ row.summary.chargebacks | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #nochargebacks>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="fees">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Fees</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.summary?.fees && row?.summary?.fees !== 0; else nofees">
                    {{ row.summary.fees | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #nofees>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="other_postings">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Other postings</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.summary?.other_postings && row?.summary?.other_postings !== 0; else nootherpostings">
                    {{ row.summary.other_postings | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #nootherpostings>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Total</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.summary?.net && row?.summary?.net !== 0; else nototal">
                    {{ row.summary.net | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #nototal>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="payout">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Payout</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.payout?.amount && row?.payout?.amount !== 0; else noamount">
                    {{ row.payout.amount | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #noamount>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="payout_date">
        <th mat-header-cell *matHeaderCellDef i18n>Payout date</th>
        <td mat-cell *matCellDef="let row">
            <div *ngIf="row?.payout?.date; else nopayoutdate">
                <qp-date-field [date]="row.payout.date"></qp-date-field>
            </div>
            <ng-template #nopayoutdate>-</ng-template>
        </td>
    </div>

    <div matColumnDef="reserve">
        <th mat-header-cell *matHeaderCellDef class="align-right" i18n>Reserve</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex-row justify-end items-center">
                <span *ngIf="row?.reserve?.amount && row?.reserve?.amount !== 0; else noreserve">
                    {{ row.reserve.amount | qpCurrency: row.currency }} {{ row.currency }}
                </span>
                <ng-template #noreserve>-</ng-template>
            </div>
        </td>
    </div>

    <div matColumnDef="reserve_date">
        <th mat-header-cell *matHeaderCellDef i18n>Reserve date</th>
        <td mat-cell *matCellDef="let row">
            <div *ngIf="row?.reserve?.date; else noreservedate">
                <qp-date-field [date]="row.reserve.date"></qp-date-field>
            </div>
            <ng-template #noreservedate>-</ng-template>
        </td>
    </div>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row
        class="cursor"
        *matRowDef="let row; columns: displayColumns"
        [routerLink]="['../settlement', row.id]"
        >
    </tr>
</table>
