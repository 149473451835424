import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NoResultsComponent } from './no-results.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [NoResultsComponent],
    exports: [NoResultsComponent]
})
export class NoResultsModule {}
