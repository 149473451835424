import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Account } from '@interfaces/account';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { Person } from '@models/clearhaus-application/person';
import { Country } from '@services/country/country.interface';
import { CUSTOMER_MAT_DATEPICKER_DATEFORMAT } from '@widgets/layout/material/custom-mat-datepicker-format';
import { FileInput, FileInputComponent } from 'ngx-material-file-input';

@Component({
    selector: 'qp-clearhaus-signup-step-three',
    templateUrl: './director.html',
    styleUrls: ['../datepicker.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: CUSTOMER_MAT_DATEPICKER_DATEFORMAT
        }
    ]
})
export class ClearhausSignupFormStepThreeComponent implements AfterViewInit {
    @Input() public account: Account;
    @Input() public countries: Country[] = [];

    @Output() public directorChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    @ViewChild('directorAddressIDInput')
    public addressIDInput: FileInputComponent;
    @ViewChild('directorPictureIDInput')
    public pictureIDInput: FileInputComponent;

    public _director: Person = new Person();
    public addressIDPlaceholder: string;
    public directorForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        social_security_number: new UntypedFormControl('', Validators.required),
        date_of_birth: new UntypedFormControl('', Validators.required),
        address_line_1: new UntypedFormControl('', Validators.required),
        zipcode: new UntypedFormControl('', Validators.required),
        city: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl('', Validators.required),
        pictureID: new UntypedFormControl('', Validators.required),
        addressID: new UntypedFormControl('', Validators.required)
    });
    public maxDate = new Date();
    public minDate = new Date('1900');
    public pictureIDPlaceholder: string;

    public get address_line_1(): UntypedFormControl {
        return this.directorForm.get('address_line_1') as UntypedFormControl;
    }
    public get addressID(): UntypedFormControl {
        return this.directorForm.get('addressID') as UntypedFormControl;
    }
    public get city(): UntypedFormControl {
        return this.directorForm.get('city') as UntypedFormControl;
    }
    public get country(): UntypedFormControl {
        return this.directorForm.get('country') as UntypedFormControl;
    }
    public get date_of_birth(): UntypedFormControl {
        return this.directorForm.get('date_of_birth') as UntypedFormControl;
    }
    public get name(): UntypedFormControl {
        return this.directorForm.get('name') as UntypedFormControl;
    }
    public get pictureID(): UntypedFormControl {
        return this.directorForm.get('pictureID') as UntypedFormControl;
    }
    public get social_security_number(): UntypedFormControl {
        return this.directorForm.get('social_security_number') as UntypedFormControl;
    }
    public get zipcode(): UntypedFormControl {
        return this.directorForm.get('zipcode') as UntypedFormControl;
    }

    @Input() public get director(): Person {
        return this._director;
    }
    public set director(value: Person) {
        this._director = value;
        this.setFormValues();
        if (value?.files) {
            value.files.forEach(file => {
                if (
                    file.label &&
                    ['address_legitimation', 'picture_legitimation'].includes(
                        file.label
                    )
                ) {
                    if (file.label === 'picture_legitimation') {
                        this.pictureID.setValue(file.name);
                        if (this.pictureIDInput) {
                            this.pictureIDInput.value = new FileInput([
                                this.generateHollowFile(file)
                            ]);
                        }
                    }
                    if (file.label === 'address_legitimation') {
                        this.addressID.setValue(file.name);
                        if (this.addressIDInput) {
                            this.addressIDInput.value = new FileInput([
                                this.generateHollowFile(file)
                            ]);
                        }
                    }
                }
            });
        }
        this.directorChange.emit(value);
    }

    constructor(private _adapter: DateAdapter<any>) {}

    public ngAfterViewInit(): void {
        if (this.account?.locale) {
            this._adapter.setLocale(this.account.locale);
        }
        this.directorForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(!this.directorForm.invalid);
        });
        this.addressID.valueChanges.subscribe((fileInput: FileInput) => {
            if (fileInput.files?.length) {
                this.addFile(fileInput.files[0], 'address_legitimation');
            }
        });
        this.pictureID.valueChanges.subscribe((fileInput: FileInput) => {
            if (fileInput.files?.length) {
                this.addFile(fileInput.files[0], 'picture_legitimation');
            }
        });

        this.setFormValues();
        this.validityChange.emit(!this.directorForm.invalid);
    }

    public setFormValues(): void {
        this.address_line_1.setValue(this.director.address_line_1);
        this.city.setValue(this.director.city);
        this.country.setValue(this.director.country);
        this.date_of_birth.setValue(
            this.director.date_of_birth ? this.director.date_of_birth : null
        );
        this.name.setValue(this.director.name);
        this.social_security_number.setValue(
            this.director.social_security_number
        );
        this.zipcode.setValue(this.director.zipcode);
    }

    public generateHollowFile(file: any): File {
        // NOTE: Needed for ngx-material-file-input
        return new File([''], file.name, { type: file.content_type });
    }

    public addFile(file: any, label: string): void {
        const directorFile: DirectorFile = {
            label,
            name: file.name,
            size: file.size,
            content_type: file.type,
            file
        };
        if (!this.director.files) {
            this.director.files = [];
        }
        this.director.files.push(directorFile);
    }

    public removeFile(i: number): void {
        (this.director.files as any)[i].remove = true;
        this.validateDirectorFiles();
    }

    public validateDirectorFiles(): void {
        this.director.files = this.director.files?.filter(file => {
            if (file.label === 'picture_legitimation') {
                this.pictureID.setValue(!file.remove ? file.name : null);
                this.pictureID.updateValueAndValidity();
                return !file.remove;
            }

            if (file.label === 'address_legitimation') {
                this.addressID.setValue(!file.remove ? file.name : null);
                this.addressID.updateValueAndValidity();
                return !file.remove;
            }
        });
    }

    public modelDirector(
        propName: keyof Person,
        eventTarget: EventTarget | any
    ): void {
        if (!eventTarget) {
            return;
        }
        const input = eventTarget as HTMLInputElement;
        const person: Partial<Person> = {
            [propName]: input.value
        };

        if (person.hasOwnProperty('social_security_number')) {
            person.social_security_number = person.social_security_number
                ?.match(/[0-9]/g)
                ?.join('');
        }

        this.director = { ...this.director, ...person };
    }

    public handleDateChange(value: any): void {
        if (value) {
            this.director = {
                ...this.director,
                date_of_birth: value.format('YYYY-MM-DD')
            };
        }
    }
}
