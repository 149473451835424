<div *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div>
        <button mat-button [routerLink]="['../brandings']" color="accent">&lt; Brandings</button>
    </div>

    <mat-card class="card02">
        <div class="flex-col rg8">
            <qp-section-heading headingText="Default branding configs" icon="satellite" i18n-headingText></qp-section-heading>
            <mat-card-subtitle class="flex-col">
                <span class="text-grey" i18n>Change the default behaviour of the payment window.</span>
                <span *ngIf="(['Quickpay'] | qpMerchantType)"><a [href]="helpdeskLink" target="_blank" i18n>Read more on our helpdesk</a>.</span>
            </mat-card-subtitle>
        </div>

        <form class="flex-col rg36" name="config" autocomplete="off" [formGroup]="configForm">
            <mat-list class="no-p">
                <mat-list-item>
                    <span matListItemTitle i18n>Add cardholder input field</span>
                    <span matListItemLine class="text-grey" i18n>Adds an input field for the cardholder name.</span>
                    <mat-slide-toggle
                        matListItemMeta
                        aria-label="Enable card holder input field"
                        formControlName="enable_card_holder_field"
                        (change)="changeToggle('enable_card_holder_field')"
                    >
                    </mat-slide-toggle>
                </mat-list-item>

                <mat-list-item *ngIf="showEnable3dCardField">
                    <span matListItemTitle i18n> Enable 3-D card field </span>
                    <span matListItemLine class="text-grey" i18n>Lets the cardholder choose to go through 3-D Secure.</span>
                    <mat-slide-toggle
                        matListItemMeta
                        aria-label="Enable 3d card field"
                        formControlName="enable_3d_card_field"
                        (change)="changeToggle('enable_3d_card_field')"
                    >
                    </mat-slide-toggle>
                </mat-list-item>

                <mat-list-item>
                    <span matListItemTitle i18n>Autojump</span>
                    <span matListItemLine class="text-grey" i18n>The cursor autojumps to the next input field upon completion.</span>
                    <mat-slide-toggle matListItemMeta aria-label="Autojump" formControlName="autojump" (change)="changeToggle('autojump')">
                    </mat-slide-toggle>
                </mat-list-item>

                <mat-list-item>
                    <span matListItemTitle i18n>3-D Secure v2</span>
                    <span matListItemLine class="text-grey" i18n>Default to 3-D Secure v2.</span>
                    <mat-slide-toggle
                        matListItemMeta
                        aria-label="3dsv2"
                        formControlName="3dsv2"
                        (change)="changeToggle('3dsv2')">
                    </mat-slide-toggle>
                </mat-list-item>
            </mat-list>

            <div class="flex-row"
                [class.error-border]="formError"
                [class.warning-border]="configForm.dirty && !formError"
                [class.success-border]="formSaved && configForm.pristine">

                <div class="flex-col rg12 flex-1">
                    <mat-form-field class="flex-1">
                        <mat-label i18n>Default HTML title</mat-label>
                        <input matInput formControlName="title" />
                    </mat-form-field>

                    <mat-form-field class="flex-1">
                        <mat-label i18n>Reservation notice</mat-label>
                        <input matInput formControlName="reservation_notice" />
                    </mat-form-field>
                </div>
            </div>

            <div class="flex-row">
                    <qp-form-state-info
                        [form_dirty]="configForm.dirty"
                        [form_pristine]="configForm.pristine"
                        [form_saved]="formSaved"
                        [form_error]="formError">
                    </qp-form-state-info>
                </div>

            <qp-form-save-discard
                *ngIf="configForm.dirty"
                [form_dirty]="configForm.dirty"
                [form_invalid]="configForm.invalid"
                (form_save)="save(false)"
                (form_discard)="discardChanges()">
            </qp-form-save-discard>
        </form>
    </mat-card>
</div>
