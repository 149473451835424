export const CARD_COLUMNS = [
    'select',
    'id',
    'created_at',
    'acquirer',
    'flags',
    'last_operation',
    'actions'
];

export const PAYMENT_COLUMNS = [
    'select',
    'id',
    'order_id',
    'created_at',
    'currency',
    'amount',
    'balance',
    'fee',
    'method',
    'acquirer',
    'state',
    'flags',
    'last_operation',
    'date_last_operation',
    'actions',
    'invoice_name',
    'shipping_name'
];

export const PAYOUT_COLUMNS = [
    'select',
    'id',
    'order_id',
    'created_at',
    'acquirer',
    'text_on_statement',
    'method',
    'flags',
    'last_operation'
];

export const SUBSCRIPTION_COLUMNS = [
    'select',
    'id',
    'order_id',
    'created_at',
    'method',
    'acquirer',
    'description',
    'state',
    'flags',
    'last_operation',
    'text_on_statement'
];
