import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isEqual } from 'lodash-es';

import { Agreement } from '@interfaces/agreement';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PermissionTemplatesService } from '@services/permission-templates/permission-templates.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { SHOPSYSTEMS } from './shopsystems.constants';
import { AgreementService } from '@services/agreement/agreement.service';
import { QpAccountTypePipe } from '@pipes/qp-account-type.pipe';

@Component({
    selector: 'qp-shopsystems',
    templateUrl: './shopsystems.html'
})
export class ShopsystemsComponent implements OnInit {
    @Output() public shopsystemChange = new EventEmitter();
    @Input() public readonly: boolean;
    @Input() public optional = true;
    @Input() public placeholder: string;
    @Output() public save = new EventEmitter<any>();

    public shopsystems: Array<any> = SHOPSYSTEMS;
    public selected_shopsystem: string;
    public custom_shopsystem?: string = undefined;
    public shopsystem_value: string;
    public agreement: Agreement;
    public permission_templates: any;

    constructor(
        private agreementRepo: AgreementRepo,
        private dialogService: DialogService,
        private permissionTemplateService: PermissionTemplatesService,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {}

    @Input()
    public get shopsystem(): string {
        return this.shopsystem_value;
    }
    public set shopsystem(val) {
        this.shopsystem_value = val;
        this.shopsystemChange.emit(this.shopsystem_value);
    }

    public ngOnInit(): void {
        this.selected_shopsystem = this.shopsystem;
        if (this.shopsystem) {
            if (!this.isInShopSystems(this.shopsystem)) {
                this.selected_shopsystem = '';
                this.custom_shopsystem = this.shopsystem;
            }
        }

        this.agreementRepo.getAll({ page_size: 100 }).subscribe(agreements => {
            agreements.forEach(agreement => {
                if (agreement.description === 'API user') {
                    this.agreement = agreement;
                }
            });
        });

        this.permission_templates = this.permissionTemplateService.getTemplates();
    }

    public selectShopsystem(): void {
        if (!new QpAccountTypePipe(this.agreementService).transform(['Reseller'])) {
            if (this.selected_shopsystem !== 'Other') {
                this.shopsystem = this.selected_shopsystem;
                this.saveSystem();

                if (this.doesRequirePermissions(this.shopsystem) && !this.missingPermissions(this.shopsystem)) {
                    const labelText = $localize`${this.selected_shopsystem} requires a specific set of permissions for the API User to process payments.\nUpdate the permissions for the API User.`;

                    this.dialogService.confirm(
                        $localize`Update permissions?`,
                        labelText,
                        $localize`Update permissions`,
                        $localize`Close`
                    ).afterClosed().subscribe((confirmed: boolean) => {
                        if (confirmed) {
                            this.setPermissions(this.shopsystem);
                        }
                    });
                }
            }
        } else {
            this.shopsystem = this.selected_shopsystem;
            this.saveSystem();

            if (this.doesRequirePermissions(this.shopsystem)) {
                this.dialogService.alert(
                    $localize`Permissions required`,
                    this.selected_shopsystem +
                    ' ' +
                    $localize`requires specific permissions.` +
                    '\n' +
                    $localize`Remember to set the permissions for the merchant after it is created.`
                );
            }
        }
    }

    public missingPermissions(templateName: string): boolean {
        const sessionAccountType = this.agreementService.agreement?.account?.type;
        if (!sessionAccountType) {
            return true;
        }
        let template: any;
        let tempGroup: any;
        for (const group of Object.keys(this.permission_templates[sessionAccountType])) {
            tempGroup = this.permission_templates[sessionAccountType][group];
            for (const temp of tempGroup) {
                if (temp.name === templateName) {
                    template = temp.acl_permissions;
                }
            }
        }

        if (!template) {
            return true;
        }

        return isEqual(template, this.agreement.acl_permissions);
    }

    public setPermissions(templateName: string): void {
        const accountType = this.agreement.account?.type;
        if (!accountType) {
            return;
        }
        let template: any;
        for (const key of Object.keys(this.permission_templates[accountType])) {
            this.permission_templates[accountType][key].forEach((entry: any) => {
                if (entry.name === templateName) {
                    template = entry.acl_permissions;
                }
            });
        }

        this.agreement.acl_permissions = template;
        this.agreementRepo.replace(this.agreement).subscribe((returnAgreement: Agreement) => {
            this.agreement = returnAgreement;
            this.snackBar.open($localize`Permissions updated`);
        });
    }

    public setCustomShopsystem(): void {
        this.shopsystem = this.custom_shopsystem ?? '';
        this.saveSystem();
    }

    public saveSystem(): void {
        this.save.emit({ shopsystem: this.shopsystem });
    }

    public isInShopSystems(system: string): boolean {
        let result = false;
        for (const shopsystem of this.shopsystems) {
            if (shopsystem['name'] === system) {
                result = true;
            }
        }
        return result;
    }

    public doesRequirePermissions(system: string): boolean {
        let result = false;
        for (const shopsystem of this.shopsystems) {
            if (shopsystem['name'] === system && shopsystem['options']) {
                result = true;
            }
        }
        return result;
    }
}
