<mat-card class="card02 avoid-page-break">
    <mat-card-content class="flex-col rg8">

        <div class="flex-50" *ngIf="transaction?.acquirer || (!!transaction?.operations && transaction.operations[0]?.request?.data?.acquirer)">
            <span class="subtitle1" i18n>Acquirer</span><br />
            {{ (transaction.acquirer | qpAcquirer) || (transaction.operations && transaction.operations[0] &&
            (transaction.operations[0].request?.data?.acquirer | qpAcquirer)) }}
        </div>

        <div class="flex-50" *ngIf="transaction?.acquirer === 'clerhaus'">
            <span class="subtitle1" i18n>Account</span><br/>
            {{ transaction.metadata.ch_mid }}
        </div>

        <div *ngIf="transaction.rrn">
            <div class="flex-row cg8 items-center">
                <span class="subtitle1">RRN</span>
                <mat-icon color="accent" class="s18 cursor" inline (click)="display_rrn_info = !display_rrn_info"> info </mat-icon>
                <span class="text-grey" *ngIf="display_rrn_info">Retrieval Reference Number</span>
            </div>
            <div>{{ transaction.rrn}}</div>
        </div>

        <div *ngIf="transaction.arn">
            <div class="flex-row cg8 items-center">
                <span class="subtitle1">ARN</span>
                <mat-icon color="accent" class="s18 cursor" inline (click)="display_arn_info = !display_arn_info"> info </mat-icon>
                <span class="text-grey" *ngIf="display_arn_info">Acquirer Reference Number</span>
            </div>
            <div>{{ transaction.arn}}</div>
        </div>

        <div class="flex-50" *ngIf="transaction?.acquirer === 'trustly'">
            <span class="subtitle1" i18n>Trustly Order ID</span><br />
            {{ transaction.operations[transaction.operations.length - 1].data?.orderid }}
        </div>

        <div class="flex-50" [hidden]="!transaction.fee" i18n-matTooltip matTooltip="Acquirer fee - The fee is calculated using the fee calculations provided under Settings - Acquirers -  transaction.acquirer  - Fees">
            <span class="subtitle1" i18n>Fee</span><br />
            {{ transaction.fee || 0 | qpCurrency: transaction.currency }}
        </div>

        <div class="flex-row" *ngIf="transaction?.facilitator">
            <div class="flex-50">
                <span class="subtitle1">
                    <span i18n>Facilitator</span>
                    <mat-icon matTooltip="{{ transaction.facilitator | qpAcquirer }}" class="text-light-blue s18">smartphone</mat-icon>
                </span><br />
                {{ transaction.facilitator | qpAcquirer }}
            </div>
        </div>

        <div class="flex-row" *ngIf="transaction?.metadata?.moto">
            <div class="flex-1">
                <span class="subtitle1">
                    <span i18n>MoTo Transactions</span>
                    <mat-icon class="text-light-blue s18">mail</mat-icon>
                </span><br />
                {{ transaction.metadata.moto | qpHumanizeBool }}
            </div>
        </div>

        <div class="flex-row" *ngIf="branding">
            <div class="flex-50">
                <span class="subtitle1" i18n>Branding ID</span>
                <br />
                {{ branding.id }}
            </div>
            <div class="flex-50">
                <span class="subtitle1" i18n>Branding name</span>
                <br />
                {{ branding.name }} <a [routerLink]="['/account', account_id, 'settings', 'branding', branding.id]">
                    <mat-icon class="text-light-blue s18">link</mat-icon>
                </a>
            </div>
        </div>

        <div class="flex-row" *ngIf="transaction?.metadata?.shopsystem_name || transaction?.metadata?.shopsystem_version">
            <div class="flex-50">
                <span class="subtitle1" i18n>Shopsystem name</span>
                <br />
                {{ transaction.metadata.shopsystem_name }}
            </div>
            <div class="flex-50">
                <span class="subtitle1" i18n>Module version</span>
                <br />
                {{ transaction.metadata.shopsystem_version | qpAcquirer}}
            </div>
        </div>

        <span *ngIf="(['Quickpay'] | qpMerchantType) && display_warning" class="flex-col rg8">
            <div class="flex-row cg8">
                <mat-icon class="text-yellow">warning</mat-icon>
                <span>
                    <span i18n>This transaction was created using an old version of the shopsystem module.</span>
                    <br>
                    <span i18n>Please update to the latest version.</span>
                </span>
            </div>
        </span>
    </mat-card-content>
</mat-card>
