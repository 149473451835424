import { BehaviorSubject, Observable } from 'rxjs';
import { DialogService } from '@widgets/dialog/dialog.service';
import { ExporterFieldsDialogComponent } from './dialog/exporter-fields.component';
import { Injectable } from '@angular/core';
import { Params, SearchParams } from '@interfaces/params';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface IRepo {
    getAll(params: Params): Observable<any[]>;
    adminGetAll?(params: any): Observable<any[]>;
}

@Injectable({
    providedIn: 'root'
})
export class ExporterService {
    public parsedObjects: Array<unknown>;

    constructor(
        private dialogService: DialogService,
        private dialogRef: MatDialog
    ) {}

    public exportObjects(
        repo: IRepo,
        params: Params,
        csvHeaders: Array<string>,
        fields: any,
        name: string,
        parser: any
    ): void {
        delete params.page;

        if (params.hasOwnProperty('acquirer')) {
            const acq: any = (params['acquirer' as keyof Params] as string).toLowerCase();
            params['acquirer' as keyof Params] = acq;
        }

        const dialogParams = new BehaviorSubject<Params>({ page: 0, page_size: params.page_size });
        this.dialogService.loading('Exporting - please wait', dialogParams);
        this.parsedObjects = [];
        if (params.hasOwnProperty('merchant_id')) {
            this.adminExportFromServer(repo, params, csvHeaders, fields, name, parser, dialogParams);
        } else {
            this.exportFromServer(repo, params, csvHeaders, fields, name, parser, dialogParams);
        }
    }

    public adminExportFromServer(
        repo: IRepo,
        params: any,
        csvHeaders: Array<string>,
        fields: any,
        name: string,
        parser: any,
        dialogParams: any
    ): void {
        const parsedObjects: any[] = [];
        const tempParams = dialogParams.value;
        tempParams.page++;
        dialogParams.next(tempParams);

        if (!repo.adminGetAll) {
            return;
        }

        repo.adminGetAll(this.paramsSearchFilter(params)).subscribe((objects: any[]) => {
            for (const object of objects) {
                const parsedObject = parser.parse(object, fields);

                for (const key in parsedObject) {
                    if (parsedObject[key] === null || parsedObject[key] === undefined) {
                        parsedObject[key] = '';
                    }
                }

                parsedObjects.push(parsedObject);
            }

            this.parsedObjects.push(...parsedObjects);

            if (params.page_size === objects.length) {
                params.page_key = objects[objects.length - 1][params.sort_by || 'id'];
                this.adminExportFromServer(repo, params, csvHeaders, fields, name, parser, dialogParams);
            } else {
                this.dialogRef.closeAll();
                this.downloadCSVFile(this.parsedObjects, csvHeaders, name);
            }
        });
    }

    public exportFromServer(
        repo: IRepo,
        params: any,
        csvHeaders: Array<string>,
        fields: any,
        name: string,
        parser: any,
        dialogParams: any
    ): void {
        const parsedObjects: any[] = [];
        const tempParams = dialogParams.value;
        tempParams.page++;
        dialogParams.next(tempParams);

        repo.getAll(this.paramsSearchFilter(params)).subscribe((objects: any[]) => {
            for (const object of objects) {
                const parsedObject = parser.parse(object, fields);

                for (const key in parsedObject) {
                    if (parsedObject[key] === null || parsedObject[key] === undefined) {
                        parsedObject[key] = '';
                    }
                }

                parsedObjects.push(parsedObject);
            }

            this.parsedObjects.push(...parsedObjects);

            if (params.page_size === objects.length) {
                params.page_key = objects[objects.length - 1][params.sort_by || 'id'];
                this.exportFromServer(repo, params, csvHeaders, fields, name, parser, dialogParams);
            } else {
                this.dialogRef.closeAll();
                this.downloadCSVFile(this.parsedObjects, csvHeaders, name);
            }
        });
    }

    private paramsSearchFilter(params: any): SearchParams {
        if (params.search) {
            if (params.search_by === 'id') {
                params.id = params.search;
            } else {
                params.order_id = params.search;
            }
        }

        return params;
    }

    public getFieldsToExport(fields: { display: boolean }): MatDialogRef<any> {
        return this.dialogService.component(ExporterFieldsDialogComponent, {
            fields
        });
    }

    public downloadCSVFile(data: any, cdvHeaders: any, filename = 'csvdata'): void {
        const csvData = this.convertToCSVData(data, cdvHeaders);
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);

        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', `${filename}_${Date()}` + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    public convertToCSVData(objArray: any, headerArray: any): string {
        let str = headerArray.toString().replaceAll(',', ';') + '\r\n';

        objArray.forEach((element: any) => {
            let line = '';

            for (const index in headerArray) {
                if (!!element[headerArray[index].replaceAll(' ', '_')]) {
                    line += element[headerArray[index].replaceAll(' ', '_')] + ';';
                } else {
                    line += '"";';
                }
            }

            str += line.slice(0, -1) + '\r\n';
        });

        return str;
    }

    public cleanCsvValue(value: string): string {
        return value
            .replace(/^\s*|\s*$/g, '') // remove leading & trailing space
            .replace(/^"|"$/g, '') // remove " on the beginning and end
            .replace(/""/g, '"'); // replace "" with "
    }

    public csvToJSON(content: string, separator: string): Array<any> {
        const lines = content.split(new RegExp('\n(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'));
        const result = [];
        const start = 0;
        const columnCount = lines[0].split(separator).length;

        for (let i = start; i < lines.length; i++) {
            const obj: { [key: string]: any } = {};
            const currentline = lines[i].split(new RegExp(separator + '(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'));
            if (currentline.length === columnCount) {
                for (let k = 0; k < currentline.length; k++) {
                    obj[k] = this.cleanCsvValue(currentline[k]);
                }
                result.push(obj);
            }
        }
        return result;
    }
}
