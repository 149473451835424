import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateTime } from 'luxon';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'qp-filters-date',
    templateUrl: './filters-date.html'
})
export class FiltersDateComponent implements OnInit {
    @Output() public dataChange = new EventEmitter();
    public range: string;
    public lastDays: any;

    public dataValue: any;
    @Input()
    public get data(): any {
        return this.dataValue;
    }
    public set data(val) {
        this.dataValue = val;
        this.dataChange.emit(this.dataValue);
    }

    constructor() {}

    public ngOnInit(): void {
        if (this.data.min_time !== null || this.data.max_time !== null) {
            this.range = 'specify';
        }
        this.data.timestamp = this.data.timestamp || 'created_at';
    }

    public changeMaxDate(event: MatDatepickerInputEvent<Date>): void {
        if (!event.value) {
            return;
        }
        this.data.max_time = DateTime.fromISO(event.value.toISOString()).endOf('day').toJSDate();
    }

    public changeLastDays(): void {
        this.data.min_time = DateTime.local().minus({ days: this.lastDays }).startOf('day');
        this.data.max_time = null;
    }

    public changeDates(): void {
        switch (this.range) {
            case 'today':
                this.data.min_time = DateTime.local().startOf('day');
                this.data.max_time = null;
                break;
            case 'yesterday':
                this.data.min_time = DateTime.local().minus({ days: 1 }).startOf('day');
                this.data.max_time = DateTime.local().minus({ days: 1 }).endOf('day').toJSDate();
                break;
            case 'last_7_days':
                this.data.min_time = DateTime.local().minus({ days: 7 }).startOf('day');
                this.data.max_time = null;
                break;
            case 'this_month':
                this.data.min_time = DateTime.local().startOf('month');
                this.data.max_time = null;
                break;
            case 'last_month':
                this.data.min_time = DateTime.local().minus({ months: 1 }).startOf('month');
                this.data.max_time = DateTime.local().minus({ months: 1 }).endOf('month').toJSDate();
                break;
        }
    }
}
