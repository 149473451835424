<ng-container *ngIf="transaction && transaction.basket && transaction.basket.length">
    <mat-card class="flex-col card02 avoid-page-break">
        <mat-card-title class="font-weight-normal" i18n> Basket </mat-card-title>
        <table class="card-table">
            <thead>
                <tr>
                    <th i18n>Qty</th>
                    <th i18n>Item no</th>
                    <th i18n>Item name</th>
                    <th i18n>Item price</th>
                    <th i18n>Vat rate</th>
                </tr>
            </thead>
            <tbody>
                <tr class="border-bottom" *ngFor="let value of transaction.basket">
                    <td>{{ value.qty }}</td>
                    <td>{{ value.item_no }}</td>
                    <td>{{ value.item_name }}</td>
                    <td>{{ value.item_price }}</td>
                    <td>{{ value.vat_rate }}</td>
                </tr>
            </tbody>
        </table>
    </mat-card>
</ng-container>
