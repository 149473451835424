import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TransactionStatesComponent } from './transaction-states.component';



@NgModule({
    declarations: [TransactionStatesComponent],
    exports: [TransactionStatesComponent],
    imports: [
        CommonModule,
        MatTabsModule
    ]
})
export class TransactionStatesModule {}
