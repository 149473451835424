<div class="min-width-750px" *ngIf="!loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../merchant']" class="mat-button-back" color="accent" i18n>&lt; Back to merchant</button>
    </div>

    <mat-card class="card02">
        <div class="flex-row cg16">
            <mat-icon color="accent">public</mat-icon>
            <h5 i18n>Mail-notifications</h5>
        </div>

        <mat-table #table [dataSource]="dataSource">
            <!-- User Column -->
            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef i18n>User</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{ row.user.system_user ? row.user.name : row.user.email }}
                </mat-cell>
            </ng-container>

            <!-- card Column -->
            <ng-container matColumnDef="card">
                <mat-header-cell *matHeaderCellDef i18n>Card authorize</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox
                        *ngIf="subscriptionPermission"
                        class="qp-small"
                        (change)="save(row, 'card_authorize_success')"
                        [(ngModel)]="row.events.card_authorize_success.checked"
                    >
                    </mat-checkbox>
                    <mat-icon *ngIf="!subscriptionPermission && row.events.card_authorize_success.checked">check</mat-icon>
                </mat-cell>
            </ng-container>

            <!-- Payment Column -->
            <ng-container matColumnDef="payment">
                <mat-header-cell *matHeaderCellDef i18n>Payment authorize</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox
                        *ngIf="subscriptionPermission"
                        class="qp-small"
                        (change)="save(row, 'payment_authorize_success')"
                        [(ngModel)]="row.events.payment_authorize_success.checked"
                    >
                    </mat-checkbox>
                    <mat-icon *ngIf="!subscriptionPermission && row.events.payment_authorize_success.checked">check</mat-icon>
                </mat-cell>
            </ng-container>

            <!-- subscription Column -->
            <ng-container matColumnDef="subscription">
                <mat-header-cell *matHeaderCellDef i18n>Subscription authorize</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox
                        *ngIf="subscriptionPermission"
                        class="qp-small"
                        (change)="save(row, 'subscription_authorize_success')"
                        [(ngModel)]="row.events.subscription_authorize_success.checked"
                    >
                    </mat-checkbox>
                    <mat-icon *ngIf="!subscriptionPermission && row.events.subscription_authorize_success.checked">check</mat-icon>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="display_columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: display_columns;"></mat-row>
        </mat-table>
    </mat-card>
</div>
