import { PaymentLink } from '@models/payment-link';
import { AdminTransaction } from '@models/admin-transaction';
import { AdminMetadata } from '@models/metadata';

export class AdminPayment extends AdminTransaction {
    public balance: number;
    public accepted: boolean;
    public acquirer: string;
    public order_id: string;
    public link: PaymentLink;
    public created_at: Date;
    public currency: string;
    public variables: any;
    public branding_id?: number;
    public subscription_id?: number;
    public metadata: AdminMetadata;
    public authAmount: number;
}
