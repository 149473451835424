<mat-card class="card02 dashboard-card">
    <div class="flex-col rg16">
        <div class="flex-row cg8">
            <img src="assets/images/integrations/loyaltykey_dark_logo.png" class="height-15" />
            <span class="flex-1"></span>
            <div>
                <button mat-raised-button class="mat-button-back" [routerLink]="['../thirdparties']" i18n>
                    Back to Integrations
                </button>
            </div>
        </div>

        <div class="flex-col rg8">
            <mat-card-title i18n>Activate marketing</mat-card-title>
            <p i18n>
                Apply for a Partnership with Loyalty Key who handles the loyalty programs Spar Nord Cashback, Cashback in partnership with Visa and BonusKroner to be marketed across platforms and media to access 150.000+ possible new customers to your online store.
                After submitting the application, you can see and sign the Loyalty Key contract directly from here, and be ready to go live within 1-3 week days. Loyalty Key is a partner of Quickpay. Read more about Loyalty Key and how to reach new customers.
                <a href="https://www.loyaltykey.com/quickpay-landingpage/" target="_blank">Read more</a>
            </p>
        </div>

        <form class="flex-col rg16" name="editloyaltykey" autocomplete="off" [formGroup]="application_form">
            <div class="flex-col rg8">
                <mat-card-title i18n>Contact information</mat-card-title>
                <p i18n>Contact information for the person responsible for creating the application.</p>
            </div>

            <div class="flex-row-mobile-col cg4p">
                <mat-form-field class="flex-49-mobile-100">
                    <mat-label i18n>First name</mat-label>
                    <input name="first_name" matInput formControlName="first_name" />
                </mat-form-field>

                <mat-form-field class="flex-49-mobile-100">
                    <mat-label i18n>Last name</mat-label>
                    <input name="last_name" matInput formControlName="last_name" />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label i18n>Email</mat-label>
                <input name="contact_email" matInput formControlName="contact_email" />
            </mat-form-field>

            <mat-card-title i18n>Company information</mat-card-title>

            <mat-form-field>
                <mat-label i18n>Registration number</mat-label>
                <input name="registration_number" matInput formControlName="registration_number" />
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n>Name</mat-label>
                <input name="name" matInput formControlName="name" />
            </mat-form-field>

            <div class="flex-row-mobile-col cg4p">
                <mat-form-field class="flex-49-mobile-100">
                    <mat-label i18n>Email</mat-label>
                    <input name="email" matInput formControlName="email" />
                </mat-form-field>

                <qp-phone-number-field class="flex-49-mobile-100" formControlName="phone"></qp-phone-number-field>
            </div>

            <div class="flex-row-mobile-col cg4p">
                <mat-form-field class="flex-28-mobile-100">
                    <mat-label i18n>Zip code</mat-label>
                    <input matInput aria-label="Zip code" formControlName="postcode" />
                </mat-form-field>

                <mat-form-field class="flex-68-mobile-100">
                    <mat-label i18n>City</mat-label>
                    <input matInput aria-label="city" formControlName="city" />
                </mat-form-field>
            </div>

            <mat-form-field class="flex-48-mobile-100">
                <mat-label i18n>Country</mat-label>
                <mat-select aria-label="Country" formControlName="country">
                    <mat-option *ngFor="let country of countries" [value]="country">
                        {{ country.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="flex-col rg8">
                <mat-card-title i18n>Webshop</mat-card-title>
                <p i18n>Please add at least one live webshop associated with your Company Registration number to continue.</p>
                <p i18n>Loyalty Key will need to access your website, to be able to review your application.</p>
            </div>

            <div *ngFor="let website of websites; let i = index">
                <div class="flex-row cg16">
                    <p>{{ website.name }}</p>
                    <p>{{ website.url }}</p>
                    <div class="flex-row cg8" *ngIf="websites.length > 1">
                        <span (click)="removeSite(i)" class="text-red cursor" i18n>Remove</span>
                    </div>
                </div>
            </div>

            <div>
                <button mat-raised-button color="accent" (click)="addSite()" i18n>
                    Add website
                </button>
            </div>

            <div class="flex-row cg8">
                <div class="flex-row justify-start items-center">
                    <p i18n>What cashback % would you like to offer members?</p>&nbsp;
                    <mat-form-field>
                        <input matInput aria-label="rate" formControlName="rate" />
                    </mat-form-field>
                    &nbsp;<p>%</p>
                </div>

                <small class="text-grey flex-33-mobile-100" i18n>Loyalty Key charges you monthly for the cashback + 4% commission. For example: If the cashback is set to 10% it will cost you 14% of the transaction amount.</small>
            </div>

            <div class="flex-col rg8">
                <mat-card-title i18n>Marketing plans</mat-card-title>
                <p i18n>
                    Loyalty Key communicates to +150.000 members of the loyalty programs accessing +80.000 weekly newsletter subscribers, +60.000 push message subscribers and +5.000 daily active users. Marketing of your webshop will happen on Loyalty Key's platforms: apps, websites, pushmessages, top banners and more, customized and segmentized to your audience.
                </p>
                <a href="https://www.loyaltykey.com/quickpay-landingpage/" target="_blank" i18n>Read more about packages here.</a>
            </div>

            <div class="flex-row justify-center stretch">
                <mat-card class="flex-25-mobile-100">
                    <div class="flex-row cg8">
                        <mat-card-title i18n>No Cure No Pay</mat-card-title>
                        <span class="flex-1"></span>
                        <div>
                            <button mat-raised-button (click)="selectPlan('membership fee')" *ngIf="selected_plan !== 'membership fee'">Select</button>
                            <button mat-raised-button (click)="selectPlan('membership fee')" color="accent" *ngIf="selected_plan === 'membership fee'" i18n>Selected</button>
                        </div>
                    </div>

                    <div class="flex-col rg8">
                        <p i18n>I want to start without a marketing package.</p>
                    </div>
                </mat-card>
                <mat-card class="flex-25-mobile-100" *ngFor="let plan of plans">
                    <div class="flex-row cg8">
                        <mat-card-title>{{ plan.plan | uppercase }}</mat-card-title>
                        <span class="flex-1"></span>
                        <div>
                            <button mat-raised-button (click)="selectPlan(plan.plan)" *ngIf="selected_plan !== plan.plan" i18n>Select</button>
                            <button mat-raised-button (click)="selectPlan(plan.plan)" color="accent" *ngIf="selected_plan === plan.plan" i18n>Selected</button>
                        </div>
                    </div>

                    <div class="flex-col rg8">
                        <div class="flex-row cg8">
                            <p i18n>Price per month: {{ plan.price_month | qpCurrency: 'DKK' }} DKK</p>
                            <small class="text-grey" i18n>1st month free</small>
                        </div>
                        <p i18n>Value per month: {{ plan.value_month | qpCurrency: 'DKK' }} DKK</p>
                        <p i18n>Binding period: 6 months</p>
                    </div>
                </mat-card>
            </div>

            <div class="flex-col rg8">
                <p i18n>As a Quickpay customer you get the first month of your marketing package for free.</p>
            </div>

            <mat-checkbox (change)="acceptTerms($event.checked)" i18n>
                By clicking the checkbox below you confirm that you have read- and agreed to the Loyalty Key
                <a *ngIf="!is_danish_merchant" href="https://storage.loyaltykey.com/terms_and_conditions.pdf" target=”_blank”>Partnership Agreement</a>
                <a *ngIf="is_danish_merchant" href="https://storage.loyaltykey.com/leveringsbetingelser.pdf" target=”_blank”>Partnership Agreement</a>
                and that you agree that Quickpay transfers this formular to Loyalty Key for a fricition free onboarding to get started with marketing of your webshop to attract new customers.
            </mat-checkbox>

            <div class="flex-row justify-center">
                <qp-spinner-button
                    color="accent"
                    [disabled]="!terms_accepted"
                    [showSpinner]="saving"
                    i18n-btnText
                    btnText="Sign up to Loyalty Key"
                    (btnClick)="send()"
                >
                </qp-spinner-button>
            </div>
        </form>
    </div>
</mat-card>
