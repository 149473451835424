<span class="flex-row cg12" *ngIf="form_dirty && !form_error">
    <mat-icon class="text-yellow">announcement</mat-icon>
    <span i18n>Unsaved changes</span>
</span>

<span class="flex-row cg12" *ngIf="form_saved && form_pristine">
    <mat-icon class="text-green">done</mat-icon>
    <span i18n>Settings saved</span>
</span>

<div class="flex-col rg12" *ngIf="form_error">
    <span class="flex-row cg12">
        <mat-icon class="text-darkred">close</mat-icon>
        <span i18n>Changes were not saved</span>
    </span>
    <span class="pre-wrap" [innerHTML]="errorMessage()"></span>
</div>
