import { AdminPayment } from '@models/admin-payment';
import { ApiClient } from './api-client';
import { FraudReport } from '@models/fraud-report';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Payment } from '@models/payment';
import { PaymentLinkUrl } from '@models/payment-link-url';
import { Transaction } from '@models/transaction';

@Injectable({
    providedIn: 'root'
})
export class PaymentRepo {
    constructor(
        private apiClient: ApiClient
    ) {}

    public adminGetAll(params?: any): Observable<AdminPayment[]> {
        return this.apiClient.get<AdminPayment[]>('/transactions', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new AdminPayment(), item)))
        );
    }

    public adminGetPayment(transaction_id: number, params: any): Observable<AdminPayment[]> {
        return this.apiClient.get<AdminPayment[]>(`/transactions/${transaction_id}/payments`, { params }).pipe(
            map(payments => payments.map(payment => Object.assign(payment, new AdminPayment())))
        );
    }

    public createFraudReport(id: number, params: FraudReport): Observable<FraudReport> {
        return this.apiClient.post<FraudReport>(`/payments/${id}/fraud-report`, params).pipe(
            map((item: any) => Object.assign(new FraudReport(), item))
        );
    }

    public get<Payment>(id: number): Observable<Payment> {
        return this.apiClient.get<Payment>(`/payments/${id}`).pipe(
            map((item) => Object.assign(new Payment(), item) as Payment)
        );
    }

    public getAll(params = {}): Observable<Payment[]> {
        return this.apiClient.get<Payment>('/payments', { params }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Payment(), item)))
        );
    }

    public getByOrderId(order_id: number): Observable<Payment | undefined> {
        return this.apiClient.get<Payment>('/payments', { params: { order_id } }).pipe(
            map((items: any) => items.map((item: any) => Object.assign(new Payment(), item))),
            map((payments: Payment[]) => payments[0])
        );
    }

    public capture(id: number, params: any): Observable<Payment> {
        params = params || {};
        return this.apiClient.post<Payment>(`/payments/${id}/capture?synchronized`, params).pipe(
            map((payment: any) => Object.assign(new Payment(), payment))
        );
    }

    public update(transaction: Transaction): Observable<Payment> {
        return this.apiClient.patch<Payment>(`/payments/${transaction.id}`, transaction).pipe(
            map((payment: any) => Object.assign(new Payment(), payment))
        );
    }

    public updateVariables(id: number, params: any): Observable<Payment> {
        return this.apiClient.patch<Payment>(`/payments/${id}`, params).pipe(
            map((payment: any) => Object.assign(new Payment(), payment))
        );
    }

    public create(params: any): Observable<Payment> {
        params = params || {};
        return this.apiClient.post<Payment>('/payments', params).pipe(
            map((payment: any) => Object.assign(new Payment(), payment))
        );
    }

    public destroyLink(id: number): Observable<Payment> {
        return this.apiClient.delete<Payment>(`/payments/${id}/link`);
    }

    public replaceLink(id: number, params: any): Observable<PaymentLinkUrl> {
        return this.apiClient.put<Payment>(`/payments/${id}/link`, params).pipe(
            map((item: any) => Object.assign(new PaymentLinkUrl(), item))
        );
    }

    public authorize(id: number, params: any): Observable<Payment> {
        params = params || {};
        return this.apiClient.post<Payment>(`/payments/${id}/authorize`, params).pipe(
            map((payment: any) => Object.assign(new Payment(), payment))
        );
    }

    public refund(id: number, params: any): Observable<Payment> {
        params = params || {};
        return this.apiClient.post<Payment>(`/payments/${id}/refund?synchronized`, params).pipe(
            map((payment: any) => Object.assign(new Payment(), payment))
        );
    }

    public cancel(id: number): Observable<Payment> {
        return this.apiClient.post<Payment>(`/payments/${id}/cancel?synchronized`, {}).pipe(
            map((payment: any) => Object.assign(new Payment(), payment))
        );
    }

    public getMpsStatus(id: number): Observable<any> {
        return this.apiClient.get<any>(`/acquirers/mobilepaysubscriptions/direct/payments/${id}/status`);
    }
}
