export const ACQUIRERSSTATUS: {[key: string]: { code: string[]; text: string}} = {
    bambora: { code: ['000', '900'], text: 'Approved' },
    base: { code: ['20000'], text: 'Approved' },
    clearhaus: { code: ['20000'], text: 'Approved' },
    handelsbanken: { code: ['000', '900'], text: 'Approved' },
    klarnapayemnts: { code: ['ok', '1'], text: 'Approved' },
    mobilepay: { code: ['0', '000'], text: 'Approved' },
    nets: { code: ['000', '900'], text: 'Approved' },
    paypal: { code: ['200'], text: 'Approved' },
    paysafecard: { code: ['0'], text: 'Approved' },
    sofort: { code: ['200'], text: 'Approved' },
    swedbank: { code: ['000', '900'], text: 'Approved' },
    swish: { code: ['200', '201'], text: 'Approved' },
    teller: { code: ['000', '900'], text: 'Approved' },
    trustly: { code: ['200', '202'], text: 'Approved' },
    valitor: { code: ['000', '900'], text: 'Approved' },
    viabill: { code: ['204'], text: 'Approved' },
    vipps: { code: ['200'], text: 'Approved' }
};
