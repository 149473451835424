import { AfterViewInit, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Account } from '@interfaces/account';
import { Company } from '@models/clearhaus-application/company';
import { DirectorFile } from '@models/clearhaus-application/director-file';
import { Person } from '@models/clearhaus-application/person';
import { Country } from '@services/country/country.interface';
import { DialogService } from '@widgets/dialog/dialog.service';
import { CUSTOMER_MAT_DATEPICKER_DATEFORMAT } from '@widgets/layout/material/custom-mat-datepicker-format';
import { FileInput, FileInputComponent } from 'ngx-material-file-input';

@Component({
    selector: 'qp-clearhaus-signup-step-four',
    templateUrl: './ownership.html',
    styleUrls: ['../datepicker.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOMER_MAT_DATEPICKER_DATEFORMAT }
    ]
})
export class ClearhausSignupFormStepFourComponent implements AfterViewInit {
    @Input() public account: Account;
    @Input() public director: Person;
    @Input() public countries: Country[] = [];
    @Output() public companyChange = new EventEmitter();
    @Output() public ownersChange = new EventEmitter();
    @Output() public validityChange = new EventEmitter();

    @ViewChildren('ownerAddressIDInput') public addressIDInputs: QueryList<FileInputComponent>;
    @ViewChildren('ownerPictureIDInput') public pictureIDInputs: QueryList<FileInputComponent>;

    public _company: Company = new Company();
    public _owners: Person[] = [];
    public maxDate = new Date();
    public minDate = new Date('1900');
    public ownershipForm = new UntypedFormGroup({
        ownership_structure: new UntypedFormControl('', Validators.required),
        ownerGroup: new UntypedFormGroup({}),
        ownership_structure_comment: new UntypedFormControl('', Validators.required)
    });

    public get companyNone25(): boolean {
        return this.company?.ownership_structure === 'none_25';
    }
    public get companyOneOrMore25(): boolean {
        return this.company?.ownership_structure === 'one_or_more_25';
    }
    public get companySoleNotDirector(): boolean {
        return this.company?.ownership_structure === 'sole_not_director';
    }
    public get ownerGroup(): UntypedFormGroup {
        return this.ownershipForm.get('ownerGroup') as UntypedFormGroup;
    }
    public get ownership_structure_comment(): AbstractControl {
        return this.ownershipForm.get('ownership_structure_comment') as UntypedFormControl;
    }
    public get ownership_structure(): AbstractControl {
        return this.ownershipForm.get('ownership_structure') as UntypedFormControl;
    }

    public set company(c: Company) {
        this._company = c;
        this.companyChange.emit(c);
    }
    @Input() public get company(): Company {
        return this._company;
    }
    @Input() public get owners(): Person[] {
        return this._owners;
    }

    public set owners(value: Person[]) {
        this._owners = value;
        this.setOwnerGroupControl(value);
    }

    constructor(
        private _adapter: DateAdapter<any>,
        private dialogService: DialogService,
        private formBuilder: UntypedFormBuilder
    ) {}

    public ngAfterViewInit(): void {
        if (this.account?.locale) {
            this._adapter.setLocale(this.account.locale);
        }
        this.ownership_structure.valueChanges.subscribe(value => {
            this.handleStructureChange(value);
        });
        this.addressIDInputs.changes.subscribe(queryList => {
            queryList.toArray().forEach((addressInput: any, i: number) => {
                const addressFiles = this.owners[i]?.files?.filter(file => file.label === 'address_legitimation');
                if (addressFiles && addressFiles.length) {
                    const hollowFiles = addressFiles.map(file => this.generateHollowFile(file));
                    addressInput.value = new FileInput(hollowFiles);
                }
            });
        });
        this.pictureIDInputs.changes.subscribe(queryList => {
            queryList.toArray().forEach((pictureInput: any, i: number) => {
                const pictureFiles = this.owners[i]?.files?.filter(file => file.label === 'picture_legitimation');
                if (pictureFiles && pictureFiles.length) {
                    const hollowFiles = pictureFiles.map(file => this.generateHollowFile(file));
                    pictureInput.value = new FileInput(hollowFiles);
                }
            });
        });
        this.ownershipForm.statusChanges.subscribe(_ => {
            this.validityChange.emit(!this.ownershipForm.invalid);
        });

        this.ownership_structure.setValue(this.company?.ownership_structure);
        this.ownership_structure_comment.setValue(this.company?.ownership_structure_comment);
        this.handleStructureChange(this.company?.ownership_structure);
        this.validityChange.emit(!this.ownershipForm.invalid);
    }

    public setOwnerGroupControl(owners: Person[]): void {
        const group: { [key: string]: AbstractControl } = {};

        owners.forEach((owner, i) => {
            group[`owner${i}`] = this.formBuilder.group({
                name: ['', Validators.required],
                social_security_number: ['', Validators.required],
                date_of_birth: ['', Validators.required],
                address_line_1: ['', Validators.required],
                zipcode: ['', Validators.required],
                city: ['', Validators.required],
                country: ['', Validators.required],
                pictureID: ['', Validators.required],
                addressID: ['', Validators.required]
            });

            const pictureFiles = owner.files?.filter(file => file.label === 'picture_legitimation');
            const addressFiles = owner.files?.filter(file => file.label === 'address_legitimation');
            const pictureFileNames = pictureFiles?.map(file => file.name).join(', ');
            const addressFileNames = addressFiles?.map(file => file.name).join(', ');

            group[`owner${i}`].setValue({
                name: owner.name || null,
                social_security_number: owner.social_security_number || null,
                date_of_birth: owner.date_of_birth || null,
                address_line_1: owner.address_line_1 || null,
                zipcode: owner.zipcode || null,
                city: owner.city || null,
                country: owner.country || null,
                pictureID: pictureFileNames || null,
                addressID: addressFileNames || null
            });
        });

        if (this.company?.ownership_structure === 'none_25') {
            this.ownership_structure_comment.setValidators(Validators.required);
        } else {
            this.ownership_structure_comment.clearValidators();
            this.ownership_structure_comment.updateValueAndValidity();
        }

        this.ownershipForm.setControl('ownerGroup', this.formBuilder.group(group));

        owners.forEach((_, i) => {
            const addressIDControl = this.getOwnerControl(i, 'addressID');
            addressIDControl?.valueChanges.subscribe(fileInput => {
                if (fileInput.files?.length) {
                    this.addFile(fileInput.files[0], 'address_legitimation', i);
                }
            });

            const pictureIDControl = this.getOwnerControl(i, 'pictureID');
            pictureIDControl?.valueChanges.subscribe(fileInput => {
                if (fileInput.files?.length) {
                    this.addFile(fileInput.files[0], 'picture_legitimation', i);
                }
            });
        });
    }

    public addOwner(): void {
        const newOwners = [...this.owners];
        const newOwner: any = {
            name: null,
            social_security_number: null,
            date_of_birth: null,
            address_line_1: null,
            zipcode: null,
            city: null,
            country: null,
            pictureID: null,
            addressID: null,
            files: [],
            role_owner: true,
            role_director: false
        };
        newOwners.push(newOwner);
        this.ownersChange.emit(newOwners);
    }

    public deleteOwner(i: number): void {
        this.dialogService.confirm(
            $localize`Delete owner`,
            $localize`Sure you want to delete the owner?`,
            $localize`Delete`
        ).afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                const newOwners = [...this.owners];
                newOwners.splice(i, 1);
                this.ownersChange.emit(newOwners);
            }
        });
    }

    public handleStructureChange(structure: 'sole_director' | 'sole_not_director' | 'one_or_more_25' | 'none_25' | undefined): void {
        if (!structure) {
            return;
        }
        this.company.ownership_structure = structure;
        this.director.role_owner = structure === 'sole_director';
        let owners = [...this.owners];

        if (['sole_not_director', 'one_or_more_25'].includes(structure)) {
            const newOwner: any = {
                name: null,
                social_security_number: null,
                date_of_birth: null,
                address_line_1: null,
                zipcode: null,
                city: null,
                country: null,
                pictureID: null,
                addressID: null,
                files: [],
                role_owner: true,
                role_director: false
            };
            owners = !owners.length ? [newOwner] : owners;
            owners = structure === 'sole_not_director' ? owners.slice(0, 1) : owners;
        } else {
            owners = [];
        }

        if (owners.length !== this.owners.length) {
            this.ownersChange.emit(owners);
        }

        if (structure === 'none_25') {
            this.ownership_structure_comment.setValidators(Validators.required);
        } else {
            this.ownership_structure_comment.clearValidators();
            this.ownership_structure_comment.updateValueAndValidity();
        }
    }

    public addFile(file: any, label: string, i: number): void {
        const directorFile: DirectorFile = {
            label,
            name: file.name,
            size: file.size,
            content_type: file.type,
            file
        };
        if (!this.owners[i].files) {
            this.owners[i].files = [];
        }
        this.owners[i].files?.push(directorFile);
    }

    public removeFile(i: number, j: number): void {
        (this.owners[i] as any).files[j].remove = true;
        this.validateOwnerFiles();
    }

    public getOwnerControl(i: number, control: string): AbstractControl {
        const owner = this.ownerGroup.get(`owner${i}`) as UntypedFormGroup;
        return owner.get(control) as UntypedFormControl;
    }

    public validateOwnerFiles(): void {
        this.owners.forEach((owner, i) => {
            const addressID = this.ownerGroup.get(`owner${i}`)?.get('addressID');
            const pictureID = this.ownerGroup.get(`owner${i}`)?.get('pictureID');

            owner.files?.filter(file => !file.remove);

            const pictureFile = owner.files?.find(file => file.label === 'picture_legitimation');
            if (pictureFile && pictureID) {
                pictureID.setValue(pictureFile.name);
                pictureID.updateValueAndValidity();
            }

            const addressFile = owner.files?.find(file => file.label === 'address_legitimation');
            if (addressFile && addressID) {
                addressID.setValue(addressFile.name);
                addressID.updateValueAndValidity();
            }
        });
    }

    public generateHollowFile(file: any): File {
        // NOTE: Needed for ngx-material-file-input
        return new File([''], file.name, { type: file.content_type });
    }

    public modelCompany(propName: keyof Company, value: string): void {
        const company: Partial<Company> = {
            [propName]: value
        };
        this.company = { ...this.company, ...company };
    }

    public onOwnershipStructureCommentChange(propName: keyof Company, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const inputElement = eventTarget as HTMLInputElement;
        const company: Partial<Company> = {
            [propName]: inputElement.value
        };
        this.company = { ...this.company, ...company };
    }

    public onCountryChange(i: number, value: string): void {
        this.owners[i].country = value;
        this.ownersChange.emit(this.owners);
    }

    public modelOwner(i: number, propName: keyof Person, eventTarget: EventTarget | null): void {
        if (!eventTarget) {
            return;
        }
        const inputElement = eventTarget as HTMLInputElement;
        const personObj: Partial<Person> = {
            [propName]: inputElement.value
        };
        this.owners.splice(i, 1, personObj);
        this.ownersChange.emit(this.owners);
    }

    public handleDateChange(i: number, value: any): void {
        if (value) {
            this.modelOwner(i, 'date_of_birth', value.format('YYYY-MM-DD'));
        }
    }
}
