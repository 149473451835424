import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { PhoneNumberService } from '@services/phone-number/phone-number.service';
import { uniqBy } from 'lodash-es';
import { Subject } from 'rxjs';

/**
 * This is a component that implements the MatFormFieldControl interface which enables it to be used inside the <mat-form-field> tag.
 * Many of the properties and methods come from this interface.
 * See more about the properties and how the interface works here:
 * https://material.angular.io/guide/creating-a-custom-form-field-control
 *
 * It also implements the ControlValueAccessor interface which allows it to be used as an Angular form control.
 */
@Component({
    selector: 'qp-phone-number-field',
    templateUrl: './phone-number-field.html',
    styleUrls: ['./phone-number-field.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MatFormFieldControl, useExisting: PhoneNumberFieldComponent }]
})
export class PhoneNumberFieldComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
    public stateChanges = new Subject<void>();
    public static nextId = 0;
    public readonly countryCodes = uniqBy(this.phoneNumberService.getPhoneCountryCodesList(), 'code');
    @Input() public label = '';
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') public userAriaDescribedBy: string;
    @Input() public get value(): string {
        return `${this.countryCodeControl.value} ${this.phoneNumberControl.value}`;
    };

    public set value(fullPhoneNumber: string) {
        this.setFormValues(fullPhoneNumber);
        this.stateChanges.next();
    }

    private _placeholder: string;

    @Input() public get placeholder(): string {
        return this._placeholder;
    }
    public set placeholder(plh: string) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    public formGroup = new UntypedFormGroup({
        countryCode: new UntypedFormControl('+45'),
        phoneNumber: new UntypedFormControl('')
    });

    @HostBinding() public id = `phone-input-${PhoneNumberFieldComponent.nextId++}`;

    public focused = false;
    public touched = false;

    public get empty(): boolean {
        const completeNumber = `${this.countryCodeControl.value}${this.phoneNumberControl.value}`;
        return !completeNumber.length;
    };

    public get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    };

    private _required = false;

    @Input() public get required(): boolean {
        return this._required;
    }

    public set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input() public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this._disabled ? this.formGroup.disable() : this.formGroup.enable();
        this.stateChanges.next();
    }

    private _disabled = false;

    public get errorState(): boolean {
        return this.ngControl.errors !== null && !!this.ngControl.touched;
    }

    public controlType = 'phone-number-input';

    public autofilled = false;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private elementRef: ElementRef,
        private phoneNumberService: PhoneNumberService
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    public setDescribedByIds(_ids: string[]): void {
    }

    public onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elementRef.nativeElement.querySelector('input').focus();
        }
    }

    public onChange = (_phoneNumber: string): void => {};
    public onTouched = (): void => {};

    public ngOnInit(): void {
        this.formGroup.valueChanges.subscribe(value => {
            const phoneNumber = `${value.countryCode} ${value.phoneNumber}`;
            this.onChange(phoneNumber);
        });
    }

    @HostListener('focusin', ['$event'])
    public onFocusIn(_event: FocusEvent): void {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    @HostListener('focusout', ['$event'])
    public onFocusOut(event: FocusEvent): void {
        if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    public writeValue(fullPhoneNumber: string | null): void {
        if (!fullPhoneNumber) {
            this.phoneNumberControl.setValue('');
            return;
        }

        this.setFormValues(fullPhoneNumber);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }

    public get phoneNumberControl(): UntypedFormControl {
        return this.formGroup.get('phoneNumber') as UntypedFormControl;
    }

    public get countryCodeControl(): UntypedFormControl {
        return this.formGroup.get('countryCode') as UntypedFormControl;
    }

    public ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    private setFormValues(fullPhoneNumber: string | null): void {
        if (fullPhoneNumber && fullPhoneNumber.startsWith('+')) {
            const countryCodeAndNumberSplit = fullPhoneNumber.split(' ');
            const countryCode = countryCodeAndNumberSplit[0];
            const phoneNumber = countryCodeAndNumberSplit[1];

            this.countryCodeControl.setValue(countryCode);
            this.phoneNumberControl.setValue(phoneNumber ?? '');
        } else {
            this.countryCodeControl.setValue('+1');
            this.phoneNumberControl.setValue(fullPhoneNumber ?? '');
        }
    }
}
