import { AcquirerService } from '@services/acquirer/acquirer.service';
import { AdditionalChPricesDialogComponent } from './additional-ch-prices-dialog/additional-ch-prices-dialog.component';
import { AdditionalPricesDialogComponent } from './additional-prices-dialog/additional-prices-dialog.component';
import { catchHttpStatusError } from '@helpers/operators/catch-http-error.operator';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClearhausContract } from '@models/clearhaus-application/ch-contract';
import { ClearhausRepo } from '@endpoints/server/clearhaus-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { filter, tap } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';
import { LocalState } from '@helpers/local-state/local-state';
import { Plan } from '@models/plan';

@Component({
    selector: 'qp-pricing-and-terms',
    templateUrl: './pricing-and-terms.component.html',
    styleUrls: ['./pricing-and-terms.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingAndTermsComponent implements OnInit {
    @Input() public plan?: Plan;
    @Input() public domains?: Array<string>;
    @Output() public switchToDefaultPlan = new EventEmitter<void>();

    public contract_state = new LocalState<ClearhausContract>();
    public payout_period = '';

    constructor(
        private clearhausRepo: ClearhausRepo,
        private acquirerService: AcquirerService,
        private dialogService: DialogService
    ) {}

    public ngOnInit(): void {
        this.getContract();
    }

    public getContract(): void {
        if (this.acquirerService.acquirers?.clearhaus.active) {
            this.clearhausRepo.getContract().pipe(
                catchHttpStatusError([HttpStatusCode.BadRequest], () => undefined),
                tap(contract => this.contract_state.setState(contract)),
                filter(contract => !!contract),
                tap(contract => this.payout_period = this.getTranslatedPayoutPeriod(contract.payment.period))
            ).subscribe();
        }
    }

    public showAdditionalPrices(): void {
        this.dialogService.component(AdditionalPricesDialogComponent, this.plan);
    }

    public showAdditionalClearhausPrices(): void {
        this.dialogService.component(
            AdditionalChPricesDialogComponent,
            { contract$: this.contract_state.state$, payout_period: this.payout_period },
            { maxWidth: '650px' }
        );
    }

    public switchToDefaultPrices(): void {
        this.dialogService.confirm(
            $localize`Switch to default prices?`,
            $localize`<p>Are you sure you would like to switch to the default price plan?</p><p>You will not be able to switch back to your current plan.</p>`,
            $localize`Confirm`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            tap(() => this.switchToDefaultPlan.emit())
        ).subscribe();
    }

    private getTranslatedPayoutPeriod(period = ''): string {
        switch (period) {
            case 'daily':
                return $localize`daily`;
            case 'weekly':
                return $localize`weekly`;
            case 'monthly':
                return $localize`monthly`;
            case 'yearly':
                return $localize`yearly`;
            default:
                return period;
        }
    }
}
