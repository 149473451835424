<div *ngIf="view_model$ | async as vm" [@fade_animation]="!!vm ? 'fadeIn' : null">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../../../invoice', vm.invoice.id]" class="mat-button-back" color="accent">
            &lt; <span i18n>Back to Invoice</span> {{ vm.invoice.id }}
        </button>
    </div>

    <mat-card class="card02">
        <mat-card-content class="flex-col rg16">
            <h4>
                {{ vm.merchant_specification.attributes.merchant_id }}
                {{ vm.merchant_specification.attributes.merchant_shop_name }}
            </h4>

            <table mat-table mat-card-image [dataSource]="dataSource">
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef i18n>Description</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="subtitle1">{{ row.text }}</span>
                        <span *ngIf="row.description"> - {{ row.description }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef i18n>Quantity</th>
                    <td mat-cell *matCellDef="let row">{{ row.quantity }}</td>
                </ng-container>

                <ng-container matColumnDef="unit_price">
                    <th mat-header-cell *matHeaderCellDef i18n>Unit Price</th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.unit_price | qpCurrency: vm.merchant_specification.attributes.currency ?? '' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef i18n>Unit</th>
                    <td mat-cell *matCellDef="let row">{{ row.unit }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
            </table>

            <div>
                <span class="flex-1"></span>
                <span>
                    <span i18n>Total:</span>
                    {{ vm.merchant_specification.attributes.total_price | qpCurrency: vm.merchant_specification.attributes.currency }}
                    {{ vm.merchant_specification.attributes.currency }}
                </span>
            </div>
        </mat-card-content>
    </mat-card>
</div>
