import { Client } from '../client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ServerClient extends HttpClient {
    public abstract setHeader(name: string, value: string): any;
    public abstract deleteHeader(name: string): any;
}

/* eslint-disable */
export function serverClientFactory(httpClient: HttpClient) {
    return new Client(httpClient, '/server', {
        headers: new HttpHeaders({
            'Accept-Version': 'v10'
        })
    });
}
