<h5 i18n mat-dialog-title>Report fraud</h5>
<mat-dialog-content class="grey-border-bottom">
    <span class="text-grey" i18n>
        The transaction will be marked as fraud. You will still be able to process and manage the transaction.
    </span>
    <span class="text-grey" i18n> The transaction details will be used to train the fraud filter. </span>
    <div class="flex-row">
        <mat-form-field>
            <mat-label i18n>Description</mat-label>
            <textarea
                matInput
                [(ngModel)]="description"
                matTextareaAutosize
                matAutosizeMinRows="2"
                matAutosizeMaxRows="5"
            ></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="flex-row">
    <span class="flex-1"></span>
    <button mat-button color="accent" mat-dialog-close i18n>Close</button>
    <button mat-button color="accent" (click)="onCreateReport()" i18n>Report</button>
</mat-dialog-actions>
