import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { Agreement } from '@interfaces/agreement';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'qp-user-ip',
    templateUrl: 'ip.component.html'
})
export class UserIpComponent implements OnInit {
    @Output() public agreementChange = new EventEmitter();

    public ips: Array<any> = [];
    public ip: string;
    public showInput = false;
    public text: string;
    public agreementValue: Agreement;

    @Input()
    public get agreement(): Agreement {
        return this.agreementValue;
    }

    public set agreement(val) {
        this.agreementValue = val;
        this.agreementChange.emit(this.agreementValue);
    }

    constructor(
        private agreementRepo: AgreementRepo,
        private dialogService: DialogService,
        private snackBar: QpSnackBar
    ) {}

    public ngOnInit(): void {
        this.ips = this.agreement?.ip_restriction || [];
    }

    public remove(index: number): void {
        this.dialogService.confirm(
            $localize`Remove ip`,
            $localize`Are you sure you want to remove the ip?`,
            $localize`Remove`
        ).afterClosed().pipe(
            filter((confirmed: boolean) => confirmed)
        ).subscribe(() => {
            this.ips.splice(index, 1);
            this.save(index);
        });
    }

    public save(index?: number): void {
        if (this.ip) {
            this.ips.push({ address: this.ip });
        }

        this.agreementRepo
            .update({ ...this.agreement, ip_restriction: this.ips })
            .subscribe((agreement: Agreement) => {
                this.agreement = agreement;
                this.ips = agreement.ip_restriction || [];
                this.ip = '';
                this.showInput = false;
                if (index && index >= 0) {
                    this.snackBar.open($localize`IP removed`);
                } else {
                    this.snackBar.open($localize`IP saved`);
                }
            });
    }
}
