import { Component, OnInit } from '@angular/core';
import { Payout } from '@models/payout';
import { AgreementService } from '@services/agreement/agreement.service';
import { Animations } from 'app/animations/animations';
import { PayoutRepo } from 'app/endpoints/api/payout-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { ParamService } from '@services/param/params.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { Agreement } from '@interfaces/agreement';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qp-payout-detail',
    templateUrl: './payout-detail.html',
    animations: [Animations.getFadeAnimation()]
})
export class PayoutDetailComponent extends BaseLoadingDirective implements OnInit {
    public showSpinner = false;
    public previousParams: any;
    public agreement: Agreement | undefined;
    public payout: Payout;

    constructor(
        private dialogService: DialogService,
        private paramService: ParamService,
        private activatedRoute: ActivatedRoute,
        public payoutRepo: PayoutRepo,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.agreement = this.agreementService.agreement;
        this.previousParams = this.paramService.returnPrevParam();
        this.getPayout();
    }

    public getPayout(): void {
        this.showSpinner = true;
        this.payoutRepo.get<Payout>(this.activatedRoute.snapshot.params.payout_id).subscribe(payout => {
            this.payout = payout;
            this.showSpinner = false;
        });
    }

    public onPrint(): void {
        window.print();
    }

    public showJSON(): void {
        const obj = JSON.stringify(this.payout, null, 4);
        obj.replace(/%/g, '%%');

        this.dialogService.copy(
            '',
            obj,
            $localize`Copied to clipboard`
        );
    }
}
