import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'qp-dialog-unsaved',
    templateUrl: 'unsaved-change.html'
})
export class UnsavedChnagesDialogComponent {
    constructor(public dialogRef: MatDialogRef<UnsavedChnagesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
}
