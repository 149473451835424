<div class="flex-row no-print">
    <button mat-button [routerLink]="['../../settlements']" class="mat-button-back" color="accent">&lt; <span i18n>Back to Settlements</span></button>
</div>
<div *ngIf="loading_state.isLoading$ | async" class="flex-row justify-center">
    <mat-spinner ></mat-spinner>
</div>

<div *ngIf="loading_state.isIdle$ | async as is_idle" [@fade_animation]="is_idle ? 'fadeIn' : null">
    <div class="flex-col" *ngIf="settlement">
        <mat-card class="card02 flex-col rg16">
            <qp-settlement-period [settlement]="settlement"></qp-settlement-period>
        </mat-card>

        <mat-card class="card02">
            <qp-settlement-summary [settlement]="settlement"></qp-settlement-summary>
        </mat-card>

        <mat-card class="card02" *ngIf="!!settlement?.payout">
            <qp-settlement-payouts [settlement]="settlement"></qp-settlement-payouts>
        </mat-card>
        <mat-card class="card02">
            <qp-settlement-transactions [settlement_id]="settlement_id"></qp-settlement-transactions>
        </mat-card>
    </div>
</div>
