import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Payment } from '@models/payment';
import { Subscription } from '@models/subscription';
import { CUSTOMER_MAT_DATEPICKER_DATEFORMAT } from '@widgets/layout/material/custom-mat-datepicker-format';
import { SubscriptionRepo } from 'app/endpoints/api/subscription-repo';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'qp-subscription-payment-recurring',
    templateUrl: 'subscription-payment-recurring.component.html',
    styleUrls: ['subscription-payment-recurring.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOMER_MAT_DATEPICKER_DATEFORMAT }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionRecurringComponent implements OnInit {
    public payment: Payment = {
        currency: this.data.subscription.currency,
        amount: 0,
        due_date: ''
    } as Payment;
    public creatingRecurring = signal(false);
    public minDate = DateTime.local().plus({ days: 2 }).toFormat('yyyy-LL-dd');
    public maxDate = DateTime.local().plus({ days: 126 }).toFormat('yyyy-LL-dd');
    public focusState = false;
    public recurring_form = new FormGroup({
        amount: new FormControl<number>(0, Validators.required),
        order_id: new FormControl<string>('', Validators.required),
        due_date: new FormControl<Moment>(moment(this.minDate), Validators.required),
        description: new FormControl<string>('', Validators.required),
        text_on_statement: new FormControl<string>('')
    });

    constructor(
        private subscriptionRepo: SubscriptionRepo,
        public dialogRef: MatDialogRef<SubscriptionRecurringComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { subscription: Subscription }
    ) {}

    public ngOnInit(): void {
        this.setFormValidators();
    }

    public createPayment(): void {
        this.creatingRecurring.set(true);
        const iso_due_date = this.recurring_form.value.due_date?.toISOString() ?? DateTime.now().toISO();
        this.payment = {
            ...this.payment,
            amount: this.recurring_form.value.amount ?? NaN,
            order_id: this.recurring_form.value.order_id ?? '',
            due_date: this.data.subscription.acquirer === 'mobilepaysubscriptions' ? DateTime.fromISO(iso_due_date).toFormat('yyyy-MM-dd') : undefined,
            description: this.data.subscription.acquirer === 'mobilepaysubscriptions' ? this.recurring_form.value.description ?? '' : undefined,
            text_on_statement: this.data.subscription.acquirer === 'clearhaus' ? this.recurring_form.value.text_on_statement ?? '' : undefined
        };

        this.subscriptionRepo.createRecurring(this.data.subscription.id, this.payment).pipe(
            finalize(() => this.creatingRecurring.set(false))
        ).subscribe(() => this.dialogRef.close());
    }

    public generateOrderId(): void {
        this.recurring_form.get('order_id')?.setValue(String(Math.floor(Math.random() * 100000000000 + 1)));
    }

    private setFormValidators(): void {
        if (this.data.subscription.acquirer !== 'mobilepaysubscriptions') {
            this.recurring_form.get('due_date')?.clearValidators();
            this.recurring_form.get('description')?.clearValidators();
        }

        if (this.data.subscription.acquirer !== 'clearhaus') {
            this.recurring_form.get('text_on_statement')?.clearValidators();
        }
        this.recurring_form.updateValueAndValidity();
    }
}
