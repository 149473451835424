import { ApiModule } from 'app/endpoints/api/api.module';
import { CommonModule } from '@angular/common';
import { CustomFormElementsModule } from '@widgets/custom-form-elements/custom-form-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@widgets/layout/layout.module';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';
import { SettingsMerchantComponent } from './merchant.component';
import { SettingsMerchantEventsComponent } from './events/events.component';
import { ShopsystemsModule } from '@widgets/shopsystems/shopsystems.module';
import { ShopurlsModule } from '@widgets/shopurls/shopurls.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        ApiModule,
        CommonModule,
        CustomFormElementsModule,
        FormsModule,
        LayoutModule,
        LayoutModule,
        PipesModule,
        ReactiveFormsModule,
        ShopsystemsModule,
        ShopurlsModule,
        RouterModule
    ],
    exports: [
        SettingsMerchantEventsComponent,
        SettingsMerchantComponent
    ],
    declarations: [
        SettingsMerchantEventsComponent,
        SettingsMerchantComponent
    ]
})
export class MerchantModule {}
