import { Injectable } from '@angular/core';

import { ACCOUNTANT_PERMISSIONS } from './templates/accountant.constant';
import { ADMINSUPPORT_PERMISSIONS } from './templates/admin-support.constant';
import { ADMINVIEWER_PERMISSIONS } from './templates/admin-viewer.constant';
import { API_USER_PERMISSIONS } from './templates/api-user.constant';
import { EMPLOYEE_PERMISSIONS } from './templates/employee.constant';
import { MERCHANT_SUPPORT } from './templates/merchant-support.constant';
import { NOP_COMMERCE_PERMISSIONS } from './templates/nopcommerce.constant';
import { PAYMENT_WINDOW_PERMISSIONS } from './templates/paymentwindow.constant';
import { PRESTA_SHOP_PERMISSIONS } from './templates/prestashop.constant';
import { SHIPMONDO_PERMISSIONS } from './templates/shipmondo.constant';
import { SHOPIFY_PERMISSIONS } from './templates/shopify.constant';
import { STOREBUDDY_PERMISSIONS } from './templates/storebuddy.constant';
import { WOOCOMMERCE_PERMISSIONS } from './templates/woocommerce.constant';
import { SIMPLERO_PERMISSIONS } from './templates/simplero.constant';
import { MAGENTO_PERMISSIONS } from './templates/magento.constant';

@Injectable({
    providedIn: 'root'
})
export class PermissionTemplatesService {
    public getTemplates(): { [key: string]: any } {
        return {
            Merchant: {
                'System users': [
                    {
                        name: 'Api User',
                        acl_permissions: API_USER_PERMISSIONS
                    },
                    {
                        name: 'Payment Window',
                        acl_permissions: PAYMENT_WINDOW_PERMISSIONS
                    }
                ],
                'System templates': [
                    {
                        name: 'Magento',
                        acl_permissions: MAGENTO_PERMISSIONS
                    },
                    {
                        name: 'nopCommerce',
                        acl_permissions: NOP_COMMERCE_PERMISSIONS
                    },
                    {
                        name: 'PrestaShop',
                        acl_permissions: PRESTA_SHOP_PERMISSIONS
                    },
                    {
                        name: 'Shipmondo',
                        acl_permissions: SHIPMONDO_PERMISSIONS
                    },
                    {
                        name: 'Shopify',
                        acl_permissions: SHOPIFY_PERMISSIONS
                    },
                    {
                        name: 'Simplero',
                        acl_permissions: SIMPLERO_PERMISSIONS
                    },
                    {
                        name: 'Storebuddy',
                        acl_permissions: STOREBUDDY_PERMISSIONS
                    },
                    {
                        name: 'WooCommerce',
                        acl_permissions: WOOCOMMERCE_PERMISSIONS
                    }
                ],
                Roles: [
                    {
                        name: 'Accountant',
                        acl_permissions: ACCOUNTANT_PERMISSIONS
                    },
                    {
                        name: 'Employee',
                        acl_permissions: EMPLOYEE_PERMISSIONS
                    },
                    {
                        name: 'Owner',
                        acl_permissions: []
                    }
                ]
            },
            Reseller: {
                Merchant: [
                    {
                        name: 'Merchant support',
                        acl_permissions: MERCHANT_SUPPORT
                    }
                ],
                Roles:[
                    {
                        name: 'Owner',
                        acl_permissions: []
                    }
                ]
            },
            Administrator: {
                Roles:[
                    {
                        name: 'Viewer',
                        acl_permissions: ADMINVIEWER_PERMISSIONS
                    },
                    {
                        name: 'Support',
                        acl_permissions: ADMINSUPPORT_PERMISSIONS
                    }
                ]
            }
        };
    }
}
