import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { Account } from '@interfaces/account';
import { AccessControlPermission } from '@interfaces/acl-permission';
import { Agreement } from '@interfaces/agreement';
import { ShopifyApp, ShopifyDomainApp } from '@models/integration/shopify-app';
import { AgreementService } from '@services/agreement/agreement.service';
import { PermissionTemplatesService } from '@services/permission-templates/permission-templates.service';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { Animations } from 'app/animations/animations';
import { AccountRepo } from 'app/endpoints/api/account-repo';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { ShopifyRepo } from 'app/endpoints/server/shopify-repo';
import { WINDOW } from 'app/helpers/windowprovider/window-provider';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ShopifyService } from './shopify.service';

@Component({
    selector: 'qp-shopify',
    templateUrl: './shopify.html',
    styleUrls: ['shopify.component.scss'],
    animations: [Animations.getFadeAnimation(), Animations.getDetailExpandAnimation()]
})
export class ShopifyComponent implements OnInit {
    public window: Window;
    public shop_system: string;
    public app_domains: Array<ShopifyDomainApp> = [];
    public shopify_apps_list: Array<{ name: string; shopify_url: string }>;

    constructor(
        @Inject(WINDOW) _window: Window,
        @Inject(LOCALE_ID) private locale: string,
        private dialogService: DialogService,
        private shopifyRepo: ShopifyRepo,
        private shopifyService: ShopifyService,
        private permissionTemplateService: PermissionTemplatesService,
        private agreementRepo: AgreementRepo,
        private accountRepo: AccountRepo,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        this.window = _window;
    }

    public ngOnInit(): void {
        this.shop_system = this.agreementService.agreement?.account?.shopsystem ?? '';
        this.shopifyService.handleSecrets({});
        this.getDomainApps();
        this.isShopifyCurrentShopsystem();
        this.shopify_apps_list = environment.shopifyAppsList;
    }

    public getDomainApps(): void {
        this.shopifyRepo.getApps().subscribe((apps: ShopifyApp[]) => {
            for (const app of apps) {
                if (!this.app_domains.find((domain) => domain.shop_domain === app.shop_domain)) {
                    this.app_domains.push({
                        shop_domain: app.shop_domain,
                        installed_apps: [],
                        not_installed_apps: this.shopifyService.possibleApps(),
                        display_not_installed: false
                    });
                }

                const index = this.app_domains.findIndex((domain) => domain.shop_domain == app.shop_domain);
                this.app_domains[index].installed_apps.push(app);
                this.app_domains[index].not_installed_apps = this.app_domains[index].not_installed_apps.filter(
                    installed_app => installed_app !== this.shopifyService.translateAppNameFromServer(app.app_name)
                );
            }
        });
    }

    public installApp(domain: ShopifyDomainApp, app: string): void {
        const shop = domain.shop_domain.replace('https://', '').replace('www.', '').replace('.myshopify.com', '').replace('/', '').replace(' ', '');
        const redirect_uri = `${document.location.origin}${location.hostname === 'localhost' ? '' : `/${this.locale}`}/activate_shopify`;
        const nonce = `QP${Math.floor(Math.random() * 100000) + 1}`;
        const app_name = this.shopifyService.translateAppNameToServer(app);
        const app_key = this.shopifyService.getAppKeyFromAppName(app);
        let scope = 'write_payment_gateways,write_payment_sessions';
        if (app_name === 'quickpay-tagorders') {
            scope = 'read_orders,write_orders';
        }
        const url = `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${app_key}&scope=${scope}&redirect_uri=${redirect_uri}&state=${nonce}`;
        localStorage.setItem('nonce', nonce);

        this.shopifyRepo.checkShopExistence({
            shop_domain: shop,
            app_name: this.shopifyService.translateAppNameToServer(app)
        }).subscribe((response: any) => {
            if (!response.exists) {
                this.window.open(url, '_blank');
            } else {
                this.dialogService.alert(
                    $localize`Dublicate error`,
                    $localize`<span><strong>${shop}.myshopify.com</strong> is already in use on another account</span>`
                );
            }
        });
    }

    public removeApp(domain: ShopifyDomainApp, app: ShopifyApp): void {
        this.shopifyRepo.deleteInstalledApp(app.id).subscribe(() => {
            const domain_index = this.app_domains.findIndex((app_domain) => app_domain.shop_domain == domain.shop_domain);
            this.app_domains[domain_index].not_installed_apps.push(this.shopifyService.translateAppNameFromServer(app.app_name));
            this.app_domains[domain_index].installed_apps = this.app_domains[domain_index].installed_apps.filter(
                remove_app => remove_app.id !== app.id
            );
        });
    }

    public removeDomain(domain: ShopifyDomainApp): void {
        this.app_domains = this.app_domains.filter(remove_domain => remove_domain.shop_domain !== remove_domain.shop_domain);
        if (domain.installed_apps.length !== 0) {
            domain.installed_apps.forEach(app => {
                this.removeApp(domain, app);
            });
        }
    }

    public reabableAppName(name: string): string {
        switch (name) {
            case 'default':
                return 'Quickpay';
            case 'tagorders':
                return 'Quickpay API app';
            case 'mobilePayOnline':
                return 'MobilePay Online';
            default:
                return name[0].toUpperCase() + name.slice(1);
        }
    }

    public newIntegrationDialog(): void {
        const fields: PromptField[] = [
            {
                placeholder: 'https://www.myshop.myshopify.com',
                label: $localize`Shop name`,
                required: true,
                type: 'text',
                name: 'shop_name',
                value: ''
            }
        ];

        this.dialogService.prompt(
            $localize`Shopify shop name`,
            $localize`Fill in your Shopify shop name`,
            $localize`Continue`,
            fields
        ).afterClosed().pipe(
            filter(params => !!params),
            map((params: PromptField[]) => {
                const domain_name = params[0].value.includes('.myshopify.com') ? params[0].value : `${params[0].value}.myshopify.com`;

                if (!this.app_domains.find((domain) => domain.shop_domain === domain_name)) {
                    this.app_domains.push({
                        shop_domain: domain_name,
                        installed_apps: [],
                        not_installed_apps: this.shopifyService.possibleApps(),
                        display_not_installed: false
                    });

                } else {
                    this.dialogService.alert(
                        $localize`Duplicate Error`,
                        $localize`Domain allready exists in list`
                    );
                }
            })
        ).subscribe();
    }

    public isShopifyCurrentShopsystem(): void {
        if (this.shop_system !== 'Shopify') {
            this.dialogService.confirm(
                $localize`Shopify is not set as the merchant shop system`,
                $localize`Do you want to set Shopify as the shop system, and add the required permissions to the API user`,
                $localize`Accept`,
                $localize`Close`
            ).afterClosed().pipe(
                filter(confirmed => !!confirmed),
                switchMap(() => this.accountRepo.update({ shopsystem: 'Shopify' })),
                tap((return_account: Account) => {
                    if (!this.agreementService.agreement) {
                        return;
                    }
                    this.agreementService.agreement.account = return_account;
                    this.snackBar.open($localize`Shop system updated to shopify`);
                }),
                switchMap(() => {
                    const templates = this.permissionTemplateService.getTemplates().Merchant['System templates'];
                    let api_user_agreement: Agreement | undefined = {} as Agreement;
                    const shopifyTemplate = templates.find((template_obj: any) => template_obj.name === 'Shopify');

                    return this.agreementRepo.getAll().pipe(
                        map((agreements: Agreement[]) => {
                            api_user_agreement = agreements.find(agreement_obj => agreement_obj.description === 'API user');
                            if (api_user_agreement) {
                                api_user_agreement.acl_permissions = this.mergePermissions(
                                    api_user_agreement.acl_permissions ?? [],
                                    shopifyTemplate.acl_permissions
                                );
                            }
                            this.snackBar.open($localize`Api user permissions updated`);
                            this.ngOnInit();
                            return this.agreementRepo.replace(api_user_agreement ?? {} as Agreement);
                        })
                    );
                })
            ).subscribe();
        }
    }

    public mergePermissions(
        user_acl: Array<AccessControlPermission>,
        template_acl: Array<AccessControlPermission>
    ): Array<AccessControlPermission> {
        for (const permission of user_acl) {
            for (const new_permission of template_acl) {
                if (permission.resource === new_permission.resource) {
                    for (const key in new_permission) {
                        if (key !== 'resource' && (new_permission as any)[key] === true) {
                            (permission as any)[key] = true;
                        }
                    };
                }
            }
        }
        return user_acl;
    }
}
