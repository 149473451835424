import { Injectable } from '@angular/core';
import { PHONECOUNTRYCODES } from './country-codes';

@Injectable({
    providedIn: 'root'
})
export class PhoneNumberService {

    public getPhoneCountryCodesList(): Array<{ [key: string]: string } > {
        return Object.entries(PHONECOUNTRYCODES).map(entry => ({ country: entry[0], code: entry[1] }));
    }

    public getPhoneCountryCodesMap(): { [key: string]: string } {
        return PHONECOUNTRYCODES;
    }

    /**
     * Get the phone country code for a given country.
     *
     * @param country Alpha2 representation of the country
     * @returns the phone number country code
     */
    public getCodeForCountry(country: string): string {
        return PHONECOUNTRYCODES[country as keyof object];
    }

    /**
     * Sets the country code for a phone number.
     * Works for numbers with or without country code.
     *
     * For phone numbers with a code already set, the code must be prefixed with a '+'.
     *
     * @param country Alpha2 representation of the country
     * @param phoneNumber The phone number
     * @returns Phone number with country code
     */
    public setCountryCode(country: string, phoneNumber: string | null): string  {
        const countyCode = PHONECOUNTRYCODES[country as keyof object];

        if (phoneNumber && phoneNumber.startsWith('+')) {
            const countryCodeAndNumber = phoneNumber.split(' ');
            const newPhoneNumber = `${countyCode} ${countryCodeAndNumber[1] ?? ''}`;
            return newPhoneNumber;
        } else {
            const newPhoneNumber = `${countyCode} ${phoneNumber ?? ''}`;
            return newPhoneNumber;
        }
    }
}
