<div class="search-widget-mat mobile flex-col flex-1 hide-gt-sm" [ngClass]="wrapperClass">
    <div class="flex-row justify-start items-center">
        <button mat-icon-button class="search-button" [ngClass]="searchIconClass" (click)="toggleSearch()">
            <mat-icon *ngIf="!showSearch">search</mat-icon>
            <mat-icon *ngIf="showSearch">close</mat-icon>
        </button>
        <mat-select class="flex-1 select-70" [(ngModel)]="searchBy" *ngIf="searchByOptions && showSearch" (selectionChange)="invokeSearch()">
            <mat-option class="select-70" *ngFor="let search of searchByOptions" [value]="search.value"> {{ search.name }} </mat-option>
        </mat-select>
    </div>
    <input [ngClass]="inputClass" #searchSmallEle type="text" *ngIf="searchByOptions && showSearch" placeholder="{{ placeholder }}" [(ngModel)]="search" (ngModelChange)="searchChanged()" [matTooltip]="tooltip" />
</div>

<div class="search-field-mat hide-lt-md">
    <div class="search-widget-mat flex-1 flex-row justify-start items-center" [ngClass]="wrapperClass">
        <mat-icon [ngClass]="searchIconClass" class="search-button"> search </mat-icon>
        <input #searchEle [ngClass]="inputClass" [class.search-by-input]="searchByOptions" [class.focus]="focus" [class.search-content]="search && search.length" [class.contains-clearsection]="clearSection" (focus)="onFocus()" (blur)="onBlur()" type="text" placeholder="{{ placeholder }}" [(ngModel)]="search" (ngModelChange)="searchChanged()" [matTooltip]="tooltip" />
        <button mat-icon-button *ngIf="!clearSection && search" [hidden]="!search.length" class="search-close-button" (click)="clearsearch()">
            <mat-icon>close</mat-icon>
        </button>

        <span [hidden]="!focus">
            <div class="search-options" *ngIf="searchByOptions">
                <div class="flex-row">
                    <div class="search-option flex-1" *ngFor="let search of searchByOptions" (click)="selectSearchOption(search)" [ngClass]="{'active': search.value === searchBy}">
                        {{ search.name }}
                    </div>
                </div>
            </div>
        </span>

        <div class="clear-section" *ngIf="clearSection" [hidden]="search?.length">
            {{ clearText }} {{ search }}
            <button mat-button color="accent" (click)="search = ''" i18n>Clear</button>
        </div>
    </div>
</div>
