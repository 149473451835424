<div *ngIf="card && !loading" [@fade_animation]="!loading ? 'fadeIn' : null">
    <div class="flex-row no-print">
        <button mat-button [routerLink]="['../../cards']" class="mat-button-back" color="accent">&lt; Back to Cards</button>
        <span class="flex-1"></span>
        <div class="flex-row cg8">
            <mat-spinner *ngIf="show_spinner" [diameter]="30"></mat-spinner>
            <mat-menu #paymentMenu="matMenu">
                <button mat-menu-item [routerLink]="['../../tools/auditlog']" [queryParams]="{ target_type: 'Card', target_id: card.id }" i18n>Audit log</button>
                <button mat-menu-item (click)="showJSON()" i18n>json</button>
                <button mat-menu-item (click)="onPrint()" i18n>Print</button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="paymentMenu">
                <mat-icon color="primary">more_vert</mat-icon>
            </button>
            <span></span>
        </div>
    </div>

    <div class="flex-row">
        <div class="flex-100" *ngIf="card.metadata.bin || card.acquirer">
            <mat-card class="card02" *ngIf="card.metadata.bin">
                <mat-card-content>
                    <qp-transaction-details-card-basic (callBack)="getCard()" [permission]="(['/cards/:id/fraud-report', 'get'] | qpPermission)" [transactionRepo]="cardRepo" [transaction]="card"> </qp-transaction-details-card-basic>
                </mat-card-content>
            </mat-card>
            <qp-transaction-details-retended [transaction]="card"></qp-transaction-details-retended>
            <qp-transaction-details-acquirer [transaction]="card"></qp-transaction-details-acquirer>
        </div>
        <div class="flex-1">
            <mat-card class="card02 avoid-page-break">
                <mat-card-content class="flex-col rg16">
                    <div>
                        <h5 i18n>Card created</h5>
                        <div class="flex-row cg20p hide-lt-sm print">
                            <div class="flex-col">
                                <span class="subtitle1" i18n>Created at</span>
                                <span>{{ card.created_at | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                            </div>
                            <div class="flex-col">
                                <span class="subtitle1" i18n>Card ID</span>
                                <span>{{ card.id }}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="card.operations.length >= 1">
                        <h5 i18n>Operations</h5>
                        <div class="avoid-page-break">
                            <qp-operations *ngIf="card.currency" [currency]="card.currency" [transactionId]="card.id || -1" [operations]="card.operations" [acquirer]="card.acquirer" mat-card-image> </qp-operations>
                        </div>
                    </div>
                    <div class="flex-row cg8 no-print">
                        <button
                            mat-raised-button
                            (click)="onCreatePayment()"
                            *ngIf="
                                (['/cards/:id/tokens', 'post'] | qpPermission) &&
                                (['/payments', 'post'] | qpPermission) &&
                                (['/payments/:id/authorize', 'post'] | qpPermission) &&
                                can_create_payment"
                                i18n
                            >
                            Create payment
                        </button>
                        <button
                            mat-raised-button
                            (click)="onCreatePayout()"
                            *ngIf="
                                card.acquirer === 'clearhaus' &&
                                (['/cards/:id/tokens', 'post'] | qpPermission) &&
                                (['/payouts', 'post'] | qpPermission) &&
                                (['/payouts/:id/credit', 'post'] | qpPermission) &&
                                can_create_payout"
                                i18n
                        >Create payout</button>
                        <button
                            mat-raised-button
                            (click)="onCancel()"
                            *ngIf="(['/cards/:id/cancel', 'post'] | qpPermission) && is_cancelable"
                            i18n
                        >Cancel</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <qp-transaction-details-variables [transaction]="card"> </qp-transaction-details-variables>
        </div>
    </div>
</div>
