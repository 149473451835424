export const environment = {
    sentry: {
        dsn: 'https://b2468076c75ced781459488a1e1885a0@o4508297019260928.ingest.de.sentry.io/4508297022472272',
        sampleRate: 0.1
    },
    production: false,
    paymentWindow: 'https://payment.staging.quickpay.net',
    homePageUrl: 'https://quickpay.net',
    learnHomePageUrl: 'https://learn.quickpay.net',
    onboardingUrl: 'https://onboarding.staging.quickpay.net',
    onboardingApiUrl: 'https://quickpay-wlo-server.test.quickpay.net',
    mpsAuthorizeUrl:
        'https://sandprod-admin.mobilepay.dk/account/connect/authorize',
    mpsPortalUrl:
        'https://admin.mobilepay.dk/businessadmin-deploy-dk-website/Settings/IntegratorSettings',
    mpsRedirectUrl:
        'https://manage.staging.quickpay.net/server/mobilepay_subscriptions/redirect',
    onboardingPaypalPaymentsUrl: 'https://paypal-backend-callback.test.quickpay.net',
    devMode: false,
    acquirersList: [
        'anydaysplit',
        'applepay',
        'bambora',
        'clearhaus',
        'elavon',
        'googlepay',
        'handelsbanken',
        'klarnapayments',
        'mobilepayonline',
        'mobilepaysubscriptions',
        'nets',
        'paypal',
        'paypalpayments',
        'paysafecard',
        'pprobancontact',
        'pproblik',
        'pproprzelewy24',
        'pprosofort',
        'pprowechatpay',
        'resurs',
        'sofort',
        'swedbank',
        'swish',
        'vipps',
        'vippspsp',
        'vmpepayment',
        'teller',
        'trustly',
        'unzerpaylater',
        'valitor',
        'viabill'
    ],
    thirdpartyList: [
        'economic',
        'loyaltykey',
        'shopify'
    ],
    qapiVersion: 'v10',
    qpResellerId: 2,
    qpOneResellerId: 8,
    unzerResellerId: 6,
    qpVat: 'DK21822434',
    shopifyApps: {
        default: {
            appKey: 'b11662898909136032555764ab379f96'
        },
        anyday: {
            appKey: '377d74cccbed633c41624f252a88e3ef'
        },
        mobilePayOnline: {
            appKey: '80abbc3c400062893139278fbfdead5c'
        },
        // paysafecard: {
        //     appKey: '377d74cccbed633c41624f252a88e3ef'
        // },
        // sofort: {
        //     appKey: '377d74cccbed633c41624f252a88e3ef'
        // },
        swish: {
            appKey: '35f86b85f532734538e6ce03fba86dcd'
        },
        trustly: {
            appKey: 'ea7e2dcf8be5b3e3e83e7f03e94d8633'
        },
        // viabill: {
        //     appKey: '377d74cccbed633c41624f252a88e3ef'
        // },
        vipps: {
            appKey: 'fee36c0ba1a689ffcb07dea5e782c756'
        },
        tagorders: {
            appKey: '3a565ec01ed1826b75ed02581871b5d4'
        },
        redirect_url: 'https://manage.staging.quickpay.net/en/activate_shopify'
    },
    shopifyAppsList: [
        { name: 'Quickpay', shopify_url: 'https://apps.shopify.com/quickpay?show_store_picker=1' },
        { name: 'Quickpay-anydaysplit', shopify_url: 'https://apps.shopify.com/anyday-via-quickpay?show_store_picker=1' },
        { name: 'MobilePay Online', shopify_url: 'https://apps.shopify.com/mobilepay-via-quickpay?show_store_picker=1' },
        { name: 'Quickpay-swish', shopify_url: 'https://apps.shopify.com/swish-1?show_store_picker=1' },
        { name: 'Vipps', shopify_url: 'https://apps.shopify.com/vipps-via-quickpay?show_store_picker=1' }
    ]
};
