import { Component, Input } from '@angular/core';
import { Customer } from '@models/customer';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { DialogService } from '@widgets/dialog/dialog.service';
import { PromptField } from '@widgets/dialog/prompt/options.interface';
import { Animations } from 'app/animations/animations';
import { CustomerRepo } from 'app/endpoints/invoice/customer-repo';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'qp-invoice-emails',
    templateUrl: './emails.component.html',
    animations: [Animations.getFadeAnimation()]
})
export class InvoiceEmailsComponent extends BaseLoadingDirective {
    @Input() public customer?: Customer;

    constructor(
        private customerRepo: CustomerRepo,
        private dialogService: DialogService,
        private snackBar: QpSnackBar
    ) {
        super();
    }

    public removeEmail(index: number): void {
        this.dialogService.prompt(
            $localize`Are you sure you want to remove this email?`,
            this.customer?.attributes?.invoice_emails[index] ?? '',
            $localize`Remove`,
            []
        ).afterClosed().pipe(
            filter(val => !!val)
        ).subscribe(() => {
            this.customer?.attributes.invoice_emails.splice(index, 1);
            this.save();
        });
    }

    public addEmail(): void {
        const fields: PromptField[] = [
            {
                placeholder: $localize`Add email`,
                label: $localize`Add email`,
                required: true,
                type: 'email',
                name: 'email_value'
            }
        ];

        this.dialogService.prompt(
            $localize`Add email`,
            '',
            $localize`Add email`,
            fields,
            { width: '450px' }
        ).afterClosed().subscribe((returnFields: PromptField[]) => {
            if (returnFields) {
                if (!this.checkInvoiceEmailsContain(returnFields[0].value)) {
                    this.customer?.attributes.invoice_emails.push(returnFields[0].value);
                    this.save();
                } else {
                    this.dialogService.alert(
                        $localize`Oops`,
                        $localize`${returnFields[0].value} is already added.`
                    );
                }
            }
        });
    }

    public save(): void {
        if (this.customer && !this.customer.id) {
            this.customerRepo.create(this.customer).subscribe((customer: Customer) => {
                this.customer = customer;
                this.showSnackBar();
            });
        } else {
            this.customerRepo.update(this.customer).subscribe(() => {
                this.showSnackBar();
            });
        }
    }

    private checkInvoiceEmailsContain(email: string): boolean {
        return this.customer ? this.customer.attributes.invoice_emails.some((element: string) => email === element) : false;
    }

    private showSnackBar(): void {
        this.snackBar.open($localize`Information updated`);
    }
}
