import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../layout/material/custom-material.module';
import { FormsModule } from '@angular/forms';
import { HttpPrefixModule } from '@widgets/httpprefix/http-prefix.module';
import { LayoutModule } from '../layout/layout.module';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';
import { ShopurlsComponent } from './shopurls.component';

@NgModule({
    imports: [
        CommonModule,
        CustomMaterialModule,
        FormsModule,
        HttpPrefixModule,
        LayoutModule,
        PipesModule
    ],
    declarations: [ShopurlsComponent],
    exports: [ShopurlsComponent]
})
export class ShopurlsModule {}
