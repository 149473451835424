import { Component, OnInit } from '@angular/core';
import { Card } from '@models/card';
import { Animations } from 'app/animations/animations';
import { CardRepo } from 'app/endpoints/api/card-repo';
import { DialogService } from '@widgets/dialog/dialog.service';
import { ParamService } from '@services/param/params.service';
import { AcquirerService } from '@services/acquirer/acquirer.service';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { switchMap, filter, map } from 'rxjs/operators';
import { CardPaymentDialogComponent } from './card-create-payment/card-create-payment.component';
import { CardPayoutDialogComponent } from './card-create-payout/card-create-payout.component';
import { TransactionService } from '@services/transaction/transaction.service';
import { EMPTY } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qp-card-detail',
    templateUrl: './card-detail.html',
    animations: [Animations.getFadeAnimation()]
})
export class CardDetailComponent extends BaseLoadingDirective implements OnInit {
    public show_spinner: boolean;
    public previousParams: any;
    public card: Card;
    public show_variables: number;
    public can_create_payment: boolean;
    public can_create_payout: boolean;
    public is_cancelable: boolean;

    constructor(
        private acquirerService: AcquirerService,
        private dialogService: DialogService,
        private paramService: ParamService,
        private transactionService: TransactionService,
        public cardRepo: CardRepo,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    public ngOnInit(): void {
        this.previousParams = this.paramService.returnPrevParam();
        this.getCard();
    }

    public getCard(): void {
        this.show_spinner = true;
        this.cardRepo.get<Card>(this.activatedRoute.snapshot.params.card_id).subscribe(card => {
            this.card = card;
            this.show_variables = Object.keys(this.card.variables).length;
            this.show_spinner = false;

            this.can_create_payment = this.card.accepted && !this.transactionService.isCancelled(card);
            this.can_create_payout = this.acquirerService.isPayoutEnabled() && this.can_create_payment;
            this.is_cancelable = this.isCancelable();
        });
    }

    public isCancelable(): boolean {
        if (!this.card.accepted || this.transactionService.isCancelled(this.card) || this.transactionService.isPending(this.card)) {
            return false;
        }
        return true;
    }

    public onCreatePayout(): void {
        this.dialogService.component(CardPayoutDialogComponent, { card: this.card });
    }

    public onCreatePayment(): void {
        this.dialogService.component(CardPaymentDialogComponent, { card: this.card });
    }

    public onCancel(): void {
        this.dialogService.confirm(
            $localize`Cancel card`,
            $localize`Are you sure you want to cancel the card?`,
            $localize`Yes`,
            $localize`No`
        ).afterClosed().pipe(
            filter(confirmed => !!confirmed),
            switchMap(() => this.card.id ? this.cardRepo.cancel(this.card.id) : EMPTY),
            map((card: Card) => {
                this.card = card;
                this.show_spinner = false;
            })
        ).subscribe();
    }

    public onPrint(): void {
        window.print();
    }

    public showJSON(): void {
        const obj = JSON.stringify(this.card, null, 4);
        obj.replace(/%/g, '%%');

        this.dialogService.copy(
            '',
            obj,
            $localize`Copied to clipboard`
        );
    }
}
