<form [formGroup]="bankForm" class="flex-col rg8">
    <span class="text-grey" i18n>With Clearhaus you can accept money from your customers in any currency.</span>
    <span class="text-grey" i18n>Clearhaus can pay you in DKK, EUR, SEK, NOK, GBP and USD.</span>

    <mat-form-field hideRequiredMarker>
        <mat-label i18n>Account currency</mat-label>
        <mat-select (selectionChange)="onCurrencyChange($event.value)" formControlName="currency" required>
            <mat-option *ngFor="let currency of currencies" [value]="currency.name"> {{ currency.name }} </mat-option>
        </mat-select>
        <mat-error *ngIf="currency.errors?.required" i18n>An account currency is required.</mat-error>
    </mat-form-field>

    <mat-form-field hideRequiredMarker>
        <mat-label i18n>IBAN number</mat-label>
        <input matInput name="bank_account_iban" formControlName="iban" ngxIban type="text" (change)="onIbanChange($event.target)" required />
        <mat-error *ngIf="iban.errors?.required" i18n>An IBAN code is required.</mat-error>
        <mat-error *ngIf="bankForm.errors?.ibanError" i18n>IBAN code invalid.</mat-error>
    </mat-form-field>

    <div class="flex-row cg8">
        <button mat-raised-button matStepperPrevious i18n>Back</button>
        <button mat-raised-button [disabled]="bankForm.invalid" color="accent" matStepperNext i18n>Next</button>
    </div>
</form>
