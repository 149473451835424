import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Plan } from '@models/plan';

@Component({
    selector: 'qp-additional-prices-dialog',
    templateUrl: './additional-prices-dialog.component.html',
    styleUrls: ['./additional-prices-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalPricesDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public plan?: Plan) {}

}
