import { Component, OnInit } from '@angular/core';
import { Agreement } from '@interfaces/agreement';
import { AgreementRepo } from 'app/endpoints/api/agreement-repo';
import { BaseLoadingDirective } from '@widgets/baseLoading/base-loading.directive';
import { EventSubscription } from '@models/event-subscription';
import { EventSubscriptionsRepo } from 'app/endpoints/api/event-subscriptions-repo';
import { Animations } from 'app/animations/animations';
import { QpSnackBar } from '@services/snackbar/snackbar.service';
import { AgreementService } from '@services/agreement/agreement.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'qp-settings-merchant-events',
    templateUrl: 'events.html',
    styleUrls: ['events.component.scss'],
    animations: [Animations.getFadeAnimation()]
})
export class SettingsMerchantEventsComponent extends BaseLoadingDirective implements OnInit {
    public mailerUrl = 'https://quickpay-mailer.prod.quickpay.net/events';
    public agreements: Agreement[] = [];
    public events: EventSubscription[] = [];
    public display_columns: string[] = ['user', 'card', 'payment', 'subscription'];
    public dataSource = new MatTableDataSource();
    public subscriptionPermission: boolean;

    constructor(
        private agreementRepo: AgreementRepo,
        private eventSubscriptionsRepo: EventSubscriptionsRepo,
        private snackBar: QpSnackBar,
        private agreementService: AgreementService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.agreementService.hasPermission('/agreements', 'get')) {
            this.getEvents();
        }

        this.subscriptionPermission = false;

        if (
            this.agreementService.hasPermission('/event-subscriptions/:id', 'delete') &&
            this.agreementService.hasPermission('/event-subscriptions', 'post')
        ) {
            this.subscriptionPermission = true;
        }
    }

    public getEvents(): void {
        this.eventSubscriptionsRepo.getAll(null).subscribe((events: EventSubscription[]) => {
            this.events = events;
            this.getAgreements();
        });
    }

    public getAgreements(): void {
        const me: any = this;
        this.agreementRepo.getAll({ support: false }, undefined).subscribe((agreements: Agreement[]) => {

            agreements.forEach((agreement) => {
                const temp_agreement = agreement;

                if (!temp_agreement.user?.system_user) {
                    if (me.agreementService.agreement.owner || me.agreementService.agreement.id === temp_agreement.id) {
                        temp_agreement['events'] = {
                            card_authorize_success: {},
                            payment_authorize_success: {},
                            subscription_authorize_success: {}
                        };

                        Object.keys(me.events).forEach((eventKey: string) => {
                            const event: EventSubscription = me.events[eventKey];
                            if (event.callback_url === me.mailerUrl && event.user_agreement_id === temp_agreement.id) {
                                if (!temp_agreement['events']) {
                                    temp_agreement['events'] = {};
                                }
                                event.checked = true;
                                temp_agreement['events'][event.topic] = event;
                            }
                        });
                        me.agreements.push(temp_agreement);
                    }
                }
            });

            this.dataSource.data = me.agreements;
        });
    }

    public save(agreement: Agreement, event: string): void {
        if (agreement['events'][event].checked === false) {
            this.eventSubscriptionsRepo.destroy(agreement['events'][event].id).subscribe(() => {
                this.snackBar.open($localize`Notification updated`);
            });
        } else {
            this.eventSubscriptionsRepo.create({
                callback_url: this.mailerUrl,
                topic: event,
                agreement_id: agreement.id
            }).subscribe((newEvent: EventSubscription) => {
                newEvent.checked = true;
                agreement['events'][newEvent.topic] = newEvent;
                this.snackBar.open($localize`Notification updated`);
            });
        }
    }
}
