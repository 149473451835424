import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';
import { AcquirerHelpdeskComponent } from './acquirer-helpdesk.component';



@NgModule({
    declarations: [AcquirerHelpdeskComponent],
    imports: [
        CommonModule,
        PipesModule
    ],
    exports: [AcquirerHelpdeskComponent]
})
export class AcquirerHelpdeskModule {}
