import { Injectable } from '@angular/core';
import { AgreementService } from '@services/agreement/agreement.service';
import { SessionService } from '@services/session/session.service';
import { Cache, CacheFactory } from 'cachefactory';
import { CACHE_DEAFULT } from './cachedefault.constant';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    public cacheFactory: CacheFactory;
    public cacheDefault: { [key: string]: any } = CACHE_DEAFULT;

    constructor(
        private sessionService: SessionService,
        private agreementService: AgreementService
    ) {
        this.cacheFactory = new CacheFactory();
    }

    public getDefaultValue(type: string): { [key: string]: any } {
        return this.cacheDefault[type];
    }

    public get<T = any>(type: any, component: string, param: string): T | undefined {
        const baseObj = this.cacheDefault[type];

        if (this.getFromLocalCache(type)) {
            return Object.assign(baseObj ?? {}, this.getFromLocalCache(type)[component])[param];
        }

        return baseObj ? baseObj[param] : undefined;
    }

    public put(type: any, component: string, param: string, value: any): void {
        let viewObj: { [key: string]: any } = {};

        if (this.getFromLocalCache(type)) {
            viewObj = this.getFromLocalCache(type);
        }

        if (!viewObj[component]) {
            viewObj[component] = this.cacheDefault[type];
        }

        viewObj[component][param] = value;
        this.putToLocalCache(type, viewObj);
    }

    public getFromLocalCache(cacheType: string): any {
        let cache: string = this.sessionService.me?.id ? this.sessionService.me.id.toString() : '';
        if (this.agreementService.agreement) {
            cache = `${cache}~${this.agreementService.agreement.id.toString()}`;
        }
        return this.getLocalCache(cacheType).get(cache);
    }

    public putToLocalCache(cacheType: string, value: any): void {
        let cache: string = this.sessionService.me?.id ? this.sessionService.me.id.toString() : '';

        if (this.agreementService.agreement) {
            cache = `${cache}~${this.agreementService.agreement.id ?? ''}`;
        }

        this.getLocalCache(cacheType).put(cache, value);
    }

    public getLocalCache(cacheType: string): Cache {
        let cache = null;

        if (this.cacheFactory.exists(cacheType)) {
            cache = this.cacheFactory.get(cacheType);
        }

        if (!cache) {
            cache = this.cacheFactory.createCache(cacheType, {
                storageMode: 'localStorage',
                maxAge: 60 * 60 * 1000 * 24 * 365 // 1 year
            });
        }

        return cache;
    }
}
